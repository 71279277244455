import { INotificationsCountProps, withNotificationsCount } from '@cian/chats-notifications-connect';
import { mergeStyles } from '@cian/utils';

import * as React from 'react';
import { PureComponent, StatelessComponent } from 'react';
import { connect } from 'react-redux';

import { ReduxState } from '../../../redux/model';
import Counter, { CounterSize } from '../../../ui/counter/counter';
import Icon from '../../../ui/icon/icon';
import { mobHeaderClick } from '../../../utils/analytics';
import { IChatsConfig, inject } from '../../../utils/context_provider';
import { replaceNewbuildingSubdomainToGeo } from '../../../utils/newbuildingSubdomain';
import { DESKTOP_LINKS } from '../../../utils/to_desktop';

const styles = require('../header.css');

interface IHeaderChatsIconStoreProps {
  isUserAuthenticated: boolean;
  linkPrefix: string;
  isNewbuildingSubdomain: boolean;
}

interface IHeaderChatsIconContextProps {
  chats: IChatsConfig;
}

type THeaderChatsIconProps = IHeaderChatsIconStoreProps & IHeaderChatsIconContextProps;

const HeaderChatsIcon: StatelessComponent<THeaderChatsIconProps & INotificationsCountProps> = props => {
  const linkContent = (
    <div
      {...mergeStyles([
        styles.icon,
        (!props.notificationsCount || props.notificationsCount === 0) && styles['icon-disabled'],
      ])}
    >
      <Icon name="chats" />
      {props.notificationsCount && props.notificationsCount > 0 ? (
        <div className={styles.counter}>
          <div className={styles.counterInner}>
            <Counter size={CounterSize.S}>{props.notificationsCount}</Counter>
          </div>
        </div>
      ) : null}
    </div>
  );

  return (
    <a
      className={styles['icon-area']}
      href={`${props.linkPrefix}${DESKTOP_LINKS.CHATS}`}
      onClick={(): void => mobHeaderClick('messages')}
    >
      {linkContent}
    </a>
  );
};

class HeaderChatsIconDecorated extends PureComponent<THeaderChatsIconProps, {}> {
  public render(): JSX.Element | null {
    const {
      chats: { unreadContext },
      isUserAuthenticated,
    } = this.props;

    if (!isUserAuthenticated) {
      return <HeaderChatsIcon {...this.props} notificationsCount={null} />;
    }

    if (unreadContext) {
      const DecoratedComponent = withNotificationsCount(unreadContext)(HeaderChatsIcon);

      return <DecoratedComponent {...this.props} />;
    }

    return null;
  }
}

const mapStoreProps = (state: ReduxState): IHeaderChatsIconStoreProps => {
  const { isNewbuildingSubdomain, geoSubdomain, requestUrl, isAuthenticated } = state.common;

  return {
    isUserAuthenticated: isAuthenticated,
    linkPrefix: isNewbuildingSubdomain ? replaceNewbuildingSubdomainToGeo(requestUrl, geoSubdomain) : '',
    isNewbuildingSubdomain,
  };
};

export const HeaderChatsIconContainer = connect<IHeaderChatsIconStoreProps, {}, {}>(mapStoreProps)(
  inject(['chats'])<IHeaderChatsIconStoreProps>(HeaderChatsIconDecorated),
);
