import { boxTypeDict } from '../../redux/filters/dictionaries';
import {
  CommissionType,
  ContractType,
  EJsonQueryPriceType,
  ESuburbanOfferFilter,
  TCategoryType,
} from '../../redux/filters/model';

export interface IJsonQueryValue {
  type: string;
}

export interface IJsonQueryTerm<T> extends IJsonQueryValue {
  type: 'term';
  value: T;
}

export interface IJsonQueryTerms<T> extends IJsonQueryValue {
  type: 'terms';
  value: T[];
}

export interface IJsonQueryRange extends IJsonQueryValue {
  type: 'range';
  value: { gte?: number; lte?: number };
}

export type IJsonQueryGeoValue = ISimpleJsonQueryGeoValue | IJsonQueryPolygon | IJsonQueryDistance;

export type ISimpleJsonQueryGeoValue =
  | IJsonQueryLocation
  | IJsonQueryHighway
  | IJsonQueryDistrict
  | IJsonQueryStreet
  | IJsonQueryHouse
  | IJsonQueryUnderground
  | IJsonQueryBuilder
  | IJsonQueryNewObject
  | IJsonQueryRailway;

export interface IJsonQueryGeo extends IJsonQueryValue {
  type: 'geo';
  value: Array<IJsonQueryGeoValue>;
}

export interface IJsonQueryRailway extends IJsonQueryValue {
  type: 'railway';
  id: number;
}

export interface IJsonQueryLocation extends IJsonQueryValue {
  type: 'location';
  id: number;
}

export interface IJsonQueryNewObject extends IJsonQueryValue {
  type: 'newobject';
  id: number;
}

export interface IJsonQueryStreet extends IJsonQueryValue {
  type: 'street';
  id: number;
}

export interface IJsonQueryHouse extends IJsonQueryValue {
  type: 'house';
  id: number;
}

export interface IJsonQueryUnderground extends IJsonQueryValue {
  type: 'underground';
  id: number;
}

export interface IJsonQueryDistrict extends IJsonQueryValue {
  type: 'district';
  id: number;
}

export interface IJsonQueryHighway extends IJsonQueryValue {
  type: 'highway';
  id: number;
}

export interface IJsonQueryBuilder extends IJsonQueryValue {
  type: 'builder';
  id: number;
}

export interface IJsonQueryPolygon extends IJsonQueryValue {
  type: 'polygon';
  name?: string;
  coordinates: Array<[string, string]>;
}

export interface IJsonQueryDistance extends IJsonQueryValue {
  type: 'distance';
  radius: number;
  center: [string, string];
}

export interface IJsonQuery {
  _type: string;

  ceiling_height?: IJsonQueryRange;
  windows_type?: IJsonQueryTerm<number>;
  apartment?: IJsonQueryTerm<boolean>;
  amenity?: IJsonQueryTerms<number>;
  all_day_access?: IJsonQueryTerm<number>;
  all_week_access?: IJsonQueryTerm<number>;
  balconies?: IJsonQueryRange;
  bath?: IJsonQueryTerm<boolean>;
  bathhouse?: IJsonQueryTerm<boolean>;
  bedroom_total?: IJsonQueryRange;
  build_quarter?: IJsonQueryTerm<number>;
  build_year?: IJsonQueryTerm<number>;
  building_class_type?: IJsonQueryTerms<number>;
  building_status?: IJsonQueryTerm<number>;
  category?: IJsonQueryTerms<TCategoryType>;
  commission_type?: IJsonQueryTerm<CommissionType>;
  conditioner?: IJsonQueryTerm<boolean>;
  contract?: IJsonQueryTerms<ContractType>;
  currency?: IJsonQueryTerm<number>;
  demolished_in_moscow_programm?: IJsonQueryTerm<boolean>;
  dish_washer?: IJsonQueryTerm<boolean>;
  electricity?: IJsonQueryTerm<boolean>;
  engine_version: IJsonQueryTerm<number>;
  enter?: IJsonQueryTerm<Boolean>;
  floor?: IJsonQueryRange;
  floorn?: IJsonQueryRange;
  foot_min?: IJsonQueryRange;
  for_day?: IJsonQueryTerm<string>;
  from_developer?: IJsonQueryTerm<boolean>;
  from_mcad_km?: IJsonQueryRange;
  from_offrep?: IJsonQueryTerm<boolean>;
  garage_garage_type?: IJsonQueryTerms<number>;
  garage_material?: IJsonQueryTerms<boxTypeDict>;
  garage_type?: IJsonQueryTerms<number>;
  garage?: IJsonQueryTerm<boolean>;
  gas?: IJsonQueryTerm<boolean>;
  geo?: IJsonQueryGeo;
  hand_over?: IJsonQueryTerm<boolean>;
  has_decoration?: IJsonQueryTerm<boolean>;
  has_drainage?: IJsonQueryTerm<boolean>;
  has_fridge?: IJsonQueryTerm<boolean>;
  has_furniture?: IJsonQueryTerm<boolean>;
  has_kitchen_furniture?: IJsonQueryTerm<boolean>;
  has_video?: IJsonQueryTerm<boolean>;
  has_washer?: IJsonQueryTerm<boolean>;
  has_water?: IJsonQueryTerm<boolean>;
  house_material?: IJsonQueryTerms<number>;
  house_year?: IJsonQueryRange;
  input_type?: IJsonQueryTerms<number>;
  internet?: IJsonQueryTerm<boolean>;
  ipoteka?: IJsonQueryTerm<boolean>;
  is_basement?: IJsonQueryTerm<boolean>;
  is_by_homeowner?: IJsonQueryTerm<boolean>;
  is_by_commercial_owner?: IJsonQueryTerm<boolean>;
  is_first_floor?: IJsonQueryTerm<boolean>;
  is_pik_promo?: IJsonQueryTerm<boolean>;
  is_semibasement?: IJsonQueryTerm<boolean>;
  is_special_promo?: IJsonQueryTerm<boolean>;
  kids?: IJsonQueryTerm<boolean>;
  kitchen?: IJsonQueryRange;
  kp_id?: IJsonQueryTerm<number>;
  land_status2?: IJsonQueryTerms<number>;
  lift_service?: IJsonQueryRange;
  lifts?: IJsonQueryTerm<number>;
  living_area?: IJsonQueryRange;
  loggia?: IJsonQueryTerm<boolean>;
  multi_id?: IJsonQueryTerm<number>;
  newbuildings_search?: IJsonQueryTerm<string>;
  not_last_floor?: IJsonQueryTerm<boolean>;
  object_type?: IJsonQueryTerms<number>;
  office_type?: IJsonQueryTerms<number>;
  only_flat?: IJsonQueryTerm<boolean>;
  only_foot?: IJsonQueryTerm<string>;
  page?: IJsonQueryTerm<number>;
  pets?: IJsonQueryTerm<boolean>;
  phone?: IJsonQueryTerm<boolean>;
  pool?: IJsonQueryTerm<boolean>;
  price_sm?: IJsonQueryTerm<boolean>;
  price?: IJsonQueryRange;
  price_type?: IJsonQueryTerm<EJsonQueryPriceType>;
  promo?: IJsonQueryTerm<boolean>;
  publish_period?: IJsonQueryTerm<number>;
  region?: IJsonQueryTerms<number>;
  ready_business_types?: IJsonQueryTerms<number>;
  room?: IJsonQueryTerms<number>;
  rooms_count?: IJsonQueryTerms<number>;
  rooms_for_sale?: IJsonQueryRange;
  shower?: IJsonQueryTerm<boolean>;
  site?: IJsonQueryRange;
  sort?: IJsonQueryTerm<string>;
  sost_type?: IJsonQueryTerms<number>;
  special_promo?: IJsonQueryTerm<string>;
  specialty_types?: IJsonQueryTerms<number>;
  status?: IJsonQueryTerms<number>;
  total_area?: IJsonQueryRange;
  tv?: IJsonQueryTerm<boolean>;
  wc_site?: IJsonQueryTerm<number>;
  wc_type?: IJsonQueryTerm<number>;
  wc?: IJsonQueryRange;
  with_newobject?: IJsonQueryTerm<boolean>;
  wp?: IJsonQueryTerm<boolean>;
  year?: IJsonQueryTerms<number>;
  yeargte?: IJsonQueryTerm<number>;
  zalog?: IJsonQueryTerm<boolean>;
  bs_center?: IJsonQueryTerms<number>;
  suburban_offer_filter?: IJsonQueryTerm<ESuburbanOfferFilter>;
  room_type?: IJsonQueryTerm<number>;
  with_layout?: IJsonQueryTerm<boolean>;
  saved_search_id?: IJsonQueryTerm<string>;
  domrf?: IJsonQueryTerm<boolean>;
  coworking_offer_type?: IJsonQueryTerms<number>;
  workplace_count?: IJsonQueryRange;
  is_sales_leader?: IJsonQueryTerm<boolean>;
  is_sales_start?: IJsonQueryTerm<boolean>;
  is_upcoming_sale?: IJsonQueryTerm<boolean>;
  electronic_trading?: IJsonQueryTerm<number>;
}

export function term<T>(value: T): IJsonQueryTerm<T> {
  return {
    type: 'term',
    value,
  };
}

export function terms<T>(value: T[]): IJsonQueryTerms<T> {
  return {
    type: 'terms',
    value,
  };
}

export function range(lte?: number, gte?: number): IJsonQueryRange {
  return {
    type: 'range',
    value: { lte, gte },
  };
}

export function rangeLte(lte: number): IJsonQueryRange {
  return {
    type: 'range',
    value: { lte },
  };
}

export function rangeGte(gte: number): IJsonQueryRange {
  return {
    type: 'range',
    value: { gte },
  };
}

export type ObjectType = 'flat' | 'suburban' | 'commercial' | 'newobject';

export function parseObjectType(_type: string): ObjectType {
  if (_type.includes('flat')) {
    return 'flat';
  }
  if (_type.includes('newobject')) {
    return 'newobject';
  }
  if (_type.includes('commercial')) {
    return 'commercial';
  }
  if (_type.includes('suburban')) {
    return 'suburban';
  }
  throw new Error(`Unexpected _type: ${_type}`);
}

export type RentTime = 'long' | 'short';

export function parseRentTime(forDay: IJsonQueryTerm<string>): RentTime {
  return forDay.value === '1' ? 'short' : 'long';
}

export type DealType = 'rent' | 'sale';
