import { ICommercialSpecialty, IOfferDetail } from '../../../api/models/offer_card';
import { responseToBaseInfo } from './base_info_parser';

export const responseToSpecialty = (offer: IOfferDetail): ICommercialSpecialty => {
  const baseInfo = responseToBaseInfo(offer);
  const specialties = [{ rusName: baseInfo.specialty?.length ? baseInfo.specialty[0] : undefined }];

  return {
    ...offer.specialty,
    specialties: [...(offer.specialty?.specialties || []), ...specialties],
  };

};
