import { CheckboxGroup } from '@cian/nested-checkbox-groups';

import * as React from 'react';

import { IReadyBusinessGroupItem } from '../../../../../../../../../shared/repositories/announcements/entities/AnnouncementReferences/ReadyBusinessGroupItem';

export function useSemiCategoryGroups(groups: Array<IReadyBusinessGroupItem>): Array<CheckboxGroup> {
  return React.useMemo<Array<CheckboxGroup>>(() => {
    return groups.map(group => ({
      items: group.items.map(item => ({ label: item.name, value: item.id })),
      label: group.name,
      value: group.groupType,
    }));
  }, [groups]);
}
