import { SAFE_RENT_POPUP_SHOW, TOfferActions } from './actions';
import { IOfferPageInfo, defaultOfferState } from './model';
import { responseToOffer } from './response_parser/offer_parser';

type Reducer = (state: IOfferPageInfo, action: TOfferActions) => IOfferPageInfo;

export const offerReducer: Reducer = (state = defaultOfferState, action) => {
  switch (action.type) {
    case 'GetOfferInfoFromOffersList': {
      return {
        ...state,
        breadcrumbs: action.breadcrumbs,
        offer: action.offer,
        similarOffers: [],
      };
    }

    case 'GetOfferInfoSuccess': {
      const { data, urlParams } = action.payload;

      return {
        ...state,
        ...responseToOffer(data, state, urlParams),
        similarOffers: [],
      };
    }

    case 'SetFavorite':
      return {
        ...state,
        offer: {
          ...state.offer,
          isFavorite: action.payload,
        },
      };

    case 'SetHideOfferOnCard': {
      return {
        ...state,
        offer: {
          ...state.offer,
          isHiddenByUser: action.payload,
        },
      };
    }

    case 'GetSimilarOfferSuccess': {
      const { similarOffersData, withoutCompetitors, modelVersion } = action.payload;

      return {
        ...state,
        similarOffers: similarOffersData,
        withoutCompetitors,
        modelVersion,
      };
    }

    case 'GetComplaintsTreePending':
      return {
        ...state,
        complain: {
          complained: false,
          loading: true,
        },
      };

    case 'GetComplaintsTreeSuccess':
      return {
        ...state,
        complaints: action.payload,
        complain: {
          complained: false,
          loading: false,
        },
      };

    case 'ClearComplaint':
      return {
        ...state,
        complain: {
          complained: false,
          loading: false,
          error: undefined,
        },
      };

    case 'SendComplaintPending':
      return {
        ...state,
        complain: {
          complained: false,
          loading: true,
        },
      };

    case 'SendComplaintError':
      return {
        ...state,
        complain: {
          complained: false,
          loading: false,
          error: action.payload,
        },
      };

    case 'SendComplaintSuccess':
      return {
        ...state,
        complain: {
          complained: true,
          loading: false,
        },
      };

    case 'SubscribePriceRequest':
      return {
        ...state,
        subscribe: {
          loading: true,
        },
      };

    case 'SubscribePriceSuccess':
      if (!state.subscribe) {
        return state;
      }

      return {
        ...state,
        subscribe: {
          loading: false,
        },
      };

    case 'SubscribePriceFail':
      return {
        ...state,
        subscribe: {
          loading: false,
        },
      };

    case 'SubscribePriceStatus':
      return {
        ...state,
        subscribeStatus: action.payload,
      };
    case 'GetGKMVKSuccess':
      return {
        ...state,
        GKMVK: action.payload,
        isFetching: false,
      };

    case 'GetGKMVKPending':
      return {
        ...state,
        GKMVK: undefined,
        isFetching: true,
      };

    case 'GetGKMVKFailure':
      return {
        ...state,
        isFetching: false,
      };
    case 'GetSimilarGKOffersPending':
      return {
        ...state,
        similarGKOffers: undefined,
      };
    case 'GetSimilarGKOffersSuccess':
      return {
        ...state,
        similarGKOffers: action.payload.offers,
      };

    case 'SetOfferCorrectHost':
      return {
        ...state,
        correctHost: action.payload.correctHost,
      };

    case 'NotFoundOffer':
      return {
        ...state,
        isNotFoundOffer: action.value,
      };

    case SAFE_RENT_POPUP_SHOW:
      return {
        ...state,
        isSafeRentPopupShow: action.payload.isShow,
      };

    default:
      return state;
  }
};
