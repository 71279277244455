import { mergeStyles } from '@cian/components';
import * as React from 'react';
import { SortingSelectContainer } from './sortingSelect';

const styles = require('./sortingBlock.css');
const NBSP = '\u00A0';

export interface ISortingBlockProps {
  emptyList: boolean;
  description?: string;
  isCommercial?: boolean;
  isPhone: boolean;
}

export interface ISortingBlockState {}

export class SortingBlock extends React.PureComponent<ISortingBlockProps, ISortingBlockState> {

  public render() {
    const {emptyList, description, isCommercial, isPhone} = this.props;
    const blockStyles = mergeStyles(styles['sorting-block'], isPhone && styles['phone']);

    return(
      <div>
        {!emptyList &&
          <div {...blockStyles}>
            <div>{description && description}{!isPhone && ` отсортированы `}</div>
            {NBSP}
            <SortingSelectContainer isCommercial={isCommercial} isPhone={isPhone} />
          </div>
        }
      </div>
    );
  }
}
