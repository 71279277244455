import * as React from 'react';

import {
  HouseMaterial,
  IFieldProps,
  isSuburbanObjectGroupHouse,
  IsVisibleCheck,
} from '../field';

import { Label } from '@cian/components';
import { SelectMobile } from '../../common/select/select';
import { createSelectOptions } from '../../common/select_helper';

const style = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  private options = [{
    label: '',
    options: [{
      id: HouseMaterial.Brick.toString(),
      label: 'Кирпичный',
    }, {
      id: HouseMaterial.Wood.toString(),
      label: 'Деревянный',
    }, {
      id: HouseMaterial.Monolithic.toString(),
      label: 'Монолитный',
    }, {
      id: HouseMaterial.Shield.toString(),
      label: 'Щитовой',
    }],
  }];

  public render() {
    const processedOptions = createSelectOptions(
      this.options,
      this.props.filter.houseMaterials.map(String),
      this.props.filter,
    );
    return (
      <div className={style.field} data-mark="FieldSelectMaterial">
        <Label label="Материал дома">
          <SelectMobile
            multiple
            id="select-house-material"
            name="selectMobile"
            label="Неважно"
            onValueChange={this.handleValueChange}
            value={processedOptions.value}
            options={processedOptions.options[0].options}
          />
        </Label>
      </div>
    );
  }
  private handleValueChange = (values: string[]) => this.props.actions.setHouseMaterials(values);
}

export const isVisible: IsVisibleCheck = filter => isSuburbanObjectGroupHouse(filter);
