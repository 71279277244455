import * as React from 'react';

import { PublicationPeriod } from '../../../../redux/filters/model';
import { IFieldProps, IsVisibleCheck } from '../../field';

import { isSafari } from '../../../../utils/device_detector';
import { SelectMobile } from '../../../common/select/select';
import { createSelectOptions } from '../../../common/select_helper';

const styles = require('../../field.css');

export class Component extends React.Component<IFieldProps, object> {
  private selectId = 'select-publication-period';
  private select: HTMLSelectElement | null;

  private options = [{
    label: '',
    options: [{
      id: String(PublicationPeriod.Unexpected),
      label: 'За все время',
    }, {
      id: String(PublicationPeriod.Today),
      label: 'За сегодня',
    }, {
      id: String(PublicationPeriod.Hours1),
      label: 'За час',
    }, {
      id: String(PublicationPeriod.Hours24),
      label: 'За сутки',
    }, {
      id: String(PublicationPeriod.Days10),
      label: 'За 10 дней',
    }],
  }];

  public componentDidMount() {
    if (typeof window !== 'undefined') {
      this.select = document.getElementById(this.selectId) as HTMLSelectElement;
    }
  }

  public render() {
    const processedOptions = createSelectOptions(
      this.options,
      [String(this.props.filter.publicationPeriod)],
      this.props.filter,
    );

    return (
      <div data-mark="BlockPublicationPeriod" className={styles['filter-publication']}>
        <span className={styles['filter-label']}>Дата публикации</span>
        <SelectMobile
          id={this.selectId}
          name="selectMobile"
          label="За все время"
          onValueChange={this.handleChange}
          value={processedOptions.value}
          options={processedOptions.options[0].options}
        />
      </div>
    );
  }

  private handleChange = (options: string[]) => {
    if (this.select && isSafari(this.props.userAgent || '')) {
      this.select.blur();
    }

    const value = Array.isArray(options) ? Number(options[0])
      : options ? Number(options) : undefined;

    this.props.actions.setPublicationPeriod(value);
  }
}

export const isVisible: IsVisibleCheck = filter => !!filter.selectedObjectTypes.length;
