import { get, omitBy } from 'lodash';

import {
  defaultOfferState,
  IAddressInfo,
  IHighway,
  IJK,
} from '../model';

import { IUnderground } from '../../common/types';

import {
  IAddressInfo as IAddressInfoResp,
  ICoordinates,
  IHighwayInfo,
  IJK as IJKResp,
  IOfferDetail,
  IUndergroundInfo,
} from '../../../api/models/offer_card';

const parseCoordinates = (coordinates?: ICoordinates): number[] | undefined => {
  return coordinates == null || coordinates.lat == null || coordinates.lng == null
    ? undefined
    : [coordinates.lat as number, coordinates.lng as number];
};

const parseJk = (offer: IOfferDetail): IJK | undefined => {
  const jk: IJKResp | null | void = get(offer, 'geo.jk');

  return jk === null || jk === undefined
    ? undefined
    : {
      name: jk.name,
      url: offer.jkUrlWithSubdomain || offer.jkUrl,
      displayName: jk.displayName,
      id: String(jk.id),
    };
};

const parseTransportType = (type?: string) => {
  switch (type) {
    case 'walk':
      return 'foot';
    case 'transport':
      return 'transport';
    default:
      return 'foot';
  }
};

const parseUndergrounds = (undergrounds?: IUndergroundInfo[]): IUnderground[] | undefined => {
  const undergroundMapper = (u: IUndergroundInfo): IUnderground => {
    return {
      name: u.name || '',
      lineColor: '#' + u.lineColor,
      time: u.time,
      type: parseTransportType(u.transportType),
    };
  };

  return undergrounds == null
    ? undefined
    : undergrounds.map(undergroundMapper);
};

const parseHighways = (highways: IHighwayInfo[]): IHighway[] | undefined => {
  return highways == null
    ? undefined
    : highways.map((h: IHighwayInfo) => ({ name: h.name, distance: h.distance }));
};

const parseAddress = (address: IAddressInfoResp[]): string | undefined => {
  return address == null
    ? undefined
    : address.map((a: IAddressInfoResp) => a.fullName).join(', ');
};

export const responseToAddressInfo = (offer: IOfferDetail): IAddressInfo | undefined => {
  if (offer.geo == null) {
    return undefined;
  }

  const newState = omitBy({
    coordinates: parseCoordinates(get(offer, 'geo.coordinates') as ICoordinates),
    jk: parseJk(offer),
    address: parseAddress(get(offer, 'geo.address') as IAddressInfoResp[]),
    buildingName: offer.building && offer.building.name,
    settlementName: offer.settlementName,
    undergrounds: parseUndergrounds(get(offer, 'geo.undergrounds') as IUndergroundInfo[]),
    highways: parseHighways(get(offer, 'geo.highways') as IHighwayInfo[]),
  }, v => v == null);

  return Object.assign({}, defaultOfferState.offer.addressInfo, newState);
};
