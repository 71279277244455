import { IGKData } from '../../../api/models/gk_card';
import { IRoadInfo, IUndergroundInfo } from '../../../api/models/gk_preview';
import { IUnderground } from '../../common/types';
import { IAddressInfo } from '../../offer/model';

const parseTransportType = (id: number) => {
  switch (id) {
    case 501:
      return 'foot';
    case 502:
      return 'transport';
    default:
      return 'foot';
  }
};

export const parseAddress = (gk: IGKData): IAddressInfo => {
  const { lat, lng } = gk.geo;
  const undergrounds = gk.geo.underground_info.map((u: IUndergroundInfo): IUnderground => ({
    lineColor: '#' + u.line_color,
    time: u.time,
    name: u.name,
    type: parseTransportType(u.transport_id),
  }));

  const highways = gk.geo.road_info.map((road: IRoadInfo) => ({
    ...road,
  }));

  return {
    coordinates: [ lng, lat ],
    address: gk.full_address,
    undergrounds,
    highways,
  };
};
