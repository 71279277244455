import * as React from 'react';

import { CheckboxList, ICheckboxListItem } from '../../../components/filters_checkbox_list/filters_ckeckbox_list';
import { FlatCondition } from '../../../redux/filters/model';
import { IFieldProps, IsVisibleCheck } from '../field';

export class Component extends React.Component<IFieldProps, object> {
  public render() {

    const conditions: ICheckboxListItem[] = [
      { id: FlatCondition.Kids, label: 'Можно с детьми' },
      { id: FlatCondition.Pets, label: 'Можно с животными' },
    ];

    const selectedConditions = this.props.filter.flatConditions;
    return (
      <CheckboxList
        list={conditions}
        selected={selectedConditions}
        onItemChecked={(checked: boolean, id: number) => this.props.actions.toggleFlatCondition(id)}
        dataMark="FieldAllowedList"
      />
    );
  }
}

export const isVisible: IsVisibleCheck = filter => true;
