import { IFullscreenAppBannerState } from './model';
import { IFullscreenAppBannerActions } from './actions';

const initialState: IFullscreenAppBannerState = {
  isViewed: false
};

export default (state: IFullscreenAppBannerState = initialState, action: IFullscreenAppBannerActions): IFullscreenAppBannerState => {
  switch (action.type) {
    case 'fullscreen_app_banner/close':
      return {
        ...state,
        isViewed: true
      };

    default:
      return state;
  }
};
