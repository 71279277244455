import * as React from 'react';

import { IFieldProps, IsVisibleCheck } from '../field';

import { CheckboxGroup } from './components/CheckboxGroup';

export const  Component: React.FC<IFieldProps> = ({ filter, actions }) => {
  const onItemChange = React.useCallback(
    (checked: boolean, itemKey: string) => {
      // Если чек-бокс отмечен, то добавляем в списко
      // если нет, то удаляем
      const filteredAminitiesList = checked
        ? [...filter.amenities, itemKey]
        : filter.amenities.filter(key => key !== itemKey);

      actions.setCoworkingAmenity(filteredAminitiesList);
    },
    [filter.amenities, actions.setCoworkingAmenity],
  );

  return <CheckboxGroup checkedList={filter.amenities} onItemChange={onItemChange}/>;
};

export const isVisible: IsVisibleCheck = () => true;
