import * as React from 'react';

import { ButtonCheckbox, Label, mergeStyles } from '@cian/components';
import { HouseMaterial } from '../../../redux/filters/model';
import ButtonCheckboxContainer from '../../../ui/button_checkbox_container/button_checkbox_container';
import { flatObjectType, IFieldProps, IsVisibleCheck } from '../field';

const fieldStyles = require('../field.css');

const typePrefix = 'house-type-';

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const { selectedHouseTypes } = this.props.filter;
    const buttonCheckboxStyle = [
      fieldStyles.buttonCheckbox,
    ];

    return (
      <div {...mergeStyles(fieldStyles.field)} data-mark="FieldHouseType">
        <Label label="Тип дома">
          <ButtonCheckboxContainer>
            <ButtonCheckbox
              mobile
              buttonCheckboxStyle={buttonCheckboxStyle}
              checked={selectedHouseTypes.includes(HouseMaterial.Panel.toString())}
              onChange={this.setSelectedHouseTypes}
              id={`${typePrefix}3`}
              name="panel-house"
            >
              Панельный
            </ButtonCheckbox>
            <ButtonCheckbox
              mobile
              buttonCheckboxStyle={buttonCheckboxStyle}
              checked={selectedHouseTypes.includes(HouseMaterial.Brick.toString())}
              onChange={this.setSelectedHouseTypes}
              id={`${typePrefix}1`}
              name="brick-house"
            >
              Кирпичный
            </ButtonCheckbox>
            <ButtonCheckbox
              mobile
              buttonCheckboxStyle={buttonCheckboxStyle}
              checked={selectedHouseTypes.includes(HouseMaterial.Monolithic.toString())}
              onChange={this.setSelectedHouseTypes}
              id={`${typePrefix}2`}
              name="monolithic-house"
            >
              Монолитный
            </ButtonCheckbox>
          </ButtonCheckboxContainer>
        </Label>
      </div>
    );
  }

  private fromID = (htmlId: string): string => {
    return htmlId.replace(typePrefix, '');
  }

  private setSelectedHouseTypes = (e: React.FormEvent<HTMLFormElement>) => {
    const { selectedHouseTypes } = this.props.filter;
    const { id, checked } = e.target as HTMLInputElement;
    const type = this.fromID(id);

    if (checked && !selectedHouseTypes.includes(type)) {
      this.props.actions.setSelectedHouseTypes(selectedHouseTypes.concat(type));
    } else if (!checked) {
      this.props.actions.setSelectedHouseTypes(selectedHouseTypes.filter(t => {
        return t !== type;
      }));
    }
  }
}

export const isVisible: IsVisibleCheck = filter => filter.selectedObjectTypes.length > 0
  && filter.selectedObjectTypes.every(t => t === flatObjectType.Apartment)
  && filter.withNewobject;
