import * as React from 'react';

import ButtonRadioGroupField from '../../../ui/button_radio_group_field/button_radio_group_field';
import {
  IFieldProps,
  isFlatObjectGroup,
  isSaleType,
  IsVisibleCheck,
} from '../field';

import * as fieldStyles from '../field.css';

export enum EHouseStatus {
  all,
  withNewobject,
  resaleOnly,
}

export class Component extends React.Component<IFieldProps, {}> {

  public shouldComponentUpdate(nextProps: IFieldProps) {
    return this.getCurValue() !== this.getCurValue(nextProps);
  }

  public render() {

    return (
      <div className={fieldStyles['field']} data-mark="FieldHouseStatus">
        <ButtonRadioGroupField
          value={this.getCurValue()}
          onValueChange={this.onValueChange}
          values={[
              { value: EHouseStatus.all, label: 'Все' },
              { value: EHouseStatus.withNewobject, label: 'Новостройка' },
              { value: EHouseStatus.resaleOnly, label: 'Вторичка' },
          ]}
          buttonStyle={fieldStyles['houseStatusButton']}
          activeButtonStyle={fieldStyles['active']}
        />
      </div>
    );
  }

  private getCurValue = (props?: IFieldProps)  => {
    const { filter: { withNewobject, resaleOnly} } = props ? props : this.props;

    if (withNewobject || resaleOnly) {
      return  withNewobject ? EHouseStatus.withNewobject : EHouseStatus.resaleOnly;
    }
    return EHouseStatus.all;
  }

  private onValueChange = (value: EHouseStatus) => {
    const { SetHouseType } = this.props.actions;

    SetHouseType({
      resaleOnly: value === EHouseStatus.resaleOnly,
      withNewobject: value === EHouseStatus.withNewobject,
    });
  }
}

export const isVisible: IsVisibleCheck = filter => {
  return (isFlatObjectGroup(filter) && isSaleType(filter));
};
