import * as React from 'react';

import { VisualSize } from '@cian/components';
import { IStyleConfig, mergeStyles } from '@cian/utils';

const style = require('./text.css');

export enum TextColor {
  WHITE,
  GRAY,
  BLACK,
}

export enum TextAlign {
  CENTER,
  LEFT,
  RIGHT,
}

export enum FontWeight {
  SEMI_BOLD,
  BOLD,
}

const FontWeightClass = {
  [FontWeight.SEMI_BOLD]: style.fontWeightSemiBold,
  [FontWeight.BOLD]: style.fontWeightBold,
};

const TextColorClass = {
  [TextColor.GRAY]: style.colorGray,
  [TextColor.WHITE]: style.colorWhite,
  [TextColor.BLACK]: style.colorBlack,
};

const TextAlignClass = {
  [TextAlign.CENTER]: style.alignCenter,
  [TextAlign.LEFT]: style.alignLeft,
  [TextAlign.RIGHT]: style.alignRight,
};

export interface ITextProps {
  children?: React.ReactNode;
  color?: TextColor;
  size?: VisualSize;
  title?: boolean;
  shadow?: boolean;
  align?: TextAlign;
  textStyles?: IStyleConfig;
  titleAttr?: string;
  tag?: typeof React.Component | string;
  weight?: FontWeight;
}

export { VisualSize };

export default class Text extends React.Component<ITextProps, {}> {
  public static defaultProps = {
    size: VisualSize.MEDIUM,
    title: false,
    tag: 'p',
  };

  public render() {
    const { children, size, textStyles, tag, title, titleAttr, color, shadow, align, weight } = this.props;
    // tslint:disable-next-line:no-any
    const Tag: any = tag || 'p';
    const styles = mergeStyles(
      style.text,

      title && style.title,
      shadow && style.shadow,

      size === VisualSize.SMALL && style.sizeSmall,
      size === VisualSize.MEDIUM && style.sizeMedium,
      size === VisualSize.LARGE && style.sizeLarge,

      size === VisualSize.SMALL && title && style.sizeSmallTitle,
      size === VisualSize.MEDIUM && title && style.sizeMediumTitle,
      size === VisualSize.LARGE && title && style.sizeLargeTitle,
      FontWeightClass[weight as FontWeight],
      TextAlignClass[align as TextAlign],
      TextColorClass[color as TextColor],
      textStyles,
    );

    return (
      <Tag {...styles} title={titleAttr}>
        {children}
      </Tag>
    );
  }
}
