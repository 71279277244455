import * as React from 'react';
import { Label } from '@cian/components';
import { FilterState } from '../../../redux/filters/model';
import { shallowEqual } from '../../../utils/react_helpers';
import RangeComponent from '../../common/range/range';
import { IFieldProps, IsVisibleCheck } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {

  public shouldComponentUpdate(nextProps: IFieldProps) {
    const filter: FilterState = this.props.filter;
    const nextFilter: FilterState = nextProps.filter;

    return !shallowEqual(filter.price, nextFilter.price)
      || filter.selectedObjectTypes !== nextFilter.selectedObjectTypes
      || filter.workplace_count !== nextFilter.workplace_count
      || filter.dealType !== nextFilter.dealType;
  }

  public render() {
    return (
      <div className={styles.label} data-mark="FieldWorkplaceCount">
        <Label label="Количество рабочих мест">
          <RangeComponent
            field="workplace_count"
            fromPlaceholder="от"
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => true;
