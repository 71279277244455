import { IGeoTag } from '../redux/filters/model';
import { YMapsCoords } from './ymaps';

export const SEARCH_AREA_GEOTAG_NAME = 'Область поиска';

export const getGeoTagName = (text: string): string => {
  const parts = text.split(',')
    .map(str => str.trim())
    .reverse();

  const firstPart = parts[1];
  const lastPart = parts[0];

  return !isNaN(Number(lastPart))
    ? `${firstPart}, ${lastPart}`
    : lastPart;
};

export const createPolygonGeoTag = (coords: YMapsCoords[]): IGeoTag => ({
  text: SEARCH_AREA_GEOTAG_NAME,
  name: SEARCH_AREA_GEOTAG_NAME,
  kind: 'Polygon',
  geo: coords.map((pair: [number, number]) => pair.slice().reverse().map(String)),
});
