import * as React from 'react';

import * as styles from './styles.css';

export function License() {
  return (
    <div className={styles['license']}>
      <span>
        При подписке вы принимаете условия
      </span>{' '}
      <a className={styles['license-link']} target="_blank" href="//www.cian.ru/help/about/rules/">
        Пользовательского соглашения
      </a>
      {' '}и{' '}
      <a className={styles['license-link']} target="_blank" href="//www.cian.ru/help/about/36526/">
        Политики конфиденциальности
      </a>
    </div>
  );
}
