export interface IPushSubscriptionSetValue {
  type: 'IPushSubscriptionSetValue';
  value: boolean;
}

export interface IPushSubscriptionShowPopup {
  type: 'IPushSubscriptionShowPopup';
}

export type TPushSubscriptionsActions =
  IPushSubscriptionSetValue |
  IPushSubscriptionShowPopup;

export function pushSubscriptionSetValue(value: boolean): IPushSubscriptionSetValue {
  return {
    type: 'IPushSubscriptionSetValue',
    value,
  };
}

export function pushSubscriptionShowPopup(): IPushSubscriptionShowPopup {
  return {
    type: 'IPushSubscriptionShowPopup',
  };
}
