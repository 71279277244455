import { ca } from '@cian/analytics';

export function trackClearButtonClick() {
  ca('event', {
    name: 'oldevent',
    category: 'Mob_refineSearch_popup',
    action: 'clean',
    label: '',
  });
}
