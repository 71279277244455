import * as React from 'react';

import { Label } from '@cian/components';
import ButtonRadioGroupField from '../../../ui/button_radio_group_field/button_radio_group_field';
import { BathroomType, IFieldProps, isFlatObjectGroup, IsVisibleCheck } from '../field';

const fieldStyles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    return this.props.filter.bathroomType !== nextProps.filter.bathroomType;
  }

  public render() {
    const { filter: { bathroomType } } = this.props;

    return (
      <div className={fieldStyles.field} data-mark="FieldBathroomType">
        <Label label="Санузел">
          <ButtonRadioGroupField
            value={bathroomType}
            onValueChange={this.props.actions.setBathroomType}
            uncheckable
            values={[
              { value: BathroomType.Separated, label: 'Раздельный' },
              { value: BathroomType.Combined, label: 'Совмещённый' },
            ]}
            buttonStyle={fieldStyles.bathroomButton}
            activeButtonStyle={fieldStyles.active}
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => isFlatObjectGroup(filter) && !filter.withNewobject;
