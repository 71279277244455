import { CheckboxGroup } from '@cian/nested-checkbox-groups';

import { capitalize } from '../../../../../../../mobile_website/utils/text';
import { ILabelService } from '../ILabelService';

export class LabelService implements ILabelService {
  private _label: string | undefined;
  private _count: number | undefined;
  private readonly defaultLabel: string;
  private readonly allValuesLabel: string;

  public constructor(defaultLabel: string, allValuesLabel: string) {
    this.defaultLabel = defaultLabel;
    this.allValuesLabel = allValuesLabel;
  }

  public calcFields(checkboxValues: Array<number>, checkboxGroups: Array<CheckboxGroup>): this {
    const checkboxValuesCollection = new Set<number>(checkboxValues);
    let label: string;
    let count: number;

    if (!checkboxValuesCollection.size) {
      label = this.defaultLabel;
      count = 0;
    } else {
      const labelList = Array.of<string>();
      let isEveryValuesIncluded = true;

      for (const checkboxGroup of checkboxGroups) {
        for (const item of checkboxGroup.items) {
          if (checkboxValuesCollection.has(item.value)) {
            labelList.push(item.label.toLowerCase());
          } else {
            isEveryValuesIncluded = false;
          }
        }
      }

      if (!labelList.length) {
        label = this.defaultLabel;
        count = 0;
      } else if (isEveryValuesIncluded) {
        label = this.allValuesLabel;
        count = checkboxGroups.reduce((accumulator, group) => accumulator + group.items.length, 0);
      } else {
        label = capitalize(labelList.join(', '));
        count = labelList.length;
      }
    }

    this._label = label;
    this._count = count;

    return this;
  }

  public get label(): string {
    if (this._label == null) {
      throw new Error('Используйте сначала метод calcFields');
    }

    return this._label;
  }

  public get count(): number {
    if (this._count == null) {
      throw new Error('Используйте сначала метод calcFields');
    }

    return this._count;
  }

  public get isDefaultLabel(): boolean {
    return this.label === this.defaultLabel;
  }

  public get isAllValuesLabel(): boolean {
    return this.label === this.allValuesLabel;
  }
}
