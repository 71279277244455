import * as React from 'react';

import { CheckboxField, mergeStyles } from '@cian/components';
import { IFieldProps, isCommonFlatSale, IsVisibleCheck, sellerType, showWithNewobject } from '../field';

const style = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    return this.props.filter.withPik !== nextProps.filter.withPik;
  }

  public render() {
    const { filter: { withPik } } = this.props;
    const labelStyles = mergeStyles(
      style.labelCheckbox,
      withPik && style.checked,
      style['checkbox--with_custom'],
    ).className;

    return (
      <div className={style.checkbox} data-mark="FieldWithPik">
        <CheckboxField
          mobile
          label="Новостройки от ПИК"
          labelStyle={labelStyles}
          value={withPik}
          onValueChange={this.props.actions.setWithPik}
        />
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = (
  filter,
  optionalVisibilityParams,
) => {
  const isNewbuildingMapFilters = Boolean(optionalVisibilityParams && optionalVisibilityParams.isNewbuildingsMapMode);
  const commonFlatSale = isCommonFlatSale(filter);

  return (filter.withNewobject
    && showWithNewobject(filter)
    && filter.selectedSellers.every(seller => seller !== sellerType.Agent)
    || isNewbuildingMapFilters && commonFlatSale)
    && filter.regionSettings != null
    && filter.regionSettings.isPikPromoEnabled;
};
