import { get } from 'lodash';

import {
  ICommercialBuildingInfrastructure,
  IOfferDetail,
} from '../../../api/models/offer_card';
import {
  IOfferIconField,
  IOfferIconListMap,
} from '../../../redux/offer/model';

export const infrastructureMap: IOfferIconListMap = new Map([
  ['hasAutomaticGates', {
    name: 'Автоматические ворота',
    icon: 'card/ic-doors',
  }],
  ['hasCarWash', {
    name: 'Автомойка',
    icon: 'card/ic-auto-pit',
  }],
  ['hasCarService', {
    name: 'Автосервис',
    icon: 'card/ic-auto-pit',
  }],
  ['hasAquapark', {
    name: 'Аквапарк',
    icon: 'card/ic-pool',
  }],
  ['hasPharmacy', {
    name: 'Аптека',
    icon: 'card/ic-hospital',
  }],
  ['hasClothesStudio', {
    name: 'Ателье одежды',
    icon: 'card/ic-clothes',
  }],
  ['hasAtm', {
    name: 'Банкомат',
    icon: 'card/ic-commerce',
  }],
  ['hasPool', {
    name: 'Бассейн',
    icon: 'card/ic-pool',
  }],
  ['hasBabySitting', {
    name: 'Беби-ситинг',
    icon: 'card/ic-kids',
  }],
  ['hasBowling', {
    name: 'Боулинг',
    icon: 'card/ic-bowling',
  }],
  ['hasBuffet', {
    name: 'Буфет',
    icon: 'card/ic-cafe',
  }],
  ['hasVideoSurveillance', {
    name: 'Видеонаблюдение',
    icon: 'card/ic-secure-camera',
  }],
  ['hasEntryByPass', {
    name: 'Въезд по пропускам',
    icon: 'card/ic-propusk',
  }],
  ['hasExhibitionAndWarehouseComplex', {
    name: 'Выставочно-складской комплекс',
    icon: 'card/ic-store',
  }],
  ['hasHotel', {
    name: 'Гостиница',
    icon: 'card/ic-hotel',
  }],
  ['hasGameRoom', {
    name: 'Игровая комната',
    icon: 'card/ic-games',
  }],
  ['hasSlotMachines', {
    name: 'Игровые автоматы',
    icon: 'card/ic-games',
  }],
  ['hasRink', {
    name: 'Каток',
    icon: 'card/ic-cort',
  }],
  ['hasCafe', {
    name: 'Кафе',
    icon: 'card/ic-cafe',
  }],
  ['hasCinema', {
    name: 'Кинотеатр',
    icon: 'card/ic-movies',
  }],
  ['hasConferenceRoom', {
    name: 'Конференц-зал',
    icon: 'card/ic-conf-room',
  }],
  ['hasHourSecurity', {
    name: 'Круглосуточная охрана',
    icon: 'card/ic-24-secure',
  }],
  ['hasMedicalCenter', {
    name: 'Медицинский центр',
    icon: 'card/ic-hospital',
  }],
  ['hasMinimarket', {
    name: 'Минимаркет',
    icon: 'card/ic-shopping-cart',
  }],
  ['hasNotaryOffice', {
    name: 'Нотариальная контора',
    icon: 'card/ic-office',
  }],
  ['hasBankDepartment', {
    name: 'Отделение банка',
    icon: 'card/ic-commerce',
  }],
  ['hasOfficeSpace', {
    name: 'Офисные помещения',
    icon: 'card/ic-office',
  }],
  ['hasPark', {
    name: 'Парк',
    icon: 'card/ic-bench',
  }],
  ['hasBasement', {
    name: 'Подвал/погреб',
    icon: 'card/ic-basement',
  }],
  ['hasRestaurant', {
    name: 'Ресторан',
    icon: 'card/ic-cafe',
  }],
  ['hasBeautyShop', {
    name: 'Салон красоты',
    icon: 'card/ic-spa',
  }],
  ['hasWarehouse', {
    name: 'Складские помещения',
    icon: 'card/ic-stock',
  }],
  ['hasInspectionPit', {
    name: 'Смотровая яма',
    icon: 'card/ic-auto-pit',
  }],
  ['hasCanteen', {
    name: 'Столовая',
    icon: 'card/ic-cafe',
  }],
  ['hasSupermarket', {
    name: 'Супермаркет',
    icon: 'card/ic-shopping-cart',
  }],
  ['hasShoppingArea', {
    name: 'Торговая зона',
    icon: 'card/ic-shopping-cart',
  }],
  ['hasFitnessCentre', {
    name: 'Фитнес-центр',
    icon: 'card/ic-gym',
  }],
  ['hasStudio', {
    name: 'Фотосалон',
    icon: 'card/ic-photo',
  }],
  ['hasFoodCourt', {
    name: 'Фуд-корт',
    icon: 'card/ic-cafe',
  }],
  ['hasCentralReception', {
    name: 'Центральная рецепция',
    icon: 'card/ic-concierge',
  }],
  ['hasTire', {
    name: 'Шиномонтаж',
    icon: 'card/ic-auto-pit',
  }],
]);

export const responseToCommercialInfrastructure = (offer: IOfferDetail): IOfferIconField[] => {
  const infrastructure: ICommercialBuildingInfrastructure = get(
    offer, 'building.infrastructure', {},
  );

  const newState: IOfferIconField[] = [];

  infrastructureMap.forEach((value: { name: string; icon: string; }, key: keyof ICommercialBuildingInfrastructure) => {
    if (infrastructure[key]) {
      newState.push(value);
    }
  });

  return newState;
};
