import { get } from 'lodash';
import { IGKData, IIconField } from '../../../api/models/gk_card';
import { IInfrastructure } from '../../../api/models/gk_preview';

const mapper = (item: IInfrastructure): IIconField => {
  const idToIconMap: { [key: number]: string } = {
    1: 'card/ic-school',
    2: 'card/ic-kindergarten',
    3: 'card/ic-hospital',
    4: 'card/ic-playground',
    5: 'card/ic-sports',
    6: 'card/ic-resort',
    7: 'card/ic-pets',
    8: 'card/ic-shopping-cart',
    9: 'card/ic-cafe',
    10: 'card/ic-gym',
    11: 'card/ic-spa',
    12: 'card/ic-pool',
    13: 'card/ic-washing',
    14: 'card/ic-commerce',
    15: 'card/ic-work',
  };
  return {
    name: item.name,
    icon: idToIconMap[item.id],
  };
};

export const parseInfrastructure = (gk: IGKData) =>
  (get(gk, 'infrastructure', []) as IInfrastructure[]).map(mapper);
