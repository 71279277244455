import * as React from 'react';

import { CheckboxField, mergeStyles } from '@cian/components';
import { IFieldProps, IsVisibleCheck, isFlatObjectGroup, DealType } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const { filter: { withLayout }, viewMode } = this.props;
    const isDesktop = viewMode === 'desktop';

    const labelStyles = mergeStyles(
      styles.labelCheckbox,
      withLayout && styles.checked,
      isDesktop && styles.desktop,
    ).className;

    return (
      <div className={styles.checkbox} data-mark="FieldWithLayout">
        <CheckboxField
          mobile
          label="Схема планировки"
          labelStyle={labelStyles}
          value={withLayout}
          onValueChange={this.props.actions.setWithLayout}
        />
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = (filter) =>
  filter.dealType !== DealType.Rent && isFlatObjectGroup(filter);
