// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  TGetUsersAuctionModel,
  IMappers,
  TGetUsersAuctionResponse,
  IGetUsersAuctionResponse,
  IGetUsersAuctionResponseError,
} from './types';

export const defaultConfig: TGetUsersAuctionModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'commercial-electronic-auction',
  pathApi: '/v1/get-users-auction/',
} as TGetUsersAuctionModel;

export function createGetUsersAuctionModel(): TGetUsersAuctionModel {
  return {
    ...defaultConfig,
  };
}

export interface IGetUsersAuctionOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

export async function fetchGetUsersAuction<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
}: IGetUsersAuctionOptions<TResponse200, TResponse400>): Promise<
  TGetUsersAuctionResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetUsersAuctionModel(), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetUsersAuctionResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetUsersAuctionResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetUsersAuctionResponse;
}
