import { IFeedbackComplaintState, EFeedbackComplaint } from './models';

import { TFeedbackComplaintActions } from './actions';

export const defaultState: IFeedbackComplaintState = {
  status: EFeedbackComplaint.default,
  complaintId: undefined,
  getUserFeedback: true,
};

export function complaintFeedbackReducer(
  state: IFeedbackComplaintState = defaultState,
  action: TFeedbackComplaintActions,
) {
  switch (action.type) {
    /* istanbul ignore next */
    case 'IFeedbackComplaintStateChanger':
      return {
        ...state,
        status: action.status,
      };
    /* istanbul ignore next */
    case 'IFeedbackComplaintStateSetComplaintId':
      return {
        ...state,
        complaintId: action.complaintId,
      };

    case 'IFeedbackComplaintStateSetUserFeedback':
      return {
        ...state,
        getUserFeedback: action.getUserFeedback,
      };

    default:
      return state;
  }
}
