import * as React from 'react';

import { Button, ButtonTheme, mergeStyles } from '@cian/components';
import { BlockArrowItem } from '../../ui/block_list/block_arrow_list';
import { BlockRadioItem } from '../../ui/block_list/block_radio_list';
import { PopupHeaderContainer } from '../../ui/popup_header/popup_header';
import { isWinPhone } from '../../utils/device_detector';
import { BackButton } from '../back_button/back_button';
import { SearchField } from './search_field/search_field';

import { IUserRegion } from '../../redux/common/model';
import Icon from '../../ui/icon/icon';
import { PopupMobile } from '../../ui/popup/popup';
import Preloader from '../../ui/preloader/preloader';
import { createBodyScroll } from '../../utils/body_scroll';
import { sortAndGroupByName } from '../../utils/object_helpers';
import { shallowEqual } from '../../utils/react_helpers';
import { Regions } from '../../utils/regions';
import { renderByGroups } from '../../utils/renderByGroups';
import { scrollContainerToElement } from '../../utils/scroll';
import { AsyncSearchRegionField, IRegionSuggestion } from './async_search_region_field/async_search_region_field';
import {
  getBackButtonTitle,
  getCapitalDetailItems,
  getDynamicProps,
  getFirstCitiesItem,
  getFirstRegions,
  getScreen,
} from './helpers';
import {
  IRegionItem,
  IRegionItemPageData, IRegionItemType, ISelectRegionProps,
  ISelectRegionState, Screen,
} from './model';

const styles = require('./select_region.css');
const blockListStyles = require('../../ui/block_list/block_list.css');

const bodyScroll = createBodyScroll();
const dataMark = 'SelectRegion';

/** Регион это не обязательно регион (область, край), это может быть также и город */
/** Используется в двух местах, имеет внутренние отличия по флагу props.withoutCities. */
export class SelectRegion extends React.Component<ISelectRegionProps, ISelectRegionState> {

  private containerNode: HTMLElement | null = null;

  public shouldComponentUpdate(nextProps: ISelectRegionProps, nextState: ISelectRegionState) {
    return (
      !shallowEqual(this.state, nextState) ||
      !shallowEqual(getDynamicProps(this.props), getDynamicProps(nextProps))
    );
  }

  public constructor(props: ISelectRegionProps) {
    super(props);
    this.state = {
      search: '',
      selectedRegionId: props.selectedRegionId,
      /** id региона, однозначно определяющий отображаемый экран. */
      regionPath: props.meta && props.meta.parentId || props.selectedRegionId,
    };
  }

  public componentWillReceiveProps(nextProps: ISelectRegionProps) {
    const oldParentId = this.props.meta && this.props.meta.parentId;
    const nextParentId = nextProps.meta && nextProps.meta.parentId;

    const regionChanged =
      this.props.selectedRegionId !== nextProps.selectedRegionId
      || this.props.ownSelectedRegionId !== nextProps.ownSelectedRegionId
      || (oldParentId && nextParentId && oldParentId !== nextParentId);
    if (regionChanged) {
      this.setState({
        search: '',
        selectedRegionId: nextProps.ownSelectedRegionId || nextProps.selectedRegionId,
        regionPath: nextProps.meta && nextProps.meta.parentId || nextProps.selectedRegionId,
      });
    }
  }

  public componentWillUpdate(nextProps: ISelectRegionProps, nextState: ISelectRegionState) {
    const prevScreen = this.getScreen(this.state.regionPath);
    const nextScreen = this.getScreen(nextState.regionPath);

    const willOpen = !this.props.opened && nextProps.opened;

    const willSwitchToCities = nextScreen !== prevScreen && nextScreen === Screen.Cities;
    const willOpenOnCities = willOpen && nextScreen === Screen.Cities;

    const needUpdateCities = this.props.regionCities[0]
      ? this.props.regionCities[0].parentId !== nextState.regionPath
      : true;

    if ((willSwitchToCities || willOpenOnCities) && needUpdateCities) {
      this.props.getRegionCities(nextState.regionPath);
    }
  }

  public componentDidUpdate(prevProps: ISelectRegionProps, prevState: ISelectRegionState) {
    const prevScreen = this.getScreen(this.state.regionPath);
    const nextScreen = this.getScreen(prevState.regionPath);

    if (nextScreen !== prevScreen && this.state.search !== '') {
      this.handleSearchClear();
    }
  }

  public render() {
    const { selectedRegionId, regionPath, search } = this.state;
    const { userAgent, isLoading, opened: pageOpened } = this.props;
    const backTitle = getBackButtonTitle(regionPath, this.getScreen(regionPath));

    let content;
    if (isLoading) {
      content =
        <Preloader
          size={32}
          containerStyle={styles.preloader}
        />;
    } else {
      const items = this.getItemsByRegion(regionPath, search);
      content = (
        <div className={styles.contentPadding}>
          {this.getItemsNodes(items, selectedRegionId)}
        </div>
      );
    }

    const searchField = this.getSearchField(search);

    const header =
      <PopupHeaderContainer
        headerStyle={styles.header}
        closeStyle={styles.closeIcon}
        onCloseClick={this.handleCloseClick}
        footer={searchField}
        dataMark={dataMark}
      >
        Выбрать регион
      </PopupHeaderContainer>;

    const footer =
      <div
        {...mergeStyles([
          styles.fixedContainer,
          styles['save_button_footer'],
          isWinPhone(userAgent) && styles.ieFixedContainer,
        ])}
        data-mark={dataMark + '|footer'}>
        <Button
          mobile
          theme={ButtonTheme.ORANGE_SOLID}
          buttonStyle={[styles.saveButton]}
          onClick={this.handleSaveClick}
        >
          Сохранить
        </Button>
      </div>;

    const popupContentStyle = [
      styles.content,
    ];

    return (
      <PopupMobile
        opened={pageOpened}
        containerStyle={styles.popupContainer}
        innerStyle={[styles.popupInner]}
        contentStyle={popupContentStyle}
        header={header}
        footer={footer}
        contentRef={this.containerNodeCb}
        onShow={this.handlePopupShow}
        bodyScroll={bodyScroll}
        onClickOutside={this.handleCloseClick}
        dataMark={dataMark}
      >
        <BackButton
          title={backTitle}
          onClick={this.handleBackClick}
          styleConfig={[styles.backButton]}
          dataMark={dataMark}
        />
        {content
          ? content
          : <div className={styles.noResults}>Ничего не найдено</div>
        }
      </PopupMobile>
    );
  }

  private handlePopupShow = () => {
    const screen = this.getScreen(this.state.regionPath);
    const shouldScrollToSelectedItem =
      this.props.opened &&
      (screen === Screen.Cities || screen === Screen.Regions && this.props.withoutCities);

    if (shouldScrollToSelectedItem) {
      this.scrollToItem(this.state.selectedRegionId);
    }
  }

  private containerNodeCb = (c: HTMLElement | null) => this.containerNode = c;

  private getSearchField = (search: string) => {
    return this.props.withoutCities
    ? <div className={styles.searchFieldNavigateContainer}>
        <SearchField
          onChange={this.handleSearchChange}
          onClear={this.handleSearchClear}
          value={search}
        />
        <div
          className={styles.navigateIcon}
          onClick={this.onNavigateClick}
        >
          <Icon name="navigate" />
        </div>
      </div>
    : <AsyncSearchRegionField
      onSuggestionSelect={this.onAsyncSearchSuggestionSelect}
      dataMark={dataMark + '|AsyncSearchRegionField'}
    />;
  }

  private onAsyncSearchSuggestionSelect = (suggestion: IRegionSuggestion) => {
    const selectedRegionId = suggestion.id;

    this.setState({ selectedRegionId });

    if (!this.props.withoutCities && selectedRegionId === this.props.selectedRegionId) {
      return this.handleCloseClick();
    }

    if (this.props.onSave) {
      const regionData: IUserRegion = {
        name: suggestion.name || '',
        subdomain: '',
        id: selectedRegionId,
        parentId: suggestion.parentId,
      };

      this.props.onSave(regionData);
    }

    this.props.onClose();
  }

  private onNavigateClick = () => {
    const regionId = this.props.detectedRegionId;
    if (regionId) {
      this.setState({ selectedRegionId: regionId }, () => this.scrollToItem(regionId));
    }
  }

  private scrollToItem = (id: number) => {
    if (this.containerNode) {
      const item = this.containerNode.querySelector(`[data-value="${id}"]`) as HTMLElement;

      if (item) {
        scrollContainerToElement(this.containerNode, item, -110);
      }
    }
  }

  private getScreen(regionId?: number) {
    return getScreen(regionId, { withoutCities: this.props.withoutCities });
  }

  private getFirstRegions(type: IRegionItemType) {
    return getFirstRegions(type, this.props.withoutCities);
  }

  private getItemsByRegion = (regionPath: number, search: string): IRegionItemPageData => {
    const screen = this.getScreen(regionPath);
    let first: IRegionItem[] = [];
    let main: IRegionItem[] = [];

    switch (screen) {
      case Screen.Regions:
        const regionItemType = this.props.withoutCities ? 'radio' : 'link';
        first = this.getFirstRegions(regionItemType);
        main = this.props.regions;
        break;

      case Screen.CapitalDetail:
        first = getCapitalDetailItems(regionPath);
        break;

      case Screen.Cities:
        main = this.props.regionCities;
        first = getFirstCitiesItem(regionPath);
        break;

      default:
        break;
    }

    if (search) {
      main = main.filter(this.getSearchCb(search));
      first = first.filter(this.getSearchCb(search));
    }

    return {
      first,
      groups: sortAndGroupByName(main),
    };
  }

  private getItemsNodes(items: IRegionItemPageData, selectedRegion: number): React.ReactNode | null {
    const isFirstItems = items.first.length;
    const isGroupItems = Object.keys(items.groups).length;

    if (!isFirstItems && !isGroupItems) {
      return null;
    }

    const firstNodes = isFirstItems
      ? <div {...mergeStyles(
        styles.firstNodesWrapper,
      )} key="firstNodesWrapper">
          {this.renderRegionItems(items.first, selectedRegion, this.handleRegionItemClick)}
        </div>
      : [];

    const groupNodes = isGroupItems
      ? renderByGroups(
          items.groups,
          group => this.renderRegionItems(group, selectedRegion, this.handleRegionItemClick),
          styles.groupTitle,
          styles.groupTitleContainer,
      )
      : [];

    return [firstNodes, groupNodes];
  }

  private getSearchCb = (search: string) => (item: IRegionItem): boolean =>
    item.name.toLowerCase().includes(search.toLowerCase())

  private renderRegionItems = (
    items: IRegionItem[],
    selectedValue: number,
    onRegionItemClick: (i: IRegionItem) => void,
  ) => {
    return <ul className={blockListStyles.list} key="MixedRegionItems">
      {items.map((item: IRegionItem, index) => {
        if (item.type === 'radio') {
          return (
            <BlockRadioItem
              item={item}
              checked={item.value === selectedValue}
              key={index}
              onItemClick={onRegionItemClick}
              dataMark={dataMark}
            />
          );
        } else {
          return (
            <BlockArrowItem
              key={index}
              onItemClick={onRegionItemClick}
              item={item}
              dataMark={dataMark}
            />
          );
        }
      })}
    </ul>;
  }

  private handleRegionItemClick = (region: IRegionItem) => {
    if (region.type === 'link') {
      this.setState({
        regionPath: region.value,
      });
    }
    if (region.type === 'radio') {
      this.setState({
        selectedRegionId: region.value,
      });
    }
  }

  private handleSearchClear = () => this.setState({ search: ''});

  private handleBackClick = () => {
    const { regionPath } = this.state;
    const screen = this.getScreen(regionPath);
    let nextRegionPath;
    if (screen === Screen.Cities) {
      if (regionPath === Regions.MoscowRegion) {
        nextRegionPath = Regions.Moscow;
      }
      if (regionPath === Regions.LeningradRegion) {
        nextRegionPath = Regions.SaintPetersburg;
      }
    }
    this.setState({
      regionPath: nextRegionPath,
    } as ISelectRegionState);
  }

  private handleSearchChange = (value: string) => {
    this.setState({
      search: value,
    });
  }

  private handleCloseClick = () => {
    this.props.onClose(this.props.routeName === 'map');
    this.setState({
      search: '',
      selectedRegionId: this.props.selectedRegionId,
      regionPath: this.props.meta && this.props.meta.parentId || this.props.selectedRegionId,
    });
  }

  private getRegionById = (id: number) => {
    return [
      ...this.props.regionCities,
      ...this.props.regions,
      ...this.getFirstRegions('radio'),
    ].find(i => i.value === id);
  }

  private handleSaveClick = () => {
    const { selectedRegionId } = this.state;

    if (!this.props.withoutCities && selectedRegionId === this.props.selectedRegionId) {
      return this.handleCloseClick();
    }
    if (this.props.onSave) {
      const region = this.getRegionById(selectedRegionId);
      const regionData: IUserRegion = {
        name: region ? region.name : '',
        subdomain: region && region.subdomain ? region.subdomain : '',
        id: selectedRegionId,
        parentId: region ? region.parentId : undefined,
      };
      this.props.onSave(regionData);
    }

    this.props.onClose();
  }

}
