import { IGetGKRequest } from '../../api/api';
import { IGetGKResponseData } from '../../api/models/gk_card';
import { IGKMVKDataResponse } from '../../components/gk_flat_selector_widget/typings';
import { isServer } from '../../utils/isomorphic';
import { toPromise } from '../../utils/streams';
import { TTypedThunkAction } from '../model';

export const GET_MORTGAGE_CALCULATOR_WIDGET_PENDING = 'GET_MORTGAGE_CALCULATOR_WIDGET_PENDING';
export const GET_MORTGAGE_CALCULATOR_WIDGET_SUCCESS = 'GET_MORTGAGE_CALCULATOR_WIDGET_SUCCESS';
export const GET_MORTGAGE_CALCULATOR_WIDGET_FAILURE = 'GET_MORTGAGE_CALCULATOR_WIDGET_FAILURE';

export type TGKPageActions =
  | { type: 'GetGKPending' }
  | { type: 'GetGKFailure' }
  | { type: 'GetGKSuccess'; payload: IGetGKResponseData }
  | { type: 'GET_MORTGAGE_CALCULATOR_WIDGET_PENDING' }
  | { type: 'GET_MORTGAGE_CALCULATOR_WIDGET_FAILURE' }
  | { type: 'GET_MORTGAGE_CALCULATOR_WIDGET_SUCCESS'; payload: string }
  | { type: 'GetGKMVKPending' }
  | { type: 'GetGKMVKFailure' }
  | { type: 'GetGKMVKSuccess'; payload: IGKMVKDataResponse }
  | { type: 'ChangeGkFavoriteStatus'; payload: { state: boolean } };

export function getGK(body: IGetGKRequest): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    const state = getState();

    if (body._path === state.GKPage.GK.url) {
      return Promise.resolve();
    }

    if (!state.routing.locationBeforeTransitions.query['gallery']) {
      dispatch<TGKPageActions>({ type: 'GetGKPending' });
    }

    return toPromise(api.getGK(body))
      .then(resp => {
        const { data, status } = resp.result;

        if (status && (status.toLowerCase() === 'ok' || String(status) === '400')) {
          dispatch<TGKPageActions>({ type: 'GetGKSuccess', payload: data });
        } else {
          dispatch<TGKPageActions>({ type: 'GetGKFailure' });

          throw new Error(`Wrong response: ${status}`);
        }
      })
      .catch(err => {
        logger.error(err);

        throw err;
      });
  };
}

export function getMortgageCalculatorWidget(): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    const { mortgageCalculatorWidget } = getState().GKPage;
    if (!mortgageCalculatorWidget && isServer) {
      const { priceSummary = {} } = getState().GKPage.GK;
      dispatch<TGKPageActions>({ type: GET_MORTGAGE_CALCULATOR_WIDGET_PENDING });

      return toPromise(
        api.getMortgageCalculatorWidget({
          price_from: priceSummary.min,
        }),
      )
        .then(resp => {
          dispatch<TGKPageActions>({ type: GET_MORTGAGE_CALCULATOR_WIDGET_SUCCESS, payload: resp.result as string });
        })
        .catch(err => {
          dispatch<TGKPageActions>({ type: GET_MORTGAGE_CALCULATOR_WIDGET_FAILURE });
          logger.error(err);
        });
    }

    return Promise.resolve();
  };
}
