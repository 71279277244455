import * as React from 'react';

/**
 * @deprecated
 */
export const IconLikeSearch: React.FC = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        clipRule="evenodd"
        d="M2.07697 7.96065C2.02627 8.29984 2 8.64691 2 9.00001C2 12.86 5.14 16 9
        16C12.86 16 16 12.86 16 9.00001C16 6.48995 14.6722 4.28436 12.6821 3.04865C13.0732
        3.89524 13.1035 4.8573 12.7726 5.72204C13.5368 6.60037 14 7.74711 14 9.00001C14 11.757
        11.757 14 9 14C6.54689 14 4.50072 12.2242 4.07944 9.89064L2.07697 7.96065Z"
        fill="#737A8E"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.2737 2.64334C9.29837 1.78555 7.71584 1.78555 6.74049 2.64334L6.48857
        2.84784L6.25604 2.64334C5.28068 1.78555 3.69815 1.78555 2.7228 2.64334C1.75907
        3.52384 1.75907 4.9156 2.7228 5.7961L6.23666 8.90911C6.2935 8.9574 6.36585
        8.98864 6.44336 9H6.48857C6.59063 8.99886 6.68881 8.96194 6.75986 8.89775L10.2802
        5.7961C11.2426 4.91447 11.2394 3.52157 10.2737 2.64334Z"
        fill="#737A8E"
        fillRule="evenodd"
      />
      <path
        d="M17.707 16.293L15.314 13.9C14.903 14.429 14.429 14.903 13.9 15.314L16.293
        17.707C16.488 17.902 16.744 18 17 18C17.256 18 17.512 17.902 17.707 17.707C18.098
        17.316 18.098 16.684 17.707 16.293Z"
        fill="#737A8E"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect fill="white" height="16" width="16" x="2" y="2" />
      </clipPath>
    </defs>
  </svg>
);
