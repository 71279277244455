declare const window: Window & {
  ymaps: IYmaps;
  ymapsLoaded(callback: Function): void;
};

import { flatten } from 'lodash';

let ymapsPromise: Promise<IYmaps> | undefined;

export enum Direction {
  Left,
  Right,
  Up,
  Down,
}

export interface IYmapsSuggestion {
  displayName: string;
  hl: [number, number][];
  type: string;
  value: string;
}

const MAX_YMAPS_ZOOM = 17;

export interface IYmaps {
  GeoObject: any; //tslint:disable-line: no-any
  Map: new (id: string, state: IYmapsMapConstructorState, options?: IYmapsMapConstructorOptions) => IYmapsMap;
  ObjectManager: any; //tslint:disable-line: no-any
  Placemark: any; //tslint:disable-line: no-any
  Polygon: any; //tslint:disable-line: no-any
  geoQuery: any; //tslint:disable-line: no-any
  panorama: any; //tslint:disable-line: no-any
  templateLayoutFactory: any; //tslint:disable-line: no-any
  traffic: any; //tslint:disable-line: no-any
  shape: any; //tslint:disable-line: no-any
  geometry: any; //tslint:disable-line: no-any
  util?: IYmapsUtil;
  modules: any; //tslint:disable-line:no-any
  PointsModule: any; //tslint:disable-line:no-any
  load(modules: string[], cb: (ymaps: IYmaps) => void, errback: (err: Error) => void): void;
  ready(cb: () => void): void;
  suggest(query: string, options?: IYmapsSuggestOptions): Promise<IYmapsSuggestion[]>;
}

export interface IYmapsMapConstructorState {
  center: YMapsCoords;
  margin?: number[][] | number[];
  zoom: number;
  maxZoom?: number;
}

//tslint:disable-next-line:no-any
export type TYmapsProvide = (moduleClass: any) => void;

export interface IYmapsUtil {
  hd: IHD;
}

export interface IHD {
  getPixelRatio(): number;
}

export interface IYmapsMapConstructorOptions {
  // чинит баг с перерисовкой карты на ios после скрытия нативных панелей браузера
  // https://tech.yandex.ru/maps/doc/jsapi/2.0/ref/reference/Map-docpage/#param-options.autoFitToViewport
  autoFitToViewport?: 'always';
}

export interface IYmapsMap {
  //tslint:disable-next-line:no-any
  events: any;
  //tslint:disable-next-line:no-any
  geoObjects: any;
  //tslint:disable-next-line:no-any
  options: any;
  //tslint:disable-next-line:no-any
  converter: any;
  container: {
    getSize: () => [number, number],
  };
  setBounds: (bounds: YMapsBoundedBox, options?: IYmapsMapSetBoundsOptions) => void;
  getBounds: () => YMapsBoundedBox;
  getZoom: () => number;
  setZoom(zooom: number): void;
  destroy(): void;
}

export interface IYmapsUtil {
  bounds: IYmapsUtilBounds;
}

export interface IYmapsMapSetBoundsOptions {
  //tslint:disable-next-line:no-any
  callback?: (err?: any) => void;
  checkZoomRange?: boolean;
}

export interface IYmapsUtilBounds {
  /** Вычисляет центр и уровень масштабирования, которые необходимо установить карте для того,
   *  чтобы полностью отобразить переданную область. */
  getCenterAndZoom: (bounds: YMapsBoundedBox, containerSize: number[]) => MapCenterAndZoom;
  /** Вычисляет минимальную прямоугольную область, в которую попадают все переданные точки. */
  fromPoints: (points: YMapsCoords[]) => YMapsBoundedBox;
}

export type MapCenterAndZoom = {
  center: YMapsCoords;
  zoom: number;
};

export interface IYmapsSuggestOptions {
  boundedBy?: YMapsBoundedBox;
  results?: number;
  strictBounds?: boolean;
}

function insertScriptToLoadYmaps(): Promise<IYmaps> {
  return new Promise((resolve, reject) => {
    if (window.ymaps) {
      window.ymaps.ready(() => {
        resolve(window.ymaps);
      });

      return;
    }

    window.ymapsLoaded(() => {
      window.ymaps.ready(() => {
        resolve(window.ymaps);
      });
    });
  });
}

function tryToLoadYmapsApi(modules: string[]): Promise<IYmaps> {
  if (!ymapsPromise) {
    ymapsPromise = insertScriptToLoadYmaps();
  }

  return ymapsPromise
    .then(ymaps => {
      return new Promise<IYmaps>((resolve, reject) => {
        ymaps.load(modules, () => {
          resolve(ymaps);
        }, reject);
      });
    });
}

export function loadYmapsApi(modules: string[]): Promise<IYmaps> {
  return tryToLoadYmapsApi(modules)
    .catch(() => {
      ymapsPromise = undefined;
      return tryToLoadYmapsApi(modules);
    });
}

export type YMapsCoords = [number, number];
export type Vector = [YMapsCoords, YMapsCoords];
export type YMapsBoundedBox = Vector;

export type CoordsString = [string, string];
export type BoundedBoxString = [CoordsString, CoordsString];

export function arrCoordsToLatLng([lng, lat]: [number, number]) {
  return { lat, lng };
}

export function latLngToArrCoords(lng: number, lat: number) {
  return [lng, lat];
}

export function getDistanceBetweenTwoPoints(point1: YMapsCoords, point2: YMapsCoords) {
  const deltaLng = Math.abs(point1[0] - point2[0]);
  const deltaLat = Math.abs(point1[1] - point2[1]);

  return Math.sqrt(deltaLng ** 2 + deltaLat ** 2);
}

export function getClosestPoint(point: YMapsCoords, points: YMapsCoords[] = []): YMapsCoords {
  let closest = points[0];
  let minimal = Number.POSITIVE_INFINITY;

  points.forEach(p => {
    if (getDistanceBetweenTwoPoints(p, point) < minimal) {
      closest = p;
    }
  });

  return closest;
}

export function toFixedCoords(coords: YMapsCoords, toSign: number): YMapsCoords {
  return [
    Number(coords[0].toFixed(toSign)),
    Number(coords[1].toFixed(toSign)),
  ];
}

/** Нашем бэку нужно передавать [lat, lng] a не [lng, lat] */
export function reverseBboxCoords(bbox: YMapsBoundedBox): YMapsBoundedBox {
  return bbox.map(pair => pair.reverse()) as YMapsBoundedBox;
}

export function lineToPolygon(line: YMapsCoords[]): YMapsCoords[] {
  const [x1, y1] = line[0];
  const [x2, y2] = line[line.length - 1];

  return [[x1, y1], [x1, y2], [x2, y2], [x2, y1], [x1, y1]];
}

export function dotToPolygon(dot: YMapsCoords, offset: number): YMapsCoords[] {
  const [x1, y1] = dot;

  return [
    [x1 - offset, y1 - offset],
    [x1 - offset, y1 + offset],
    [x1 + offset, y1 + offset],
    [x1 + offset, y1 - offset],
  ];
}

export function getCianPolygonFromBBox(bbox: YMapsBoundedBox): Array<Array<string>> {
  return lineToPolygon(bbox).map(pair => pair.map(String));
}

export function getBBoxFromRectanglePolygon(polygon: Array<Array<string>>): YMapsBoundedBox {
  const x1 = polygon[0][0];
  const y1 = polygon[0][1];
  const x2 = polygon[2][0];
  const y2 = polygon[2][1];

  return reverseBboxCoords([[+x1, +y1], [+x2, +y2]]);
}

export function getBBoxCenter([[x1, y1], [x2, y2]]: YMapsBoundedBox): YMapsCoords {
  return [(x2 + x1) / 2, (y2 + y1) / 2];
}

export function checkBoxContainsPoint(point: YMapsCoords, box: YMapsBoundedBox): boolean {
  // для системы координат сверху вниз.
  const [pointX, pointY] = point;
  const [[minX, maxY], [maxX, minY]] = box;

  return pointX >= minX && pointX <= maxX
    && pointY >= minY && pointY <= maxY;
}

export function checkBoxesIntersect(box1: YMapsBoundedBox, box2: YMapsBoundedBox) {
  // Пересекаются ли два прямоугольника. Полагается, что ось Y возрастает вверх.
  const [[ax, ay], [bx, by]] = box1;
  const [[cx, cy], [dx, dy]] = box2;
  return !(cx > bx || dy < ay || dx < ax || cy > by);
}

type Polygon = Array<YMapsCoords>;
export function getPolygonBounds(polygon: Polygon): YMapsBoundedBox {
  const left = Math.min(...polygon.map(point => point[0]));
  const right = Math.max(...polygon.map(point => point[0]));
  const top = Math.max(...polygon.map(point => point[1]));
  const bottom = Math.min(...polygon.map(point => point[1]));

  return [
    [bottom, left],
    [top, right],
  ];
}

export function checkGeoBoxContainsPoint(point: YMapsCoords, box: YMapsBoundedBox): boolean {
  // для системы координат снизу вверх.
  const [pointX, pointY] = point;
  const [[minX, minY], [maxX, maxY]] = box;

  return pointX >= minX && pointX <= maxX
    && pointY >= minY && pointY <= maxY;
}

export function bboxToQueryStringParam(bbox: YMapsBoundedBox): string {
  return flatten(bbox).join(',');
}

//tslint:disable-next-line:no-any
export function geoCoordsToPixels(map: any, geoCoords: YMapsCoords): [number, number] {
  return map.converter.globalToPage(
    map.options.get('projection').toGlobalPixels(
      geoCoords,
      map.getZoom(),
    ),
  );
}

//tslint:disable-next-line:no-any
function getProjection(map: any): any {
  const projection = map.options.get('projection');
  if (!projection) {
    throw new Error('Could not get projection for map');
  }
  return projection;
}

export function geoCoordsToGlobalPixels(map: IYmapsMap, geoCoords: YMapsCoords): [number, number] {
  return getProjection(map).toGlobalPixels(
    geoCoords,
    map.getZoom(),
  );
}

export function pixelsToGeoCoords(map: IYmapsMap, pixelCoords: [number, number]): YMapsCoords {
  return getProjection(map).fromGlobalPixels(
    map.converter.pageToGlobal(pixelCoords),
    map.getZoom(),
  );
}

export function globalPixelsToGeoCoords(map: IYmapsMap, globalPixelCoords: [number, number]): YMapsCoords {
  return map.options.get('projection').fromGlobalPixels(
    globalPixelCoords,
    map.getZoom(),
  );
}

/**
 * Очень приблизительно определения направления двух векторов, этого должно быть достаточно, чтобы понять
 * передана нам линия, или нет
 */
export function sameDirection(vector1: Vector, vector2: Vector): boolean {
  const [[v1x1, v1y1], [v1x2, v1y2]] = vector1;
  const [[v2x1, v2y1], [v2x2, v2y2]] = vector2;

  const direction1 = [
    v1x1 <= v1x2 ? Direction.Right : Direction.Left,
    v1y1 <= v1y2 ? Direction.Up : Direction.Down,
  ];

  const direction2 = [
    v2x1 <= v2x2 ? Direction.Right : Direction.Left,
    v2y1 <= v2y2 ? Direction.Up : Direction.Down,
  ];

  return direction1[0] === direction2[0] && direction1[1] === direction2[1];
}

// проверяет имеет ли начало линии и ее конце общее направление
export function isLine(path: YMapsCoords[], threshold: number = 0): boolean {
  if (path.length === 1) {
    return false;
  } else if (path.length === 2) {
    return true;
  } else if (path.length > 2) {
    const start = path.slice(0, 2) as Vector;
    const end = path.slice(path.length - 2) as Vector;

    if (!sameDirection(start, end)) {
      return false;
    }

    // проверяем, что все точки лежат на одной прямой
    const [x1, y1] = path[0];
    const [x2, y2] = path[path.length - 1];

    const restPoints = path.slice(1, path.length - 2);

    // Непонятный код, который ничего не делал
    // Раньше тут не было return
    return restPoints.filter(point => {
      const [xn, yn] = point;

      return (xn - x1) / (x2 - x1) === (yn - y1) / (y2 - y1);
    }).length === restPoints.length;
  }

  return false;
}

export function limitZoom({ center, zoom }: MapCenterAndZoom): MapCenterAndZoom {
  return {
    center,
    zoom: Math.min(zoom, MAX_YMAPS_ZOOM),
  };
}

/** Увеличить прямоугольную убласть в (2k+1)^2 раз. */
export function enlargeBounds(bounds: YMapsBoundedBox, k: number): YMapsBoundedBox {
  const [[x1, y1], [x2, y2]] = bounds;
  const dx = (x2 - x1) * k;
  const dy = (y2 - y1) * k;

  return [[x1 - dx, y1 - dy], [x2 + dx, y2 + dy]];
}

export function isSingleDot(bounds?: YMapsBoundedBox): boolean {
  if (bounds == null) {
    return false;
  }

  const [[x1, y1], [x2, y2]] = bounds;

  return x1 === x2 && y1 === y2;
}
