import { IStyleConfig, mergeStyles } from '@cian/utils';
import * as React from 'react';

interface IIconProps {
  styleConfig?: IStyleConfig;
  name: string;
  title?: string;
  dataMark?: string;
}

interface IIconState {
  svgContent?: string;
  name?: string;
}

export default class Icon extends React.PureComponent<IIconProps, IIconState> {
  public state: IIconState = {};

  public static getDerivedStateFromProps(props: IIconProps, prevState: IIconState) {
    if (prevState && prevState.name === props.name) {
      return null;
    }

    return {
      svgContent: require(`./icons/${props.name}.svg?raw`),
      name: props.name,
    };
  }

  public render() {
    if (!this.state.svgContent) {
      return null;
    }

    return (
      <span
        {...mergeStyles(this.props.styleConfig)}
        dangerouslySetInnerHTML={{ __html: this.state.svgContent }}
        title={this.props.title}
        data-mark={this.props.dataMark}
      />
    );
  }
}
