import * as React from 'react';

import { Label, mergeStyles } from '@cian/components';
import ButtonRadioGroupField from '../../../ui/button_radio_group_field/button_radio_group_field';
import { IFieldProps, IsVisibleCheck, isApartamentObjectGroup } from '../field';

const styles = require('../field.css');

export enum EApartmentValues {
  apartment = 1,
  onlyFlat = 2,
}

export class Component extends React.Component<IFieldProps, {}> {
  public render() {
    const { filter: { onlyFlat, apartment }, specialStyles } = this.props;

    return (
      <div {...mergeStyles(
          styles.field,
          specialStyles,
        )}
        data-mark="FieldApartments"
      >
        <Label label="Статус помещения">
          <ButtonRadioGroupField
            uncheckable
            value={this.getValue(onlyFlat, apartment)}
            onValueChange={this.handleValueChange}
            values={[
              { value: EApartmentValues.onlyFlat, label: 'Без апартаментов' },
              { value: EApartmentValues.apartment, label: 'Апартаменты' },
            ]}
            buttonStyle={styles.apartmentButton}
            activeButtonStyle={styles.active}
          />
        </Label>
      </div>
    );
  }

  private getValue = (onlyFlat: boolean | undefined, apartment: boolean | undefined) => {
    if (onlyFlat) {
      return EApartmentValues.onlyFlat;
    }

    if (apartment) {
      return EApartmentValues.apartment;
    }

    return undefined;
  }

  private handleValueChange = (value: EApartmentValues) => {
    if (value === EApartmentValues.apartment) {
      this.props.actions.setOnlyFlat(undefined);
      this.props.actions.setApartment(true);

      return;
    }

    if (value === EApartmentValues.onlyFlat) {
      this.props.actions.setApartment(undefined);
      this.props.actions.setOnlyFlat(true);

      return;
    }

    this.props.actions.setApartment(undefined);
    this.props.actions.setOnlyFlat(undefined);
  }
}

export const isVisible: IsVisibleCheck = filter => isApartamentObjectGroup(filter);
