import { ISelectMobileOption, Label } from '@cian/components';
import * as React from 'react';

import { DealType, flatObjectType } from '../../../redux/filters/model';
import { SelectMobile } from '../../common/select/select';
import { IFieldProps, IsVisibleCheck } from '../field';

const fieldStyles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const countsList: ISelectMobileOption[] = [1, 2, 3, 4, 5].map(i => ({
      id: i.toString(),
      label: i.toString(),
    }));

    countsList.unshift({
      id: '',
      label: 'Неважно',
    });

    const currentValue = this.props.filter.rentRoomsCount != null
      ? this.props.filter.rentRoomsCount.toString()
      : '';

    return (
      <div className={fieldStyles.field} data-mark="FieldRentRoomsCount">
        <Label label="Комнат в аренду">
          <SelectMobile
            onValueChange={this.onValueSelect}
            options={countsList}
            label="Неважно"
            value={[currentValue]}
          >
          </SelectMobile>
        </Label>
      </div>
    );
  }

  private onValueSelect = (options: string[]) => {
    const selectedValue = Number(options[0]);
    this.props.actions.setSelectedRentRoomsCount(selectedValue);
  }
}

export const isVisible: IsVisibleCheck = filter => {
  return filter.dealType === DealType.Rent
    && (filter.selectedObjectTypes.includes(flatObjectType.Room)
     || filter.selectedObjectTypes.includes(flatObjectType.Bed));
};
