/* eslint-disable max-lines */
import { ca } from '@cian/analytics';
import { EActiveView, ESocialType, ETwoFactorType } from '@cian/authentication-widget';

export type TAuthenticateType = ESocialType | 'email' | 'sms';

const EVENT_TWO_FACTOR_MAPPER = {
  [ETwoFactorType.Phone]: 'phone_',
  [ETwoFactorType.Email]: 'email_',
};

const VIEW_TO_LABEL_MAPPING: { [key in EActiveView]: string } = {
  [EActiveView.AccountSelect]: 'accountSelect',
  [EActiveView.AccountSelectError]: 'accountSelectError',
  [EActiveView.Agreement]: 'agreement',
  [EActiveView.AuthenticationRegistration]: 'authenticationRegistration',
  [EActiveView.ConfirmPhone]: 'confirmPhone',
  [EActiveView.ConfirmSelectedAccount]: 'confirmSelectedAccount',
  [EActiveView.EmailSent]: 'emailSent',
  [EActiveView.PhoneAuth]: 'phoneAuth',
  [EActiveView.PhoneAuthValidateSms]: 'phoneAuthValidateSms',
  [EActiveView.Restore]: 'restore',
  [EActiveView.SocialCreate]: 'socialCreate',
  [EActiveView.SocialMerge]: 'socialMerge',
  [EActiveView.TokenExpiredError]: 'tokenExpiredError',
  [EActiveView.TwoFactorWithPhone]: 'twoFactorWithPhone',
  [EActiveView.TwoFactorPhoneValidateSms]: 'twoFactorPhoneValidateSms',
  [EActiveView.TwoFactorPhoneValidateCall]: 'twoFactorPhoneValidateCall',
  [EActiveView.TwoFactorAttemptsExhausted]: 'twoFactorAttemptsExhausted',
  [EActiveView.TwoFactorWithEmail]: 'twoFactorWithEmail',
  [EActiveView.PhoneAuthValidateCall]: 'phoneAuthValidateCall',
  [EActiveView.RememberedAccountsSelect]: 'latestAccountSelect',
  [EActiveView.EnterPhoneNumber]: 'enterPhoneNumber',
};

/**
 * Успешная авторизация
 */
export function trackAuthenticateSuccess(socialType?: TAuthenticateType) {
  ca('event', {
    name: 'oldevent',
    category: 'Authorization',
    action: 'success',
    label: socialType || '',
  });
}

/**
 * Клик на кнопку "забыли пароль" при авторизации
 */
export function trackForgotPasswordClick() {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'forgot_password_click',
    label: '',
  });
}

/**
 * Клик на кнопку "забыли пароль" при мердже
 */
export function trackForgotPasswordClickMerge() {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'link_accounts_forgot_password',
    label: '',
  });
}

/**
 * Успешная отправка формы "забыли пароль"
 */
export function trackForgotPasswordSubmitSuccess() {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'get_password',
    label: '',
  });
}

/**
 * Успешная регистрация
 */
export function trackRegistrationSuccess(socialType?: TAuthenticateType) {
  ca('event', {
    name: 'oldevent',
    category: 'Registration',
    action: 'success',
    label: socialType || '',
  });
}

/**
 * Подписка на новости
 */
export function trackWantsSubscription() {
  ca('event', {
    name: 'oldevent',
    category: 'Subscription',
    action: 'send',
    label: 'news',
  });
}

/**
 * Показ попапа "Отправлено письмо"
 */
export function trackRegistrationDonePopup() {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'verify_email_popup_show',
  });
}

/**
 * Закрытие попапа "Отправлено письмо"
 */
export function trackRegistrationDonePopupClose() {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'verify_email_popup_got_it',
  });
}

/**
 * Открытие попапа двухфакторной авторизации
 */
export function trackTwoFactorPopupOpen(type: ETwoFactorType | undefined) {
  ca('event', {
    name: 'oldevent',
    category: 'Authorization',
    action: 'Open',
    label: `${type ? EVENT_TWO_FACTOR_MAPPER[type] : ''}reg_page`,
  });
}

/**
 * Открытие попапа двухфакторной авторизации
 */
export function trackTwoFactorClose(type: ETwoFactorType | undefined) {
  ca('event', {
    name: 'oldevent',
    category: 'Authorization',
    action: 'Click',
    label: `${type ? EVENT_TWO_FACTOR_MAPPER[type] : ''}close`,
  });
}

/**
 * Открытие попапа после нескольких попыток ввода неправильного кода
 */
export function trackTwoFactorAttemptsExhaustedPopupOpen(type: ETwoFactorType | undefined) {
  ca('event', {
    name: 'oldevent',
    category: 'Authorization',
    action: 'Fail',
    label: `${type ? EVENT_TWO_FACTOR_MAPPER[type] : ''}fatal`,
  });
}

export function trackTwoFactorInvalid(type: ETwoFactorType | undefined) {
  ca('event', {
    name: 'oldevent',
    category: 'Authorization',
    action: 'Fail',
    label: `${type ? EVENT_TWO_FACTOR_MAPPER[type] : ''}simple`,
  });
}

export function trackTwoFactorWhatForClick(type: ETwoFactorType | undefined) {
  ca('event', {
    name: 'oldevent',
    category: 'Authorization',
    action: 'Click',
    label: `${type ? EVENT_TWO_FACTOR_MAPPER[type] : ''}what_for`,
  });
}

export function trackTwoFactorContinueClick(type: ETwoFactorType | undefined) {
  ca('event', {
    name: 'oldevent',
    category: 'Authorization',
    action: 'Click',
    label: `${type ? EVENT_TWO_FACTOR_MAPPER[type] : ''}continue`,
  });
}

export function trackTwoFactorResendClick(type: ETwoFactorType | undefined) {
  ca('event', {
    name: 'oldevent',
    category: 'Authorization',
    action: 'Click',
    label: `${type ? EVENT_TWO_FACTOR_MAPPER[type] : ''}send_again`,
  });
}

export function trackTwoFactorCannotConfirmClick(type: ETwoFactorType | undefined) {
  ca('event', {
    name: 'oldevent',
    category: 'Authorization',
    action: 'Click',
    label: `${type ? EVENT_TWO_FACTOR_MAPPER[type] : ''}cant_confirm`,
  });
}

export function trackTwoFactorAttemptsExhaustedSupportClick(type: ETwoFactorType | undefined) {
  ca('event', {
    name: 'oldevent',
    category: 'Authorization',
    action: 'Click',
    label: `${type ? EVENT_TWO_FACTOR_MAPPER[type] : ''}write_to_support`,
  });
}

export function trackSocialButtonClick(type: ESocialType) {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'click',
    label: type,
  });
}

export function trackSocialAuthenticateEmailTaken() {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'link_accounts_show',
  });
}

export function trackSocialMergeMergeClick() {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'link_accounts_click',
  });
}

export function trackSocialMergeSucceed() {
  ca('event', {
    name: 'oldevent',
    category: 'Authorization',
    action: 'link_accounts_success',
  });
}

export function trackConfirmPhoneShown() {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'verify_number_popup_show',
  });
}

export function trackConfirmPhoneConfirmClick() {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'verify_number_popup_click',
  });
}

export function trackConfirmPhoneResendClick() {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'verify_number_popup_retry',
  });
}

export function trackConfirmPhoneSucceed() {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'verify_number_popup_success',
  });
}

export function trackSocialCreateShow() {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'complete_reg_popup_show',
  });
}

export function trackSocialCreateCreateClick() {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'complete_reg_popup_click',
  });
}

export function trackClose(view: EActiveView) {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'close',
    label: VIEW_TO_LABEL_MAPPING[view] || '',
  });
}

export function trackShow() {
  ca('event', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'show',
  });
}
