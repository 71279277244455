import {
  EBuildingStatus,
  ECategoryType,
  EObjectType,
  EOfficeType,
  ERentTime,
  ERoomType,
} from '@cian/api-models/common/json_query';
import { IJsonQuery } from '../../api/models/json_query';

import { FDealType, FOfferType } from './typings';

export const getDealTypeFromJsonQuery = (jsonQuery: IJsonQuery): FDealType => {
  const type = jsonQuery._type;
  const rentTime = jsonQuery.for_day && jsonQuery.for_day.value;

  if (type.endsWith('sale')) {
    return FDealType.Sale;
  }

  if (type.endsWith('rent')) {
    if (rentTime === ERentTime.NotDaily) {
      return FDealType.RentLongterm;
    } else if (rentTime === ERentTime.Daily) {
      return FDealType.RentDaily;
    }

    return FDealType.Rent;
  }

  return FDealType.Unexpected;
};

export const getOfferTypeFromJsonQuery = (jsonQuery: IJsonQuery): FOfferType => {
  const type = jsonQuery._type;
  const dealType = getDealTypeFromJsonQuery(jsonQuery);
  const room = jsonQuery.room && jsonQuery.room.value;
  const buildingStatus = jsonQuery.building_status && jsonQuery.building_status.value;
  const fromDeveloper = jsonQuery.from_developer && jsonQuery.from_developer.value;
  const withNewbuildings = jsonQuery.with_newobject && jsonQuery.with_newobject.value;

  if (type.startsWith('flat')) {
    if (room) {
      if (room.includes(ERoomType.FlatShared)) {
        return FOfferType.FlatShared;
      } else if (room.includes(ERoomType.Room)) {
        return FOfferType.Room;
      } else if (room.includes(ERoomType.Bed)) {
        return FOfferType.Bed;
      }
    }

    if (buildingStatus && buildingStatus === EBuildingStatus.New) {
      return FOfferType.FlatNew;
    }

    if (fromDeveloper !== undefined) {
      return FOfferType.FlatNew;
    }

    if (withNewbuildings) {
      return FOfferType.FlatNew;
    }

    if (buildingStatus && buildingStatus === EBuildingStatus.Old) {
      return FOfferType.FlatOld;
    }

    if (withNewbuildings !== undefined) {
      return FOfferType.FlatOld;
    }

    if (dealType & FDealType.Rent) {
      return FOfferType.FlatOld;
    }

    if (type === 'flatsale') {
      return FOfferType.Flat;
    }

    return FOfferType.Urban;
  }

  if (type.startsWith('suburban')) {
    const objectType = jsonQuery.object_type && jsonQuery.object_type.value;

    if (objectType) {
      if (objectType.length > 1) {
        let offerType = FOfferType.Unexpected;
        if (objectType.includes(EObjectType.House)) {
          offerType |= FOfferType.House;
        }
        if (objectType.includes(EObjectType.Housepart)) {
          offerType |= FOfferType.HousePart;
        }
        if (objectType.includes(EObjectType.Townhouse)) {
          offerType |= FOfferType.Townhouse;
        }
        if (objectType.includes(EObjectType.Land)) {
          offerType |= FOfferType.Land;
        }

        return offerType;
      } else if (objectType.includes(EObjectType.House)) {
        return FOfferType.House;
      } else if (objectType.includes(EObjectType.Housepart)) {
        return FOfferType.HousePart;
      } else if (objectType.includes(EObjectType.Townhouse)) {
        return FOfferType.Townhouse;
      }
    }

    if (objectType && objectType.includes(EObjectType.Land)) {
      if ((dealType & FDealType.Rent) === 0) {
        return FOfferType.Land;
      } else {
        return FOfferType.CommercialLand;
      }
    }

    return FOfferType.Suburban;
  }

  if (type.startsWith('commercial')) {
    const officeType = jsonQuery.office_type && jsonQuery.office_type.value;
    const coworkingOfferType = jsonQuery.coworking_offer_type && jsonQuery.coworking_offer_type.value;
    const isCoworking = coworkingOfferType && coworkingOfferType.length > 0;
    const category: string | undefined = jsonQuery.category && jsonQuery.category.value[0];

    if (officeType) {
      let offerType = FOfferType.Unexpected;
      if (officeType.includes(EOfficeType.Office) && !isCoworking) {
        offerType |= FOfferType.Office;
      }
      if (officeType.includes(EOfficeType.TradeArea)) {
        offerType |= FOfferType.TradeArea;
      }
      if (officeType.includes(EOfficeType.Warehouse)) {
        offerType |= FOfferType.Warehouse;
      }
      if (officeType.includes(EOfficeType.FreeAppointmentObject)) {
        offerType |= FOfferType.FreeAppointmentObject;
      }
      if (officeType.includes(EOfficeType.PublicCatering)) {
        offerType |= FOfferType.PublicCatering;
      }
      if (officeType.includes(EOfficeType.Garage)) {
        offerType |= FOfferType.Garage;
      }
      if (officeType.includes(EOfficeType.Manufacture)) {
        offerType |= FOfferType.Manufacture;
      }
      if (officeType.includes(EOfficeType.AutoService)) {
        offerType |= FOfferType.AutoService;
      }
      if (officeType.includes(EOfficeType.Business)) {
        offerType |= FOfferType.Business;
      }
      if (officeType.includes(EOfficeType.Building)) {
        offerType |= FOfferType.Building;
      }
      if (officeType.includes(EOfficeType.DomesticServices)) {
        offerType |= FOfferType.DomesticServices;
      }
      if (officeType.includes(EOfficeType.Office) && isCoworking) {
        offerType |= FOfferType.Coworking;
      }

      return offerType;
    }

    // tslint:disable-next-line: no-any
    if (category && [ECategoryType.CommercialLandRent, ECategoryType.CommercialLandSale].includes(category as any)) {
      return FOfferType.CommercialLand;
    }

    return FOfferType.Commercial;
  }

  return FOfferType.Unexpected;
};
