import { pluralize } from '@cian/newbuilding-utils';

const titles: [string, string, string] = [
  'закреплённое место',
  'закреплённых места',
  'закреплённых мест',
];

export function getFixedWorkplaceCategoryTitle(workplaceCount: number): string {
  return pluralize(workplaceCount, titles);
}
