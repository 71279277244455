import { InlineText1 } from '@cian/ui-kit';

import classNames from 'classnames';
import * as React from 'react';

import { getButtonLabelColor } from '../../helpers/getButtonLabelColor';

import * as styles from './FilterButton.css';
import { IFilterButtonProps } from './types';

const FilterButtonComponent: React.VFC<IFilterButtonProps> = props => {
  const { label, isActive, onClick, count, isPlaceholderLabel } = props;

  return (
    <button
      className={classNames(styles['button'], { [styles['button--active']]: isActive })}
      data-testid="container"
      onClick={onClick}
      type="button"
    >
      <div className={styles['label-wrapper']}>
        <InlineText1 whiteSpace="nowrap" color={getButtonLabelColor(isPlaceholderLabel)}>
          {label}
        </InlineText1>
      </div>
      <div className={styles['info-block']}>
        {Boolean(count) && (
          <div data-testid="count" className={styles['count']}>
            <InlineText1 color="gray60_100">{count}</InlineText1>
          </div>
        )}
        <div className={styles['arrow-wrapper']} />
      </div>
    </button>
  );
};

FilterButtonComponent.displayName = 'FilterButton';

export const FilterButton = React.memo<IFilterButtonProps>(FilterButtonComponent);
