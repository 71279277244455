import { IHideOfferRequestScheme, IRestoreOfferRequestScheme } from '../../api/api';
import { AuthRouteParamsMessages, EAuthRouteParams } from '../../constants/auth_route_params';
import { IOfferAnalyticsData } from '../../utils/analytics';
import {
  getOfferAnalyticsData,
  trackAuthPopupShow,
  trackSuccessHiding,
  trackSuccessRestoring,
} from '../../utils/hidden_objects';
import { toPromise } from '../../utils/streams';
import { openAuthenticationPopup } from '../authenticationPopup/actions';
import { TTypedThunkAction } from '../model';
import { TOfferActions } from '../offer/actions';
import { TOffersListActions } from '../offers_list/actions';

import { getErrorCode } from './utils/getErrorCode';

const HIDE_ANIMATION_TIME = 700;

export enum EHideOfferState {
  INIT = 'INIT',
  FETCHING = 'FETCHING',
  SUCCESS = 'SUCCESS',
  ANIMATING = 'ANIMATING',
  ERROR = 'ERROR',
}

export enum EHideOfferErrorCode {
  limit = 'limitExceeded',
}

export interface IHideOfferInfo {
  requestInfo: IHideOfferRequestScheme | IRestoreOfferRequestScheme;
  analyticsInfo?: IOfferAnalytics;
  onCard: boolean;
  isSuggestion?: boolean;
}

export interface IOfferAnalytics {
  product: IOfferAnalyticsData;
  gaLabel?: string | null;
}

export interface ISetTopPopupState {
  type: 'SetTopPopupState';
  payload: boolean;
}

export type THideOfferActions =
  | { type: 'SetHideOfferState'; payload: { state: EHideOfferState; errorCode?: EHideOfferErrorCode } }
  | { type: 'SetTopPopupState'; payload: boolean }
  | { type: 'SetHidingOffer'; payload: IHideOfferInfo }
  | { type: 'SetHideOfferOnCard'; payload: boolean };

export function toggleTopPopup(payload: boolean): ISetTopPopupState {
  return {
    payload,
    type: 'SetTopPopupState',
  };
}

export function hideOfferAction(params: IHideOfferInfo): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    const {
      common: { isAuthenticated },
    } = getState();
    const { analyticsInfo } = params;

    dispatch<THideOfferActions>({ type: 'SetHidingOffer', payload: params });

    if (!isAuthenticated) {
      dispatch(openAuthenticationPopup(AuthRouteParamsMessages[EAuthRouteParams.Hide]));
      trackAuthPopupShow();

      return Promise.resolve();
    }

    dispatch<THideOfferActions>({
      type: 'SetHideOfferState',
      payload: { state: EHideOfferState.FETCHING },
    });

    return toPromise(api.postHideOffer(params.requestInfo as IHideOfferRequestScheme))
      .then(res => {
        if (res.response && res.response.ok) {
          dispatch<THideOfferActions>({
            type: 'SetHideOfferState',
            payload: { state: EHideOfferState.ANIMATING },
          });
          setTimeout(() => {
            dispatch<THideOfferActions>({
              type: 'SetHideOfferState',
              payload: { state: EHideOfferState.SUCCESS },
            });
            dispatch<TOffersListActions>({ type: 'SetHideOffer', payload: params });
          }, HIDE_ANIMATION_TIME);

          if (analyticsInfo) {
            trackSuccessHiding(analyticsInfo.product, analyticsInfo.gaLabel);
          }
        } else {
          dispatch<THideOfferActions>({
            type: 'SetHideOfferState',
            payload: { state: EHideOfferState.ERROR },
          });
        }
      })
      .catch(err => {
        dispatch<THideOfferActions>({
          type: 'SetHideOfferState',
          payload: {
            state: EHideOfferState.ERROR,
            errorCode: getErrorCode(err),
          },
        });

        logger.error(new Error(`Failed to hide offer with error: ${JSON.stringify(err)}`));
      });
  };
}

export function hideOfferActionOnCard(params: IHideOfferInfo): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    const {
      common: { isAuthenticated },
      offerPage: {
        offer: { gaLabel, offerAnalyticsInfo },
      },
    } = getState();
    const product = getOfferAnalyticsData(offerAnalyticsInfo);

    dispatch<THideOfferActions>({ type: 'SetHidingOffer', payload: params });

    if (!isAuthenticated) {
      dispatch(openAuthenticationPopup(AuthRouteParamsMessages[EAuthRouteParams.Hide]));

      trackAuthPopupShow();

      return Promise.resolve();
    }

    dispatch<THideOfferActions>({
      type: 'SetHideOfferState',
      payload: { state: EHideOfferState.FETCHING },
    });

    return toPromise(api.postHideOffer(params.requestInfo as IHideOfferRequestScheme))
      .then(res => {
        if (res.response && res.response.ok) {
          dispatch<THideOfferActions>({
            type: 'SetHideOfferState',
            payload: { state: EHideOfferState.SUCCESS },
          });
          dispatch<TOfferActions>({ type: 'SetHideOfferOnCard', payload: true });
          trackSuccessHiding(product, gaLabel);
        } else {
          dispatch<THideOfferActions>({
            type: 'SetHideOfferState',
            payload: { state: EHideOfferState.ERROR },
          });
        }
      })
      .catch(err => {
        dispatch<THideOfferActions>({
          type: 'SetHideOfferState',
          payload: {
            state: EHideOfferState.ERROR,
            errorCode: getErrorCode(err),
          },
        });

        logger.error(new Error(`Failed to hide offer`));
      });
  };
}

export function restoreOfferAction(params: IHideOfferInfo): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    const {
      offerPage: {
        offer: { gaLabel, offerAnalyticsInfo },
      },
    } = getState();
    const product = getOfferAnalyticsData(offerAnalyticsInfo);

    dispatch<THideOfferActions>({
      type: 'SetHideOfferState',
      payload: { state: EHideOfferState.FETCHING },
    });

    return toPromise(api.postRestoreOffer(params.requestInfo as IRestoreOfferRequestScheme))
      .then(res => {
        if (res.response && res.response.ok) {
          dispatch<THideOfferActions>({
            type: 'SetHideOfferState',
            payload: { state: EHideOfferState.SUCCESS },
          });
          dispatch<TOfferActions>({ type: 'SetHideOfferOnCard', payload: false });
          trackSuccessRestoring(product, gaLabel);
        } else {
          dispatch<THideOfferActions>({
            type: 'SetHideOfferState',
            payload: { state: EHideOfferState.ERROR },
          });
        }
      })
      .catch(err => {
        dispatch<THideOfferActions>({
          type: 'SetHideOfferState',
          payload: { state: EHideOfferState.ERROR },
        });

        logger.error(new Error(`Failed to restore offer`));
      });
  };
}

export function hideOfferAfterAuth(): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    const {
      hideOffer: { hidingOffer },
    } = getState();

    if (hidingOffer) {
      if (hidingOffer.onCard) {
        return dispatch(hideOfferActionOnCard(hidingOffer));
      } else {
        return dispatch(hideOfferAction(hidingOffer));
      }
    }

    return Promise.resolve();
  };
}
