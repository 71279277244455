export interface INewbuildingFlatTourBooking {
  isEnabled: boolean;
  title: string;
  description: string;
}

export interface INewbuildingFlatTourBookingSettings {
  title: string;
  description: string;
}

export const DEFAULT_FLAT_TOUR_BOOKING: INewbuildingFlatTourBooking = {
  isEnabled: false,
  title: '',
  description: '',
};
