import * as React from 'react';

import ButtonRadioGroupField from '../../../ui/button_radio_group_field/button_radio_group_field';
import { ruble } from '../../../utils/formatters';
import { isCommercialObjectType } from '../../common/select_helper';
import { Currency, DealType, IFieldProps, IsVisibleCheck } from '../field';

const styles = require('./currency.css');
const fieldStyles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    const { filter: { currency, dealType } } = this.props;
    const { filter: { currency: nextCurrency, dealType: nextDealType } } = nextProps;

    return currency !== nextCurrency || dealType !== nextDealType;
  }

  public render() {
    const { filter: { currency, dealType }, userAgent } = this.props;

    return (
      <div className={fieldStyles.field} data-mark="FieldCurrency">
        <div className={styles.currency}>
          <ButtonRadioGroupField
            containerStyle={styles.buttonsContainer}
            value={currency}
            onValueChange={this.props.actions.setCurrency}
            values={[
              { value: Currency.RUB, label: ruble(userAgent) },
              { value: Currency.USD, label: '$' },
              { value: Currency.EUR, label: '€' },
            ]}
          />
          {dealType === DealType.Rent && <span> в месяц </span>}
        </div>
      </div>
    );
  }

}

export const isVisible: IsVisibleCheck = filter => {
  return isCommercialObjectType(filter.selectedObjectTypes);
};
