import { PageType } from '../../utils/analytics';
export type TransitionMetricsState = {
  transitioned: boolean;
  pageType: PageType;
};

export const defaultTransitionMetricsState: TransitionMetricsState = {
  transitioned: false,
  pageType: '',
};
