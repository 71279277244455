import * as React from 'react';

import Block from '../../../ui/block/block';
import { Field } from '../../field';
import * as fields from '../../fields/';
import { IFieldProps, IsVisibleCheck } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    return (
      <Block
        title="Об участке"
        dataMark="BlockAboutStead"
      >
        <div className={styles.fieldBlock}>
          <Field field={fields.squareSteadAdvancedFilter} />
          <Field field={fields.steadConditions} />
          <Field field={fields.steadStatus} />
        </div>
      </Block>
    );
  }
}

export const isVisible: IsVisibleCheck = filter =>
  fields.steadStatus.isVisible(filter)
  || fields.steadConditions.isVisible(filter)
  || fields.squareSteadAdvancedFilter.isVisible(filter);
