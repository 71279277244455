import * as React from 'react';

export type RComponent<P> = React.ComponentClass<P> | React.SFC<P>;
export type Element<P> = {
  Component: RComponent<P>;
  props: P;
  container: string;
};
export type Elements = { [key: string]: Element<any> }; // tslint:disable-line:no-any
export type Containers = {
  [key: string]: Elements;
};
export type Listener = () => void;
export type Unsubscribe = () => void;

export class FixedComponentsStore {
  private containers: Containers = {};
  private listeners: Listener[] = [];
  private forceReflowListeners: Listener[] = [];

  public getElements = (container: string): Elements => {
    return this.containers[container] || {};
  }

  public setElement = <P>(container: string, id: string, Component: RComponent<P>, props: React.Props<P>): void => {
    if (!this.containers[container]) {
      this.containers[container] = {};
    }
    this.containers[container][id] = { Component, props, container };
    this.emitChanges();
  }

  public removeElement = (container: string, id: string) => {
    if (this.containers[container] && this.containers[container][id]) {
      delete this.containers[container][id];
      this.emitChanges();
    }
  }

  public subscribe = (listener: Listener): Unsubscribe => {
    this.listeners.push(listener);
    return () => {
      return this.listeners = this.listeners.filter(l => l !== listener);
    };
  }

  public forceReflow = () => {
    this.forceReflowListeners.forEach(listener => {
      listener();
    });
  }

  public onForceReflow = (listener: Listener): Unsubscribe => {
    this.forceReflowListeners.push(listener);
    return () => {
      return this.forceReflowListeners = this.forceReflowListeners.filter(l => l !== listener);
    };
  }

  private emitChanges = () => {
    this.listeners.forEach(listener => {
      listener();
    });
  }
}
