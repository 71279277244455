/* eslint-disable max-lines */
import { ILogger } from '@cian/logger';

import { Dispatch } from 'redux';

import { ICurrentLocation, IRegionInfo, IRegionMetaData, IRegionsResponseData, MobileWebsiteApi } from '../../api/api';
import logException from '../../utils/exceptions';
import { getCurrentRegionId } from '../../utils/geo';
import { isClient } from '../../utils/isomorphic';
import { getSubdomain, isTesting, makeRedirectRes } from '../../utils/location';
import { Regions, coordsCianToYmapsBox, mapRegionInfoToUserRegion } from '../../utils/regions';
import { toPromise } from '../../utils/streams';
import { isTimeoutError, timeoutPromise } from '../../utils/timeout_promise';
import { YMapsBoundedBox } from '../../utils/ymaps';
import { checkRedirectData, setUserRegion } from '../common/actions';
import { ResourceStatus } from '../common/model';
import { ReduxState, TReduxActions, TTypedThunkAction } from '../model';

export type TRegionsActions =
  | { type: 'RequestRegions' }
  | { type: 'RequestRegionMeta' }
  | { type: 'RejectRegionMeta' }
  | { type: 'UpdateRegions'; payload: { data: IRegionsResponseData } }
  | { type: 'RejectRegions' }
  | { type: 'SetRegionsInfo'; payload: { regionsInfo: IRegionInfo[] } }
  | { type: 'SetRegionMeta'; payload: IRegionMetaData }
  | { type: 'SetRegionCities'; payload: { data: IRegionInfo[]; region: number; status: ResourceStatus } }
  | { type: 'RequestRegionCities' }
  | { type: 'RequestRegionCitiesFail' }
  | { type: 'SetCurrentRegionInfo'; payload: { data: IRegionInfo } };

export function setCurrentRegionInfo(currentRegionInfo: IRegionInfo): TRegionsActions {
  return {
    type: 'SetCurrentRegionInfo',
    payload: {
      data: currentRegionInfo,
    },
  };
}

export function requestRegions(): TRegionsActions {
  return {
    type: 'RequestRegions',
  };
}

export function requestRegionMeta(): TRegionsActions {
  return {
    type: 'RequestRegionMeta',
  };
}

export function updateRegions(data: IRegionsResponseData): TRegionsActions {
  return {
    type: 'UpdateRegions',
    payload: {
      data,
    },
  };
}

export function setRegionMeta(regionMeta: IRegionMetaData): TRegionsActions {
  return {
    type: 'SetRegionMeta',
    payload: regionMeta,
  };
}

export function setRegionsInfo(regionsInfo: IRegionInfo[]): TRegionsActions {
  return {
    type: 'SetRegionsInfo',
    payload: {
      regionsInfo,
    },
  };
}

export function setRegionCities(cities: IRegionInfo[], region: number): TRegionsActions {
  return {
    type: 'SetRegionCities',
    payload: {
      data: cities,
      region,
      status: ResourceStatus.Success,
    },
  };
}

export function rejectRegions(): TRegionsActions {
  return {
    type: 'RejectRegions',
  };
}

export function rejectRegionMeta(): TRegionsActions {
  return {
    type: 'RejectRegionMeta',
  };
}

export function getRegionsBySubdomain(
  subdomain: string,
  api: MobileWebsiteApi,
  logger: ILogger,
): Promise<void | IRegionsResponseData> {
  return toPromise(api.getRegionsBySubdomain(subdomain, logger))
    .then(res => {
      const { data, status } = res.result;
      if (status && status.toLowerCase() === 'ok') {
        return data;
      }
      throw new Error(`Wrong response: ${status}`);
    })
    .catch(err => {
      // Если ошибка сетевая, то она уже обработана, и логгировать ее не нужно
      if (err.meta?.response) {
        return;
      }

      logger.error(err, {
        message: 'getRegionsBySubdomain error',
        domain: 'src/mobile_website/redux/regions/actions.ts',
      });
    });
}

export function getRegionMeta(region: number, api: MobileWebsiteApi, logger: ILogger): Promise<IRegionMetaData> {
  return toPromise(api.getRegionMeta(region, logger)).then(res => {
    const { data, status } = res.result;

    if (status && status.toLowerCase() === 'ok') {
      return data;
    }
    throw new Error(`Wrong response: ${status}`);
  });
}

/** Получение вспомогательной информации по id региона фильтра (поле "Где") на страницах фильтра.
 *  Включает получение мета информации для "Гео" пользователя. */
export function updateRegionMetaForFilter(
  filterRegionId: number,
  skipServerSubdomainReplace?: boolean,
): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    dispatch<TRegionsActions>(requestRegionMeta());
    const { logger } = getState();
    const requestUrl = getState().common.requestUrl;

    return timeoutPromise(getRegionMeta(filterRegionId, api, logger), 4000)
      .then(filterRegionMeta => {
        const { location } = filterRegionMeta;

        if (!skipServerSubdomainReplace) {
          checkServerSubdomainReplaceByRegion(filterRegionMeta, dispatch, requestUrl);
        }

        dispatch(setRegionMeta(filterRegionMeta));
        dispatch(setCurrentRegionInfo(filterRegionMeta.location));

        return location.parentId ? getRegionMeta(location.parentId, api, logger) : Promise.resolve(filterRegionMeta);
      })
      .then(userRegionMeta => {
        if (!userRegionMeta) {
          throw new Error('updateRegionMetaForFilter error, no userRegionMeta');
        }
        const { cookieRegion } = getState().common;
        const userRegion = mapRegionInfoToUserRegion(userRegionMeta.location);

        if (userRegion && cookieRegion) {
          dispatch(setUserRegion(cookieRegion.subdomain ? cookieRegion : userRegion));
        }
      })
      .catch(err => {
        dispatch<TRegionsActions>(rejectRegionMeta());
        throw err;
      });
  };
}

export function updateRegionMeta(region: number): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    const { logger } = getState();
    dispatch<TRegionsActions>(requestRegionMeta());

    return getRegionMeta(region, api, logger)
      .then(regionMeta => {
        dispatch(setRegionMeta(regionMeta));
        dispatch(setCurrentRegionInfo(regionMeta.location));
      })
      .catch(err => {
        if (logger) {
          if (isTimeoutError(err)) {
            logger.error('updateRegionMeta timeout', err);
          } else {
            logger.error('updateRegionMeta', err);
          }
        }
        dispatch<TRegionsActions>(rejectRegionMeta());
        throw err;
      });
  };
}

function checkServerSubdomainReplaceByRegion(
  filterRegionMeta: IRegionMetaData,
  dispatch: Dispatch<TReduxActions>,
  requestUrl: string,
) {
  if (isClient) {
    return;
  }
  const currentSubdomain = getSubdomain(requestUrl);
  const filterRegion = mapRegionInfoToUserRegion(filterRegionMeta.location);
  const baseConditions = currentSubdomain && !isTesting(currentSubdomain);
  if (baseConditions && filterRegion && filterRegion.subdomain !== currentSubdomain) {
    const location = requestUrl.replace(currentSubdomain, filterRegion.subdomain);
    dispatch(checkRedirectData(makeRedirectRes(location)));
  }
}

export function getAllRegionsInfo(api: MobileWebsiteApi): Promise<IRegionInfo[]> {
  return toPromise(api.getRegions()).then(res => {
    const { result } = res;
    if (res.response && res.response.status && res.response.status === 200) {
      return result.data.items;
    }
    throw new Error(`Wrong response: ${res}`);
  });
}

export function getRegionInfo(api: MobileWebsiteApi, regionId: string): Promise<IRegionInfo> {
  return toPromise(api.getRegion(regionId)).then(res => {
    const { result } = res;
    if (res.response && res.response.status && res.response.status === 200) {
      return result.data;
    }

    throw new Error(`Wrong response: ${res}`);
  });
}

export function getRegionByLocation(api: MobileWebsiteApi, path: string, subdomain: string): Promise<ICurrentLocation> {
  return toPromise(api.getCurrentLocation(path, subdomain)).then(res => {
    const { result } = res;

    if (res.response && res.response.status && res.response.status === 200) {
      return result.data;
    }

    throw new Error(`Wrong response: ${res}`);
  });
}

export function getRegions(subdomain: string): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    dispatch<TRegionsActions>(requestRegions());
    const state = getState();
    const { locationBeforeTransitions, routeName } = state.routing;
    const { pathname, search } = locationBeforeTransitions;

    return Promise.all([
      getRegionsBySubdomain(subdomain, api, state.logger),
      getAllRegionsInfo(api),
      /**
       * Получаем regionId текущей локации пользователя для апи region_meta
       * (region_meta не поддерживает локацию - городской округ и
       * область внутри области(актуально для Краснодарского края)).
       */
      getRegionByLocation(api, pathname + search, subdomain),
    ])
      .then(([data, regions, currentRegion]) => {
        if (data) {
          dispatch(updateRegions(data));
        }

        dispatch(setRegionsInfo(regions));

        /** Если мы не в фильтрах, то должны получать метаинформацию здесь, т.к. она требуется для бокового меню. */
        if (!['main', 'advancedSearch', 'map', 'listing'].includes(routeName)) {
          const defaultRegion = getDefaultCityOrRegion(state);

          const { items } = currentRegion;
          const { isNewbuildingSubdomain, cookieRegion } = state.common;

          let region: number;
          //у ЖК на поддомене path это '/', а по нему бек логично вернет мск
          //такой костыль, чтобы пока не усложнять взаимодействие с беком еще одним параметром
          if (isNewbuildingSubdomain) {
            region = defaultRegion;
          } else if (cookieRegion && cookieRegion.id) {
            region = cookieRegion.id;
          } else {
            region = items ? getCurrentRegionId(items) : defaultRegion;
          }
          dispatch(updateRegionMeta(region));
        }
      })
      .catch(err => {
        dispatch<TRegionsActions>(rejectRegions());
      });
  };
}

export function getRegionCities(regionId: number): TTypedThunkAction<Promise<{} | void>> {
  return (dispatch, getState, { api }) => {
    dispatch<TRegionsActions>({ type: 'RequestRegionCities' });

    return toPromise(api.getRegionCities(regionId))
      .then(res => {
        const { result } = res;
        if (result && result.status && result.status === 'ok') {
          return dispatch<TRegionsActions>(setRegionCities(result.data.items, regionId));
        }
        throw new Error(`Wrong response: ${res}`);
      })
      .catch(err => {
        dispatch<TRegionsActions>({ type: 'RequestRegionCitiesFail' });
      });
  };
}

export function searchRegions(api: MobileWebsiteApi, text: string): Promise<IRegionInfo[]> {
  return toPromise(api.searchRegions(text)).then(res => {
    const { result } = res;
    if (result && result.status && result.status === 'ok') {
      return result.data.items;
    }
    logException(new Error(`Wrong response: ${res}`));

    return [];
  });
}

function getDefaultRegion(state: ReduxState): number {
  const { cookieRegion, userRegion, detectedRegion } = state.common;
  const { data: regions } = state.regions;
  const {
    locationBeforeTransitions: { search },
    routeName,
  } = state.routing;

  const subdomainRegionId = parseInt(Object.keys(regions.default)[0], 10);
  const cookieRegionId = cookieRegion ? Number(cookieRegion.id) : undefined;

  if (subdomainRegionId === Regions.Moscow && search === '') {
    if (routeName === 'map') {
      return detectedRegion.id;
    } else if (cookieRegionId) {
      return cookieRegionId;
    }
  }

  return cookieRegionId || detectedRegion.id || (userRegion && userRegion.id) || subdomainRegionId;
}

/**
 * Возвращаем регион из состояния
 * Берем по модулю, чтобы регионы -1 и -2 не ломали работу апи
 * @param state ReduxState
 */
export function getDefaultCityOrRegion(state: ReduxState) {
  return Math.abs(getDefaultRegion(state));
}

export function filterRegionBoundsSelector(state: ReduxState): YMapsBoundedBox | undefined {
  const { location } = state.regions.regionMeta;

  return (location && location.boundedBy && coordsCianToYmapsBox(location.boundedBy)) || undefined;
}
