import { TAgentContactsPopupActions } from './actions';
import { defaultAgentContactsPopupState, IAgentContactsPopupState } from './model';
type Reducer = (state: IAgentContactsPopupState, action: TAgentContactsPopupActions) => IAgentContactsPopupState;

export const agentContactsPopupReducer: Reducer = (
  state = defaultAgentContactsPopupState,
  action: TAgentContactsPopupActions,
) => {
  switch (action.type) {
    case 'OpenAgentContactsCardPagePopup':
      return {
        ...state,
        offerId: undefined,
        isCardPage: true,
      };

    case 'OpenAgentContactsSerpPagePopup':
      return {
        ...state,
        offerId: action.payload,
        isCardPage: false,
      };

      case 'CloseAgentContactsPopup':
      return {
        ...state,
        offerId: undefined,
        isCardPage: false,
      };

    default:
      return state;
  }
};
