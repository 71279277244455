import { ReduxState } from '../model';

export const PIN_POPUP_QUERY_PARAM = 'pin_opened';

const inAppPopupRouting: { [key: string]: boolean } = {};

export const setPopupInAppRouting = (param: string) => inAppPopupRouting[param] = true;
export const getPopupInAppRouting = (param: string) => inAppPopupRouting[param];

const hasBooleanQueryParamChecker = (param: string, checkInAppRouting?: boolean) => (queryString: string): boolean =>
  queryString.includes(`${param}=true`) && (!checkInAppRouting || !!getPopupInAppRouting(param));

export const hasPinQueryParam = hasBooleanQueryParamChecker(PIN_POPUP_QUERY_PARAM);

export const pinPopupOpenedByQuery = hasBooleanQueryParamChecker(PIN_POPUP_QUERY_PARAM, true);

interface IConditions {
  isPhone?: boolean;
  isSingle?: boolean;
  isPortrait?: boolean;
}

export enum PopupType {
  SingleBottom,
  PopupFullPage,
  PopupBottomHorizontal,
  PopupRightVertical,
}

const rules: (IConditions & { type: PopupType })[] = [
  {
    type: PopupType.SingleBottom,
    isSingle: true,
    isPhone: true,
  },
  {
    type: PopupType.SingleBottom,
    isSingle: true,
    isPhone: false,
    isPortrait: true,
  },
  {
    type: PopupType.PopupFullPage,
    isSingle: false,
    isPhone: true,
  },
  {
    type: PopupType.PopupBottomHorizontal,
    isSingle: false,
    isPhone: false,
    isPortrait: true,
  },
  {
    type: PopupType.PopupRightVertical,
    isPhone: false,
    isPortrait: false,
  },
];

const booleanConditionChecker = (conditionsKey: keyof IConditions) =>
  (condition: IConditions, value: boolean): boolean =>
    condition[conditionsKey] == null || condition[conditionsKey] === value;

const checkIsPhone = booleanConditionChecker('isPhone');
const checkIsPortrait = booleanConditionChecker('isPortrait');
const checkIsSingle = booleanConditionChecker('isSingle');

type CheckPopupModeEnabledOptions = {
  isSingleOffer: boolean,
  isPhone: boolean,
  isPortrait: boolean,
};

export const popupModeEnabled = (type: PopupType, params: CheckPopupModeEnabledOptions): boolean => {
  const suitabledRule = rules.find((rule) =>
    checkIsPhone(rule, params.isPhone)
    && checkIsPortrait(rule, params.isPortrait)
    && checkIsSingle(rule, params.isSingleOffer),
  );
  return !!suitabledRule && suitabledRule.type === type;
};

export const popupIsOpened = (type: PopupType, state: ReduxState): boolean => {
  const { routing, mapPage: { openedPinType }, common: { deviceType, orientation } } = state;
  const queryString = routing.locationBeforeTransitions.search;

  const isPortrait = orientation === 'portrait';
  const isPhone = deviceType === 'phone';

  const opened = pinPopupOpenedByQuery(queryString) && popupModeEnabled(type, {
      isSingleOffer: openedPinType !== 'multi',
      isPortrait,
      isPhone,
    });
  return opened;
};
