import { IOfferDetail, IVideo } from '../../../api/models/offer_card';
import { getYoutubeVideoPreviewUrl } from '../../../utils/youtube';

export function responseToVideos(offer: IOfferDetail): IVideo[] {
  if (!offer.videos) {
    return [];
  }

  return offer.videos.map(video => {
    if (video.type === 'youtube' && video.url) {
      video.previewUrl = getYoutubeVideoPreviewUrl(video.url);
    }

    return video;
  });
}
