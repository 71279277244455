import * as React from 'react';

import { EJsonQueryPriceType } from '../../../../../../redux/filters/model';
import ButtonRadioGroupField from '../../../../../../ui/button_radio_group_field/button_radio_group_field';

import { coworkingPriceTypeRadioButtonsMapper } from '../../mappers/coworkingPriceTypeRadioButtonsMapper';

import { ICoworkingPriceTypeRadioButtonsProps } from './types';

export class CoworkingPriceTypeRadioButtonsComponent extends React.PureComponent<ICoworkingPriceTypeRadioButtonsProps> {
  public static readonly defaultProps: Partial<ICoworkingPriceTypeRadioButtonsProps> = {
    priceType: EJsonQueryPriceType.All,
  };

  private static readonly jsonQueryPriceTypeValues = Object
    .keys(EJsonQueryPriceType)
    .map((key: keyof typeof EJsonQueryPriceType) => EJsonQueryPriceType[key]);

  public render(): React.ReactNode {
    const { priceType } = this.props;

    return (
      <div>
        <ButtonRadioGroupField
          value={priceType}
          onValueChange={this.handleValueChange}
          uncheckable
          values={coworkingPriceTypeRadioButtonsMapper}
        />
      </div>
    );
  }

  private readonly handleValueChange = (value: EJsonQueryPriceType): void => {
    const { priceType, setPriceType } = this.props;

    if (value === EJsonQueryPriceType.All) {
      setPriceType(undefined);
    } else if (
      value &&
      value !== priceType &&
      CoworkingPriceTypeRadioButtonsComponent.jsonQueryPriceTypeValues.includes(value)
    ) {
      setPriceType(value);
    }
  }
}
