import { ISelectMobileGroup } from '@cian/components';

import { commercialObjectType, DisablePredicate } from '../../redux/filters/model';
import { someProp } from '../../utils/object_helpers';
import { FilterState } from '../fields/field';

export { rent,  sale, short } from '../../redux/filters/model';

export interface IProcessedOptions {
  options: ISelectMobileGroup[];
  value: string[];
}

export interface IOptionsList {
  label: string;
  options: IOption[];
  disableIn?: DisablePredicate[];
}

export interface IOption {
  label: string;
  id: string;
  disableIn?: DisablePredicate[];
  isNotMultiple?: boolean;
  comingsoon?: boolean;
  disabled?: boolean;
}

export const filterOptions =
  (option: IOptionsList | IOption, filter: FilterState): boolean => {
    return option.disableIn
      ? !option.disableIn.some(f => f(filter))
      : true;
};

export const createSelectOptions =
  (options: IOptionsList[], types: string[], filter: FilterState): IProcessedOptions => {
    const selectedIds: string[] = [];

    const processedOptions = options
      .filter(o => filterOptions(o, filter))
      .map(group => {
        const filteredOptions = group.options
          .filter(o => filterOptions(o, filter));

        filteredOptions.forEach(option => {
          const selected = types
            .some(type => type === option.id);

          if (selected) {
            selectedIds.push(option.id);
          }
        });

        return Object.assign({}, group, { options: filteredOptions });
      });

    return {
      options: processedOptions,
      value: selectedIds,
    };
};

export const isCommercialObjectType =
  (types: string[]): boolean => {
    return types.some(type => {
      return someProp(commercialObjectType, type);
    });
};
