import { ECoworkingOfferType } from '../api/models/offer_card';

const coworkingOfferTypeTitle = {
  office: 'Отдельный офис',
  fixedWorkplace: 'Закреплённое рабочее место',
  flexibleWorkplace: 'Незакреплённое рабочее место',
  conferenceHall: 'Конференц-зал',
  meetingRoom: 'Переговорная комната',
};

export interface ICommercialParams {
  isOfficeSearch?: boolean;
}

interface ICoworkingOfferType {
  coworkingOfferType?: ECoworkingOfferType | null;
}

export function getCommercialCoworkingTitle(
  { coworkingOfferType }: ICoworkingOfferType,
  defaultTitle: string,
  params: ICommercialParams = {},
) {
  if (!coworkingOfferType) {
    return defaultTitle;
  }

  const { isOfficeSearch = false } = params;

  if (coworkingOfferType === ECoworkingOfferType.Office) {
    return isOfficeSearch ? 'Офис' : 'Отдельный офис';
  }

  return coworkingOfferTypeTitle[coworkingOfferType] || defaultTitle;
}
