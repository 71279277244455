import * as React from 'react';

import { NestedCheckboxGroupsFilter } from '../../../../../../../shared/components/NestedCheckboxGroupsFilter';
import {
  BusinessAppointmentsIdsService,
  IBusinessAppointmentsIdsService,
} from '../../../../../../../shared/services/BusinessAppointmentsIdsService';
import {
  AssociateGroupTypeWithHashService,
  IAssociateStringWithNumberService,
} from '../../services/AssociateStringWithNumberService';

import { prepareSaveCategoryValues } from './internal/helpers/prepareSaveCategoryValues';
import { useCategoryGroups } from './internal/hooks/useCategoryGroups';
import { useCategoryValues } from './internal/hooks/useCategoryValues';
import { useSemiCategoryGroups } from './internal/hooks/useSemiCategoryGroups';
import { useSemiCategoryRawGroups } from './internal/hooks/useSemiCategoryRawGroups';
import { HandleCategorySave, HandleSemiCategorySave, ReadyBusinessAppointmentsProps } from './types';

export const ReadyBusinessAppointments = React.memo<ReadyBusinessAppointmentsProps>(function ReadyBusinessAppointments(
  props,
) {
  const { values, groups, setBusinessAppointments } = props;

  const businessAppointmentsIdsService = React.useMemo<IBusinessAppointmentsIdsService>(
    () => new BusinessAppointmentsIdsService(values),
    [values],
  );

  const availableReadyBusinessIds = React.useMemo<Array<number>>(
    () => businessAppointmentsIdsService.getAvailableReadyBusinessIds(groups),
    [groups, businessAppointmentsIdsService],
  );

  const associateGroupTypeWithHashService = React.useMemo<IAssociateStringWithNumberService>(
    () => new AssociateGroupTypeWithHashService(groups),
    [groups],
  );

  const categoryGroups = useCategoryGroups(groups, associateGroupTypeWithHashService);

  const categoryValues = useCategoryValues(groups, availableReadyBusinessIds, associateGroupTypeWithHashService);

  const semiCategoryRawGroups = useSemiCategoryRawGroups(groups, categoryValues, associateGroupTypeWithHashService);

  const semiCategoryGroups = useSemiCategoryGroups(semiCategoryRawGroups);

  const handleCategorySave = React.useCallback<HandleCategorySave>(
    groupTypes => {
      const saveCategoryValues = prepareSaveCategoryValues(
        groups,
        categoryValues,
        availableReadyBusinessIds,
        groupTypes,
        associateGroupTypeWithHashService,
        businessAppointmentsIdsService,
      );

      setBusinessAppointments(saveCategoryValues);
    },
    [
      associateGroupTypeWithHashService,
      availableReadyBusinessIds,
      businessAppointmentsIdsService,
      categoryValues,
      groups,
      setBusinessAppointments,
    ],
  );

  const handleSemiCategorySave = React.useCallback<HandleSemiCategorySave>(
    ids => {
      const availableSemigroupsIds = businessAppointmentsIdsService.getAvailableReadyBusinessIds(semiCategoryRawGroups);

      const saveSemiCategoryValues = ids.concat(businessAppointmentsIdsService.difference(availableSemigroupsIds));

      setBusinessAppointments(saveSemiCategoryValues);
    },
    [businessAppointmentsIdsService, semiCategoryRawGroups, setBusinessAppointments],
  );

  return (
    <div>
      <NestedCheckboxGroupsFilter
        closeOnSave
        withoutSearch
        allValuesLabel="Все категории"
        defaultLabel="Выберите категорию"
        groups={categoryGroups}
        title="Категория"
        values={categoryValues}
        onSave={handleCategorySave}
      />
      {!!semiCategoryGroups.length && (
        <NestedCheckboxGroupsFilter
          closeOnSave
          allValuesLabel="Все подкатегории"
          defaultLabel="Выберите подкатегорию"
          groups={semiCategoryGroups}
          title="Подкатегория"
          values={availableReadyBusinessIds}
          onSave={handleSemiCategorySave}
        />
      )}
    </div>
  );
});
