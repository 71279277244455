import { ca } from '@cian/analytics';

import { GeoTagKind } from '../../../../api/api';
import { FiltersPlace } from '../../../fields/field';
import { getCategory } from '../../../../utils/analytics';

export function trackTagsDeleteClick(kind: GeoTagKind, filtersPlace: FiltersPlace) {
  const geoType = kind === 'location' ? 'region' : kind;

  ca('event', {
    name: 'oldevent',
    category: getCategory(filtersPlace),
    action: 'delete_tag',
    label: `geo_${geoType}`,
  });
}
