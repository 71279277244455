import * as React from 'react';

import { IReadyBusinessGroupItem } from '../../../../../../../../../shared/repositories/announcements/entities/AnnouncementReferences/ReadyBusinessGroupItem';
import { IAssociateStringWithNumberService } from '../../../../services/AssociateStringWithNumberService';

export function useSemiCategoryRawGroups(
  groups: Array<IReadyBusinessGroupItem>,
  categoryValues: Array<number>,
  associateStringWithNumberService: IAssociateStringWithNumberService,
): Array<IReadyBusinessGroupItem> {
  return React.useMemo<Array<IReadyBusinessGroupItem>>(() => {
    if (categoryValues.length) {
      return groups.filter(group => {
        return (
          group.items.length > 1 &&
          categoryValues.some(categoryValue => categoryValue === associateStringWithNumberService.get(group.groupType))
        );
      });
    }

    return Array.of<IReadyBusinessGroupItem>();
  }, [associateStringWithNumberService, categoryValues, groups]);
}
