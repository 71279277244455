import * as React from 'react';

import { NestedCheckboxGroupsFilter } from '../../../../../../../shared/components/NestedCheckboxGroupsFilter';
import { BusinessAppointmentsIdsService } from '../../../../../../../shared/services/BusinessAppointmentsIdsService';

import { useRentalBusinessGroups } from './internal/hooks/useRentalBusinessGroups';
import { HandleSave, RentalBusinessAppointmentsProps } from './types';

export const RentalBusinessAppointments = React.memo<RentalBusinessAppointmentsProps>(
  function RentalBusinessAppointments(props) {
    const { items, values, setBusinessAppointments } = props;

    const groups = useRentalBusinessGroups(items);

    const businessAppointmentsIdsService = React.useMemo(() => new BusinessAppointmentsIdsService(values), [values]);

    const availableRentalBusinessIds = React.useMemo(
      () => businessAppointmentsIdsService.getAvailableRentalBusinessIds(items),
      [businessAppointmentsIdsService, items],
    );

    const handleSave = React.useCallback<HandleSave>(
      ids => {
        setBusinessAppointments(businessAppointmentsIdsService.difference(availableRentalBusinessIds).concat(ids));
      },
      [availableRentalBusinessIds, businessAppointmentsIdsService, setBusinessAppointments],
    );

    return (
      <NestedCheckboxGroupsFilter
        closeOnSave
        withoutSearch
        allValuesLabel="Все категории"
        defaultLabel="Выберите категорию"
        groups={groups}
        title="Категория"
        values={availableRentalBusinessIds}
        onSave={handleSave}
      />
    );
  },
);
