import { ca } from '@cian/analytics';

export function trackingEnterLK() {
  ca('event', {
    name: 'oldevent',
    category: 'LoginMotivation_popup',
    action: 'click_login',
    label: 'Home',
  });
}

export function trackingShowPopup() {
  ca('event', {
    name: 'oldevent',
    category: 'LoginMotivation_popup',
    action: 'show',
    label: 'Home',
  });
}
