import { get } from 'lodash';

import { IOfferDetail } from '../../../api/models/offer_card';
import {
  IOfferIconField,
  IOfferIconListMap,
} from '../../../redux/offer/model';

export const tenantsMap: IOfferIconListMap = new Map([
  ['any', {
    name: 'Любой состав съёмщиков',
    icon: 'card/ic-tenant',
  }],
  ['family', {
    name: 'Для семьи',
    icon: 'card/ic-tenant',
  }],
  ['female', {
    name: 'Для женщины',
    icon: 'card/ic-tenant',
  }],
  ['male', {
    name: 'Для мужчины',
    icon: 'card/ic-tenant',
  }],
]);

export const responseToTenants = (offer: IOfferDetail): IOfferIconField[] => {
  const newState: IOfferIconField[] = [];

  const tenants: string = get(
    offer, 'bargainTerms.tenantsType', '',
  );

  if (!tenants) {
    return newState;
  }

  tenantsMap.forEach((value: { name: string; icon: string; }, key: string) => {
    if (key === tenants) {
      newState.push(value);
    }
  });

  return newState;
};
