import { flatMap } from 'lodash';

import { IBreadcrumb, IMarkedList, ISalesDescriptionSchema, ISeoData, ISeoSchemas } from '../../api/api';
import { IJsonQuery } from '../../api/models/json_query';
import { IOfferSerialized } from '../../api/models/offer_preview';
import { IOfferInfo } from '../offer/model';

import { IGk } from './gk';

export interface IOfferSource {
  type: 'offer';
  key: string; // because we can get the same gks in suggestions
  isSuggestion: boolean;
  isCommercialPageList: boolean;
  data: IOfferInfo;
}

export interface IGkSource {
  type: 'gk';
  key: string; // because we can get the same gks in suggestions
  isSuggestion: boolean;
  data: IGk;
}

export interface ITGBAdSource {
  type: 'tgb';
  bannerId: string;
  offer: IOfferInfo;
}

export interface INewbuildingChatBannerSource {
  type: 'newbuilding-chat';
}

export interface INewbuildingFlatTourBookingBannerSource {
  type: 'newbuilding-flat-tour-booking';
}

export interface ICoworkingProfitBanner {
  type: 'coworking_profit_banner';
}

export interface IMultiAdsBanner {
  type: 'multi_ads_banner';
}

export interface IParallaxAdSource {
  type: 'parallax';
  bannerId: string;
  offer: IOfferInfo;
}

export type AdSource = ITGBAdSource | IParallaxAdSource;

export interface ISeoDataSource {
  type: 'seo_data';
  data?: {
    description?: string;
  };
}

export interface IPreInfiniteBanner {
  type: 'pre_infinite_banner';
}

export type SearchListSource =
  | AdSource
  | ICoworkingProfitBanner
  | IMultiAdsBanner
  | IGkSource
  | INewbuildingChatBannerSource
  | IOfferSource
  | IPreInfiniteBanner
  | ISeoDataSource
  | INewbuildingFlatTourBookingBannerSource;

export interface IPage {
  num: number;
  offers: IOfferSource[];
  gks: IGkSource[];
  suggestions: Array<IOfferSource | IGkSource>;
  extraTop3OffersEnabled: boolean;
  extraTop3SuggestionsEnabled: boolean;
}

export type SubscriptionStatus = 'waiting' | 'pending' | 'errored' | 'subscribed';
export interface ISubscriptionState {
  status: SubscriptionStatus;
  email?: string;
}

export interface ISubscribedListType {
  queryString: string;
  email: string;
}

export interface IInitializedSearchOffersState {
  initialized: true;
  isNotFound: boolean;
  loading: boolean;
  jsonQuery: IJsonQuery;
  queryString: string; // без ? в начале
  suggestionsQuery?: string; // без ? в начале
  pages: IPage[];
  seoData: ISeoData;
  markedList?: IMarkedList;
  seoSchemas?: ISeoSchemas;
  suggestionsSeoData?: ISeoData;
  breadcrumbs: IBreadcrumb[];
  offersCountOnNextPage: number;
  // CD-13359 эксперимент для коммерческой с карточкой
  callButtonVariant: number;
  // CD-14005 эксперимент для коммерческой с кнопкой позвонить
  isCallButtonEnabled: boolean;
  isGkList: boolean;
  isSpecificGkList: boolean;
  isSpecificKpList: boolean;
  pikPhone?: string;
  subscription: ISubscriptionState;
  lastUrl: string;
  isEmptyList: boolean; //пуста ли основная выдача (не считая похожих)
  subscribedLists: ISubscribedListType[];
  suggestionsMl?: {
    pageNumber?: number;
    shown?: number;
    suggestions?: IOfferSerialized[];
  };
  hideShowMoreButton?: boolean;
  /** уникальный идентификатор поискового запроса */
  searchUuid?: string | null;
  /** уникальный id ML-ранжирования */
  mlRankingGuid?: string | null;
  /** версия модели ML-ранжирования */
  mlRankingModelVersion?: string | null;
  /** количество доступных предложений в посёлке при пустой выдаче */
  kp?: IKpOffers | null;
  salesDescription?: ISalesDescriptionSchema | null;
}

export type SearchOffersState =
  | {
      initialized: false;
      isNotFound?: boolean;
      subscription?: ISubscriptionState;
      subscribedLists: ISubscribedListType[];
      lastUrl?: string;
      queryString?: string;
      seoData?: ISeoData;
      isEmptyList?: boolean;
    }
  | IInitializedSearchOffersState;

export const defaultSubscriptionState: ISubscriptionState = {
  status: 'waiting',
};

export const defaultSearchOffersState: SearchOffersState = {
  initialized: false,
  isNotFound: false,
  subscribedLists: [],
};

export function getExistingObjects(pages: IPage[], isGkList: boolean): Array<IGkSource | IOfferSource> {
  return flatMap(pages, (page): Array<IGkSource | IOfferSource> => {
    return [...(isGkList ? page.gks : page.offers), ...page.suggestions];
  });
}

export function isAdSource(source: SearchListSource): source is ITGBAdSource | IParallaxAdSource {
  return source.type === 'tgb' || source.type === 'parallax';
}

export function isOfferSource(source: SearchListSource): source is IOfferSource {
  return source.type === 'offer';
}

export function isNotTop3OfferSource(source: SearchListSource): boolean {
  return isOfferSource(source) && !source.data.baseInfo.isTop3;
}

export function getGkFromSource(source: IGkSource): IGk {
  return source.data;
}

export function checkIfListWasSubscridedBefore(subscribedLists: ISubscribedListType[], queryString: string): boolean {
  return subscribedLists.map(list => list.queryString).includes(queryString || '');
}

export interface IKp {
  finish_year_min: number;
  finish_year_max: number;
  id: number;
  name: string;
  price_min: number;
  price_max: number;
  seo?: {
    url: string;
  };
}

export interface IKpOffers {
  offers_by_category_counter: IKpOffersByCategoryCounter;
}

export interface IKpOffersByCategoryCounter {
  cottageSale?: number;
  townhouseSale?: number;
  houseSale?: number;
  houseShareSale?: number;
  landSale?: number;
}

export interface IKpData {
  id: number;
  name: string;
  displayBuilderPhone: boolean;
  url: string;
}

export function isKp(data: IKp | IKpOffers | null): data is IKp {
  return Boolean(data && 'id' in data);
}

export function isKpOffers(data: IKp | IKpOffers | null): data is IKpOffers {
  return Boolean(data && 'offers_by_category_counter' in data);
}
