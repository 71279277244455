import { IUserGaDataLayer } from '../../api/models/data_layer';
import { toPromise } from '../../utils/streams';
import { isTimeoutError, timeoutPromise } from '../../utils/timeout_promise';
import { TTypedThunkAction } from '../model';

export interface ISetGaDataLayer {
  type: 'SetGaDataLayer';
  payload: IUserGaDataLayer;
}

export interface ISetGaDataLayerDegradation {
  type: 'SetGaDataLayerDegradation';
}

export type TDataLayerActions = ISetGaDataLayer | ISetGaDataLayerDegradation;

export function setGaDataLayer(data: IUserGaDataLayer): ISetGaDataLayer {
  return {
    type: 'SetGaDataLayer',
    payload: data,
  };
}

export function setDegradation(): ISetGaDataLayerDegradation {
  return { type: 'SetGaDataLayerDegradation' };
}

/**
 * getUserDataTimeout - это переменная, которая прокидывается из дженкинса. Сделано через
 * дженкинс, чтобы можно было оперативно менять значение таймаута, если что-то пойдет не так.
 * Если мы не смогли получить переменную, то сетим дефолтное значение 1000 ms
 */
export function fetchGaDataLayer(): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, getUserDataTimeout }) => {
    const { logger } = getState();

    return timeoutPromise(toPromise(api.getUserGaDataLayer()), getUserDataTimeout || 1000)
      .then(res => {
        dispatch(setGaDataLayer(res.result.data));
      })
      .catch(err => {
        const meta = { message: err.message, stackTrace: err.stack };

        if (logger) {
          if (isTimeoutError(err)) {
            logger.warning('Failed to load /cian-api/site/v1/get-user-ga-data-layer-data/', meta);
          } else {
            logger.error('Error occurred: /cian-api/site/v1/get-user-ga-data-layer-data/', meta);
          }
          dispatch(setDegradation());
        }
        return;
      });
  };
}

export interface IInitializedState {
  data: IUserGaDataLayer;
  initialized: true;
}

interface INotInitializedState {
  initialized: false;
}

const initialState: INotInitializedState = {
  initialized: false,
};

export interface IDegradatedState {
  initialized: false;
  data: { abGroup: string; };
}

export type DataLayerState = IInitializedState | INotInitializedState | IDegradatedState;

export function reducer(state: DataLayerState = initialState, action: TDataLayerActions): DataLayerState {
  const { type } = action;

  switch (type) {
    case 'SetGaDataLayer': {
      const abGroupFromHeader = 'data' in state ? state.data.abGroup : undefined;
      const { abGroup } = (action as ISetGaDataLayer).payload;

      return {
        initialized: true,
        data: {
          ...(action as ISetGaDataLayer).payload,
          abGroup: abGroupFromHeader || abGroup,
        },
      } as IInitializedState;
    }

    case 'SetGaDataLayerDegradation': {
      const abGroupFromHeader = 'data' in state ? state.data.abGroup : undefined;

      return {
        initialized: false,
        data: { abGroup: abGroupFromHeader || '100' },
      };
    }

    default:
      return state;
  }
}
