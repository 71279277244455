export type FloorsRangeWithTotalFloorsTypes = 'withFloor' | 'outOfTotal';

export const getFloorsRangeWithTotalFloors = (type: FloorsRangeWithTotalFloorsTypes) => (
  from: number,
  to: number,
  total: number,
): string => {
  switch (type) {
    case 'withFloor':
      return `${from}-${to}/${total} этаж`;

    case 'outOfTotal':
      return `${from}-${to} из ${total}`;

    default:
      return `${from}-${to}/${total} этаж`;
  }
};
