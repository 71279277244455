import { RouteName } from './model';

export type TRouteActions
  = { type: 'SetRouteName', payload: RouteName }
  | { type: 'SetPageviewSent', payload: boolean };

export function setRouteName(name: RouteName): TRouteActions {
  return {
    type: 'SetRouteName',
    payload: name,
  };
}

export function setPageviewSent(isPageviewSent: boolean): TRouteActions {
  return {
    type: 'SetPageviewSent',
    payload: isPageviewSent,
  };
}
