import { ca } from '@cian/analytics';
import { TObjectType } from '../../../../redux/filters/model';

export type TWhoseSuggest = 'yandex' | 'cian';

export function trackGeoSuggestClick() {
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'Click',
    label: '',
  });
}

export function trackGeoSuggestSelectValue(label: string, whoseSuggest: TWhoseSuggest) {
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'Change',
    /**
     * Пока нет разделения гео саджеста на наш собственный и яндексовый, всегда шлем yandex
     */
    label: `${label}/${whoseSuggest}`,
  });
}

export function trackGeoSuggestNotFound(label: string) {
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'NotFound',
    label,
  });
}

export const OBJECT_TYPE_TRACKING_LABEL: {[key in TObjectType]: string} = {
  kvartira: 'flat',
  komnata: 'room',
  dolya: 'flatshared',
  'koyko-mesto': 'bed',
  dom: 'house',
  chast_doma: 'housepart',
  taunkhaus: 'townhouse',
  uchastok: 'land',
  ofis: 'office',
  torgovaya_ploschad: 'tradearea',
  sklad: 'warehouse',
  psn: 'freeappointmentobject',
  obschepit: 'publiccatering',
  garazh: 'garage',
  proizvodstvo: 'manufacture',
  avtoservis: 'autoservice',
  gotovyy_biznes: 'business',
  zdanie: 'building',
  bytovye_uslugi: 'domesticservices',
  commercialLand: 'commercialland',
  coworking: 'coworking',
  readyBusiness: 'readyBusiness',
  rentalBusiness: 'rentalBusiness',
};

export function trackGeoSuggestAutoOfferChange(kpId: number, oldOfferType: TObjectType[]) {
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'AutoOfferChange',
    label: `${kpId}/${oldOfferType.map(value => OBJECT_TYPE_TRACKING_LABEL[value]).join(',')}/suburban`,
  });
}
