import { EDemandActionTypes, TDemandActions } from './actions';

export interface IDemandState {
  isHomeowner: boolean;
  comment: string;
}

const initialState: IDemandState = {
  isHomeowner: false,
  comment: '',
};

export const demandReducer = (state: IDemandState = initialState, action: TDemandActions) => {
  const { payload, type } = action;

  switch (type) {
    case EDemandActionTypes.HOME_OWNER_ON_CHANGE:

      return { ...state, isHomeowner: payload };

    case EDemandActionTypes.ON_COMMENT_CHANGE:

      return { ...state, comment: payload };

    default:

      return state;
  }
};
