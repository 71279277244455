import { isEqual } from 'lodash';
import * as React from 'react';

import { ButtonCheckbox, Label } from '@cian/components';
import { garageTypeDict } from '../../../redux/filters/dictionaries';
import { commercialObjectType } from '../../../redux/filters/model';
import ButtonCheckboxContainer from '../../../ui/button_checkbox_container/button_checkbox_container';
import { IFieldProps, isCommercialObjectGroup, IsVisibleCheck } from '../field';

const styles = require('../field.css');

const garageTypes: Array<{id: number, text: string}> = [
  {
    id: garageTypeDict.Garage,
    text: 'Гараж',
  },
  {
    id: garageTypeDict.CarPlace,
    text: 'Машиноместо',
  },
  {
    id: garageTypeDict.Box,
    text: 'Бокс',
  },
];

export class Component extends React.Component<IFieldProps, object> {

  public shouldComponentUpdate(nextProps: IFieldProps) {
    const { filter: { selectedGarageTypes: next } } = nextProps;
    const { filter: { selectedGarageTypes: current } } = this.props;
    return !isEqual(current, next);
  }

  public render() {
    const {
      filter: { selectedGarageTypes },
      actions: { setGarageType },
    } = this.props;

    const buttonCheckboxStyle = [
      styles.buttonCheckbox,
      { flexGrow: 0 },
    ];

    return (
      <div className={styles.field} data-mark="FieldGarageType">
        <Label label="Тип">
          <ButtonCheckboxContainer>
            {garageTypes.map(garageType => (
              <ButtonCheckbox
                mobile
                checked={selectedGarageTypes.includes(garageType.id)}
                onChange={e => setGarageType(garageType.id, (e.target as HTMLInputElement).checked)}
                key={garageType.id}
                buttonCheckboxStyle={buttonCheckboxStyle}
                wide
              >
                {garageType.text}
              </ButtonCheckbox>
            ))}
          </ButtonCheckboxContainer>
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter =>
  isCommercialObjectGroup(filter)
  && isEqual(filter.selectedObjectTypes, [commercialObjectType.Garage]);
