import { IConfig } from '@cian/config/shared';
import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';
import { makeContextModule } from '@cian/react-utils';
import { ITelemetryLogger } from '@cian/telemetry/shared';

export type ApplicationContextCustom = {
  isTestMode: boolean;
  subdomain: string;
};

export type ApplicationContextModel = {
  config: IConfig;
  httpApi: IHttpApi;
  logger: ILogger;
  custom: ApplicationContextCustom;
  telemetry: ITelemetryLogger;
};

export const {
  Context: ApplicationContext,
  useContext: useApplicationContext,
  withContext: withApplicationContext,
} = makeContextModule<ApplicationContextModel>({
  contextName: 'ApplicationContext',
});
