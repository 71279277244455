import { flatten } from 'ramda';
import * as React from 'react';

import { IStyleConfig, mergeStyles } from '@cian/components';

interface IFeaturesProps {
  children?: React.ReactNode;
  separator?: React.ReactNode;
  containerStyle?: IStyleConfig;
  nowrap?: boolean;
}

const styles = require('./features.css');

const Features: React.SFC<IFeaturesProps> = (props: IFeaturesProps) => {
  const { children, separator, nowrap } = props;

  const flattenChildren = flatten(React.Children.toArray(children));
  const items = flattenChildren.map((child, idx, { length }) => [
    <div className={styles['feature-wrapper']}>
      <span className={styles.feature}>
        {child}{length - 1 !== idx ? separator : null}
      </span>
    </div>,
  ]);

  const nowrapStyles = nowrap ? styles.containerNowrap : '';

  return (
    <div {...mergeStyles(styles.container, props.containerStyle, nowrapStyles)} data-mark="Features">
      {React.Children.map(items, x => x)}
    </div>
  );
};

Features.defaultProps = {
  separator: <span className={styles.separator}>•</span>,
};

export default Features;
