import { BaseError } from 'make-error-cause';

export class TimeoutError extends BaseError {
  public constructor(message: string) {
    super(message);
  }
}

//tslint:disable-next-line:no-any - ошибка может быть какой угодно
export function isTimeoutError(error: any): boolean {
  return error instanceof TimeoutError;
}

export function timeoutPromise<T>(promise: Promise<T>, timeout: number): Promise<T> {
  return Promise.race([
    promise,
    new Promise<never>((resolve, reject) =>
      setTimeout(() => reject(new TimeoutError('Timeout has occurred')), timeout),
    ),
  ]);
}
