import * as React from 'react';

import ButtonCheckboxContainer from '../../../ui/button_checkbox_container/button_checkbox_container';
import * as fields from '../../fields/';
import { IFieldProps, IsVisibleCheck } from '../field';

import { ButtonCheckbox } from '@cian/components';

const fieldStyles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const { notLastFloor, firstFloor } = this.props.filter;
    const buttonCheckboxStyle = [
      fieldStyles.buttonCheckbox,
    ];

    return (
      <div
        className={[
          fieldStyles.field,
          fieldStyles.fakeLabel,
        ].join(' ')}
        data-mark="FieldEdgeFloors"
      >
        <ButtonCheckboxContainer>
          <ButtonCheckbox
            mobile
            checked={firstFloor === false}
            onValueChange={this.props.actions.setNotFirstFloor}
            id="not-first-floor"
            name="not-first-floor"
            buttonCheckboxStyle={buttonCheckboxStyle}
          >
            Не первый
          </ButtonCheckbox>
          <ButtonCheckbox
            mobile
            checked={notLastFloor === true}
            onValueChange={this.props.actions.setNotLastFloor}
            id="not-last-floor"
            name="not-last-floor"
            buttonCheckboxStyle={buttonCheckboxStyle}
          >
            Не последний
          </ButtonCheckbox>
          <ButtonCheckbox
            mobile
            checked={notLastFloor === false}
            onValueChange={this.props.actions.setOnlyLastFloor}
            id="only-last-floor"
            name="only-last-floor"
            buttonCheckboxStyle={buttonCheckboxStyle}
          >
            Только последний
          </ButtonCheckbox>
        </ButtonCheckboxContainer>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => fields.floorFlat.isVisible(filter);
