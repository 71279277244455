import { connect } from 'react-redux';

import { filterToJsonQuery } from '../../../redux/filters/filter_to_json_query';
import { commercialObjectType } from '../../../redux/filters/model';
import { ReduxState, TTypedThunkDispatch } from '../../../redux/model';
import { searchOffersWithSorting } from '../../../redux/offers_list/actions';

import {
  ISortingSelectDispatchProps,
  ISortingSelectOwnProps,
  ISortingSelectStateProps,
  SortingSelect,
} from './SortingSelect';

function mapStateToProps(state: ReduxState): ISortingSelectStateProps {
  const { searchList, regions, filter, userAgent, queryClient } = state;
  const isCoworking =
    !!filter.selectedObjectTypes &&
    filter.selectedObjectTypes.length === 1 &&
    filter.selectedObjectTypes[0] === commercialObjectType.Coworking &&
    !!filter.selectedCoworkingOfferType &&
    filter.selectedCoworkingOfferType.length > 0;

  return {
    regions,
    userAgent,
    jsonQuery: searchList.initialized ? searchList.jsonQuery : filterToJsonQuery(filter, undefined, queryClient),
    isCoworking,
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch): ISortingSelectDispatchProps {
  return {
    onSelect(sortingType: string): void {
      dispatch(searchOffersWithSorting(sortingType));
    },
  };
}

export const SortingSelectContainer = connect<
  ISortingSelectStateProps,
  ISortingSelectDispatchProps,
  ISortingSelectOwnProps
>(
  mapStateToProps,
  mapDispatchToProps,
)(SortingSelect);
