import 'rxjs/add/observable/of';
import { Observable } from 'rxjs/Observable';

import { ICacheStore } from './cache';

// tslint:disable-next-line:no-any
type Value = any;

export class LocalStorageStore implements ICacheStore {
  public constructor() {
    this.checkLocalStorageAvailability();
  }

  public put(key: string, value: Value): Observable<void> {
    const stringifiedValue = JSON.stringify(value);
    window.localStorage.setItem(key, stringifiedValue);
    return Observable.of(undefined);
  }

  public get<V>(key: string): Observable<V | undefined> {
    const value = window.localStorage.getItem(key);
    const parsedValue = value && JSON.parse(value);
    return Observable.of(parsedValue != null ? parsedValue : undefined);
  }

  private checkLocalStorageAvailability() {
    const key = 'a unique key, which no one stores data';
    const value = 'value';
    window.localStorage.setItem(key, value);
    if (window.localStorage.getItem(key) !== value) {
      throw new Error('LocalStorage is not available');
    }
  }
}
