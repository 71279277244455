import { EGetUsersAuctionStatus, GetUsersAuctionMappers } from './types';

export const mappers: GetUsersAuctionMappers = {
  200: state => ({
    status: EGetUsersAuctionStatus.Success,
    userIds: state.userIds,
  }),
  400: ({ message }) => ({
    message: message || 'Неизвестная ошибка',
    status: EGetUsersAuctionStatus.Error,
    userIds: [],
  }),
};
