import { Config } from '@cian/config';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { getTelemetry } from '@cian/telemetry/browser';

import { ApplicationContextCustom, ApplicationContextModel } from '../../../../shared/utils/applicationContext';

import { initClientDependencies } from './initClientDependencies';

export interface IPrepareApplicationParams {
  config: Config;
}

export async function prepareApplication(params: IPrepareApplicationParams): Promise<ApplicationContextModel> {
  const { config } = params;

  const projectName = config.get<string>('projectName');

  if (!projectName) {
    throw new Error('Project name not found');
  }

  initClientDependencies();

  const logger = getLogger();
  const httpApi = getHttpApi();
  const telemetry = getTelemetry();

  return {
    config,
    custom: config.getStrict<ApplicationContextCustom>('applicationContext.custom'),
    httpApi,
    logger,
    telemetry,
  };
}
