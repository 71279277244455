import { QueryClient } from '@tanstack/react-query';
import { difference, get, isEqual } from 'lodash';

import { IJsonQuery, IJsonQueryGeoValue } from '../../api/models/json_query';
import { IFiltersVisibilityParams } from '../../filters/fields/field';

import { filterToJsonQuery } from './filter_to_json_query';
import { FilterState } from './model';

const valueEquals = (jsonQuery: IJsonQuery, path: string, value: any): boolean => {
  return isEqual(get(jsonQuery, path), value);
};

export const countHouseType = (jsonQuery: IJsonQuery): number => {
  const types: number[] = get(jsonQuery, 'house_material.value') || [];

  if (valueEquals(jsonQuery, 'with_newobject.value', true)) {
    return types.filter(t => [1, 2, 3].includes(t)).length;
  }

  return types.length > 0 ? 1 : 0;
};

export const countTotalArea = (jsonQuery: IJsonQuery): number => {
  if (jsonQuery.total_area != null) {
    const objecTypes: number[] = get(jsonQuery, 'object_type.value');

    const shouldCount =
      jsonQuery.office_type != null ||
      jsonQuery.with_newobject ||
      (objecTypes != null && objecTypes.some(t => [1, 2, 3, 4].includes(t)));

    return shouldCount ? 0 : 1;
  }

  return 0;
};

export const countSite = (jsonQuery: IJsonQuery): number => {
  if (jsonQuery.site != null) {
    return valueEquals(jsonQuery, 'object_type.value', [3]) ? 0 : 1;
  }

  return 0;
};

export const countRange = (jsonQuery: IJsonQuery, path: string): number => {
  const types: number[] = get(jsonQuery, path);

  return types != null && types.length > 0 ? types.length : 0;
};

export const countRentTime = (jsonQuery: IJsonQuery): number => {
  const rentTime: string = get(jsonQuery, 'for_day.value');

  const isAdvancedRentTime = rentTime === '0' || rentTime === '2';

  return isAdvancedRentTime && jsonQuery.office_type == null ? 1 : 0;
};

export const countFirstFloor = (jsonQuery: IJsonQuery): number => {
  const isFirstFloor: boolean = get(jsonQuery, 'is_first_floor.value');

  if (isFirstFloor != null) {
    if (jsonQuery.office_type != null && isFirstFloor === true) {
      return 1;
    } else if (jsonQuery.office_type == null && isFirstFloor === false) {
      return 1;
    }
  }

  return 0;
};

export const countGeoTags = (jsonQuery: IJsonQuery): number => {
  const tags: IJsonQueryGeoValue[] = get(jsonQuery, 'geo.value');

  return tags != null && tags.filter(t => t.type === 'highway').length > 0 ? 1 : 0;
};

const countYear = (jsonQuery: IJsonQuery): number => {
  const years: number[] = get(jsonQuery, 'year.value');
  const hasYears = years && years.length > 0;
  const yearGte: boolean = get(jsonQuery, 'yeargte.value');
  const handOver: boolean = get(jsonQuery, 'hand_over.value');

  return hasYears || handOver || yearGte ? 1 : 0;
};

const countBalconyLoggia = (jsonQuery: IJsonQuery): number => {
  const balconies: { gte?: number; lte?: number } = get(jsonQuery, 'balconies.value');
  const loggia: boolean = get(jsonQuery, 'loggia.value');

  return balconies || loggia ? 1 : 0;
};

export const jsonQueryToAdvancedFiltersCount =
  (doNotCountFields: string[]) =>
  (jsonQuery: IJsonQuery): number => {
    let count = difference(Object.keys(jsonQuery), doNotCountFields).length;

    count += countHouseType(jsonQuery);
    count += countTotalArea(jsonQuery);
    count += countSite(jsonQuery);
    count += countRentTime(jsonQuery);
    count += countFirstFloor(jsonQuery);
    count += countGeoTags(jsonQuery);
    count += countBalconyLoggia(jsonQuery);

    count += countRange(jsonQuery, 'garage_type.value');
    count += countRange(jsonQuery, 'garage_material.value');
    count += countRange(jsonQuery, 'contract.value');
    count += countRange(jsonQuery, 'sost_type.value');
    count += countRange(jsonQuery, 'building_class_type.value');

    count += jsonQuery.wp ? 1 : 0;

    return count;
  };

const mobileNotCountFields = [
  '_type',
  'building_class_type',
  'building_status',
  'contract',
  'currency',
  'engine_version',
  'for_day',
  'from_developer',
  'garage_material',
  'garage_type',
  'geo',
  'hand_over',
  'house_material',
  'house_type',
  'is_first_floor',
  'is_pik_promo',
  'kp_id',
  'multi_id',
  'new_object_id',
  'object_type',
  'office_type',
  'page',
  'price',
  'promo',
  'region',
  'room',
  'site',
  'sost_type',
  'special_promo',
  'suburban_offer_filter',
  'total_area',
  'with_newobject',
  'wp',
  'year',
  'yeargte',
  'price_sm',
  'domrf',
  'balconies',
  'loggia',
  'coworking_offer_type',
  'is_by_commercial_owner',
  'is_upcoming_sale',
  'ready_business_types',
  'specialty_types',
];

export const jsonQueryToMobileAdvancedFiltersCount = (jsonQuery: IJsonQuery): number => {
  let count = jsonQueryToAdvancedFiltersCount(mobileNotCountFields)(jsonQuery);

  count -= jsonQuery.is_by_homeowner ? 1 : 0;

  return count;
};

export function filterToMobileAdvancedFiltersCount(
  filter: FilterState,
  filtersVisibilityParams: IFiltersVisibilityParams | undefined,
  queryClient: QueryClient,
): number {
  return jsonQueryToMobileAdvancedFiltersCount(filterToJsonQuery(filter, filtersVisibilityParams, queryClient));
}

const tabletNotCountFields = [
  '_type',
  'building_class_type',
  'building_status',
  'contract',
  'currency',
  'engine_version',
  'for_day',
  'garage_material',
  'garage_type',
  'geo',
  'house_material',
  'house_type',
  'is_first_floor',
  'kp_id',
  'multi_id',
  'new_object_id',
  'object_type',
  'office_type',
  'price',
  'region',
  'room',
  'site',
  'sost_type',
  'suburban_offer_filter',
  'total_area',
  'with_newobject',
  'wp',
  'year',
  'yeargte',
  'hand_over',
  'page',
  'price_sm',
  'is_upcoming_sale',
  'ready_business_types',
  'specialty_types',
];

export function jsonQueryToTabletAdvancedFiltersCount(jsonQuery: IJsonQuery): number {
  let count: number = jsonQueryToAdvancedFiltersCount(tabletNotCountFields)(jsonQuery);
  count += countYear(jsonQuery);

  count -= jsonQuery.is_by_homeowner ? 1 : 0;

  return count;
}

export function filterToTabletAdvancedFiltersCount(
  filter: FilterState,
  filtersVisibilityParams: IFiltersVisibilityParams | undefined,
  queryClient: QueryClient,
): number {
  return jsonQueryToTabletAdvancedFiltersCount(filterToJsonQuery(filter, filtersVisibilityParams, queryClient));
}
