import * as React from 'react';

import { IStyleConfig, mergeStyles } from '@cian/components';
import { connect } from 'react-redux';
import * as actionCreators from '../../redux/filters/actions';
import { FilterState } from '../../redux/filters/model';
import { ReduxState, TTypedThunkDispatch } from '../../redux/model';
import ErrorTag from '../../ui/error/error';

interface IErrorStoreProps {
  filter: FilterState;
}

interface IErrorDispatchProps {
  deleteError: () => void;
}

export interface IErrorProps {
  styles?: IStyleConfig;
}
type ErrorProps = IErrorProps & IErrorStoreProps & IErrorDispatchProps;

const styles = require('./error_container.css');

export const getErrorTop = (pageYOffset: number, showBanner?: boolean): number => {
  const headerHeight = 50;
  const bannerHeight = 85;
  const shift = showBanner ? bannerHeight : 0;

  if (pageYOffset <= 0) {
    return headerHeight + shift;
  } else if (pageYOffset <= (headerHeight + shift)) {
    return (headerHeight + shift) - pageYOffset;
  }

  return 0;
};

export class ErrorContainer extends React.Component<ErrorProps, object> {

  public componentDidMount() {
    window.addEventListener('click', this.removeError);
  }

  public componentWillUnmount() {
    window.removeEventListener('click', this.removeError);
  }

  public shouldComponentUpdate(nextProps: ErrorProps) {
    const messageNow = this.props.filter.error && this.props.filter.error.message;
    const messageNext = nextProps.filter.error && nextProps.filter.error.message;
    return (
      messageNext !== messageNow ||
      this.props.styles !== nextProps.styles
    );
  }

  public render() {
    return (
      <div
        {...mergeStyles(styles.errorContainer, this.props.styles)}
      >
        {this.renderErrors()}
      </div>
    );
  }

  public renderErrors() {
    const { error } = this.props.filter;
    return error && <ErrorTag onRefreshClick={error.onRefresh}>{error.message}</ErrorTag>;
  }

  private removeError = () => {
    this.props.deleteError();
  }
}

function mapStateToProps(state: ReduxState): IErrorStoreProps {
  return {
    filter: state.filter,
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch): IErrorDispatchProps {
  return {
    deleteError: () => dispatch(actionCreators.deleteError()),
  };
}

export default connect<IErrorStoreProps, IErrorDispatchProps, IErrorProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorContainer);
