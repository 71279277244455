/* eslint-disable max-lines */

import { NotFoundError } from '@cian/peperrors/shared';

import { get, isEqual } from 'lodash';
import { replace } from 'react-router-redux';

import { IExperimentResultSchema } from '../../../server/utils/ab-use/entities/entities/ExperimentResultSchema';
import {
  IBreadcrumb,
  IComplaintsTreeItem,
  ISendComplaintRequest,
  ISimilarOffersResponseItem,
  ISubscriptionStatusError,
  TModelVersion,
} from '../../api/api';
import { ISimilarGKOffersResponse } from '../../api/models/gk_card';
import { IOfferDetailResponse } from '../../api/models/offer_card';
import { IGKMVKDataResponse } from '../../components/gk_flat_selector_widget/typings';
import { SiteType, trackShowSimilar } from '../../utils/analytics';
import { CookiesUtils } from '../../utils/cookies';
import { yearsToMilliseconds } from '../../utils/date';
import { isEmptyArray } from '../../utils/helpers';
import { Regions } from '../../utils/regions';
import { toPromise } from '../../utils/streams';
import { getHeaderData } from '../common/actions';
import { setComplaintId, setUserFeedback } from '../complaint/actions';
import { fetchFavoritesCount, setFavoritesCount } from '../favorites/actions';
import { deleteError, setError, setRegion } from '../filters/actions';
import { TTypedThunkAction } from '../model';
import { setOfferFavoriteStatus } from '../offers_list/actions';

import { IOfferInfo, IOfferURLParams, ISubscriptionStatus } from './model';
import { trackSubscriptionNewsSuccess, trackSubscriptionPriceSuccess, trackSubscriptionRegistration } from './tracking';

const cookiesUtils = new CookiesUtils();

export const SAFE_RENT_POPUP_SHOW = 'SAFE_RENT_POPUP_SHOW';

export type TOfferActions =
  | { type: 'LogTokenPending' }
  | { type: 'LogTokenSuccess' }
  | { type: 'LogTokenError' }
  | { type: 'SendComplaintPending' }
  | { type: 'SendComplaintSuccess' }
  | { type: 'SendComplaintError'; payload: string }
  | { type: 'ClearComplaint' }
  | { type: 'GetComplaintsTreePending' }
  | { type: 'GetComplaintsTreeSuccess'; payload: IComplaintsTreeItem[] }
  | { type: 'GetOfferInfoPending' }
  | { type: 'GetOfferInfoSuccess'; payload: { data: IOfferDetailResponse; urlParams: IOfferURLParams } }
  | { type: 'GetOfferInfoFromOffersList'; offer: IOfferInfo; breadcrumbs: IBreadcrumb[] }
  | { type: 'SendOfferToBIPending' }
  | { type: 'SendOfferToBISuccess' }
  | { type: 'SendOfferToBIError' }
  | { type: 'SetFavorite'; payload: boolean }
  | { type: 'SubscribePriceRequest' }
  | { type: 'SubscribePriceSuccess' }
  | { type: 'SubscribePriceStatus'; payload: ISubscriptionStatus }
  | { type: 'SubscribePriceFail' }
  | { type: 'NotFoundOffer'; value: boolean }
  | { type: 'SetHideOfferOnCard'; payload: boolean }
  | {
      type: 'GetSimilarOfferSuccess';
      payload: {
        similarOffersData: ISimilarOffersResponseItem[];
        withoutCompetitors: boolean;
        modelVersion: TModelVersion;
      };
    }
  | { type: typeof SAFE_RENT_POPUP_SHOW; payload: { isShow: boolean } }
  | { type: 'GetGKMVKPending' }
  | { type: 'GetGKMVKFailure' }
  | { type: 'GetGKMVKSuccess'; payload: IGKMVKDataResponse }
  | { type: 'GetSimilarGKOffersPending' }
  | { type: 'GetSimilarGKOffersFailure' }
  | { type: 'GetSimilarGKOffersSuccess'; payload: ISimilarGKOffersResponse }
  | { type: 'SetOfferCorrectHost'; payload: { correctHost: string } }
  | { type: 'SetOffer'; payload: IOfferInfo };

export interface ISubscribePriceDispatchResult {
  isEmailConfirmed: boolean;
}

export function logToken(token: string): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    dispatch<TOfferActions>({ type: 'LogTokenPending' });

    return toPromise(api.logToken(token))
      .then(resp => {
        const { status } = resp.result;

        if (status.toLowerCase() === 'ok') {
          dispatch<TOfferActions>({ type: 'LogTokenSuccess' });
        } else {
          throw new Error(`No statistic_token to log`);
        }
      })
      .catch(err => {
        dispatch<TOfferActions>({ type: 'LogTokenError' });
        throw err;
      });
  };
}

export function getOfferInfo(dealType: string, objectType: string, id: string): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    const urlParams = { dealType, objectType, id: Number(id) } as IOfferURLParams;
    const { searchList } = getState();

    //эксперимент: продавец онлайн
    const getIsAgentOnline = (experiments: IExperimentResultSchema[]): boolean => {
      return false;
    };

    if (searchList.initialized) {
      for (const page of searchList.pages) {
        const offer = page.offers.find(o => isEqual(o.data.urlParams, urlParams));
        if (offer) {
          const isAgentOnline = getIsAgentOnline(getState().experiments);
          offer.data.isAgentOnline = isAgentOnline;
          if (offer.data.gaLabel) {
            const gaLabelAddition = `${isAgentOnline ? 'online' : 'offline'}/`;
            offer.data.gaLabel = offer.data.gaLabel + gaLabelAddition;
          }
          dispatch<TOfferActions>({
            type: 'GetOfferInfoFromOffersList',
            offer: offer.data,
            breadcrumbs: searchList.breadcrumbs,
          });

          return Promise.resolve();
        }
      }
    }

    dispatch<TOfferActions>({ type: 'GetOfferInfoPending' });

    return (
      api
        .getOffer(dealType, objectType, id)
        // tslint:disable-next-line:no-any
        .then((resp: any) => {
          if (resp.responseStatusCode && resp.responseStatusCode === 404) {
            dispatch<TOfferActions>({
              type: 'NotFoundOffer',
              value: true,
            });

            return;
          }

          const { data, status } = JSON.parse(resp.responseBody);

          if (status && status.toLowerCase() !== 'ok') {
            throw new Error(`Wrong response: ${status}`);
          }

          const { geo } = data.offer;
          const region = geo && geo.address && geo.address[0] && geo.address[0].id;

          if (data.correctHost) {
            dispatch<TOfferActions>({
              type: 'SetOfferCorrectHost',
              payload: {
                correctHost: data.correctHost,
              },
            });
          }

          if (status && status.toLowerCase() === 'ok') {
            const isAgentOnline = getIsAgentOnline(getState().experiments);
            data.offer.isAgentOnline = isAgentOnline;
            if (data.offer.gaLabel) {
              const gaLabelAddition = `${isAgentOnline ? 'online' : 'offline'}/`;
              data.offer.gaLabel = data.offer.gaLabel + gaLabelAddition;
            }
            dispatch<TOfferActions>({ type: 'GetOfferInfoSuccess', payload: { data, urlParams } });
            if (region) {
              dispatch(setRegion({ region }));
            } else {
              throw new NotFoundError({
                message: 'Region for offer not specified',
                domain: 'actions/getOfferInfo',
                details: geo,
              });
            }
          } else {
            throw new Error(`Wrong response: ${status}`);
          }
        })
        .catch((err: Error) => {
          throw logger.error(err);
        })
    );
  };
}

export function getSimilarOffersInfo(id: string | number): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    return toPromise(api.getSimilarOffers(id))
      .then(response => {
        const similarOffersData = response.result.data.similarOffers;
        const withoutCompetitors = response.result.data.withoutCompetitors;
        const modelVersion = response.result.data.modelVersion;
        if (isEmptyArray(similarOffersData)) {
          const state = getState();
          const { common, dataLayer, offerPage } = state;
          const user = {
            abGroup: dataLayer.initialized && dataLayer.data.abGroup ? Number(dataLayer.data.abGroup) : 100,
            isAuthorized: Boolean(dataLayer.initialized && dataLayer.data.realtyUserId),
            userId: dataLayer.initialized && dataLayer.data.userId ? Number(dataLayer.data.realtyUserId) : undefined,
          };
          const page = {
            breadCrumbs: offerPage.breadcrumbs ? offerPage.breadcrumbs.map(breadcrumb => breadcrumb.title) : [],
            offerType: 'offer' as const,
            offerID: offerPage.offer.id,
            pageType: 'Card' as const,
            siteType: (common.deviceType === 'phone' ? 'mobile' : common.deviceType) as SiteType,
          };
          trackShowSimilar(
            {
              user,
              offerData: [],
              page,
            },
            withoutCompetitors,
          );
        }
        dispatch({ type: 'GetSimilarOfferSuccess', payload: { similarOffersData, withoutCompetitors, modelVersion } });
      })
      .catch(err => {
        logger.error(err);
      });
  };
}

export function getComplaintsTree(): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    dispatch<TOfferActions>({ type: 'GetComplaintsTreePending' });
    const state = getState();
    const { common, offerPage } = state;
    const region = common.userRegion ? common.userRegion : undefined;
    const { geo } = offerPage.offer;
    const offerAddess = geo ? geo.address : undefined;
    const offerRegion = offerAddess && offerAddess.length ? offerAddess[0].id : Regions.Moscow;
    const userRegion = region ? region.id : offerRegion;
    const { categoriesIds } = offerPage.offer;
    let category;
    if (categoriesIds && categoriesIds.length) {
      category = categoriesIds[categoriesIds.length - 1];
    } else {
      category = 111;
    }

    return toPromise(api.getComplaintsTree(category, userRegion || Regions.Moscow))
      .then(resp => {
        dispatch<TOfferActions>({ type: 'GetComplaintsTreeSuccess', payload: resp.result });
      })
      .catch(err => {
        throw logger.error(err);
      });
  };
}

export function sendComplaint(complaint: ISendComplaintRequest): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    dispatch<TOfferActions>({ type: 'SendComplaintPending' });

    return toPromise(api.sendComplaint(complaint))
      .then(resp => {
        const { complaintId, getUserFeedback } = resp.result;
        dispatch<TOfferActions>({ type: 'SendComplaintSuccess' });
        dispatch(setComplaintId(complaintId));
        dispatch(setUserFeedback(getUserFeedback));
      })
      .catch(err => {
        dispatch<TOfferActions>({
          type: 'SendComplaintError',
          payload: 'Не удалось отправить жалобу. Проверьте подключение к сети и попробуйте ещё раз.',
        });

        logger.error(err);

        throw err;
      });
  };
}

export function clearComplaint(): TOfferActions {
  return { type: 'ClearComplaint' };
}

export function sendOfferToBI(dealType: string, objectType: string, id: string): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    dispatch<TOfferActions>({ type: 'SendOfferToBIPending' });

    return toPromise(api.sendOfferToBI(dealType, objectType, id))
      .then(() => {
        dispatch<TOfferActions>({ type: 'SendOfferToBISuccess' });
      })
      .catch(() => {
        dispatch<TOfferActions>({ type: 'SendOfferToBIError' });
      });
  };
}

export function setFavorite(
  dealType: string,
  objectType: string,
  offerId: number,
  state: boolean,
): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    const oldFavoriteCount = getState().favorites.count;
    const { isFavorite: oldFavoriteState } = getState().offerPage.offer;

    dispatch(setFavoritesCount(state ? oldFavoriteCount + 1 : oldFavoriteCount - 1));
    dispatch<TOfferActions>({ type: 'SetFavorite', payload: state });

    return toPromise(api.setOfferFavoriteStatus(dealType, objectType, offerId, state))
      .then(resp => {
        const { status } = resp.result;

        if (status && status.toLowerCase() === 'ok') {
          /// TODO: вернуть, когда бэк удалит архивные объявы из счетчик
          // dispatch(setFavoritesCount(count.total));
          dispatch(fetchFavoritesCount());
          dispatch(setOfferFavoriteStatus({ dealType, objectType, offerId, state }));
          dispatch(getPriceSubcriptionStatusAction(String(offerId)));
        } else {
          throw new Error(`Wrong response: ${status}`);
        }
      })
      .catch(err => {
        /// TODO: вернуть, когда бэк удалит архивные объявы из счетчик
        // dispatch(setFavoritesCount(oldFavoriteCount));
        dispatch(fetchFavoritesCount());
        dispatch<TOfferActions>({ type: 'SetFavorite', payload: oldFavoriteState });

        logger.error(err);

        throw err;
      });
  };
}

export const getOfferIdForSubscribe = (offer: IOfferInfo): number => {
  const offerAnalyticsInfo = offer.offerAnalyticsInfo;

  return offerAnalyticsInfo && offerAnalyticsInfo.id ? offerAnalyticsInfo.id : offer.urlParams.id;
};

export function SubscribePriceStatus(payload: ISubscriptionStatus): TOfferActions {
  return {
    type: 'SubscribePriceStatus',
    payload,
  };
}

export function getPriceSubcriptionStatusAction(offerId: string): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    return toPromise(api.getPriceSubcriptionStatus(offerId))
      .then(response => {
        const { result } = response;
        if (response.response && response.response.ok) {
          dispatch(SubscribePriceStatus(result as ISubscriptionStatus));
        } else {
          const errors = (result as ISubscriptionStatusError).errors.join('\n');

          throw new Error(
            'Ошибка при получении статуса подписки:' + `${(result as ISubscriptionStatusError).message}:\n${errors}`,
          );
        }
      })
      .catch(err => {
        logger.error(err);

        return Promise.resolve();
      });
  };
}

export function subscribePrice(
  email: string,
  subscribeNews: boolean,
  showPushNotificationCheckbox: boolean,
  isPushCheck: boolean,
): TTypedThunkAction<Promise<ISubscribePriceDispatchResult>> {
  return (dispatch, getState, { api, logger }) => {
    const { id: offerId, dealType, objectType: offerType } = getState().offerPage.offer.urlParams;

    if (!offerId) {
      dispatch<TOfferActions>({ type: 'SubscribePriceFail' });
      dispatch(setError('Не удалось подписаться.'));
      const err = new Error('Falsy id from getOfferIdForSubscribe on subscription attempt');
      logger.error(err);

      return Promise.reject(err);
    }

    dispatch<TOfferActions>({ type: 'SubscribePriceRequest' });

    return toPromise(api.subscribePrice({ email, offerId, dealType, offerType, subscribeNews }))
      .then(async (response): Promise<ISubscribePriceDispatchResult> => {
        const { status } = response.result;

        if (status && status.toLowerCase() === 'ok') {
          dispatch(deleteError());

          const logOnInfo = response.result.data && response.result.data.logOnInfo;
          const login = response.result.data && response.result.data.login;
          const { isAuthenticated } = getState().common;
          if (!isAuthenticated && logOnInfo && login) {
            const loginPromises = logOnInfo.map(logOnInfoItem => {
              return toPromise(
                api.userLogon({
                  login,
                  token: logOnInfoItem.token,
                  persistent: true,
                  url: logOnInfoItem.logOnUrl,
                }),
              ).then(res => Promise.resolve(res));
            });

            await Promise.all(loginPromises).then(async () => {
              await dispatch(getHeaderData());
              dispatch({ type: 'AuthSuccess' });
              trackSubscriptionRegistration();
            });
          }
          dispatch(replace(document.location.href));
          cookiesUtils.set('subscriptionEmail', email, yearsToMilliseconds(1));

          const subscriptionStatus = {
            isEmailConfirmed: response.result.data.isEmailConfirmed || false,
            isSubscribedOnNews: subscribeNews,
            isSubscribedOnPrice: true,
          };
          dispatch(SubscribePriceStatus(subscriptionStatus));
          dispatch<TOfferActions>({ type: 'SubscribePriceSuccess' });

          trackSubscriptionPriceSuccess(showPushNotificationCheckbox, isPushCheck);
          if (subscribeNews) {
            trackSubscriptionNewsSuccess();
          }

          return Promise.resolve({
            isEmailConfirmed: response.result.data.isEmailConfirmed || false,
          });
        } else {
          throw new Error(`Wrong response: ${status}`);
        }
      })
      .catch(err => {
        dispatch<TOfferActions>({ type: 'SubscribePriceFail' });
        try {
          const errCode: string = get(JSON.parse(get(err, 'cause.response.text')), 'data.errors.code');
          if ('email_subscribed' === errCode) {
            const errorText = 'Введенный электронный адрес уже подписан.';
            dispatch(setError(errorText));

            return Promise.reject(new Error(errorText));
          } else {
            throw new Error();
          }
        } catch (e) {
          dispatch(
            setError(
              [
                'Не удалось подписаться.',
                'Проверьте правильность указанного электронного адреса и повторите попытку.',
              ].join('\n'),
            ),
          );

          return Promise.reject(e);
        }
      });
  };
}

export function getSimilarGKOffers(offerId: number): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    dispatch<TOfferActions>({ type: 'GetSimilarGKOffersPending' });

    return toPromise(api.getSimilarGKOffers(offerId))
      .then(resp => {
        const { result } = resp;

        if (result) {
          dispatch<TOfferActions>({ type: 'GetSimilarGKOffersSuccess', payload: result });
        } else {
          dispatch<TOfferActions>({ type: 'GetSimilarGKOffersFailure' });
          throw new Error('Wrong response');
        }
      })
      .catch(err => {
        dispatch<TOfferActions>({ type: 'GetSimilarGKOffersFailure' });
        logger.error(err);
      });
  };
}

export function setOffer(offer: IOfferInfo): TTypedThunkAction<void> {
  return dispatch => {
    dispatch<TOfferActions>({ type: 'SetOffer', payload: offer });
  };
}

export function safeRentPopupShow(isShow: boolean): TTypedThunkAction<void> {
  return dispatch => {
    dispatch<TOfferActions>({ type: SAFE_RENT_POPUP_SHOW, payload: { isShow } });
  };
}
