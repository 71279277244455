import { isUpdate } from '../../utils/react_helpers';
import { TFixedComponentsActions } from './actions';
import { defaultFixedComponentsState, FixedComponentsState } from './model';

type Reducer = (state: FixedComponentsState, action: TFixedComponentsActions) => FixedComponentsState;

const fixedCompoenetsReducer: Reducer = (state = defaultFixedComponentsState, action) => {
  switch (action.type) {
    case 'SetAppBannerState':
    case 'SetSearchButtonFixed':
    case 'SetShowContactsButtonFixed':
    case 'SetShowContactsPopupVisibile':
      return isUpdate(action.payload, state)
        ? { ...state, ...action.payload }
        : state;

    default:
      return state;
  }
};

export default fixedCompoenetsReducer;
