import { ILiftType, IOfferDetail, LiftTypeType } from '../../../api/models/offer_card';
import { IBuildingInfo, ObjectType } from '../model';
import { parseBuildingType, parseObjectType } from './base_info_parser';

function parseNumber(num: string | number): number;
function parseNumber(num?: string | number): number | undefined;
function parseNumber(num?: string | number): number | undefined {
  if (num == null) {
    return;
  }
  if (typeof num === 'string') {
    return parseFloat(num);
  }
  return num;
}

function getLiftsCount(liftTypes: ILiftType[], type: LiftTypeType) {
  const lift = liftTypes && liftTypes.find(l => l && l.type === type);

  return lift && lift.count;
}

function getAllBuildingData(offer: IOfferDetail): IBuildingInfo | undefined {
  const { building } = offer;
  if (!building) {
    return;
  }
  const objectType = parseObjectType(offer);
  const possiblePurpose = parseBuildingType(offer);
  const isBuilding = objectType === ObjectType.Building;

  const area = offer.land && offer.land.area != null
    ? (parseNumber(offer.land.area) * (offer.land.areaUnitType === 'sotka' ? 0.01 : 1))
    : undefined;

  return {
    objectType: ObjectType.Building,
    // Название
    name: building.name,
    // Год постройки
    yearBuilt: building.buildYear,
    // Тип здания
    type: building.type,
    // Масштаб комплекса
    scaleOfTheComplex: building.shoppingCenterScaleType,
    // Класс здания (A/A+/B/...)
    classType: building.classType,
    // Этажей в здании
    floorsCount: building.floorsCount,
    // Высота потолков
    ceilingHeight: parseNumber(building.ceilingHeight),
    // Возможное назначение
    possiblePurpose,
    // Состояние
    conditionRating: isBuilding
      ? offer.conditionType
      : building.conditionRatingType,
    // Мебель (Есть/Нет)
    hasFurniture: offer.hasFurniture,
    // Линия домов
    houseLine: building.houseLineType,
    // Общая площадь
    totalArea: parseNumber(isBuilding ? offer.totalArea : building.totalArea),
    // Участок
    land: {
      // площадь (га)
      area,
      // в собственности / в аренде
      status: offer.land && offer.land.type,
    },
    // Категория (Новостройка / Действующее / Проект / Втроичка / Строящееся)
    status: building.statusType,
    // Девелопер (название)
    developer: building.developer,
    // Управляющая компания (название)
    managementCompany: building.managementCompany,
    // Вход (Отдельный со двора / Отдельный с улицы / Общий со двора / Общий с улицы)
    entrance: {
      // type: 'personal' | 'common';
      type: offer.inputType && offer.inputType.includes('common') ? 'common' : 'personal',
      // from: 'yard' | 'street';
      from: offer.inputType && offer.inputType.includes('FromYard') ? 'yard' : 'street',
    },
    // Лифты
    liftsCount: building.liftTypes && getLiftsCount(building.liftTypes, 'lift'),
    // Траволаторы
    theMovingWalkwaysCount: building.liftTypes && getLiftsCount(building.liftTypes, 'travelator'),
    // Эскалаторы
    escalatorsCount: building.liftTypes && getLiftsCount(building.liftTypes, 'escalator'),
    // Часы работы
    workDays: building.workingDaysType,
    // Рабочие дни
    workHours: building.openingHours,
  };
}

export function responseToBuildingInfoModel(offer: IOfferDetail): IBuildingInfo | undefined {
  // 1. Сначала получаем всю информацию о здании, которая приходит с бекенда
  const data = getAllBuildingData(offer);
  const objectType = parseObjectType(offer);

  if (!data || !objectType || offer.offerType !== 'commercial') {
    return;
  }

  // 2. Потом фильтруем для нужных объектов
  // Можно было использовать _.pick, но тогда теряется типизация
  if ([
    ObjectType.Office,
    ObjectType.FreeAppointmentObject,
    ObjectType.Industry,
    ObjectType.Warehouse,
  ].includes(objectType)) {
    return {
      objectType: objectType,
      name: data.name,
      yearBuilt: data.yearBuilt,
      type: data.type,
      classType: data.classType,
      totalArea: data.totalArea,
      land: data.land,
      status: data.status,
      developer: data.developer,
      managementCompany: data.managementCompany,
    };
  }

  if (objectType === ObjectType.ShoppingArea) {
    return {
      objectType: data.objectType,
      name: data.name,
      type: data.type,
      scaleOfTheComplex: data.scaleOfTheComplex,
      classType: data.classType,
      totalArea: data.totalArea,
      land: data.land,
      status: data.status,
      developer: data.developer,
      managementCompany: data.managementCompany,
      liftsCount: data.liftsCount,
      theMovingWalkwaysCount: data.theMovingWalkwaysCount,
      escalatorsCount: data.escalatorsCount,
      workDays: data.workDays,
      workHours: data.workHours,
    };
  }

  if (objectType === ObjectType.Building) {
    return {
      objectType: data.objectType,
      name: data.name,
      yearBuilt: data.yearBuilt,
      classType: data.classType,
      totalArea: data.totalArea,
      floorsCount: data.floorsCount,
      ceilingHeight: data.ceilingHeight,
      possiblePurpose: data.possiblePurpose,
      conditionRating: data.conditionRating,
      hasFurniture: data.hasFurniture,
      houseLine: data.houseLine,
      land: data.land,
      status: data.status,
      developer: data.developer,
      managementCompany: data.managementCompany,
      entrance: data.entrance,
      liftsCount: data.liftsCount,
      theMovingWalkwaysCount: data.theMovingWalkwaysCount,
      escalatorsCount: data.escalatorsCount,
    };
  }

  if (objectType === ObjectType.Garage) {
    return {
      objectType: data.objectType,
      name: data.name,
    };
  }

  return;
}
