import { without } from 'lodash';
import * as React from 'react';

import { ISelectMobileOption, Label } from '@cian/components';
import { SteadStatus, suburbanObjectTypes } from '../../../redux/filters/model';
import { SelectMobile } from '../../common/select/select';
import { IFieldProps, IsVisibleCheck } from '../field';

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const statusList: ISelectMobileOption[] = [{
      id: SteadStatus.Farm.toString(),
      label: 'Фермерское хозяйство',
    }, {
      id: SteadStatus.Gardening.toString(),
      label: 'Садоводство',
    }, {
      id: SteadStatus.IJS.toString(),
      label: 'ИЖС',
    }, {
      id: SteadStatus.Industry.toString(),
      label: 'Земля промназначения',
    }, {
      id: SteadStatus.DNP.toString(),
      label: 'ДНП',
    }];
    const currentValue = this.props.filter.steadStatuses.map(String);

    return (
      <div data-mark="FieldSteadStatus">
        <Label label="Статус участка">
          <SelectMobile
            multiple
            onValueChange={this.onValueSelect}
            options={statusList}
            label="Неважно"
            value={currentValue}
          >
          </SelectMobile>
        </Label>
      </div>
    );
  }

  private onValueSelect = (options: string[]) => {
    const selectedValue = options.map(Number);
    this.props.actions.setSteadStatus(selectedValue);
  }
}

export const isVisible: IsVisibleCheck = filter =>
  filter.selectedObjectTypes.length > 0
  && without(filter.selectedObjectTypes, ...suburbanObjectTypes).length === 0;
