import * as React from 'react';

import { CheckboxField, mergeStyles } from '@cian/components';
import { IFieldProps, IsVisibleCheck } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    return this.props.filter.withPhoto !== nextProps.filter.withPhoto;
  }

  public render() {
    const { filter: { withPhoto } } = this.props;
    const labelStyles = mergeStyles(
      styles.labelCheckbox,
      withPhoto && styles.checked,
    ).className;

    return (
      <div className={styles.checkbox} data-mark="FieldWithPhoto">
        <CheckboxField
          mobile
          label="Фото"
          labelStyle={labelStyles}
          value={withPhoto}
          onValueChange={this.props.actions.setWithPhoto}
        />
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => true;
