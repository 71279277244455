import { FormField } from '@cian/ui-kit';

import * as React from 'react';

import { useGetBusinessPossibleAppointmentsQuery } from '../../../../shared/store/serverSide/announcements/v1/get-business-possible-appointments/query';
import { getIsBusinessCategory } from '../../../../shared/utils/getIsBusinessCategory';
import { FilterButton } from '../../../ui/FilterDropdown/internal/components/FilterButton';
import { IFieldProps, IsVisibleCheck, isBusiness, isSaleType } from '../field';

import { AllBusinessAppointments } from './internal/components/AllBusinessAppointments';
import { ReadyBusinessAppointments } from './internal/components/ReadyBusinessAppointments';
import { RentalBusinessAppointments } from './internal/components/RentalBusinessAppointments';

export const Component = React.memo<IFieldProps>(function FilterBusinessAppointments(props) {
  const { filter, actions } = props;

  const { data: businessPossibleAppointments } = useGetBusinessPossibleAppointmentsQuery();

  const { isRentalBusiness, isReadyBusiness, isAll } = getIsBusinessCategory(filter.selectedObjectTypes);

  if (!businessPossibleAppointments) {
    return (
      <FormField label="Категория">
        <FilterButton
          isPlaceholderLabel
          count={0}
          isActive={false}
          label="Выберите категорию"
          onClick={(): void => {}}
        />
      </FormField>
    );
  }

  if (isAll) {
    return (
      <AllBusinessAppointments
        businessPossibleAppointments={businessPossibleAppointments}
        setBusinessAppointments={actions.setBusinessAppointments}
        values={filter.businessAppointments}
      />
    );
  }

  if (isReadyBusiness) {
    return (
      <ReadyBusinessAppointments
        groups={businessPossibleAppointments.readyBusiness.groups}
        setBusinessAppointments={actions.setBusinessAppointments}
        values={filter.businessAppointments}
      />
    );
  }

  if (isRentalBusiness) {
    return (
      <RentalBusinessAppointments
        items={businessPossibleAppointments.rentalBusiness.items}
        setBusinessAppointments={actions.setBusinessAppointments}
        values={filter.businessAppointments}
      />
    );
  }

  return null;
});

export const isVisible: IsVisibleCheck = filter => {
  return [isSaleType, isBusiness].every(fn => fn(filter));
};
