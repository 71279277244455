import * as React from 'react';

import { Label } from '@cian/components';
import RangeComponent from '../../common/range/range';
import { IFieldProps, IsVisibleCheck } from '../field';

const fieldStyles = require('../field.css');

export class Component extends React.Component<IFieldProps> {
  public render() {
    return (
      <div className={fieldStyles.label} data-mark="FieldFloor">
        <Label label="Этаж">
          <RangeComponent
            field="floor"
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => true;
