import { IGKData } from '../../../api/models/gk_card';
import { IFlatType, IQueryFlatTypesStats } from '../../../api/models/gk_preview';
import { routeFor } from '../../../utils/routeFor';
import { IGKFlats } from '../model';

const getFlatsCount = (flats: IFlatType[]): number => flats.reduce((a, b) => a + b.offers_count, 0);

const mapIFlatTypeToIQueryFlatTypesStats = (flat: IFlatType): IQueryFlatTypesStats => ({
  max_price: flat.max_price,
  max_total_area: 0,
  min_price: flat.min_price,
  min_total_area: flat.min_total_area,
  offers_count: flat.offers_count,
  query: flat.query,
  query_string: flat.qs,
  type: flat.type,
});

const sortFlats = (a: IQueryFlatTypesStats, b: IQueryFlatTypesStats) => {
  const studio = 9;
  const changeStudioVlaue = (value: number) => value && value === studio ? -1 : value;

  let value1 = changeStudioVlaue(a.type) || 0;
  let value2 = changeStudioVlaue(b.type) || 0;

  return value1 - value2;
};

export const parseFlats = (gk: IGKData): IGKFlats => {
  return {
    developer: {
      flats: gk.flat_types_developer
        .map(mapIFlatTypeToIQueryFlatTypesStats)
        .sort(sortFlats)
      ,
      totalCount: getFlatsCount(gk.flat_types_developer),
      link: `${routeFor.OFFERS_LIST}?${gk.qs}&from_developer=1`,
    },
    agent: {
      totalCount: getFlatsCount(gk.flat_types_agent),
      link: `${routeFor.OFFERS_LIST}?${gk.qs}&from_developer=0`,
    },
  };
};
