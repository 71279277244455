/* eslint-disable max-lines */

import { ca } from '@cian/analytics';

import { get } from 'lodash';

import { IOfferSerialized, IRegionInfo } from '../api/api';
import { IUserGaDataLayer, TUserGaType } from '../api/models/data_layer';
import { IGKPhoneDataLayer } from '../api/models/gk_preview';
import { IJsonQuery } from '../api/models/json_query';
import { IAnalyticsProducts, OfferDetailStatus } from '../api/models/offer_card';
import { FiltersPlace } from '../filters/fields/field';
import { DeviceType } from '../redux/common/model';
import { rentTimeDict } from '../redux/filters/dictionaries';
import { getRentTime } from '../redux/filters/json_query_to_filter';
import { BuildingStatus } from '../redux/filters/model';
import {
  IBaseInfo,
  IOfferAnalyticsInfo,
  IOfferInfo,
  IPhoto,
  IValueAddedServices,
  RentTime,
} from '../redux/offer/model';
import { IOfferSource } from '../redux/offers_list/model';
import { RouteName } from '../redux/routing/model';

import { isTablet } from './device_detector';
import logException from './exceptions';
import { getOfferExtra } from './getOfferExtra';
import { getOfferAnalyticsData } from './hidden_objects';
import { isClient } from './isomorphic';
import { normalizeAnalytcsEventData } from './normalizeAnalytcsEventData';
import { isSuburbanOfferFromBuilder } from './suburban';
import { mlSearchSession } from './analytics/mlSearchSession';

export type TPageType = 'Listing' | 'Card' | 'Map';

export type AnalyticsEvent =
  // gk
  | 'jk-card'
  | '1-komnatnye'
  | '2-komnatnye'
  | '3-komnatnye'
  | '4-komnatnye_i_bolee'
  | 'studii'
  | 'free_planning'
  | 'all-variants'
  | '' // for links which never be shown

  // menu
  | 'new_search'
  | 'main_page'
  | 'novostroyki'
  | 'favorite'
  | 'feedback'
  | 'PIK'

  // main page
  | 'kupit'
  | 'snyat'

  // subscription
  | 'From_cat_mob'
  | 'From_Empty_mob'
  | 'From_cat_jk_mob'
  | 'From_Empty_jk_mob'

  // card
  | 'from_card'
  | 'from_card_mob'
  | 'from_card_mob_ChangePrice'
  | 'map'
  | 'panorama'

  // menu and footer
  | 'app_ios'
  | 'app_android'
  | 'full-version'
  | 'newpage';

export type AnalyticsPlace =
  | 'jk-listing'
  | 'page'
  | 'similar_search'
  | 'complain'
  | 'bannerClick'
  | 'feedback'
  | 'subscription'
  | 'Pagination'
  | 'Phones'
  | 'Phones_from_banner'
  | 'favorite'
  | 'Listing'
  | 'similar_objects'
  | 'Mob_BreadCrumbs'
  | 'Mob_head'
  | 'Mob_menu'
  | 'Mob_main_page'
  | 'Mob_advanced_search'
  | 'Mob_filter_page'
  | 'Mob_refineSearch_popup'
  | 'Mob_footer'
  | 'card_mob'
  | 'OldBrowserPopup'
  | 'card'
  | 'Promo_app'
  | 'Tablet_head'
  | 'Tablet_Listing'
  | 'Mob_smart_banner'
  | 'Card_JK'
  | 'CallRate_popup'
  | 'CallRate_popup_gallery'
  | '404'
  | 'GEO_block'
  | 'Mob_favorite'
  | 'Skin'
  | 'Mob_map'
  | 'Map'
  | 'Registration'
  | 'reg_popup'
  | 'Auth_popup'
  | 'auth_popup'
  | 'Authorization'
  | 'Mob_syncFavorites_popup'
  | 'Mob_gotoLK_button'
  | 'Message_popup';

export interface IUserAnalyticsData {
  cianUserId?: string | number;
  userId?: string | number;
  email?: string;
  type: TUserGaType | null;
  abGroup?: string | number;
  extra?: {
    defaultRegionId: number | undefined;
  };
}

export interface ICommonPartnerAnalyticsData {
  CriteoEmail?: string;
  CriteoNewCustomer?: number | string;
  CriteoProducts?: number[];
  FB_City?: string;
  FB_Region?: string;
}

export type QueryDealType = 'sale' | 'rent_long' | 'rent_day' | 'other';

export type ObjectType =
  | 'flat'
  | 'suburban'
  | 'suburban_dev'
  | 'commercial'
  | 'flat_new'
  | 'flat_new_dev'
  | 'flat_old'
  | 'flat_old,flat_new'
  | 'BC'
  | 'TC'
  | 'JK'
  | 'other';

export type PageType =
  | 'Listing'
  | 'ListingJK'
  | 'Card'
  | 'Newobject_card'
  | 'Home'
  | 'Filter'
  | 'Card_JK'
  | 'CardJK'
  | 'Map'
  | 'MyOffers'
  | 'Other'
  | '404'
  | '';

export type SiteType = 'mobile' | 'tablet' | 'desktop';
export type TCategory = 'Main' | 'Map' | 'Listing';

export interface ICommonAnalyticsData {
  // то, что сейчас передается в dimension13
  pageType: PageType;
  // то, что сейчас передается в dimension10
  region?: string | number;
  // mobile или desktop
  siteType?: SiteType;
}

export type OfferVAS = 'free' | 'standard' | 'premium' | 'top3' | '';

export type IMainPageAnalytics = ICommonAnalyticsData;

export type TOfferType = 'offer' | 'JK' | 'KP' | 'BC' | 'TC' | 'WC';

export interface IOfferPageAnalytics extends ICommonAnalyticsData {
  // новое поля для ЕБЦА (обобщенный тип офера, на самом деле в мобилке может быть либо offer, либо JK)
  offerType?: TOfferType;
  // все уровни
  breadCrumbs?: string[];
  // то, что сейчас передается в dimension12
  objectType?: ObjectType;
  // то, что сейчас передается в dimension11
  dealType?: QueryDealType;
  // ID объявления
  offerID: string | number | undefined;
  // цена объекта
  offerPrice?: string | number;
  // "Сдается офис за 700 руб./месяц, 80.0 м², этаж 3/3"
  offerName?: string;
  //  справочник: free, free+color, standard, premium, premium+color, top3
  offerVAS?: OfferVAS;
  // id автора объявления + его тип
  // (например, 123567/realtor_based, 1264975/realtor_not_commerce, 154/realtor_prof, 1634/physical, 1427/developer)
  offerAuthor?: string;
  // цифрами, если телефона 2 или больше, то передаем 1 любой из них
  offerPhone?: string;
  // кастомный url
  customPageURL?: string;
  offersQty?: '';
  // кол-во объявлений по новостройкам
  newObjects?: number | undefined;
  // кол-во объявлений от застройщика
  fromDeveloper?: number | undefined;

  // в карточке объекта привязанного к ЖК передавать ID и название этого ЖК:
  offerJKID?: string;
  offerJKName?: string;
  photosCount?: number;
  published?: number;
  hiddenBase?: number;
  region?: string;
}

export interface IGKPageAnalytics extends ICommonAnalyticsData {
  breadCrumbs?: string[];
  customPageURL: string;
  dealType: 'sale';
  // кол-во объявлений от застройщика
  fromDeveloper?: string | number;
  objectType: 'JK';
  // id
  offerID: string | number;
  offerName: string;
  offerPhone?: string;
  pageType: 'CardJK';
  region: string;
  reviewsQty: number;
  newObjects?: number;
}

export interface IProductsGKPage {
  id: number;
  objectType: string;
  dealType: string;
  position: number;
  brand: string | undefined;
  nv: boolean;
  name: string | undefined;
}

export interface IContactsPageAnalytics extends ICommonAnalyticsData {
  objectType: ObjectType;
  dealType: QueryDealType;
}

export interface IMapPageAnalytics extends ICommonAnalyticsData {
  objectType: ObjectType;
  dealType: QueryDealType;
}

export interface IListPageAnalytics extends ICommonAnalyticsData {
  customPageURL?: string;
  /** все уровни */
  breadCrumbs?: string[];
  /** то, что сейчас передается в dimension12 */
  objectType: ObjectType;
  /** то, что сейчас передается в dimension11 */
  dealType: QueryDealType;
  offersQty: number;
  listingType?: TListingType;
  pageNumber?: number;
  sortType?: string;
  queryString?: string;
  /** уникальный идентификатор поискового запроса */
  searchGuid?: string;
  /** уникальный id ML-ранжирования */
  mlRankingGuid?: string | null;
  /** версия модели ML-ранжирования */
  mlRankingModelVersion?: string | null;
}

export interface IProductsSOPRData {
  id: number | undefined;
  cianId?: number | undefined;
  parentId?: number;
  ownerId?: number | undefined;
  ownerCianId?: number | undefined;
  dealType: string | undefined;
  objectType: string | undefined;
  name?: string;
  position: number;
  auctionPosition?: number;
  published?: boolean;
  price?: number;
  variant?: string[];
  brand: string | undefined;
  photosCount?: number;
  hiddenBase?: boolean;
  repres?: boolean;
  podSnos?: boolean;
  nv?: boolean;
  owner?: boolean;
}

export type TListingType = 'listing' | 'list' | 'map' | 'mapAuto';

export interface IOfferAnalyticsData {
  label?: string | null;
  id?: number | string | null;
  name?: string | null;
  price?: number | string | null;
  brand?: string | null;
  category?: string | null;
  variant?: string[] | string | null;
  position?: string | number | null;
  quantity?: number | null;
  owner?: boolean;
  cianId?: number;
  nv?: boolean;
  ownerId?: number;
  ownerCianId?: number;
  dealType?: string;
  objectType?: string;
  published?: boolean;
  photosCount?: number;
  headline?: boolean;
  hiddenBase?: boolean;
  podSnos?: boolean;
  parentId?: number;
  modelVersion?: string;
  consultant?: boolean;
  // tslint:disable-next-line:no-any
  extra?: any;
}

interface INewPageGTMEvent {
  event: 'newpage';
  user: IUserAnalyticsData;
  partner?: ICommonPartnerAnalyticsData;
  products?: any; // tslint:disable-line
  searchConditions?: IJsonQuery;
  searchResults?: number[];
}

export interface IMainPageAnalyticsGTMEvent extends INewPageGTMEvent {
  page: IMainPageAnalytics;
}

export interface IListPageAnalyticsGTMEvent extends INewPageGTMEvent {
  page: IListPageAnalytics;
  partner: ICommonPartnerAnalyticsData;
}

export interface IOfferPageAnalyticsGTMEvent extends INewPageGTMEvent {
  page: IOfferPageAnalytics;
}

export interface IContactsPageAnalyticsGTMEvent extends INewPageGTMEvent {
  page: IContactsPageAnalytics;
}

export type PageAnalyticsGTMEvent =
  | IMainPageAnalyticsGTMEvent
  | IListPageAnalyticsGTMEvent
  | IOfferPageAnalyticsGTMEvent
  | IContactsPageAnalyticsGTMEvent;

export type AnalyticsAction =
  | 'click'
  | 'show'
  | 'page'
  | 'newpage_infinite_search'
  | 'to_card_infinite_search'
  | 'click_similar_search'
  | 'Call'
  | 'Call_gallery'
  | 'tab_click'
  | 'download'
  | 'first'
  | 'next'
  | 'advanced_search'
  | 'clean_filters'
  | 'clean'
  | 'open'
  | 'open_call'
  | 'open_message'
  | 'close'
  | 'search'
  | 'change_geo'
  | 'button_search'
  | 'from_list_mob'
  | 'direct_links'
  | 'regionOffers'
  | 'readAll'
  | 'send'
  | 'Call_from_banner'
  | 'favorite'
  | 'ipoteka_request'
  | 'flats_otherFloors'
  | 'click_breadcrumbs'
  | 'read_all'
  | 'to_card'
  | 'to_JKCard'
  | 'to_realtorCard'
  | 'to_card_similar'
  | 'show_in_listing'
  | 'map_popup_open'
  | 'map_popup_click'
  | 'Click'
  | 'Open_listing_tablet'
  | 'Close'
  | 'popup_metro_open'
  | 'popup_mck_open'
  | 'popup_district_open'
  | 'popup_metro_save'
  | 'popup_mck_save'
  | 'popup_district_save'
  | 'popup_metro_tab_click'
  | 'popup_mck_tab_click'
  | 'Open_listing_jk_tablet'
  | 'view'
  | 'rate'
  | 'click_similar_CallRate_popup'
  | 'show_similar_CallRate_popup'
  | 'click_others_CallRate_popup'
  | 'show_others_CallRate_popup'
  | 'click_similar_offers'
  | 'click_others_offers'
  | 'open_contacts'
  | 'click_promoFlats'
  | 'show_similar_offers'
  | 'show_others_offers'
  | 'popup_district_tab_click'
  | 'click_developersFlats'
  | 'click_agentsFlats'
  | 'find'
  | 'click_call'
  | 'click_no'
  | 'save_region'
  | 'click_to_change'
  | 'click_yes'
  | 'from_card'
  | 'empty_screen'
  | 'search_button'
  | 'sorting'
  | 'button_search_on_map'
  | 'search_on_map'
  | 'MAP'
  | 'LIST'
  | 'Select_Area'
  | 'Send'
  | 'Open'
  | 'select_area'
  | 'pin_click'
  | 'pinJK_click'
  | 'add_from_listing_in_map'
  | 'remove_from_listing_in_map'
  | 'add_from_list_mob'
  | 'remove_from_list_mob'
  | 'tutorial_view'
  | 'tutorial_click'
  | 'legend_view'
  | 'reg_tab_open'
  | 'auth_tab_open'
  | 'auth_popup'
  | 'auth_tab_click'
  | 'reg_tab_click'
  | 'reg_popup'
  | 'Call_listing'
  | 'Call_listing_in_map'
  | 'Open_listing_in_map'
  | 'click_next'
  | 'click_prev'
  | 'vk_click'
  | 'fb_click'
  | 'google_click'
  | 'mail_click'
  | 'ok_click'
  | 'link_accounts'
  | 'offersQty'
  | 'Open_map_JK'
  | 'use_ruler'
  | 'open_builder_stats'
  | 'master_podbora_show'
  | 'master_podbora_click'
  | 'master_podbora_zoom_drag'
  | 'master_podbora_show_similar_offers'
  | 'master_podbora_click_similar_offers'
  | 'send_message'
  | 'Open_listing'
  | 'Open_card'
  | 'Open_card_master_podbora'
  | 'Open_gallery'
  | 'Open_listing_jk';

export interface IAnalitycsEvent {
  name?: string;
  place: AnalyticsPlace;
  action: AnalyticsAction;
  eventLabel?: AnalyticsEvent | string;
  pagesQty?: string;
  ecommerce?: any; // tslint:disable-line
  products?: any; // tslint:disable-line
  searchConditions?: IJsonQuery;
  email?: string;
  recommendations?: number[] | IAnalyticsProducts[];
  user?: IUserAnalyticsData;
  clicked_oid?: number;
  clicked_position?: number;
  timestamp?: string;
  page_num?: number;
  page?: ITrackingPage;
  customPageURL?: string;
  // tslint:disable-next-line:no-any
  extra?: any;
}

export interface ITrackingPage {
  customPageURL?: string | undefined;
  pageType: string;
  siteType: string;
  listingType?: string;
  queryString?: string;
  offerQty?: number;
  dealType?: string;
  objectType?: string;
  pageNumber?: number;
  region?: number | string;
  sortType?: string;
  offersQty?: number;
}

export interface ITrackingCallListring extends IAnalitycsEvent {
  page: ITrackingPage;
  user: IUserAnalyticsData;
}

export type TVariant = 'standard' | 'free' | 'premium' | 'top3' | 'auction';

export interface INewbecUser {
  abGroup: number;
  userId?: number;
  isAuthorized: boolean;
}

export interface ISuggestionEbc {
  id?: number;
  offerType: TOfferType;
  position?: number;
  published?: boolean;
  variant?: TVariant[];
  mainImageId?: number;
  ownerId?: number;
}

// На клиенте ждём загрузки ga и загрузки страницы
// Пока ga не загружено, добавляем события в queue.events
// Когда ga загружено, запускаем отложенные события
interface IQueue {
  waitForInitialize: () => Promise<void>;
  initialized: boolean;
  callbacks: Function[];
}

export interface IUserEbc {
  userId?: number;
  isAuthorized: boolean;
  abGroup: number;
}

export interface IPageEbc {
  breadCrumbs: string[];
  offerType: 'offer';
  offerID: number;
  pageType: 'Card';
  siteType: SiteType;
}

export interface ITrackSimilarData {
  offerData: IOfferAnalyticsData[];
  user: IUserEbc;
  page: IPageEbc;
}

export interface IProductsNewbec {
  id: number;
  offerType: 'offer' | 'JK';
  position: number;
  // tslint:disable-next-line:no-any
  extra?: any;
}

/* istanbul ignore next */
export const getOfferVAS = (baseInfo: IBaseInfo, valueAddedServices?: IValueAddedServices) => {
  const { isAuction, isStandard, isPremium, isTop3 } = baseInfo;
  const isFree = valueAddedServices ? valueAddedServices.isFree : false;

  return [
    isPremium && 'premium',
    isTop3 && 'top3',
    isStandard && 'standard',
    isAuction && 'auction',
    isFree && 'free',
  ].filter(Boolean);
};

//из-за проблемы с отправкой oldevent раньше newpage
interface INewPageQueue {
  callbacks: Function[];
  isPageviewSent: boolean;
}
const newPageQueue: INewPageQueue = {
  callbacks: [],
  isPageviewSent: false,
};

/* istanbul ignore next */
export const newPageQueueHandler = {
  subscribe(fn: Function) {
    if (newPageQueue.isPageviewSent) {
      fn();

      return;
    }
    newPageQueue.callbacks.push(fn);
  },
  fire() {
    newPageQueue.isPageviewSent = true;
    newPageQueue.callbacks.forEach(cb => cb());
  },
  clean() {
    newPageQueue.callbacks = [];
    newPageQueue.isPageviewSent = false;
  },
};

/* istanbul ignore next */
const queue: IQueue = {
  waitForInitialize() {
    return new Promise<void>(resolve => {
      // tslint:disable-next-line:no-any
      if ((window as any).ga) {
        resolve();

        return;
      }

      let _ga: GoogleAnalytics;
      Object.defineProperty(window, 'ga', {
        set(value: GoogleAnalytics) {
          _ga = value;
          resolve();
        },
        get() {
          return _ga;
        },
        configurable: true,
      });
    });
  },
  initialized: false,
  callbacks: [],
};

/* istanbul ignore next */
if (isClient) {
  queue.waitForInitialize().then(function executeAnalyticsQueue() {
    queue.callbacks.forEach(cb => cb());
    queue.callbacks = [];
    queue.initialized = true;
  });
}

/* istanbul ignore next */
export const sendEventToGoogleAnalytics = function (command: string, ...args: any[]) {
  // tslint:disable-line:no-any
  const fireEvent = () => {
    ga(command, ...args);
  };

  if (queue.initialized) {
    fireEvent();
  } else {
    queue.callbacks.push(fireEvent);
  }
};

/* istanbul ignore next */
export function sendDefferedEventToAnalytics(args: ITrackingCallListring) {
  let timerId: number;
  let userIsActive = false;
  const listener = (e: Event) => {
    userIsActive = true;
    clearTimeout(timerId);
    window.removeEventListener('focus', listener);
  };
  window.addEventListener('focus', listener);
  timerId = window.setTimeout(() => {
    if (!userIsActive) {
      window.removeEventListener('focus', listener);
      sendEventToAnalytics(args);
    }
  }, 10000);
}

export function sendEventToAnalytics(
  trackingParams: IAnalitycsEvent,
  isOldFlow?: boolean,
  isNeedToEnrichEvent?: boolean,
) {
  /* istanbul ignore next */
  if (!isClient) {
    return;
  }

  const { place, action, eventLabel, ...secondaryTrackingParams } = trackingParams;

  type TSecondaryTrackingParams = Pick<
    IAnalitycsEvent,
    'ecommerce' | 'email' | 'name' | 'pagesQty' | 'products' | 'searchConditions'
  >;

  Object.keys(secondaryTrackingParams).forEach((key: keyof TSecondaryTrackingParams) => {
    if (typeof secondaryTrackingParams[key] === 'undefined') {
      delete secondaryTrackingParams[key];
    }
  });

  sendEventToGoogleAnalytics('send', 'event', place, action, eventLabel);

  const eventMethod = (() => {
    if (isOldFlow) {
      return 'event';
    }

    if (isNeedToEnrichEvent) {
      return 'eventEnrich';
    }

    return 'eventSite';
  })();

  ca(
    eventMethod,
    Object.assign(
      {
        name: isOldFlow ? 'oldevent' : undefined,
        category: place,
        action,
        label: eventLabel,
        ...(isNeedToEnrichEvent && { event: 'oldevent' }),
      },
      secondaryTrackingParams,
    ),
  );
}

export const eventOld =
  (place: AnalyticsPlace) => (action: AnalyticsAction) => (eventLabel?: AnalyticsEvent | string) => {
    sendEventToAnalytics(
      {
        place,
        action,
        eventLabel,
      },
      true,
    ); // Всегда true чтобы отправлялось по старому в /collect
  };

export const event =
  (place: AnalyticsPlace) =>
  (action: AnalyticsAction) =>
  (eventLabel?: AnalyticsEvent | string, products?: IProductsNewbec[], isOldFlow?: boolean) => {
    // exports добавил для написания unit теста
    sendEventToAnalytics(
      {
        place,
        action,
        eventLabel,
        products,
      },
      isOldFlow,
    );
  };

/* istanbul ignore next */
export function sendFavoriteEvent(action: AnalyticsAction, gaLabel?: string) {
  sendEventToAnalytics({ place: 'favorite', action, eventLabel: gaLabel });
}

export const sendMapEvent = eventOld('Mob_map');

/* istanbul ignore next */
export function offerInListShowContacts(
  offerData: IOfferAnalyticsData,
  gaLabel: string,
  deviceType: DeviceType,
  onMap: boolean,
  page: ITrackingPage,
  user: IUserAnalyticsData,
  jsonQuery?: IJsonQuery,
) {
  sendEventToAnalytics({
    place: 'Phones',
    action: onMap ? 'Open_listing_in_map' : 'Open_listing_tablet',
    eventLabel: gaLabel,
    products: [{ ...offerData }],
    page,
    user,
    searchConditions: jsonQuery,
  });
}

/* istanbul ignore next */
export function gkInListShowPhone(
  offerData: IOfferAnalyticsData,
  gaLabel: string,
  page: ITrackingPage,
  user: IUserAnalyticsData,
  jsonQuery: IJsonQuery | undefined,
) {
  sendEventToAnalytics({
    place: 'Phones',
    action: 'Open_listing_jk_tablet',
    eventLabel: gaLabel,
    products: [{ ...offerData }],
    page,
    user,
    searchConditions: jsonQuery,
  });
}

export function clickCallButtonInMapListing(gaLabel: string) {
  sendEventToAnalytics(
    {
      place: 'Mob_map',
      action: 'click_call',
      eventLabel: gaLabel,
    },
    true,
  ); // Всегда true чтобы отправлять по старому в /collect
}

/* istanbul ignore next */
export function sendCallInCardEvent(
  page: ITrackingPage,
  user: IUserAnalyticsData,
  offerData?: IOfferAnalyticsData,
  gaLabel?: string,
  action: 'Call' | 'Call_gallery' = 'Call',
) {
  sendEventToAnalytics({
    place: 'Phones',
    action,
    eventLabel: gaLabel,
    products: [offerData],
    page,
    user,
  });
}

/* istanbul ignore next */
export function trackCallButtonClick(
  page: ITrackingPage,
  user: IUserAnalyticsData,
  offerData?: IOfferAnalyticsData,
  gaLabel?: string,
  action: 'Open_card' | 'Open_gallery' | 'Open_card_master_podbora' = 'Open_card',
) {
  const isNeedToEnrichEvent = page.objectType === 'commercial' && action === 'Open_card';

  sendEventToAnalytics(
    {
      place: 'Phones',
      action,
      eventLabel: gaLabel,
      products: [offerData],
      page: { ...page, ...(isNeedToEnrichEvent && { offerType: 'offer' }) },
      user,
    },
    false,
    isNeedToEnrichEvent,
  );
}

/* istanbul ignore next */
export function callBuilderInMapListing(offerData: IOfferAnalyticsData, gaLabel: string) {
  sendEventToAnalytics({
    place: 'Phones',
    action: 'Call_listing_in_map',
    eventLabel: gaLabel,
    products: [{ ...offerData }],
  });
}

export function gkInListShowContacts(gaLabel: string) {
  sendEventToAnalytics(
    {
      place: 'Card_JK',
      action: 'open_contacts',
      eventLabel: gaLabel,
    },
    true,
  );
}

export const clickMLSuggestionInListing = (
  jsonQuery: IJsonQuery | undefined,
  suggestion: ISuggestionEbc,
  modelVersion: number | undefined,
  pageNumber: number,
  breadCrumbs: string[],
  queryString: string,
  offersQty: number,
  siteType: string,
  user: INewbecUser,
) => {
  const ebcObject = {
    name: 'oldevent',
    category: 'Listing',
    action: 'to_card_infinite_search',
    label: '',
    modelVersion: String(modelVersion),
    searchConditions: jsonQuery,
    user,
    products: [suggestion],
    page: {
      listingType: 'list',
      sortType: jsonQuery && jsonQuery.sort ? jsonQuery.sort : 'default',
      pageNumber,
      breadCrumbs,
      queryString,
      offersQty,
      pageType: 'Listing',
      siteType: siteType === 'phone' ? 'mobile' : siteType,
    },
  };

  ca('eventEbc', ebcObject);
};

export function listingItemClick(params: { place: AnalyticsPlace; action: AnalyticsAction; actionFieldList?: string }) {
  return (label?: string) => (offerData?: IOfferAnalyticsData, isAuction?: boolean, positionWithoutTop3?: number) => {
    sendEventToAnalytics(
      {
        place: params.place,
        action: params.action,
        eventLabel: label,
        ecommerce: {
          click: {
            actionField: {
              list: params.actionFieldList,
            },
            products: [offerData].filter(Boolean),
            ...(isAuction ? { auction_position: positionWithoutTop3 } : {}),
          },
        },
      },
      true,
    ); // Всегда true чтобы отправлялось по старому в /collect
  };
}

export const showMLSuggestionsInListing = (
  jsonQuery: IJsonQuery,
  suggestions: IOfferSerialized[],
  pageNumber: number,
  breadCrumbs: string[],
  queryString: string,
  offersQty: number,
  siteType: string,
  user: INewbecUser,
  allSuggestions?: IOfferSerialized[],
) => {
  const modelVersion = suggestions && suggestions.length > 0 ? Number(suggestions[0].modelVersion) : undefined;

  const products = suggestions.map((suggestion, index) => {
    const i = allSuggestions && allSuggestions.findIndex(s => s.id === suggestion.id);
    const position = i ? i + 1 : index + 1;

    return {
      id: Number(suggestion.id),
      offerType: 'offer',
      position,
      published: suggestion.status === 'published',
      variant: getVariantsForSuggestion(suggestion),
      mainImageId: getMainImageId(suggestion),
      ownerId: suggestion.userId ? Number(suggestion.userId) : undefined,
      extra: getOfferExtra(suggestion),
    };
  });

  const ebcObject = {
    name: 'oldevent',
    category: 'page',
    action: 'newpage_infinite_search',
    label: '',
    modelVersion: String(modelVersion),
    searchConditions: jsonQuery,
    user,
    products,
    page: {
      listingType: 'list',
      sortType: jsonQuery.sort ? jsonQuery.sort : 'default',
      pageNumber,
      breadCrumbs,
      queryString,
      offersQty,
      pageType: 'Listing',
      siteType,
    },
  };

  ca('eventEbc', ebcObject);
};

/* istanbul ignore next */
export const clickOfferInListingWithAreaParts = (
  analyticsInfo: IOfferAnalyticsInfo,
  user: INewbecUser,
  jsonQuery: IJsonQuery,
  queryString: string,
  offersQty: number,
  siteType: string,
  pageNumber: number,
) => {
  const { id, parentId, position } = getOfferAnalyticsData(analyticsInfo);
  let extra = {};

  if (parentId) {
    extra = {
      ...extra,
      parentId,
    };
  }
  const product = {
    id,
    offerType: 'offer',
    position,
    extra,
  };

  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'to_card',
    label: 'from_mob_listing',
    user,
    page: {
      pageType: 'Listing',
      siteType: getSiteType(),
      listingType: getSiteType(),
      offersQty,
      queryString,
      pageNumber,
    },

    products: [product],
  });
};

export const clickOfferInListing = listingItemClick({
  place: 'Listing',
  action: 'to_card',
  actionFieldList: 'mob_listing',
})('from_mob_listing');

/* istanbul ignore next */
export const clickOfferInMapListing = listingItemClick({
  place: 'Listing',
  action: 'to_card',
  actionFieldList: 'Listing',
})('from_mob_listing_in_map');

/* istanbul ignore next */
export const clickOthersInCard = (offerData: IOfferAnalyticsData) => {
  ca('eventEbc', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'click_others_offers',
    label: '',
    products: [
      {
        id: offerData.id,
        ownerId: offerData.ownerId,
        offerType: 'offer',
        variant: Array.isArray(offerData.variant) ? offerData.variant : [offerData.variant],
        published: offerData.published,
        position: offerData.position ? Number(offerData.position) : undefined,
      },
    ],
  });
};

/* istanbul ignore next */
export const showOthersInCard = (offerData: IOfferAnalyticsData[]) => {
  ca('eventEbc', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'show_others_offers',
    label: '',
    products: offerData.map(od => ({
      id: od.id,
      ownerId: od.ownerId,
      offerType: 'offer',
      variant: Array.isArray(od.variant) ? od.variant : [od.variant],
      published: od.published,
      position: od.position ? Number(od.position) : undefined,
    })),
  });
};

export const clickOthersInCallRatePopup = (offerData: IOfferAnalyticsData, jsonQuery: IJsonQuery | undefined) => {
  ca('eventEbc', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'click_others_CallRate_popup',
    label: '',
    searchConditions: jsonQuery,
    products: [
      {
        id: offerData.id,
        ownerId: offerData.ownerId,
        offerType: 'offer',
        variant: Array.isArray(offerData.variant) ? offerData.variant : [offerData.variant],
        published: offerData.published,
        position: offerData.position ? Number(offerData.position) : undefined,
      },
    ],
  });
};

export const showOthersInCallRatePopup = (offerData: IOfferAnalyticsData[], jsonQuery: IJsonQuery | undefined) => {
  ca('eventEbc', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'show_others_CallRate_popup',
    label: '',
    searchConditions: jsonQuery,
    products: offerData.map(od => ({
      id: od.id,
      ownerId: od.ownerId,
      offerType: 'offer',
      variant: Array.isArray(od.variant) ? od.variant : [od.variant],
      published: od.published,
      position: od.position ? Number(od.position) : undefined,
    })),
  });
};

/* istanbul ignore next */
export const clickSuggestionsInCard = (offerData: IOfferAnalyticsData) => {
  const { modelVersion, ...product } = offerData;

  ca('eventEbc', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'click_similar_offers',
    label: '',
    modelVersion,
    products: [
      {
        id: product.id,
        ownerId: product.ownerId,
        offerType: 'offer',
        variant: Array.isArray(product.variant) ? product.variant : [product.variant],
        published: product.published,
        position: product.position ? Number(product.position) : undefined,
      },
    ],
  });
};

/* istanbul ignore next */
export const showSuggestionsInCard = (params: ITrackSimilarData) => {
  const { user, offerData, page } = params;

  let modelVersion: string | undefined;

  if (offerData.length > 0) {
    modelVersion = String(offerData[0].modelVersion);
  }

  ca('eventEbc', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'show_similar_offers',
    label: '',
    modelVersion,
    user,
    page,
    products: offerData.map(od => {
      const extra = (() => {
        const _extra = {} as { parentId?: number };

        if (od.parentId) {
          _extra.parentId = Number(od.parentId);
        }

        if (Object.keys(_extra).length === 0) {
          return undefined;
        }

        return _extra;
      })();

      return {
        id: Number(od.id),
        ownerId: Number(od.ownerId),
        offerType: 'offer',
        variant: Array.isArray(od.variant) ? od.variant : [od.variant],
        published: Boolean(od.published),
        position: od.position ? Number(od.position) : undefined,
        extra,
      };
    }),
  });
};

export const clickSuggestionsInCallRatePopup = (offerData: IOfferAnalyticsData, jsonQuery: IJsonQuery | undefined) => {
  ca('eventEbc', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'click_similar_CallRate_popup',
    label: '',
    modelVersion: typeof offerData.modelVersion !== 'undefined' ? String(offerData.modelVersion) : undefined,
    searchConditions: jsonQuery,
    products: [
      {
        id: offerData.id,
        ownerId: offerData.ownerId,
        offerType: 'offer',
        variant: Array.isArray(offerData.variant) ? offerData.variant : [offerData.variant],
        published: offerData.published,
        position: offerData.position ? Number(offerData.position) : undefined,
      },
    ],
  });
};

export const showSuggestionsInCallRatePopup = (offerData: IOfferAnalyticsData[], jsonQuery: IJsonQuery | undefined) => {
  let modelVersion: string | undefined;

  if (offerData.length > 0) {
    modelVersion = String(offerData[0].modelVersion);
  }

  ca('eventEbc', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'show_similar_CallRate_popup',
    label: '',
    modelVersion,
    searchConditions: jsonQuery,
    products: offerData.map(od => ({
      id: od.id,
      ownerId: od.ownerId,
      offerType: 'offer',
      variant: Array.isArray(od.variant) ? od.variant : [od.variant],
      published: od.published,
      position: od.position ? Number(od.position) : undefined,
    })),
  });
};

/* istanbul ignore next */
export function trackShowSimilar(params: ITrackSimilarData, withoutCompetitors?: boolean) {
  if (withoutCompetitors) {
    const { offerData } = params;
    newPageQueueHandler.subscribe(() => {
      showOthersInCard(offerData);
    });
  } else {
    newPageQueueHandler.subscribe(() => {
      showSuggestionsInCard(params);
    });
  }
}

/* istanbul ignore next */
export function trackShowSimilarInCallRate(
  trackingData: IOfferAnalyticsData[],
  jsonQuery: IJsonQuery | undefined,
  withoutCompetitors?: boolean,
) {
  if (withoutCompetitors) {
    showOthersInCallRatePopup(trackingData, jsonQuery);
  } else {
    showSuggestionsInCallRatePopup(trackingData, jsonQuery);
  }
}

export function subscriptionSend(eventLabel: string, searchConditions?: IJsonQuery, email?: string): void {
  sendEventToAnalytics(
    {
      place: 'subscription',
      action: 'Send',
      eventLabel,
      searchConditions,
      email,
    },
    true,
  );
}

/* istanbul ignore next */
export interface ITrackSaveSearchButtonClickParams {
  queryString: string;
  siteType: SiteType;
  searchConditions?: IJsonQuery;
  email?: string;
}

/* istanbul ignore next */
export const trackingFactory = (action: string) => (params: ITrackSaveSearchButtonClickParams) => {
  ca('event', {
    name: 'oldevent',
    category: 'Subscription',
    action,
    label: 'saved_search',
    page: {
      pageType: 'Map',
      block: 'corner|save_search',
      ...params,
    },
  });
};

export function sendMapGoToJKEvent(idGK: string): void {
  sendEventToAnalytics(
    {
      place: 'Listing',
      action: 'to_JKCard',
      eventLabel: idGK,
    },
    true,
  ); // Всегда true чтобы отправлять по старому в /collect
}

export const offerMapPanoramaToggle = eventOld('card_mob')('map_popup_click');

export const offerMapOpen = eventOld('card_mob')('map_popup_open');

export const offerNext = eventOld('card_mob')('click_next');

export const offerPrev = eventOld('card_mob')('click_prev');

export const filterTabClick = eventOld('Mob_main_page')('tab_click');

export const filterSearchClick = event('Mob_main_page')('button_search');

export const showAdvancedFiltersMain = eventOld('Mob_main_page')('advanced_search');

export const showAdvancedFiltersAdvanced = eventOld('Mob_filter_page')('advanced_search');

export const filterAdvancedClear = eventOld('Mob_main_page')('clean_filters');

export const promoBlocksLinkClick = eventOld('Mob_main_page')('direct_links');

export const geoSeoLinkClick = eventOld('Mob_main_page')('regionOffers');

export const seoTextReadClick = eventOld('Mob_main_page')('readAll');

export const breadcrumbsClick = eventOld('Mob_BreadCrumbs')('click');

export const refineSearchClick = eventOld('Mob_refineSearch_popup')('open');

export const refineSearchCloseClick = eventOld('Mob_refineSearch_popup')('close');

export const refineSearchButtonClick = event('Mob_refineSearch_popup')('search');

export const refineSearchClearClick = eventOld('Mob_refineSearch_popup')('clean');

export const refineSearchChangeGeo = event('Mob_refineSearch_popup')('change_geo');

export const gkCardEvent = eventOld('Card_JK');

export const gkPreviewClick = eventOld('jk-listing')('click');

export const gkCardClick = eventOld('Card_JK')('click_developersFlats');

export const gkCardAgentsClick = eventOld('Card_JK')('click_agentsFlats');

export const gkCardOpenBuilderStatsClick = eventOld('Card_JK')('open_builder_stats');

export const menuClick = eventOld('Mob_menu')('click');

export const mobHeaderClick = eventOld('Mob_head')('click');

export const tabletHeaderClick = eventOld('Tablet_head')('click');

export const footerClick = eventOld('Mob_footer')('click');

export const pagination = eventOld('Pagination');

export const userRegion = eventOld('GEO_block');
/* istanbul ignore next */
export const phonesCall = event('Phones')('Call');
/* istanbul ignore next */
export const phonesOpen = event('Phones')('Open');

export const trackMVKGKPageShown = () => event('Card_JK')('master_podbora_show')('', undefined, true);
export const trackMVKGKPageMapClick = () => event('Card_JK')('master_podbora_click')('map', undefined, true);
export const trackMVKHouseClick = () => event('Card_JK')('master_podbora_click')('korpus', undefined, true);
export const trackMVKSectionClick = () => event('Card_JK')('master_podbora_click')('section', undefined, true);
export const trackMVKFloorClick = () => event('Card_JK')('master_podbora_click')('floor', undefined, true);
export const trackMVKRoomCountClick = () => event('Card_JK')('master_podbora_click')('room_qty', undefined, true);
export const trackMVKFloorPlanClick = () => event('Card_JK')('master_podbora_click')('plan', undefined, true); //dont ok
export const trackMVKFlatClick = () => event('Card_JK')('master_podbora_click')('flat', undefined, true);

export const trackMVKFloorPlanInteracting = () => event('Card_JK')('master_podbora_zoom_drag')('plan', undefined, true);

export const trackMVKOfferPageSimilarShown = () =>
  event('similar_objects')('master_podbora_show_similar_offers')('', undefined, true);
export const trackMVKOfferPageSimilarClick = () =>
  event('similar_objects')('master_podbora_click_similar_offers')('', undefined, true);

export const subscription = {
  open: eventOld('subscription')('Open'),
  send: subscriptionSend,
};

export const complaint = {
  open: eventOld('complain')('open'),
  send: eventOld('complain')('send'),
};

export const card = eventOld('card');

export const favoriteCard = eventOld('favorite')('from_card');

/* istanbul ignore next */
export const oldBrowserPopup = {
  show: event('OldBrowserPopup')('Open'),
  close: event('OldBrowserPopup')('Close'),
  click: event('OldBrowserPopup')('Click'),
};

/* istanbul ignore next */
export const callRate = (
  eventLabel: 'not_confirmed' | 'confirmed' | 'no_answer' | 'close',
  params: {
    product?: IAnalyticsProducts;
    user?: IUserAnalyticsData;
    page?: ITrackingPage;
    searchConditions?: IJsonQuery;
    place?: 'CallRate_popup' | 'CallRate_popup_gallery';
  },
) => {
  const { product, user, page, searchConditions, place = 'CallRate_popup' } = params;

  sendEventToAnalytics({
    place,
    action: 'rate',
    eventLabel,
    products: [{ ...product }],
    user,
    page,
    searchConditions,
  });
};

/* istanbul ignore next */
export function getUserAnalitycsData(gaDataLayer: IUserGaDataLayer, regionId?: number | undefined): IUserAnalyticsData {
  return {
    cianUserId: gaDataLayer.userId ? Number(gaDataLayer.userId) : undefined,
    userId: gaDataLayer.realtyUserId ? Number(gaDataLayer.realtyUserId) : undefined,
    email: gaDataLayer.email || undefined,
    type: gaDataLayer.userType || 'not_authorized',
    abGroup: gaDataLayer.abGroup ? Number(gaDataLayer.abGroup) : 0,
    extra: {
      defaultRegionId: regionId,
    },
  };
}

/* istanbul ignore next */
export function getPartnerAnalitycsData(
  userGaDataLayer: IUserGaDataLayer,
  productIds?: number[],
  trackingLocation?: ITrackingLocation,
): ICommonPartnerAnalyticsData {
  return {
    CriteoEmail: userGaDataLayer.criteoEmail,
    CriteoNewCustomer: userGaDataLayer.criteoNewCustomer,
    CriteoProducts: productIds || [],
    FB_City: trackingLocation && trackingLocation.fbCity,
    FB_Region: trackingLocation && trackingLocation.fbRegion,
  };
}

export function sendInitPageData(eventData: PageAnalyticsGTMEvent): void {
  const evt = normalizeAnalytcsEventData(eventData);
  const { user, partner, page, products, searchConditions, searchResults } = evt;
  
  const pageWithSiteTypeAndExtra = {
    ...page,
    siteType: getSiteType(),
    extra: {
      mobileWebsiteType: 'old',
    },
  };

  //CSSSR-8432 При формировании события для СОПР ca берет информацию о странице
  //не из переданного аргумента "page", а из сохраненного внутри себя.
  //При вызове "pageview", ca сам его сохраняет, а потом формирует событие.
  //Но при вызове "event" нужно сохранять его самим.
  try {
    ca('page', pageWithSiteTypeAndExtra);
    ca('user', user);
    // eslint-disable-next-line no-empty
  } catch (e) {}

  ca('pageview', {
    event: 'newpage', // очень важное поле, без него будет крит SEM-1566
    category: 'page',
    action: 'newpage',
    label: 'open',
    value: '',
    user,
    page: pageWithSiteTypeAndExtra,
    partner,
    products,
    searchConditions,
    searchResults,
  });
}

/**
 * Отправляем событие в ручку /site (/newbec в будущем)
 */
export function trackBasePageview(gaDataLayer: IUserGaDataLayer, page: IMainPageAnalytics, regionId?: number) {
  const { userId, abGroup } = getUserAnalitycsData(gaDataLayer);

  ca('pageviewSite', {
    event: 'newpage',
    category: 'page',
    action: 'newpage',
    label: 'open',
    user: { userId, abGroup, extra: { defaultRegionId: regionId } },
    page: {
      pageType: page.pageType,
      siteType: getSiteType(),
      extra: {
        mobileWebsiteType: 'old',
      },
    },
    products: [],
  });
}

/* istanbul ignore next */
export function sendInitPageDataForListPage(
  gaDataLayer: IUserGaDataLayer,
  page: IListPageAnalytics,
  productsIds: number[], // id офферов или ЖК, в зависимости от выдачи
  searchConditions: IJsonQuery | undefined,
  searchResults: number[] | undefined,
  trackingLocation?: ITrackingLocation,
  products?: IAnalyticsProducts[],
): void {
  const regionId =
    (searchConditions &&
      searchConditions.region &&
      searchConditions.region.value &&
      searchConditions.region.value[0]) ||
    undefined;
  const user = getUserAnalitycsData(gaDataLayer, regionId);
  const partner = {
    CriteoEmail: gaDataLayer.criteoEmail,
    CriteoNewCustomer: gaDataLayer.criteoNewCustomer,
    CriteoProducts: productsIds,
  };

  if (trackingLocation) {
    Object.assign(partner, {
      FB_Region: trackingLocation.fbRegion,
      FB_City: trackingLocation.fbCity,
    });
  }

  const evt: IListPageAnalyticsGTMEvent = {
    event: 'newpage',
    user,
    page,
    partner,
    products,
    searchConditions,
    searchResults,
  };

  sendInitPageData(evt);
}

/* istanbul ignore next */
export function sendInitPageDataForOtherPage(
  gaDataLayer: IUserGaDataLayer,
  page: IMainPageAnalytics | IContactsPageAnalytics | IMapPageAnalytics,
  searchConditions?: IJsonQuery,
): void {
  const regionId =
    (searchConditions &&
      searchConditions.region &&
      searchConditions.region.value &&
      searchConditions.region.value[0]) ||
    undefined;
  const user = getUserAnalitycsData(gaDataLayer, regionId);
  const partner = getPartnerAnalitycsData(gaDataLayer);
  let evt: IMainPageAnalyticsGTMEvent | IContactsPageAnalyticsGTMEvent = { event: 'newpage', user, page, partner };

  if (searchConditions) {
    evt = Object.assign({}, evt, { searchConditions });
  }

  sendInitPageData(evt);
}

/* istanbul ignore next */
export function trackListingPageview(
  gaDataLayer: IUserGaDataLayer,
  page: IListPageAnalytics,
  productsIds: number[], // id офферов или ЖК, в зависимости от выдачи
  searchConditions: IJsonQuery,
  trackingLocation?: ITrackingLocation,
  products?: IProductsSOPRData[],
): void {
  const { realtyUserId, userId, abGroup, userType, email } = gaDataLayer;

  const user = {
    userId: realtyUserId ? Number(realtyUserId) : undefined,
    cianUserId: userId ? Number(userId) : undefined,
    abGroup: abGroup ? Number(abGroup) : 0,
    type: userType ? userType : 'not_authorized',
    email: email || undefined,
  };

  const partner = {
    CriteoEmail: gaDataLayer.criteoEmail,
    CriteoNewCustomer:
      typeof gaDataLayer.criteoNewCustomer === 'number' ? String(gaDataLayer.criteoNewCustomer) : undefined,
    CriteoProducts: productsIds,
    FB_Region: trackingLocation ? trackingLocation.fbRegion : undefined,
    FB_City: trackingLocation ? trackingLocation.fbCity : undefined,
  };

  const pageWithSiteTypeAndExtra = {
    ...page,
    siteType: getSiteType(),
    extra: {
      mobileWebsiteType: 'old',
      mlSearchSessionGuid: mlSearchSession.getSessionGuid(searchConditions)
    },
  };

  ca('page', pageWithSiteTypeAndExtra);

  ca('pageviewSite', {
    event: 'newpage',
    name: 'newpage',
    category: 'page',
    label: 'open',
    action: 'newpage',
    modelVersion: '1',
    schemaVersion: '1',
    user,
    page: pageWithSiteTypeAndExtra,
    partner,
    products,
    searchConditions,
  });
}

/* istanbul ignore next */
export function sendInitPageDataForOfferPage(
  gaDataLayer: IUserGaDataLayer,
  page: IOfferPageAnalytics,
  products: IProductsSOPRData[],
  newOffer: IOfferInfo,
  trackingLocation?: ITrackingLocation,
): void {
  const user = {
    cianUserId: gaDataLayer.userId ? Number(gaDataLayer.userId) : undefined,
    userId: gaDataLayer.realtyUserId ? Number(gaDataLayer.realtyUserId) : undefined,
    abGroup: gaDataLayer.abGroup ? Number(gaDataLayer.abGroup) : 0,
    type: gaDataLayer.userType ? gaDataLayer.userType : 'not_authorized',
    email: gaDataLayer.email ? gaDataLayer.email : undefined,
  };

  const partner = {
    CriteoEmail: gaDataLayer.criteoEmail,
    CriteoNewCustomer: gaDataLayer.criteoNewCustomer ? String(gaDataLayer.criteoNewCustomer) : undefined,
    FB_Region: trackingLocation ? trackingLocation.fbRegion : undefined,
    FB_City: trackingLocation ? trackingLocation.fbCity : undefined,
  };

  const coworkingOfferType = newOffer.baseInfo.coworkingOfferType;

  const pageWithSiteTypeAndExtra = {
    ...page,
    siteType: getSiteType(),
    extra: {
      mobileWebsiteType: 'old',
      coworkingOfferType,
    },
  };

  ca('page', pageWithSiteTypeAndExtra);

  ca('pageviewSite', {
    event: 'newpage',
    name: 'newpage',
    category: 'page',
    label: 'open',
    action: 'newpage',
    modelVersion: '1',
    schemaVersion: '1',
    user,
    page: pageWithSiteTypeAndExtra,
    partner,
    products,
  });
}

/* istanbul ignore next */
export function sendInitPageDataForGKPage(
  gaDataLayer: IUserGaDataLayer,
  page: IGKPageAnalytics,
  products: IProductsGKPage[],
): void {
  const { realtyUserId, userId, abGroup, userType, email } = gaDataLayer;

  const user = {
    userId: realtyUserId ? Number(realtyUserId) : undefined,
    cianUserId: userId ? Number(userId) : undefined,
    abGroup: abGroup ? Number(abGroup) : 0,
    type: userType ? userType : 'not_authorized',
    email: email ? email : undefined,
  };

  const partner = {
    CriteoEmail: gaDataLayer.criteoEmail,
    CriteoNewCustomer: gaDataLayer.criteoNewCustomer ? String(gaDataLayer.criteoNewCustomer) : undefined,
  };

  const trackPageSOPR = {
    ...page,
    dealType: 'other',
    objectType: 'JK',
    offerID: Number(page.offerID),
    offerPhone: page.offerPhone,
    siteType: getSiteType(),
    offerType: 'JK',
    /** Делаем сброс fromDeveloper - так он приходит с бекенда и нужен в каких то местах  */
    fromDeveloper: undefined,
    extra: {
      mobileWebsiteType: 'old',
    },
  };

  ca('page', trackPageSOPR);

  ca('pageviewSite', {
    event: 'newpage',
    name: 'newpage',
    category: 'page',
    label: 'open',
    action: 'newpage',
    modelVersion: '1',
    schemaVersion: '1',
    user,
    page: trackPageSOPR,
    partner,
    products,
  });
}

/* istanbul ignore next */
export function openRegionsPopup(place: AnalyticsPlace, action: AnalyticsAction) {
  sendEventToAnalytics({
    place,
    action,
  });
}

/* istanbul ignore next */
export function openRegionsPopupTab(place: AnalyticsPlace, action: AnalyticsAction) {
  sendEventToAnalytics({
    place,
    action,
  });
}

/* istanbul ignore next */
export function regionsPopupSaveClick(place: AnalyticsPlace, action: AnalyticsAction, eventLabel: string) {
  sendEventToAnalytics({
    place,
    action,
    eventLabel,
  });
}

/* istanbul ignore next */
export function decodeGaLabel(gaLabel: string): { data: string[]; params: { [key: string]: string } } {
  // tslint:disable-next-line:max-line-length
  // https://cianru.atlassian.net/wiki/pages/viewpage.action?pageId=118358055#id-ОписаниепараметровисобытийdataLayer-КастомныйURL
  const pairs = gaLabel
    .split('/')
    .filter(Boolean)
    .map(pair => pair.split('='));

  const data = pairs.filter(([key, value]) => !value).map(([key]) => key);
  const params = pairs.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  return { data, params };
}

/* istanbul ignore next */
export function getRegionFromGaLabel(gaLabel: string): string {
  const data = decodeGaLabel(gaLabel);

  return [data.params['mo_id'], data.params['obl_id'], data.params['city_id']].join('-');
}

/* istanbul ignore next */
export function getDealTypeForList(jsonQuery: IJsonQuery): QueryDealType {
  const _type = jsonQuery._type;

  if (_type.includes('sale')) {
    return 'sale';
  }

  if (_type.includes('rent')) {
    const forDay = jsonQuery.for_day && jsonQuery.for_day.value;
    const rentTime = forDay && getRentTime(forDay);

    if (rentTimeDict.Short === rentTime) {
      return 'rent_day';
    }

    return 'rent_long';
  }
  throw new Error(`Unexpected _type: ${_type}`);
}

export function getObjectTypeForList(
  jsonQuery: IJsonQuery,
  fromDeveloper?: boolean,
  fromSuburbanBuilder?: boolean,
): ObjectType {
  const _type = jsonQuery._type;

  if (_type.includes('flat')) {
    // при аренде не может быть flat_new
    if (_type.includes('rent')) {
      return 'flat_old';
    }

    const building_status = get(jsonQuery, 'building_status.value');

    if (BuildingStatus.New === building_status) {
      return fromDeveloper ? 'flat_new_dev' : 'flat_new';
    }

    if (BuildingStatus.Resale === building_status) {
      return 'flat_old';
    }

    return 'flat_old,flat_new';
  }
  if (_type.includes('commercial')) {
    return 'commercial';
  }
  if (_type.includes('suburban')) {
    return fromSuburbanBuilder ? 'suburban_dev' : 'suburban';
  }
  throw new Error(`Unexpected _type: ${_type}`);
}

/* istanbul ignore next */
export const getDealTypeForOffer = (dealType: string, rentTime?: RentTime): QueryDealType => {
  return dealType === 'sale' ? 'sale' : rentTime === RentTime.Short ? 'rent_day' : 'rent_long';
};

export const getObjectTypeForOffer = (offer: IOfferInfo): ObjectType => {
  if (offer.baseInfo.buildingType === 'Бизнес-центр') {
    return 'BC';
  }

  if (offer.baseInfo.buildingType === 'Торговый центр') {
    return 'TC';
  }

  switch (offer.urlParams.objectType) {
    case 'flat': {
      const { commonInfo, newbuilding, isFromBuilder } = offer;
      const isFromBuilderPresenter = Boolean(newbuilding && newbuilding.isFromDeveloper);

      return commonInfo && commonInfo.isNewBuilding
        ? isFromBuilder || isFromBuilderPresenter
          ? 'flat_new_dev'
          : 'flat_new'
        : 'flat_old';
    }

    case 'commercial':
      return 'commercial';

    case 'suburban':
      return isSuburbanOfferFromBuilder(offer) ? 'suburban_dev' : 'suburban';

    default:
      throw logException(new Error(`Unexpected object type ${offer.urlParams.objectType}`));
  }
};

/* istanbul ignore next */
export function getSiteType(): SiteType {
  return isTablet() ? 'tablet' : 'mobile';
}

/**
 * В мобильном сайте существует deviceType из entry типа DeviceType
 * и функция getSiteType, которая определяет девайс по размеру экрана и
 * возвращает SiteType. Так как нам для трека нужен тип SiteType, но определение
 * девайса из entry кажется более точным, была написана функция, которая конвертирует
 * deviceType в siteType
 */
/* istanbul ignore next */
export function convertDeviceTypeToSiteType(deviceType: DeviceType): SiteType {
  switch (deviceType) {
    case 'desktop':
      return 'desktop' as SiteType;
    case 'phone':
      return 'mobile' as SiteType;
    case 'tablet':
      return 'tablet' as SiteType;
    default:
      return 'desktop' as SiteType;
  }
}

/* istanbul ignore next */
export const getPhoneAnalyticsDataForGk = (data: IGKPhoneDataLayer): IOfferAnalyticsData => {
  const getData = (key: string) => get(data, key, undefined);

  return {
    id: Number(getData('id') || 0),
    name: getData('name'),
    price: String(getData('price')),
    brand: getData('brand'),
    category: getData('category'),
    variant: getData('variant'),
    quantity: 1,
  };
};

export interface ITrackingLocation {
  fbRegion: string;
  fbCity: string;
}

/**
 * 1 - Москва
 * 2 - Санкт-Петербург
 */
/* istanbul ignore next */
export const CITY_WITHOUT_PARENT_ID = [1, 2];

/* istanbul ignore next */
export function getTrackingLocation(currentLocation: IRegionInfo, allRegions: IRegionInfo[]): ITrackingLocation {
  const trackingLocation: ITrackingLocation = {
    fbRegion: '',
    fbCity: '',
  };

  /**
   * Если текущий гео id = -1 - отправляем Москва и Московская область
   * Если текущий гео id = -2 - отправляем Санкт-Петербург и Ленинградская область
   * Если Москва(id=1) и Санкт-Петербург(id=2) - отправляем только город, у них нет parentId
   *
   * Если у текущего гео есть parentId, значит текущее гео это город,
   * и нужно попробовать найти его регион по этому parentId.
   *
   * Иначе текущее гео это и есть регион, тогда город не отправляем в аналитику.
   */
  if (currentLocation.id === -1) {
    trackingLocation.fbRegion = 'Московская область';
    trackingLocation.fbCity = 'Москва';
  } else if (currentLocation.id === -2) {
    trackingLocation.fbRegion = 'Ленинградская область';
    trackingLocation.fbCity = 'Санкт-Петербург';
  } else if (CITY_WITHOUT_PARENT_ID.includes(currentLocation.id) && currentLocation.displayName) {
    trackingLocation.fbCity = currentLocation.displayName;
  } else if (currentLocation.parentId && currentLocation.displayName) {
    const parentRegion = allRegions.find(region => region.id === currentLocation.parentId);

    trackingLocation.fbCity = currentLocation.displayName;
    trackingLocation.fbRegion = parentRegion && parentRegion.displayName ? parentRegion.displayName : '';
  } else if (currentLocation.displayName) {
    trackingLocation.fbRegion = currentLocation.displayName;
  }

  return trackingLocation;
}

/* istanbul ignore next */
export const getLabel = (type: string) => {
  switch (type) {
    case 'problem':
      return 'red';
    case 'reliable':
      return 'green';
    default:
      return null;
  }
};

/* istanbul ignore next */
export const trackOffersReliableIconClick = (GKId: number | string) => {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'JK_problems_icon_click',
    label: `green/${GKId}`,
  });
};

/* istanbul ignore next */
export const trackGKListProblematicIconClick = (GKId: number | string, type: string) => {
  const label = getLabel(type);

  if (!label) {
    return;
  }

  ca('event', {
    name: 'oldevent',
    category: 'Listing_JK',
    action: 'JK_problems_icon_click',
    label: `${label}/${GKId}`,
  });
};

/* istanbul ignore next */
export const trackGKCardProblematicIconClick = (GKId: number | string, type: string) => {
  const label = getLabel(type);

  if (!label) {
    return;
  }

  ca('event', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'JK_problems_icon_click',
    label: `${label}/${GKId}`,
  });
};

/* istanbul ignore next */
export const trackGKCardProblematicMoreButtonClick = (GKId: number | string, type: string) => {
  const label = getLabel(type);

  if (!label) {
    return;
  }

  ca('event', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'JK_problems_more_click',
    label: `${label}/${GKId}`,
  });
};

/* istanbul ignore next */
export const trackOffersListGKProblematicMoreButtonClick = (GKId: number | string, type: string) => {
  const label = getLabel(type);

  if (!label) {
    return;
  }

  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'JK_problems_more_click',
    label: `${label}/${GKId}`,
  });
};

export const trackChatsPopupOpen = (gaLabel: string, action?: string) => {
  return eventOld('Message_popup')(action as AnalyticsAction)(gaLabel);
};

export const trackChatsStartClick = (gaLabel: string) => eventOld('card')('send_message')(gaLabel);

/* istanbul ignore next */
export const trackChatsPopupOpenCustom = (customPageURL?: string) => {
  ca('event', {
    name: 'oldevent',
    category: 'Message_popup',
    action: 'open',
    customPageURL,
  });
};

/* istanbul ignore next */
export const trackChatsStartButtonClick = (customPageURL?: string) => {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click',
    label: 'message',
    customPageURL,
  });
};

/* istanbul ignore next */
export const track1PhotoTop3Click = () => {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click_photo',
    label: 'top3_1',
  });
};

/* istanbul ignore next */
export const track2PhotoTop3Click = () => {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click_photo',
    label: 'top3_2',
  });
};

/* istanbul ignore next */
export const track3PhotoTop3Click = () => {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click_photo',
    label: 'top3_3',
  });
};

/* istanbul ignore next */
export const track4PhotoTop3Click = () => {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click_photo',
    label: 'top3_4',
  });
};

/* istanbul ignore next */
export const trackDomrfPromoClick = (link: string) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'direct_links',
    label: link,
  });
};

export function getObjectType(offer: IOfferSerialized) {
  const { offerType, category, isFromBuilder, newbuilding } = offer;
  if (isSuburbanOfferFromBuilder(offer)) {
    return 'suburban_dev';
  }

  if (offerType === 'flat') {
    const isFromBuilderPresenter = Boolean(newbuilding && newbuilding.isFromDeveloper);
    const isNewBuilding = category && category.toLowerCase().includes('newbuilding');

    return isNewBuilding ? (isFromBuilder || isFromBuilderPresenter ? 'flat_new_dev' : 'flat_new') : 'flat_old';
  }

  return offerType;
}

/* istanbul ignore next */
export function getHeadline(offer: IOfferSerialized) {
  const { title, valueAddedServices } = offer;

  if (!valueAddedServices) {
    return false;
  }

  const { isTop3, isPremium } = valueAddedServices;

  return !!((isTop3 || isPremium) && title);
}

/* istanbul ignore next */
export function getOffersAsProducts(
  offers: Array<IOfferSource>,
  allOffers: Array<IOfferSource>,
): Array<IAnalyticsProducts> {
  return offers.map(({ data }) => {
    const {
      auctionPosition,
      businessShoppingCenter,
      cianId,
      dealType,
      demolishedInMoscowProgramm,
      isByHomeowner,
      isInHiddenBase,
      kp,
      newbuilding,
      offerAnalyticsInfo,
      status,
      user,
      photos,
      isDealRequestSubstitutionPhone,
      baseInfo,
    } = data;
    const { brand, id, price, variant } = offerAnalyticsInfo;

    const index = allOffers.findIndex(o => o.data.offerAnalyticsInfo.id === id);
    const position = index ? index + 1 : 1;

    const objectType = getObjectTypeForOffer(data);
    const parentId = (() => {
      if (objectType === 'suburban') {
        return kp && kp.id;
      }

      if (baseInfo.coworkingOfferType && baseInfo.coworking) {
        return baseInfo.coworking.id;
      }

      return (newbuilding && newbuilding.id) || (businessShoppingCenter && businessShoppingCenter.parentId);
    })();
    const parentName =
      (newbuilding && newbuilding.name) || (businessShoppingCenter && businessShoppingCenter.parentName) || undefined;
    const cianUserId = (user && user.cianUserId) || undefined;
    const userId = (user && user.userId) || undefined;
    const repres = (newbuilding && newbuilding.isFromSeller) || undefined;
    const nv = (newbuilding && newbuilding.showJkReliableFlag) || undefined;

    return {
      auctionPosition,
      brand,
      cianId,
      dealType: getDealTypeForOffer(dealType as QueryDealType),
      hiddenBase: isInHiddenBase,
      id,
      nv,
      objectType,
      owner: Boolean(isByHomeowner),
      ownerCianId: cianUserId,
      ownerId: userId,
      parentId: Number(parentId) || undefined,
      parentName,
      photosCount: photos ? photos.length : 0,
      podSnos: Boolean(demolishedInMoscowProgramm),
      position,
      price,
      published: status === ('published' as OfferDetailStatus),
      repres,
      variant: variant ? variant : [],
      consultant: isDealRequestSubstitutionPhone,
      extra: getOfferExtra(data),
    };
  });
}

/* istanbul ignore next */
export function trackFirstMessageSend(
  routeName: RouteName,
  showPushNotificationCheckbox: boolean,
  isPushCheck: boolean,
) {
  if (showPushNotificationCheckbox) {
    const pageTypeMapper: { [key: string]: TPageType } = {
      offerCard: 'Card',
      map: 'Map',
      listing: 'Listing',
    };

    ca('event', {
      action: 'Send',
      category: 'subscription',
      label: `From_Chats/${isPushCheck ? 'PushOn' : 'PushOff'}`,
      pagetype: pageTypeMapper[routeName],
    });
  }
}

export function trackDomrfPromoBlockLinkClick(label: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Mob_main_page',
    action: 'direct_links',
    label,
  });
}

/* istanbul ignore next */
export function getVariantsForSuggestion(suggestion: IOfferSerialized): string[] {
  const { isAuction, isStandard, isPremium, isTop3 } = suggestion;

  return [isAuction && 'auction', isStandard && 'standard', isPremium && 'premium', isTop3 && 'top3'].filter(
    Boolean,
  ) as string[];
}

/* istanbul ignore next */
export function getMainImageId(offer: IOfferSerialized | IOfferInfo): number | undefined {
  if (offer.photos && offer.photos.length > 0) {
    const defaultPhotos = (offer.photos as IPhoto[]).find(photo => Boolean(photo.isDefault));

    return defaultPhotos ? defaultPhotos.id : undefined;
  }

  return undefined;
}

/* istanbul ignore next */
export function getCategory(filtersPlace: FiltersPlace): TCategory {
  switch (filtersPlace) {
    case 'map':
      return 'Map';

    case 'main':
      return 'Main';

    default:
      return 'Listing';
  }
}
