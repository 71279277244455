import {
  Button,
  ButtonCheckbox,
  ButtonRadioGroupField,
  ButtonRadioGroupFieldFit,
  ButtonTheme,
  CheckboxField,
  TextField,
  VisualSize,
} from '@cian/components';
import { IStyleConfig, mergeStyles } from '@cian/utils';

import { flatten, groupBy, isEqual, sortBy, uniq } from 'lodash';
import * as React from 'react';
import { GeoTagKind, IDistrict, ISubdistrict, IUnderground } from '../../../api/api';
import { gteTablet } from '../../../redux/common/actions';
import { ResourceStatus } from '../../../redux/common/model';
import Counter, { CounterSize, CounterTheme } from '../../../ui/counter/counter';
import Icon from '../../../ui/icon/icon';
import { PopupMobile } from '../../../ui/popup/popup';
import { PopupHeaderContainer } from '../../../ui/popup_header/popup_header';
import Preloader from '../../../ui/preloader/preloader';
import {
  AnalyticsAction,
  AnalyticsPlace,
  openRegionsPopup,
  openRegionsPopupTab,
  regionsPopupSaveClick,
} from '../../../utils/analytics';
import { isIE } from '../../../utils/device_detector';
import logException from '../../../utils/exceptions';
import { shallowEqual } from '../../../utils/react_helpers';
import { isMoscowAndRegion } from '../../../utils/regions';
import { IFieldProps, IGeoTag, isNotUpcomingSale, IsVisibleCheck } from '../field';

import { PopupParamName, PopupParamNames } from '../../../redux/popups/actions';
import { createBodyScroll } from '../../../utils/body_scroll';

const bodyScroll = createBodyScroll();

const styles = require('./advanced_region.css');
const fieldStyles = require('../field.css');

// Приходится хардкодить IDs, так как некоторые станции относятся к разным линиям,
// но для определения таких станций нет данных
const MCK_ID = 22;
const MCK_STATIONS = [28, 272, 62, 2, 40, 152, 301, 300, 137, 21];
const RING_STATIONS = [103, 38, 50, 78, 86, 123, 159, 15, 46, 80, 85, 61, 150];
const INSIDE_THE_RING_STATIONS = [149, 148, 145, 132, 130, 129, 125, 124, 121, 119,
  115, 105, 98, 96, 84, 77, 68, 64, 58, 56, 54, 47, 20, 18, 8, 4];
const NEW_MOSCOW_DISTRICTS = [325, 326];

const withoutNewMoscowText = 'Москва без Новой Москвы';
const newMoscowText = 'Новая Москва';
const ringStationsText = 'Все станции кольцевой';
const insideRingStationsText = 'Все станции внутри кольца';
const underConstructionStations = 'Все строящиеся станции';

const dataMark = 'AdvancedRegion';

enum CurrentTab {
  Underground,
  District,
  MCK,
}

interface IAdvancedRegionState {
  searchValue: string;
  currentTab: CurrentTab;
  prevTags: IGeoTag[];
  popupParamName: PopupParamName;
}

interface IAdvancedRegionProps extends IFieldProps {
  showSpecifyText?: boolean;
  containerStyle: IStyleConfig;
  linkButtonStyle?: IStyleConfig;
}

export class AdvancedRegion extends React.Component<IAdvancedRegionProps, IAdvancedRegionState> {
  public state = {
    searchValue: '',
    currentTab: CurrentTab.Underground,
    prevTags: [],
    popupParamName: gteTablet(this.props.viewMode)
      ? PopupParamNames.AdvancedRegionTablet
      : PopupParamNames.AdvancedRegion,
  };

  private content: HTMLElement | null;

  public shouldComponentUpdate(newProps: IFieldProps, newState: IAdvancedRegionState) {
    return !isEqual(this.props.filter.geoTags, newProps.filter.geoTags)
      || !isEqual(this.props.regions.regionMeta, newProps.regions.regionMeta)
      || this.props.regions.regionMeta.status !== newProps.regions.regionMeta.status
      || this.props.filter.region !== newProps.filter.region
      || this.props.search !== newProps.search
      || !shallowEqual(this.state, newState);
  }

  public render() {
    const isMetaFetching = this.props.regions.regionMeta.status === ResourceStatus.Pending;
    const shouldShowFilter = this.showUndergrounds() || this.showDistricts() || this.showMCK();

    if (shouldShowFilter || isMetaFetching) {
      const linkButtonStyles = mergeStyles(styles.linkButton, this.props.linkButtonStyle);

      return (
        <div {...mergeStyles(this.props.containerStyle) }>
          {this.props.showSpecifyText &&
            <span className={styles.specifyText}>Указать:</span>}
          {isMetaFetching &&
            <Preloader
              size={16}
              containerStyle={styles.preloader}
            />}
          {shouldShowFilter &&
            <span
              className={styles.buttons}
              data-mark={dataMark + '|buttons'}>
              {this.showUndergrounds() && !isMetaFetching &&
                <button
                  {...linkButtonStyles}
                  type="button"
                  onClick={() => this.openPopup(CurrentTab.Underground)}
                  data-mark={dataMark + '|button'}
                >Метро</button>
              }
              {this.showMCK() && !isMetaFetching &&
                <button
                  {...linkButtonStyles}
                  type="button"
                  onClick={() => this.openPopup(CurrentTab.MCK)}
                  data-mark={dataMark + '|button'}
                >МЦК</button>
              }
              {this.showDistricts() && !isMetaFetching &&
                <button
                  {...linkButtonStyles}
                  type="button"
                  onClick={() => this.openPopup(CurrentTab.District)}
                  data-mark={dataMark + '|button'}
                >Район</button>}
            </span>}
          {shouldShowFilter && this.renderPopup()}
        </div>
      );
    }

    return null as any; // tslint:disable-line:no-any
  }

  private showUndergrounds = () => {
    const { regionMeta } = this.props.regions;

    return regionMeta != null
      && regionMeta.underground != null
      && regionMeta.underground.length > 0;
  }

  private showDistricts = () => {
    const { regionMeta } = this.props.regions;

    return regionMeta != null
      && regionMeta.districts != null
      && regionMeta.districts.length > 0;
  }

  private showMCK = () => {
    const { regions: { regionMeta }, filter: { region } } = this.props;

    return this.showUndergrounds()
      && isMoscowAndRegion(region)
      && regionMeta!.underground.some(this.filterMCK);
  }

  private getLineIdById = (id: number): number => {
    const underground = this.getUndergounds().find(u => u.id === id);

    return underground != null && underground.lineId != null
      ? underground.lineId
      : -1;
  }

  private getUndergoundsCount = () => {
    const predicate = (geoTag: IGeoTag) => geoTag.kind.toLowerCase() === 'underground'
      && this.getUndergounds().some((underground: IUnderground) => underground.id === geoTag.id);

    return this.props.filter.geoTags
      .filter(predicate).length;
  }

  private getMCKCount = () => {
    const predicate = (geoTag: IGeoTag) => geoTag.id != null
      && geoTag.kind.toLowerCase() === 'underground'
      && this.getUndergounds().some(underground => underground.id === geoTag.id)
      && (this.getLineIdById(geoTag.id) === MCK_ID || MCK_STATIONS.includes(geoTag.id));

    return this.props.filter.geoTags
      .filter(predicate).length;
  }

  private getDistrictsCount = () => {
    const predicate = (geoTag: IGeoTag) => geoTag.kind.toLowerCase() === 'district'
      && flatten(this.getDistricts()
        .map(d => d.childs.concat(d)))
        .some((district: IDistrict) => district.id === geoTag.id);

    return this.props.filter.geoTags
      .filter(predicate).length;
  }

  private renderTabLabel = (label: string, count: number) => {
    return (
      <div>
        <Counter
          theme={CounterTheme.DARK_GRAY}
          size={CounterSize.S}
          styleConfig={styles.counter}
        >
          {count}
        </Counter>
        {label}
      </div>
    );
  }

  private renderPopup() {
    const { currentTab } = this.state;
    const tabs = [];
    const withTabs = this.showDistricts() && this.showUndergrounds();

    // tslint:disable: no-any
    if (this.showUndergrounds()) {
      tabs.push({
        value: CurrentTab.Underground as any,
        label: this.renderTabLabel('Метро', this.getUndergoundsCount()),
      });
    }
    if (this.showMCK()) {
      tabs.push({
        value: CurrentTab.MCK as any,
        label: this.renderTabLabel('МЦК', this.getMCKCount()),
      });
    }
    if (withTabs) {
      tabs.push({
        value: CurrentTab.District as any,
        label: this.renderTabLabel('Район', this.getDistrictsCount()),
      });
    }

    const headerStyles = [
      withTabs && styles.popupHeader,
    ];

    const header = (
      <PopupHeaderContainer
        headerStyle={headerStyles}
        closeStyle={styles.closeButton}
        onCloseClick={this.closePopupWithoutSaving}
        dataMark={dataMark}
      >
        <div>Указать</div>

        {withTabs &&
          <ButtonRadioGroupField
            containerStyle={[styles.tabsContainer]}
            buttonStyle={[styles.tabButton]}
            activeButtonStyle={fieldStyles.active}
            fit={ButtonRadioGroupFieldFit.CONTAINER}
            value={currentTab as any}
            onValueChange={this.setTab as any}
            values={tabs}
          />}
      </PopupHeaderContainer>
    );
    // tslint:enable
    const showDivider = currentTab === CurrentTab.Underground
      && !this.state.searchValue;

    const footerStyles = mergeStyles([
      styles.fixedContainer,
      styles['saved_button'],
      isIE(this.props.userAgent) && styles.ieFixedContainer,
    ]);

    const footer = (
      <div {...footerStyles} data-mark={dataMark + '|button'}>
        <Button
          mobile
          theme={ButtonTheme.ORANGE_SOLID}
          buttonStyle={[styles.saveButton]}
          onClick={this.closePopup}
        >
          Сохранить
        </Button>
      </div>
    );

    return (
      <PopupMobile
        opened={this.props.isPopupOpened(this.state.popupParamName)}
        containerStyle={styles.popupContainer}
        innerStyle={[styles.popupInner]}
        contentStyle={mergeStyles(
          styles.popupContent,
          !withTabs && styles.withoutTabs,
        ).className}
        header={header}
        footer={footer}
        contentRef={this.setRef}
        bodyScroll={bodyScroll}
        onClickOutside={this.closePopupWithoutSaving}
        dataMark={dataMark}
      >
        <div>
          {this.renderFilters()}
          {showDivider && <div className={styles.divider} />}
          {this.areThereNoResults() && <div className={styles.noResults}>Ничего не найдено</div>}
          <div className={styles.contentPadding}>
            {(currentTab === CurrentTab.Underground || currentTab === CurrentTab.MCK)
              && this.renderUndergrounds()}
            {currentTab === CurrentTab.District && this.renderDistricts()}
          </div>

        </div>
      </PopupMobile>
    );
  }

  private setRef = (el: HTMLElement | null) => {
    this.content = el;
  }

  private openPopup = (currentTab: CurrentTab) => {
    this.props.popupActions.openPopup(this.state.popupParamName);
    this.setState({
      ...this.state,
      currentTab,
      prevTags: this.props.filter.geoTags,
      searchValue: '',
    });

    openRegionsPopup(
      this.getCurrentAnalyticsPlace(),
      `popup_${this.tabToString(currentTab)}_open` as AnalyticsAction,
    );
  }

  private closePopup = () => {
    this.props.popupActions.closePopup(
      this.state.popupParamName,
      this.props.filtersPlace === 'map',
    );
    regionsPopupSaveClick(
      this.getCurrentAnalyticsPlace(),
      `popup_${this.tabToString(this.state.currentTab)}_save` as AnalyticsAction,
      `${this.getUndergoundsCount() + this.getDistrictsCount()}`,
    );
  }

  private closePopupWithoutSaving = () => {
    this.props.actions.setGeoTags(this.state.prevTags);
    this.closePopup();
  }

  private setTab = (currentTab: CurrentTab) => {
    this.setState({
      ...this.state,
      currentTab,
    });

    openRegionsPopupTab(
      this.getCurrentAnalyticsPlace(),
      `popup_${this.tabToString(currentTab)}_tab_click` as AnalyticsAction,
    );

    if (this.content) {
      this.content.scrollTop = 0;
    }
  }

  private setSearchValue = (searchValue: string) => {
    this.setState({
      ...this.state,
      searchValue,
    });
  }

  private search = (entity: IDistrict | ISubdistrict | IUnderground): boolean => {
    const { searchValue } = this.state;

    return (entity.name == null && searchValue === '')
      || entity != null && entity.name != null && entity.name.toLowerCase().includes(searchValue.toLowerCase());
  }

  private clearSearch = () => {
    this.setState({
      ...this.state,
      searchValue: '',
    });
  }

  private getRingsStations = (): number[] => {
    return uniq(this.getUndergounds()
      .filter(u => u.id != null && RING_STATIONS.includes(u.id))
      .map(u => u.id as number));
  }

  private getConstructingStations = (): number[] => {
    return this.getUndergounds()
      .filter(u => u.id && u.underConstruction)
      .map(u => u.id as number);
  }

  private renderFilters = () => {
    const { currentTab } = this.state;
    const { geoTags } = this.props.filter;

    const searchPlaceholder = currentTab === CurrentTab.District
      ? 'Поиск района'
      : 'Поиск станции';

    const showCheckboxes = currentTab === CurrentTab.Underground
      && isMoscowAndRegion(this.props.filter.region);

    const insideTheRingChecked = geoTags.length > 0
      && INSIDE_THE_RING_STATIONS
        .every(id => geoTags.some(t => t.id === id && t.kind.toLowerCase() === 'underground'));

    const ringLinesChecked = geoTags.length > 0
      && this.getRingsStations()
        .every(id => geoTags.some(t => t.id === id && t.kind.toLowerCase() === 'underground'));

    const underConstructionChecked = geoTags.length > 0
    && this.getConstructingStations()
      .every(id => geoTags.some(t => t.id === id && t.kind.toLowerCase() === 'underground'));

    const filtersStyles = mergeStyles(
      styles.filters,
    );

    const containerStyle = [
      fieldStyles.inputContainer,
    ];

    const ringLabelStyle = mergeStyles(
      fieldStyles.labelCheckbox,
      ringLinesChecked && fieldStyles.checked,
    );

    const insideRingLabelStyle = mergeStyles(
      fieldStyles.labelCheckbox,
      insideTheRingChecked && fieldStyles.checked,
    );

    const underConstructionLabelStyle = mergeStyles(
      fieldStyles.labelCheckbox,
      underConstructionChecked && fieldStyles.checked,
    );

    return (
      <div
        {...filtersStyles}
        data-mark={dataMark + '|'} >
        <TextField
          value={this.state.searchValue}
          onValueChange={this.setSearchValue}
          onClear={this.clearSearch}
          placeholder={searchPlaceholder}
          containerStyle={containerStyle}
          size={VisualSize.MEDIUM}
        />
        {showCheckboxes &&
          <div>
            {this.includesSearch(ringStationsText) &&
              <CheckboxField
                value={ringLinesChecked}
                onValueChange={value => this.toggleRingStations(value, false)}
                containerStyle={[styles.filterCheckbox]}
                labelStyle={ringLabelStyle.className}
                label={ringStationsText}
              />}
            {this.includesSearch(insideRingStationsText) &&
              <CheckboxField
                value={insideTheRingChecked}
                onValueChange={value => this.toggleRingStations(value, true)}
                containerStyle={[styles.filterCheckbox]}
                labelStyle={insideRingLabelStyle.className}
                label={insideRingStationsText}
              />}
             {this.includesSearch(underConstructionStations) &&
              <CheckboxField
                value={underConstructionChecked}
                onValueChange={value => this.toggleConstructingStations(value)}
                containerStyle={[styles.filterCheckbox]}
                labelStyle={underConstructionLabelStyle.className}
                label={underConstructionStations}
              />}
          </div>}
      </div>
    );
  }

  private renderDistricts = () => {
    const { geoTags, region } = this.props.filter;
    const districts = this.getDistricts().filter((d: IDistrict) => {
      return this.search(d) || d.childs.some(this.search);
    });

    const flatDistricts = (predicate: (d: IDistrict) => boolean): ISubdistrict[] => {
      return flatten(this.getDistricts()
        .filter(predicate)
        .map((d: IDistrict) => d.childs));
    };

    const withoutNewMoscow = flatDistricts((d: IDistrict) => d.id != null && !NEW_MOSCOW_DISTRICTS.includes(d.id));
    const newMoscow = flatDistricts((d: IDistrict) => d.id != null && NEW_MOSCOW_DISTRICTS.includes(d.id));

    const withoutNewMoscowChecked = withoutNewMoscow.length > 0
      && withoutNewMoscow.every((d: IDistrict) => this.tagIsChecked('district', d));

    const newMoscowChecked = newMoscow.length > 0
      && newMoscow.every((d: IDistrict) => this.tagIsChecked('district', d));

    return (
      <div>
        {isMoscowAndRegion(region) &&
          <div>
            {this.includesSearch(withoutNewMoscowText) &&
              <ButtonCheckbox
                mobile
                checked={withoutNewMoscowChecked}
                name="without-new-moscow"
                buttonCheckboxStyle={[styles.checkboxButton]}
                onValueChange={value => this.toggleDistictTags(withoutNewMoscow, value)}
              >
                {withoutNewMoscowText}
              </ButtonCheckbox>}
            {this.includesSearch(newMoscowText) &&
              <ButtonCheckbox
                mobile
                checked={newMoscowChecked}
                name="new-moscow"
                buttonCheckboxStyle={[styles.checkboxButton]}
                onValueChange={value => this.toggleDistictTags(newMoscow, value)}
              >
                {newMoscowText}
              </ButtonCheckbox>}
          </div>
        }

        {!this.areThereNoResults() && districts.map((d: IDistrict) => {
          const checked = (d.childs.length > 0 && d.childs
            .every((s: ISubdistrict) => geoTags.some(t => t.id === s.id && t.kind.toLowerCase() === 'district')))
            || (d.childs.length === 0 && geoTags.some(t => t.id === d.id && t.kind.toLowerCase() === 'district'));

          const childs = sortBy(
            d.childs.filter(this.search),
            (subdistrict: ISubdistrict) => subdistrict.name.toLowerCase(),
          );

          return (
            <div key={`district-${d.id}`}>
              <ButtonCheckbox
                mobile
                checked={checked}
                id={`region-destrict-${d.id}`}
                name="district"
                buttonCheckboxStyle={[styles.checkboxButton]}
                onValueChange={value => this.toggleDistictTags(d.childs.length > 0 ? d.childs : [d], value)}
              >
                {d.name}
              </ButtonCheckbox>

              {childs
                .map((subdistrict: ISubdistrict) => {
                  const value = this.tagIsChecked('district', subdistrict);
                  return (
                    <CheckboxField
                      containerStyle={[styles.checkbox]}
                      key={subdistrict.name}
                      label={subdistrict.name}
                      value={value}
                      onValueChange={v => this.toggleDistictTags([subdistrict], v)}
                      labelStyle={mergeStyles(value && fieldStyles.checked).className}
                    />
                  );
                })}
            </div>
          );
        })}
      </div>
    );
  }

  private includesSearch = (text: string) => {
    return text.toLowerCase().includes(this.state.searchValue.toLowerCase());
  }

  private getDistricts = () => {
    const { regionMeta } = this.props.regions;
    return regionMeta != null
      ? regionMeta.districts
      : [];
  }

  private getUndergounds = () => {
    const { regionMeta } = this.props.regions;
    return regionMeta != null
      ? regionMeta.underground
      : [];
  }

  private filterMCK = (u: IUnderground): boolean => {
    return u.lineId === MCK_ID || MCK_STATIONS.includes(u.id as number);
  }

  private renderUndergrounds = () => {
    let undergrounds = this.getUndergounds().filter(this.search);

    if (this.state.currentTab === CurrentTab.MCK) {
      undergrounds = undergrounds
        .filter(this.filterMCK);
    }
    undergrounds = sortBy(undergrounds, (u: IUnderground) => u.name && u.name.toLowerCase());
    const undergroundGroups = groupBy(undergrounds, (u: IUnderground) => u.name != null ? u.name[0] : 'А') || {};

    return Object.keys(undergroundGroups).sort().map((letter: string) => {
      const titleStyles = styles.groupTitle;

      const isUnderground = this.state.currentTab === CurrentTab.Underground;

      return (
        <div key={letter}>
          <div {...titleStyles}>{letter}</div>

          {undergroundGroups[letter].map((u: IUnderground) => {
            const checkboxContent = (
              <span>
                {isUnderground && <Icon name="underground" styleConfig={{ fill: `#${u.lineColor}` }} />}
                <span
                  {...mergeStyles(
                    u.underConstruction && styles['text-grayed'],
                    { marginLeft: 6 },
                  )}
                >
                  {`${u.name}${u.underConstruction ? ` · ${u.releaseYear}` : ''}`}
                </span>
              </span>
            );

            const value = this.tagIsChecked('underground', u);

            return (
              <CheckboxField
                containerStyle={[styles.checkbox]}
                key={u.name}
                label={checkboxContent}
                value={value}
                onValueChange={v => this.toggleUndergroundTag(u, v)}
                labelStyle={mergeStyles(value && fieldStyles.checked).className}
              />
            );
          })}
        </div>
      );
    });
  }

  private areThereNoResults = (): boolean => {
    const { currentTab } = this.state;
    let undergrounds = this.getUndergounds().filter(this.search);
    const districts = this.getDistricts().filter((d: IDistrict) => {
      return this.search(d) || d.childs.some(this.search);
    });

    if (this.state.currentTab === CurrentTab.MCK) {
      undergrounds = undergrounds.filter(this.filterMCK);
    }

    if (currentTab === CurrentTab.Underground || currentTab === CurrentTab.MCK) {
      return this.state.searchValue.length > 0 && undergrounds.length === 0
        && !this.includesSearch(ringStationsText)
        && !this.includesSearch(insideRingStationsText);
    }

    return this.state.searchValue.length > 0 && districts.length === 0
      && !this.includesSearch(newMoscowText)
      && !this.includesSearch(withoutNewMoscowText);
  }

  private addUndergoundTag = (tag: IUnderground) => {
    const { region } = this.props.filter;

    this.props.actions.addGeoTag({
      id: tag.id,
      regionId: region,
      name: tag.name || '',
      text: tag.name || '',
      kind: 'Underground',
      isParsed: true,
      color: '#' + tag.lineColor,
      underConstruction: tag.underConstruction,
      releaseYear:  tag.releaseYear,
    });
  }

  private toggleUndergroundTag = (tag: IUnderground, checked: boolean) => {
    if (checked) {
      this.addUndergoundTag(tag);
    } else {
      this.props.actions.deleteGeoTag({ id: tag.id, kind: 'Underground' });
    }
  }

  private toggleRingStations = (checked: boolean, inside: boolean) => {
    const undergrounds = this.getUndergounds();

    undergrounds.forEach((u: IUnderground) => {
      if (!inside && u.id && this.getRingsStations().includes(u.id)) {
        this.toggleUndergroundTag(u, checked);
      } else if (inside && u.id && INSIDE_THE_RING_STATIONS.includes(u.id)) {
        this.toggleUndergroundTag(u, checked);
      }
    });
  }

  private toggleConstructingStations = (checked: boolean) => {
    const undergrounds = this.getUndergounds();
    const constructing = this.getConstructingStations();
    undergrounds
      .filter((u: IUnderground) => u.id && constructing.includes(u.id))
      .forEach((u: IUnderground) => this.toggleUndergroundTag(u, checked));
  }

  private toggleDistictTags = (tags: (IDistrict | ISubdistrict)[], checked: boolean) => {
    const { region } = this.props.filter;

    tags.forEach((t: IDistrict) => {
      if (checked) {
        this.props.actions.addGeoTag({
          id: t.id,
          regionId: region,
          name: t.name || '',
          text: t.name || '',
          kind: 'District',
          isParsed: true,
        });
      } else {
        this.props.actions.deleteGeoTag({ id: t.id, kind: 'District' });
      }
    });
  }

  private tagIsChecked = (kind: GeoTagKind, tag: IUnderground | IDistrict | ISubdistrict): boolean => {
    return this.props.filter.geoTags
      .some(t => t.id === tag.id && t.kind.toLowerCase() === kind);
  }

  private tabToString = (tab: CurrentTab): string => {
    switch (tab) {
      case CurrentTab.Underground:
        return 'metro';
      case CurrentTab.MCK:
        return 'mck';
      case CurrentTab.District:
        return 'district';
      default:
        logException(new Error(`Tab value ${tab} is not allowed`), tab);
        return '';
    }
  }

  private getCurrentAnalyticsPlace = (): AnalyticsPlace => {
    const { showAdvancedFilters } = this.props.filter;
    let isAdvancedSearchPage = false;

    if (typeof window !== 'undefined') {
      isAdvancedSearchPage = window.location.href.includes('/nd/search');
    }

    return showAdvancedFilters || isAdvancedSearchPage
      ? 'Mob_advanced_search'
      : 'Mob_main_page';
  }
}

export const Component: React.SFC<IFieldProps> = props => {
  return <AdvancedRegion showSpecifyText containerStyle={styles.field} {...props} />;
};

export const isVisible: IsVisibleCheck = isNotUpcomingSale;
