import { IOfferDetail, IPhoto as IApiPhoto } from '../../../api/models/offer_card';
import { IPhoto } from '../../../redux/offer/model';

const mapPhoto = ({ id, isDefault, isLayout, fullUrl, miniUrl, thumbnailUrl, thumbnail2Url }: IApiPhoto): IPhoto => ({
  id,
  isDefault,
  isLayout,
  fullUrl,
  miniUrl,
  thumbnailUrl,
  thumbnail2Url,
});

export const responseToPhotos = (offer: IOfferDetail): IPhoto[] => {
  const { layoutPhoto, photos } = offer;
  if (photos == null) {
    return [];
  }

  if (!layoutPhoto) {
    return photos.map(mapPhoto);
  }

  let resultPhotos: IPhoto[] = [];
  let layoutFound = false;
  photos.forEach(photo => {
    const mappedPhoto = mapPhoto(photo);
    if (photo.id === layoutPhoto.id) {
      layoutFound = true;
      // свойство isLayout при этом все равно может отсутсвовать
      mappedPhoto.isLayout = true;
    }
    resultPhotos.push(mappedPhoto);
  });

  if (!layoutFound) {
    resultPhotos.push({ ...mapPhoto(layoutPhoto), isLayout: true });
  }

  return resultPhotos;
};
