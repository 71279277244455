import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setJsonQueryPriceType } from '../../../../../../redux/filters/actions';
import { ReduxState } from '../../../../../../redux/model';

import { CoworkingPriceTypeRadioButtonsComponent } from './CoworkingPriceTypeRadioButtonsComponent';
import { ICoworkingPriceTypeRadioButtonsProps } from './types';

/* istanbul ignore next */
export const CoworkingPriceTypeRadioButtons = connect<
  Pick<ICoworkingPriceTypeRadioButtonsProps, 'priceType'>,
  Pick<ICoworkingPriceTypeRadioButtonsProps, 'setPriceType'>,
  {},
  ReduxState>(
  (state) => ({
    priceType: state.filter.jsonQueryPriceType,
  }),
  (dispatch) => bindActionCreators(
    {
      setPriceType: setJsonQueryPriceType,
    },
    dispatch,
  ),
  )(CoworkingPriceTypeRadioButtonsComponent);
