import * as React from 'react';
import { IMapInfrastructure, TInfrastructureLabel } from '../../../../redux/filters/model';
import CheckboxField from '../../../../ui/cian_components/checkbox_field/checkbox_field';
import { trackInfrastructureCheckboxChoice } from '../trackings';

const styles = require('./item.css');
const SPACE = '\u0020';

const labels: TIndexSignatureStrings = {
  withCafes: 'Кафе',
  withSchools: 'Школы',
  withHospitals: 'Поликлиники',
  withKindergartens: 'Детские сады',
  withGroceryStores: 'Продукт. магазины',
};

export const queryStringParams: TIndexSignatureStrings = {
  withCafes: 'with_cafe',
  withSchools: 'with_schools',
  withHospitals: 'with_hospitals',
  withKindergartens: 'with_kindergartens',
  withGroceryStores: 'with_grocery_stores',
};

export interface IInfrastructureItemStateProps {
  checked: boolean;
}

export interface IInfrastructureItemOwnProps {
  icon: React.ReactNode;
  name: keyof IMapInfrastructure;
}

export interface IInfrastructureItemDispatchProps {
  onClick: (type: keyof IMapInfrastructure) => void;
}

export type TIndexSignatureStrings = {[key: string]: string};

export type IInfrastructureItemProps = IInfrastructureItemOwnProps &
                                       IInfrastructureItemStateProps &
                                       IInfrastructureItemDispatchProps;

export function Item({icon, onClick, checked, name}: IInfrastructureItemProps) {
  const handler = () => {
    const mapInfrastructureTypeToLabel: TIndexSignatureStrings = {
      withCafes: 'cafes',
      withSchools: 'schools',
      withHospitals: 'hospitals',
      withGroceryStores: 'shops',
      withKindergartens: 'kindergartens',
    };

    onClick(name);
    trackInfrastructureCheckboxChoice(mapInfrastructureTypeToLabel[name] as TInfrastructureLabel);
  };

  return(
    <div className={styles['item']} onClick={handler}>
      <div className={styles['content']}>
        {icon}{SPACE}
        <div className={styles['part']}>{labels[name]}</div>
      </div>
      <div className={styles['part']}>
        <CheckboxField
          labelStyle={styles['label']}
          label={''}
          onValueChange={() => {}} //заглушка, так как onClick срабатывает по клику на всю строку
          value={checked}
        />
      </div>
    </div>
  );
}
