import * as React from 'react';
import { mergeStyles } from '@cian/utils';

const styles = require('./IconArrow.css');

interface IIconArrow {
  className?: string;
  isCollapsed: boolean;
}

export const IconArrow: React.FC<IIconArrow> = (props) => {
  const { className, isCollapsed } = props;

  return (
    <svg
      {...mergeStyles(styles['root'], isCollapsed && styles['collapsed'], className)}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
    >
      <path
        fill="#0468FF"
        fillRule="evenodd"
        d="M12.707 9.293l-1.414 1.414L8 7.414l-3.293 3.293-1.414-1.414L8 4.586l4.707 4.707z"
        clipRule="evenodd"
      />
    </svg>
  );
};

IconArrow.displayName = 'IconArrow';
