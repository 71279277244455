export function sort(key: string) {
  // tslint:disable-next-line:no-any
  return (a: any, b: any) => {
    if (a[key] < b[key]) {
      return -1;
    }

    if (a[key] > b[key]) {
      return 1;
    }

    return 0;
  };
}
