import { RouteName } from '../redux/routing/model';
import { trackFirstMessageSend } from './analytics';

const FLOCTORY_CHECK_TIMEOUT = 1500;
/**
 * Название события, которое отправляем в dataLayer.push,
 * для показа системного попапа разрешения пуш уведомлений
 */
export const FLOCKTORY_KEY_EVENT = 'show_push_cian_site';
export const PUSH_SUBSCRIPTION_COOKIE_KEY = 'push_subscription';

export const checkFlocktoryLoad: () => Promise<IFlocktory> = () => {
  return new Promise((resolve) => {
    const flocktoryAttempt = () => {
      if (window.flocktory && typeof window.flocktory.getSubscriptionStatus === 'function') {
        clearTimeout(timeIntervalFlocktoryCheck);

        return resolve(window.flocktory);
      }

      timeIntervalFlocktoryCheck = setTimeout(flocktoryAttempt, FLOCTORY_CHECK_TIMEOUT);
    };

    let timeIntervalFlocktoryCheck = setTimeout(flocktoryAttempt, FLOCTORY_CHECK_TIMEOUT);
  });
};

export function sendPushNotificationSystemPopup(
  routeName: RouteName,
  showPushNotificationCheckbox: boolean,
  isPushCheck: boolean,
) {
  if (window.dataLayer && isPushCheck) {
    window.dataLayer.push({ event: FLOCKTORY_KEY_EVENT });
  }

  trackFirstMessageSend(routeName, showPushNotificationCheckbox, isPushCheck);
}
