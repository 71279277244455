import * as React from 'react';

import { mergeStyles } from '@cian/utils';
import { omit } from 'lodash';

const styles = require('./sandwich.css');

interface ISandwichProps {
  showAsClose: boolean;
  className?: string;
  style?: React.CSSProperties;
  light?: boolean;
  [key: string]: any; // tslint:disable-line
}

const omitProps = (props: ISandwichProps) => omit(props, ['showAsClose', 'className', 'style', 'light']);

const Sandwich: React.SFC<ISandwichProps> = (props: ISandwichProps) => {
  const svgStyles = mergeStyles(
    styles.sandwich,
    props.light && styles.lightSandwich,
    props.showAsClose && styles.close,
    props.className,
    props.style,
  );

  return (
    <div {...omitProps(props)} {...svgStyles}>
      <div className={styles.one}></div>
      <div className={styles.two}></div>
      <div className={styles.three}></div>
    </div>
  );
};

export default Sandwich;
