/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { IWSUniformResourceIdentifierDeclaration } from '@cian/chats-api/lib/browser-entry';
import { ICommonHttpClassConfig } from '@cian/chats-api/lib/shared/http/common_class';
import { INotificationsCountContext } from '@cian/chats-notifications-connect';

import { pick } from 'lodash';
import * as PropTypes from 'prop-types';
import * as React from 'react';

import { ApplicationContextModel } from '../../shared/utils/applicationContext';
import { MobileWebsiteApi } from '../api/api';
import { FixedComponentsStore } from '../components/fixed/store';

import { CookiesUtils } from './cookies';
import { IMetrics } from './metrics/shared';
import { ReactComponent } from './react_helpers';

// экспортируем типы контекста, чтобы было удобней импортировать
export { MobileWebsiteApi, FixedComponentsStore, CookiesUtils, IMetrics };

export interface IChatsConfig {
  httpConfig: ICommonHttpClassConfig;
  timeout: number;
  unreadContext: INotificationsCountContext;
  wsApiBaseUrl: IWSUniformResourceIdentifierDeclaration;
}

/**
 * @deprecated
 */
export interface IAppContext extends ApplicationContextModel {
  api: MobileWebsiteApi;
  chats: IChatsConfig | undefined;
  fixedComponentsStore: FixedComponentsStore;
  cookiesUtils: CookiesUtils;
  metrics: IMetrics;
  getUserDataTimeout?: number;
}

/**
 * @deprecated
 */
const appContextDI = createDI<IAppContext>('appContext');
/**
 * @deprecated
 */
export const inject = appContextDI.inject;
/**
 * @deprecated
 */
export const AppContextProvider = appContextDI.ContextProvider;

/**
 * @deprecated
 */
export function createDI<TContextProps extends object>(id: string) {
  interface IContextProviderProps {
    context: TContextProps;
  }

  const contextTypes = {
    [id]: PropTypes.object,
  };

  /**
   * @deprecated
   */
  class ContextProvider extends React.Component<IContextProviderProps, object> {
    public static childContextTypes = contextTypes;

    public getChildContext(): { [p: string]: TContextProps } {
      return { [id]: this.props.context };
    }

    public render(): React.ReactNode {
      return React.Children.only(this.props.children as React.ReactNode);
    }
  }

  /**
   * @deprecated
   */
  function createWrap<TPickedProps>(propsSelector: (context: TContextProps) => TPickedProps) {
    return function wrap<TOwnProps>(
      Component: ReactComponent<TPickedProps & TOwnProps>,
    ): React.ComponentClass<TOwnProps> {
      return class ConnectedToAppContextComponent extends React.Component<TOwnProps, object> {
        public static displayName = `connectContext(${Component.displayName || Component.name})`;
        public static contextTypes = contextTypes;

        private pickedContext: TPickedProps;

        public constructor(props: TOwnProps, context: { [key: string]: TContextProps }) {
          super(props, context);
          this.pickedContext = propsSelector(context[id]);
        }

        public render(): React.ReactNode {
          return <Component {...this.pickedContext} {...this.props} />;
        }
      };
    };
  }

  /**
   * @deprecated
   */
  function injectFunc<K extends keyof TContextProps>(keys: K[]) {
    return createWrap<Pick<TContextProps, K>>(appContext => pick<TContextProps, K>(appContext, keys));
  }

  /**
   * @deprecated
   */
  function injectAll<TOwnProps>(Component: ReactComponent<TContextProps & TOwnProps>): React.ComponentClass<TOwnProps> {
    return createWrap(appContext => appContext)(Component);
  }

  return { inject: injectFunc, injectAll, ContextProvider };
}
