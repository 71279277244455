import { Tag, VisualSize } from '@cian/components';
import { IStyleConfig, mergeStyles } from '@cian/utils';
import * as React from 'react';

import * as filterActions from '../../../../redux/filters/actions';
import Preloader from '../../../../ui/preloader/preloader';
import Text, { TextColor } from '../../../../ui/text/text';
import { getRegionTitleById } from '../../../../utils/regions';
import { IGeoTag, FiltersPlace } from '../../../fields/field';
import { GeoTagKind } from '../../../../api/api';

import * as Trackings from './trackings';

const styles = require('./tags.css');

interface ITagsProps {
  tags: IGeoTag[];
  region: number;
  actions: typeof filterActions;
  tagStyle?: IStyleConfig;
  tagsStyle?: IStyleConfig;
  onTagClick?: React.MouseEventHandler<HTMLElement>;
  loading?: boolean;
  dataMark?: string;
  filtersPlace: FiltersPlace;
}

export const Tags: React.SFC<ITagsProps> = props => {
  const onDeleteClick = (id: number | undefined, kind: GeoTagKind, filtersPlace: FiltersPlace) => {
    Trackings.trackTagsDeleteClick(kind, filtersPlace);

    props.actions.deleteGeoTag({ id, kind});
  }

  const tags = props.tags.map((tag, index) => {
    const tagText = tag.text
      .replace('Россия, ', '')
      .replace(getRegionTitleById(props.region) + ', ', '');

    return (
      <Tag
        //чтобы не ломалось когда одинаковый текст в тегах
        key={`${tag.text}-${index + 1}`}
        onClick={props.onTagClick as (() => void)}
        onDeleteClick={() => onDeleteClick(tag.id, tag.kind, props.filtersPlace)}
        tagStyles={[styles.geoTag, tag.underConstruction && styles['under-construction']].concat(props.tagStyle)}
      >
        {tag.color != null &&
          <span style={{marginRight: 5, marginLeft: -5}}>
            <svg height="8" width="8">
              <circle cx="4" cy="4" r="4" fill={tag.color} />
            </svg>
          </span>}
        {tag.name || tagText}
        {tag.underConstruction &&
          <div className={styles['release-year']}>
            {` · ${tag.releaseYear}`}
          </div>}
      </Tag>
    );
  });

  return (
    <div
      {...mergeStyles(styles.tagsContainer, props.tagsStyle)}
      data-mark={`${props.dataMark ? props.dataMark + '|' : ''}Tags`}>
      {tags}
      {props.loading && <TagsLoader containerStyle={styles.loaderInTags} textStyle={styles.loaderInTagsText} />}
    </div>
  );
};

Tags.displayName = 'Tags';

export const TagsLoader: React.SFC<{ containerStyle?: IStyleConfig, textStyle?: IStyleConfig }> = props => {
  return (
    <div {...mergeStyles(styles.loader, props.containerStyle)}>
      <Preloader containerStyle={styles.loaderIcon} />
      <Text
        color={TextColor.GRAY}
        size={VisualSize.SMALL}
        textStyles={props.textStyle}
      >
        Добавление тега…
      </Text>
    </div>
  );
};
