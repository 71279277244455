import { Button, ButtonTheme, VisualSize } from '@cian/components';
import { IStyleConfig, mergeStyles } from '@cian/utils';

import * as React from 'react';
import { connect } from 'react-redux';

import { showAutoOfferChangeHint } from '../../redux/autoOfferChangeHint/actions';
import { goToList, searchInMap, setAdvancedFilterInPopup } from '../../redux/filters/actions';
import { FilterState } from '../../redux/filters/model';
import { ReduxState, TTypedThunkDispatch } from '../../redux/model';
import { getQueryString } from '../../selector/getQueryString';
import { selectCurrentRegion } from '../../selector/regions';
import Preloader from '../../ui/preloader/preloader';
import { filterSearchClick, refineSearchButtonClick } from '../../utils/analytics';
import declension from '../../utils/declension';
import { formatNumber } from '../../utils/formatters';
import { getFiltersDataForGA } from '../../utils/get_filters_data_for_ga';
import { IFixedComponentOwnProps, IFixedProps, INoFixedProps, makeFixedComponent } from '../fixed/fixed';

const styles = require('./search_button.css');
export const DEFAULT_SEARCH_QS = 'deal_type=sale&engine_version=2&offer_type=flat&region=1&room1=1&room2=1';
export const DEFAULT_SEARCH_QS_WITHOUT_GEO = 'deal_type=sale&engine_version=2&offer_type=flat&room1=1&room2=1';

interface ISearchButtonStoreProps {
  filter: FilterState;
  userAgent: string;
  isOfferCountFetching: boolean;
  newbuildingsPinsEnabled: boolean;
  autoOfferChangeHint?: boolean;
  gaLabel: string;
  queryString: string;
}

interface ISearchButtonDispatchProps {
  goToList(): void;
  searchInMap(isNewbuildingsMapMode?: boolean): Promise<void>;
}

interface ISearchButtonOwnProps {
  containerStyle?: IStyleConfig;
  buttonStyle?: IStyleConfig;
  inPopup: boolean;
  isUpcomingSale?: boolean;
  fromMapRoute: boolean;
  onClick?: () => void;
}

type SearchButtonProps = ISearchButtonStoreProps & ISearchButtonDispatchProps & ISearchButtonOwnProps;

export class SearchButtonComponent extends React.PureComponent<SearchButtonProps> {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public render() {
    const { props } = this;

    return (
      <div
        {...mergeStyles(styles.buttonContainer, props.containerStyle, props.autoOfferChangeHint && styles.buttonHint)}
        data-mark={'SearchButtonComponent'}
      >
        <Button
          mobile
          buttonStyle={[styles.button, props.isOfferCountFetching && styles.disabled].concat(props.buttonStyle)}
          size={VisualSize.SMALL}
          theme={ButtonTheme.ORANGE_SOLID}
          onClick={this.search}
        >
          {props.isOfferCountFetching ? <Preloader containerStyle={styles.preloader} /> : this.getButtonText()}
        </Button>
      </div>
    );
  }

  private getButtonText(): string {
    const { isUpcomingSale } = this.props;
    const { offerCount } = this.props.filter;

    if (isUpcomingSale) {
      return 'Показать объекты';
    }

    if (typeof offerCount !== 'number') {
      return 'Показать';
    }

    const countText = declension(offerCount, ['объект', 'объекта', 'объектов']);

    return `Показать ${formatNumber(offerCount)} ${countText}`;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private search = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const {
      inPopup,
      fromMapRoute,
      // tslint:disable-next-line:no-shadowed-variable
      // eslint-disable-next-line @typescript-eslint/no-shadow
      goToList,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      searchInMap,
      queryString,
    } = this.props;

    if (inPopup) {
      refineSearchButtonClick(queryString);
    } else {
      filterSearchClick(queryString);
    }

    if (this.props.onClick) {
      this.props.onClick();
    }

    if (fromMapRoute) {
      searchInMap(this.props.newbuildingsPinsEnabled);

      return;
    }
    goToList();
  };
}

//будет покрыто в https://cianru.atlassian.net/browse/CD-52132

// istanbul ignore next
const FixedSearchButton: React.SFC<SearchButtonProps & IFixedProps> = ({ containerStyle, ...props }) => {
  return (
    <SearchButtonComponent containerStyle={[styles.fixed].concat(props.styles).concat(containerStyle)} {...props} />
  );
};

// istanbul ignore next
const NoFixedSearchButton: React.SFC<SearchButtonProps & INoFixedProps> = props => {
  return props.hidden ? (
    <div {...mergeStyles(styles.buttonStub, props.autoOfferChangeHint && styles.buttonHint)} />
  ) : (
    <SearchButtonComponent {...props} />
  );
};

// istanbul ignore next
function mapStateToProps(state: ReduxState): ISearchButtonStoreProps {
  const region = selectCurrentRegion(state);

  const gaLabel = getFiltersDataForGA({ filter: state.filter, region });
  const queryString = getQueryString(state);

  return {
    autoOfferChangeHint: state.autoOfferChangeHint,
    filter: state.filter,
    userAgent: state.userAgent,
    isOfferCountFetching: state.filter.isOfferCountFetching,
    newbuildingsPinsEnabled: state.mapPage.newbuildingsPinsEnabled,
    gaLabel,
    queryString,
  };
}

// istanbul ignore next
function mapDispatchToProps(dispatch: TTypedThunkDispatch): ISearchButtonDispatchProps {
  return {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    goToList: () => {
      dispatch(showAutoOfferChangeHint(false));
      dispatch(setAdvancedFilterInPopup(undefined));

      return dispatch(goToList());
    },
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    searchInMap(isNewbuildingsMapMode?: boolean) {
      dispatch(showAutoOfferChangeHint(false));

      return dispatch(searchInMap(isNewbuildingsMapMode));
    },
  };
}

// istanbul ignore next
const SearchButtonFixed = makeFixedComponent({
  id: 'SearchButton',
  Fixed: FixedSearchButton,
  NoFixed: NoFixedSearchButton,
  shouldBeFixed: (node, { autoOfferChangeHint }) => {
    const offset = autoOfferChangeHint ? 170 : 70;
    const nodeRect = node.getBoundingClientRect();

    return window.innerHeight > document.documentElement.offsetHeight || window.innerHeight - nodeRect.top < offset;
  },
  // eslint-disable-next-line import/no-default-export
  getScrollableElement: props => {
    return window;
  },
});

// tslint:disable-next-line:max-line-length
// eslint-disable-next-line import/no-default-export
export default connect<
  ISearchButtonStoreProps,
  ISearchButtonDispatchProps,
  ISearchButtonOwnProps & IFixedComponentOwnProps
>(
  mapStateToProps,
  mapDispatchToProps,
)(SearchButtonFixed);

export const SearchButton = connect<ISearchButtonStoreProps, ISearchButtonDispatchProps, ISearchButtonOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SearchButtonComponent);
