import { isAppleDevice } from './device_detector';

export function fixZoomWhenFocusOnIOS(userAgent: string) {
  if (!isAppleDevice(userAgent)) { return; }

  // prevent iphone from zooming in when filling out a form, like login or application
  const css = `
    @media screen and (-webkit-min-device-pixel-ratio: 0) { /* stylelint-disable */
      select,
      textarea,
      input,
      input:focus,
      input:active {
        font-size: 16px !important;
      }
    }
  `;

  const styleElement = document.createElement('style');
  styleElement.type = 'text/css';
  styleElement.appendChild(document.createTextNode(css));
  document.head.appendChild(styleElement);
}
