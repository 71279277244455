import { routerReducer as reduxRouterReducer } from 'react-router-redux';
import { TRouteActions } from './actions';
import { defaultRouteState, RouterState } from './model';

function customRouterReducer(state: RouterState = defaultRouteState, action: TRouteActions): RouterState {
  switch (action.type) {
    case 'SetRouteName':
      return {
        ...state,
        routeName: action.payload,
      };
    case 'SetPageviewSent':
      return {
        ...state,
        isPageviewSent: action.payload,
      };
    default:
      return state;
  }
}

// tslint:disable:no-any
function routerReducer(state: RouterState = defaultRouteState, action: any): RouterState {
  return [
    customRouterReducer,
    reduxRouterReducer as any, //any нужен из-за некорректных тайпингов react-router-redux
  ].reduce((prevState, reducer) => reducer(prevState, action), state );
}
// tslint:enable:no-any

export default routerReducer;
