import { get } from 'lodash';

import { IOfferDetail } from '../../../api/models/offer_card';
import { IOfferIconField } from '../../../redux/offer/model';

export const infrastructureIcons = {
  ventilation: 'card/ic-vent',
  conditioning: 'card/ic-snow',
  heating: 'card/ic-radiator',
  extinguishingSystem: 'card/ic-fire',
};

const parseVentilation = (type?: string): string => {
  switch (type) {
    case 'forced':
      return 'Приточная вентиляция';
    case 'natural':
      return 'Естественная вентиляция';
    case 'no':
      return 'Нет';
    default:
      return '';
  }
};

const parseConditioning = (type?: string): string => {
  switch (type) {
    case 'central':
      return 'Центральное кондиционирование';
    case 'local':
      return 'Местное кондиционирование';
    case 'no':
      return 'Нет';
    default:
      return '';
  }
};

const parseHeating = (type?: string): string => {
  switch (type) {
    case 'autonomous':
      return 'Автономное отопление';
    case 'central':
      return 'Центральное отопление';
    case 'centralCoal':
      return 'Центральное угольное отопление';
    case 'centralGas':
      return 'Центральное газовое отопление';
    case 'fireplace':
      return 'Камин';
    case 'boiler':
      return 'Котел';
    case 'stove':
      return 'Печь';
    case 'other':
      return 'Другое';
    case 'no':
      return 'Нет';
    default:
      return '';
  }
};

const parseExtinguishingSystem = (type?: string): string => {
  switch (type) {
    case 'alarm':
      return 'Система пожарной сигнализации';
    case 'gas':
      return 'Газовая система пожаротушения';
    case 'hydrant':
      return 'Гидрантная система пожаротушения';
    case 'powder':
      return 'Порошковая система пожаротушения';
    case 'sprinkler':
      return 'Спринклерная система пожаротушения';
    case 'no':
      return 'Нет';
    default:
      return '';
  }
};

export const responseToBuildingInfrastructure = (offer: IOfferDetail): IOfferIconField[] => {
  const newState: IOfferIconField[] = [];
  const ventilation: string = parseVentilation(
    get(offer, 'building.ventilationType', ''),
  );
  const conditioning: string = parseConditioning(
    get(offer, 'building.conditioningType', ''),
  );
  const heating: string = parseHeating(
    get(offer, 'building.heatingType', ''),
  );
  const extinguishingSystem: string = parseExtinguishingSystem(
    get(offer, 'building.extinguishingSystemType', ''),
  );

  if (ventilation) {
    newState.push({
      name: ventilation,
      icon: infrastructureIcons['ventilation'],
    });
  }

  if (conditioning) {
    newState.push({
      name: conditioning,
      icon: infrastructureIcons['conditioning'],
    });
  }

  if (heating) {
    newState.push({
      name: heating,
      icon: infrastructureIcons['heating'],
    });
  }

  if (extinguishingSystem) {
    newState.push({
      name: extinguishingSystem,
      icon: infrastructureIcons['extinguishingSystem'],
    });
  }

  return newState;
};
