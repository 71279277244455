import { toPromise } from '../../utils/streams';
import { isTimeoutError, timeoutPromise } from '../../utils/timeout_promise';
import { TTypedThunkAction } from '../model';
import { ISpecialPromoInfo } from './model';

export type TSpecialPromosInfoActions
  = { type: 'SetSpecialPromosInfo', payload: ISpecialPromoInfo[] }
  | { type: 'SetCanPreviewSpecialPromos', payload: boolean }
  | { type: 'SetSpecialPromosInfoRegion', payload: number }
  | { type: 'SetSpecialPromosInfoDomain', payload: string }
;

const DEFAULT_REGION_ID = 1;  // Москва
const DEFAULT_DOMAIN = 'www'; // Москва

export function setSpecialPromosInfoRegion(regionId: number): TSpecialPromosInfoActions {
  return {
    type: 'SetSpecialPromosInfoRegion',
    payload: regionId,
  };
}

export function setSpecialPromosInfoDomain(domain: string): TSpecialPromosInfoActions {
  return {
    type: 'SetSpecialPromosInfoDomain',
    payload: domain,
  };
}

export function setSpecialPromosInfo(subdomain: string): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    const { logger } = getState();
    const setRegionAndDomain = (region: number, domain: string) => {
      dispatch<TSpecialPromosInfoActions>(setSpecialPromosInfoRegion(region));
      dispatch<TSpecialPromosInfoActions>(setSpecialPromosInfoDomain(domain));
    };

    const specialPromosInfoP = timeoutPromise(toPromise(api.getSpecialPromosInfo()), 4000)
      .then(({result}) => result.specialPromos)
      .catch((error) => {
        const meta = { message: error.message, stackTrace: error.stack };

        if (logger && isTimeoutError(error)) {
          logger.warning('Failed to load /v1/get-special-promo-thumbs/', meta);
        }
        return [];
      });

    const regionBySubdomainP = timeoutPromise(toPromise(api.getRegionBySubdomain(subdomain, logger)), 4000)
      .then(({result}) => result.data.id)
      .catch(() => DEFAULT_REGION_ID);

    return Promise.all([specialPromosInfoP, regionBySubdomainP])
      .then(([specialPromosInfo, regionIdByDomain]) => {
        dispatch<TSpecialPromosInfoActions>({ type: 'SetSpecialPromosInfo', payload: specialPromosInfo });

        if (regionIdByDomain === DEFAULT_REGION_ID) {
          setRegionAndDomain(DEFAULT_REGION_ID, DEFAULT_DOMAIN);
        } else {
          timeoutPromise(toPromise(api.getRegionMeta(regionIdByDomain, logger)), 4000)
            .then(({result}) => {
              const { parentId, id } = result.data.location;
              setRegionAndDomain(parentId || id, subdomain);
            })
            .catch(() => {
              setRegionAndDomain(DEFAULT_REGION_ID, DEFAULT_DOMAIN);
            });
        }
      });
  };
}

export function setCanPreviewSpecialPromos(canPreviewSpecialPromos: boolean): TSpecialPromosInfoActions {
  return {type: 'SetCanPreviewSpecialPromos', payload: canPreviewSpecialPromos};
}
