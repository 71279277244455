import { TTransitionMetricsActions } from './actions';
import { defaultTransitionMetricsState, TransitionMetricsState } from './model';

type Reducer = (state: TransitionMetricsState, action: TTransitionMetricsActions) => TransitionMetricsState;

const TransitionMetricsReducer: Reducer = (
  state = defaultTransitionMetricsState, action: TTransitionMetricsActions,
) => {
  switch (action.type) {
    case 'MarkTransition':
    case 'SetTransitioned':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default TransitionMetricsReducer;
