import { IOfferInfo } from '../redux/offer/model';

export function getSpecialty(offer: IOfferInfo) {
    if (offer.specialty && offer.specialty.specialties) {
      return offer.specialty.specialties.reduce((acc: string[], value) => {
        if (value.rusName && acc.length < 2) {
          acc.push(value.rusName.toLowerCase());
        }

        return acc;
      }, []);
    }

    return [];
  }
