import * as React from 'react';

import { IStyleConfig, mergeStyles } from '@cian/components';

const styles = require('./block_list.css');

interface IBlockListProps {
  listStyle?: IStyleConfig;
  listItemStyle?: IStyleConfig;
  data: IBlockRadioItemData[];
  onItemClick: (i: IBlockRadioItemData) => void;
  value: string | number;
}

interface IBlockRadioItemData {
  itemStyles?: IStyleConfig;
  name: string;
  value: string | number;
}

interface IBlockRadioItemProps {
  item: IBlockRadioItemData;
  onItemClick: (i: IBlockRadioItemData) => void;
  checked: boolean;
  dataMark?: string;
}

export const BlockRadioItem: React.SFC<IBlockRadioItemProps> =
  ({ item, onItemClick, checked, dataMark }: IBlockRadioItemProps) => {
    return (
      <li className={styles.item}>
        <label
          {...mergeStyles(
            styles.label,
            styles.itemButton,
            checked && styles['label-active'],
          )}
          onClick={() => onItemClick(item)}
          data-value={item.value}
          data-mark={`${dataMark ? dataMark + '|' : ''}BlockRadioItem`}
        >
          <div {...mergeStyles(
            styles.text,
          )}>
            {item.name}
          </div>
        </label>
      </li>
    );
  };

export const BlockRadioList: React.SFC<IBlockListProps> =
  ({ data, listStyle, onItemClick, value }: IBlockListProps) => {
    const listMergedStyles = mergeStyles(
      styles.list,
      listStyle,
    );

    return (
      <ul {...listMergedStyles}>
        {data.map((item: IBlockRadioItemData, index) =>
          <BlockRadioItem
            item={item}
            checked={item.value === value}
            key={index}
            onItemClick={onItemClick} />,
        )}
      </ul>
    );
  };
