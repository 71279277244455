import { get } from 'lodash';
import { parseNumberLike } from '../../../utils/helpers';

import {
  IOfferDetail,
  OfferDetailCategory,
} from '../../../api/models/offer_card';

import {
  IAdditionalInfo,
  ICommonInfo,
} from '../model';

import { parseDealType, parseLandStatus } from './base_info_parser';

export {
  responseToCommonInfo,
  responseToAdditionalInfo,
};

function getCategoryChecker(data: IOfferDetail) {
  return function <T>(value: T, categories: OfferDetailCategory[]): T | undefined {
    return data.category != null && categories.includes(data.category) ? value : undefined;
  };
}

function mapHouseType(offerData: IOfferDetail): string | undefined {
  if (offerData.building == null || offerData.building.materialType == null) {
    return undefined;
  }
  const map: { [key: string]: string } = {
    'brick': 'Кирпичный',
    'monolith': 'Монолитный',
    'panel': 'Панельный',
    'block': 'Блочный',
    'wood': 'Деревянный',
    'stalin': 'Сталинский',
    'boards': 'Щитовой',
    'monolithBrick': 'Монолитно-кирпичный',
    'old': 'старый фонд',
  };
  return map[offerData.building.materialType];
}

function mapIsNewBuilding(offerData: IOfferDetail): boolean | undefined {
  return offerData.category === 'newBuildingFlatSale';
}

function mapRoomType(data: IOfferDetail): string | undefined {
  const map: { [key: string]: string } = {
    'both': 'Оба варианта',
    'combined': 'Совмещенная',
    'separate': 'Изолированная',
  };
  return map[data.roomType as string];
}
function mapStatus(data: IOfferDetail): string | undefined {
  let parts: string[] = [];

  const addToParts = function(condition: boolean | undefined, item: string) {
    if (condition) {
      parts.push(parts.length ? item.toLowerCase() : item);
    }
  };

  addToParts(data.isApartments, 'Апартаменты');
  addToParts(data.isPenthouse, 'Пентхаус');

  if (!parts.length) {
    return undefined;
  }

  return parts.join(', ');
}

function responseToCommonInfo(data: IOfferDetail): ICommonInfo | null {
  if (data.offerType === 'commercial') {
    return {
      category: data.category,
    };
  }

  const includeForCategory = getCategoryChecker(data);

  const commonInfo: ICommonInfo = {
    category: data.category,
    area: includeForCategory(parseNumberLike(data.totalArea), [
      'flatRent',
      'roomRent',
      'bedRent',
      'houseRent',
      'townhouseRent',
      'cottageRent',
      'houseShareRent',
      'dailyFlatRent',
      'dailyRoomRent',
      'dailyBedRent',
      'dailyHouseRent',
      'flatSale',
      'newBuildingFlatSale',
      'roomSale',
      'flatShareSale',
      'houseSale',
      'townhouseSale',
      'cottageSale',
      'houseShareSale',
    ]),
    status: mapStatus(data),
    bedroomsCount: includeForCategory(parseNumberLike(data.bedroomsCount), [
      'houseRent',
      'townhouseRent',
      'cottageRent',
      'houseShareRent',
      'dailyHouseRent',
      'houseSale',
      'townhouseSale',
      'cottageSale',
      'houseShareSale',
    ]),
    bedsCount: includeForCategory(parseNumberLike(data.bedsCount), [
      'bedRent',
      'dailyFlatRent',
      'dailyRoomRent',
      'dailyBedRent',
      'dailyHouseRent',
    ]),
    floor: includeForCategory(parseNumberLike(data.floorNumber), [
      'flatRent',
      'roomRent',
      'bedRent',
      'dailyFlatRent',
      'dailyRoomRent',
      'dailyBedRent',
      'flatSale',
      'newBuildingFlatSale',
      'roomSale',
      'flatShareSale',
    ]),
    houseType: includeForCategory(mapHouseType(data), [
      'houseRent',
      'townhouseRent',
      'cottageRent',
      'houseShareRent',
      'dailyHouseRent',
      'houseSale',
      'townhouseSale',
      'cottageSale',
      'houseShareSale',
    ]),
    isNewBuilding: includeForCategory(mapIsNewBuilding(data), [
      'flatSale',
      'newBuildingFlatSale',
      'roomSale',
      'flatShareSale',
    ]),
    kitchenArea: includeForCategory(parseNumberLike(data.kitchenArea), [
      'flatRent',
      'roomRent',
      'bedRent',
      'houseRent',
      'townhouseRent',
      'cottageRent',
      'houseShareRent',
      'dailyFlatRent',
      'dailyRoomRent',
      'dailyBedRent',
      'flatSale',
      'newBuildingFlatSale',
      'roomSale',
      'flatShareSale',
      'houseSale',
      'townhouseSale',
      'cottageSale',
      'houseShareSale',
    ]),
    livingArea: includeForCategory(parseNumberLike(data.livingArea), [
      'flatRent',
      'dailyFlatRent',
      'flatSale',
      'newBuildingFlatSale',
      'flatShareSale',
    ]),
    partForRent: includeForCategory(data.shareAmount, [
      'houseRent',
      'townhouseRent',
      'cottageRent',
      'houseShareRent',
    ]),
    partSize: includeForCategory(data.shareAmount, [
      'flatShareSale',
      'houseShareSale',
      'townhouseSale',
      'cottageSale',
    ]),
    roomArea: includeForCategory(parseNumberLike(data.roomArea), [
      'roomRent',
      'bedRent',
      'dailyRoomRent',
      'dailyBedRent',
      'roomSale',
    ]),
    roomsArea: includeForCategory(data.allRoomsArea, [
      'flatRent',
      'roomRent',
      'dailyFlatRent',
      'dailyRoomRent',
      'dailyBedRent',
      'flatSale',
      'newBuildingFlatSale',
      'roomSale',
      'flatShareSale',
    ]),
    rooms: includeForCategory(parseNumberLike(data.roomsCount), [
      'flatRent',
      'dailyFlatRent',
      'flatSale',
      'newBuildingFlatSale',
      'flatShareSale',
    ]),
    roomsForRent: includeForCategory(parseNumberLike(data.roomsForSaleCount), [
      'roomRent',
      'bedRent',
      'dailyRoomRent',
      'dailyBedRent',
    ]),
    roomsForSale: includeForCategory(parseNumberLike(data.roomsForSaleCount), [
      'roomSale',
    ]),
    roomType: includeForCategory(mapRoomType(data), [
      'roomRent',
      'bedRent',
      'roomSale',
    ]),
    steadAreaUnitType: includeForCategory(get(data, 'land.areaUnitType'), [
      'houseRent',
      'townhouseRent',
      'cottageRent',
      'houseShareRent',
      'dailyHouseRent',
      'landSale',
      'houseSale',
      'townhouseSale',
      'cottageSale',
      'houseShareSale',
    ]),
    steadArea: includeForCategory(parseNumberLike(get(data, 'land.area')), [
      'houseRent',
      'townhouseRent',
      'cottageRent',
      'houseShareRent',
      'dailyHouseRent',
      'landSale',
      'houseSale',
      'townhouseSale',
      'cottageSale',
      'houseShareSale',
    ]),
    steadStatus: includeForCategory(parseLandStatus(data), [
      'landSale',
      'houseSale',
      'houseShareSale',
      'cottageSale',
      'townhouseSale',
    ]),
    totalFloors: includeForCategory(parseNumberLike(get(data, 'building.floorsCount')), [
      'flatRent',
      'roomRent',
      'bedRent',
      'houseRent',
      'townhouseRent',
      'cottageRent',
      'houseShareRent',
      'dailyFlatRent',
      'dailyRoomRent',
      'dailyBedRent',
      'dailyHouseRent',
      'flatSale',
      'newBuildingFlatSale',
      'roomSale',
      'flatShareSale',
      'houseSale',
      'townhouseSale',
      'cottageSale',
      'houseShareSale',
    ]),
  };

  return commonInfo;
}

function mapDecoration(data: IOfferDetail): string | undefined {
  if (!mapIsNewBuilding(data)) {
    return undefined;
  }

  const map: { [key: string]: string } = {
    'without': 'Без отделки',
    'rough': 'Черновая',
    'fine': 'Чистовая',
  };

  return map[data.decoration as string];
}

function mapHeating(data: IOfferDetail): string | undefined {
  const heating = get(data, 'building.heatingType');
  const map: { [key: string]: string } = {
    'no': 'Нет',
    'central': 'Центральное',
    'autonomous': 'Автономное',
    'boiler': 'Котел',
    'stove': 'Печь',
    'fireplace': 'Камин',
    'other': 'Другое',
    'centralGas': 'Центральное газовое',
    'centralCoal': 'Центральное угольное',
  };
  return map[heating];
}

function mapReparirType(data: IOfferDetail): string | undefined {
  const map: { [key: string]: string } = {
    'cosmetic': 'Косметический',
    'euro': 'Евроремонт',
    'design': 'Дизайнерский',
    'no': 'Без ремонта',
  };
  return map[data.repairType as string];
}

function mapWindowView(data: IOfferDetail): string | undefined {
  const map: { [key: string]: string } = {
    'street': 'На улицу',
    'yard': 'Во двор',
    'yardAndStreet': 'Во двор, на улицу',
  };
  return map[data.windowsViewType as string];
}

function mapBathType(data: IOfferDetail): string | undefined {
  let parts: string[] = [];

  const addToParts = function(condition: boolean | undefined, item: string) {
    if (condition) {
      parts.push(parts.length ? item.toLowerCase() : item);
    }
  };

  addToParts(data.hasBathtub, 'Ванна');
  addToParts(data.hasShower, 'Душевая кабина');

  if (!parts.length) {
    return undefined;
  }

  return parts.join(', ');
}

function mapRestroomLocation(data: IOfferDetail): string | undefined {
  switch (data.wcLocationType) {
    case 'indoors':
      return 'В доме';
    case 'outdoors':
      return 'На улице';
    default:
      return undefined;
  }
}

function responseToAdditionalInfo(data: IOfferDetail): IAdditionalInfo | null {
  const { building } = data;

  if (data.offerType === 'commercial') {
    return null;
  }

  const includeForCategory = getCategoryChecker(data);

  const ceilingHeight =
    building &&
    building.ceilingHeight &&
    parseNumberLike(building.ceilingHeight) ||
    undefined;

  const additionalInfo: IAdditionalInfo = {
    dealType: parseDealType(data.dealType),
    ceilingHeight,
    totalRoomsCount: includeForCategory(parseNumberLike(data.roomsCount), [
      'roomRent',
      'bedRent',
      'dailyRoomRent',
      'dailyBedRent',
      'roomSale',
    ]),
    balcony: includeForCategory(data.balconiesCount, [
      'flatRent',
      'roomRent',
      'bedRent',
      'dailyFlatRent',
      'dailyRoomRent',
      'dailyBedRent',
      'flatSale',
      'newBuildingFlatSale',
      'roomSale',
      'flatShareSale',
    ]),
    loggia: includeForCategory(data.loggiasCount, [
      'flatRent',
      'roomRent',
      'bedRent',
      'dailyFlatRent',
      'dailyRoomRent',
      'dailyBedRent',
      'flatSale',
      'newBuildingFlatSale',
      'roomSale',
      'flatShareSale',
    ]),
    bathroom: includeForCategory(mapBathType(data), [
      'flatRent',
      'roomRent',
      'bedRent',
      'dailyFlatRent',
      'dailyRoomRent',
      'dailyBedRent',
      'flatSale',
      'newBuildingFlatSale',
      'roomSale',
      'flatShareSale',
    ]),
    combinedRestroom: includeForCategory(data.combinedWcsCount, [
      'flatRent',
      'roomRent',
      'bedRent',
      'dailyFlatRent',
      'dailyRoomRent',
      'dailyBedRent',
      'flatSale',
      'newBuildingFlatSale',
      'roomSale',
      'flatShareSale',
    ]),
    separatedRestroom: includeForCategory(data.separateWcsCount, [
      'flatRent',
      'roomRent',
      'bedRent',
      'dailyFlatRent',
      'dailyRoomRent',
      'dailyBedRent',
      'flatSale',
      'newBuildingFlatSale',
      'roomSale',
      'flatShareSale',
    ]),
    restroomLocation: includeForCategory(mapRestroomLocation(data), [
      'houseRent',
      'townhouseRent',
      'cottageRent',
      'houseShareRent',
      'dailyHouseRent',
      'houseSale',
      'townhouseSale',
      'cottageSale',
      'houseShareSale',
    ]),
    decoration: includeForCategory(mapDecoration(data), [
      'newBuildingFlatSale',
    ]),
    windowsView: includeForCategory(mapWindowView(data), [
      'flatRent', // длительная аренда квартиры
      'dailyFlatRent', // посуточная аренда квартиры

      'flatSale', // продажа квартиры
      'newBuildingFlatSale', // продажа квартиры в новостройке

      'roomSale', // продажа комнаты
      'roomRent', // длительная аренда комнаты
      'dailyRoomRent', // посуточная аренда комнаты

      'bedRent', // длительная аренда койко-места
      'dailyBedRent', // посуточная аренда койко-места

      'flatShareSale', // продажа доли
    ]),
    heating: includeForCategory(mapHeating(data), [
      'houseRent',
      'townhouseRent',
      'cottageRent',
      'houseShareRent',
      'dailyHouseRent',
      'houseSale',
      'townhouseSale',
      'cottageSale',
      'houseShareSale',
    ]),
    phone: includeForCategory(data.hasPhone, [
      'newBuildingFlatSale',
    ]),
    repair: includeForCategory(mapReparirType(data), [
      'flatRent',
      'roomRent',
      'bedRent',
      'houseRent',
      'townhouseRent',
      'cottageRent',
      'houseShareRent',
      'dailyFlatRent',
      'dailyRoomRent',
      'dailyBedRent',
      'dailyHouseRent',
      'flatSale',
      'newBuildingFlatSale',
      'roomSale',
      'flatShareSale',
    ]),
  };

  return additionalInfo;
}
