import { IUpcomingSale } from './model';
import { EUpcomingSale, TUpcomingSaleActions } from './actions';

export const defaultState: IUpcomingSale = {
  newbuilding: null,
  showPopup: false,
};

export function upcomingSale(
  state: IUpcomingSale = defaultState,
  action: TUpcomingSaleActions,
) {

  switch (action.type) {
    case EUpcomingSale.OpenUpcomingSalePopup:
      return {
        ...state,
        newbuilding: action.payload,
        showPopup: true,
      };
    case EUpcomingSale.CloseUpcomingSalePopup:
      return {
        ...state,
        newbuilding: null,
        showPopup: false,
      };
    default:
      return state;
  }
}
