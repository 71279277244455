import { PageAnalyticsGTMEvent } from './analytics';

export function normalizeAnalytcsEventData(eventData: PageAnalyticsGTMEvent) {
  const normalizedEventData = Object.assign({}, eventData);

  if (normalizedEventData.page && typeof normalizedEventData.page.region === 'number') {
    normalizedEventData.page.region = String(normalizedEventData.page.region);
  }

  return normalizedEventData;
}
