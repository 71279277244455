/**
* @todo Удалить файл с RS
* @description Данный функционал появился в задаче CD-143100, будет удалён в задаче CD-143261
*/
let isCommercialNewVatTextEnabled: null | boolean = null;

export function setIsCommercialNewVatTextEnabled(enabled: boolean): void {
    isCommercialNewVatTextEnabled = enabled;
}

export function getIsCommercialNewVatTextEnabled(): boolean {
    return !!isCommercialNewVatTextEnabled;
}