import { mergeStyles } from '@cian/utils';

import * as React from 'react';

import { IconArrow } from './IconArrow';

const styles = require('./CollapsedButton.css');

interface ICollapsedButton {
  className?: string;
  /**
   * @default `Показать все`
   */
  collapsedButtonText?: string;
  isCollapsed: boolean;
  /**
   * @default `Свернуть`
   */
  notCollapsedButtonText?: string;
  /**
   * @default `true`
   */
  showButton?: boolean;

  onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

export const CollapsedButton: React.FC<ICollapsedButton> = (props) => {
  const {
    showButton = true,
    className,
    collapsedButtonText = 'Показать все',
    isCollapsed,
    notCollapsedButtonText = 'Свернуть',
    onClick,
  } = props;

  if (!showButton) {
    return null;
  }

  const title = isCollapsed ? collapsedButtonText : notCollapsedButtonText;

  return (
    <div
      {...mergeStyles(styles['root'], className)}
      onClick={onClick}
    >
      {title}
      <IconArrow isCollapsed={isCollapsed} className={styles['icon']} />
    </div>
  );

};

CollapsedButton.displayName = 'CollapsedButton';
