/* eslint-disable max-lines */

import { IPriceHistoryEvent } from '@cian/price-history-widget';

import { IBreadcrumb, IComplaintsTreeItem, ISimilarOffersResponseItem, TModelVersion } from '../../api/api';
import { ISimilarGKOffer } from '../../api/models/gk_card';
import {
  BuildingPresenterClassType,
  BuildingPresenterConditionRatingType,
  BuildingPresenterHouseLineType,
  BuildingPresenterShoppingCenterScaleType,
  BuildingPresenterStatusType,
  BuildingPresenterType,
  BuildingPresenterWorkingDaysType,
  ECoworkingOfferType,
  IAgentBonus,
  IBargainTermsPresenter,
  IBuildingPresenter,
  IBusinessShoppingCenter,
  ICommercialSpecialty,
  ICoworking,
  IDemandJsonQuery,
  IDistrictBTIData,
  IFlags,
  IGeo,
  ILand as ILandExtended,
  IOfferBooking,
  IOfferNewbuilding,
  IOpeningHours,
  IPricePresenter,
  IRosreestrCheck,
  ISimilarOffers,
  IUserPresenter,
  IVideo,
  IWorkTimeInfo,
  LandAreaUnitType,
  LandType,
  OfferDetailCategory,
  OfferDetailConditionType,
  OfferDetailDealType,
  OfferDetailFlatType,
  OfferDetailOfferType,
  OfferDetailStatus,
  TAgentAccountType,
  TBargainTermsVatTypes,
  TVatPrices,
  UserPresenterCianProfileStatus,
} from '../../api/models/offer_card';
import { ICommercialOwnershipSchema } from '../../types/ICommercialOwnershipSchema';
import { YMapsCoords } from '../../utils/ymaps';
import { IUnderground } from '../common/types';

export enum ObjectType {
  Bed,
  Building,
  Business,
  CarService,
  CommercialLand,
  Cottage,
  Flat,
  House,
  Room,
  DomesticService,
  FreeAppointmentObject,
  Garage,
  Industry,
  Land,
  NewBuilding,
  Office,
  PublicCatering,
  ShoppingArea,
  Townhouse,
  Warehouse,
  OpenPlan,
  Studio,
}

export enum RentTime {
  FewMonths,
  Long,
  Short,
}

export enum ResidentsType {
  Male,
  Female,
  Family,
  Any,
}

export enum Currency {
  RUB,
  USD,
  EUR,
}

export enum RentType {
  Rent,
  SubRent,
}

export enum PaymentPeriod {
  Month,
  Year,
}

export enum SaleType {
  // Свободная продажа
  Free,
  // Альтернатива
  Alternative,
  // 214-ФЗ
  Fz214,
  // Договор уступки права требования
  DUPT,
  // Договор ЖСК
  DJSK,
  // Предварительный договор купли-продажи
  PDKP,
  // Договор инвестирования
  Investment,
}

export enum DealType {
  Rent,
  Sale,
}

export enum ApartmentType {
  Apartment,
  Penthouse,
}

export enum RoomType {
  Adjacent,
  Isolated,
  Both,
}

export enum HouseType {
  //Блочный
  Block,
  // Кирпичный
  Brick,
  // Монолитный
  Monolithic,
  // Монолитно-Кирпичный
  MonolithBrick,
  // Деревянный
  Wood,
  //Панельный
  Panel,
  // Щитовой
  Shield,
  //Сталинский
  Stalin,
  //Старый фонд
  Old,
}

export enum SteadStatus {
  // Индивидуальное жилищное хозяйство
  Individual,
  // Садоводство
  Gardening,
  // Фермерское хозяйство
  Farming,
  // Дачное некоммерческое партнерство
  DNP,
  //Садовое некоммерческое товарищество
  SNT,
  //Дачное некоммерческое партнерство поселений
  DNPP,
  // Инвестпроект
  InvestmentProject,
  // Земля промназначения
  Industrial,
  //Участок сельскохозяйственного назначения
  Agricultural,
  //Участок промышленности, транспорта, связи и иного не сельхоз. назначения
  IndustryTransportCommunications,
  //Особо охраняемая категория
  Protected,
  //Участок лесного фонда
  Forest,
  //Участок водного фонда
  Water,
  //Участок запаса
  Reserve,
  //Участок поселений
  Settlements,
}

export enum Layout {
  //Открытая
  Open,
  //Кабинетная
  Office,
  //Смешанная
  Mixed,
}

export enum Access {
  Free,
  ByPermition,
}

export enum State {
  // требуется косметический ремонт
  CosmeticRepairsRequired,
  //Дизайнернский ремонт
  Design,
  //Под чистовую отделку
  Finishing,
  // требуется капитальный ремонт
  MajorRepairsRequired,
  // офисная отделка
  Office,
  // Типовой ремонт
  Typical,
}

export enum RepairType {
  //Косметический
  Cosmetic,
  //Дизайнерский
  Design,
  //Евро
  Euro,
  //Нет
  No,
}

export enum CommercialRoomType {
  ShoppingMall,
  StreetRetail,
}

export enum EnterType {
  // Отдельный со двора
  SeparateFromYard,
  // Отдельный с улицы
  SeparateFromStreet,
  // Общий со двора
  CommonFromYard,
  // Общий с улицы
  CommonFromStreet,
}

export enum GateType {
  // на пандусе
  Rampant,
  // докового типа
  Dock,
  // на нулевой отметке
  ZeroMark,
}

export enum CarStoreType {
  //Машиноместо
  ParkingPlace,
  //Гараж
  Garage,
  //Бокс
  Box,
}

export enum GarageType {
  // встроенный
  BuiltIn,
  // капитальный
  Capital,
  // ракушка
  Shell,
  // самострой
  Handmade,
}

export enum BoxType {
  Brick,
  Metal,
}

export enum PermittedUsageType {
  // сельскохозяйственное использование
  Farming,
  // ИЖС
  IJS,
  // МЖС
  MJS,
  // высотная застройка
  HighriseBuilding,
  // общественное использование объктов капитального строительства
  PublicUsage,
  // деловое управление
  BusinessManagment,
  // торговые центры
  ShoppingMalls,
  // гостинечное обслуживание
  Hotels,
  // обслуживание автотранспорта
  CarService,
  // отдых
  Recreation,
  // промышленность
  Industrial,
  // склады
  Warehouses,
  // общее пользование территории
  CommonUsage,
}

export enum ElevatorType {
  // грузовой
  Cargo,
  // тельфер
  Telpher,
  // пассажирский
  Passenger,
}

export enum CraneType {
  // Мостовой
  Overhead,
  // Кран-балка
  Beam,
  // ж/д кран
  Railway,
  // козловой кран
  Gantry,
}

export enum Placement {
  // на участке
  Location,
  // по границе участка
  Border,
  // нет
  No,
}

export enum Pressure {
  // высокое
  High,
  // среднее
  Medium,
  // низкое
  Low,
}

export enum UtilityType {
  // центральная
  Central,
  // автономная
  StandAlone,
}

export enum Driveways {
  // асфальтированная дорога
  Asphalt,
  // грунтовая дорога
  Ground,
  // нет
  No,
}

export enum ParkingType {
  // наземная
  Simple,
  // многоуровневая
  Multilevel,
  // подземная
  Underground,
  // на крыше
  Roof,
  // открытая
  Open,
}

export enum ParkingPlacement {
  // на территории
  InTerritory,
  // за территорией
  Outside,
}

export enum ParkingPurpose {
  // грузовой транспорт
  FreightTansport,
  // легковой транспорт
  PassengerTransport,
}

export enum PossiblePurpose {
  // свободное
  Free,
  // имущественный комплекс
  PropertyComplex,
  // офисное
  Office,
  // офисно-складское
  OfficeWarehouse,
  // бизнес-центр
  BusinessCenter,
  // товарно-развлекательный центр
  CommodityEntertainmentCenter,
  // административное
  Administration,
  // производственное,
  Production,
  // модульное
  Module,
}

export enum EBrandingLevel {
  /** Серебро **/
  FirstLevel = 'firstLevel',
  /** Золото и платина **/
  SecondLevel = 'secondLevel',
  /** Платина **/
  ThirdLevel = 'thirdLevel',
}

export type Icon = 'builder' | 'auction' | 'top-3' | 'premium' | 'good' | 'standard';

export type OfferType = 'flat' | 'suburban' | 'commercial' | 'newobject';

export type BuildingTypeParsed =
  | 'Бизнес-центр'
  | 'Торгово-развлекательный центр'
  | 'Старый фонд'
  | 'Офисное здание'
  | 'Бизнес-парк'
  | 'Офисно-производственный комплекс'
  | 'Офисно-складской комплекс'
  | 'Складской комплекс'
  | 'Производственно-складской комплекс'
  | 'Другое'
  | 'Логистический центр'
  | 'Склад'
  | 'Административное здание'
  | 'Индустриальный парк'
  | 'Логистический комплекс'
  | 'Торгово-деловой комплекс'
  | 'Многофункциональный комплекс'
  | 'Офисно-жилой комплекс'
  | 'Торгово-офисный комплекс'
  | 'Офисно-гостиничный комплекс'
  | 'Деловой центр'
  | 'Особняк'
  | 'Технопарк'
  | 'Бизнес-квартал'
  | 'Отдельно стоящее здание'
  | 'Жилой дом'
  | 'Жилой комплекс'
  | 'Торговый центр'
  | 'Специализированный торговый центр'
  | 'Торгово-общественный центр'
  | 'Аутлет'
  | 'Производственный комплекс'
  | 'Промплощадка'
  | 'Производственный цех'
  | 'Объект свободного назначения'
  | 'Имущественный комплекс'
  | 'Производственное здание'
  | 'Модульное здание'
  | 'Свободное'
  | 'Офисно-складское'
  | 'Нежилой фонд'
  | 'Жилой фонд'
  | 'Коворкинг';

export type SpecialtyTypeParsed =
  | 'Магазин'
  | 'Кафе/ресторан'
  | 'Банк'
  | 'Салон красоты'
  | 'Аптека'
  | 'Бытовые услуги'
  | 'Автомойка'
  | 'Ателье одежды'
  | 'Бар'
  | 'Выпечка'
  | 'Выставка'
  | 'Гостиница'
  | 'Кальянная'
  | 'Медицинский центр'
  | 'Клуб'
  | 'Кондитерская'
  | 'Ломбард'
  | 'Мастерская'
  | 'Парикмахерская'
  | 'Пекарня'
  | 'продукты'
  | 'Ресторан'
  | 'Сервис'
  | 'Спортзал'
  | 'Фитнес'
  | 'Фотостудия'
  | 'Фрукты'
  | 'Хостел'
  | 'Цветы'
  | 'Цех'
  | 'Шаурма'
  | 'Шиномонтаж'
  | 'Школа'
  | 'Коммерцию'
  | 'Стоматология'
  | 'Зал'
  | 'Сауна'
  | 'Офис'
  | 'Общепит'
  | 'Шоурум'
  | 'Автосервис'
  | 'Другое';

export type AreaUnitType = 'Га' | 'сот.' | 'м²';

// Провайдер услуги подмены номера
export type TCalltrackingProvider = 'beeline' | 'mtt' | 'mts' | null;

export type THomeownerProof =
  /** Прошёл идентификацию по паспорту */
  | 'passport'
  /** Прошёл проверку в Росреестре */
  | 'rosreestr'
  /** Зарегистрирован в ФНС как самозанятый */
  | 'selfEmployed';

export enum OfferStatus {
  Blocked,
  Deactivated,
  Deleted,
  Draft,
  Moderate,
  Published,
  Refused,
  RemovedByModerator,
  Sold,
}

export enum HeatingType {
  //Автономное
  Autonomous,
  //Бойлер
  Boiler,
  //Центральное
  Central,
  //Печь
  Stove,
  //Камин
  Fireplace,
  //Нет
  No,
  //Другое
  Other,
}

export enum RestroomLocationType {
  //В помещении
  Indoors,
  //На улице
  Outdoors,
}

export enum BathType {
  //Ванна
  Bathtub,
  //Душевая кабина
  Shower,
}

export enum WindowViewType {
  //На улицу
  Street,
  Yard,
  //Во двор, на улицу
  YardAndStreet,
}

export enum FurnitureType {
  //Нет
  No,
  //Опционально
  Optional,
  //Есть
  Yes,
}

export enum ClassType {
  A,
  APlus,
  B,
  BMinus,
  BPlus,
  C,
  CPlus,
  D,
}

export enum LeaseType {
  Direct,
  JointVenture,
  Sublease,
}

export enum ContractType {
  LeaseAssignment,
  Sale,
}

export enum EstateType {
  Rent,
  Owned,
}

export enum Pessimisation {
  Light,
  Strong,
  Info,
}

export interface IContact {
  countryCode?: string;
  number?: string;
}

export interface IAgentInfo {
  // Realty id агента
  id?: number;
  // Название агентства
  agencyName?: string;
  agentAccountType?: TAgentAccountType;
  agentReviews?: IAgentReviewsSchema | null;
  // Имя агента
  agentName?: string;
  //  Id-агента
  agentId?: number;
  //  путь к файлу аватара агента
  avatar?: string;
  // Опыт работы
  experience?: string;
  //  Имя агента
  name?: string;
  //  показывать метку "Партнер ЦИАН"
  isCianPartner?: boolean;
  //  показывать метку "Работаем честно"
  isHonest?: boolean;
  //  показывать метку "Pro"
  isPro?: boolean;
  // контакты
  contacts: IContact[];
  //закрытый профиль
  isHidden?: boolean;
  // Является ли сабагентом
  isSubAgent?: boolean;
  status?: UserPresenterCianProfileStatus;
  // Тип пользователя
  userType?: string;
}

export interface IAgentReviewsSchema {
  entityRate: number | null;
  totalCount: number;
}

export interface IRange {
  min: number;
  max: number;
}

export interface IElevator {
  type: string;
  capacity?: number;
  count?: number;
}

export interface ICrane {
  type: string;
  capacity?: number;
  count?: number;
}

export interface ILift {
  type: string;
  capacity?: number;
  count?: number;
}

export interface IParking {
  //тип транспорта
  purpose?: string;
  // кол-во мест
  capacity?: number;
  //Стоимость въезда
  priceEntry?: number;
  currency?: Currency;
  //Стоимость за месяц за место
  priceMonthly?: number;
  placement?: string;
  // тип парковки
  type?: string;
  // бесплатная/платная парковка
  isFree?: boolean;
}

export interface IUtility {
  // размещение
  placement: Placement;
  // емкость
  capacity?: number;
  // Можно подключить
  possibleToConnect?: boolean;
  type?: string;
  pressure?: string;
}

export interface INewBuilding {
  // отделка
  decoration: boolean;
  // срок сдачи
  handOverDate: string;
  // акция от застройщика
  promo: boolean;
}

export interface IChangableType {
  type?: string;
  possibleChange?: boolean;
}

export interface IPricePerUnit {
  price: number;
  unitOfTime?: 'мес' | 'год';
}

export interface ICompletion {
  quarter?: number;
  year?: number;
  isComplete?: boolean;
}

export interface IUtilityPaymentsCurrencyPrice {
  rur?: number;
  usd?: number;
  eur?: number;
}

export interface IBaseInfo {
  agentBonus?: IAgentBonus;
  // тип объекта
  type?: ObjectType;
  // тип здания
  buildingType?: BuildingTypeParsed;
  // тип сделки
  dealType?: DealType;
  // цена
  price?: number | IRange;
  // цена в рублях
  priceRub?: number;
  // валюта (в таблице нет этого поля в явном виде)
  currency?: Currency;
  // иконки (в старой версии сайта передаются в виде html)
  icons?: Icon[];
  // в аукционе
  isAuction: boolean;
  // топ 3
  isTop3: boolean;
  // значок профессионала
  isPro: boolean;
  // стандарт
  isStandard: boolean;
  // премиум
  isPremium: boolean;
  // избранное
  favorites?: number;
  // площадь
  area?: number | IRange;
  // единица измерения площади
  areaUnitType?: AreaUnitType;
  // кол-во комнат
  rooms?: number;
  // срок аренды
  rentTime?: RentTime;
  // комиссия
  fee?: number;
  // комиссия агента
  agentFee?: number;
  // залог
  deposit?: number;
  // предоплата
  prepayment?: number;
  // возможен торг
  bargainAllowed?: boolean;
  // вкл. коммунальные платежи
  utilityPayments?: boolean;
  // цена за коммунальные услуги
  utilityPaymentsPrice?: number;
  // цена за коммунальные услуги в разной валюте - rur, usd, eur
  utilityPaymentsCurrencyPrice?: IUtilityPaymentsCurrencyPrice;
  // состав съёмщиков
  residentsType?: ResidentsType;
  // возможна ипотека
  mortgageAllowed?: boolean;
  // тип продажи
  saleType?: SaleType;
  // Если новостройка
  newBuilding?: INewBuilding;
  // размер (может принимать значения "25%", "0.25", "1/4")
  partSize?: string;
  // этаж
  floor?: number;
  // Этаж с
  floorFrom?: number;
  // Этаж по
  floorTo?: number;
  // всего этажей (в доме)
  totalFloors?: number;
  // апартаменты/пентхаус
  apartmentType?: ApartmentType;
  // кол-во спальных мест
  bedsCount?: number;
  // тип аренды
  leaseType?: LeaseType;
  // включая НДС
  vatIncluded?: boolean;
  // НДС включен/выключен, УСН
  vatType?: TBargainTermsVatTypes;
  // Цена НДС
  vatPrices?: TVatPrices;
  // эксплуотационные платежи
  exploitationPayments?: boolean;
  // цена за единицу площади в год
  pricePerUnit?: IPricePerUnit;
  // условия тогра
  bargainConditions?: string;
  // цена с учетом тогра
  bargainPrice?: number;
  // минимальный срок аренды
  minLeaseTerm?: number;
  // арендные каникулы
  hasGracePeriod?: boolean;
  // обеспечитальный платеж
  securityDeposit?: number;
  // обеспечитальный платеж
  securityDepositPrices?: IPricePresenter;
  // тип договора
  contractType?: ContractType;
  // недвижимость в аренде/собстввенности
  estateType?: EstateType;
  // ежемесячная прибыль
  monthlyIncome?: number;
  // валюта ежемесячной прибыли
  monthlyIncomeCurrency?: Currency;
  // помещение занято
  isOccupied?: boolean;
  // прямая аренда/субаренда
  rentType?: RentType;
  // текст промоакции
  promo?: string;
  // статистика (просмотры, время публикации)
  stats?: IStats;
  // информация о годе сдачи
  completion?: ICompletion;
  // комнат в продажу
  roomsForSale?: number;
  // кол-во спален
  bedroomsCount?: number;
  // часть дома/квартиры
  shareAmount?: string;
  // продажа/аренда части
  canParts?: boolean;
  // сфера деятельности бизнеса
  specialty?: SpecialtyTypeParsed[];
  // статус участка
  landStatus?: string;
  // категория земли
  landCategory?: string;
  // отделка
  decoration?: string;
  // отделка, когда Черновая = без отделки, CD-57708
  decorationStrict?: string;
  // стоимость в рублях
  priceRubles?: number | IRange;
  // стоимость в рублях в месяц
  priceRublesPerMonth?: number;
  /// лейбл "без-комиссии" - не нагенерено
  withoutCommissionLabel?: boolean;
  // лейбл "собственник"
  fromHomeOwnerLabel?: boolean;
  // категория объявления
  category?: OfferDetailCategory;
  // минимальная сумма налога в месяц
  minVatPriceTotalPerMonthRur?: number;
  // общая сумма налога в месяц
  vatPriceTotalPerMonthRur?: number;
  // минимальная сумма налога
  minVatPriceTotalRur?: number;
  // общая сумма налога
  vatPriceTotalRur?: number;
  // Состояние проверки собственника в Росреестре
  rosreestrCheck?: IRosreestrCheck | null;
  // Тип коворкинга
  coworkingOfferType?: ECoworkingOfferType | null;
  // Количество рабочих мест
  workplaceCount?: number | null;
  // Цена за рабочее место
  priceForWorkplace?: number | null;
  // Объект коворкинга
  coworking?: ICoworking;
}

export interface ICommonInfo {
  // категория объявления
  category?: OfferDetailCategory;
  // Статус(апартаменты/пентхаус)
  status?: string;
  // Общая площадь
  area?: number | IRange;
  // кол-во комнат
  rooms?: number;
  // этаж
  floor?: number;
  // всего этажей (в доме)
  totalFloors?: number;
  // площадь комнаты
  roomArea?: number;
  // площадь комнат
  roomsArea?: string;
  // жилая площадь
  livingArea?: number;
  // площадь кухни
  kitchenArea?: number;

  // тип комнаты
  roomType?: string;
  // комнат в аренду
  roomsForRent?: number;
  //комнат в продажу
  roomsForSale?: number;
  // кол-во спальных мест
  bedsCount?: number;
  // тип дома
  houseType?: string;
  // площадь участка
  steadArea?: number;
  // Единица измерения площади участка
  steadAreaUnitType?: LandAreaUnitType;
  // кол-во спален
  bedroomsCount?: number;
  // часть в аренду (может принимать значения "25%", "0.25", "1/4")
  partForRent?: string;
  // тип жилья (вторичка/новостройка)
  isNewBuilding?: boolean;
  // размер (может принимать значения "25%", "0.25", "1/4")
  partSize?: string;
  // статус участка
  steadStatus?: string;
}

export interface IAdditionalInfo {
  dealType?: DealType;
  // высота потолков
  ceilingHeight?: number;
  // всего комнат в квартире
  totalRoomsCount?: number;
  // раздельных санузлов
  separatedRestroom?: number;
  // совмещеннчх санузлов
  combinedRestroom?: number;
  //Расположение санузла
  restroomLocation?: string;
  // ванная комната
  bathroom?: string;
  // ремонт
  repair?: string;
  // лоджия
  loggia?: number;
  // балкон
  balcony?: number;
  // вид из окон
  windowsView?: string;
  // отопление
  heating?: string;
  // телефон
  phone?: boolean;
  // отделка
  decoration?: string;
}

export interface ICommercialInfo {
  //Общая площадь
  area?: number;
  //Этаж
  floor?: number;
  totalFloors?: number;
  // класс объекта
  class?: string;
  // валюта (в таблице нет этого поля в явном виде)
  currency?: Currency;
  // период оплаты (в год/в месяц)
  paymentPeriod?: PaymentPeriod;
  // прямая аренда/субаренда
  rentType?: RentType;
  // помещение свободно/занято
  isFree?: boolean;
  // возможное назначение
  possiblePurpose?: string[];
  // категория земли
  landCategory?: IChangableType;
  // высота потолков
  ceilingHeight?: number;
  // тип помещения
  commercialRoomType?: string;
  // вход
  enter?: string;
  // сетка колонн (формат "X x Y": "3 x 9")
  columnGrid?: string;
  //Тип "авто-места"
  carStoreType?: string;
  //Статус "авто-места"
  carStoreStatus?: string;
  // тип гаража
  garageType?: string;
  // тип бокса
  boxType?: string;
  // вид разрешенного использования
  permittedUsageType?: IChangableType;
  // инвестпроект (это отдельное поле, не связанное с инвестпроектом из SteadStatus)
  investmentProject?: boolean;
  // обременение
  encumbrance?: boolean;
  // площадь участка
  steadArea?: number;
  // тип объекта
  objectType?: ObjectType;
  //тип недвижимости
  propertyType?: string;
  // состояние
  state?: string;
}

export interface ICommercialInfoLayout {
  // планировка
  layout?: string;
  // состояние
  state?: string;
  // мебель
  furniture?: boolean;
  // оборудование
  equipment?: boolean;
  // доступ
  access?: string;
  // вход
  enter?: string;
  // ворота
  gate?: string;
}

export interface ICommercialAdditionalInfo {
  // лифт
  elevators?: ILift[];
  // кран
  cranes?: ILift[];
  // электричество
  electricity?: IUtility;
  // газ
  gas?: IUtility;
  // канализация
  sewerage?: IUtility;
  // водоснабжение
  waterSupply?: IUtility;
  // подъездные пути
  driveways?: string;
}

export interface IJK {
  name?: string;
  url?: string;
  displayName?: string;
  id: string;
}

export interface IHighway {
  name?: string;
  distance?: string | number;
}

export interface IAddressInfo {
  coordinates?: YMapsCoords;
  jk?: IJK;
  address?: string;
  buildingName?: string;
  settlementName?: string;
  undergrounds?: IUnderground[];
  highways?: IHighway[];
  jkUrl?: string;
}

export interface IHouseInfo {
  //Название жк
  gkName?: string;
  // Строительная серия
  seriesName?: string;
  // Отопление
  houseHeatSupplyType?: string | null;
  // Газоснабжение
  houseGasSupplyType?: string | null;
  // Тип перекрытий
  houseOverlapType?: string | null;
  // Подъезды
  entrances?: number;

  villageName?: string;
  buildYear?: number;
  houseType?: string;
  batch?: string;
  ceilingHeight?: number;
  passengerElevatorCount?: number;
  serviceElevatorCount?: number;
  garbageChut?: boolean;
  parkingType?: string;
  rentalRate?: IRentalRate;
  rentalDynamics?: number;
  priceDynamics?: number;
  avgPriceSqm?: number;
  emergency?: boolean;
  isNewBuilding?: boolean;
  priceCian?: number;
  priceCianMin?: number;
  priceCianMax?: number;

  // Наличие используемых данных из BTI
  hasBTIData: boolean;
}

export interface IPlaceInfo {
  name?: string;
  region?: string;
  roomsCount?: number;
  rentPrice?: number;
  salePrice?: number;
  rentPriceDynamics?: number;
  salePriceDynamics?: number;
}

export interface IRentalRate {
  rate: number;
  roomsCount?: number;
}

export type IOfferListMap = Map<string, string>;
export type IOfferIconListMap = Map<string, { name: string; icon: string }>;

export interface IOfferIconField {
  icon: string;
  name: string;
}

export interface ILand {
  // площадь (га)
  area?: number;
  // в собственности / в аренде
  status?: LandType;
}

export interface IEntrance {
  type?: 'personal' | 'common';
  from?: 'yard' | 'street';
}

// О здании
export interface IBuildingInfo {
  objectType: ObjectType;
  // Название
  name?: string;
  // Год постройки
  yearBuilt?: number;
  // Тип здания
  type?: BuildingPresenterType;
  // Масштаб комплекса
  scaleOfTheComplex?: BuildingPresenterShoppingCenterScaleType;
  // Класс здания (A/A+/B/...)
  classType?: BuildingPresenterClassType;
  // Этажей в здании
  floorsCount?: number;
  // Высота потолков
  ceilingHeight?: number;
  // Возможное назначение
  possiblePurpose?: BuildingTypeParsed;
  // Состояние
  conditionRating?: BuildingPresenterConditionRatingType | OfferDetailConditionType;
  // Мебель (Есть/Нет)
  hasFurniture?: boolean;
  // Линия домов
  houseLine?: BuildingPresenterHouseLineType;
  // Общая площадь
  totalArea?: number;
  // Участок
  land?: ILand;
  // Категория (Новостройка / Действующее / Проект / Втроичка / Строящееся)
  status?: BuildingPresenterStatusType;
  // Девелопер (название)
  developer?: string;
  // Управляющая компания (название)
  managementCompany?: string;
  // Вход (Отдельный со двора / Отдельный с улицы / Общий со двора / Общий с улицы)
  entrance?: IEntrance;
  // Лифты
  liftsCount?: number;
  // Траволаторы
  theMovingWalkwaysCount?: number;
  // Эскалаторы
  escalatorsCount?: number;
  // Часы работы
  workHours?: IOpeningHours;
  // Рабочие дни
  workDays?: BuildingPresenterWorkingDaysType;
}

export interface IPhoto {
  id?: number;
  fullUrl?: string;
  miniUrl?: string;
  thumbnailUrl?: string;
  thumbnail2Url?: string;
  isDefault?: boolean;
  isLayout?: boolean;
}

export interface IStats {
  views: number;
  published: string;
  uniqueViewsStr: string | null;
  totalViewsStr: string | null;
}

export interface IOfferURLParams {
  id: number;
  dealType: 'sale' | 'rent';
  objectType: OfferType;
}

export interface ISubscribePrice {
  loading: boolean;
  subscribed?: ISubscription[];
}

export interface ISubscription {
  offerId: number;
  email: string;
}
export interface ISubscriptionStatus {
  isEmailConfirmed: boolean;
  isSubscribedOnNews: boolean;
  isSubscribedOnPrice: boolean;
}

export interface IOfferAnalyticsInfo {
  id?: number;
  cianId?: number;
  parentId?: number;
  ownerId?: number;
  ownerCianId?: number;
  dealType?: string;
  objectType?: string;
  category?: string;
  name?: string;
  position?: number;
  auctionPosition?: number;
  published?: boolean;
  price?: number;
  quantity?: number;
  variant?: string[] | string;
  brand?: string;
  photosCount?: number;
  headline?: boolean;
  hiddenBase?: boolean;
  repres?: boolean;
  owner?: boolean;
  podSnos?: boolean;
  nv?: boolean;
  consultant?: boolean;
}

export interface IComplainOffer {
  complained: boolean;
  loading: boolean;
  error?: string;
}

export interface IValueAddedServices {
  isFree: boolean;
  isPremium: boolean;
  isStandard: boolean;
  isTop3: boolean;
}

export type AreaPartPhoto = { id: number; isDefault: boolean };

export interface IAreaPart {
  price: number;
  area: number;
  floorFrom?: number;
  floorTo?: number;
}
export type RawAreaPart = {
  area: string;
  price: number;
  formattedFloor?: string;
  pricePerMeterRur?: string;
  pricePerMeterPerYearRur?: string;
  photos?: AreaPartPhoto[];
};

export type TRepairType = 'cosmetic' | 'design' | 'euro' | 'no' | undefined;

export interface IOfferInfo {
  newbuildingDynamicCalltracking?: {
    siteBlockId?: number | null;
  };
  // Версия модели ML, которая вернула офферы
  modelVersion?: number;
  id: number;
  user?: IUserPresenter;
  dealType?: OfferDetailDealType;
  offerType?: OfferDetailOfferType;
  valueAddedServices?: IValueAddedServices;
  newbuilding?: IOfferNewbuilding | null;
  building?: IBuildingPresenter;
  additionalInfo?: IAdditionalInfo;
  addressInfo?: IAddressInfo;
  agentInfo: IAgentInfo;
  allowed: IOfferIconField[];
  apartmentConditions: IOfferIconField[];
  baseInfo: IBaseInfo;
  booking?: IOfferBooking;
  buildingInfo?: IBuildingInfo;
  buildingInfrastructure: IOfferIconField[];
  businessShoppingCenter?: IBusinessShoppingCenter;
  category?: OfferDetailCategory;
  categoriesIds?: number[];
  callTrackingProvider: TCalltrackingProvider;
  commercialAdditionalInfo?: ICommercialAdditionalInfo;
  commercialInfo?: ICommercialInfo;
  commercialInfoLayout?: ICommercialInfoLayout;
  commercialInfrastructure: IOfferIconField[];
  commercialParking?: IParking;
  commonInfo?: ICommonInfo;
  decoration?: 'fine' | 'rough' | 'without' | undefined;
  demandJsonQuery?: IDemandJsonQuery;
  demolishedInMoscowProgramm?: boolean;
  description?: string;
  flags: IFlags;
  fromDeveloper?: boolean;
  gaLabel?: string;
  geo?: IGeo;
  garageInfrastructure: IOfferIconField[];
  houseInfo?: IHouseInfo;
  isByHomeowner?: boolean;
  isByCommercialOwner?: boolean;
  isCommercialOwnershipVerified?: boolean;
  isDuplicatedDescription: boolean;
  isFavorite: boolean;
  isFromBuilder?: boolean;
  isInHiddenBase?: boolean;
  isCalltrackingEnabled: boolean;
  isPro?: boolean;
  kp?: IOfferKp;
  isResale?: boolean;
  multioffer?: ISimilarOffers;
  offerAnalyticsInfo: IOfferAnalyticsInfo;
  pessimisation?: Pessimisation;
  photos: IPhoto[];
  district?: IDistrictBTIData;
  placeInfo?: IPlaceInfo;
  priceChanges?: IPriceHistoryEvent[];
  readyBusinessType?: EReadyBusinessType;
  removalPublication?: boolean;
  roomsCount?: number;
  rosreestrCheck?: IRosreestrCheck | null;
  status?: OfferDetailStatus;
  suburbanRentConditions: IOfferIconField[];
  suburbanSaleConditions: IOfferIconField[];
  tenants: IOfferIconField[];
  tenantsComment?: string;
  urlParams: IOfferURLParams;
  videos: IVideo[];
  workTimeInfo?: IWorkTimeInfo;
  // ID объявления на ЦИАНе
  cianId?: number;
  // ID пользователя в ЦИАНе
  cianUserId?: number;
  // Аукционная позиция объявления
  auctionPosition?: number;
  //экспериимент продавец онлайн
  isAgentOnline?: boolean;
  isHiddenByUser?: boolean;
  publishedUserId?: number;
  title?: string;
  /**
   * Отвечает за показ лейбла - "Новое"
   * Актуально для выдачи по сохраненному поиску
   */
  isNew?: boolean;
  //подменен ли номер
  isDealRequestSubstitutionPhone?: boolean;

  //поля для мета информации
  isApartments?: boolean;
  flatType?: OfferDetailFlatType;
  totalArea?: string | number;
  minArea?: string | number;
  maxArea?: string | number;
  land?: ILandExtended;
  bargainTerms?: IBargainTermsPresenter;
  showCountrysideBuilderBadge?: boolean;
  /** Список проверок, пройденных пользователем */
  homeownerProofs: Array<THomeownerProof> | null;
  /**
   * @todo Оставить только RawAreaPart
   * @description Данный функционал появился в CD-150883, будет дополнен в CD-152964
   */
  areaParts?: IAreaPart[] | RawAreaPart[];
  repairType: TRepairType;
  livingConditions: ILivingConditions;
  amenities: IAmenities;
  specialty?: ICommercialSpecialty;
  isNeedHideExactAddress?: boolean;
  brandingLevel: EBrandingLevel | null;
  /** Данные о собственнике коммерческой недвижимости **/
  commercialOwnership: ICommercialOwnershipSchema | null | undefined;
  minPricePerMeterRur?: number;
  minPricePerMeterPerYearRur?: number;
  /** Флаг нелегальной застройки **/
  isIllegalConstruction: boolean;
  /** Ссылка на торги на электронной площадке **/
  tradingLink?: string | null;
  /** Лейблы на фото **/
  photoLabels: IPhotoLabelSchema[];
}

export interface ILivingConditions {
  childrenAllowed: boolean | undefined;
  petsAllowed: boolean | undefined;
}

export interface IAmenities {
  hasFridge: boolean | undefined;
  hasDishwasher: boolean | undefined;
  hasWasher: boolean | undefined;
  hasFurniture: boolean | undefined;
  hasKitchenFurniture: boolean | undefined;
  hasConditioner: boolean | undefined;
  hasTv: boolean | undefined;
  hasInternet: boolean | undefined;
  hasPhone: boolean | undefined;
  hasBathtub: boolean | undefined;
  hasShower: boolean | undefined;
}

export interface IOfferPageInfo {
  isNotFoundOffer?: boolean;
  correctHost: string;
  offer: IOfferInfo;
  breadcrumbs?: IBreadcrumb[];
  statisticToken?: string;
  subscribeStatus?: ISubscriptionStatus;
  subscribe?: ISubscribePrice;
  complaints?: IComplaintsTreeItem[];
  complain?: IComplainOffer;
  categoriesIds?: number[];
  similarOffers?: ISimilarOffersResponseItem[];
  withoutCompetitors: boolean;
  modelVersion: TModelVersion;
  similarGKOffers?: ISimilarGKOffer[];
  newbuildingDynamicCalltracking?: {
    siteBlockId?: number | null;
  };
  isResale?: boolean;
  /** Флаг открытия попапа «Сдаётся с ЦИАН-помощником» */
  isSafeRentPopupShow: boolean;
}

export const defaultOfferState: IOfferPageInfo = {
  correctHost: '',
  offer: {
    amenities: {
      hasFridge: undefined,
      hasDishwasher: undefined,
      hasWasher: undefined,
      hasFurniture: undefined,
      hasKitchenFurniture: undefined,
      hasConditioner: undefined,
      hasTv: undefined,
      hasInternet: undefined,
      hasPhone: undefined,
      hasBathtub: undefined,
      hasShower: undefined,
    },
    livingConditions: {
      childrenAllowed: undefined,
      petsAllowed: undefined,
    },
    repairType: undefined,
    callTrackingProvider: null,
    isFavorite: false,
    isDuplicatedDescription: false,
    isCalltrackingEnabled: false,
    urlParams: {
      id: 0,
      dealType: 'sale',
      objectType: 'flat',
    },

    photos: [],
    videos: [],
    description: '',
    baseInfo: {
      isAuction: false,
      isTop3: false,
      isPro: false,
      isStandard: false,
      isPremium: false,
    },
    agentInfo: {
      contacts: [],
      isCianPartner: false,
    },
    allowed: [],
    apartmentConditions: [],
    buildingInfrastructure: [],
    id: 0,
    commercialInfrastructure: [],
    garageInfrastructure: [],
    suburbanRentConditions: [],
    suburbanSaleConditions: [],
    tenants: [],
    gaLabel: '',
    offerAnalyticsInfo: {
      quantity: 0,
    },
    isHiddenByUser: false,
    flags: {},
    homeownerProofs: null,
    commercialOwnership: null,
    brandingLevel: null,
    isIllegalConstruction: false,
    photoLabels: [],
  },
  subscribeStatus: {
    isEmailConfirmed: false,
    isSubscribedOnNews: false,
    isSubscribedOnPrice: false,
  },
  subscribe: {
    loading: false,
  },
  complaints: [],
  complain: {
    complained: false,
    loading: false,
    error: undefined,
  },
  categoriesIds: [],
  similarOffers: [],
  withoutCompetitors: false,
  modelVersion: null,
  isSafeRentPopupShow: false,
};

export interface IOfferKp {
  displayBuilderPhone: boolean;
  id: number;
  name: string;
  url: string;
  isResale?: boolean;
}

export const LivingObjectTypes = [
  ObjectType.Cottage,
  ObjectType.Flat,
  ObjectType.House,
  ObjectType.NewBuilding,
  ObjectType.OpenPlan,
  ObjectType.Room,
  ObjectType.Studio,
  ObjectType.Townhouse,
];

export enum EReadyBusinessType {
  /** Арендный бизнес */
  RentalBusiness = 'rentalBusiness',
  /**
   * @deprecated
   * @description Другое
   * */
  Other = 'other',
  /** Готовый бизнес */
  ReadyBusiness = 'readyBusiness',
}

export interface IPhotoLabelSchema {
  /** Цвет лейбла **/
  color: EColorLabel;
  /** Текст лейбла **/
  name: string;
}

export enum EColorLabel {
  /** Синий **/
  Blue = 'blue',
  /** Зеленый **/
  Green = 'green',
  /** Белый **/
  White = 'white',
  /** Красный **/
  Red = 'red',
  /** Серый **/
  Gray = 'gray',
}
