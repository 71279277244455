import * as React from 'react';

import { IStyleConfig, mergeStyles } from '@cian/components';
import Icon from '../../ui/icon/icon';

const styles = require('./back_button.css');

export interface IBackButtonProps {
  styleConfig?: IStyleConfig;
  onClick: () => void;
  title: string;
  isFirstStep?: boolean;
  children?: React.ReactNode;
  dataMark?: string;
}

export class BackButton extends React.Component<IBackButtonProps, object> {

  public render() {
    const { onClick, title, children, styleConfig } = this.props;
    if (!title) {
      return <noscript />;
    }
    const isFirstStep = !!this.props.isFirstStep;
    return (
      <button
        disabled={isFirstStep}
        type="button"
        onClick={onClick}
        {...mergeStyles(styles.prevButton, styleConfig)}
        data-mark={`${this.props.dataMark ? this.props.dataMark + '|' : ''}button`}
      >
        {!isFirstStep && <Icon name="arrow" styleConfig={styles.prevIcon} />}
        <p className={styles.title}>{title}</p>
        {children}
      </button>
    );
  }
}
