export function parseQueryString(queryString: string) {
  if (queryString) {
    let variables: {[key: string]: string | undefined} = {};

    queryString.split('&').forEach((variable) => {
      const pair = variable.split('=');
      if (pair.length < 2) {
        variables[decodeURIComponent(pair[0])] = undefined;
      } else {
        variables[decodeURIComponent(pair[0])] = decodeURIComponent(pair.slice(1).join('='));
      }
    });

    return variables;
  }

  return undefined;
}
