import { IJsonQuery, IJsonQueryTerms } from '../../api/models/json_query';
import { sortOptionsValues, TOption } from '../../components/sorting/sortingSelect';
import { RegionsState } from '../../redux/regions/model';
import { Regions } from '../regions';

export function filterSortOptions(
  jsonQuery: IJsonQuery,
  {currentRegionInfo}: RegionsState,
  isCoworking?: boolean,
) {
  return function(options: TOption[]): TOption[] {
    const {_type: dealType, room, object_type} = jsonQuery;
    const {mcad, priceSquareOrder, areaOrder, siteOrder, walkingTime, streetName} = sortOptionsValues;

    return options.filter(({value}) => {
      switch (value) {
        case mcad:
          return !isCoworking && isMcadArea(jsonQuery);
        case priceSquareOrder:
          return (
            !isCoworking && isCommercial(dealType)
          ) || isFlat(dealType) && isSale(dealType) && !isRoom(room);
        case areaOrder:
          return !isCoworking && !isLand(dealType, object_type);
        case siteOrder:
          return !isCoworking && isSuburban(dealType);
        case walkingTime:
          return !isCoworking && !isSuburban(dealType) && currentRegionInfo && currentRegionInfo.hasMetro;
        case streetName:
          return !isCoworking && isUrban(dealType, room);
        default:
          return true;
      }
    });
  };
}

export function isMcadArea({region, _type: dealType}: IJsonQuery) {
  return region && region.value.includes(Regions.MoscowRegion) && isSuburban(dealType);
}

export function isSuburban(type: string): boolean {
  return type.includes('suburban');
}

export function isCommercial(type: string): boolean {
  return type.includes('commercial');
}

export function isFlat(type: string): boolean {
  return type.includes('flat');
}

export function isSale(type: string): boolean {
  return type.includes('sale');
}

export function isLand(type: string, objectType?: IJsonQueryTerms<number>): boolean {
  return type.includes('suburbansale') && Boolean(objectType && objectType.value.includes(3));
}

export function isUrban(type: string, room?: IJsonQueryTerms<number>): boolean {
  return isFlat(type) || isRoom(room) || isFlatShared(room) || isBed(room) || false;
}

export function isRoom(room?: IJsonQueryTerms<number>) {
  return room && room.value.includes(0);
}

export function isFlatShared(room?: IJsonQueryTerms<number>) {
  return room && room.value.includes(8);
}

export function isBed(room?: IJsonQueryTerms<number>) {
  return room && room.value.includes(10);
}
