import { IStyleConfig, mergeStyles } from '@cian/utils';
import * as React from 'react';
import { getScrollTop } from '../../utils/scroll';

export interface IScrollFoldingProps {
  containerStyle?: IStyleConfig;
  hideScrollOffset: () => number;
  onFoldChange?: (hidden: boolean) => void;
  noFold?: boolean;
}

interface ISCrollFoldingState {
  hidden?: boolean;
}

export class ScrollFolding extends React.Component<IScrollFoldingProps, ISCrollFoldingState> {

  public state: ISCrollFoldingState = {
    hidden: false,
  };

  private lastScrollTop = 0;
  private scrollDelta = 5;

  public componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  public render() {

    const containerStyles = mergeStyles([
      this.state.hidden && { top: -this.getElementHeight()},
    // tslint:disable-next-line:no-any
    ].concat(this.props.containerStyle as any));

    return (
      <div {...containerStyles}>
        {this.props.children}
      </div>
    );
  }

  private getElementHeight = () => {
    return this.props.hideScrollOffset();
  }

  private handleScroll = () => {
    if (this.props.noFold) {
      return;
    }

    let st = getScrollTop();

    if (Math.abs(this.lastScrollTop - st) <= this.scrollDelta) {
      return;
    }

    const shouldHide = st > this.lastScrollTop && st > this.getElementHeight();
    if (shouldHide !== this.state.hidden) {
      if (this.props.onFoldChange) {
        this.props.onFoldChange(shouldHide);
      }
      this.setState({ hidden: shouldHide });
    }

    this.lastScrollTop = st;
  }
}
