import { IGKData } from '../../../api/models/gk_card';
import { IPromoInfo as IAPIPromo } from '../../../api/models/gk_preview';
import { IPromo } from '../model';

const mapper = (promo: IAPIPromo): IPromo => {
  // fixme no logoUrl from backend
  const { name, end, offers_count: count, qs, logo_url: logoUrl } = promo;
  return {
    name,
    end,
    count,
    qs,
    logoUrl,
  };
};

export const parsePromo = (gk: IGKData): IPromo | undefined =>
  gk.promo_info && mapper(gk.promo_info) || undefined;
