import { QueryClient } from '@tanstack/react-query';

import { ApplicationContextModel } from '../../../utils/applicationContext';
import type { IServerSideState } from '../IServerSideState';

import { ServerSideState } from './ServerSideState';

export class BrowserServerSideState extends ServerSideState implements IServerSideState {
  public constructor(applicationContext: ApplicationContextModel) {
    const { config } = applicationContext;

    const queryClient = new QueryClient({
      defaultOptions: { queries: { refetchOnMount: false, refetchOnWindowFocus: false } },
    });

    super(queryClient);

    this.hydrate(config.getStrict<unknown>(ServerSideState.key));
  }
}
