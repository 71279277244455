import { IHttpApi } from '@cian/http-api';
import { get } from 'lodash';
import { Observable } from 'rxjs/Observable';
import { share } from 'rxjs/operator/share';
import { parse as parseUrl } from 'url';

import { IProfiler, Meta } from '../common_interfaces/profiler';
import { IClientConfig } from '../client/DEPRECATED/config';
import { IRequestConfiguration, JsonRequest, Request, Response, TextRequest } from './request';
import { removeExtraSlashesFromUrl } from './utils';
import { IConfig } from '../server/DEPRECATED/config';

const noop = () => undefined;

export interface IRequestMiddleware {
  process(config: IRequestConfiguration): IRequestConfiguration;
}

export class NetworkLayer {
  private baseUrl: string;
  private hostname: string;
  private requestMiddlewares: IRequestMiddleware[];
  private profiler: IProfiler | void;
  private currentRequests: Request<any>[]; // tslint:disable-line
  private defaultTimeout: number;
  private httpApi: IHttpApi;
  private config: IConfig | IClientConfig;

  public constructor(
    baseUrl: string,
    defaultTimeout: number,
    requestMiddlewares: IRequestMiddleware[],
    profiler: IProfiler | void,
    httpApi: IHttpApi,
    config: IConfig | IClientConfig,
  ) {
    this.baseUrl = baseUrl[baseUrl.length - 1] === '/'
      ? baseUrl.slice(0, -1)
      : baseUrl;

    this.defaultTimeout = defaultTimeout;
    this.hostname = parseUrl(baseUrl).hostname || 'python';
    this.requestMiddlewares = requestMiddlewares;
    this.profiler = profiler;
    this.config = config;
    this.currentRequests = [];
    this.httpApi = httpApi;
  }

  public json<T>(_config: IRequestConfiguration): Observable<Response<T>> {
    // Раскомментировать для тестирования деградации
    // if (config.url.includes('/cian-api/')) {
    //   return new ErrorObservable(`DEGRADATION ${config.url}`);
    // }
    const config = this.processConfig(_config);
    const request = share.call(new JsonRequest<T>(config, this.config));
    this.registerRequest(request, config);
    return request;
  }

  public text(_config: IRequestConfiguration) {
    const config = this.processConfig(_config);
    const request = share.call(new TextRequest(config, this.config));
    this.registerRequest(request, config);
    return request;
  }

  public waitForAllCurrentRequests(): Promise<void> {
    return Promise.all(
      this.currentRequests.map(request => new Promise<void>((resolve) => {
        request
          .subscribe(
            noop,
            () => resolve(),
            () => resolve(),
          );
      })),
    )
      .then(() => new Promise<void>(resolve => setImmediate(() => resolve())))
      .then(() => {
        if (this.currentRequests.length > 0) {
          return this.waitForAllCurrentRequests();
        }

        return;
      });
  }

  public getHttpApi() {
    return this.httpApi;
  }

  public getConfig() {
    return this.config;
  }

  private processConfig(config: IRequestConfiguration): IRequestConfiguration {
    let newConfig = this.requestMiddlewares.reduce((acc, middleware) => {
      return middleware.process(acc);
    }, config);

    const baseUrl = config.absoluteUrl ? '' : this.baseUrl;

    const microserviceApiUrl = this.config && this.config.api.microservicesUrls && config.microserviceApiName
    ? this.config.api.microservicesUrls[config.microserviceApiName]
    : '';

    return Object.assign({}, newConfig, {
      url: removeExtraSlashesFromUrl((microserviceApiUrl || baseUrl) + newConfig.url),
    });
  }

  private registerRequest(request: Request<any>, config: IRequestConfiguration) { // tslint:disable-line
    const operationDone = this.profiler ? this.profiler.profile(
      'http',
      config.url.replace(/\//g, '_'),
      this.hostname,
      {
        url: config.url,
        method: config.method || 'GET',
        timeout: String(config.timeout || this.defaultTimeout),
        headers: JSON.stringify(config.headers) || '',
        body: JSON.stringify(config.body) || '',
      },
    ) : (additionalMeta?: Meta) => undefined;
    this.currentRequests.push(request);

    const remove = () => {
      this.currentRequests = this.currentRequests.filter(r => r !== request);
    };

    const error = (err: Error) => {
      const statusCode = String(get(err, 'cause.status'));
      try {
        err.meta.response.body = JSON.parse(err.meta.response.body);
      } catch (e) {}

      operationDone({ statusCode });
      remove();
    };

    const success = (res: Response<any>) => { // tslint:disable-line
      const statusCode = String(res.response && res.response.status);

      operationDone({ statusCode });
    };

    request
      .subscribe(
        success,
        error,
        remove,
      );
  }
}
