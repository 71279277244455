import * as React from 'react';

import { isFlatObjectType } from '../../../redux/filters/model';
import Block from '../../../ui/block/block';
import { Field } from '../../field';
import * as fields from '../../fields/';
import { IFieldProps, IsVisibleCheck } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const objectType = this.props.filter.selectedObjectTypes[0];
    const objectTypeTitle = isFlatObjectType(objectType) ? 'квартире' : 'доме';
    const title = `Что нужно в ${objectTypeTitle}`;

    return (
      <Block
        title={title}
        dataMark="BlockConditions"
      >
        <div className={styles.fieldBlock}>
          <Field field={fields.withFurnitureFlat} />
          <Field field={fields.conditionsList} />
        </div>
        <div className={styles.fieldBlock}>
          <Field field={fields.allowedList} />
        </div>
      </Block>
    );
  }
}

export const isVisible: IsVisibleCheck = filter =>
  fields.conditionsList.isVisible(filter)
  || fields.withFurnitureFlat.isVisible(filter);
