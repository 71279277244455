import { IStyleConfig, mergeStyles } from '@cian/components';

import * as React from 'react';

import Icon from '../icon/icon';

const styles = require('./logo.css');

interface ILogoProps {
  isNewLogo: boolean;
  isNewYearLogo: boolean;
  styleConfig?: IStyleConfig;
}

const Logo: React.SFC<ILogoProps> = ({ styleConfig, isNewLogo, isNewYearLogo }: ILogoProps) => {
  return (
    <figure {...mergeStyles(styles.logo, styleConfig)}>
      {isNewYearLogo && <Icon name="logo-cian-new-year" styleConfig={styles.inscription} />}

      {!isNewYearLogo && isNewLogo && <Icon name="logo-cian-new" styleConfig={styles.inscription} />}

      {!isNewYearLogo && !isNewLogo && (
        <>
          <Icon name="logo-roof" />
          <Icon name="logo-cian" styleConfig={styles.inscription} />
        </>
      )}
    </figure>
  );
};

// eslint-disable-next-line import/no-default-export
export default Logo;
