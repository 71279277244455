import { get } from 'lodash';

import {
  IOfferDetail,
  IPhone,
} from '../../../api/models/offer_card';
import {
  IAgentInfo,
  IContact,
} from '../model';

function mapContacts(data: IOfferDetail): IContact[] {
  const offerPhones = data.phones || [];

  const phones: IContact[] = offerPhones.map(function(phone: IPhone): IContact {
    return {
      countryCode: phone.countryCode,
      number: phone.number,
    };
  });
  return phones;
}

function mapAgencyName(data: IOfferDetail): string | undefined {
  const name: string = get(data, 'user.agencyName');
  return !/^ID:.*$/.test(name) ? name : undefined;
}

export function responseToAgentInfo(data: IOfferDetail): IAgentInfo {
  const { user } = data;

  let agentInfo: IAgentInfo = {
    agencyName: user && user.companyName,
    agentAccountType: user && user.agentAccountType,
    agentName: user && user.agencyNameV2,
    agentReviews: user && user.agentReviews,
    id: Number(data.id),
    agentId: data.cianUserId,
    contacts: mapContacts(data),
    experience: user && user.experience,
    isCianPartner: !!(user && user.isCianPartner),
    isPro: data.isPro,
    isHonest: data.isFairplay,
    isHidden: get(data, 'user.isHidden'),
    avatar: get(data, 'user.agentAvatarUrl'),
    name: mapAgencyName(data),
    isSubAgent: data.user
      ? data.user.isSubAgent
      : undefined,
    userType: user && user.userType,
  };

  if (data.user) {
    Object.assign(agentInfo, {
      status: data.user.cianProfileStatus,
    });
  }

  return agentInfo;
}
