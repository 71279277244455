import * as React from 'react';

import { IStyleConfig, mergeStyles } from '@cian/components';

const styles = require('./block.css');

export interface IBlockProps {
  children?: React.ReactNode;
  title?: string;
  titleStyle?: IStyleConfig;
  blockStyle?: IStyleConfig;
  noPadding?: boolean;
  dataMark?: string;

  onTouchStart?: (e: React.TouchEvent<HTMLElement>) => void;
}

const Block: React.SFC<IBlockProps> = (props) => {
  const {
    children, blockStyle, noPadding, title,
  } = props;

  const blockMergedStyles = mergeStyles(
    styles.block,
    blockStyle,
  );

  const sectionStyles = mergeStyles(
    styles.section,
    noPadding && styles.noPadding,
  );

  const titleStyles = mergeStyles(
    styles.title,
    props.titleStyle,
  );

  return (
    <div
      onTouchStart={props.onTouchStart}
      data-mark={props.dataMark}
      {...blockMergedStyles}
    >
      {title && <h3 {...titleStyles}>{title}</h3>}
      {!!children && React.Children.map(children,
        // tslint:disable-next-line:no-any
        (child: React.ReactElement<any>) => (
          !child ? child : React.cloneElement(
            child,
            { className: `${sectionStyles.className} ${child.props.className || ''}` },
          )
        ),
      )}
    </div>
  );
};

export default Block;
