import { TPushSubscriptionsActions } from './actions';

export interface IPushSubscriptionState {
  pushSubscribed: boolean;
  isVisiblePushPopup: boolean;
}

export type TPushSubscriptionReducer =
  (state: IPushSubscriptionState, action: TPushSubscriptionsActions) => IPushSubscriptionState;

const defaultState: IPushSubscriptionState = {
  pushSubscribed: false,
  isVisiblePushPopup: false,
};

export const pushSubscriptionReducer: TPushSubscriptionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'IPushSubscriptionSetValue':
      return {
        ...state,
        pushSubscribed: action.value,
      };

    case 'IPushSubscriptionShowPopup':
      return {
        ...state,
        isVisiblePushPopup: true,
      };

    default:
      return state;
  }
};
