import * as React from 'react';

import { DealType } from '../../../redux/filters/model';
import { Field } from '../../field';
import * as fields from '../../fields/';
import { IFieldProps, isFlatObjectGroup, IsVisibleCheck } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    return (
      <div className={styles['filter-layout']} data-mark="BlockSaleType">
        <span className={styles['filter-label']}>
          Тип продажи
        </span>
        <Field field={fields.saleTypeFlat} />
        <Field field={fields.saleTypeNewbuilding} />
        <Field field={fields.withMortgage} />
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter =>
  isFlatObjectGroup(filter) && filter.dealType === DealType.Sale;
