import { Config } from '@cian/config';
import { Request } from 'express';
import { getHost } from './location';

export const isNewbuildingSubdomain = (request: Request, config: Config): boolean => {
  const newbuildingSubdomain = request.get('X-NewbuildingSubdomainName');
  if (!newbuildingSubdomain) {
    return false;
  }

  return config.get('frontendMobileWebsite.NewbuildingSubdomains.Enabled') + '' === 'true' &&
    (config.get<string[]>('frontendMobileWebsite.NewbuildingSubdomains.List') || [])
      .includes(newbuildingSubdomain);
};

export const replaceNewbuildingSubdomainToGeo = (url: string, geoSubdomain: string): string => {
  const host = getHost(url || '').split('.').slice(1).join('.') || 'cian.ru';

  return `https://${geoSubdomain || 'www'}.${host}`;
};
