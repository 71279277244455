import { toPromise } from '../../utils/streams';
import { TTypedThunkAction } from '../model';

import { IQuickLinks } from './model';

export type TQuickLinksActions =
  | { type: 'ResetQuickLinks' }
  | { type: 'QuickLinksSuccess'; payload: { data: IQuickLinks } };

export function resetQuickLinks(): TQuickLinksActions {
  return {
    type: 'ResetQuickLinks',
  };
}

export function getQuickLinks(queryString: string): TTypedThunkAction<Promise<{}>> {
  return (dispatch, getState, { api, logger }) => {
    return toPromise(api.getJsonQuery(queryString))
      .then(response => {
        return toPromise(api.getQuickLinks(response.result.data.jsonQuery));
      })
      .then(response => {
        return dispatch<TQuickLinksActions>({
          type: 'QuickLinksSuccess',
          payload: {
            data: response.result.data.quickLinks,
          },
        });
      })
      .catch(err => {
        logger.error(err, {
          message: 'Failed to getQuickLinks in getQuickLinks',
          domain: 'src/mobile_website/redux/quick_links/actions.ts',
        });

        return dispatch<TQuickLinksActions>({
          type: 'QuickLinksSuccess',
          payload: {
            data: { level: 0, links: null },
          },
        });
      });
  };
}
