// tslint:disable:max-line-length
import { IExperiment } from '../../api/api';
import { IExperimentResultSchema } from '../../api/models/ab_use';

export function getAbExperiment(experiments: IExperiment[], experimentName: string) {
  return experiments.find(({ name }) => name === experimentName);
}

export const getAbGroup = (experiment?: IExperiment | undefined): (number | null) => {
  return experiment ? experiment.abGroup : null;
};

export const isExperimentActive = (experiments: IExperiment[], experimentName: string): boolean => {
  const experiment = getAbExperiment(experiments, experimentName);

  return !!(experiment && experiment.abGroup);
};

export function getExperiment(experiments: IExperimentResultSchema[], experimentName: string): IExperimentResultSchema | undefined {
  return experiments.find(o => o.experimentName === experimentName);
}

export function isAbUseExperimentActive(experiments: IExperimentResultSchema[], experimentName: string, groupName: string): boolean {
  const experiment = getExperiment(experiments, experimentName);

  if (!experiment) {
    return false;
  }

  return experiment.groupName === groupName;
}
