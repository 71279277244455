import { TSpecialPromosInfoActions } from './actions';
import { ISpecialPromoInfo } from './model';

export interface ISpecialPromosInfoState {
  specialPromosInfo: ISpecialPromoInfo[];
  canPreviewSpecialPromos: boolean;
  specialPromosInfoRegion: number;
  specialPromosInfoDomain: string;
}

const defaultSpecialPromosInfoState = {
  specialPromosInfo: [],
  canPreviewSpecialPromos: false,
  specialPromosInfoRegion: 1,
  specialPromosInfoDomain: 'www',
};

export function specialPromosInfoReducer(
  state: ISpecialPromosInfoState = defaultSpecialPromosInfoState,
  action: TSpecialPromosInfoActions,
): ISpecialPromosInfoState {
  switch (action.type) {
    case 'SetSpecialPromosInfo':
      return {...state, specialPromosInfo: action.payload};

    case 'SetCanPreviewSpecialPromos':
      return {...state, canPreviewSpecialPromos: action.payload};

    case 'SetSpecialPromosInfoRegion':
      return {...state, specialPromosInfoRegion: action.payload};

    case 'SetSpecialPromosInfoDomain':
      return {...state, specialPromosInfoDomain: action.payload};

    default:
      return state;
  }
}
