import { IBoundedBy, IRegionInfo, IRegions } from '../api/api';
import { IGeoTag, IGeoTagDetails } from '../redux/filters/model';
import { YMapsBoundedBox } from './ymaps';

import { first, last } from 'lodash';
import { IUserRegion } from '../redux/common/model';
import logException from './exceptions';
import { getSubdomain } from './location';

export enum Regions {
  Moscow = 1,
  MoscowRegion = 4593,
  SaintPetersburg = 2,
  LeningradRegion = 4588,
  Adygea = 4553,
  Moskovskij = 191974,
  Troick = 189131,
  Zelenograd = 4760,
  Shcherbinka = 189141,
  /** Следующие два на бэке хранятся в другом виде, см. комменты методах апи. */
  MoscowAndRegion = -1,
  SaintPetersburgAndRegion = -2,
}
export interface IRegion {
  hasMetro: boolean;
  hasHighway: boolean;
  id: number;
  title: string;
  isMale?: boolean; // муж. род
}

// Дефолтный только для сабдомена www
export const defaultRegionId = Regions.Moscow;

// todo все эти данные теперь приходят в метаинформации о регионе, в теории можно выпилить.
export const regions: IRegion[] = [
  {
    hasMetro: true,
    hasHighway: false,
    id: Regions.Moscow,
    title: 'Москва',
  },
  {
    hasMetro: false,
    hasHighway: true,
    id: Regions.MoscowRegion,
    title: 'Московская область',
  },
  {
    hasMetro: true,
    hasHighway: false,
    id: Regions.SaintPetersburg,
    title: 'Санкт-Петербург',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: Regions.LeningradRegion,
    title: 'Ленинградская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: Regions.Adygea,
    title: 'Адыгея',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4554,
    title: 'Алтай (Республика)',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4555,
    title: 'Алтайский край',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4556,
    title: 'Амурская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4557,
    title: 'Архангельская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4558,
    title: 'Астраханская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4560,
    title: 'Башкортостан',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4561,
    title: 'Белгородская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4562,
    title: 'Брянская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4563,
    title: 'Бурятия',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4564,
    title: 'Владимирская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4565,
    title: 'Волгоградская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4566,
    title: 'Вологодская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4567,
    title: 'Воронежская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4568,
    title: 'Дагестан',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4569,
    title: 'Еврейская АО',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 187450,
    title: 'Забайкальский край',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4570,
    title: 'Ивановская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4571,
    title: 'Ингушетия',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4572,
    title: 'Иркутская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4573,
    title: 'Кабардино-Балкария',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4574,
    title: 'Калининградская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4575,
    title: 'Калмыкия',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4576,
    title: 'Калужская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4577,
    title: 'Камчатский край',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4578,
    title: 'Карачаево-Черкесия',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4579,
    title: 'Карелия',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4580,
    title: 'Кемеровская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4581,
    title: 'Кировская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4582,
    title: 'Коми',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4583,
    title: 'Костромская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4584,
    title: 'Краснодарский край',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4585,
    title: 'Красноярский край',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 181462,
    title: 'Крым',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4586,
    title: 'Курганская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4587,
    title: 'Курская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4589,
    title: 'Липецкая область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4590,
    title: 'Магаданская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4591,
    title: 'Марий Эл',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4592,
    title: 'Мордовия',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4594,
    title: 'Мурманская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4595,
    title: 'Ненецкий АО',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4596,
    title: 'Нижегородская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4597,
    title: 'Новгородская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4598,
    title: 'Новосибирская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4599,
    title: 'Омская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4600,
    title: 'Оренбургская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4601,
    title: 'Орловская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4602,
    title: 'Пензенская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4603,
    title: 'Пермский край',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4604,
    title: 'Приморский край',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4605,
    title: 'Псковская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4606,
    title: 'Ростовская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4607,
    title: 'Рязанская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4608,
    title: 'Самарская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4609,
    title: 'Саратовская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4610,
    title: 'Саха (Якутия)',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4611,
    title: 'Сахалинская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4612,
    title: 'Свердловская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 184723,
    title: 'Севастополь',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4613,
    title: 'Северная Осетия-Алания',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4614,
    title: 'Смоленская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4615,
    title: 'Ставропольский край',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4617,
    title: 'Тамбовская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4618,
    title: 'Татарстан',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4619,
    title: 'Тверская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4620,
    title: 'Томская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4621,
    title: 'Тульская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4622,
    title: 'Тыва',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4623,
    title: 'Тюменская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4624,
    title: 'Удмуртия',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4625,
    title: 'Ульяновская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4627,
    title: 'Хабаровский край',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4628,
    title: 'Хакасия',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4629,
    title: 'Ханты-Мансийский АО',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4630,
    title: 'Челябинская область',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4631,
    title: 'Чечня',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4633,
    title: 'Чувашия',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4634,
    title: 'Чукотский АО',
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4635,
    title: 'Ямало-Ненецкий АО',
    isMale: true,
  },
  {
    hasMetro: false,
    hasHighway: false,
    id: 4636,
    title: 'Ярославская область',
  },
];

export const compositeRegions: IUserRegion[] = [
  {
    id: Regions.MoscowAndRegion,
    name: 'Москва и МО',
    subdomain: 'www',
  },
  {
    id: Regions.MoscowAndRegion,
    name: 'Санкт-Петербург и ЛО',
    subdomain: 'spb',
  },
];

export function isMoscowAndRegion(id: number): boolean {
  return [
    Regions.Moscow,
    Regions.MoscowRegion,
    Regions.MoscowAndRegion,
  ].includes(id);
}

export function isSaintPetersburgAndRegion(id: number): boolean {
  return [
    Regions.SaintPetersburg,
    Regions.LeningradRegion,
    Regions.SaintPetersburgAndRegion,
  ].includes(id);
}

export const getRegionId = (region: IRegions): number =>
  parseInt(Object.keys(region)[0], 10);

export const getRegionById = (id: number): IRegion | undefined => {
  return regions.find(r => r.id === id);
};

export const getRegionTitleById = (id: number): string => {
  const region = getRegionById(id);

  return region !== undefined
    ? region.title
    : '';
};

export const getRegionByTitle = (title: string) => {
  return regions.filter(r => r.title === title)[0];
};

export const getMainTownId = (regionId: number, regionsInfo?: IRegionInfo[]): number => {
  if (regionId === Regions.MoscowAndRegion) {
    return Regions.Moscow;
  }
  if (regionId === Regions.SaintPetersburgAndRegion) {
    return Regions.SaintPetersburg;
  }

  const region = (regionsInfo || [])
    .find((r: IRegionInfo) => r.id === regionId);

  return region != null
    ? region.mainTownId || region.id
    : regionId;
};

/** Функция для методов АПИ, которые не принимают в качестве id региона массив.
 *  Заменяет id Мск+МО и Спб+ЛО на id соответствующих городов. */
export const patchIdForCompositeRegion = (region_id: number): number => {
  switch (region_id) {
    case Regions.MoscowAndRegion:
      return Regions.Moscow;

    case Regions.SaintPetersburgAndRegion:
      return Regions.SaintPetersburg;

    default:
      return region_id;
  }
};

/** Обобщенная функция для определения региона по данным текущих геотегов.
 * @returns общий регион, который можно поставить в поле "Где"
 *          либо регион первых деталей, который можно поставить в поле "Где"
 *          либо undefined.
 * См. тесты в regions.test.ts */
export const getLastCommonRegion = (tagsDetails: IGeoTagDetails[][]): number | undefined => {
  const firstTagDetails = tagsDetails[0];

  const lastCommonDetail = last(firstTagDetails.filter(detail =>
    detail.locationInfo &&
    tagsDetails.every(tagDetails =>
      tagDetails.some(tagDetail => tagDetail.id === detail.id),
    ),
  ));

  if (lastCommonDetail) {
    return lastCommonDetail.id;
  }

  const defaultRegion = last(firstTagDetails.filter(d => !!d.locationInfo));
  if (defaultRegion) {
    return defaultRegion.id;
  }

  return undefined;
};

/**
 * Добавляет области в иерархию для городов.
 * Преобразование данных гео иерархии, приходящей в geocoded-for-search (CSSSR-7603).
 *  Предназначена для подготовки тегов перед отправкой в getLastCommonRegion.
 * */
export const patchGeoTag = (tag: IGeoTag): IGeoTag => {
  if (!tag.details) { return tag; }

  let patchedDetails = [...tag.details];

  if (first(tag.details)!.id === Regions.Moscow) {
    patchedDetails.unshift({
      'id': Regions.MoscowRegion,
      'name': 'Московская область',
      'fullName': 'Московская область',
      'geoType': 'Location',
      'locationInfo': {
        'id': Regions.MoscowRegion,
      },
    });
  } else if (first(tag.details)!.id === Regions.SaintPetersburg) {
    patchedDetails.unshift({
      'id': Regions.LeningradRegion,
      'name': 'Ленинградская область',
      'fullName': 'Ленинградская область',
      'geoType': 'Location',
      'locationInfo': {
        'id': Regions.LeningradRegion,
      },
    });
  } else if (tag.regionId && first(tag.details)!.id !== tag.regionId) {
    patchedDetails.unshift({
      'id': tag.regionId,
      'name': '',
      'fullName': '',
      'geoType': 'Location',
      'locationInfo': {
        'id': tag.regionId,
      },
    });
  }
  return {
    ...tag,
    details: patchedDetails,
  };
};

export const tagsCanBeUnitedByCompositeRegion = (allTags: IGeoTag[], requiredRegions: number[]) => {
  /** каждый тег должен содержать каждый из регионов. */
  /** Для каждого региона из requiredRegions должен найтись хотя бы 1 тег */
  const filteredTagRegions = allTags.filter(
    tag => tag.regionId && requiredRegions.includes(tag.regionId),
  ).map(tag => tag.regionId);

  return filteredTagRegions.length === allTags.length && requiredRegions.every(requiredRegion =>
    filteredTagRegions.some(tagRegion => tagRegion === requiredRegion),
  );
};

export const coordsCianToYmapsBox = (coords: IBoundedBy): YMapsBoundedBox => {
  return [
    [
      coords.lowerCorner.lat,
      coords.lowerCorner.lng,
    ],
    [
      coords.upperCorner.lat,
      coords.upperCorner.lng,
    ],
  ];
};

export const patchUserRegion = (region: IUserRegion): IUserRegion => {
  if (isMoscowAndRegion(region.id)) {
    return {
      id: Regions.Moscow,
      name: 'Москва и МО',
      subdomain: 'www',
    };
  }
  if (isSaintPetersburgAndRegion(region.id)) {
    return {
      id: Regions.SaintPetersburg,
      name: 'Санкт-Петербyрг и ЛО',
      subdomain: 'spb',
    };
  }
  return region;
};

export function removeMainRegions<T extends {id: number }>(regionInfo: T[]): T[] {
  return regionInfo.filter(({ id }) =>
    !(isMoscowAndRegion(id) || isSaintPetersburgAndRegion(id)),
  );
}

export function mapRegionInfoToUserRegion(regionInfo: IRegionInfo): IUserRegion | undefined {
  const { baseHost, displayName, id } = regionInfo;
  if (!baseHost || !displayName) {
    logException(
      new Error(`Unexpected baseHost=${baseHost} or displayName=${displayName} in mapRegionInfoToUserRegion`),
    );
    return undefined;
  }

  return {
    name: displayName,
    subdomain: getSubdomain(baseHost),
    id,
  };
}
