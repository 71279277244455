import * as React from 'react';

import { without } from 'lodash';
import { commercialObjectType } from '../../../redux/filters/model';
import { Field } from '../../field';
import * as fields from '../../fields/';
import { IFieldProps, IsVisibleCheck } from '../field';

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    return (
      <Field field={fields.floor} />
    );
  }
}

const allowedCommercialObjectTypes = [
  commercialObjectType.Office,
  commercialObjectType.Marketplace,
  commercialObjectType.Warehouse,
  commercialObjectType.Production,
  commercialObjectType.Psn,
  commercialObjectType.PublicCatering,
  commercialObjectType.CarService,
  commercialObjectType.DomesticService,
  commercialObjectType.RentalBusiness,
  commercialObjectType.ReadyBusiness,
  commercialObjectType.Coworking,
];

export const isVisible: IsVisibleCheck = filter =>
  filter.selectedObjectTypes.length > 0
  && without(filter.selectedObjectTypes, ...allowedCommercialObjectTypes).length === 0;
