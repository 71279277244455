import * as React from 'react';
import { connect } from 'react-redux';
import { IRegionsSettings } from '../../redux/filters/model';
import { ReduxState } from '../../redux/model';
import { isSidebarOpened, setSidebarState } from '../../redux/popups/actions';
import { updateRegionMetaForFilter } from '../../redux/regions/actions';
import { RouteName } from '../../redux/routing/model';
import { logOut } from '../../redux/users/actions';
import Sidebar from '../../ui/sidebar/sidebar';
import { isAndroidDevice, isAppleDevice } from '../../utils/device_detector';
import { removeTrailingSlash } from '../../utils/location';
import { Regions } from '../../utils/regions';
import { routeFor } from '../../utils/routeFor';
import { TOs } from '../download_app_button/download_app_button';
import Header from '../header/header';
import Page, { PageTheme } from '../page/page';
import SidebarContent from '../sidebar_content/sidebar_content';

interface IStateProps {
  userAgent: string;
  regionSettings?: IRegionsSettings;
  region?: number;
  filterRegion?: number;
  sidebarOpened: boolean;
  pathname: string;
  favoritesCount: number;
  pikPhone?: string;
  showBanner: boolean;
  routeName: RouteName;
  isAuthenticated: boolean;
  userName?: string;
}

interface IDispatchProps {
  setSidebarState(state: boolean): void;
  logOut(): void;
  updateRegionMetaForFilter(filterRegion: number): void;
}

interface ILayoutState {
  os?: TOs;
}

const defaultPageExtraProps = {
  theme: PageTheme.GRAY,
  withFooter: true,
};

export class Layout extends React.Component<IStateProps & IDispatchProps, ILayoutState> {

  public constructor(props: IStateProps & IDispatchProps) {
    super(props);

    const isAndroid = isAndroidDevice(props.userAgent);
    const isApple = isAppleDevice(props.userAgent);

    const os: TOs = isApple ? 'apple' : isAndroid ? 'android' : undefined;

    this.state = {
      os,
    };
  }

  public componentDidMount() {
    // tslint:disable-next-line:no-shadowed-variable
    const { updateRegionMetaForFilter, filterRegion } = this.props;

    if (updateRegionMetaForFilter && filterRegion) {
      updateRegionMetaForFilter(filterRegion);
    }
  }

  public render() {
    const { os } = this.state;
    const {
      region,
      regionSettings,
      sidebarOpened,
      showBanner,
      isAuthenticated,
      userName,
    } = this.props;

    const sidebarContent = <SidebarContent
      favoritesTotal={this.props.favoritesCount}
      os={os}
      regionSettings={regionSettings}
      region={region || Regions.Moscow}
      authenticated={isAuthenticated}
      userName={userName}
      onLogOut={this.props.logOut}
    />;
    const extraPageProps = this.getPageExtraProps();
    return (
      <div>
        <Sidebar
          sidebarContent={sidebarContent}
          setSidebarState={this.props.setSidebarState}
          opened={sidebarOpened}
          showBanner={showBanner}
          onOverlayClick={this.closeSidebar}
        >
          <Page
            os={os}
            onClick={this.closeSidebar}
            {...extraPageProps}
          >
            <Header />
            {this.props.children}
          </Page>
        </Sidebar>
      </div>
    );
  }

  private getPageExtraProps = () => {
    const { routeName, pathname, favoritesCount } = this.props;
    const showFavoritesDefaultPage = favoritesCount > 0;

    if (routeName === '404') {
      return {
        theme: PageTheme.LIGHT,
        withFooter: false,
      };
    } else if (['sign_in_up', 'resetPassword'].includes(routeName)) {
      return {
        ...defaultPageExtraProps,
        theme: PageTheme.LIGHT,
        withFooter: false,
      };
    } else if (routeName === 'map') {
      return {
        ...defaultPageExtraProps,
        withFooter: false,
      };
    }

    switch (removeTrailingSlash(pathname)) {
      case routeFor.ADD_ADVERT_PROMO:
      case routeFor.RESET_PASSWORD:
      case routeFor.MY_OFFERS_PROMO:
        return {
          theme: PageTheme.LIGHT,
          withFooter: false,
        };
      case routeFor.FAVORITES:
        return {
          withFooter: showFavoritesDefaultPage,
          theme: showFavoritesDefaultPage ? PageTheme.GRAY : PageTheme.LIGHT,
        };
      case routeFor.OFFERS_LIST:
        return {
          ...defaultPageExtraProps,
          pikPhone: this.props.pikPhone,
        };
      default:
        return defaultPageExtraProps;
    }
  }

  private closeSidebar = () => {
    if (this.props.sidebarOpened) {
      this.props.setSidebarState(false);
    }
  }

}

function mapStateToProps(state: ReduxState): IStateProps {
  const {
    regions: { regionMeta },
    filter,
    routing: { locationBeforeTransitions },
    favorites,
  } = state;

  const region = regionMeta && regionMeta.location && regionMeta.location.id || filter.region;

  return {
    userAgent: state.userAgent,
    regionSettings: filter.regionSettings,
    filterRegion: filter.region,
    region,
    sidebarOpened: isSidebarOpened(state),
    pathname: locationBeforeTransitions.pathname,
    favoritesCount: favorites.count,
    pikPhone: state.searchList.initialized ? state.searchList.pikPhone : undefined,
    showBanner: state.fixedComponents.appBannerOn,
    routeName: state.routing.routeName,
    isAuthenticated: state.common.isAuthenticated,
    userName: state.common.displayUsername,
  };
}

export default connect<IStateProps, IDispatchProps, any>(
  mapStateToProps,
  {
    logOut,
    setSidebarState,
    updateRegionMetaForFilter,
  },
)(Layout);
