import { Label } from '@cian/components';
import * as React from 'react';

import { SelectMobile } from '../../common/select/select';

import { BathroomsCountType } from '../../../redux/filters/model';
import {
  IFieldProps,
  isFlatObjectGroup,
  IsVisibleCheck,
} from '../field';

import { createSelectOptions } from '../../common/select_helper';

const style = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  private options = [{
    label: '',
    options: [{
      id: String(BathroomsCountType.Any),
      label: 'Неважно',
    }, {
      id: String(BathroomsCountType.One),
      label: 'От 1',
    }, {
      id: String(BathroomsCountType.Two),
      label: 'От 2',
    }, {
      id: String(BathroomsCountType.Three),
      label: 'От 3',
    }, {
      id: String(BathroomsCountType.Four),
      label: 'От 4',
    }],
  }];

  public render() {
    const processedOptions = createSelectOptions(
      this.options,
      [String(this.props.filter.bathroomsCount)],
      this.props.filter,
    );
    return (
      <div className={style.field} data-mark="FieldBathroomsCount">
        <Label label="Количество санузлов">
          <SelectMobile
            id="select-bathrooms-count"
            name="selectMobile"
            label="Количество санузлов"
            onValueChange={(value: string[]) => this.props.actions.setBathroomsCount(Number(value[0]))}
            value={processedOptions.value}
            options={processedOptions.options[0].options}
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => isFlatObjectGroup(filter) && !filter.withNewobject;
