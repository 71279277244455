import * as React from 'react';

import * as styles from './styles.css';
import {
  Label,
  Tooltip,
  CheckboxField,
  Button,
  ButtonTheme,
  TextField,
} from '@cian/components';
import { TNotificationFrequencyValue } from '../utils/notificationFrequency';
import { mergeStyles } from '@cian/utils';
import { License } from '../../License';
import { FrequencyChange } from '../FrequencyChange';

export interface ISavedSearchFormProps {
  title: string;
  isErrorResponse: boolean;
  isLoader: boolean;
  emailError: string;
  email: string;
  notificationFrequency: TNotificationFrequencyValue;
  isNews: boolean;
  pushSubscribed: boolean;
  isPushCheck: boolean;
  isAuthenticated: boolean;

  handleTitleChange(value: string): void;
  handleEmailChange(value: string): void;
  handleNewsChange(value: boolean): void;
  handlePushChange(value: boolean): void;
  handleFrequencyChange(value: TNotificationFrequencyValue): void;
  handleSave(e: React.SyntheticEvent): void;
}

export class SavedSearchForm extends React.Component<ISavedSearchFormProps, {}> {
  public render() {
    return (
      <form className={styles['form']}>
        <div className={styles['field']}>
          <Label label="Назовите поиск">
            <TextField
              value={this.props.title}
              invalid={this.props.isErrorResponse}
              disabled={this.props.isLoader}
              onValueChange={this.props.handleTitleChange}
              inputStyle={styles['input']}
              placeholder="Например, двушка в Сокольниках"
            />
          </Label>
        </div>
        <div {...mergeStyles(
          styles['field'],
          this.props.isAuthenticated ? styles['field-empty'] : '',
        )}>
          <Label label={
            this.props.isAuthenticated
              ? ''
              : 'Управлять подписками можно будет в личном кабинете'
          }>
            <Tooltip tooltipStyles={styles['tooltip']} tooltipContent={this.props.emailError} position={0}>
              <TextField
                invalid={Boolean(this.props.emailError || this.props.isErrorResponse)}
                value={this.props.email}
                onValueChange={this.props.handleEmailChange}
                disabled={this.props.isLoader}
                inputStyle={styles['input']}
                placeholder="Email для новых объявлений"
              />
            </Tooltip>
          </Label>
        </div>
        <div className={`${styles['field']} ${styles['field_notification']}`}>
          <Label label="Частота уведомлений">
            <FrequencyChange
              value={this.props.notificationFrequency}
              setFrequency={this.props.handleFrequencyChange}
            />
          </Label>
          {
            this.props.isLoader &&
              <div className={styles['field_loader']} />
          }
        </div>
        <CheckboxField
          containerStyle={styles['checkbox']}
          value={this.props.isNews}
          onValueChange={this.props.handleNewsChange}
          label="Хочу получать новости Cian.ru"
        />
        {
          this.props.pushSubscribed &&
            <CheckboxField
              containerStyle={styles['checkbox']}
              value={this.props.isPushCheck}
              onValueChange={this.props.handlePushChange}
              label="Включить push-уведомления"
            />
        }
        <Button
          onClick={this.props.handleSave}
          theme={ButtonTheme.BLUE_SOLID}
          type="submit"
          buttonStyle={
            mergeStyles([
              styles['button'],
              this.props.isLoader && styles['button--loader'],
            ]).className
          }
        >
          <span className={styles['button-text']}>
            Следить за обновлениями
          </span>
        </Button>
        {
          this.props.isAuthenticated
            ? <div className={styles['info']}>Управлять подписками можно в личном кабинете</div>
            : <License />
        }
      </form>
    );
  }
}
