import { TABTestsActions } from './actions';

export default (state = [], action: TABTestsActions) => {

  switch (action.type) {
    case 'GetABTestsSuccess': {
      return action.experiments;
    }

    default:
      return state;
  }
};
