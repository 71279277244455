import { INewbuildingChat } from './model';
import { TNewbuildingChatActions } from './actions';

const initialState = {
  enabled: false,
  bannerAvailable: false,
  isOpen: false,
};

export function newbuildingChatReducer(
  state: INewbuildingChat = initialState,
  action: TNewbuildingChatActions,
): INewbuildingChat {
  switch (action.type) {
    case 'NewbuildingChatToggleOpen':
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    default:
      return state;
  }
}
