import { IReadyBusinessGroupItem } from '../../../../../../../../shared/repositories/announcements/entities/AnnouncementReferences/ReadyBusinessGroupItem';
import { IAssociateStringWithNumberService } from '../IAssociateStringWithNumberService';

export class AssociateGroupTypeWithHashService implements IAssociateStringWithNumberService {
  private static getHash(str: string): number {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0;
    }

    return hash;
  }

  private readonly associationsMapper: Record<string | number, string | number>;

  public constructor(groups: IReadyBusinessGroupItem[]) {
    this.associationsMapper = groups.reduce((accumulator, group) => {
      const hash = AssociateGroupTypeWithHashService.getHash(group.groupType);

      accumulator[group.groupType] = hash;
      accumulator[hash] = group.groupType;

      return accumulator;
    }, {} as Record<string | number, string | number>);
  }

  public get<Key extends string | number>(key: Key): Key extends string ? number : string;
  public get(key: string | number): string | number {
    return this.associationsMapper[key];
  }
}
