import { IOfferDetail } from '../../../api/models/offer_card';
import { ObjectType } from '../model';
import { parseObjectType } from './base_info_parser';

export function responseToTenantsComment(offer: IOfferDetail): string | undefined {
  const objectType = parseObjectType(offer);
  if (!objectType || objectType !== ObjectType.ShoppingArea) {
    return;
  }

  const { building } = offer;

  return building && building.tenants;
}
