import * as React from 'react';

import { ButtonCheckbox, Label } from '@cian/components';

import ButtonCheckboxContainer from '../../../ui/button_checkbox_container/button_checkbox_container';
import {
  DealType,
  IFieldProps,
  isFlatObjectGroup,
  isSuburbanObjectGroup,
  IsVisibleCheck,
  rentTimeDict,
} from '../field';

const styles = require('../field.css');

const longRentTimes: Array<{id: string, text: string}> = [
  {
    id: rentTimeDict.LongGtYear,
    text: 'От года',
  },
  {
    id: rentTimeDict.LongLtYear,
    text: 'Несколько месяцев',
  },
];

export class Component extends React.Component<IFieldProps, object> {

  public shouldComponentUpdate(nextProps: IFieldProps) {
    const { filter: { rentTime: next } } = nextProps;
    const { filter: { rentTime: current } } = this.props;
    return current !== next;
  }

  public render() {
    const { filter: { rentTime }, actions: { setRentTime } } = this.props;
    const buttonCheckboxStyle = [
      styles.buttonCheckbox,
      { flexGrow: 0 },
    ];

    return (
      <div className={styles.field} data-mark="FieldRentTimeLong">
        <Label label="Срок аренды">
          <ButtonCheckboxContainer>
            {longRentTimes.map(longRentTime => (
              <ButtonCheckbox
                mobile
                checked={rentTime === longRentTime.id}
                onChange={e => {
                  const checked = (e.target as HTMLInputElement).checked;

                  setRentTime(checked ? longRentTime.id : rentTimeDict.Long);
                }}
                key={longRentTime.id}
                buttonCheckboxStyle={buttonCheckboxStyle}
                wide
              >
                {longRentTime.text}
              </ButtonCheckbox>
            ))}
          </ButtonCheckboxContainer>
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter =>
filter.dealType === DealType.Rent &&
(isFlatObjectGroup(filter) || isSuburbanObjectGroup(filter)) && filter.rentTime !== rentTimeDict.Short;
