export type FixedComponentsState = {
  appBannerOn: boolean;
  searchButtonFixed: boolean;
  showContactsButtonFixed: boolean;
  showContactsPopupVisible: boolean;
};

export const defaultFixedComponentsState: FixedComponentsState = {
  appBannerOn: false,
  searchButtonFixed: false,
  showContactsButtonFixed: false,
  showContactsPopupVisible: false,
};
