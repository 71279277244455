import * as React from 'react';
import { IStyleConfig, mergeStyles } from '../utils/style';

import { ICheckboxFieldStyles } from './checkbox_field.css';

export interface ICheckboxFieldProps extends React.Props<CheckboxField> {
  containerStyle?: IStyleConfig;
  invalid?: boolean;
  disabled?: boolean;
  label: React.ReactNode;
  labelStyle?: IStyleConfig;
  mobile?: boolean;
  onValueChange: (value: boolean) => void;
  required?: boolean;
  value: boolean;
  styles?: ICheckboxFieldStyles;
  dataMark?: string;
}

export default class CheckboxField extends React.Component<ICheckboxFieldProps, {}> {
  private styles: ICheckboxFieldStyles;

  public constructor(props: ICheckboxFieldProps) {
    super(props);

    this.styles = require('./checkbox_field.css');
  }

  public render() {
    const { value, required, invalid, disabled, mobile } = this.props;

    return (
      <span
        {...mergeStyles(
          this.styles['container'],
          !!value ? this.styles['checked'] : '',
          required ? this.styles['required'] : '',
          invalid ? this.styles['invalid'] : '',
          disabled ? this.styles['disabled'] : '',
          mobile ? this.styles['mobile'] : '',
          this.props.containerStyle,
        )}
        data-mark={this.props.dataMark}
      >
        <label
          {...mergeStyles(
            this.styles['label'],
            mobile ? this.styles['mobile'] : '',
            this.props.labelStyle,
          )}
          onClick={() => this.handleClick()}
        >
          {this.props.label}
        </label>
      </span>
    );
  }

  private handleClick() {
    if (this.props.disabled) {
      return;
    }

    this.props.onValueChange(!this.props.value);
  }
}
