import { Location } from 'history';

import { Response } from '../../../network_framework/request';
import { IHeaderData } from '../../api/api';
import { Orientation } from '../../utils/device_detector';
import { isClient } from '../../utils/isomorphic';
import { toPromise } from '../../utils/streams';
import { setFavoritesCount } from '../favorites/actions';
import { ReduxState, TTypedThunkAction } from '../model';

import { DeviceType, IRedirectData, IUserRegion } from './model';

export type TCommonActions =
  | { type: 'GetHeaderDataSuccess'; payload: IHeaderData }
  | { type: 'GetHeaderDataPending' }
  | { type: 'SetRedirectData'; data: IRedirectData }
  | { type: 'SetUserRegion'; payload: IUserRegion }
  | { type: 'SetСookieRegion'; payload: IUserRegion }
  | { type: 'SetFirstClientLoad'; payload: boolean }
  | { type: 'SetLastUrl'; payload: string }
  | { type: 'SetOrienation'; payload: Orientation }
  | { type: 'ClearHeaderData' }
  | { type: 'newPageEventSent' };

export function getHeaderData(customGetHeaderHost?: string): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    dispatch<TCommonActions>({ type: 'GetHeaderDataPending' });
    const {
      headerApiUrl,
      common: { detectedRegion, userRegion },
      logger,
    } = getState();

    return toPromise(api.getHeaderData(userRegion || detectedRegion, headerApiUrl || customGetHeaderHost))
      .then(headerData => {
        const {
          data: {
            email,
            displayUsername,
            isAuthenticated,
            favoritesCount = 0,
            storedFiltersCount = 0,
            userId,
            firstName,
            lastName,
            phone,
            isProfi,
            villagesUrl,
            lkType,
            kpnWebimChecksum,
          },
          status,
        } = headerData.result;

        if (status && status.toLowerCase() === 'ok') {
          dispatch(setFavoritesCount(favoritesCount));
          dispatch<TCommonActions>({
            type: 'GetHeaderDataSuccess',
            payload: {
              email,
              firstName,
              lastName,
              phone,
              displayUsername,
              isAuthenticated,
              storedFiltersCount,
              userId,
              isProfi,
              villagesUrl,
              lkType,
              kpnWebimChecksum,
            },
          });
        } else {
          logger?.error(`Wrong response: ${status}`);
        }
      })
      .catch(err => {
        logger?.error(err);
      });
  };
}

// tslint:disable-next-line:no-any
export function checkRedirectData(res: Response<any>): TCommonActions {
  const {
    result: { data },
  } = res;

  return {
    type: 'SetRedirectData',
    data: data && data.redirectData,
  };
}

export function setLastUrlPure(lastUrl: string): TCommonActions {
  return { type: 'SetLastUrl', payload: lastUrl };
}

export function setLastUrl(location?: Location): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState) => {
    const pathname = (location && location.pathname) || '';
    const search = (location && location.search) || '';
    const { requestUrl } = getState().common;

    const lastUrl = isClient ? `${window.location.origin}${pathname}${search}` : requestUrl;
    dispatch(setLastUrlPure(lastUrl));

    return Promise.resolve();
  };
}

export function clearHeaderData(): TCommonActions {
  return { type: 'ClearHeaderData' };
}

export function setUserRegion(region: IUserRegion): TCommonActions {
  return { type: 'SetUserRegion', payload: region };
}

export function setCookieRegion(region: IUserRegion): TCommonActions {
  return { type: 'SetСookieRegion', payload: region };
}

export function setFirstClientLoad(value: boolean): TCommonActions {
  return { type: 'SetFirstClientLoad', payload: value };
}

export function gteTabletSelector(state: ReduxState) {
  return gteTablet(state.common.deviceType);
}

export function gteTablet(deviceType: DeviceType) {
  return deviceType === 'tablet' || deviceType === 'desktop';
}

export function setOrienation(orientation: Orientation): TCommonActions {
  return {
    type: 'SetOrienation',
    payload: orientation,
  };
}

export function newPageEventSent(): TCommonActions {
  return { type: 'newPageEventSent' };
}
