import { ButtonCheckbox } from '@cian/components';

import * as React from 'react';

import { getIsElectronicTradingEnabled } from '../../../../shared/utils/isElectronicTradingEnabled';
import { DeviceType } from '../../../redux/common/model';
import * as filterActions from '../../../redux/filters/actions';
import { DealType, FilterState, RentTime, TObjectType, suburbanObjectType } from '../../../redux/filters/model';
import Block from '../../../ui/block/block';
import ButtonCheckboxContainer from '../../../ui/button_checkbox_container/button_checkbox_container';
import { Field } from '../../field';
import * as fields from '../../fields/';
import {
  IsVisibleCheck,
  createFieldWithPropsTransformation,
  isBusiness,
  isCommercialObjectGroup,
  isCoworkingFilters,
  isSpecialCommercialObjectGroup,
  rentTimeDict,
} from '../field';

const styles = require('../field.css');

interface ITermsProps {
  actions: typeof filterActions;
  agentNoFee: boolean;
  dealType: DealType;
  isByHomeOwner: boolean;
  isCommercial: boolean;
  fromRepresentative: boolean;
  rentTime: RentTime;
  selectedObjectTypes: TObjectType[];
  viewMode?: DeviceType;
  withDeposit?: boolean;
  isSpecialCommercial?: boolean;
  correctRegion?: boolean;
  isCommercialOwnerFilersAvailable?: boolean;
  isBlockTermsVisible: boolean;
  isElectronicTradingVisible: boolean;
}

export const CORRECT_OFFREP_REGION_IDS = [
  -1 /* Москва и область */, 1 /* Москва */, -2 /* Спб и област */, 2 /* СПБ */, 4593, 4588,
  4608 /* Самарская область */, 4966 /* Самара */, 4820 /* Краснодар */, 4998 /* Сочи */, 4584 /* Краснодарский край */,
  4885 /* Нижний новгород */, 4596 /* Нижненов. область */, 176245 /* Уфа */, 4560 /* Башкортостан */,
  4777 /* Казань */, 4618 /* Респблика Татарстан */, 4959 /* Ростов */, 4606 /* Ростовская область */,
];

export class Component extends React.PureComponent<ITermsProps, object> {
  public render() {
    const {
      actions,
      agentNoFee,
      dealType,
      isByHomeOwner,
      isCommercial,
      rentTime,
      selectedObjectTypes,
      withDeposit,
      fromRepresentative,
      isSpecialCommercial,
      correctRegion,
      isCommercialOwnerFilersAvailable,
      isBlockTermsVisible,
      isElectronicTradingVisible,
    } = this.props;
    const buttonCheckboxStyle = [styles.buttonCheckbox];

    /**
     * @todo Удалить строку с RS
     * @description Данный функционал появился в задаче CD-98438,
     * будет удалён в задаче CD-103422
     */
    const isBlockTermsDisabled = isCommercialOwnerFilersAvailable && isCommercial && dealType === DealType.Sale;
    const isBlockTermsAvailable = !isBlockTermsDisabled && isBlockTermsVisible;

    if (!isBlockTermsAvailable && !isElectronicTradingVisible) {
      return null;
    }

    return (
      <Block dataMark="BlockTerms" title="Условия сделки">
        <div className={styles.fieldBlock}>
          {isBlockTermsAvailable && (
            <div className={styles.field}>
              <ButtonCheckboxContainer>
                {isCommercial && isSpecialCommercial && correctRegion && !isCommercialOwnerFilersAvailable && (
                  <ButtonCheckbox
                    mobile
                    buttonCheckboxStyle={buttonCheckboxStyle}
                    checked={fromRepresentative}
                    id="from-representative"
                    name="from-representative"
                    onValueChange={actions.setRepresentative}
                  >
                    От оф. представителя
                  </ButtonCheckbox>
                )}
                {dealType === DealType.Rent &&
                  rentTime === rentTimeDict.Long &&
                  !selectedObjectTypes.includes(suburbanObjectType.Stead) && (
                    <ButtonCheckbox
                      mobile
                      buttonCheckboxStyle={buttonCheckboxStyle}
                      checked={agentNoFee}
                      id="agent-no-fee"
                      name="agent-no-fee"
                      onValueChange={actions.setAgentNoFee}
                    >
                      Без комиссии
                    </ButtonCheckbox>
                  )}
                {!isCommercial && (
                  <ButtonCheckbox
                    mobile
                    buttonCheckboxStyle={buttonCheckboxStyle}
                    checked={isByHomeOwner}
                    id="from-owner"
                    name="from-owner"
                    onValueChange={actions.setFromOwner}
                  >
                    От собственника
                  </ButtonCheckbox>
                )}
                {dealType === DealType.Rent && rentTime === rentTimeDict.Long && !isCommercial && (
                  <ButtonCheckbox
                    mobile
                    buttonCheckboxStyle={buttonCheckboxStyle}
                    checked={withDeposit === undefined ? false : !withDeposit}
                    id="no-deposit"
                    name="no-deposit"
                    onValueChange={actions.setNoDeposit}
                  >
                    Без залога
                  </ButtonCheckbox>
                )}
              </ButtonCheckboxContainer>
            </div>
          )}
          {isElectronicTradingVisible && (
            <div className={styles.field}>
              <Field field={fields.electronicTrading} />
            </div>
          )}
        </div>
      </Block>
    );
  }
}

const isVisibleBlockTerms = (filter: FilterState) => {
  const isCommercialSale = filter.dealType === DealType.Sale && isCommercialObjectGroup(filter);

  // условие из общего контейнера вынесено сюда
  const isRent = filter.dealType === DealType.Rent;

  if (isCommercialSale || !isRent) {
    return false;
  }

  const correctRegion = CORRECT_OFFREP_REGION_IDS.includes(filter.region);

  return (!filter.withNewobject && !isCommercialSale) || (isSpecialCommercialObjectGroup(filter) && correctRegion);
};

const isVisibleElectronicTrading = (filter: FilterState) => {
  const isElectronicTradingEnabled = getIsElectronicTradingEnabled();
  // Фильтр доступен для продажи и аренды для всех типов объектов, кроме коворкингов, готового бизнеса и арендного бизнеса
  const isCoworking = isCoworkingFilters(filter);
  const isReadyBusiness = isBusiness(filter);

  return isElectronicTradingEnabled && !isReadyBusiness && !isCoworking;
};

export const isVisible: IsVisibleCheck = filter => {
  const isElectronicTradingVisible = isVisibleElectronicTrading(filter);
  const isBlockTermsVisible = isVisibleBlockTerms(filter);

  return isBlockTermsVisible || isElectronicTradingVisible;
};

// eslint-disable-next-line import/no-default-export
export default createFieldWithPropsTransformation(
  ({ filter, actions, viewMode, isCommercialOwnerFilersAvailable }): ITermsProps => ({
    actions,
    agentNoFee: filter.agentNoFee,
    dealType: filter.dealType,
    isByHomeOwner: filter.isByHomeOwner,
    isCommercial: isCommercialObjectGroup(filter),
    isSpecialCommercial: isSpecialCommercialObjectGroup(filter),
    rentTime: filter.rentTime,
    selectedObjectTypes: filter.selectedObjectTypes,
    viewMode,
    withDeposit: filter.withDeposit,
    fromRepresentative: !!filter.fromRepresentative,
    correctRegion: CORRECT_OFFREP_REGION_IDS.includes(filter.region),
    isCommercialOwnerFilersAvailable,
    isBlockTermsVisible: isVisibleBlockTerms(filter),
    isElectronicTradingVisible: isVisibleElectronicTrading(filter),
  }),
  ({ filter }) => isVisible(filter),
)(Component);
