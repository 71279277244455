import {
  GET_MORTGAGE_CALCULATOR_WIDGET_FAILURE,
  GET_MORTGAGE_CALCULATOR_WIDGET_PENDING,
  GET_MORTGAGE_CALCULATOR_WIDGET_SUCCESS,
  TGKPageActions,
} from './actions';
import { defaultGKPageState, IGKPage } from './model';
import { responseToGK } from './reponse_parser/reponse_parser';

type Reducer = (state: IGKPage, action: TGKPageActions) => IGKPage;

const GKPageReducer: Reducer = (state = defaultGKPageState, action) => {
  switch (action.type) {
    case 'GetGKSuccess':
      const { seoData, newobject } = action.payload;
      return {
        ...state,
        GK: responseToGK(newobject[0]),
        seoData: seoData,
        isFetching: false,
      };

    case 'GetGKPending':
      return {
        ...state,
        isFetching: true,
      };

    case 'GetGKFailure':
      return {
        ...state,
        isFetching: false,
      };

    case 'GetGKMVKSuccess':
      return {
        ...state,
        GKMVK: action.payload,
        isFetching: false,
      };

    case 'GetGKMVKPending':
      return {
        ...state,
        GKMVK: undefined,
        isFetching: true,
      };

    case 'GetGKMVKFailure':
      return {
        ...state,
        isFetching: false,
      };
    case 'ChangeGkFavoriteStatus':
      if (!state.GK || state.GK.id === '') { return state; }

      return {
        ...state,
        GK: {
          ...state.GK,
          is_favorite: action.payload.state,
        },
      };

    case GET_MORTGAGE_CALCULATOR_WIDGET_PENDING:
      return {
        ...state,
        isFetching: true,
      };

    case GET_MORTGAGE_CALCULATOR_WIDGET_SUCCESS:
      return {
        ...state,
        mortgageCalculatorWidget: action.payload,
        isFetching: false,
      };

    case GET_MORTGAGE_CALCULATOR_WIDGET_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default GKPageReducer;
