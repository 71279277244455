import { connect } from 'react-redux';
import { setInfrastructure } from '../../../../redux/filters/actions';
import { IMapInfrastructure } from '../../../../redux/filters/model';
import { ReduxState, TTypedThunkDispatch } from '../../../../redux/model';
import {
  IInfrastructureItemDispatchProps,
  IInfrastructureItemOwnProps,
  IInfrastructureItemStateProps,
  Item,
} from './Item';

function mapStateToProps(state: ReduxState, ownProps: IInfrastructureItemOwnProps): IInfrastructureItemStateProps {
  return {
    checked: !!state.filter.infrastructure[ownProps.name],
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch): IInfrastructureItemDispatchProps {
  return {
    onClick(infrastructureType: Extract<keyof IMapInfrastructure, string>) {
      dispatch(setInfrastructure(infrastructureType));
    },
  };
}

export const ItemContainer = connect<
  IInfrastructureItemStateProps,
  IInfrastructureItemDispatchProps,
  IInfrastructureItemOwnProps
  >(mapStateToProps, mapDispatchToProps)(Item);
