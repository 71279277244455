import { ELKType } from '../../api/api';
import { Orientation } from '../../utils/device_detector';

/** Общий тип. Не относится к этому хранилищу. */
export enum ResourceStatus {
  Initial,
  Pending,
  Success,
  Error,
  UpdatedOnClient,
}

export interface IRedirectData {
  location: string;
}

export interface IUserRegion {
  name: string;
  subdomain: string;
  id: number;
  parentId?: number;
}

export type DeviceType = 'phone' | 'tablet' | 'desktop';

export type CommonState = {
  displayUsername: string;
  isAuthenticated: boolean;
  isProfi?: boolean;
  authInfoInitialized: boolean;
  storedFiltersCount?: number;
  redirectData?: IRedirectData;
  lastUrl?: string;
  detectedRegion: IUserRegion;
  // IP - адрес клиента.
  detectedIP: string;
  // "Гео" пользователя (модель)
  userRegion?: IUserRegion;
  // "Гео" пользователя, которое он когда-то подтвердил. Храним для SSR.
  cookieRegion?: IUserRegion;
  // Урл запроса, который пришел ноде. Храним для использования при редиректах внутри компонентов при SSR.
  requestUrl: string;
  isFirstClientLoad: boolean; // находимся ли мы при первом исполнении на клиенте.
  deviceType: DeviceType;
  orientation: Orientation;
  userEmail?: string;
  userId?: number;
  firstName?: string;
  lastName?: string;
  phone?: string;
  /* ссылка на выдачу КП */
  kpUrl?: string;
  /* ссылка на каталог КП в боковом меню */
  villagesUrl?: string;
  /* Отправлен ли ивент newpage (карта) */
  hasNewPageEventSent?: boolean;
  //Находимся ли мы на сабдомене ЖК
  isNewbuildingSubdomain: boolean;
  homeIsOnOtherDomain: boolean;
  geoSubdomain: string;
  lkType: ELKType | null;
  // Новые ловушки на выдачу КП из выдачи объявлений
  isCountrysideTrapEnabled?: boolean;
  knAuctionCompanies: number[];
  isDomrfPromo: boolean;
  domrfPromoUrl: string;
  // Использовать cianUserId или realtyUserId
  kpnWebimChecksum?: string | null;
  legalLink: string;
  isElectronicTradingEnabled: boolean;
};

export const defaultCommonState: CommonState = {
  displayUsername: '',
  isAuthenticated: false,
  storedFiltersCount: 0,
  authInfoInitialized: false,
  isProfi: false,
  detectedRegion: {
    name: 'Москва',
    subdomain: 'www',
    id: 1,
  },
  requestUrl: '',
  detectedIP: '',
  isFirstClientLoad: false,
  deviceType: 'phone',
  orientation: 'portrait',
  hasNewPageEventSent: false,
  isNewbuildingSubdomain: false,
  homeIsOnOtherDomain: false,
  geoSubdomain: 'www',
  lkType: null,
  knAuctionCompanies: [],
  isDomrfPromo: false,
  domrfPromoUrl: '',
  legalLink: '',
  isElectronicTradingEnabled: false,
};
