/* eslint-disable max-lines,sort-keys */

import { createContext as createAuthenticationWidgetContext } from '@cian/authentication-widget';
import {
  IWSUniformResourceIdentifierDeclaration,
  parseURI as parseWSURI,
} from '@cian/chats-api/lib/browser/websocket/uri';
import { ICommonHttpClassConfig } from '@cian/chats-api/lib/shared/http/common_class';
import {
  INotificationsCountContext,
  createContext as createContextNotificationsCountContext,
} from '@cian/chats-notifications-connect';
import { init as initConfig, setup as setupConfig } from '@cian/config/browser';
import { IHTTPCompletedResponse, IHTTPRequestConfig, IUserLayersConfig, parseURI } from '@cian/http-api/shared';
import * as cianMetrics from '@cian/metrics';

import * as React from 'react';
import { render } from 'react-dom';
import { Router, browserHistory, match } from 'react-router';

import { prepareApplication } from '../core/browser/utils/app';
import { Providers, createReduxStore, getRoutes } from '../mobile_website';
import { MobileWebsiteApi } from '../mobile_website/api/api';
import { FixedComponentsStore } from '../mobile_website/components/fixed/store';
import { setOrienation } from '../mobile_website/redux/common/actions';
import { ReduxState } from '../mobile_website/redux/model';
import { isGkList } from '../mobile_website/redux/offers_list/actions';
import { ICacheStore } from '../mobile_website/utils/cache';
import { IAppContext } from '../mobile_website/utils/context_provider';
import { CookiesUtils } from '../mobile_website/utils/cookies';
import { getOrientation, isInternetExplorer, isWinPhone } from '../mobile_website/utils/device_detector';
import { IndexedDBStore } from '../mobile_website/utils/indexed_db_store';
import { fixZoomWhenFocusOnIOS } from '../mobile_website/utils/ios_zoom_fix';
import { LocalStorageStore } from '../mobile_website/utils/local_storage_store';
import { MemoryStore } from '../mobile_website/utils/memory_store';
import { ClientMetrics } from '../mobile_website/utils/metrics/client';
import { NetworkLayer } from '../network_framework/network_layer';
import { UNIQUE_CONFIG_KEY } from '../shared/constants/config';
import { BrowserServerSideState } from '../shared/services/ServerSideState';
import { getUsersAuction } from '../shared/services/commercial-electronic-auction/getUsersAuction/getUsersAuction';
import { setAgentListsMapper } from '../shared/utils/agentListsMapper';
import { setIsCommercialMultiAdsBannerEnabled } from '../shared/utils/isCommercialMultiAdsBannerEnabled';
import { setIsCommercialNewVatTextEnabled } from '../shared/utils/isCommercialNewVatTextEnabled';
import { setIsElectronicTradingEnabled } from '../shared/utils/isElectronicTradingEnabled';
import { setIsNewAuthEnabled } from '../shared/utils/isNewAuthEnabled';

import { IClientConfig, defaultConfig } from './DEPRECATED/config';

setupConfig({
  settingsKey: UNIQUE_CONFIG_KEY,
});

const configNext = initConfig();

prepareApplication({ config: configNext }).then(async applicationContext => {
  const { httpApi, logger } = applicationContext;

  const win = window as any;

  const config: IClientConfig = win.config || defaultConfig;
  const network = new NetworkLayer('/', config.timeout, [], undefined, httpApi, config);
  const api = new MobileWebsiteApi(network, {
    config: configNext,
    chats: config.chats,
    demand: config.demand,
    memoryStore: new MemoryStore(50),
    longTermStore: ((): ICacheStore | undefined => {
      if (isWinPhone() || isInternetExplorer()) {
        //IE странно работает с параметром version при создании IndexedDB
        //пока в IE используем localstorage для кэширования, но с IE стоит разобраться.
        return new LocalStorageStore();
      }
      try {
        let version = 1;

        if (config.indexedDBversion) {
          const versionString = config.indexedDBversion;

          if (!isNaN(+versionString)) {
            version = +versionString;
          }
        }

        return new IndexedDBStore('mobile_site_api', version);
      } catch (err) {
        try {
          return new LocalStorageStore();
          // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (err) {
          return new MemoryStore(50);
        }
      }
    })(),
  });

  const authenticationWidgetInitialState = configNext.get<any>('authenticationWidgetInitialState');
  const authenticationWidgetContext = createAuthenticationWidgetContext(
    {
      config: configNext,
      httpApi,
      logger,
      subdomain: configNext.get<string>('subdomain') || 'www',
    },
    authenticationWidgetInitialState,
  );

  const httpApiAdapter = {
    makeRequest: (
      requsetConfig: IHTTPRequestConfig,
      ecnhancersConfig?: IUserLayersConfig,
    ): Promise<IHTTPCompletedResponse> =>
      httpApi.httpRequest({ ...requsetConfig, withCredentials: true }, ecnhancersConfig),
  };

  const chatsConfig:
    | {
        httpConfig: ICommonHttpClassConfig;
        wsApiBaseUrl: IWSUniformResourceIdentifierDeclaration;
        timeout: number;
      }
    | undefined = config.chats
    ? {
        httpConfig: { baseUri: parseURI(config.chats.httpApiBrowserUrl), client: httpApiAdapter },
        timeout: config.chats.timeout,
        wsApiBaseUrl: parseWSURI(config.chats.wsApiBaseUrl) as IWSUniformResourceIdentifierDeclaration,
      }
    : undefined;

  let chatsUnreadContext: INotificationsCountContext | undefined;

  if (chatsConfig) {
    chatsUnreadContext = createContextNotificationsCountContext(chatsConfig.httpConfig, chatsConfig.wsApiBaseUrl);
  }

  const usersAuction = await getUsersAuction({ httpApi, logger, config: configNext });

  /**
   * @todo Удалить строку с RS
   * @description Данный функционал появился в задаче CD-143100, будет удалён в задаче CD-143261
   */
  setIsCommercialNewVatTextEnabled(!!configNext.get<boolean>('Commercial.NewVatText.Enabled'));

  /**
   * @todo Удалить файл с RS
   * @description Данный функционал появился в CD-154318, будет удален в CD-154562
   */
  setIsCommercialMultiAdsBannerEnabled(!!configNext.get<boolean>('Commercial.MultiAdsBanner.Enabled'));

  /**
   * @todo Удалить код с RS
   * @description Данный функционал появился в задаче CD-197673, будет удалён в задаче CD-197395
   * @description Доступы вычисляются на ноде, сюда прокидывается уже готовое значение
   */
  setIsElectronicTradingEnabled(win.storeState.common.isElectronicTradingEnabled);

  /**
   * @todo Удалить файл с RS
   * @description Данный функционал появился в CD-223451
   */
  setIsNewAuthEnabled(!!configNext.get<boolean>('frontendMobileWebsite.newAuthEnabled'));

  /**
   * @description Задает маппер для вычисления photoLabels
   */
  const textsMap = configNext.get<Record<string, string>>('sellingTypes.agentLists');
  setAgentListsMapper(textsMap);

  const browserServerSideState = new BrowserServerSideState(applicationContext);

  const store = createReduxStore(api, logger, browserHistory, {
    ...win.storeState,
    chats: {
      ...win.storeState.chats,
      unreadContext: chatsConfig ? (chatsUnreadContext as INotificationsCountContext) : undefined,
    },
    common: {
      ...win.storeState.common,
      orientation: getOrientation(),
      knAuctionCompanies: usersAuction.userIds,
      headerApiUrl: configNext.get<string>('headerPython.apiUrl'),
    },
    hideOffer: {
      ...win.storeState.hideOffer,
      lkUrl: (config.hiddenObjects && config.hiddenObjects.lkUrl) || '',
    },
    logger: null,
    authenticationWidgetContext,
    authentication: {
      ...win.storeState.authentication,
      disabledSocials: configNext.get<string[]>('authentication.disabledSocials') || [],
    },
    soprApi: configNext.get('soprApi.browserUrl'),
    headerApiUrl: configNext.get<string>('headerPython.apiUrl'),
    queryClient: browserServerSideState.queryClient,
  });

  const fixedComponentsStore = new FixedComponentsStore();
  const cookiesUtils = new CookiesUtils({ path: '/', domain: 'cian.ru' }, document.cookie);

  function updateOrientation(): void {
    store.dispatch(setOrienation(getOrientation()));
  }
  window.addEventListener('resize', updateOrientation);

  if (win.assetsBaseUrl) {
    __webpack_public_path__ = win.assetsBaseUrl;
  }

  const routes = getRoutes(browserHistory, location.href, api, chatsConfig, store, logger);

  function getRouteName(storeState: ReduxState): string {
    const { routeName } = storeState.routing;
    if (routeName === 'listing') {
      return isGkList(storeState.searchList) ? 'gk_list' : 'offers_list';
    }

    return routeName;
  }

  function collectMetrics(metrics: ClientMetrics, routeName: string): void {
    metrics.waitMarksAndMeasure('request-start', 'navigationStart', 'fetchStart');
    metrics.waitMarksAndMeasure('backend', 'fetchStart', 'responseStart');
    metrics.waitMarksAndMeasure('before-head', 'responseStart', 'head-start');

    if (routeName === 'map') {
      metrics.waitMarksAndMeasure('from-fetch-to-usable', 'fetchStart', 'map-usable');
      metrics.waitMarksAndMeasure('from-fetch-to-full', 'fetchStart', 'map-usable');
      metrics.waitMarksAndMeasure('from-head-to-usable', 'head-start', 'map-usable');
      metrics.waitMarksAndMeasure('from-head-to-full', 'head-start', 'map-usable');
    } else {
      metrics.waitMarksAndMeasure('from-fetch-to-usable', 'fetchStart', 'application-start');
      metrics.waitMarksAndMeasure('from-fetch-to-full', 'fetchStart', 'loadEventEnd');
      metrics.waitMarksAndMeasure('from-head-to-usable', 'head-start', 'application-start');
      metrics.waitMarksAndMeasure('from-head-to-full', 'head-start', 'loadEventEnd');
    }

    metrics.waitMarksAndMeasure('from-head-to-script', 'head-start', 'script-start');
    metrics.waitMarksAndMeasure('from-script-to-match', 'script-start', 'match-start');
    metrics.waitMarksAndMeasure('from-match-to-render', 'match-start', 'render-start');
  }

  if (window.performance && performance.mark) {
    performance.mark('match-start');
  }

  match({ routes, history: browserHistory }, (error, redirectLocation, renderProps) => {
    const state = store.getState();
    const routeName = getRouteName(state);
    const { deviceType } = state.common;
    const prefix = `mobile_website.${deviceType}.${routeName}`;
    const metrics = new ClientMetrics({ prefix });

    /**
     * @deprecated
     */
    const legacyContext: IAppContext = {
      ...applicationContext,
      api,
      chats: chatsConfig
        ? { ...chatsConfig, unreadContext: chatsUnreadContext as INotificationsCountContext }
        : undefined,
      fixedComponentsStore,
      cookiesUtils,
      metrics,
    };

    fixZoomWhenFocusOnIOS(store.getState().userAgent);

    metrics.mark('render-start');

    render(
      <Providers
        context={applicationContext}
        legacyContext={legacyContext}
        serverSideState={browserServerSideState}
        store={store}
      >
        <Router {...renderProps} />
      </Providers>,
      document.getElementById('app') as HTMLElement,
      () => {
        cianMetrics.add('applicationStart');
        metrics.mark('application-start');
        setTimeout(() => {
          collectMetrics(metrics, routeName);
        });
      },
    );
  });
});
