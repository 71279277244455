import { get } from 'lodash';
import * as React from 'react';
import shouldUpdate from 'recompose/shouldUpdate';

export const onlyUpdateForPathes = (...pathes: Array<string[]>) => {
  return shouldUpdate((props: {}, nextProps: {}) => {
    return !pathes.every(path => get(props, path) === get(nextProps, path));
  });
};

export const onlyUpdateForPathsShallow = (paths: string[]) => {
  return shouldUpdate((props: IObject, nextProps: IObject) => {
    return !paths.every(path => props[path] === nextProps[path]);
  });
};

interface IObject {
  /* tslint:disable-next-line:no-any */
  [key: string]: any;
}

export function shallowEqual(first: IObject, second: IObject): boolean {
  const firstKeys = Object.keys(first);
  const secondKeys = Object.keys(second);
  const sameKeysCount = firstKeys.length === secondKeys.length;

  return sameKeysCount && firstKeys.every((key: string) => {
    return first[key] === second[key];
  });
}

export function isUpdate(newObj: IObject, oldObj: IObject): boolean {
  const newKeys = Object.keys(newObj);

  return newKeys.some((key: string) => newObj[key] !== oldObj[key]);
}

export interface IFileReaderEvent extends Event {
  target: IFileReaderEventTarget;
}

interface IFileReaderEventTarget extends EventTarget {
  result: string;
}

export interface IFileEventTarget extends EventTarget {
  files: FileList;
}

export type ReactComponent<Props> = React.ComponentClass<Props> | React.SFC<Props>;

export function transformProps<OuterProps, InnerProps>(transform: (outerProps: OuterProps) => InnerProps) {
  return (Component: ReactComponent<InnerProps>): React.ComponentClass<OuterProps> => {
    return class EnhancedComponent extends React.Component<OuterProps, object> {
      public static displayName = `transformProps(${Component.displayName || Component.name})`;

      public render() {
        return <Component {...transform(this.props)} />;
      }
    };
  };
}
