import { ca } from '@cian/analytics';
import { Filters16, SaveSearch16 } from '@cian/ui-kit';
import * as React from 'react';
import { connect } from 'react-redux';

import { goToAdvancedSearch, goToList, goToMap } from '../../redux/filters/actions';
import { FilterState } from '../../redux/filters/model';
import { resetMapState } from '../../redux/map_page/actions';
import { ReduxState, TTypedThunkDispatch } from '../../redux/model';
import { RouteName } from '../../redux/routing/model';
import { openSaveFilterPopupAction } from '../../redux/save_filter_popup/index';
import { mergeStyles } from '../../ui/cian_components/utils';
import Counter, { CounterSize, CounterTheme } from '../../ui/counter/counter';
import Icon from '../../ui/icon/icon';
import { eventOld, refineSearchClick } from '../../utils/analytics';
import { CookiesUtils, inject } from '../../utils/context_provider';
import { isIE } from '../../utils/device_detector';
import { trackListingSaveSearchClick } from './tracking';

const styles = require('./search.css');

//кука отвечает за то, что пользователю была показана призывная анимация на кнопке сохранить
//в следующий раз показывать через 30 дней
const ANIMATION_DONE_COOKIE = 'save_search_animation_done';

interface ISearchDispatchProps {
  goToList(): void;
  goToAdvancedSearch(): void;
  goToMap(isNewbuildingsMapMode?: boolean): void;
  openSaveFilterPopup(): void;
  resetMapState(): void;
}

interface ISearchStoreProps {
  userAgent: string;
  deviceType: string;
  isNotFoundPage?: boolean;
  filter: FilterState;
  isSavedSearchPage?: boolean;
}

interface ISearchOwnProps {
  link: string;
  advancedFiltersCount: number;
  routeName: RouteName;
}

interface ISearchOwnContextProps {
  cookiesUtils: CookiesUtils;
}

interface ISearchOwnState {
  isAnimated: boolean;
}

const NEW_BILDINGS_QUERY = 'newbuildings_search=true';
const hasQuery = (query: string) => window && window.location.search.includes(query);
export const handleShowMapAsList = (
  goToListAction: (openNewWindow?: boolean, isNewbuildingsMapMode?: boolean) => void,
) => {
  const isNewbuildingSearchEnabled = hasQuery(NEW_BILDINGS_QUERY);

  goToListAction(false, isNewbuildingSearchEnabled);
  eventOld('Skin')('LIST')('MAP');
};

interface ISearchProps extends ISearchDispatchProps, ISearchOwnProps, ISearchStoreProps, ISearchOwnContextProps {}

class Search extends React.Component<ISearchProps, ISearchOwnState> {
  public constructor(props: ISearchProps) {
    super(props);

    this.state = {
      isAnimated: false,
    };
  }

  public componentDidMount() {
    const isButtonAnimationAvailable = !this.props.cookiesUtils.get(ANIMATION_DONE_COOKIE);

    if (isButtonAnimationAvailable && !this.props.isSavedSearchPage) {
      this.setState({
        isAnimated: true,
      });

      ca('event', {
        name: 'oldevent',
        category: 'Listing',
        action: 'pulse_saved_search',
        label: '',
      });

      this.props.cookiesUtils.set(ANIMATION_DONE_COOKIE, 'rawr', 60 * 60 * 24 * 30); // 30 дней
    }
  }

  public render() {
    const { props } = this;

    if (props.isNotFoundPage) {
      // tslint:disable-next-line:no-any
      return null as any;
    }

    return (
      <div className={styles.search} >
        <a href={props.link} onClick={this.handleSearchClick} className={styles.content}>
          <span className={styles['button-content']}>
            <span className={styles['iconSearch']}><Filters16 color="primary_100" /></span>
            { this.props.deviceType === 'phone' ? 'Фильтры' : 'Уточнить поиск' }
            {props.advancedFiltersCount > 0 &&
            <Counter
              size={CounterSize.S}
              theme={CounterTheme.DARK_GRAY}
              styleConfig={
                props.advancedFiltersCount < 10
                  ? styles['count_with_one_symbol']
                  : styles['count']
              }
            >
              {props.advancedFiltersCount}
            </Counter>}
          </span>
        </a>
        { this.renderSaveButton() }
        {!isIE(props.userAgent) && this.getRightBlock(props.routeName)}
        {!isIE(props.userAgent) && <div className={styles.divider} /> }
      </div>
    );
  }

  private renderSaveButton() {
    const { isAnimated } = this.state;

    return (
      <button
        type="button"
        className={styles['content']}
        onClick={this.handleSaveSearchClick}
      >
        <span
          {...mergeStyles(
            styles['button-content'],
            isAnimated && styles['heartbeat'],
          )}
        >
          <span className={styles['save-icon']}>
            <SaveSearch16 color="primary_100" />
          </span>
          {this.props.deviceType === 'phone' ? 'Сохранить' : 'Сохранить поиск'}
        </span>
      </button>
    );
  }

  private handleSaveSearchClick = () => {
    if (this.props.routeName === 'listing') {
      trackListingSaveSearchClick();
    }

    this.props.openSaveFilterPopup();
  }

  private getRightBlock = (currentRoute: RouteName) => {
    let name: string;
    let text: string;
    let styleConfig: string;
    let onClick: () => void;

    switch (currentRoute) {
      case 'listing':
        name = 'ic-map';
        text = this.props.deviceType === 'phone' ? 'На карте' : 'Показать на карте';
        styleConfig = styles.iconMap;
        onClick = this.handleShowOnMapClick;
        break;

      case 'map':
        name = 'ic-list';
        text = this.props.deviceType === 'phone' ? 'Список' : 'Показать списком';
        styleConfig = styles.iconList;
        onClick = () => handleShowMapAsList(this.props.goToList);
        break;

      default:
        return null as any; //tslint:disable-line:no-any
    }

    return (
      <button type="button" className={styles.content} {...{ onClick }}>
        <span className={styles['button-content']}>
          <Icon {...{name, styleConfig}} />
          {text}
        </span>
      </button>
    );
  }

  private handleShowOnMapClick = () => {
    const { resetMapState: resetMapStateAction, goToMap: goToMapeAction } = this.props;

    resetMapStateAction();

    // queryString <-> store sync will be fixed in CD-23100
    const isWithNewobjects = hasQuery('with_newobject=1');
    const isNewbuildingSearchEnabled = hasQuery(NEW_BILDINGS_QUERY);
    const isNewbuildingsMapMode = isNewbuildingSearchEnabled || isWithNewobjects;

    goToMapeAction(isNewbuildingsMapMode);

    eventOld('Skin')('MAP')('LIST');
  }

  private handleSearchClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const { routeName } = this.props;

    if (routeName === 'map') {
      refineSearchClick('map');
    } else {
      refineSearchClick();
    }
    this.props.goToAdvancedSearch();
  }
}

export default connect<ISearchStoreProps, ISearchDispatchProps, ISearchOwnProps> (
  (state: ReduxState) => {
    const {
      userAgent,
      filter,
      common: { deviceType },
    } = state;

    const queryString = state.searchList.initialized ? state.searchList.queryString : '';

    return {
      filter,
      isNotFoundPage: state.isNotFound || state.searchList.isNotFound,
      userAgent,
      deviceType,
      isSavedSearchPage: queryString.includes('saved_search_id'),
    };
  },
  (dispatch: TTypedThunkDispatch) => ({
    goToList: (
        openNewWindow?: boolean, isNewbuildingsMapMode?: boolean,
      ) =>
      dispatch(
        goToList(openNewWindow, isNewbuildingsMapMode),
      ),
    goToMap: (isNewbuildingsMapMode?: boolean) => dispatch(goToMap(isNewbuildingsMapMode)),
    goToAdvancedSearch: () => dispatch(goToAdvancedSearch()),
    openSaveFilterPopup: () => dispatch(openSaveFilterPopupAction()),
    resetMapState: () => dispatch(resetMapState()),
  }),
)(inject(['cookiesUtils'])<ISearchStoreProps & ISearchDispatchProps & ISearchOwnProps>(Search));
