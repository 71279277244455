import * as React from 'react';

import { FilterState } from '../../../redux/filters/model';
import { shallowEqual } from '../../../utils/react_helpers';
import RangeComponent from '../../common/range/range';
import { isCommercialObjectType } from '../../common/select_helper';
import { IFieldProps, isFlatObjectGroup, IsVisibleCheck } from '../field';

import { Label } from '@cian/components';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {

  public shouldComponentUpdate(nextProps: IFieldProps) {
    const filter: FilterState = this.props.filter;
    const nextFilter: FilterState = nextProps.filter;

    return !shallowEqual(filter.sizeLiving, nextFilter.sizeLiving)
      || filter.dealType !== nextFilter.dealType;
  }

  public render() {
    const selectedObjectTypes = this.props.filter.selectedObjectTypes;
    const isCommercial = isCommercialObjectType(selectedObjectTypes);

    return (
      <div className={styles.label} data-mark="FieldLivingArea">
        <Label label="Жилая площадь">
          <RangeComponent
            field="sizeLiving"
            metric={!isCommercial ? 'м²' : undefined}
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => {
  const isFlatObjectType = isFlatObjectGroup(filter);
  return !filter.withNewobject && isFlatObjectType;
};
