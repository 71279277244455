import * as React from 'react';

import { ReadyBusinessAppointments } from '../ReadyBusinessAppointments';

import { useAllBusinessAppointmentsGroups } from './internal/hooks/useAllBusinessAppointmentsGroups';
import { AllBusinessAppointmentsProps } from './types';

export const AllBusinessAppointments = React.memo<AllBusinessAppointmentsProps>(function AllBusinessAppointments(
  props,
) {
  const { businessPossibleAppointments, values, setBusinessAppointments } = props;

  const groups = useAllBusinessAppointmentsGroups(businessPossibleAppointments);

  return (
    <ReadyBusinessAppointments groups={groups} setBusinessAppointments={setBusinessAppointments} values={values} />
  );
});
