import { groupBy, isEmpty, isEqual, omitBy, sortBy } from 'lodash';

//tslint:disable: no-any
export const someProp = (object: any, prop: any): boolean => {
  let result = false;

  for (let key in object) {
    if (object[key] != null) {
      result = result || object[key] === prop;
    }
  }

  return result;
};

export const deepEmpty = (obj: any): boolean => {
  const emptyPredicate =  (prop: any) => prop == null
    || isEqual(prop, {})
    || isEqual(prop, []);

  return isEmpty(omitBy(obj, emptyPredicate));
};

//tslint:enable: no-any

export function sortAndGroupByName<T extends { name?: string }>(array: T[]): { [key: string]: T[]; } {
  const filteredArray = array.filter(item => !!item.name);
  const sortedArray = sortBy(filteredArray, item => item.name && item.name.toLowerCase());
  return groupBy(sortedArray, item => item.name ? item.name[0] : 'А') || null;
}
