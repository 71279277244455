import { Label } from '@cian/components';
import * as React from 'react';

import { rentTimeDict } from '../../../redux/filters/dictionaries';
import { DealType, flatObjectType, TObjectType } from '../../../redux/filters/model';
import { Regions } from '../../../utils/regions';
import { SelectMobile } from '../../common/select/select';
import { createSelectOptions } from '../../common/select_helper';
import { IFieldProps, IsVisibleCheck } from '../field';

const styles = require('../field.css');

const options = [{
  label: '',
  options: [{
    id: '0',
    label: 'Неважно',
  }, {
    id: '1',
    label: 'Не показывать',
  }, {
    id: '2',
    label: 'Только под снос',
  }],
}];

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const value = Number(this.props.filter.demolishedInMoscowProgramm) + 1;
    const processedOptions = createSelectOptions(
      options,
      [String(value || 0)],
      this.props.filter,
    );

    return (
      <div className={styles.field} data-mark="FieldRenovation">
        <Label label="Дома под снос">
          <SelectMobile
            id="select-object-renovation"
            label="Неважно"
            name="selectRenovation"
            onValueChange={this.handleChange}
            value={processedOptions.value}
            options={processedOptions.options[0].options}
          />
        </Label>
      </div>
    );
  }

  private handleChange = (value: string[]) => {
    const selectedId = Number(value[0]);
    let newValue: boolean | undefined;

    if (selectedId === 1) {
      newValue = false;
    } else if (selectedId === 2) {
      newValue = true;
    }

    this.props.actions.setDemolishedInMoscowProgramm(newValue);
  }
}

export const isVisible: IsVisibleCheck = filter => {
  const AVAILABLE_OBJECT_TYPES: TObjectType[] = [flatObjectType.Apartment, flatObjectType.Part, flatObjectType.Room];
  const isAvailableDealType = filter.dealType === DealType.Sale
  || (filter.dealType === DealType.Rent && filter.rentTime === rentTimeDict.Long);
  const isAvailableObjectType = filter.selectedObjectTypes.some(item => AVAILABLE_OBJECT_TYPES.includes(item));

  return isAvailableObjectType && !filter.withNewobject
  && isAvailableDealType && [
    Regions.Moscow,
    Regions.Moskovskij,
    Regions.Troick,
    Regions.Zelenograd,
    Regions.Shcherbinka,
  ].includes(filter.region);
};
