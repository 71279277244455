import { IOfferDetail } from '../../../api/models/offer_card';
import {
  IOfferIconField,
  IOfferIconListMap,
  ObjectType,
} from '../../../redux/offer/model';
import { parseObjectType } from './base_info_parser';

export const conditionsMap: IOfferIconListMap = new Map([
  ['hasFridge', {
    name: 'Холодильник',
    icon: 'card/ic-fridge',
  }],
  ['hasDishwasher', {
    name: 'Посудомоечная машина',
    icon: 'card/ic-washing',
  }],
  ['hasWasher', {
    name: 'Стиральная машина',
    icon: 'card/ic-washing',
  }],
  ['hasFurniture', {
    name: 'Мебель в комнатах',
    icon: 'card/ic-furniture',
  }],
  ['hasKitchenFurniture', {
    name: 'Мебель на кухне',
    icon: 'card/ic-furniture',
  }],
  ['hasConditioner', {
    name: 'Кондиционер',
    icon: 'card/ic-snow',
  }],
  ['hasTv', {
    name: 'Телевизор',
    icon: 'card/ic-tv',
  }],
  ['hasInternet', {
    name: 'Интернет',
    icon: 'card/ic-internet',
  }],
  ['hasPhone', {
    name: 'Телефон',
    icon: 'card/ic-phone',
  }],
  ['hasBathtub', {
    name: 'Ванна',
    icon: 'card/ic-wash',
  }],
  ['hasShower', {
    name: 'Душевая кабина',
    icon: 'card/ic-wash',
  }],
]);

export const responseToApartmentConditions = (offer: IOfferDetail): IOfferIconField[] => {
  const objectType = parseObjectType(offer);
  const allowedObjectTypes = [
    ObjectType.Flat,
    ObjectType.Studio,
    ObjectType.OpenPlan,
    ObjectType.Room,
    ObjectType.Bed,
    ObjectType.House,
    ObjectType.Cottage,
    ObjectType.Townhouse,
  ];

  const shouldShowApartmentCoditions = (
    offer.dealType === 'rent' && objectType != null && allowedObjectTypes.includes(objectType)
  );

  if (!shouldShowApartmentCoditions) {
    return [];
  }

  const newState: IOfferIconField[] = [];

  conditionsMap.forEach((value: { name: string; icon: string; }, key: keyof IOfferDetail) => {
    if (offer[key]) {
      newState.push(value);
    }
  });

  return newState;
};
