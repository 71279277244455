import {
  IGetBusinessPossibleAppointmentsResponse,
  IGetBusinessPossibleAppointmentsResponse200,
  createGetBusinessPossibleAppointmentsModel,
  fetchGetBusinessPossibleAppointments,
} from '../../../../../../repositories/announcements/v1/get-business-possible-appointments';
import { FetchAssertsService } from '../../../../../../services/FetchAssertsService';
import { ApplicationContextModel } from '../../../../../../utils/applicationContext';

export function fetchGetBusinessPossibleAppointmentsService(
  applicationContext: ApplicationContextModel,
): Promise<IGetBusinessPossibleAppointmentsResponse> {
  const { httpApi, logger } = applicationContext;

  const fetchAssertsService = new FetchAssertsService(
    createGetBusinessPossibleAppointmentsModel(),
    'src/shared/store/serverSide/announcements/v1/get-business-possible-appointments/internal/fetchService.ts',
    logger,
  );

  return fetchGetBusinessPossibleAppointments<IGetBusinessPossibleAppointmentsResponse200>({
    httpApi,
  }).then(
    result => {
      const statusCode = result.statusCode;

      switch (result.statusCode) {
        case 200:
          return result.response;
        default:
          throw fetchAssertsService.assertUnknownStatusCodeError(statusCode);
      }
    },
    error => {
      throw fetchAssertsService.assertUnknownServerError(error);
    },
  );
}
