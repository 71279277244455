/* eslint-disable max-lines */

import { get, omitBy } from 'lodash';

import { DeadlineQuarter, IOfferDetail } from '../../../api/models/offer_card';
import {
  ApartmentType,
  AreaUnitType,
  BuildingTypeParsed,
  ContractType,
  Currency,
  DealType,
  EstateType,
  IBaseInfo,
  ICompletion,
  IOfferListMap,
  IPricePerUnit,
  IRange,
  IStats,
  Icon,
  LeaseType,
  LivingObjectTypes,
  ObjectType,
  RentTime,
  ResidentsType,
  SaleType,
  SpecialtyTypeParsed,
  defaultOfferState,
} from '../model';

const isNewBuilding = (offer: IOfferDetail): boolean => {
  const type = offer.category;
  const preparedType =
    type != null
      ? type.toLowerCase().replace('rent', '').replace('sale', '').replace('daily', '').replace('share', '')
      : null;

  return preparedType === 'newbuildingflat';
};

const parseFlatType = (defaultType: ObjectType, flatType?: string): ObjectType => {
  switch (flatType) {
    case 'openPlan':
      return ObjectType.OpenPlan;
    case 'rooms':
      return ObjectType.Flat;
    case 'studio':
      return ObjectType.Studio;
    default:
      return defaultType;
  }
};

export const parseObjectType = (offer: IOfferDetail): ObjectType | undefined => {
  const type = offer.category;
  const flatType = offer.flatType;
  const preparedType =
    type != null
      ? type.toLowerCase().replace('rent', '').replace('sale', '').replace('daily', '').replace('share', '')
      : null;

  switch (preparedType) {
    case 'bed':
      return ObjectType.Bed;
    case 'building':
      return ObjectType.Building;
    case 'business':
      return ObjectType.Business;
    case 'carservice':
      return ObjectType.CarService;
    case 'commercialland':
      return ObjectType.CommercialLand;
    case 'cottage':
      return ObjectType.Cottage;
    case 'flat':
      return parseFlatType(ObjectType.Flat, flatType);
    case 'house':
      return ObjectType.House;
    case 'room':
      return ObjectType.Room;
    case 'domesticservice':
      return ObjectType.DomesticService;
    case 'freeappointmentobject':
      return ObjectType.FreeAppointmentObject;
    case 'garage':
      return ObjectType.Garage;
    case 'industry':
      return ObjectType.Industry;
    case 'land':
      return ObjectType.Land;
    case 'newbuildingflat':
      return parseFlatType(ObjectType.NewBuilding, flatType);
    case 'office':
      return ObjectType.Office;
    case 'publiccatering':
      return ObjectType.PublicCatering;
    case 'shoppingarea':
      return ObjectType.ShoppingArea;
    case 'townhouse':
      return ObjectType.Townhouse;
    case 'warehouse':
      return ObjectType.Warehouse;
    default:
      return undefined;
  }
};

export const parseBuildingType = (offer: IOfferDetail): BuildingTypeParsed | undefined => {
  const type = get(offer, 'building.type');

  switch (type) {
    case 'administrativeBuilding':
      return 'Административное здание';
    case 'businessCenter':
      return 'Бизнес-центр';
    case 'businessCenter2':
      return 'Деловой центр';
    case 'businessPark':
      return 'Бизнес-парк';
    case 'businessQuarter':
      return 'Бизнес-квартал';
    case 'free':
      return 'Свободное';
    case 'freeAppointmentObject':
      return 'Объект свободного назначения';
    case 'industrialComplex':
      return 'Производственный комплекс';
    case 'industrialPark':
      return 'Индустриальный парк';
    case 'industrialSite':
      return 'Промплощадка';
    case 'industrialWarehouseComplex':
      return 'Производственно-складской комплекс';
    case 'logisticsCenter':
      return 'Логистический центр';
    case 'logisticsComplex':
      return 'Логистический комплекс';
    case 'mansion':
      return 'Особняк';
    case 'manufactureBuilding':
      return 'Производственное здание';
    case 'manufacturingFacility':
      return 'Производственный цех';
    case 'modular':
      return 'Модульное здание';
    case 'multifunctionalComplex':
      return 'Многофункциональный комплекс';
    case 'officeAndHotelComplex':
      return 'Офисно-гостиничный комплекс';
    case 'officeAndResidentialComplex':
      return 'Офисно-жилой комплекс';
    case 'officeAndWarehouse':
      return 'Офисно-складское';
    case 'officeAndWarehouseComplex':
      return 'Офисно-складской комплекс';
    case 'officeBuilding':
      return 'Офисное здание';
    case 'officeIndustrialComplex':
      return 'Офисно-производственный комплекс';
    case 'old':
      return 'Старый фонд';
    case 'other':
      return 'Другое';
    case 'outlet':
      return 'Аутлет';
    case 'propertyComplex':
      return 'Имущественный комплекс';
    case 'residentialComplex':
      return 'Жилой комплекс';
    case 'residentialFund':
      return 'Жилой фонд';
    case 'residentialHouse':
      return 'Жилой дом';
    case 'shoppingAndBusinessComplex':
      return 'Торгово-деловой комплекс';
    case 'shoppingAndCommunityCenter':
      return 'Торгово-общественный центр';
    case 'shoppingAndEntertainmentCenter':
      return 'Торгово-развлекательный центр';
    case 'shoppingCenter':
      return 'Торговый центр';
    case 'specializedShoppingCenter':
      return 'Специализированный торговый центр';
    case 'standaloneBuilding':
      return 'Отдельно стоящее здание';
    case 'technopark':
      return 'Технопарк';
    case 'tradingOfficeComplex':
      return 'Торгово-офисный комплекс';
    case 'uninhabitedFund':
      return 'Нежилой фонд';
    case 'warehouse':
      return 'Склад';
    case 'warehouseComplex':
      return 'Складской комплекс';
    default:
      return undefined;
  }
};

const specialtiesMap: IOfferListMap = new Map([
  ['carWash', 'Автомойка'],
  ['carService', 'Автосервис'],
  ['pharmacy', 'Аптека'],
  ['clothesStudio', 'Ателье одежды'],
  ['bank', 'Банк'],
  ['bar', 'Бар'],
  ['domesticServices', 'Бытовые услуги'],
  ['bakeryProducts', 'Выпечка'],
  ['exhibition', 'Выставка'],
  ['hotel', 'Гостиница'],
  ['hall', 'Зал'],
  ['hookah', 'Кальянная'],
  ['cafe', 'Кафе/ресторан'],
  ['club', 'Клуб'],
  ['trade', 'Коммерция'],
  ['confectionery', 'Кондитерская'],
  ['pawnshop', 'Ломбард'],
  ['shop', 'Магазин'],
  ['workshop', 'Мастерская'],
  ['medicalCenter', 'Медицинский центр'],
  ['publicCatering', 'Общепит'],
  ['office', 'Офис'],
  ['barbershop', 'Парикмахерская'],
  ['bakery', 'Пекарня'],
  ['products', 'Продукты'],
  ['restaurant', 'Ресторан'],
  ['beautySaloon', 'Салон красоты'],
  ['phoneShop', 'Салон связи'],
  ['sauna', 'Сауна'],
  ['service', 'Сервис'],
  ['gym', 'Спортзал'],
  ['stomatology', 'Стоматология'],
  ['fitnessCentre', 'Фитнес'],
  ['studio', 'Фотостудия'],
  ['fruit', 'Фрукты'],
  ['hostel', 'Хостел'],
  ['flowers', 'Цветы'],
  ['guild', 'Цех'],
  ['shawarma', 'Шаурма'],
  ['mounting', 'Шиномонтаж'],
  ['school', 'Школа'],
  ['showroom', 'Шоурум'],
  ['other', 'Другое'],
  ['notarySOffice', 'Нотариальная контора'],
  ['shoppingFloorSpace', 'Торговая площадь'],
  ['warehouse', 'Склад'],
  ['production', 'Производство'],
  ['spaSaloon', 'Spa салон'],
  ['aviaBookingOffice', 'Авиа кассы'],
  ['carSpareParts', 'Автозапчасти'],
  ['automobileCenter', 'Автосалон'],
  ['fillingStation', 'АЗС'],
  ['alcholicBeveragesStore', 'Алкомаркет'],
  ['anticafe', 'Антикафе'],
  ['rentalBusiness', 'Арендный бизнес'],
  ['center', 'База'],
  ['recreationalCenter', 'База отдыха'],
  ['bathingComplex', 'Банный комплекс'],
  ['underwear', 'Белье'],
  ['bijouterie', 'Бижутерия'],
  ['poolhall', 'Бильярдная'],
  ['hospitalComplex', 'Больничный комплекс'],
  ['bowling', 'Боулинг'],
  ['bookmakerSOffice', 'Букмекерская контора'],
  ['boutique', 'Бутик'],
  ['snackBar', 'Буфет'],
  ['householdAppliances', 'Бытовая техника'],
  ['gallery', 'Галерея'],
  ['hypermarket', 'Гипермаркет'],
  ['guestHouse', 'Гостевой дом'],
  ['readyMadeBusiness', 'Готовый бизнес'],
  ['goodsForChildren', 'Детские товары'],
  ['childClub', 'Детский клуб'],
  ['childStore', 'Детский магазин'],
  ['daycare', 'Детский сад'],
  ['childCenter', 'Детский центр'],
  ['rentalHouse', 'Доходный дом'],
  ['factory', 'Завод'],
  ['lending', 'Займы'],
  ['petShop', 'Зоомагазин'],
  ['petProducts', 'Зоотовары'],
  ['dentalPolyclinic', 'Зубная поликлиника'],
  ['internetShop', 'Интернет магазин'],
  ['joga', 'Йога'],
  ['quest', 'Квест'],
  ['customerRelatedOffice', 'Клиентский офис'],
  ['clinic', 'Клиника'],
  ['cosmetics', 'Косметика'],
  ['cosmeticMedicine', 'Косметология'],
  ['coffeeHouse', 'Кофейня'],
  ['cookery', 'Кулинария'],
  ['smallProductionEnterprice', 'Малое производство'],
  ['manicure', 'Маникюр'],
  ['massageSalon', 'Массажный салон'],
  ['furniture', 'Мебель'],
  ['miniHotel', 'Мини-отель'],
  ['meat', 'Мясо'],
  ['nightClub', 'Ночной клуб'],
  ['currencyExchange', 'Обмен валюты'],
  ['shoes', 'Обувь'],
  ['clothers', 'Одежда'],
  ['optics', 'Оптика'],
  ['recreationCenter', 'Пансионат'],
  ['perfumery', 'Парфюмерия'],
  ['pizzeria', 'Пиццерия'],
  ['dishes', 'Посуда'],
  ['representativeOffice', 'Представительство'],
  ['collectionPoint', 'Пункт выдачи'],
  ['workplace', 'Рабочeе место'],
  ['workRoom', 'Рабочий кабинет'],
  ['fish', 'Рыба'],
  ['salon', 'Салон'],
  ['healthResort', 'Санаторий'],
  ['flexiblePurpose', 'Свободное назначение'],
  ['gymHall', 'Спортивный зал'],
  ['vahicleServiceCenter', 'СТО'],
  ['canteen', 'Столовая'],
  ['streetRetail', 'Стрит ритейл'],
  ['constructionMaterials', 'Стройматериалы'],
  ['dancingStudio', 'Студия танцев'],
  ['souvenirs', 'Сувениры'],
  ['bagStore', 'Сумки'],
  ['supermarket', 'Супермаркет'],
  ['sushi', 'Суши'],
  ['tatooSaloon', 'Тату салон'],
  ['printingOffice', 'Типография'],
  ['consumerGoods', 'ТНП'],
  ['householdGoods', 'Товары для дома'],
  ['trading', 'Торговля'],
  ['shopEquipment', 'Торговое'],
  ['shoppingComplex', 'Торговый комплекс'],
  ['tradingCenter', 'Торговый центр'],
  ['travelAgency', 'Турагенство'],
  ['services', 'Услуги'],
  ['educationalCenter', 'Учебный центр'],
  ['fastFood', 'Фастфуд'],
  ['farm', 'Ферма'],
  ['photoStudio', 'Фото студия'],
  ['dryCleaning', 'Химчистка'],
  ['bakeryComplex', 'Хлебокомбинат'],
  ['privatePractice', 'Частная практика'],
  ['sewingShop', 'Швейный цех'],
  ['electronicCigarette', 'Электронные сигареты'],
  ['jewerlyShop', 'Ювелирный'],
]);

export const parseSpecialty = (offer: IOfferDetail): SpecialtyTypeParsed[] => {
  const types: string[] = get(offer, 'specialty.types', []);
  const preparedTypes: SpecialtyTypeParsed[] = types
    .map(t => specialtiesMap.get(t) as SpecialtyTypeParsed)
    .filter(Boolean);

  return preparedTypes;
};

const parseApartmentType = (offer: IOfferDetail): ApartmentType | undefined => {
  if (offer.isApartments) {
    return ApartmentType.Apartment;
  } else if (offer.isPenthouse) {
    return ApartmentType.Penthouse;
  }

  return undefined;
};

const parseResidentsType = (type?: string): ResidentsType | undefined => {
  switch (type) {
    case 'any':
      return ResidentsType.Any;
    case 'family':
      return ResidentsType.Family;
    case 'female':
      return ResidentsType.Female;
    case 'male':
      return ResidentsType.Male;
    default:
      return undefined;
  }
};

const parseSaleType = (type?: string): SaleType | undefined => {
  switch (type) {
    case 'alternative':
      return SaleType.Alternative;
    case 'dupt':
      return SaleType.DUPT;
    case 'dzhsk':
      return SaleType.DJSK;
    case 'free':
      return SaleType.Free;
    case 'fz214':
      return SaleType.Fz214;
    case 'investment':
      return SaleType.Investment;
    case 'pdkp':
      return SaleType.PDKP;
    default:
      return undefined;
  }
};

export const parseDealType = (type?: string): DealType | undefined => {
  switch (type) {
    case 'sale':
      return DealType.Sale;
    case 'rent':
      return DealType.Rent;
    default:
      return undefined;
  }
};

export const parseRentTime = (offer: IOfferDetail): RentTime | undefined => {
  if (parseDealType(offer.dealType) === DealType.Sale) {
    return undefined;
  }

  const { bargainTerms } = offer;
  const type = bargainTerms && bargainTerms.leaseTermType;

  if (offer.category != null && offer.category.toLowerCase().includes('daily')) {
    return RentTime.Short;
  }

  switch (type) {
    case 'fewMonths':
      return RentTime.FewMonths;
    case 'longTerm':
      return RentTime.Long;
    default:
      return undefined;
  }
};

const parseCurrency = (type?: string): Currency | undefined => {
  switch (type) {
    case 'rur':
      return Currency.RUB;
    case 'usd':
      return Currency.USD;
    case 'eur':
      return Currency.EUR;
    default:
      return undefined;
  }
};

const parseBedsCount = (offer: IOfferDetail): number | undefined => {
  const type = parseObjectType(offer);
  if (type === ObjectType.Bed || type === ObjectType.Room) {
    return offer.bedsCount;
  }

  return undefined;
};

const parseLeaseType = (offer: IOfferDetail): LeaseType | undefined => {
  if (parseDealType(offer.dealType) !== DealType.Rent) {
    return undefined;
  }
  const { bargainTerms } = offer;
  const type = bargainTerms && bargainTerms.leaseType;

  switch (type) {
    case 'direct':
      return LeaseType.Direct;
    case 'jointVenture':
      return LeaseType.JointVenture;
    case 'sublease':
      return LeaseType.Sublease;
    default:
      return undefined;
  }
};

const parseExplotationPayments = (offer: IOfferDetail): boolean => {
  const { bargainTerms } = offer;

  const includedOptions = bargainTerms && bargainTerms.includedOptions;

  return includedOptions != null && includedOptions.includes('operationalCosts');
};

const parseUtilityPayments = (offer: IOfferDetail): boolean => {
  const { bargainTerms } = offer;
  const dealType = parseDealType(offer.dealType);
  const rentTime = parseRentTime(offer);
  const type = parseObjectType(offer) || ObjectType.Flat;

  const allowedCommercialTypes = [
    ObjectType.Building,
    ObjectType.Business,
    ObjectType.CommercialLand,
    ObjectType.FreeAppointmentObject,
    ObjectType.Garage,
    ObjectType.Office,
    ObjectType.ShoppingArea,
    ObjectType.Warehouse,
  ];

  const showUtilityPayments =
    dealType === DealType.Rent &&
    (allowedCommercialTypes.includes(type) || (LivingObjectTypes.includes(type) && rentTime !== RentTime.Short));
  if (!showUtilityPayments) {
    return false;
  }
  const includedOptions = bargainTerms && bargainTerms.includedOptions;

  return includedOptions != null && includedOptions.includes('utilityCharges');
};

const parsePricePerUnit = (offer: IOfferDetail): IPricePerUnit | undefined => {
  const dealType = parseDealType(offer.dealType);

  if (dealType === DealType.Rent) {
    if (offer.pricePerUnitAreaPerYear) {
      return {
        price: offer.pricePerUnitAreaPerYear,
        unitOfTime: 'год',
      };
    } else if (offer.pricePerUnitAreaPerMonth) {
      return {
        price: offer.pricePerUnitAreaPerMonth,
        unitOfTime: 'мес',
      };
    }
  } else if (offer.pricePerUnitArea) {
    return {
      price: offer.pricePerUnitArea,
    };
  } else if (offer.offerType === 'flat') {
    const area = Number(offer.totalArea);
    const price = offer.bargainTerms && offer.bargainTerms.price;

    if (!offer.totalArea || isNaN(area) || !price) {
      return undefined;
    }

    return {
      price: Math.round(price / area),
    };
  }

  return undefined;
};

const parseContractType = (type?: string): ContractType | undefined => {
  switch (type) {
    case 'leaseAssignment':
      return ContractType.LeaseAssignment;
    case 'sale':
      return ContractType.Sale;
    default:
      return undefined;
  }
};

const parseEstateType = (type?: string): EstateType | undefined => {
  switch (type) {
    case 'rent':
      return EstateType.Rent;
    case 'owned':
      return EstateType.Owned;
    default:
      return undefined;
  }
};

const parseIcons = (offer: IOfferDetail): Icon[] | undefined => {
  const icons: Icon[] = [];

  const isLeadFactory = !!offer.newbuilding && offer.newbuilding.isFromLeadFactory;

  if (offer.fromDeveloper && !isLeadFactory) {
    icons.push('builder');
  }

  if (offer.isTop3) {
    icons.push('top-3');
  }

  if (offer.isAuction) {
    icons.push('auction');
  }

  if (offer.isPremium) {
    icons.push('premium');
  }

  if (offer.isStandard) {
    icons.push('standard');
  }

  return icons.length > 0 ? icons : undefined;
};

const parseStats = (offer: IOfferDetail): IStats | undefined => {
  return offer.added == null
    ? undefined
    : {
        published: offer.added,
        views: (offer.statistic && offer.statistic.total) || 0,
        uniqueViewsStr: (offer.statistic && offer.statistic.totalUniqueViewsFormattedString) || null,
        totalViewsStr: (offer.statistic && offer.statistic.totalViewsFormattedString) || null,
      };
};

export const parseArea = (offer: IOfferDetail): number | IRange | undefined => {
  const type = parseObjectType(offer);

  if (type === ObjectType.Room) {
    return parseFloat(offer.roomArea as string);
  } else if ((type === ObjectType.CommercialLand || type === ObjectType.Land) && offer.land && offer.land.area) {
    return parseFloat(offer.land.area as string);
  }

  const totalArea = parseFloat(offer.totalArea as string);
  const minArea = parseFloat(offer.minArea as string);

  if (!isNaN(minArea) && minArea !== totalArea) {
    return { min: minArea, max: totalArea };
  } else {
    return totalArea;
  }
};

export const parseAreaType = (offer: IOfferDetail): AreaUnitType => {
  const objectType = parseObjectType(offer);
  const type =
    objectType === ObjectType.Land || objectType === ObjectType.CommercialLand ? get(offer, 'land.areaUnitType') : null;

  switch (type) {
    case 'hectare':
      return 'Га';
    case 'sotka':
      return 'сот.';
    default:
      return 'м²';
  }
};

export const parsePrice = (offer: IOfferDetail): number | IRange | undefined => {
  const dealType = parseDealType(offer.dealType);
  const { bargainTerms, priceTotal, minPriceTotal, priceTotalPerMonth, minPriceTotalPerMonth } = offer;

  if (dealType === DealType.Rent) {
    if (priceTotalPerMonth && minPriceTotalPerMonth && priceTotalPerMonth !== minPriceTotalPerMonth) {
      return { min: minPriceTotalPerMonth, max: priceTotalPerMonth };
    } else if (priceTotalPerMonth) {
      return priceTotalPerMonth;
    }
  } else {
    if (priceTotal && minPriceTotal && priceTotal !== minPriceTotal) {
      return { min: minPriceTotal, max: priceTotal };
    } else if (priceTotal) {
      return priceTotal;
    }
  }

  return bargainTerms && bargainTerms.price;
};

export const parseQuarter = (deadlineQuarter?: DeadlineQuarter): number | undefined => {
  const map: { [i: string]: number } = {
    first: 1,
    second: 2,
    third: 3,
    fourth: 4,
  };

  return map[deadlineQuarter as string];
};

const parseCompletion = (offer: IOfferDetail): ICompletion | undefined => {
  const deadline = offer.building && offer.building.deadline;
  if (deadline) {
    return {
      quarter: parseQuarter(deadline.quarter),
      year: deadline.year,
      isComplete: deadline.isComplete,
    };
  }

  return undefined;
};

export const parseLandStatus = (offer: IOfferDetail): string | undefined => {
  const status = offer.land && offer.land.status;
  const landStatusMap = {
    individualHousingConstruction: 'ИЖС',
    gardening: 'Садоводство',
    farm: 'Фермерское хоз-во',
    suburbanNonProfitPartnership: 'ДНП',
    industrialLand: 'Земля промназначения',
    investmentProject: 'Инвестпроект',
  } as { [key: string]: string | undefined };

  return landStatusMap[status as string];
};

export const parseLandCategory = (data: IOfferDetail): string | undefined => {
  if (!data.land || !data.land.status) {
    return undefined;
  }

  const map: { [key: string]: string } = {
    individualHousingConstruction: 'Индивидуальное жилищное строительство',
    gardening: 'Садоводство',
    farm: 'Фермерское хозяйство',
    suburbanNonProfitPartnership: 'Дачное некоммерческое партнерство',
    industrialLand: 'Земля промышленного назначения',
    gardeningNonProfitPartnership: 'Садовое некоммерческое товарищество',
    suburbanNonProfitSettlementsPartnership: 'Дачное некоммерческое партнерство поселений',
    forAgriculturalPurposes: 'Участок сельскохозяйственного назначения',
    industryTransportCommunications: 'Участок промышленности, транспорта, связи и иного не сельхоз. назначения',
    ofProtectedCategories: 'Особо охраняемых категорий',
    forestArea: 'Участок лесного фонда',
    waterArea: 'Участок водного фонда',
    reserve: 'Участок запаса',
    investmentProject: 'Инвестпроект',
    settlements: 'Участок поселений',
  };

  const { status } = data.land;

  return map[status];
};

const parseDecoration = (offer: IOfferDetail): 'без отделки' | 'с отделкой' | undefined => {
  if (isNewBuilding(offer) && offer.decoration != null) {
    return offer.decoration === 'fine' || offer.decoration === 'rough' ? 'с отделкой' : 'без отделки';
  }

  return undefined;
};

const parseDecorationStrict = (offer: IOfferDetail): 'без отделки' | 'с отделкой' | undefined => {
  if (isNewBuilding(offer) && offer.decoration != null) {
    return offer.decoration === 'fine' ? 'с отделкой' : 'без отделки';
  }

  return undefined;
};

export const parsePriceRub = (offer: IOfferDetail): number | IRange | undefined => {
  const { priceTotalRur, bargainTerms, priceTotalPerMonthRur, minPriceTotalPerMonthRur } = offer;
  const dealType = parseDealType(offer.dealType);

  if (dealType === DealType.Rent && priceTotalPerMonthRur && minPriceTotalPerMonthRur) {
    return {
      min: minPriceTotalPerMonthRur,
      max: priceTotalPerMonthRur,
    };
  }

  if (dealType === DealType.Rent && priceTotalPerMonthRur) {
    return Math.floor(priceTotalPerMonthRur);
  }

  if (priceTotalRur) {
    return Math.floor(priceTotalRur);
  } else if (bargainTerms && bargainTerms.priceRur) {
    return Math.floor(bargainTerms.priceRur);
  }

  return undefined;
};

export const parseCommissionLabel = (offer: IOfferDetail): boolean | undefined => {
  return (
    offer.bargainTerms &&
    !offer.bargainTerms.clientFee &&
    !offer.bargainTerms.agentFee &&
    parseDealType(offer.dealType) === DealType.Rent &&
    parseRentTime(offer) !== RentTime.Short &&
    offer.offerType !== 'commercial'
  );
};

const parseFromHomeOwnerLabel = (offer: IOfferDetail): boolean | undefined => {
  const objectType = parseObjectType(offer);

  return offer.isByHomeowner && objectType != null && LivingObjectTypes.includes(objectType);
};
/**
 * Избавляемся от null с бэка и превращаем его в undefined
 */
export const normalize = <T>(a: T | null): T | undefined => {
  if (a === undefined || a === null) {
    return undefined;
  }

  return a;
};

export const responseToBaseInfo = (offer: IOfferDetail): IBaseInfo => {
  const { bargainTerms, monthlyIncome, promoInfo, building } = offer;

  const baseInfo: IBaseInfo = {
    agentBonus: bargainTerms && bargainTerms.agentBonus,
    type: parseObjectType(offer),
    buildingType: parseBuildingType(offer),
    specialty: parseSpecialty(offer),
    rooms: offer.roomsCount,
    price: parsePrice(offer),
    priceRub: bargainTerms && bargainTerms.priceRur,
    area: parseArea(offer),
    areaUnitType: parseAreaType(offer),
    deposit: bargainTerms && bargainTerms.deposit,
    prepayment: bargainTerms && bargainTerms.prepayMonths,
    bargainAllowed: bargainTerms && bargainTerms.bargainAllowed,
    mortgageAllowed: bargainTerms && bargainTerms.mortgageAllowed,
    fee: bargainTerms && bargainTerms.clientFee,
    agentFee: bargainTerms && bargainTerms.agentFee,
    utilityPayments: parseUtilityPayments(offer),
    utilityPaymentsPrice: bargainTerms && bargainTerms.utilitiesTerms && bargainTerms.utilitiesTerms.price,
    utilityPaymentsCurrencyPrice: bargainTerms && bargainTerms.utilitiesTermsPrices,
    residentsType: parseResidentsType(bargainTerms && bargainTerms.tenantsType),
    floor: offer.floorNumber,
    floorFrom: offer.floorFrom,
    floorTo: offer.floorTo,
    totalFloors: building && building.floorsCount,
    apartmentType: parseApartmentType(offer),
    saleType: parseSaleType(bargainTerms && bargainTerms.saleType),
    rentTime: parseRentTime(offer),
    dealType: parseDealType(offer.dealType),
    currency: parseCurrency(bargainTerms && bargainTerms.currency),
    bedsCount: parseBedsCount(offer),
    bedroomsCount: offer.bedroomsCount,
    leaseType: parseLeaseType(offer),
    vatIncluded: bargainTerms && bargainTerms.vatIncluded,
    vatType: bargainTerms && bargainTerms.vatType,
    minVatPriceTotalPerMonthRur: normalize(offer.minVatPriceTotalPerMonthRur),
    vatPriceTotalPerMonthRur: normalize(offer.vatPriceTotalPerMonthRur),
    minVatPriceTotalRur: normalize(offer.minVatPriceTotalRur),
    vatPriceTotalRur: normalize(offer.vatPriceTotalRur),
    exploitationPayments: parseExplotationPayments(offer),
    pricePerUnit: parsePricePerUnit(offer),
    bargainConditions: bargainTerms && bargainTerms.bargainConditions,
    bargainPrice: bargainTerms && bargainTerms.bargainPrice,
    minLeaseTerm: bargainTerms && bargainTerms.minLeaseTerm,
    hasGracePeriod: bargainTerms && bargainTerms.hasGracePeriod,
    securityDeposit: bargainTerms && bargainTerms.securityDeposit,
    securityDepositPrices: bargainTerms && bargainTerms.securityDepositPrices,
    contractType: parseContractType(bargainTerms && bargainTerms.contractType),
    estateType: parseEstateType(offer.estateType),
    monthlyIncome: monthlyIncome && monthlyIncome.income,
    monthlyIncomeCurrency: parseCurrency(monthlyIncome && monthlyIncome.currency),
    isOccupied: offer.isOccupied,
    icons: parseIcons(offer),
    isAuction: Boolean(offer.isAuction),
    isTop3: Boolean(offer.isTop3),
    isPro: Boolean(offer.isPro),
    isStandard: Boolean(offer.isStandard),
    isPremium: Boolean(offer.isPremium),
    promo: promoInfo && promoInfo.name,
    completion: parseCompletion(offer),
    stats: parseStats(offer),
    roomsForSale: offer.roomsForSaleCount,
    rosreestrCheck: offer.rosreestrCheck,
    shareAmount: offer.shareAmount,
    canParts: offer.canParts,
    landStatus: parseLandStatus(offer),
    landCategory: parseLandCategory(offer),
    decoration: parseDecoration(offer),
    decorationStrict: parseDecorationStrict(offer),
    priceRubles: parsePriceRub(offer),
    withoutCommissionLabel: parseCommissionLabel(offer),
    fromHomeOwnerLabel: parseFromHomeOwnerLabel(offer),
    category: offer.category,
    coworkingOfferType: offer.coworkingOfferType,
    coworking: offer.coworking,
    workplaceCount: offer.workplaceCount,
    priceForWorkplace: bargainTerms && bargainTerms.priceForWorkplace,
  };

  const newState = omitBy(baseInfo, v => v == null || v !== v);

  return { ...defaultOfferState.offer.baseInfo, ...newState };
};
