import * as React from 'react';

import Block from '../../../ui/block/block';
import { Field } from '../../field';
import * as fields from '../../fields/';
import {
  isFlatObjectGroup,
  isSuburbanObjectGroupHouse,
  IsVisibleCheck,
  transformToField,
  isSuburbanObjectGroup,
} from '../field';

const styles = require('../field.css');

interface IAboutHouseProps {
  isSuburban: boolean;
  showRentTimeLong: boolean;
  showFloorsNumber: boolean;
}

class AboutHouse extends React.PureComponent<IAboutHouseProps, {}> {
  public render() {
    const { isSuburban} = this.props;

    return (
      <Block
        title="О доме"
        dataMark="BlockAboutHouse"
      >
        <div className={styles.fieldBlock}>
          {this.props.showRentTimeLong && <Field field={fields.rentTimeLong} />}
          <Field field={fields.selectMaterial} />
          <Field field={fields.selectHouseType} />
          <Field field={fields.houseType} />
          <div className={styles.margins}>
            {
              isSuburban &&
                <Field field={fields.houseYear} />
            }
            {this.props.showFloorsNumber && <Field field={fields.floorsNumber} />}
          </div>
        </div>
        <div className={styles.fieldBlock}>
          <Field field={fields.bathroomPlacement} />
        </div>
      </Block>
    );
  }
}

export const Component = transformToField(({ filter }) => ({
  showFloorsNumber: isFlatObjectGroup(filter) || isSuburbanObjectGroupHouse(filter),
  showRentTimeLong: isSuburbanObjectGroupHouse(filter),
  isSuburban: isSuburbanObjectGroup(filter),
}))(AboutHouse);

export const isVisible: IsVisibleCheck = filter =>
  fields.rentTimeLong.isVisible(filter)
  || fields.selectMaterial.isVisible(filter)
  || fields.selectHouseType.isVisible(filter)
  || fields.houseType.isVisible(filter)
  || fields.renovation.isVisible(filter)
  || fields.floorsNumber.isVisible(filter) && (isFlatObjectGroup(filter) || isSuburbanObjectGroupHouse(filter))
  || fields.lifts.isVisible(filter);
