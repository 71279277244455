import { TQueryStringActions } from './queryStringActions';

export function queryStringReducer(
  state: string = '',
  action: TQueryStringActions,
): string {
  switch (action.type) {
    case 'SetQueryStringFromMeta':
      return action.payload.queryString;
    default:
      return state;
  }
}
