import * as React from 'react';

import Block from '../../../ui/block/block';
import { Field } from '../../field';
import * as fields from '../../fields/';

import {
  IFieldProps,
  isCommercialObjectGroupBuilding,
  isFlatObjectGroup,
  isSuburbanObjectGroup,
  IsVisibleCheck,
  isCommercialObjectGroup,
  DealType,
  isApartamentObjectGroup,
  isReadyBusinessSaleFilters,
} from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const { filter } = this.props;
    const isCommercial = isCommercialObjectGroup(filter);
    const isReadyBusinessSale = isReadyBusinessSaleFilters(filter);
    const showFloorsNumber = isCommercialObjectGroupBuilding(filter);
    const showRentTimeLong = isFlatObjectGroup(filter);
    const showApartments = isApartamentObjectGroup(filter);

    return (
      <Block
        title="Об объекте"
        titleStyle={[{ marginBottom: 5 }]}
        dataMark="BlockAboutObject"
      >
        {
          !filter.withNewobject &&
            <div className={styles.fieldBlock}>
              {showRentTimeLong && <Field field={fields.rentTimeLong} />}
              <Field field={fields.roomsCount} />
              <Field field={fields.rentRoomsCount} />
              <Field field={fields.totalArea} />
              <Field field={fields.kitchenArea} />
              <Field field={fields.livingArea} />
              <Field field={fields.buildingClass} />
              <Field field={fields.garageType} />
              <Field field={fields.selectGarageSubtype} />
              <Field field={fields.boxType} />
            </div>
        }
        {
          !isCommercial &&
            <>
              <div className={styles['filter-layout']}>
                {
                  filter.dealType !== DealType.Rent &&
                    <span className={styles['filter-title']}>Планировка</span>
                }
                <Field field={fields.withLayout} />
                <Field field={fields.roomType} />
              </div>
              <div className={styles['filter-layout']}>
                <Field field={fields.ceilingHeight} />
              </div>
              <Field field={fields.bathroomTypeAB} />
              <div className={styles['filter-layout']}>
                <Field field={fields.windowsType} />
                <Field field={fields.balconyAB} />
              </div>
              {
                !filter.withNewobject &&
                  <>
                    <div className={styles['filter-layout']}>
                      <Field field={fields.renovation} />
                    </div>
                    <div className={styles['filter-layout']}>
                      <Field field={fields.liftsAB} />
                    </div>
                  </>
              }
              <Field field={fields.saleType} />
              {showApartments && (
                <div className={styles['filter-layout']}>
                  <Field field={fields.apartments} />
                </div>
              )}
            </>
        }
        <div className={styles['filter-layout']}>
          <Field field={fields.publicationPeriodAB} />
          <div className={styles['photo-filter']}>
            <Field field={fields.withPhoto} />
          </div>
          <Field field={fields.hasVideo} />
        </div>
        <div className={styles.fieldBlock}>
          {isReadyBusinessSale && <Field field={fields.square} />}
          <Field field={fields.floorFlat} />
          <Field field={fields.edgeFloors} />
          <Field field={fields.houseYear} isCommercialFilter={isCommercial} />
          <Field field={fields.decoration} specialStyles={styles['decorationField']} />
          <Field field={fields.floorCommerce} />
          <Field field={fields.basementFloors} />
          {showFloorsNumber && <Field field={fields.floorsNumber} />}
          <Field field={fields.accessSystem} />
          <Field field={fields.enterType} />
        </div>
        <div className={styles.fieldBlock}>
          <Field field={fields.contractType} />
          <Field field={fields.withFurnitureCommerce} />
        </div>
      </Block>
    );
  }
}

export const isVisible: IsVisibleCheck = filter =>
  !isSuburbanObjectGroup(filter) &&
  /* istanbul ignore next */
  (fields.rentTimeLong.isVisible(filter)
    || fields.roomsCount.isVisible(filter)
    || fields.rentRoomsCount.isVisible(filter)
    || fields.totalArea.isVisible(filter)
    || fields.kitchenArea.isVisible(filter)
    || fields.livingArea.isVisible(filter)
    || fields.buildingClass.isVisible(filter)
    || fields.garageType.isVisible(filter)
    || fields.selectGarageSubtype.isVisible(filter)
    || fields.boxType.isVisible(filter)
    || fields.floorFlat.isVisible(filter)
    || fields.edgeFloors.isVisible(filter)
    || fields.decoration.isVisible(filter)
    || fields.floorCommerce.isVisible(filter)
    || fields.basementFloors.isVisible(filter)
    || (fields.floorsNumber.isVisible(filter) && isCommercialObjectGroupBuilding(filter))
    || fields.accessSystem.isVisible(filter)
    || fields.enterType.isVisible(filter)
    || fields.balcony.isVisible(filter)
    || fields.bathroomType.isVisible(filter)
    || fields.selectBathroomsCount.isVisible(filter)
    || fields.contractType.isVisible(filter)
    || fields.withFurnitureCommerce.isVisible(filter)
    || fields.houseYear.isVisible(filter)
  );
