import { v4 as uuid } from 'uuid';

import { ISession } from '../types';
import { IJsonQuery } from '../../../../api/models/json_query';

const SESSION_KEY = '__serp_ml_search_session__comm_mobsite__';

export function generateSession(jsonQuery: IJsonQuery): ISession {
  const guid = uuid();
  const session = { jsonQuery, guid };

  try {
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(session));
    // eslint-disable-next-line no-empty
  } catch (error) {}

  return session;
}

export function restoreSession(): ISession | null {
  try {
    const sessionString = sessionStorage.getItem(SESSION_KEY);
    const session = sessionString ? JSON.parse(sessionString) : null;

    return session;
  } catch (error) {
    return null;
  }
}
