import { IOfferDetail } from '../../../api/models/offer_card';
import {
  IOfferIconField,
  IOfferIconListMap,
} from '../../../redux/offer/model';

export const suburbanConditionsMap: IOfferIconListMap = new Map([
  ['hasElectricity', {
    name: 'Электричество',
    icon: 'card/ic-electricity',
  }],
  ['hasGas', {
    name: 'Газ',
    icon: 'card/ic-gas',
  }],
  ['hasDrainage', {
    name: 'Канализация',
    icon: 'card/ic-canal',
  }],
  ['hasWater', {
    name: 'Водоснабжение',
    icon: 'card/ic-water-system',
  }],
  ['hasSecurity', {
    name: 'Охрана',
    icon: 'card/ic-secure',
  }],
  ['hasPhone', {
    name: 'Телефон',
    icon: 'card/ic-phone',
  }],
  ['hasBathhouse', {
    name: 'Баня',
    icon: 'card/ic-wash',
  }],
  ['hasGarage', {
    name: 'Гараж',
    icon: 'card/ic-garage',
  }],
  ['hasPool', {
    name: 'Бассейн',
    icon: 'card/ic-pool',
  }],
]);

export const responseToSuburbanSaleConditions = (offer: IOfferDetail): IOfferIconField[] => {
  const newState: IOfferIconField[] = [];

  if (offer.offerType !== 'suburban' || offer.dealType !== 'sale') {
    return [];
  }

  suburbanConditionsMap.forEach((value: { name: string; icon: string; }, key: keyof IOfferDetail) => {
    if (offer[key]) {
      newState.push(value);
    }
  });

  return newState;
};
