import *  as React from 'react';

import {
  Button,
  ButtonTheme,
  CheckboxField,
  DropdownField,
  Label,
  TextField,
  Tooltip,
} from '@cian/components';
import { mergeStyles } from '@cian/utils';

import { IRequestSubscribeNewResponse } from '../../api/api';
import Icon from '../../ui/icon/icon';
import { PopupMobile } from '../../ui/popup/popup';
import { createBodyScroll } from '../../utils/body_scroll';
import { isEmail } from '../../utils/validators';
import { TooltipPopup } from '../tooltip_popup/index';
import {
  trackNewAccountRegistration,
  trackSubscribe,
  trackSubscribeWithFlag,
  trackSuccessActivationPopupShow,
  trackSuccessPopupShow,
} from './tracking';

import { FLOCKTORY_KEY_EVENT } from '../../utils/flocktory';

import { License } from './License';
import {
  TNotificationFrequencyValue,
  TNotificationFrequency,
  NOTIFICATION_FREQUENCY,
  mapNotificationFrequency,
} from './ab/utils/notificationFrequency';
import { SavedSearchForm } from './ab/SavedSearchForm';

const styles = require('./index.css');

export interface ISaveFilterPopupProps {
  isAuthenticated: boolean;
  title?: string;
  email?: string;
  opened: boolean;
  isFromBanner?: boolean;
  pushSubscribed: boolean;
  isFromFilter: boolean;
  isCardRoute: boolean;
  queryString: string;

  isNewDesignSavedSearch: boolean;

  closeSaveFilterPopup(): void;

  saveSearch(
    email: string,
    title: string,
    isNews: boolean,
    notificationFrequency: TNotificationFrequency,
  ): Promise<IRequestSubscribeNewResponse | void>;
}

export interface ISaveFilterState {
  title: string;
  titleAutoSet: string | undefined;
  isSuccess: boolean;
  isNews: boolean;
  email: string;
  emailError: string;
  sessionEmail: string;
  isLoader: boolean;
  isTooltipVisible: boolean;
  isErrorResponse: boolean;
  notificationFrequency: TNotificationFrequencyValue;
  isPushCheck: boolean;
}

const fakeEmailRex = /@social\.cian\.ru$/;

const bodyScroll = createBodyScroll();

export class SaveFilterPopup extends React.Component<ISaveFilterPopupProps, ISaveFilterState> {

  public constructor(props: ISaveFilterPopupProps) {
    super(props);
    const emailNoFake =  props.email && !fakeEmailRex.test(props.email) ? props.email : '';

    const titleObject = {
      title: props.isNewDesignSavedSearch ? '' : props.title || '',
      titleAutoSet: props.isNewDesignSavedSearch && props.title ? props.title : undefined,
    };

    this.state = {
      isNews: true,
      isSuccess: false,
      isErrorResponse: false,
      emailError: '',
      ...titleObject,
      email: emailNoFake,
      sessionEmail: emailNoFake,
      isLoader: false,
      isTooltipVisible: false,
      /**
       * По умолчанию частота уведомлений - каждый час
       */
      notificationFrequency: 1,
      isPushCheck: true,
    };
  }

  public componentWillUpdate(nextProps: ISaveFilterPopupProps) {
    if (this.props.opened !== nextProps.opened && nextProps.opened) {
      bodyScroll.disable();
    } else if (this.props.opened !== nextProps.opened && !nextProps.opened) {
      bodyScroll.enable();
    }
  }

  public componentDidUpdate(prevProps: ISaveFilterPopupProps, prevState: ISaveFilterState) {
    const pageType = this.props.isCardRoute ? 'card' : 'cat';

    if (this.props.opened && this.state.isSuccess !== prevState.isSuccess && this.state.isSuccess) {
      if (this.isSameEmail()) {
        trackSuccessPopupShow(pageType);
      } else {
        trackSuccessActivationPopupShow(pageType);
      }
    }
  }

  public componentWillReceiveProps(nextProps: ISaveFilterPopupProps) {

    if (this.props.opened !== nextProps.opened && nextProps.opened) {
      const emailNoFake = nextProps.email && !fakeEmailRex.test(nextProps.email) ? nextProps.email : '';

      const titleObject = {
        title: this.props.isNewDesignSavedSearch ? '' : nextProps.title || '',
        titleAutoSet: this.props.isNewDesignSavedSearch && nextProps.title ? nextProps.title : undefined,
      };

      this.setState({
        isSuccess: false,
        isErrorResponse: false,
        ...titleObject,
        email: emailNoFake,
        emailError: '',
        sessionEmail: emailNoFake,
      });
    }
  }

  public render() {
    const { isNewDesignSavedSearch, isAuthenticated } = this.props;

    return (
      <PopupMobile
        containerStyle={styles['container']}
        innerStyle={styles['inner']}
        contentStyle={mergeStyles(
          styles['content'],
          this.props.isAuthenticated && styles['cotnent--auth'],
        ).className}
        opened={this.props.opened}
        onClickOutside={this.handleClose}
      >
        <div className={styles['close-icon']} onClick={this.handleClose}>
          <Icon name="close12"/>
        </div>
        <h3 className={styles['title']}>
          {this.state.isSuccess
            ? <>Поиск сохранён <span className={styles['emoji']}>😊</span></>
            : this.getTitle(isNewDesignSavedSearch, isAuthenticated)
          }
        </h3>
        {this.state.isSuccess ? this.renderSuccess() : this.renderForm()}
      </PopupMobile>
    );
  }

  private getTitle(isNewDesignSavedSearch: boolean, isAuthenticated: boolean) {
    if (isNewDesignSavedSearch) {
      return (
        <>
          <div className={styles['header']}>Сохраните поиск</div>
          <div className={styles['sub-header']}>
            Мы будем присылать новые объявления<br/>по вашему поиску
          </div>
        </>
      );
    }

    return 'Сохранение поиска';
  }

  private renderSuccess() {
    const { isAuthenticated } = this.props;

    return (
      <div className={styles['success-container']}>
        <div className={styles['success-text']}>
          {
            isAuthenticated && this.isSameEmail()
              ? 'Мы оповестим вас, когда появятся новые объявления по вашему поиску'
              : 'Активируйте вашу подписку с помощью ссылки из письма, отправленного на адрес'
          }
        </div>
        <div className={styles['success-email']}>
          {this.state.email}
        </div>
      </div>
    );
  }

  private isSameEmail() {
    const trimmedEmail = this.state.email.trim();

    return (this.state.sessionEmail || '').toLowerCase() === trimmedEmail.toLowerCase();
  }

  private renderLabel() {
    return (
      <div className={styles['label-container']}>
        <span className={styles['label']}>
          Ваш email
        </span>
        <span onClick={this.handleLabelClick} className={styles['icon-container']}>
          <TooltipPopup
            containerClass={styles.tooltip}
            direction="left"
            handlePopupClose={this.handleTooltipHide}
            open={this.state.isTooltipVisible}
          >
            Для получения уведомлений  о новых объявлениях
          </TooltipPopup>
        </span>
      </div>
    );
  }

  private renderForm() {
    if (this.props.isNewDesignSavedSearch) {
      return (
        <SavedSearchForm
          title={this.state.title}
          isErrorResponse={this.state.isErrorResponse}
          isLoader={this.state.isLoader}
          emailError={this.state.emailError}
          email={this.state.email}
          notificationFrequency={this.state.notificationFrequency}
          isNews={this.state.isNews}
          pushSubscribed={this.props.pushSubscribed}
          isPushCheck={this.state.isPushCheck}
          isAuthenticated={this.props.isAuthenticated}

          handleTitleChange={title => this.setState({ title, isErrorResponse: false })}
          handleEmailChange={email => this.setState({ email, isErrorResponse: false, emailError: '' })}
          handleNewsChange={isNews => this.setState({ isNews })}
          handlePushChange={isPushCheck => this.setState({ isPushCheck })}
          handleFrequencyChange={notificationFrequency => this.setState({ notificationFrequency })}
          handleSave={this.handleSave}
        />
      );
    }

    return (
      <form className={styles['form']}>
        <div className={styles['field']}>
          <Label label="Название поиска">
            <TextField
              value={this.state.title}
              invalid={this.state.isErrorResponse}
              disabled={this.state.isLoader}
              onValueChange={this.handleTitleChange}
              inputStyle={styles.input}
              placeholder="Поиск"
            />
          </Label>
        </div>
        <div className={styles['field']}>
          <Label label={this.renderLabel()}>
            <Tooltip tooltipContent={this.state.emailError} position={0}>
              <TextField
                invalid={Boolean(this.state.emailError || this.state.isErrorResponse)}
                value={this.state.email}
                onValueChange={this.handleEmailChange}
                disabled={this.state.isLoader}
                inputStyle={styles.input}
                placeholder="Email"
              />
            </Tooltip>
          </Label>
        </div>
        <div className={`${styles['field']} ${styles['field_notification']}`}>
          <Label label="Частота уведомлений">
            <DropdownField
              value={this.state.notificationFrequency}
              onValueChange={(value: TNotificationFrequencyValue) => {
                this.setState({ notificationFrequency: value });
              }}
              values={NOTIFICATION_FREQUENCY}
              containerStyle={styles['subscription-period']}
              popupStyle={styles['popup-period']}
            />
          </Label>
          {
            this.state.isLoader &&
              <div className={styles['field_loader']} />
          }
        </div>
        <CheckboxField
          containerStyle={styles['checkbox']}
          value={this.state.isNews}
          onValueChange={this.handleCheckboxChange}
          label="Хочу получать новости Cian.ru"
        />
        {
          this.props.pushSubscribed &&
            <CheckboxField
              containerStyle={styles['checkbox']}
              value={this.state.isPushCheck}
              onValueChange={(value: boolean) => this.setState({ isPushCheck: value })}
              label="Включить push-уведомления"
            />
        }
        <Button
          onClick={this.handleSave}
          theme={ButtonTheme.BLUE_SOLID}
          type="submit"
          buttonStyle={
            mergeStyles([
              styles['button'],
              this.state.isLoader && styles['button--loader'],
            ]).className
          }
        >
          <span className={styles['button-text']}>
            Сохранить
          </span>
        </Button>
        {!this.props.isAuthenticated && <License />}
      </form>
    );
  }

  private handleClose = () => {
    this.props.closeSaveFilterPopup();
  }

  private getEmailError = () => {
    const { email } = this.state;

    if (!email.trim()) {
      return 'Введите email';
    } else if (!isEmail(email)) {
      return 'Неверный e-mail адрес';
    }

    return '';
  }

  private handleTooltipHide = () => {
    this.setState({
      isTooltipVisible: false,
    });
  }

  private handleLabelClick = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    this.setState({
      isTooltipVisible: true,
    });
  }

  private handleTitleChange = (title: string) => {
    this.setState({
      title,
      isErrorResponse: false,
    });
  }

  private handleEmailChange = (email: string) => {
    this.setState({
      email,
      emailError: '',
      isErrorResponse: false,
    });
  }

  private handleCheckboxChange = (isNews: boolean) => {
    this.setState({
      isNews,
    });
  }

  private handleSave = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (this.state.isLoader) {
      return;
    }

    const pageType = this.props.isCardRoute ? 'card' : 'cat';
    const emailError = this.getEmailError();
    const { title, notificationFrequency, titleAutoSet } = this.state;
    const { isFromFilter, queryString } = this.props;

    if (emailError) {
      this.setState({
        emailError,
      });

      return;
    }

    this.setState({
      isLoader: true,
    });

    if (this.props.isNewDesignSavedSearch && !title && titleAutoSet) {
      this.setState({ title: titleAutoSet });
    }

    this.props.saveSearch(
      this.state.email,
      title || titleAutoSet || '',
      this.state.isNews,
      mapNotificationFrequency(notificationFrequency),
    )
      .then((res) => {
        if (!res) {
          throw res;
        }

        if (this.props.isFromBanner) {
          trackSubscribe(notificationFrequency, true, false, queryString);
        } else {
          trackSubscribe(notificationFrequency, false, isFromFilter, queryString);
        }

        if (this.state.isNews) {
          trackSubscribeWithFlag(pageType);
        }

        if (this.state.isPushCheck && window.dataLayer && this.props.pushSubscribed) {
          window.dataLayer.push({ event: FLOCKTORY_KEY_EVENT });
        }

        if (res.auth) {
          trackNewAccountRegistration(pageType);
        }

        this.setState({
          isSuccess: true,
          isLoader: false,
        });
      })
      .catch(() => {
        this.setState({
          isErrorResponse: true,
          isLoader: false,
        });
      });
  }
}
