import * as React from 'react';

import ButtonRadioGroupField from '../../../ui/button_radio_group_field/button_radio_group_field';
import {
  IFieldProps,
  isCommercialObjectGroup,
  isFlatObjectGroup,
  isNotUpcomingSale,
  isSaleType,
  IsVisibleCheck,
  PriceType,
  reduceVisibilityCheck,
} from '../field';

const fieldStyles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    return this.props.filter.priceType !== nextProps.filter.priceType;
  }

  public render() {
    const { filter: { priceType } } = this.props;

    return (
      <div className={fieldStyles.field} data-mark="FieldPriceType">
        <ButtonRadioGroupField
          value={priceType}
          onValueChange={this.props.actions.setPriceType}
          values={[
              { value: PriceType.Total, label: 'За всё' },
              { value: PriceType.SM, label: 'За м\u00B2' },
          ]}
          buttonStyle={fieldStyles.priceTypeButton}
          activeButtonStyle={fieldStyles.active}
        />
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = reduceVisibilityCheck(
  (filter) => isFlatObjectGroup(filter) || isCommercialObjectGroup(filter),
  isSaleType,
  isNotUpcomingSale,
);
