import { TAuthenticationPopupActions, OPEN_TYPE, CLOSE_TYPE } from './actions';
import { IAuthenticationPopupState, defaultAuthenticationPopupState } from './model';

type Reducer = (state: IAuthenticationPopupState, action: TAuthenticationPopupActions) => IAuthenticationPopupState;

export const authenticationPopupReducer: Reducer = (
  state = defaultAuthenticationPopupState,
  action: TAuthenticationPopupActions,
) => {
  switch (action.type) {
    case OPEN_TYPE:
      return {
        ...state,
        isOpen: true,
        message: action.message,
      };

    case CLOSE_TYPE:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};
