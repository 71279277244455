import { CheckboxField } from '@cian/components';
import { mergeStyles } from '@cian/utils';
import * as React from 'react';

import { IFieldProps, isFlatObjectGroup, IsVisibleCheck } from '../../field';

const fieldStyles = require('../../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const { withBalcony, withLoggia } = this.props.filter;

    const labelStyles = mergeStyles(
      fieldStyles.labelCheckbox,
      (withBalcony || withLoggia) && fieldStyles.checked,
    ).className;

    return (
      <div data-mark="FieldBalcony">
        <CheckboxField
          mobile
          label="Балкон/Лоджия"
          labelStyle={labelStyles}
          value={withBalcony || withLoggia}
          onValueChange={this.onChange}
        />
      </div>
    );
  }

  private onChange = (value: boolean) => {
    this.props.actions.setWithBalcony(value);
    this.props.actions.setWithLoggia(value);
  }
}

export const isVisible: IsVisibleCheck = filter => isFlatObjectGroup(filter)
  && !filter.withNewobject;
