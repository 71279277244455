import * as React from 'react';

import Block from '../../../ui/block/block';
import { Field } from '../../field';
import * as fields from '..';
import { IFieldProps, IsVisibleCheck } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const isDesktop = this.props.viewMode === 'desktop';

    return (
      <Block
        title="Планировка"
        titleStyle={[styles.blockTitle, isDesktop && styles.desktop]}
        dataMark="BlockLayout"
      >
        <div className={styles.fieldBlock}>
          <Field field={fields.withLayout} />
          <Field field={fields.roomType} />
        </div>
      </Block>
    );
  }
}

export const isVisible: IsVisibleCheck = (filter) => fields.withLayout.isVisible(filter)
  || fields.roomType.isVisible(filter);
