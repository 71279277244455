import * as React from 'react';

import { shallowEqual } from '../../../utils/react_helpers';
import RangeComponent from '../../common/range/range';

import {
  FilterState,
  IFieldProps,
  isCommercialObjectGroupBuilding,
  isFlatObjectGroup,
  isSuburbanObjectGroupHouse,
  IsVisibleCheck,
  isCoworkingFilters,
} from '../field';

const styles = require('../field.css');

import { Label } from '@cian/components';

export class Component extends React.Component<IFieldProps, object> {

  public shouldComponentUpdate(nextProps: IFieldProps) {
    const {
      filter: {
        floorsNumber: nextFloors,
        dealType: nextDeal,
        selectedObjectTypes: nextTypes,
      },
    } = nextProps;
    const {
      filter: {
        floorsNumber: currentFloors,
        dealType: currentDeal,
        selectedObjectTypes: currentTypes,
      },
    } = this.props;

    return !shallowEqual(nextFloors, currentFloors) || !shallowEqual(nextTypes, currentTypes)
      || currentDeal !== nextDeal;
  }

  public render() {
    return (
      <div className={styles.label} data-mark="FieldFloorsNumber">
        <Label label={this.getLabel(this.props.filter)}>
          <RangeComponent
            field="floorsNumber"
          />
        </Label>
      </div>
    );
  }

  private getLabel = (filter: FilterState) => {
    if (isFlatObjectGroup(filter)) {
      return 'Этажей в доме';
    }
    if (isSuburbanObjectGroupHouse(filter)) {
      return 'Количество этажей';
    }
    if (isCommercialObjectGroupBuilding(filter) || isCoworkingFilters(filter)) {
      return 'Этажей в здании';
    }
    return 'Этажей';
  }
}

export const isVisible: IsVisibleCheck = filter =>
  (isFlatObjectGroup(filter)
  || isSuburbanObjectGroupHouse(filter)
  || isCommercialObjectGroupBuilding(filter)
  || isCoworkingFilters(filter));
