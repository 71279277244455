import * as React from 'react';
import { findDOMNode } from 'react-dom';

import { scrollToTopOf } from '../../../utils/scroll';
import { loadYmapsApi } from '../../../utils/ymaps';
import Suggest from '../../common/geo_search/suggest/new_suggest';
import { ClearButton, HideButton, MoreButton } from '../../common/geo_search/tags/buttons';
import { Tags, TagsLoader } from '../../common/geo_search/tags/tags';
import { IFieldProps, IGeoTag, isNotUpcomingSale, IsVisibleCheck } from '../field';

const styles = require('./geo_search.css');

interface IGeoSearchState {
  showAllTags: boolean;
}

export class Component extends React.Component<IFieldProps, IGeoSearchState> {
  public state: IGeoSearchState = {
    showAllTags: false,
  };

  public componentDidMount() {
    // preload
    this.loadYmaps();
  }

  public render() {
    const { filter, regions, ...props } = this.props;
    const { regionMeta } = regions;

    const boundedBy = regionMeta && regionMeta.location
      ? regionMeta.location.boundedBy
      : undefined;

    return (
      <div className={styles.field} data-mark="FieldGeoSearch">
        <Suggest
          region={filter.region}
          isTagLoading={filter.isGeoTagsLoading}
          actions={props.actions}
          boundedBy={boundedBy}
        />
        <Tags
          tags={this.state.showAllTags ? filter.geoTags : filter.geoTags.slice(0, 5)}
          region={filter.region}
          actions={props.actions}
          tagStyle={styles.tag}
          filtersPlace={props.filtersPlace}
        />
        {filter.geoTags.length > 5 && this.renderButtons(filter.geoTags)}
        {filter.isGeoTagsLoading && <TagsLoader />}
      </div>
    );
  }

  private renderButtons = (geoTags: IGeoTag[]) => {
    const { showAllTags } = this.state;

    return (
      <div style={{ marginTop: 15 }}>
          {!showAllTags &&
            <MoreButton count={geoTags.length - 5} onClick={this.toggleTags} />}
        {showAllTags &&
          <span className={styles.hideButton}>
            <HideButton onClick={this.toggleTags} />
          </span>}
        {showAllTags &&
          <ClearButton onClick={this.clearGeoTags} />}
      </div>
    );
  }

  private toggleTags = () => {
    if (this.state.showAllTags) {
      this.scrollToGeoSearch();
    }

    this.setState({ showAllTags: !this.state.showAllTags });
  }

  private scrollToGeoSearch = () => {
    scrollToTopOf(findDOMNode(this), -100);
  }

  private clearGeoTags = () => {
    this.props.actions.setGeoTags([]);
    this.scrollToGeoSearch();

    this.setState({ showAllTags: false });
  }

  private loadYmaps() {
    return loadYmapsApi(['suggest', 'behavior.DblClickZoom']);
  }
}

export const isVisible: IsVisibleCheck = isNotUpcomingSale;
