export type TNotificationFrequencyValue = 1 | 2 | 3;
export type TNotificationFrequency =
  'everyDay' |
  'everyHour' |
  'everyWeek';

export const NOTIFICATION_FREQUENCY = [
  { value: 1, label: 'Каждый час' },
  { value: 2, label: 'Каждый день' },
  { value: 3, label: 'Раз в неделю' },
];

export function mapNotificationFrequency(value: TNotificationFrequencyValue): TNotificationFrequency {
  const notificationMap: { [key: number]: TNotificationFrequency } = {
    1: 'everyHour',
    2: 'everyDay',
    3: 'everyWeek',
  };

  return notificationMap[value];
}
