import { IStyleConfig, mergeStyles } from '@cian/components';
import * as React from 'react';
import { animateVisibility } from '../../ui/animation/visibility/visibility';
import Error from '../../ui/error/error';
import { Portal } from '../../ui/portal/portal';
import { IBodyScrollController } from '../../utils/body_scroll';
import { isIE } from '../../utils/device_detector';
import { createClickOutsideHandler } from '../click_outside/click_outside';
import { htmlButtonType } from '../../../shared/types/buttonHtml';

export type PopupMobileOwnProps = {
  containerStyle?: IStyleConfig;
  onTransitionEnd?: React.TransitionEventHandler<HTMLElement>;
  onShow?: () => void;
  innerStyle?: IStyleConfig;
  contentStyle?: IStyleConfig;
  buttonsStyle?: IStyleConfig;
  errorStyle?: IStyleConfig;
  onToggle?: (opened: boolean) => void;
  buttons?: IPopupMobileButton[];
  header?: React.ReactNode;
  error?: React.ReactNode;
  contentRef?: React.Ref<HTMLDivElement>;
  footer?: React.ReactNode;
  bodyScroll?: IBodyScrollController;
  onClickOutside?: () => void;
  opened: boolean;
  dataMark?: string;
};

export interface IPopupMobileButton {
  id: string;
  text: React.ReactNode;
  type?: string;
  onClick(): void;
}

const style = require('./popup.css');

export class PopupInner extends React.Component<PopupMobileOwnProps, object> {
  private wrapper: HTMLElement | null = null;
  private clickOutsideHandler: (e: Event) => void;
  private lastTouchTime = 0;

  public componentDidMount() {
    if (this.props.onClickOutside && this.wrapper) {
      this.clickOutsideHandler = createClickOutsideHandler(this.props.onClickOutside, this.wrapper);
      document.addEventListener('click', this.clickOutsideHandler, true);
    }
  }

  public componentWillUnmount() {
    if (this.clickOutsideHandler) {
      document.removeEventListener('click', this.clickOutsideHandler, true);
    }
  }

  public render() {
    const { header, error, footer } = this.props;
    const haveButtons: boolean = !!(this.props.buttons || []).length;

    const innerContainerStyle = mergeStyles([
      style.inner,
    ].concat(this.props.innerStyle));

    const contentStyle = mergeStyles(
      style.content,
      !haveButtons && style.circled,
      this.props.contentStyle,
    );

    return (
      <div {...innerContainerStyle}
        onClick={this.handleContentClick}
        onTransitionEnd={this.props.onTransitionEnd}
        onTouchStart={this.handleContentTouch}
        ref={r => this.wrapper = r}
        data-mark={`${this.props.dataMark ? this.props.dataMark + '|' : ''}popup`}
      >
        {header}

        {!!error && <Error errorStyle={this.props.errorStyle}>{error}</Error>}

        <div {...contentStyle} ref={this.props.contentRef}>
          {this.props.children}
        </div>

        <div {...mergeStyles(style.buttons, this.props.buttonsStyle) }>
          {haveButtons && this.props.buttons!.map(this.getButton)}
        </div>
        {footer}
      </div>
    );
  }

  private getButton = (button: IPopupMobileButton) => {
    return (
      <button
        key={button.id}
        className={style.button}
        type={(button.type as htmlButtonType) || 'button'}
        onClick={button.onClick}
        data-mark={`${this.props.dataMark ? this.props.dataMark + '|' : '' }button`}
      >
        {button.text}
      </button>
    );
  }

  private handleContentClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  }

  private handleContentTouch = (e: React.TouchEvent<HTMLElement>) => {
    e.stopPropagation();

    const newTime = new Date().getTime();
    const minDelta = 300;

    if (Math.abs(this.lastTouchTime - newTime) < minDelta) {
      e.preventDefault();
    }

    this.lastTouchTime = newTime;
  }
}

const AnimatedPopup = animateVisibility(PopupInner);

type PopupMobileProps = PopupMobileOwnProps;

export class PopupMobile extends React.Component<PopupMobileProps, {}> {
  private animationTime = 300; // завязано на css

  public render() {
    const containerStyle = mergeStyles([
      style.container,
    ].concat(this.props.containerStyle));

    return (
      <Portal>
        <AnimatedPopup
          {...this.props}
          containerStyle={containerStyle.className}
          visibleStyle={style.opened}
          visible={this.props.opened}
          onClick={this.handleTogglePopup}
          onShow={this.onShow}
          onBeforeHide={this.onBeforeHide}
          onHide={this.reLayoutHack}
        />
      </Portal>
    );
  }

  public componentWillUnmount() {
    this.onBeforeHide();
  }

  private onShow = () => {
    const { bodyScroll, onShow } = this.props;
    if (bodyScroll) {
        // дизейблим скролл только после того как отработала анимация
       setTimeout(() => {
          bodyScroll.disable();
      }, this.animationTime);
    }

    if (onShow) {
      onShow();
    }
  }

  private onBeforeHide = () => {
    if (this.props.bodyScroll) {
      this.props.bodyScroll.enable();
    }
  }

  private reLayoutHack() {
    //При закрытии попапов в IE могут пропадать элементы под попапом.
    //Вызываем relayout чтобы устранить последствия

    if (isIE()) {
      setTimeout(() => {
        document.body.getBoundingClientRect();
      }, this.animationTime);
    }
  }

  private handleTogglePopup = () => {
    if (this.props.onToggle) {
      this.props.onToggle(Boolean(this.props.opened));
    }
  }
}
