import { TGetGeoSeoLinksActions } from './actions';
import { defaultGeoSeoLinksState, GeoSeoLinksState } from './model';

type Reducer = (state: GeoSeoLinksState, action: TGetGeoSeoLinksActions) => GeoSeoLinksState;

const geoSeoLinksReducer: Reducer = (state = defaultGeoSeoLinksState, action) => {
  switch (action.type) {
    case 'GetGeoSeoLinksSuccess':
      return action.payload.geoSeoLinks;

    default:
      return state;
  }
};

export default geoSeoLinksReducer;
