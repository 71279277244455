const toDesktop = () => {
  document.cookie = 'flavour=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
  document.cookie = 'flavour=full;path=/;domain=.cian.ru';
  window.location.reload();
};

export const DESKTOP_LINKS = {
  ADD: '/razmestit-obyavlenie/',
  TERMS_OF_USE: 'https://www.cian.ru/help/about/rules-legal/',
  PRIVACY_POLICY: 'https://www.cian.ru/help/about/36526/',
  CONTENT_MAIN: '/magazine/',
  CONTENT_ADVICE: 'https://cian.ru/forum-rieltorov/',
  CHATS: '/dialogs/',
  MORTGAGE: '/ipoteka/',
  MY_OFFERS: '/cat.php?id_user={{userId}}&deal_type=1&engine_version=2',
};

export default toDesktop;
