import { IOfferAnalyticsInfo } from '../../redux/offer/model';
import { IOfferAnalyticsData } from '../analytics';

export function getOfferAnalyticsData(analyticsInfo?: IOfferAnalyticsInfo): IOfferAnalyticsData {
  return {
    id: analyticsInfo && analyticsInfo.id,
    cianId: analyticsInfo && analyticsInfo.cianId,
    ownerId: analyticsInfo && analyticsInfo.ownerId,
    ownerCianId: analyticsInfo && analyticsInfo.ownerCianId,
    dealType: analyticsInfo && analyticsInfo.dealType,
    objectType: analyticsInfo && analyticsInfo.objectType,
    position: analyticsInfo && analyticsInfo.position,
    published: analyticsInfo && analyticsInfo.published,
    price: analyticsInfo && analyticsInfo.price,
    variant: analyticsInfo && analyticsInfo.variant,
    photosCount: analyticsInfo && analyticsInfo.photosCount,
    hiddenBase: analyticsInfo && analyticsInfo.hiddenBase,
    owner: analyticsInfo && analyticsInfo.owner,
    podSnos: analyticsInfo && analyticsInfo.podSnos,
    brand: analyticsInfo && analyticsInfo.brand,
    parentId: analyticsInfo && analyticsInfo.parentId,
  };
}
