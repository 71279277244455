import { Label } from '@cian/components';

import * as React from 'react';

import { DeviceType } from '../../../redux/common/model';
import RangeComponent from '../../common/range/range';
import { isCommercialObjectType } from '../../common/select_helper';
import { IsVisibleCheck, transformToField } from '../field';

const styles = require('../field.css');

interface ITotalAreaProps {
  isCommercial: boolean;
  viewMode: DeviceType;
}

class TotalArea extends React.PureComponent<ITotalAreaProps, object> {
  public render() {
    return (
      <div className={styles.label} data-mark="FieldTotalArea">
        <Label label="Общая площадь">
          <RangeComponent field="sizeTotal" metric={!this.props.isCommercial ? 'м²' : undefined} />
        </Label>
      </div>
    );
  }
}

export const Component = transformToField(({ filter, viewMode }) => {
  const selectedObjectTypes = filter.selectedObjectTypes;

  return {
    viewMode,
    isCommercial: isCommercialObjectType(selectedObjectTypes),
  };
})(TotalArea);

export const isVisible: IsVisibleCheck = filter => {
  return !filter.withNewobject;
};
