import * as React from 'react';

import { PublicationPeriod } from '../../../redux/filters/model';
import Block from '../../../ui/block/block';
import { IFieldProps, IsVisibleCheck } from '../field';

import { isSafari } from '../../../utils/device_detector';
import { SelectMobile } from '../../common/select/select';
import { createSelectOptions } from '../../common/select_helper';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  private selectId = 'select-publication-period';
  private select: HTMLSelectElement | null;

  private options = [{
    label: '',
    options: [{
      id: String(PublicationPeriod.Unexpected),
      label: 'Неважно',
    }, {
      id: String(PublicationPeriod.Today),
      label: 'за сегодня',
    }, {
      id: String(PublicationPeriod.Minutes5),
      label: 'за 5 мин.',
    }, {
      id: String(PublicationPeriod.Minutes10),
      label: 'за 10 мин.',
    }, {
      id: String(PublicationPeriod.Minutes15),
      label: 'за 15 мин.',
    }, {
      id: String(PublicationPeriod.Minutes20),
      label: 'за 20 мин.',
    }, {
      id: String(PublicationPeriod.Minutes30),
      label: 'за 30 мин.',
    }, {
      id: String(PublicationPeriod.Hours1),
      label: 'за 1 час',
    }, {
      id: String(PublicationPeriod.Hours2),
      label: 'за 2 часа',
    }, {
      id: String(PublicationPeriod.Hours3),
      label: 'за 3 часа',
    }, {
      id: String(PublicationPeriod.Hours4),
      label: 'за 4 часа',
    }, {
      id: String(PublicationPeriod.Hours5),
      label: 'за 5 часов',
    }, {
      id: String(PublicationPeriod.Hours6),
      label: 'за 6 часов',
    }, {
      id: String(PublicationPeriod.Hours12),
      label: 'за 12 часов',
    }, {
      id: String(PublicationPeriod.Hours18),
      label: 'за 18 часов',
    }, {
      id: String(PublicationPeriod.Hours24),
      label: 'за сутки',
    }, {
      id: String(PublicationPeriod.Hours36),
      label: 'за 36 часов',
    }, {
      id: String(PublicationPeriod.Days2),
      label: 'за 2 дня',
    }, {
      id: String(PublicationPeriod.Days3),
      label: 'за 3 дня',
    }, {
      id: String(PublicationPeriod.Days5),
      label: 'за 5 дней',
    }, {
      id: String(PublicationPeriod.Days10),
      label: 'за 10 дней',
    }, {
      id: String(PublicationPeriod.Month),
      label: 'за месяц',
    }],
  }];

  public componentDidMount() {
    if (typeof window !== 'undefined') {
      this.select = document.getElementById(this.selectId) as HTMLSelectElement;
    }
  }

  public render() {
    const processedOptions = createSelectOptions(
      this.options,
      [String(this.props.filter.publicationPeriod)],
      this.props.filter,
    );

    return (
      <Block
        title="Дата публикации объявления"
        dataMark="BlockPublicationPeriod"
        blockStyle={styles['block-reset']}
      >
        <div className={styles.fieldBlock}>
          <div className={styles.field}>
            <SelectMobile
              id={this.selectId}
              name="selectMobile"
              label="Неважно"
              onValueChange={this.handleChange}
              value={processedOptions.value}
              options={processedOptions.options[0].options}
            />
          </div>
        </div>
      </Block>
    );
  }

  private handleChange = (options: string[]) => {
    // it fixes bug CSSSR-6910. In mobile Safari not a multiple select
    // can visually select few values, but actually only one value will be selected
    if (this.select && isSafari(this.props.userAgent || '')) {
      this.select.blur();
    }

    const value = Array.isArray(options) ? Number(options[0])
      : options ? Number(options) : undefined;

    this.props.actions.setPublicationPeriod(value);
  }
}

export const isVisible: IsVisibleCheck = filter => !!filter.selectedObjectTypes.length;
