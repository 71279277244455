import { CheckboxField, ISelectMobileGroup, mergeStyles } from '@cian/components';
import { includes, memoize } from 'lodash';
import * as React from 'react';

import { PopupMobile } from '../../../ui/popup/popup';
import Text from '../../../ui/text/text';
import { createBodyScroll } from '../../../utils/body_scroll';

import {
  commercialObjectType,
  commercialObjectTypes,
  FilterState,
  flatObjectType,
  flatObjectTypes,
  objectTypeIsNotMultiple,
  objectTypeVisibility,
  suburbanObjectType,
  suburbanObjectTypes,
  TObjectType,
} from '../../../redux/filters/model';

import * as filterActions from '../../../redux/filters/actions';
import { DealType } from '../../../redux/filters/model';

import {
  createSelectOptions,
  IOption,
  IOptionsList,
  IProcessedOptions,
} from '../../common/select_helper';

const style = require('./select_object_type.css');
const fieldStyle = require('../field.css');

const bodyScroll = createBodyScroll();

enum ObjectsGroup {
  Commercial,
  Flat,
  Suburban,
  Nothing,
}

interface IObjectTypesPopupProps {
  options: ISelectMobileGroup[];
  selectedObjectTypes: TObjectType[];
  withNewObject?: boolean;
  resaleOnly?: boolean;
  opened: boolean;
  onClose: () => void;
  extraFilters?: boolean;
  actions: typeof filterActions;
  dealType?: DealType;
  userAgent?: string;
  dataMark?: string;
  isUpcomingSale?: boolean;
  isNewFiltersAvailable?: boolean;
}

interface IObjectTypePopupState {
  tmpIsUpcomingSale?: boolean;
  tmpOptions?: TObjectType[];
  tmpNewObject?: boolean;
  tmpResaleOnly?: boolean;
}

function createObjectTypesList(): IOptionsList[] {
  const options: IOptionsList[] = [
    {
      label: 'Жилая',
      options: [{
        label: 'Квартира',
        id: flatObjectType.Apartment,
      }, {
        label: 'Комната',
        id: flatObjectType.Room,
      }, {
        label: 'Доля',
        id: flatObjectType.Part,
      }, {
        label: 'Койко-место',
        id: flatObjectType.Bed,
      }],
    },
    {
      label: '',
      options: [{
        label: 'Дом',
        id: suburbanObjectType.House,
      }, {
        label: 'Часть дома',
        id: suburbanObjectType.PartOfHouse,
      }, {
        label: 'Таунхаус',
        id: suburbanObjectType.TownHouse,
      }, {
        label: 'Участок',
        id: suburbanObjectType.Stead,
      }],
    },
    {
      label: 'Коммерческая',
      options: [{
        label: 'Офис',
        id: commercialObjectType.Office,
      }, {
        label: 'Коворкинг',
        id: commercialObjectType.Coworking,
      }, {
        label: 'Торговая площадь',
        id: commercialObjectType.Marketplace,
      }, {
        label: 'Склад',
        id: commercialObjectType.Warehouse,
      }, {
        label: 'Помещение свободного назначения',
        id: commercialObjectType.Psn,
      }, {
        label: 'Общепит',
        id: commercialObjectType.PublicCatering,
      }, {
        label: 'Гараж',
        id: commercialObjectType.Garage,
      }, {
        label: 'Производство',
        id: commercialObjectType.Production,
      }, {
        label: 'Автосервис',
        id: commercialObjectType.CarService,
      }, {
        label: 'Здание',
        id: commercialObjectType.Building,
      }, {
        label: 'Бытовые услуги',
        id: commercialObjectType.DomesticService,
      }, {
        label: 'Коммерческая земля',
        id: commercialObjectType.CommercialLand,
      }, {
        label: 'Арендный бизнес',
        id: commercialObjectType.RentalBusiness,
      }, {
        label: 'Готовый бизнес',
        id: commercialObjectType.ReadyBusiness,
      }],
    }];

  options.forEach(group => {
    group.options.forEach(option => {
      option.disableIn = objectTypeVisibility[option.id];
      option.isNotMultiple = objectTypeIsNotMultiple[option.id];
    });
    group.disableIn = [
      (filter) => group.options.every(
        option => !!option.disableIn && option.disableIn.some(
          rule => rule(filter),
        ),
      ),
    ];
  });
  return options;
}

export const objectTypesList = createObjectTypesList();

export function getProcessedOptions(filter: FilterState, isNewFiltersAvailable?: boolean): IProcessedOptions {
  let objectTypes = objectTypesList;
  if (isNewFiltersAvailable) {
    objectTypes = objectTypesList.slice(2);
    objectTypes[0].options = objectTypes[0].options.filter(o => o.id !== commercialObjectType.Garage);
  }

  return createSelectOptions(
    objectTypes,
    filter.selectedObjectTypes,
    filter,
  );
}

export class ObjectTypesPopup extends React.Component<IObjectTypesPopupProps, IObjectTypePopupState> {

  public state: IObjectTypePopupState = {
    tmpNewObject: false,
    tmpIsUpcomingSale: undefined,
    tmpResaleOnly: false,
    tmpOptions: [],
  };

  private currentGroup: ObjectsGroup;

  public componentWillReceiveProps(newProps: IObjectTypesPopupProps) {
    this.currentGroup = this.getNewGroup(newProps.selectedObjectTypes);
    if (newProps.opened && !this.props.opened) {
      this.setState({
        tmpIsUpcomingSale: newProps.isUpcomingSale,
        tmpNewObject: newProps.withNewObject,
        tmpResaleOnly: newProps.resaleOnly,
        tmpOptions: newProps.selectedObjectTypes,
      });
    }
  }

  public render() {
    const popupButtons = [{
        id: 'cancel',
        text: 'Отменить',
        onClick: this.cancel,
      }, {
        id: 'apply',
        text: 'Применить',
        onClick: this.confirm,
      }];

    return (
      <PopupMobile
        opened={this.props.opened}
        innerStyle={[style.popupContainer]}
        contentStyle={[style.popupContent]}
        onToggle={this.cancel}
        bodyScroll={bodyScroll}
        buttons={popupButtons}
        dataMark={this.props.dataMark}
      >
        {this.props.options.map(this.renderOption)}
      </PopupMobile>
    );
  }

  private renderOption = (option: IOptionsList, index: number) => {
    const groupStyles = mergeStyles(
      style.group,
    );

    return option.options.length > 0 && (
      <div key={index} {...groupStyles}>
        {option.label && <Text
          textStyles={[style.groupTitle]}
          title
        >
          {option.label}
        </Text>}
        <div>
          {option.options.map(this.renderCheckbox)}
        </div>
      </div>
    );
  }

  private renderCheckbox = (option: IOption, index: number) => {
    const checked: boolean = includes(this.props.selectedObjectTypes, option.id);

    const renderAppartmentTypeFilters = this.props.extraFilters && option.id === flatObjectType.Apartment;

    const checkboxStyles = mergeStyles(
      fieldStyle.labelCheckbox,
      style.labelCheckbox,
      checked && fieldStyle.checked,
    );

    const checkboxContainerStyles = mergeStyles(
      style.checkboxContainer,
    );

    return (
      <div
        key={option.id + index} {...checkboxContainerStyles}
        data-mark={`${this.props.dataMark ? this.props.dataMark + '|' : ''}checkbox`}>
        <CheckboxField
          mobile
          label={option.label}
          value={checked}
          onValueChange={this.handleObjectTypeChange(option.id, option.isNotMultiple)}
          labelStyle={checkboxStyles.className}
        />
        {renderAppartmentTypeFilters && this.renderAppartmentTypeOptions(checked)}
      </div>
    );
  }

  private renderAppartmentTypeOptions = (apartmentIsChecked: boolean) => {
    const isOnlyNewObject = apartmentIsChecked && !!this.props.withNewObject;
    const isOnlyResale = apartmentIsChecked && !!this.props.resaleOnly;
    const subcontainerStyles = mergeStyles(
      style.subCheckboxContainer,
    );
    const checkboxContainerStyles = mergeStyles(
      style.checkboxContainer,
    );

    return (
      <div {...subcontainerStyles}>
        <div {...checkboxContainerStyles} data-mark={`${this.props.dataMark}|AppartmentType|checkbox`}>
          <CheckboxField
            mobile
            label={'Только в новостройке'}
            value={isOnlyNewObject}
            onValueChange={this.setWithNewObject}
            labelStyle={mergeStyles(
              fieldStyle.labelCheckbox,
              style.labelCheckbox,
              isOnlyNewObject && fieldStyle.checked,
            ).className}
          />
        </div>
        <div {...checkboxContainerStyles} data-mark={`${this.props.dataMark}|AppartmentType|checkbox`}>
          <CheckboxField
            mobile
            label={'Только во вторичке'}
            value={isOnlyResale}
            onValueChange={this.setResaleOnly}
            labelStyle={mergeStyles(
              fieldStyle.labelCheckbox,
              style.labelCheckbox,
              isOnlyResale && fieldStyle.checked,
            ).className}
          />
        </div>
      </div>
    );
  }

  private handleObjectTypeChange: any = memoize( // tslint:disable-line
    (id: TObjectType, isNotMultiple?: boolean): any => // tslint:disable-line
      (value: boolean) => {
        if (value) {
          if (isNotMultiple) {
            this.setObjectTypes([id]);
          } else {
            this.selectObjectType(id);
          }
        } else {
          const options = this.props.selectedObjectTypes.filter(t => t !== id);
          this.setObjectTypes(options);
        }
      },
  );

  private selectObjectType(objectType: TObjectType) {
    const { selectedObjectTypes } = this.props;
    if (!selectedObjectTypes.includes(objectType)) {
      const options = selectedObjectTypes.filter(o => !objectTypeIsNotMultiple[o]).concat(objectType);
      this.setObjectTypes(options);
    }
  }

  private setObjectTypes(objectTypes: TObjectType[]) {
    this.currentGroup = this.getNewGroup(objectTypes);
    this.sendOptions(objectTypes);
  }

  private setWithNewObject = (value: boolean) => {
    const { actions } = this.props;
    actions.setWithNewobject(value);
    if (value) {
      actions.setResaleOnly(!value);
      this.selectObjectType(flatObjectType.Apartment);
    }
  }

  private setResaleOnly = (value: boolean) => {
    const { actions } = this.props;

    actions.setResaleOnly(value);
    if (value) {
      actions.setWithNewobject(!value);
      this.selectObjectType(flatObjectType.Apartment);
    }
  }

  private allInGroup = (options: string[], group: string[]): boolean => {
    if (options.length === 0) {
      return false;
    }

    return options.every(o => group.some(g => o === g));
  }

  private someInGroup = (options: string[], group: string[]): boolean => {
    if (options.length === 0) {
      return false;
    }

    return options.some(o => group.some(g => o === g));
  }

  private getNewSubGroup = (options: string[]): ObjectsGroup => {
    if (this.someInGroup(options, flatObjectTypes)
      && this.currentGroup !== ObjectsGroup.Flat) {
      return ObjectsGroup.Flat;
    } else if (this.someInGroup(options, suburbanObjectTypes)
      && this.currentGroup !== ObjectsGroup.Suburban) {
      return ObjectsGroup.Suburban;
    } else if (this.someInGroup(options, commercialObjectTypes)
      && this.currentGroup !== ObjectsGroup.Commercial) {
      return ObjectsGroup.Commercial;
    }

    return ObjectsGroup.Nothing;
  }

  private getNewGroup = (options: string[]): ObjectsGroup => {
    if (this.allInGroup(options, flatObjectTypes)) {
      return ObjectsGroup.Flat;
    } else if (this.allInGroup(options, suburbanObjectTypes)) {
      return ObjectsGroup.Suburban;
    } else if (this.allInGroup(options, commercialObjectTypes)) {
      return ObjectsGroup.Commercial;
    }

    return this.getNewSubGroup(options);
  }

  private filterGroup = (options: TObjectType[], group: ObjectsGroup): TObjectType[] => {
    if (options.length === 0) {
      return options;
    }

    if (group === ObjectsGroup.Flat) {
      return options.filter(o => flatObjectTypes.some(t => t === o));
    } else if (group === ObjectsGroup.Suburban) {
      return options.filter(o => suburbanObjectTypes.some(t => t === o));
    } else if (group === ObjectsGroup.Commercial) {
      return options.filter(o => commercialObjectTypes.some(t => t === o));
    }

    return [];
  }

  private sendOptions = (options: TObjectType[]) => {
    const selectedValues = this.filterGroup(options, this.currentGroup);

    // Если выбрали коммерческий тип недвижимости в фильтрах, сбрасываем гео теги Ж/Д
    // as any потому что commercialObjectTypes видит типы только для commercial'ов,
    // а в objectType может быть любой тип объекта
    // tslint:disable-next-line
    if (selectedValues.find(selectedValue => commercialObjectTypes.includes(selectedValue as any))) {
      this.props.actions.deteleRailwayTags();
    }

    this.props.actions.setSelectedObjectTypes(selectedValues);
  }

  private cancel = () => {
    this.props.onClose();

    if (this.state.tmpOptions) {
      this.props.actions.setSelectedObjectTypes(this.state.tmpOptions);
    }
    if (this.state.tmpNewObject != null) {
      this.props.actions.setWithNewobject(this.state.tmpNewObject);
    }
    if (this.state.tmpResaleOnly != null) {
      this.props.actions.setResaleOnly(this.state.tmpResaleOnly);
    }
    if (this.state.tmpIsUpcomingSale != null) {
      this.props.actions.setIsUpcomingSale(this.state.tmpIsUpcomingSale);
    }
  }

  private confirm = () => {
    this.props.onClose();
  }
}
