import * as React from 'react';

import { IReadyBusinessGroupItem } from '../../../../../../../../../shared/repositories/announcements/entities/AnnouncementReferences/ReadyBusinessGroupItem';
import { IGetBusinessPossibleAppointmentsResponse } from '../../../../../../../../../shared/repositories/announcements/v1/get-business-possible-appointments';

export function useAllBusinessAppointmentsGroups(
  businessPossibleAppointments: IGetBusinessPossibleAppointmentsResponse,
): Array<IReadyBusinessGroupItem> {
  return React.useMemo<Array<IReadyBusinessGroupItem>>(
    () =>
      [
        {
          groupType: 'rentalBusiness',
          items: businessPossibleAppointments.rentalBusiness.items,
          name: 'Арендный бизнес',
        },
      ].concat(businessPossibleAppointments.readyBusiness.groups),
    [businessPossibleAppointments.readyBusiness.groups, businessPossibleAppointments.rentalBusiness.items],
  );
}
