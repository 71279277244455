import { IOfferInfo } from '../../redux/offer/model';

import { FDealType, FOfferType } from './typings';

export function getDealTypeFromOffer(offer: IOfferInfo): FDealType {
  const category = (offer.category || '').toLowerCase();

  if (category.indexOf('daily') >= 0) {
    return FDealType.RentDaily;
  } else if (category.indexOf('rent') >= 0) {
    return FDealType.RentLongterm;
  } else if (category.indexOf('sale') >= 0) {
    return FDealType.Sale;
  }

  return FDealType.Unexpected;
}

export function getOfferTypeFromOffer(offer: IOfferInfo): FOfferType {
  let category: string | undefined = offer.category;

  if (!category) {
    return FOfferType.Unexpected;
  }

  if ([
      'buildingRent',
      'buildingSale',
      'businessRent',
      'businessSale',
      'carServiceRent',
      'carServiceSale',
      'commercialLandRent',
      'commercialLandSale',
      'domesticServicesRent',
      'domesticServicesSale',
      'freeAppointmentObjectRent',
      'freeAppointmentObjectSale',
      'garageRent',
      'garageSale',
      'industryRent',
      'industrySale',
      'officeRent',
      'officeSale',
      'publicCateringRent',
      'publicCateringSale',
      'shoppingAreaRent',
      'shoppingAreaSale',
      'warehouseRent',
      'warehouseSale',
    ].includes(category)
  ) {
    if (category.startsWith('building')) {
      return FOfferType.Building;
    }

    if (category.startsWith('business')) {
      return FOfferType.Business;
    }

    if (category.startsWith('carService')) {
      return FOfferType.AutoService;
    }

    if (category.startsWith('commercialLand')) {
      return FOfferType.CommercialLand;
    }

    if (category.startsWith('domesticServices')) {
      return FOfferType.DomesticServices;
    }

    if (category.startsWith('freeAppointmentObject')) {
      return FOfferType.FreeAppointmentObject;
    }

    if (category.startsWith('garage')) {
      return FOfferType.Garage;
    }

    if (category.startsWith('industry')) {
      return FOfferType.Manufacture;
    }

    if (category.startsWith('office')) {
      return FOfferType.Office;
    }

    if (category.startsWith('publicCatering')) {
      return FOfferType.PublicCatering;
    }

    if (category.startsWith('shoppingArea')) {
      return FOfferType.TradeArea;
    }

    if (category.startsWith('warehouse')) {
      return FOfferType.Warehouse;
    }
  }

  category = category.toLowerCase();

  if (category.includes('bed')) {
    return FOfferType.Bed;
  }

  if (category.includes('room')) {
    return FOfferType.Room;
  }

  if (category.includes('flat')) {
    if (category.includes('newbuilding')) {
      return FOfferType.FlatNew;
    }

    if (category.includes('share')) {
      return FOfferType.FlatShared;
    }

    return FOfferType.FlatOld;
  }

  if (category.includes('cottage')) {
    return FOfferType.House;
  }

  if (category.includes('house')) {
    if (category.includes('townhouse')) {
      return FOfferType.Townhouse;
    }

    if (category.includes('share')) {
      return FOfferType.HousePart;
    }

    return FOfferType.House;
  }

  if (category.includes('land')) {
    return FOfferType.Land;
  }

  return FOfferType.Unexpected;
}
