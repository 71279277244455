import { pathOr } from 'ramda';

import { IOfferInfo } from '../redux/offer/model';

export type TOffer = Pick<IOfferInfo, 'newbuildingDynamicCalltracking' | 'newbuilding' | 'dealType'>
  & Partial<Pick<IOfferInfo, 'offerAnalyticsInfo'>>;

export function getOfferExtra(offer: TOffer) {
  if (offer.dealType !== 'sale') {
    if (offer.offerAnalyticsInfo && offer.offerAnalyticsInfo.parentId != null) {
      return { parentId: Number(offer.offerAnalyticsInfo.parentId) };
    }

    return undefined;
  }

  const siteBlockId = pathOr(null, ['newbuildingDynamicCalltracking', 'siteBlockId'], offer);
  const isFromDeveloper = pathOr(false, ['newbuilding', 'isFromDeveloper'], offer);
  const isFromLeadFactory = pathOr(false, ['newbuilding', 'isFromLeadFactory'], offer);

  return {
    cplCalltracking: isFromDeveloper && !isFromLeadFactory ? 1 : 0,
    cplDynamicCalltracking: siteBlockId ? 1 : 0,
  };
}
