/* https://github.com/eldargab/translit */

export interface IMap {
  [key: string]: string;
}

export default function translit(map: IMap) {
  const keys = Object.keys(map).sort((a: string, b: string) => {
    return b.length - a.length;
  });

  function peek(str: string) {
    for (let i = 0; i < keys.length; i++) {
      if (startsWith(keys[i], str)) {
        return keys[i];
      }
    }

    return '';
  }

  return (str: string) => {
    let out = '';

    while (str) {
      const key = peek(str);

      if (key) {
        out += map[key];
        str = str.slice(key.length);
      } else {
        out += str[0];
        str = str.slice(1);
      }
    }
    return out;
  };
}

function startsWith(start: string, str: string) {
  for (let i = 0; i < start.length; i++) {
    if (start[i] !== str[i]) {
      return false;
    }
  }
  return true;
}
