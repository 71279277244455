import { EFeedbackComplaint } from './models';

import { IComplaintFeedbackBody } from '../../card/complaint/feedback_form';
import { TTypedThunkAction } from '../model';

export interface IFeedbackComplaintStateChanger {
  type: 'IFeedbackComplaintStateChanger';
  status: EFeedbackComplaint;
}

export interface IFeedbackComplaintStateSetComplaintId {
  type: 'IFeedbackComplaintStateSetComplaintId';
  complaintId: number;
}

export interface IFeedbackComplaintStateSetUserFeedback {
  type: 'IFeedbackComplaintStateSetUserFeedback';
  getUserFeedback: boolean;
}

export type TFeedbackComplaintActions =
  IFeedbackComplaintStateChanger |
  IFeedbackComplaintStateSetComplaintId |
  IFeedbackComplaintStateSetUserFeedback;
/* istanbul ignore next */
export function statusChanger(status: EFeedbackComplaint): IFeedbackComplaintStateChanger {
  return {
    type: 'IFeedbackComplaintStateChanger',
    status,
  };
}
/* istanbul ignore next */
export function setComplaintId(complaintId: number): IFeedbackComplaintStateSetComplaintId {
  return {
    type: 'IFeedbackComplaintStateSetComplaintId',
    complaintId,
  };
}

export function setUserFeedback(getUserFeedback: boolean): IFeedbackComplaintStateSetUserFeedback {
  return {
    type: 'IFeedbackComplaintStateSetUserFeedback',
    getUserFeedback,
  };
}
/* istanbul ignore next */
export function sendFeedbackComplaint(body: IComplaintFeedbackBody): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {

    dispatch(statusChanger(EFeedbackComplaint.loading));

    return api.sendComplaintFeedbackForm(body)
      .then(() => {
        dispatch(statusChanger(EFeedbackComplaint.success));
      })
      .catch(() => {
        dispatch(statusChanger(EFeedbackComplaint.error));
      });
  };
}
