/* eslint-disable max-lines */

import { ILogger } from '@cian/logger';

import { toPairs } from 'lodash';
import { push, replace } from 'react-router-redux';
import { Dispatch } from 'redux';

import { IRequestSubscribeNewResponse, ISearchOffersData, TSubscribeSource } from '../../api/api';
import { IJsonQuery } from '../../api/models/json_query';
import { ISearchMapRequestMain, PropertyType } from '../../api/models/map';
import { IGeo } from '../../api/models/offer_card';
import { TNotificationFrequency } from '../../components/save_filter_popup/ab/utils/notificationFrequency';
import { appendQueryParam, deserializeParams, removeQueryParam, serializeParams } from '../../utils/location';
import { IYmapsMap, IYmapsUtil, YMapsBoundedBox, YMapsCoords, bboxToQueryStringParam } from '../../utils/ymaps';
import { getHeaderData } from '../common/actions';
import { ResourceStatus } from '../common/model';
import { fetchGaDataLayer } from '../data_layer';
import { fetchFavoritesIDs } from '../favorites/actions';
import { getOfferCount, getUrlByJsonQuery, setInitialInfrastructure } from '../filters/actions';
import { filterToJsonQuery } from '../filters/filter_to_json_query';
import { ReduxState, TReduxActions, TTypedThunkAction } from '../model';
import { addSearchOffers, resetSearchOffers } from '../offers_list/actions';
import { IGk } from '../offers_list/gk';
import { IKp } from '../offers_list/model';

import { hasMapNewbuildingsSearchQueryParam, infrastructureFromQueryString } from './map_rules';
import {
  DEFAULT_MAP_ZOOM,
  INewbuildingPolygon,
  INewbuildingsMapData,
  IPin,
  MapObject,
  MapObjectType,
  PinType,
} from './model';
import { addFavouritesToPins } from './parser';
import { PIN_POPUP_QUERY_PARAM, hasPinQueryParam, setPopupInAppRouting } from './popup_rules';
import { getJsonQuery } from './utils/get_json_query';

declare const window: Window & {
  mapObjects: MapObject[];
};

export type TMapPageActions =
  | {
      type: 'SetMapData';
      payload: {
        mapObjects: MapObject[];
        mapObjectsType: MapObjectType;
        legend: PropertyType[];
        offersCount: number;
        zoom?: number;
      };
    }
  | { type: 'AddFavoriteQueryString'; payload: string }
  | { type: 'SetMapState'; payload: IMapState }
  | { type: 'SetActivePin'; payload: { id: string } }
  | { type: 'UnsetActivePin' }
  | { type: 'SetPinViewed'; payload: { id: string } }
  | { type: 'SaveLastMapRequestQueryString'; payload: { mapRequestQueryString: string } }
  | { type: 'SetDrawPending'; payload: { isDrawPending: boolean } }
  | { type: 'ResetMapState'; payload: Partial<IMapState> }
  | { type: 'SetMinBbox'; payload: { minBbox: YMapsBoundedBox } }
  | { type: 'SetMapObjectsStatus'; payload: { mapObjectsStatus: ResourceStatus } }
  | { type: 'SetMapContainer'; payload: { mapContainer: HTMLDivElement } }
  | { type: 'SetMapSize'; payload: { mapSize: [number, number] } }
  | { type: 'SetMap'; payload: { ymap: IYmapsMap } }
  | {
      type: 'SetMapFilterQueryString';
      payload: { filterQueryString: string; filterQueryStringWithoutGeo: string; filterQueryStringOnlyGeo: string };
    }
  | { type: 'SetMapPinPopupOpened'; payload: { pinPopupIsOpened: boolean } }
  | { type: 'SetFromDeveloperMapFilter'; payload: { fromDeveloperMapFilter?: boolean } }
  | { type: 'SetSubscriptionPending'; payload: { subscriptionIsPending: boolean } }
  | { type: 'SetSubscription'; payload: { subscriptionEmail?: string; lastSubscribtionUrl?: string } }
  | { type: 'SetOpenedPinPopupInfo'; payload: { openedPinType: PinType; openedPinGkInfo?: IGk; openedPinKpInfo?: IKp } }
  | { type: 'SetFetchDataForPinPopupInfo'; payload: { fetching: boolean } }
  | { type: 'SetNewbuildingsPinsEnabled'; payload: { enabled: boolean } }
  | { type: 'toggleInfrastructure'; payload: { enable: boolean } }
  | { type: 'addInfrastructureToMapObjects'; payload: { infrastructurePins: Array<IPin> } }
  | { type: 'removeInfrastructureFromMapObjects'; payload: { category: string } }
  | { type: 'SetPinViewType' }
  | { type: 'SetFixedViewType' }
  | { type: 'UnsetFixedViewType' }
  | { type: 'SetPinOrClusterWithOfferActive'; payload: IGeo | undefined }
  | { type: 'UnsetPinOrClusterWithOfferActive' }
  | { type: 'removeInfrastructureFromMapObjects'; payload: { category: string } }
  | { type: 'ChangeGkMapFavoriteStatus'; payload: { state: boolean } };

export function getSearchMapRequestQueryString(
  filterQuerystring: string,
  mapSize: [number, number],
  mapBounds: YMapsBoundedBox,
  logger: ILogger | null,
  allowBboxCorrection?: boolean,
): string {
  const querystringObject = deserializeParams(filterQuerystring, logger);
  const dealType = querystringObject['deal_type'] || 'sale';

  const requestParams: ISearchMapRequestMain = {
    screen_area: getScreenArea(mapSize),
    bbox: bboxToQueryStringParam(mapBounds),
    deal_type: dealType === 'rent' ? 1 : 2,
    add_clusters_sizes: 1,
    allow_precision_correction: Number(Boolean(allowBboxCorrection)),
  };

  return `${cleanQueryStringFilterParams(filterQuerystring)}&${serializeParams(requestParams)}`;
}

function cleanQueryStringFilterParams(queryString: string): string {
  return queryString.replace('newbuildings_search=true', '');
}

export interface IGetMapObjectsOptions {
  bounds?: YMapsBoundedBox;
  containerSize?: [number, number];
  util?: IYmapsUtil;
  allowBboxCorrection?: boolean; // разрешает второй запрос бэку для получения минимального bbox
  forceUseParamBounds?: boolean; // форсим использование переданных bounds в параметрах
  zoom?: number;
  getMap(bbox?: YMapsBoundedBox): IYmapsMap | undefined;
}

/**
 * Данные о кластерах и пинах на карте хранятся по-разному:
 * - кластеры в виду своего большого количества (от 4 до 10 тысяч
 *   за раз) хранятся в window, так как это самый простой способ;
 * - пины, как и прежде, хранятся в сторе, так как с ними связаны
 *   экшены избранного, пины инфраструктуры и тд и тп;
 */
export function setMapObjectsToWindow(objects: MapObject[]): void {
  window['mapObjects'] = objects;
}

export function updateMapFavorites(): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState) => {
    return dispatch(fetchFavoritesIDs()).then(() => {
      const state = getState();
      const { legend, offersCount, newbuildingsCount, mapObjectsType, mapGeoObjects, mapObjects } = state.mapPage;
      const { ids: favouritesIDs } = state.favorites;
      const { dealType } = state.filter;

      if (mapObjectsType === 'pin') {
        const updatedMapObjects = addFavouritesToPins(mapObjects as IPin[], favouritesIDs, dealType);

        dispatch<TMapPageActions>(
          setMapData(updatedMapObjects, legend, offersCount, newbuildingsCount, mapObjectsType, mapGeoObjects),
        );

        dispatch<TMapPageActions>(setMapObjectsStatus(ResourceStatus.UpdatedOnClient));
      }
    });
  };
}

export function setPinViewType(): TMapPageActions {
  return {
    type: 'SetPinViewType',
  };
}

export function setFixedViewType(): TMapPageActions {
  return {
    type: 'SetFixedViewType',
  };
}

export function unsetFixedViewType(): TMapPageActions {
  return {
    type: 'UnsetFixedViewType',
  };
}

export function setActivePin(id: string): TMapPageActions {
  return {
    type: 'SetActivePin',
    payload: { id },
  };
}

export function unsetActivePin(): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState) => {
    const { activePin } = getState().mapPage;
    if (activePin) {
      dispatch<TMapPageActions>({
        type: 'UnsetActivePin',
      });
    }

    return Promise.resolve();
  };
}

export function setPinViewed(id: string): TMapPageActions {
  return {
    type: 'SetPinViewed' as const,
    payload: { id },
  };
}

export function setNewbuildingsPinsEnabled(enabled: boolean): TMapPageActions {
  return {
    type: 'SetNewbuildingsPinsEnabled' as const,
    payload: { enabled },
  };
}

export function fetchingDataForPinPopupInfo(fetching: boolean): TMapPageActions {
  return {
    type: 'SetFetchDataForPinPopupInfo',
    payload: {
      fetching,
    },
  };
}

export interface IPinInfo {
  coords: YMapsCoords;
  placemarkId?: string;
  propertyType: number[];
  customBBox?: YMapsBoundedBox;
  zoom?: number;
}

export function setOpenedPinPopupInfo(
  openedPinType: PinType,
  openedPinGkInfo?: IGk,
  openedPinKpInfo?: IKp,
): TMapPageActions {
  return {
    type: 'SetOpenedPinPopupInfo',
    payload: {
      openedPinType,
      openedPinGkInfo,
      openedPinKpInfo,
    },
  };
}

export function setPinOrClusterWithOfferActive(offerGeo?: IGeo): TMapPageActions {
  return {
    type: 'SetPinOrClusterWithOfferActive',
    payload: offerGeo,
  };
}

export function unsetPinOrClusterWithOfferActive(): TMapPageActions {
  return {
    type: 'UnsetPinOrClusterWithOfferActive',
  };
}

export function setMapPinOffers(
  getState: () => ReduxState,
  dispatch: Dispatch<TReduxActions>,
  data: ISearchOffersData,
): void {
  const {
    mapPage: { mapRequestQueryString },
    routing,
  } = getState();
  const {
    locationBeforeTransitions: { pathname },
  } = routing;

  dispatch(resetSearchOffers());
  dispatch(
    addSearchOffers(
      data,
      data.offersSerialized.length > 0 && data.offersSerialized[0].category === 'newBuildingFlatSale',
      `${pathname}/?${mapRequestQueryString}`,
      true,
    ),
  );
}

export function setFromDeveloperMapFilterPure(fromDeveloper?: boolean): TMapPageActions {
  return {
    type: 'SetFromDeveloperMapFilter',
    payload: { fromDeveloperMapFilter: fromDeveloper },
  };
}

// Название ScreenArea не соответствует действительности (продиктовано бэком).
// На самом деле это площадь видимой области поделенная на площадь одного пина.
// В качестве площади пинов берем усредненное значение.
// В конечном счете этот параметр влияет на то, придут ли с сервера кластеры или легкие пины.
function getScreenArea([width, height]: [number, number]): number {
  const AVERAGE_PIN_SIDE = 50;
  const PIN_AREA = AVERAGE_PIN_SIDE * AVERAGE_PIN_SIDE;

  return Math.floor((width * height) / PIN_AREA);
}

export function setMapObjectsStatus(status: ResourceStatus): TMapPageActions {
  return {
    type: 'SetMapObjectsStatus' as const,
    payload: { mapObjectsStatus: status },
  };
}

export function setMapContainer(mapContainer: HTMLDivElement): TMapPageActions {
  return {
    type: 'SetMapContainer' as const,
    payload: { mapContainer },
  };
}

export function setMapSize(mapSize: [number, number]): TMapPageActions {
  return {
    type: 'SetMapSize' as const,
    payload: { mapSize },
  };
}

export function setMap(ymap: IYmapsMap): TMapPageActions {
  return {
    type: 'SetMap' as const,
    payload: { ymap },
  };
}

export interface IMapState {
  mapBounds: YMapsBoundedBox;
  mapZoom: number;
  mapCenter: [number, number];

  legend?: PropertyType[];
  mapObjects?: MapObject[];
  offersCount?: number;
}

export function setMapData(
  mapObjects: MapObject[],
  legend: PropertyType[] = [],
  offersCount: number,
  newbuildingsCount: number | undefined,
  mapObjectsType: MapObjectType,
  mapGeoObjects: INewbuildingPolygon[] | undefined,
  newbuildingsMapData?: INewbuildingsMapData,
  zoom?: number,
): any {
  return {
    type: 'SetMapData' as const,
    payload: {
      mapObjects,
      legend,
      offersCount,
      newbuildingsCount,
      mapObjectsType,
      mapGeoObjects,
      newbuildingsMapData,
      zoom,
    },
  };
}

export function setDrawPending(isDrawPending: boolean): TMapPageActions {
  return {
    type: 'SetDrawPending' as const,
    payload: { isDrawPending },
  };
}

export function setMinBbox(minBbox: YMapsBoundedBox): TMapPageActions {
  return {
    type: 'SetMinBbox' as const,
    payload: { minBbox },
  };
}

export function resetMapState(): TMapPageActions {
  return {
    type: 'ResetMapState' as const,
    payload: {
      // FIXME: в сторе значение не может быть null
      mapBounds: null as any,
      mapZoom: DEFAULT_MAP_ZOOM,
      legend: [],
      mapObjects: [],
      offersCount: 0,
    },
  };
}

export function saveLastMapRequestQueryString(mapRequestQueryString: string): TMapPageActions {
  return {
    type: 'SaveLastMapRequestQueryString' as const,
    payload: { mapRequestQueryString },
  };
}

const setPinPopupQueryParam = (queryParam: string, dispatch: Dispatch<TReduxActions>): void => {
  if (!location.search.includes(`${queryParam}`)) {
    const queryString = appendQueryParam(location.search, `${queryParam}=true`);
    const querySeparator = location.search ? '' : '?';
    dispatch(push(`${location.pathname}${querySeparator}${queryString}`));
    setPopupInAppRouting(queryParam);
  }
};

const unsetPinPopupQueryParam = (queryParam: string, dispatch: Dispatch<TReduxActions>): void => {
  if (location.search.includes(`${queryParam}`)) {
    const queryString = removeQueryParam(location.search, queryParam);
    dispatch(replace(`${location.pathname}${queryString}`));
  }
};

const updatePinPopupQueryParam = (queryParam: string, opened: boolean, dispatch: Dispatch<TReduxActions>): void => {
  if (opened) {
    setPinPopupQueryParam(queryParam, dispatch);
  } else {
    unsetPinPopupQueryParam(queryParam, dispatch);
    dispatch(unsetFixedViewType());
  }
};

export function restorePopupStateByQueryParams(queryString: string): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState) => {
    const { activePin } = getState().mapPage;

    const pinShouldBeOpened = hasPinQueryParam(queryString) && !!activePin;

    updatePinPopupQueryParam(PIN_POPUP_QUERY_PARAM, pinShouldBeOpened, dispatch);

    if (!pinShouldBeOpened) {
      dispatch(unsetActivePin());
      dispatch(unsetFixedViewType());
    }

    return Promise.resolve();
  };
}

export function closeFixedPinPopup(): TTypedThunkAction<Promise<void>> {
  return dispatch => {
    dispatch(unsetFixedViewType());

    return Promise.resolve();
  };
}

export function closePinPopup(): TTypedThunkAction<Promise<void>> {
  return dispatch => {
    dispatch(unsetActivePin());
    dispatch(unsetFixedViewType());
    unsetPinPopupQueryParam(PIN_POPUP_QUERY_PARAM, dispatch);

    return Promise.resolve();
  };
}

export function saveSearchAction(
  email: string,
  title: string,
  subscribeNews: boolean,
  notificationFrequency: TNotificationFrequency,
): TTypedThunkAction<Promise<void | IRequestSubscribeNewResponse>> {
  return async (dispatch, getState, { api }) => {
    const store = getState();
    const { routing, searchList, filter, offerPage, queryClient } = store;

    let queryString: string | undefined;
    let jsonQuery: IJsonQuery | undefined;

    if (['main', 'advancedSearch'].includes(routing.routeName)) {
      const { querystring: querystringFilters } = await getUrlByJsonQuery(
        filterToJsonQuery(filter, undefined, queryClient),
        api,
      );

      queryString = querystringFilters;
    } else if (routing.routeName === 'offerCard') {
      jsonQuery = getJsonQuery(offerPage.offer, searchList);
    } else {
      queryString = searchList.queryString || '';
    }

    return api
      .subscribeNew({ jsonQuery, queryString, email, title, subscribeNews, notificationFrequency })
      .then((res: IRequestSubscribeNewResponse) => {
        dispatch(getHeaderData());
        dispatch(fetchGaDataLayer());

        return res;
      });
  };
}

export function subscribe(
  email: string,
  queryString: string,
  subscribeNews: boolean,
  source: TSubscribeSource,
): TTypedThunkAction<Promise<void | IRequestSubscribeNewResponse>> {
  return (dispatch, getState, { api }) => {
    dispatch<TMapPageActions>({ type: 'SetSubscriptionPending', payload: { subscriptionIsPending: true } });

    return api
      .subscribeNew({ queryString, email, source, subscribeNews })
      .then(res => {
        if (res && res.auth) {
          dispatch(getHeaderData())
            .then(() => dispatch({ type: 'AuthSuccess' }))
            .then(() => {
              window.__reloadHeader__?.();
            });
        }

        dispatch<TMapPageActions>({
          type: 'SetSubscription',
          payload: {
            subscriptionEmail: email,
            lastSubscribtionUrl: queryString,
          },
        });

        return res;
      })
      .catch(err => {
        dispatch<TMapPageActions>({ type: 'SetSubscriptionPending', payload: { subscriptionIsPending: false } });
        throw err;
      });
  };
}

export function removePinPopupParam(search: string): string {
  return removeQueryParam(search, PIN_POPUP_QUERY_PARAM);
}

export function restoreNewbuildingsMapSearchByQueryParams(queryString: string): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState) => {
    const state = getState();

    const { newbuildingsPinsEnabled } = state.mapPage;
    const newbuildingsMapSearchShouldBeEnabled = hasMapNewbuildingsSearchQueryParam(queryString);

    if (newbuildingsMapSearchShouldBeEnabled && !newbuildingsPinsEnabled) {
      dispatch(setNewbuildingsPinsEnabled(true));
      dispatch(getOfferCount({ isNewbuildingsMapModeEnabled: true }));
    } else if (!newbuildingsMapSearchShouldBeEnabled && newbuildingsPinsEnabled) {
      dispatch(setNewbuildingsPinsEnabled(false));
    }

    return Promise.resolve();
  };
}

export const setQueryParam = (queryParam: string): TTypedThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    if (!location.search.includes(`${queryParam}`)) {
      const queryString = appendQueryParam(location.search, `${queryParam}=true`);
      const querySeparator = location.search ? '' : '?';
      dispatch(push(`${location.pathname}${querySeparator}${queryString}`));
    }

    return Promise.resolve();
  };
};

export const unsetQueryParam = (queryParam: string): TTypedThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    if (location.search.includes(`${queryParam}`)) {
      const queryString = removeQueryParam(location.search, queryParam);
      dispatch(replace(`${location.pathname}${queryString}`));
    }

    return Promise.resolve();
  };
};

export function toggleInfrastructure(enable: boolean): TMapPageActions {
  return {
    type: 'toggleInfrastructure',
    payload: { enable },
  };
}

export function restoreInfrastructureStateByQueryParams(queryString: string): TTypedThunkAction<Promise<void>> {
  return dispath => {
    const infrastructureParams = infrastructureFromQueryString(queryString);
    const enableInfrastructure = toPairs(infrastructureParams).filter(pair => pair[1]).length > 0;

    dispath(toggleInfrastructure(enableInfrastructure));
    dispath(setInitialInfrastructure(infrastructureParams));

    return Promise.resolve();
  };
}

export function addInfrastructureToMapObjects(infrastructurePins: Array<IPin>): TMapPageActions {
  return {
    type: 'addInfrastructureToMapObjects',
    payload: { infrastructurePins },
  };
}

export function removeInfrastructureFromMapObjects(category: string): TMapPageActions {
  return {
    type: 'removeInfrastructureFromMapObjects',
    payload: { category },
  };
}
