import { IOfferInfo } from '../../redux/offer/model';

import { FDealType, getDealTypeFromOffer } from '../category';

import { EWordCase } from './partials/constants';
import { getOfferTypeVariant } from './partials/getOfferTypeVariant';
import { getAreaLabel } from './partials/getAreaLabel';
import { getPriceLabel } from './partials/getPriceLabel';
import { getGeoLabel } from './partials/getGeoLabel';

const getDealTypeLabel = (offer: IOfferInfo) => (
  getDealTypeFromOffer(offer) === FDealType.Sale ? 'Купите' : 'Арендуйте'
);

export const getDescription = (offer: IOfferInfo) => {
  const { cianId } = offer;
  const area = getAreaLabel(offer);

  return [
    '\u279C',
    getDealTypeLabel(offer),
    getOfferTypeVariant(offer, EWordCase.Accusative),
    area && `площадью ${area}`,
    `${getGeoLabel(offer)}.`,
    getPriceLabel(offer),
    cianId && `Недвижимость на ЦИАН - объявление ${cianId}`,
  ].filter(Boolean).join(' ');
};
