import { EReadyBusinessType, IOfferInfo } from '../redux/offer/model';
import { getSpecialty } from './getSpecialty';

export function getReadyBusinessSpecialty(offer: IOfferInfo): string {
    const { readyBusinessType } = offer;

    if (!readyBusinessType) {
      return '';
    }

    if (readyBusinessType === EReadyBusinessType.RentalBusiness) {
      return 'Арендный бизнес';
    }

    const specialty = getSpecialty(offer)[0];

    return specialty ? specialty[0].toUpperCase() + specialty.slice(1) : 'Готовый бизнес';
}
