import { IStyleConfig } from '@cian/components';
import * as React from 'react';

import { inject } from '../../utils/context_provider';
import { FixedComponentsStore, RComponent, Unsubscribe } from './store';

export interface IFixedState {
  fixed: boolean;
}

interface IAppContextProps {
  fixedComponentsStore: FixedComponentsStore;
}

export interface IFixedProps {
  styles: IStyleConfig | undefined;
}

export interface INoFixedProps {
  hidden: boolean;
}

export interface IFixedComponentOwnProps {
  container: string;
}

interface IMakeFixedComponentOptions<Props> {
  id: string;
  Fixed: RComponent<Props & IFixedProps>;
  NoFixed: RComponent<Props & INoFixedProps>;
  shouldBeFixed(node: HTMLElement, props: Props & IFixedComponentOwnProps): boolean;
  getScrollableElement?(props: Props & IFixedComponentOwnProps): Element | Window;
}

export function makeFixedComponent<Props>({
  id, Fixed, NoFixed, shouldBeFixed, getScrollableElement,
}: IMakeFixedComponentOptions<Props>): React.ComponentClass<Props & IFixedComponentOwnProps> {
  Fixed.displayName = `fixed(${id})`;
  NoFixed.displayName = `noFixed(${id})`;

  type FixedComponentProps = Props & IAppContextProps & IFixedComponentOwnProps;
  class FixedComponent extends React.Component<FixedComponentProps, IFixedState> {
    public static displayName = `fixedComponent(${id})`;
    public state: IFixedState = {
      fixed: false,
    };

    private node: HTMLElement | null;
    private scrollableElement: Element | Window;
    private unsubscribeFromForceReflow: Unsubscribe;

    public componentDidMount() {
      this.scrollableElement = getScrollableElement ? getScrollableElement(this.props as FixedComponentProps) : window;
      this.scrollableElement.addEventListener('scroll', this.handleScroll);
      this.scrollableElement.addEventListener('orientationchange', this.handleScroll);
      this.unsubscribeFromForceReflow = this.props.fixedComponentsStore.onForceReflow(this.handleScroll);
      this.handleScroll();
    }

    public componentWillUnmount() {
      this.scrollableElement.removeEventListener('scroll', this.handleScroll);
      this.scrollableElement.removeEventListener('orientationchange', this.handleScroll);
      this.props.fixedComponentsStore.removeElement(this.props.container, id);
      this.unsubscribeFromForceReflow();
    }

    public componentWillReceiveProps(nextProps: FixedComponentProps) {
      this.updateElement(nextProps);
    }

    public render() {
      return (
        <div ref={this.setNode}>
          <NoFixed hidden={this.state.fixed} {...this.props} />
        </div>
      );
    }

    private handleScroll = () => {
      if (!this.node) { return; }
      const isShouldBeFixed = shouldBeFixed(this.node, this.props as FixedComponentProps);
      if (this.state.fixed !== isShouldBeFixed) {
        this.setState({ fixed: isShouldBeFixed }, () => this.updateElement(this.props as FixedComponentProps));
      }
    }

    private setNode = (node: HTMLElement | null) => {
      this.node = node;
    }

    private updateElement = (props: FixedComponentProps) => {
      if (this.state.fixed) {
        // tslint:disable-next-line:no-any
        this.props.fixedComponentsStore.setElement(this.props.container, id, Fixed, props as any);
      } else {
        this.props.fixedComponentsStore.removeElement(this.props.container, id);
      }
    }
  }

  return inject(['fixedComponentsStore'])(FixedComponent);
}

export interface IFixedContainerComponentProps {
  name: string;
}

type FixedContainerComponentProps = IFixedContainerComponentProps & IFixedProps & IAppContextProps;

class FixedContainerComponent extends React.Component<FixedContainerComponentProps, object> {
  private unsubscribe: Unsubscribe;

  public componentWillMount() {
    this.unsubscribe = this.props.fixedComponentsStore.subscribe(() => {
      this.forceUpdate();
    });
  }

  public componentWillUnmount() {
    this.unsubscribe();
  }

  public render() {
    const { styles } = this.props;
    const elements = this.props.fixedComponentsStore.getElements(this.props.name);
    const elementsWithKeys = Object.keys(elements).map(key => {
      const { Component, props } = elements[key];
      return <Component {...{ ...props, key, styles }} />;
    });
    return (
      <div>
        {elementsWithKeys}
      </div>
    );
  }
}

export const FixedContainer = inject(['fixedComponentsStore'])(FixedContainerComponent);
