export interface IScrollPositionState {
  serpScrollPosition: number | null;
}

export interface IScrollPositionSetSerpScroll {
  type: 'IScrollPositionSetSerpScroll';
  payload: {
    serpScrollPosition: number;
  };
}

const defaultState: IScrollPositionState = {
  serpScrollPosition: null,
};

export function setSerpScrollPosition(serpScrollPosition: number): IScrollPositionSetSerpScroll {
  return {
    payload: { serpScrollPosition },
    type: 'IScrollPositionSetSerpScroll',
  };
}

export type TScrollPositionActions = IScrollPositionSetSerpScroll;

export function scrollPositionReducer(
  state: IScrollPositionState = defaultState,
  action: TScrollPositionActions,
) {
  switch (action.type) {
    case 'IScrollPositionSetSerpScroll':
      return {
        ...state,
        serpScrollPosition: action.payload.serpScrollPosition,
      };

    default:
      return state;
  }
}
