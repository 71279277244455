import { IOfferInfo } from '../../../redux/offer/model';

import { EWordCase } from './constants';

interface IOfferTypeVariant {
  ids: string[];
  getCases(): [string, string, null, string] | null;
}

const createOfferTypeVariant = (
  ids: string[],
  getCases: () => [string, string, null, string] | null,
): IOfferTypeVariant => ({
  getCases,
  ids,
});

// tslint:disable-next-line:variable-name
export const getOfferTypeVariant = (offer: IOfferInfo, wordCase: EWordCase) => {
  const offerTypeVariants = Array<IOfferTypeVariant>(
    // tslint:disable:max-line-length
    createOfferTypeVariant(['flat', 'newBuildingFlat', 'dailyFlat'], () => {
      if (offer.flatType === 'studio') {
        return offer.isApartments
          ? ['апартаменты-студия', 'апартаментов-студии', null, 'апартаменты-студию']
          : ['квартира-студия', 'квартиры-студии', null, 'квартиру-студию'];
      }

      if (offer.flatType === 'openPlan') {
        return offer.isApartments
          ? ['апартаменты свободной планировки', 'апартаментов свободной планировки', null, 'апартаменты свободной планировки']
          : ['квартира свободной планировки', 'квартиры свободной планировки', null, 'квартиру свободной планировки'];
      }

      if ((offer.roomsCount || 0) >= 6) {
        return offer.isApartments
          ? ['многокомнатные апартаменты', 'многокомнатных апартаментов', null, 'многокомнатные апартаменты']
          : ['многокомнатная квартира', 'многокомнатной квартиры', null, 'многокомнатную квартиру'];
      }

      // tslint:disable-next-line:switch-default
      switch (offer.roomsCount) {
        case 1:
          return offer.isApartments
            ? ['однокомнатные апартаменты', 'однокомнатных апартаментов', null, 'однокомнатные апартаменты']
            : ['однокомнатная квартира', 'однокомнатной квартиры', null, 'однокомнатную квартиру'];
        case 2:
          return offer.isApartments
            ? ['двухкомнатные апартаменты', 'двухкомнатных апартаментов', null, 'двухкомнатные апартаменты']
            : ['двухкомнатная квартира', 'двухкомнатной квартиры', null, 'двухкомнатную квартиру'];
        case 3:
          return offer.isApartments
            ? ['трехкомнатные апартаменты', 'трехкомнатных апартаментов', null, 'трехкомнатные апартаменты']
            : ['трехкомнатная квартира', 'трехкомнатной квартиры', null, 'трехкомнатную квартиру'];
        case 4:
          return offer.isApartments
            ? ['четырехкомнатные апартаменты', 'четырехкомнатных апартаментов', null, 'четырехкомнатные апартаменты']
            : ['четырехкомнатная квартира', 'четырехкомнатной квартиры', null, 'четырехкомнатную квартиру'];
        case 5:
          return offer.isApartments
            ? ['пятикомнатные апартаменты', 'пятикомнатных апартаментов', null, 'пятикомнатные апартаменты']
            : ['пятикомнатная квартира', 'пятикомнатной квартиры', null, 'пятикомнатную квартиру'];
      }

      return null;
    }),
    createOfferTypeVariant(['room', 'dailyRoom'], () => ['комната', 'комнаты', null, 'комнату']),
    createOfferTypeVariant(['bed', 'dailyBed'], () => ['койко-место', 'койко-места', null, 'койко-место']),
    createOfferTypeVariant(['house', 'dailyHouse'], () => ['дом', 'дома', null, 'дом']),
    createOfferTypeVariant(['cottage'], () => ['коттедж', 'коттеджа', null, 'коттедж']),
    createOfferTypeVariant(['townhouse'], () => ['таунхаус', 'таунхауса', null, 'таунхаус']),
    createOfferTypeVariant(['land'], () => ['участок', 'участка', null, 'участок']),
    createOfferTypeVariant(['houseShare'], () => ['часть дома', 'части дома', null, 'часть дома']),
    createOfferTypeVariant(['flatShare'], () => ['долю в квартире', 'доли в квартире', null, 'долю в квартире']),
    createOfferTypeVariant(['office'], () => ['офис', 'офиса', null, 'офис']),
    createOfferTypeVariant(['shoppingArea'], () => ['торговая площадь', 'торговой площади', null, 'торговую площадь']),
    createOfferTypeVariant(['warehouse'], () => ['cклад', 'cклада', null, 'cклад']),
    createOfferTypeVariant(['freeAppointmentObject'], () => ['помещение свободного назначения', 'помещения свободного назначения', null, 'помещение свободного назначения']),
    createOfferTypeVariant(['industry'], () => ['помещение под производство', 'помещения под производство', null, 'помещение под производство']),
    createOfferTypeVariant(['publicCatering'], () => ['общепит', 'общепита', null, 'общепит']),
    createOfferTypeVariant(['carService'], () => ['помещение под автосервис', 'помещения под автосервис', null, 'помещение под автосервис']),
    createOfferTypeVariant(['business'], () => ['бизнес', 'бизнеса', null, 'бизнес']),
    createOfferTypeVariant(['building'], () => ['здание', 'здания', null, 'здание']),
    createOfferTypeVariant(['domesticServices'], () => ['бытовая услуга', 'бытовой услуги', null, 'бытовую услугу']),
    createOfferTypeVariant(['commercialLand'], () => ['коммерческая земля', 'коммерческой земли', null, 'коммерческую землю']),
    createOfferTypeVariant(['garage'], () => ['гараж', 'гаража', null, 'гараж']),
    // tslint:enable:max-line-length
  );

  const offerTypeVariant = offerTypeVariants.find(({ ids }) =>
    ids.some((id) => offer.category === `${id}Rent` || offer.category === `${id}Sale`));

  const cases = offerTypeVariant && offerTypeVariant.getCases();

  return cases && cases[wordCase] || null;
};
