import * as React from 'react';

import { CheckboxField, mergeStyles } from '@cian/components';
import {
  IFieldProps,
  IsVisibleCheck,
  withNewobjectSelected,
  isCommonFlatSale,
  isNotUpcomingSale,
  reduceVisibilityCheck,
} from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    return this.props.filter.withDiscount !== nextProps.filter.withDiscount;
  }

  public render() {
    const { filter: { withDiscount } } = this.props;
    const labelStyles = mergeStyles(
      styles.labelCheckbox,
      withDiscount && styles.checked,
    ).className;

    return (
      <div className={styles.checkbox} data-mark="FieldWithDiscount">
        <CheckboxField
          mobile
          label="Скидки и акции"
          labelStyle={labelStyles}
          value={withDiscount}
          onValueChange={this.props.actions.setWithDiscount}
        />
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = reduceVisibilityCheck(
  isNotUpcomingSale,
  (filter, optionalVisibilityParams) => {
    const isNewbuildingMapFilters = Boolean(optionalVisibilityParams && optionalVisibilityParams.isNewbuildingsMapMode);
    const commonFlatSale = isCommonFlatSale(filter);

    return withNewobjectSelected(filter) || (isNewbuildingMapFilters && commonFlatSale);
  },
);
