import { ISeoMainData } from '../../../api/api';
import { toPromise } from '../../../utils/streams';
import { FiltersSection, FiltersSectionQuery } from '../../filters/model';
import { TTypedThunkAction } from '../../model';

export type TSeoMainActions
  = { type: 'GetSeoMainPending' }
  | { type: 'GetSeoMainSuccess', payload: { seoMain: ISeoMainData } }
  | { type: 'GetSeoMainError', payload: { err: Error } };

export function getSeoMain(section?: FiltersSection): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    dispatch<TSeoMainActions>({ type: 'GetSeoMainPending' });

    const apiSection = section != null ? FiltersSectionQuery[section] : 'index';

    dispatch<TSeoMainActions>({ type: 'GetSeoMainPending' });

    return toPromise(api.seoSection(apiSection))
      .then(res => {
        dispatch<TSeoMainActions>({
          type: 'GetSeoMainSuccess',
          payload: {
            seoMain: res.result.data,
          },
        });
      })
      .catch(err => {
        dispatch<TSeoMainActions>({ type: 'GetSeoMainError', payload: { err } });
      });
  };
}
