/* eslint-disable max-lines,sort-keys */

import { QueryClient } from '@tanstack/react-query';
import { flatMap, isNil, mapValues, omitBy, uniq, values } from 'lodash';

import { BusinessAppointmentsIdsService } from '../../../shared/services/BusinessAppointmentsIdsService';
import { FetchGetBusinessPossibleAppointmentsLoadService } from '../../../shared/store/serverSide/announcements/v1/get-business-possible-appointments/load';
import {
  IJsonQuery,
  IJsonQueryGeo,
  IJsonQueryGeoValue,
  IJsonQueryRange,
  IJsonQueryTerm,
  IJsonQueryTerms,
  range,
  rangeGte,
  rangeLte,
  term,
  terms,
} from '../../api/models/json_query';
import * as fields from '../../filters/fields';
import { amenitiesOptions } from '../../filters/fields/coworking_amenities/constants';
import {
  Currency,
  EJsonQueryPriceType,
  FieldType,
  IFiltersVisibilityParams,
  coworkingOfferType,
  isCoworkingFilters,
  isGarageFilterOnly,
  isSpecialCommercialObjectGroup,
  rentTimeDict,
} from '../../filters/fields/field';
import { CORRECT_OFFREP_REGION_IDS } from '../../filters/fields/terms/terms';
import { Regions } from '../../utils/regions';

import { garageSubtypeDict, mapByDict } from './dictionaries';
import {
  BathroomType,
  BathroomsCountType,
  CommissionType,
  DealType,
  EElectronicTradingType,
  FilterState,
  FlatCondition,
  ICoworkingAccess,
  IGeoTag,
  IRooms,
  MovementMethod,
  PriceType,
  SaleTypeFlat,
  SaleTypeNewbuilding,
  SteadCondition,
  TCategoryType,
  TObjectType,
  commercialObjectType,
  flatObjectType,
  flatObjectTypes,
  sellerType,
  suburbanObjectType,
  suburbanObjectTypes,
  yearType,
} from './model';

const isFlat = (selectedObjectTypes: TObjectType[]): boolean => {
  return selectedObjectTypes.some(t1 => flatObjectTypes.some(t2 => t1 === t2));
};

const isSuburban = (selectedObjectTypes: TObjectType[]): boolean => {
  return selectedObjectTypes.some(t1 => suburbanObjectTypes.some(t2 => t1 === t2));
};

const isCommercialLand = (selectedObjectTypes: TObjectType[]): boolean => {
  return selectedObjectTypes.some(t1 => commercialObjectType.CommercialLand === t1);
};

function getType(filter: FilterState): string {
  function isObjectTypeSelected(objectTypes: { [key: string]: string }): boolean {
    return filter.selectedObjectTypes.some(type => {
      return values(objectTypes).includes(type);
    });
  }

  const conditions = {
    flat: isObjectTypeSelected(flatObjectType),
    suburban: isObjectTypeSelected(suburbanObjectType),
    commercial: isObjectTypeSelected(commercialObjectType),
    sale: filter.dealType === DealType.Sale,
    rent: filter.dealType === DealType.Rent,
  };

  const isFalse = (value: boolean): boolean => value === false;

  return Object.keys(omitBy(conditions, isFalse)).join('');
}

const roomsKeys: { [key: string]: number[] } = {
  oneRoom: [1],
  twoRooms: [2],
  threeRooms: [3],
  fourRooms: [4],
  fiveRooms: [5],
  sixRooms: [6],
  freeLayout: [7],
  studio: [9],
};

function getRooms(filter: FilterState): IJsonQueryTerms<number> {
  let rooms: number[] = [];
  const isRoomsVisible = fields.rooms.isVisible(filter);
  const objectTypeSelected = (type: TObjectType): boolean => filter.selectedObjectTypes.some(t => t === type);

  if (isRoomsVisible) {
    // проверяем, что ключ в фильтрах просэчен в true и что он возвращает валидное значение
    const apartmentRoomKeys = (Object.keys(filter.rooms) as Array<keyof IRooms>).filter(
      key => filter.rooms[key] && !!roomsKeys[key],
    );

    if (apartmentRoomKeys.length) {
      rooms = rooms.concat(flatMap<keyof IRooms, number>(apartmentRoomKeys, (key: string) => roomsKeys[key]));
    }
  }

  if (objectTypeSelected(flatObjectType.Room)) {
    rooms.push(0);
  }

  if (objectTypeSelected(flatObjectType.Part)) {
    rooms.push(8);
  }

  if (objectTypeSelected(flatObjectType.Bed)) {
    rooms.push(10);
  }

  return terms(rooms);
}

function getYears(filter: FilterState): IJsonQueryTerms<number> {
  const years: number[] = filter.selectedYears
    .filter(y => y !== yearType.Later && y !== yearType.PutIntoOperation)
    .map(y => +y);

  return terms(years);
}

function getYearGte(filter: FilterState): IJsonQueryTerm<number> | undefined {
  return filter.selectedYears.some(y => y === yearType.Later) ? term(new Date().getFullYear() + 4) : undefined;
}

function getHandOver(filter: FilterState): IJsonQueryTerm<boolean> | undefined {
  return filter.selectedYears.some(y => y === yearType.PutIntoOperation) ? term(true) : undefined;
}

function getCurrency(filter: FilterState): IJsonQueryTerm<Currency> {
  return term(filter.currency);
}

export function getSellerType(filter: FilterState): IJsonQueryTerm<boolean> | undefined {
  for (const selectedSeller of filter.selectedSellers) {
    switch (selectedSeller) {
      case sellerType.Developer:
        return term(true);
      case sellerType.Agent:
        return term(false);
    }
  }

  return undefined;
}

function getSaleType(filter: FilterState): IJsonQueryTerms<SaleTypeFlat> | IJsonQueryTerms<SaleTypeNewbuilding> {
  if (!filter.withNewobject) {
    return terms(filter.saleTypeFlat);
  }

  const saleTypes = [];

  const {
    saleTypesNewbuilding: { FZ214, cessionOfRights, ZhSK },
  } = filter;

  if (FZ214) {
    saleTypes.push(SaleTypeNewbuilding.FZ214);
  }

  if (cessionOfRights) {
    saleTypes.push(SaleTypeNewbuilding.cessionOfRights);
  }

  if (ZhSK) {
    saleTypes.push(SaleTypeNewbuilding.ZhSK);
  }

  return terms(saleTypes);
}

function omitEmptyValues(jsonQuery: IJsonQuery): IJsonQuery {
  const mappedJsonQuery = mapValues(jsonQuery, (queryValue: any, key: string) => {
    // the only value without .type
    if (key === '_type') {
      return queryValue;
    }

    if (isNil(queryValue)) {
      return null;
    }

    const { type, value }: { type: string; value: any } = queryValue;

    if (type === 'term' && isNil(value)) {
      return null;
    }

    if (type === 'terms' && (isNil(value) || value.length === 0)) {
      return null;
    }

    if (type === 'range') {
      if (isNil(value.lte) && isNil(value.gte)) {
        return null;
      }

      if (isNil(value.lte)) {
        return rangeGte(value.gte);
      }

      if (isNil(value.gte)) {
        return rangeLte(value.lte);
      }
    }

    if (type === 'geo' && value.length === 0) {
      return null;
    }

    return queryValue;
  });

  return omitBy(mappedJsonQuery, isNil) as IJsonQuery;
}

function getObjectTypes(filter: FilterState): IJsonQueryTerms<number> {
  if (isSuburban(filter.selectedObjectTypes)) {
    return terms(
      flatMap(filter.selectedObjectTypes, t => {
        switch (t) {
          case suburbanObjectType.House:
            return [1];
          case suburbanObjectType.PartOfHouse:
            return [2];
          case suburbanObjectType.Stead:
            return [3];
          case suburbanObjectType.TownHouse:
            return [4];
          default:
            return [];
        }
      }),
    );
  }

  return terms([]);
}

function getCoworkingOfferType(filter: FilterState): IJsonQueryTerms<number> {
  return terms(
    flatMap(filter.selectedCoworkingOfferType, t => {
      switch (t) {
        case coworkingOfferType.Office:
          return [1];
        case coworkingOfferType.FixedWorkplace:
          return [2];
        case coworkingOfferType.FlexibleWorkplace:
          return [3];
        case coworkingOfferType.ConferenceHall:
          return [4];
        case coworkingOfferType.MeetingRoom:
          return [5];
        default:
          return [];
      }
    }),
  );
}

function getCoworkingAmenities({ amenities }: FilterState): IJsonQueryTerms<number> {
  return terms(amenities.map(key => amenitiesOptions.findIndex(item => item.key === key) + 1));
}

function getCoworkingAccess(
  { coworkingAccess }: FilterState,
  fieldName: keyof ICoworkingAccess,
): IJsonQueryTerm<number> | undefined {
  if (!coworkingAccess || !coworkingAccess[fieldName]) {
    return undefined;
  }

  return term(1);
}

function getBuildingStatus(filter: FilterState): IJsonQueryTerm<number> | undefined {
  if ((isFlat(filter.selectedObjectTypes) && filter.withNewobject) || areAllTagsNewobjects(filter)) {
    return term(2);
  } else if (filter.resaleOnly) {
    return term(1);
  }

  return undefined;
}

function getOfficeTypes(filter: FilterState): IJsonQueryTerms<number> {
  return terms(
    uniq(
      flatMap(filter.selectedObjectTypes, t => {
        switch (t) {
          case commercialObjectType.DomesticService:
            return [12];
          case commercialObjectType.Garage:
            return [6];
          case commercialObjectType.Building:
            return [11];
          case commercialObjectType.PublicCatering:
            return [4];
          case commercialObjectType.Office:
            return [1];
          case commercialObjectType.Psn:
            return [5];
          case commercialObjectType.CarService:
            return [9];
          case commercialObjectType.Production:
            return [7];
          case commercialObjectType.RentalBusiness:
          case commercialObjectType.ReadyBusiness:
            return [10];
          case commercialObjectType.Warehouse:
            return [3];
          case commercialObjectType.Marketplace:
            return [2];
          case commercialObjectType.Coworking:
            return [13];
          default:
            return [];
        }
      }),
    ),
  );
}

function getRegion(filter: FilterState): IJsonQueryTerms<number> | undefined {
  switch (filter.region) {
    case Regions.MoscowAndRegion:
      return terms([Regions.Moscow, Regions.MoscowRegion]);

    case Regions.SaintPetersburgAndRegion:
      return terms([Regions.SaintPetersburg, Regions.LeningradRegion]);

    default:
      return terms([filter.region]);
  }
}

function getGeoQueryValueFromTag(tag: IGeoTag): IJsonQueryGeoValue {
  return tag.isParsed && tag.id
    ? ({
        type: tag.kind.toLowerCase(),
        id: tag.id,
      } as IJsonQueryGeoValue)
    : ({
        type: 'polygon',
        name: tag.name,
        coordinates: tag.geo,
      } as any);
}

function getGeo(filter: FilterState): IJsonQueryGeo {
  const value = filter.geoTags
    .filter(tag => {
      const kind = tag.kind.toLowerCase();

      return kind !== 'village' && kind !== 'shoppingcenter' && kind !== 'businesscenter';
    })
    .map(getGeoQueryValueFromTag);

  if (fields.withPik.isVisible(filter) && filter.withPik) {
    value.push({ type: 'builder', id: 9 });
  }

  const isCustomVisible = fields.withCustom.isVisible(filter, {
    isCustomSpecialPromo: true,
    isNewbuildingsMapMode: false,
  });

  if (isCustomVisible && filter.withCustom && filter.builderId !== undefined) {
    value.push({ type: 'builder', id: filter.builderId });
  }

  if (fields.direction.isVisible(filter) && filter.highways) {
    filter.highways.forEach(id => value.push({ type: 'highway', id }));
  }

  return {
    type: 'geo',
    value,
  };
}

function getBSCenter(filter: FilterState): IJsonQueryTerms<number> | undefined {
  const bsIds = filter.geoTags
    .map((tag: IGeoTag) => {
      const kindLow = tag.kind.toLowerCase();

      return (kindLow === 'shoppingcenter' || kindLow === 'businesscenter') && tag.id;
    })
    .filter(Boolean);

  return bsIds.length ? { type: 'terms', value: bsIds as number[] } : undefined;
}

function areAllTagsNewobjects(filter: FilterState): boolean {
  return (
    filter.geoTags != null &&
    filter.geoTags.length > 1 &&
    filter.geoTags.every(tag => tag.kind.toLowerCase() === 'newobject')
  );
}

function getSteadStatus(filter: FilterState): IJsonQueryTerms<number> | undefined {
  return filter.steadStatuses.length > 0 ? terms(filter.steadStatuses) : undefined;
}

function getCondition<T>(conditionsList: T[], type: T): IJsonQueryTerm<boolean> | undefined {
  const condition = conditionsList.find(item => item === type);

  return condition != null ? term(true) : undefined;
}

function getSteadCondition(filter: FilterState, type: SteadCondition): IJsonQueryTerm<boolean> | undefined {
  return getCondition(filter.steadConditions, type);
}

function getFlatCondition(filter: FilterState, type: FlatCondition): IJsonQueryTerm<boolean> | undefined {
  return getCondition(filter.flatConditions, type);
}

function getSuburbanCondition(filter: FilterState, type: FlatCondition): IJsonQueryTerm<boolean> | undefined {
  if (!isSuburban(filter.selectedObjectTypes)) {
    return;
  }

  return getCondition(filter.flatConditions, type);
}

function getCatagory(filter: FilterState): IJsonQueryTerms<TCategoryType> | undefined {
  if (!isCommercialLand(filter.selectedObjectTypes)) {
    return;
  }

  return filter.dealType === DealType.Rent
    ? terms(['commercialLandRent' as const])
    : terms(['commercialLandSale' as const]);
}

function getCommercialOffrep(filter: FilterState): IJsonQueryTerm<true> | undefined {
  if (
    isSpecialCommercialObjectGroup(filter) &&
    CORRECT_OFFREP_REGION_IDS.includes(filter.region) &&
    filter.fromRepresentative
  ) {
    return term(filter.fromRepresentative);
  }

  return undefined;
}

function getOnlyFoot(filter: FilterState): IJsonQueryTerm<string> | undefined {
  const { maxDistance, movementMethod } = filter.subwayDistance;

  return maxDistance != null && movementMethod != null
    ? term<string>(movementMethod === MovementMethod.Walk ? '2' : '-2')
    : undefined;
}

function getFootMin(filter: FilterState): IJsonQueryRange | undefined {
  const { maxDistance, movementMethod } = filter.subwayDistance;

  return maxDistance != null && movementMethod != null ? range(maxDistance) : undefined;
}

function getGarageSubtypes(filter: FilterState): IJsonQueryTerms<number> {
  return terms(mapByDict<number>(filter.selectedGarageSubtypes, garageSubtypeDict));
}

function getHouseMaterial(filter: FilterState): IJsonQueryTerms<number> {
  const types = isSuburban(filter.selectedObjectTypes) ? filter.houseMaterials : filter.selectedHouseTypes;

  return terms(types.map(Number));
}

function getWc(filter: FilterState): IJsonQueryRange | undefined {
  let { bathroomsCount } = filter;

  // см. комментарии CSSSR-6770
  if (filter.bathroomType !== BathroomType.Any && bathroomsCount === BathroomsCountType.Any) {
    bathroomsCount = BathroomsCountType.One;
  }

  return bathroomsCount ? range(undefined, bathroomsCount) : undefined;
}

function getHasFurniture(filter: FilterState): IJsonQueryTerm<boolean> | undefined {
  if (
    filter.withFurniture == null ||
    (!fields.withFurnitureFlat.isVisible(filter) && !fields.withFurnitureCommerce.isVisible(filter))
  ) {
    return;
  }

  return term(filter.withFurniture);
}

function getCommissionType(filter: FilterState): IJsonQueryTerm<CommissionType> | undefined {
  return filter.agentNoFee ? term(CommissionType.Zero) : undefined;
}

function getPriceType(filter: FilterState): IJsonQueryTerm<boolean> | undefined {
  return filter.priceType === PriceType.SM
    ? {
        type: 'term',
        value: true,
      }
    : undefined;
}

function getJsonQueryPriceType(filter: FilterState): IJsonQueryTerm<EJsonQueryPriceType> | undefined {
  if (filter.jsonQueryPriceType == null) {
    return undefined;
  }

  return filter.jsonQueryPriceType === EJsonQueryPriceType.All ? undefined : term(filter.jsonQueryPriceType);
}

function getIsByCommercialOwner(filter: FilterState): IJsonQueryTerm<boolean> | undefined {
  const isSingleObjectType = filter.selectedObjectTypes.length === 1;

  if ((isSingleObjectType && isCoworkingFilters(filter)) || isGarageFilterOnly(filter)) {
    return undefined;
  }

  return filter.isByCommercialOwner ? term(filter.isByCommercialOwner) : undefined;
}

function getReadyBusinessTypes(filter: FilterState): IJsonQuery['ready_business_types'] {
  return terms(
    filter.selectedObjectTypes.reduce((acc, type) => {
      if (type === 'rentalBusiness') {
        acc.push(1);
      }

      if (type === 'readyBusiness') {
        acc.push(2);
      }

      return acc;
    }, Array.of<number>()),
  );
}

function getElectronicTrading(filter: FilterState): IJsonQueryTerm<EElectronicTradingType> | undefined {
  if (!filter.electronicTrading) {
    return undefined;
  }

  return filter.electronicTrading === EElectronicTradingType.DoNotDisplay ||
    filter.electronicTrading === EElectronicTradingType.ElectronicTradingOnly
    ? term(filter.electronicTrading)
    : undefined;
}

/**
 * По умолчанию когда мы заходим на любую страничку /snyat-ofis, /kupit-kvartiry etc.
 * фильтры находятся в дефолтном состоянии хотя это !! -> не правда <- !!. К сожалению перекорчевать
 * фильтры так что-бы они были всегда актуальны очень долго, по этому оринтеруйтесь по экшену GetInitialFiltersSuccess
 * но он 100% случаев тоже не покрывает.
 * добавлено в рамках постмортема "https://cianru.atlassian.net/browse/FAIL-145"
 */
export function filterToJsonQuery(
  filter: FilterState,
  filtersVisibilityParams: IFiltersVisibilityParams | undefined,
  queryClient: QueryClient,
): IJsonQuery {
  const isNewbuildingsMapMode = Boolean(filtersVisibilityParams && filtersVisibilityParams.isNewbuildingsMapMode);
  const isCustomSpecialPromo = Boolean(filtersVisibilityParams && filtersVisibilityParams.isCustomSpecialPromo);
  const optionalRegionInfo = filtersVisibilityParams ? filtersVisibilityParams.optionalRegionInfo : undefined;

  function checkVisible<T>(
    field: FieldType | FieldType[],
    getValue: () => T,
    overwriteFilterParams?: Partial<IFiltersVisibilityParams>,
  ): T | undefined {
    let isVisible;
    const filterParams = {
      isNewbuildingsMapMode,
      isCustomSpecialPromo,
      optionalRegionInfo,
      ...overwriteFilterParams,
    };

    if (Array.isArray(field)) {
      isVisible = field.some(f => f.isVisible(filter, filterParams));
    } else {
      isVisible = field.isVisible(filter, filterParams);
    }

    return isVisible ? getValue() : undefined;
  }

  const buildingStatus =
    filter.dealType === DealType.Sale ? checkVisible([fields.houseStatus], () => getBuildingStatus(filter)) : undefined;

  let objectType = buildingStatus ? undefined : checkVisible(fields.selectObjectType, () => getObjectTypes(filter));

  const coworkingOfferTypeJson = checkVisible(fields.selectCoworkingOfferType, () => getCoworkingOfferType(filter));
  if (
    filter.selectedObjectTypes &&
    filter.selectedObjectTypes.length === 1 &&
    filter.selectedObjectTypes[0] === 'ofis' &&
    filter.selectedCoworkingOfferType &&
    filter.selectedCoworkingOfferType.length > 0
  ) {
    if (objectType) {
      objectType = {
        ...objectType,
        value: [13],
      };
    }
  }

  const withNewobjectShouldBeShown = filter.withNewobject || areAllTagsNewobjects(filter);
  const withNewobject = checkVisible(fields.houseStatus, () =>
    withNewobjectShouldBeShown ? term(filter.withNewobject || areAllTagsNewobjects(filter)) : undefined,
  );

  const fetchGetBusinessPossibleAppointmentsLoadService = new FetchGetBusinessPossibleAppointmentsLoadService(
    queryClient,
  );

  const jsonQuery: IJsonQuery = {
    _type: getType(filter),
    building_class_type: checkVisible(fields.buildingClass, () => terms(filter.selectedBuildingClasses)),
    // только для квартир в новостройках
    building_status: buildingStatus,
    // только для коммерческой земли
    category: getCatagory(filter),
    commission_type:
      filter.rentTime === rentTimeDict.Long ? checkVisible(fields.terms, () => getCommissionType(filter)) : undefined,
    currency: checkVisible(fields.currency, () => getCurrency(filter)),
    electricity: checkVisible(fields.steadConditions, () => getSteadCondition(filter, SteadCondition.Electricity)),
    engine_version: term(filter.engineVersion),
    is_by_homeowner: checkVisible(fields.terms, () => (filter.isByHomeOwner ? term(filter.isByHomeOwner) : undefined)),
    is_by_commercial_owner: checkVisible(fields.terms, () => getIsByCommercialOwner(filter)),
    floorn: checkVisible(fields.floorsNumber, () =>
      range(filter.floorsNumber.max || undefined, filter.floorsNumber.min || undefined),
    ),
    foot_min: checkVisible(fields.subwayDistance, () => getFootMin(filter)),
    from_developer: checkVisible(fields.selectSellerType, () => getSellerType(filter)),
    gas: checkVisible(fields.steadConditions, () => getSteadCondition(filter, SteadCondition.Gas)),
    garage_garage_type: checkVisible(fields.selectGarageSubtype, () => getGarageSubtypes(filter)),
    garage_material: checkVisible(fields.boxType, () => terms(filter.boxTypes)),
    garage_type: checkVisible(fields.garageType, () => terms(filter.selectedGarageTypes)),
    geo: getGeo(filter),
    hand_over: checkVisible(fields.selectYear, () => getHandOver(filter)),
    has_drainage: checkVisible(fields.steadConditions, () => getSteadCondition(filter, SteadCondition.Sewerage)),
    has_water: checkVisible(fields.steadConditions, () => getSteadCondition(filter, SteadCondition.Water)),
    house_material: checkVisible([fields.selectHouseType, fields.houseType, fields.selectMaterial], () =>
      getHouseMaterial(filter),
    ),
    house_year: checkVisible(fields.houseYear, () =>
      range(filter.houseYear.max || undefined, filter.houseYear.min || undefined),
    ),
    is_pik_promo: checkVisible(fields.withPik, () => (filter.withPik ? term(filter.withPik) : undefined)),
    is_special_promo: checkVisible(fields.withCustom, () => (filter.withCustom ? term(filter.withCustom) : undefined), {
      isCustomSpecialPromo: true,
    }),
    ipoteka: checkVisible(fields.withMortgage, () => (filter.withMortgage ? term(filter.withMortgage) : undefined)),
    with_layout: checkVisible(fields.withLayout, () => (filter.withLayout ? term(filter.withLayout) : undefined)),
    price: checkVisible(fields.price, () => range(filter.price.max || undefined, filter.price.min || undefined)),
    price_sm: getPriceType(filter),
    price_type: getJsonQueryPriceType(filter),
    site: checkVisible([fields.squareSteadFastFilter, fields.squareSteadAdvancedFilter], () =>
      range(filter.sizeStead.max || undefined, filter.sizeStead.min || undefined),
    ),
    total_area: checkVisible([fields.square, fields.totalArea], () =>
      range(filter.sizeTotal.max || undefined, filter.sizeTotal.min || undefined),
    ),
    for_day: checkVisible(fields.rentTime, () => term(filter.rentTime)),
    kitchen: checkVisible(fields.kitchenArea, () =>
      range(filter.sizeKitchen.max || undefined, filter.sizeKitchen.min || undefined),
    ),
    land_status2: checkVisible(fields.steadStatus, () => getSteadStatus(filter)),
    lifts: checkVisible(fields.lifts, () => (filter.liftsCount ? term(filter.liftsCount) : undefined)),
    lift_service: checkVisible(fields.lifts, () => range(undefined, filter.withServiceLift ? 1 : undefined)),
    living_area: checkVisible(fields.livingArea, () =>
      range(filter.sizeLiving.max || undefined, filter.sizeLiving.min || undefined),
    ),
    loggia: checkVisible(fields.balcony, () => (filter.withLoggia ? term(filter.withLoggia) : undefined)),
    balconies: checkVisible(fields.balcony, () => (filter.withBalcony ? range(undefined, 1) : undefined)),
    multi_id: filter.multiObject && term(filter.multiObject.id),
    object_type: objectType,
    office_type: checkVisible(fields.selectObjectType, () => getOfficeTypes(filter)),
    only_foot: checkVisible(fields.subwayDistance, () => getOnlyFoot(filter)),
    promo: checkVisible(fields.withDiscount, () => (filter.withDiscount ? term(filter.withDiscount) : undefined)),
    sost_type: checkVisible(fields.saleType, () => getSaleType(filter)),
    publish_period: checkVisible(fields.publicationPeriod, () =>
      filter.publicationPeriod != null ? term(filter.publicationPeriod) : undefined,
    ),
    region: checkVisible(fields.region, () => getRegion(filter)),
    demolished_in_moscow_programm: checkVisible(fields.renovation, () =>
      typeof filter.demolishedInMoscowProgramm === 'boolean'
        ? term<boolean>(filter.demolishedInMoscowProgramm)
        : undefined,
    ),
    room: getRooms(filter),
    rooms_count: checkVisible(fields.roomsCount, () =>
      filter.roomsCount.length > 0 ? terms(filter.roomsCount) : undefined,
    ),
    rooms_for_sale: checkVisible(fields.rentRoomsCount, () => range(undefined, filter.rentRoomsCount)),
    // объявления, привязанные к ЖК (не обязательно новостройки)
    with_newobject: withNewobject,
    wc_site: checkVisible(fields.bathroomPlacement, () =>
      filter.bathroomPlacement ? term(filter.bathroomPlacement) : undefined,
    ),
    wc_type: checkVisible(fields.bathroomType, () => (filter.bathroomType ? term(filter.bathroomType) : undefined)),
    wc: checkVisible(fields.selectBathroomsCount, () => getWc(filter)),
    has_fridge: checkVisible(fields.conditions, () => getFlatCondition(filter, FlatCondition.Fridge)),
    has_washer: checkVisible(fields.conditions, () => getFlatCondition(filter, FlatCondition.Washer)),
    has_video: checkVisible(fields.hasVideo, () => (filter.hasVideo ? term(filter.hasVideo) : undefined)),
    has_kitchen_furniture: checkVisible(fields.conditions, () =>
      getFlatCondition(filter, FlatCondition.KitchenFurniture),
    ),
    internet: checkVisible(fields.conditions, () => getFlatCondition(filter, FlatCondition.Internet)),
    tv: checkVisible(fields.conditions, () => getFlatCondition(filter, FlatCondition.Tv)),
    conditioner: checkVisible(fields.conditions, () => getFlatCondition(filter, FlatCondition.Conditioner)),
    dish_washer: checkVisible(fields.conditions, () => getFlatCondition(filter, FlatCondition.DishWasher)),
    phone: checkVisible(fields.conditions, () => getFlatCondition(filter, FlatCondition.Phone)),
    bath: checkVisible(fields.conditions, () => getFlatCondition(filter, FlatCondition.Bath)),
    shower: checkVisible(fields.conditions, () => getFlatCondition(filter, FlatCondition.Shower)),
    pets: checkVisible(fields.conditions, () => getFlatCondition(filter, FlatCondition.Pets)),
    kp_id: filter.kpId ? term(filter.kpId) : undefined,
    suburban_offer_filter: filter.suburbanOfferFilter ? term(filter.suburbanOfferFilter) : undefined,
    kids: checkVisible(fields.conditions, () => getFlatCondition(filter, FlatCondition.Kids)),
    bathhouse: checkVisible(fields.conditions, () => getSuburbanCondition(filter, FlatCondition.Bathhouse)),
    garage: checkVisible(fields.conditions, () => getSuburbanCondition(filter, FlatCondition.Garage)),
    pool: checkVisible(fields.conditions, () => getSuburbanCondition(filter, FlatCondition.Pool)),
    has_furniture: getHasFurniture(filter),
    // не проверяем видимость, так как даже, если это поле невидимо, то могут добавляться terms из других полей
    wp: checkVisible(fields.withPhoto, () => (filter.withPhoto ? term(filter.withPhoto) : undefined)),
    year: checkVisible(fields.selectYear, () => getYears(filter)),
    yeargte: checkVisible(fields.selectYear, () => getYearGte(filter)),
    enter: checkVisible(fields.accessSystem, () =>
      filter.accessSystem != null ? term(filter.accessSystem) : undefined,
    ),
    input_type: checkVisible(fields.enterType, () => terms(filter.enterTypes)),
    contract: checkVisible(fields.contractType, () => terms(filter.contractTypes)),
    from_offrep: checkVisible(fields.terms, () => getCommercialOffrep(filter)),
    apartment: checkVisible(fields.apartments, () => (filter.apartment ? term(filter.apartment) : undefined)),
    windows_type: checkVisible(fields.windowsType, () =>
      filter.windowsType !== undefined ? term(filter.windowsType) : undefined,
    ),
    ceiling_height: checkVisible(fields.ceilingHeight, () => range(undefined, filter.ceilingHeight)),
    only_flat: checkVisible(fields.apartments, () => (filter.onlyFlat ? term(filter.onlyFlat) : undefined)),
    has_decoration: checkVisible(fields.decoration, () =>
      filter.decoration != null ? term(filter.decoration) : undefined,
    ),
    not_last_floor: checkVisible(fields.edgeFloors, () =>
      filter.notLastFloor != null ? term(filter.notLastFloor) : undefined,
    ),
    is_first_floor: checkVisible([fields.edgeFloors, fields.basementFloors], () =>
      filter.firstFloor != null ? term(filter.firstFloor) : undefined,
    ),
    is_basement: checkVisible(fields.basementFloors, () =>
      filter.basementFloor ? term(filter.basementFloor) : undefined,
    ),
    is_semibasement: checkVisible(fields.basementFloors, () =>
      filter.semibasementFloor ? term(filter.semibasementFloor) : undefined,
    ),
    floor: checkVisible([fields.floorCommerce, fields.floorFlat], () =>
      range(filter.floor.max || undefined, filter.floor.min || undefined),
    ),
    from_mcad_km: checkVisible(fields.direction, () =>
      range(filter.distanceToMKAD.max || undefined, filter.distanceToMKAD.min || undefined),
    ),
    zalog: checkVisible(fields.terms, () => (filter.withDeposit === false ? term(filter.withDeposit) : undefined)),
    bs_center: getBSCenter(filter),
    room_type: checkVisible(fields.roomType, () => (filter.roomType ? term(filter.roomType) : undefined)),
    domrf: checkVisible(fields.fromDomrf, () => (filter.isFromDomrf ? term(filter.isFromDomrf) : undefined)),
    coworking_offer_type: coworkingOfferTypeJson,
    amenity: checkVisible(fields.coworkingAmenities, () => getCoworkingAmenities(filter)),
    all_day_access: checkVisible(fields.coworkingAccess, () => getCoworkingAccess(filter, 'allDay')),
    all_week_access: checkVisible(fields.coworkingAccess, () => getCoworkingAccess(filter, 'allWeek')),
    workplace_count: checkVisible(fields.workPlaceCount, () =>
      range(
        (filter.workplace_count && filter.workplace_count.max) || undefined,
        (filter.workplace_count && filter.workplace_count.min) || undefined,
      ),
    ),
    is_sales_leader: checkVisible(fields.newobjectCompilation, () =>
      filter.newobjectCompilation.isSalesLeader ? term(filter.newobjectCompilation.isSalesLeader) : undefined,
    ),
    is_sales_start: checkVisible(fields.newobjectCompilation, () =>
      filter.newobjectCompilation.isSalesStart ? term(filter.newobjectCompilation.isSalesStart) : undefined,
    ),
    is_upcoming_sale: checkVisible(fields.upcomingSale, () =>
      filter.isUpcomingSale ? term(filter.isUpcomingSale) : undefined,
    ),
    ready_business_types: checkVisible(fields.selectObjectType, () => getReadyBusinessTypes(filter)),
    specialty_types: checkVisible(fields.businessAppointments, () => {
      const businessPossibleAppointments = fetchGetBusinessPossibleAppointmentsLoadService.getQueryData();

      if (!businessPossibleAppointments) {
        return filter.businessAppointments.length ? terms(filter.businessAppointments) : undefined;
      }

      const businessAppointmentsIdsService = new BusinessAppointmentsIdsService(filter.businessAppointments);

      const availableBusinessAppointmentsIds = businessAppointmentsIdsService.getAvailableBusinessAppointmentsIds(
        businessPossibleAppointments,
        filter.selectedObjectTypes,
      );

      return availableBusinessAppointmentsIds.length ? terms(availableBusinessAppointmentsIds) : undefined;
    }),
    electronic_trading: checkVisible(fields.electronicTrading, () => getElectronicTrading(filter)),
  };

  return omitEmptyValues(jsonQuery);
}
