import { IStyleConfig, mergeStyles } from '@cian/components';
import * as React from 'react';
import { animateVisibility } from '../animation/visibility/visibility';

const styles = require('./tooltip.css');

export enum TooltipPosition {
  Right = 1,
  Left,
  Top,
  Bottom = 0,
}

const tooltipPositionStyles = {
  [TooltipPosition.Left]: styles.alignLeft,
  [TooltipPosition.Right]: styles.alignRight,
  [TooltipPosition.Top]: styles.alignTop,
  [TooltipPosition.Bottom]: styles.alignBottom,
};

const tooltipPositionAnimateStyles = {
  [TooltipPosition.Left]: styles.tooltipAnimationContainerFromRight,
  [TooltipPosition.Right]: styles.tooltipAnimationContainerFromRight,
  [TooltipPosition.Top]: styles.tooltipAnimationContainerFromBottom,
  [TooltipPosition.Bottom]: styles.tooltipAnimationContainerFromBottom,
};

interface ITooltipProps {
  children?: React.ReactNode;
  position: TooltipPosition;
  tooltipStyle?: IStyleConfig;
  dataMark?: string;
}

export class Tooltip extends React.Component<ITooltipProps, object> {

  public render() {
    const tooltipStyles = mergeStyles([
      styles.tooltip,
      this.props.tooltipStyle,
      tooltipPositionStyles[this.props.position],
    ]);
    return (
      <div {...tooltipStyles} data-mark={this.props.dataMark}>
        {this.props.children}
      </div>
    );
  }
}

export const AnimatedTooltip = animateVisibility(Tooltip, props => ({
  ...props,
  containerStyle: mergeStyles(
    styles.tooltipAnimationContainer,
    tooltipPositionAnimateStyles[props.position],
    props.containerStyle).className,
  visibleStyle: styles.showAnimation,
}));
