import { IGKData } from '../../../api/models/gk_card';
import { formatNumber } from '../../../utils/formatters';
import { capitalize } from '../../../utils/text';
import { IAboutGK } from '../model';

const parseStage = (gk: IGKData): string => {

  switch (gk.building_status_id) {
    case 5002:
      return 'строится';
    case 5005:
      return 'проект';
    case 5004:
      return 'сдан';
    default:
      return '';
  }
};

const parseQueue = (gk: IGKData): string[] => {
  return gk.periods != null
    ? gk.periods.map(period => period.text)
    : [];
};

const parseMaterials = (gk: IGKData): string | undefined => {
  const parseMaterial = (id: number) => {
    switch (id) {
      case 401:
        return 'кирпичный';
      case 402:
        return 'монолитный';
      case 403:
        return 'панельный';
      case 404:
        return 'блочный';
      case 405:
        return 'деревянный';
      case 407:
        return 'кирпично-монолитный';
      default:
        return null;
    }
  };

  return gk.materials != null
    ? capitalize(gk.materials
        .map(parseMaterial)
        .join(', '))
    : undefined;
};

const parseCeilings = (gk: IGKData): string | undefined => {
  if (gk.ceiling_height_range && (gk.ceiling_height_range.from || gk.ceiling_height_range.to)) {
    const { from, to } = gk.ceiling_height_range;

    return from != null && to != null && from !== to
      ? `${formatNumber(from)} - ${formatNumber(to)} м`
      : `${formatNumber((from || to) as number)} м`;
  }

  return undefined;
};

const parseFloors = (gk: IGKData): string | undefined => {
  if (gk.floor && (gk.floor.minFloors || gk.floor.maxFloors)) {
    const { minFloors, maxFloors } = gk.floor;

    return minFloors != null && maxFloors != null && minFloors !== maxFloors
      ? `${minFloors} - ${maxFloors}`
      : `${minFloors || maxFloors}`;
  }

  return undefined;
};

export const parseAboutGK = (gk: IGKData): IAboutGK => {
  return {
    stage: parseStage(gk),
    queues: parseQueue(gk),
    status: gk.building_status_display,
    buildingClass: gk.new_object_class_name,
    houses: gk.houses && gk.houses.count,
    floors: parseFloors(gk),
    material: parseMaterials(gk),
    ceilings: parseCeilings(gk),
  };
};
