import { ITypeaheadSuggestion } from '@cian/components';
import {
  ESuggestType,
  IAddressSuggest,
  IMultiSuggestResult as IMultiSuggestFull,
  IResultSuggestions } from '@cian/geosuggest-widget';
import { capitalize, isEmpty, omitBy } from 'lodash';
import { GeoTagKind, IBoundedBy, IJKSuggestionItem, IRailwaysData, MobileWebsiteApi } from '../../../../api/api';
import { IGeoSuggestRequestScheme, IMultiSuggestResult } from '../../../../api/models/geo_suggest';
import { coordsCianToYmapsBox } from '../../../../utils/regions';
import { IYmapsSuggestion, IYmapsSuggestOptions, loadYmapsApi } from '../../../../utils/ymaps';

export interface IYaError {
  message: string;
}
export function loadYmaps() {
  return loadYmapsApi(['suggest', 'behavior.DblClickZoom']);
}

export function YaSuggest(query: string, options?: IAddressSuggestionOptions ): Promise<IYmapsSuggestion[] | IYaError> {
  const results = options && options.results;
  const boundedBy = options && options.boundedBy && coordsCianToYmapsBox(options.boundedBy);
  const suggestOptions: IYmapsSuggestOptions = { results, boundedBy };

  return loadYmaps().then(ymaps => ymaps.suggest(`Россия, ${query}`, suggestOptions));
}

export function prepareTagKind(group: ESuggestType) {
  let kind: string;
  switch (group) {
    case ESuggestType.city:
      kind = 'location';
      break;
    case ESuggestType.road:
      kind = 'highway';
      break;
    case ESuggestType.newbuilding:
      kind = 'newobject';
      break;
    default:
      kind = group;
  }

  return capitalize(kind) as GeoTagKind;
}
export function prepareJKSuggestion(jk?: IJKSuggestionItem): ITypeaheadSuggestion | undefined {
  if (jk == null) {
    return undefined;
  }

  return {
    label: jk.value,
    meta: { suggestionData: jk.data, isJk: true },
  };
}

export function prepareRailwaysSuggestion(railways: IRailwaysData): ITypeaheadSuggestion {
  return {
    label: railways.name,
    meta: {
      suggestionData: railways,
      isRailway: true,
    },
  };
}

export function prepareYmapsSuggestion(suggestionData: IYmapsSuggestion): ITypeaheadSuggestion | undefined {
  return {
    label: suggestionData.displayName,
    meta: { suggestionData },
  };
}

export interface IAddressSuggestionOptions {
  boundedBy?: IBoundedBy;
  results: number;
}

export interface IMultiSuggestParams {
  api: MobileWebsiteApi;
  structured: IGeoSuggestRequestScheme;
  yandex: {
    value: string,
    options?: IAddressSuggestionOptions,
  };
}

export enum ESuggestOfferType {
  FLAT = 'flat',
  SUBURBAN = 'suburban',
  COMMERCIAL = 'commercial',
  NEWOBJECT = 'newobject',
}

export function getSuggestOfferType(offerType: string, withNewobject?: boolean) {
  if (offerType.includes('suburban')) {
    return ESuggestOfferType.SUBURBAN;
  } else if (offerType.includes('commercial')) {
    return ESuggestOfferType.COMMERCIAL;
  } else if (withNewobject && offerType.includes('flat')) {
    return ESuggestOfferType.NEWOBJECT;
  }
  return ESuggestOfferType.FLAT;
}

//для коммерческой недвижимости не показываем ЖД,
export function filterSuggest(offerType: string, suggestions: IResultSuggestions) {
  let filtered: IResultSuggestions = {...suggestions};

  if (offerType === ESuggestOfferType.COMMERCIAL) {
    filtered = { ...filtered, railways: undefined };
  }

  return filtered;
}

export function getMultiSuggest(
  params: IMultiSuggestParams,
): Promise<IMultiSuggestFull> {
  const {
    yandex: { value, options },
    structured: structuredParams,
    api: { getGeoSuggest },
  } = params;

  return Promise.all([
      YaSuggest(value, options).catch(() => null),
      getGeoSuggest(structuredParams).catch(() => null),
    ])
    .then((results: [IYmapsSuggestion[] | null, IMultiSuggestResult | null]) => {
      const [yandex, structured] = results;

      if ((!yandex || 'message' in yandex ) && (!structured || (structured && !structured.suggestions))) {
        throw new Error('Failed to load suggest data');
      }

      let suggestions: IResultSuggestions = {};

      if (structured && structured.suggestions) {
        const {
          businessCenters,
          cities,
          districts,
          newbuildings,
          shoppingCenters,
          undergrounds,
          railways,
          roads,
          villages,
        } = structured.suggestions;

        suggestions = {
          businessCenters: businessCenters && businessCenters.items,
          cities: cities && cities.items,
          districts: districts && districts.items,
          newbuildings: newbuildings && newbuildings.items,
          shoppingCenters: shoppingCenters && shoppingCenters.items,
          undergrounds: undergrounds && undergrounds.items,
          villages: villages && villages.items,
          roads: roads && roads.items,
          railways: railways && railways.items,
        };
      }

      suggestions = omitBy(filterSuggest(structuredParams.offerType, suggestions), isEmpty);

      if (yandex && !isEmpty(yandex)) {
        suggestions.addresses = Object.keys(suggestions).length
          ? (yandex as IAddressSuggest[]).slice(0, 5)
          : yandex as IAddressSuggest[];
      }

      return {
        suggestions,
        sortOrder: isEmpty(suggestions) ? [] : structured && structured.sortOrder || ['addresses'],
      };
    });
}
