import { ILogger } from '@cian/logger';
import { IPromoBlock } from '../../../api/api';
import { toPromise } from '../../../utils/streams';
import { FiltersSection, FiltersSectionQuery } from '../../filters/model';
import { TTypedThunkAction } from '../../model';

export type TPromoBlocksActions
  = { type: 'GetPromoBlocksPending' }
  | { type: 'GetPromoBlocksSuccess', payload: { promoBlocks: IPromoBlock[] } }
  | { type: 'GetPromoBlocksError', payload: { err: Error } };

export function getPromoBlocks(sectionType: FiltersSection, logger: ILogger): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    dispatch<TPromoBlocksActions>({ type: 'GetPromoBlocksPending' });
    return toPromise(api.promoBlocks(FiltersSectionQuery[sectionType]))
      .then(res => {
        dispatch<TPromoBlocksActions>({
          type: 'GetPromoBlocksSuccess',
          payload: {
            promoBlocks: res.result.data.promoBlocks,
          },
        });
      })
      .catch(err => {
        logger.error(err);

        dispatch<TPromoBlocksActions>({ type: 'GetPromoBlocksError', payload: { err } });
      });
  };
}
