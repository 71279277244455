import { TQuickLinksActions } from './actions';
import { IQuickLinks } from './model';

type Reducer = (state: IQuickLinks, action: TQuickLinksActions) => IQuickLinks;

const defaultState = {
  level: 0,
  links: null,
};

export const quickLinksReducer: Reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'QuickLinksSuccess':
      return {
        ...state,
        level: action.payload.data.level,
        links: action.payload.data.links,
      };

    case 'ResetQuickLinks':
      return {
        ...state,
        level: 0,
        links: null,
      };

    default:
      return state;
  }
};
