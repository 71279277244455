import { TSpecialPromoActions } from './actions';
import { ISpecialPromo } from './model';

export function specialPromoReducer(
  state: ISpecialPromo | null = null, action: TSpecialPromoActions,
): ISpecialPromo | null {
  switch (action.type) {
    case 'SetSpecialPromo':
      return action.payload;

    case 'ClearSpecialPromo':
      return null;

    default:
      return state;
  }
}
