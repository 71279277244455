import * as React from 'react';

import { FilterState } from '../../../redux/filters/model';
import { shallowEqual } from '../../../utils/react_helpers';
import RangeComponent from '../../common/range/range';

import {
  IFieldProps,
  isFlatObjectGroup,
  isSuburbanObjectGroupHouse,
  IsVisibleCheck,
  isCommercialObjectBuilding,
} from '../field';

const styles = require('../field.css');

import { Label } from '@cian/components';

export class Component extends React.Component<IFieldProps, object> {

  public shouldComponentUpdate(nextProps: IFieldProps) {
    const { isCommercialFilter } = this.props;
    const filter: FilterState = this.props.filter;
    const nextFilter: FilterState = nextProps.filter;

    return isCommercialFilter !== nextProps.isCommercialFilter ||
      !shallowEqual(filter.houseYear, nextFilter.houseYear) ||
      filter.dealType !== nextFilter.dealType;
  }

  public render() {
    const { isCommercialFilter } = this.props;
    const labelText = `Год постройки ${isCommercialFilter ? 'здания' : 'дома'}`;

    return (
      <Label
        label={labelText}
        containerStyle={styles.label}
      >
        <RangeComponent
          field="houseYear"
          disablePrettyFormating
        />
      </Label>
    );
  }
}

export const isVisible: IsVisibleCheck = filter =>
  !filter.withNewobject &&
  (isFlatObjectGroup(filter) || isSuburbanObjectGroupHouse(filter) || isCommercialObjectBuilding(filter));
