/* eslint-disable max-lines */

import { get, isEqual, range, set, uniq } from 'lodash';

import {
  GeoTagKind,
  IAddressNode,
  IAddressTag,
  IBCTag,
  IDistrictTag,
  IGeoTags,
  IMetroTag,
  INewobjectTag,
  IPolygonTag,
  IRailwayTag,
  ISCTag,
  IVillageTag,
} from '../../api/api';
import { IJsonQuery, IJsonQueryBuilder, IJsonQueryHighway, parseObjectType } from '../../api/models/json_query';
import { amenitiesOptions } from '../../filters/fields/coworking_amenities/constants';
import { getGeoTagName } from '../../utils/geotags';
import { ifNull } from '../../utils/helpers';
import { deepEmpty } from '../../utils/object_helpers';
import { Regions, getRegionByTitle } from '../../utils/regions';
import { ISpecialPromo } from '../special_promo/model';

import { garageSubtypeDictById, mapByDict, rentTimeDict } from './dictionaries';
import {
  BathroomPlacement,
  BathroomType,
  BuildingClassType,
  BuildingStatus,
  CommissionType,
  ContractType,
  Currency,
  DealType,
  EElectronicTradingType,
  EnterType,
  FilterState,
  FlatCondition,
  ICoworkingAccess,
  IGeoTag,
  IRange,
  ISaleTypesNewbuilding,
  ISubwayDistance,
  MovementMethod,
  PriceType,
  PublicationPeriod,
  RentTime,
  RoomType,
  SteadCondition,
  SteadStatus,
  TCategoryType,
  TCoworkingOfferType,
  TPriceType,
  commercialObjectType,
  coworkingOfferType,
  defaultFilter,
  flatObjectType,
  sellerType,
  suburbanObjectType,
  yearType,
} from './model';

function getCurrency(value?: number): Currency {
  switch (value) {
    case 2:
      return Currency.RUB;
    case 1:
      return Currency.USD;
    case 3:
      return Currency.EUR;
    default:
      return defaultFilter.currency;
  }
}

function getPublicationPeriod(value?: number): number | undefined {
  if (value == null) {
    return defaultFilter.publicationPeriod;
  }

  switch (true) {
    case value === -2:
      return PublicationPeriod.Today;
    case value >= 0 && value < 600:
      return PublicationPeriod.Minutes5; // 300
    case value >= 600 && value < 900:
      return PublicationPeriod.Minutes10; // 600
    case value >= 900 && value < 1200:
      return PublicationPeriod.Minutes15; // 900
    case value >= 1200 && value < 1800:
      return PublicationPeriod.Minutes20; // 1200
    case value >= 1800 && value < 3600:
      return PublicationPeriod.Minutes30; // 1800
    case value >= 3600 && value < 7200:
      return PublicationPeriod.Hours1; // 3600
    case value >= 7200 && value < 10800:
      return PublicationPeriod.Hours2; // 7200
    case value >= 10800 && value < 14400:
      return PublicationPeriod.Hours3; // 10800
    case value >= 14400 && value < 18000:
      return PublicationPeriod.Hours4; // 14400
    case value >= 18000 && value < 21600:
      return PublicationPeriod.Hours5; // 18000
    case value >= 21600 && value < 43200:
      return PublicationPeriod.Hours6; // 21600
    case value >= 43200 && value < 64800:
      return PublicationPeriod.Hours12; // 43200
    case value >= 64800 && value < 86400:
      return PublicationPeriod.Hours18; // 64800
    case value >= 86400 && value < 129600:
      return PublicationPeriod.Hours24; // 86400
    case value >= 129600 && value < 172800:
      return PublicationPeriod.Hours36; // 129600
    case value >= 172800 && value < 259200:
      return PublicationPeriod.Days2; // 172800
    case value >= 259200 && value < 432000:
      return PublicationPeriod.Days3; // 259200
    case value >= 432000 && value < 864000:
      return PublicationPeriod.Days5; // 432000
    case value >= 864000 && value < 2592000:
      return PublicationPeriod.Days10; // 864000
    case value >= 2592000:
      return PublicationPeriod.Month; // 2592000
    default:
      return defaultFilter.publicationPeriod;
  }
}

function getDealType(type?: string): DealType {
  if (type == null) {
    return defaultFilter.dealType;
  }
  if (type.includes('sale')) {
    return DealType.Sale;
  }
  if (type.includes('rent')) {
    return DealType.Rent;
  }

  return defaultFilter.dealType;
}

function getSelectedSeller(type?: boolean): Array<string> {
  if (type == null) {
    return defaultFilter.selectedSellers;
  }

  return type ? [sellerType.Developer] : [sellerType.Agent];
}

function getLifts(lifts?: number): number | undefined {
  return lifts ? Number(lifts) : undefined;
}

function getYears(getIn: any): any[] {
  let result: any[] = [];

  function getNumberYears(value?: number[]): string[] | null {
    return value != null ? value.map(String) : null;
  }

  function getYearGte(value?: number): string[] | null {
    return value != null ? [yearType.Later] : null;
  }

  function getYearHandOver(value?: boolean): string[] | null {
    return value ? [yearType.PutIntoOperation] : null;
  }

  if (getIn) {
    result = result
      .concat(getIn('year.value', getNumberYears))
      .concat(getIn('yeargte.value', getYearGte))
      .concat(getIn('hand_over.value', getYearHandOver));
  }

  result = result.filter(option => option != null);

  return result.length > 0 ? result : defaultFilter.selectedYears;
}

function getWithNewobject(getIn: any): any {
  const isWithNewobject = getIn('with_newobject.value', Boolean);
  const hasFromDeveloper = getIn('from_developer', Boolean);
  const isNewBuilding = getIn('building_status.value', Number) === BuildingStatus.New;
  const isSale = getIn('_type', getDealType) === DealType.Sale;
  const isNewObject = getIn('_type', parseObjectType) === 'newobject';
  const isFlatObject = getIn('_type', parseObjectType) === 'flat';

  //Считаем фильтр "только в новостройке выбранным", либо если это явно указано в json_query,
  //либо если в есть любое значение "from_developer"
  // https://cianru.atlassian.net/browse/CSSSR-7521
  // https://cianru.atlassian.net/browse/CSSSR-9197
  return (
    ((isFlatObject && isNewBuilding) || (isWithNewobject && isNewBuilding) || hasFromDeveloper || isNewObject) && isSale
  );
}

function getWithMortgage(getIn: any): any {
  const isMortgage = getIn('ipoteka.value', Boolean);
  const isFlatObject = getIn('_type', parseObjectType) === 'flat';
  const isSale = getIn('_type', getDealType) === DealType.Sale;

  return isMortgage && isFlatObject && isSale;
}

function getWithLayout(getIn: any): any {
  const isLayout = getIn('with_layout.value', Boolean);
  const isNotDailyRent = getIn('for_day.value', withDefault(defaultFilter.rentTime)) !== '1';
  const objectType = getIn('_type', parseObjectType);
  const isResidentialObject = ['flat', 'newobject', 'suburban'].includes(objectType);

  return isLayout && isNotDailyRent && isResidentialObject;
}

function getRange(value?: { gte?: number; lte?: number }): IRange {
  return value != null ? { min: value.gte, max: value.lte } : {};
}

export function getRentTime(value?: string): RentTime {
  switch (value) {
    case '1':
      return rentTimeDict.Short;
    case '!1':
      return rentTimeDict.Long;
    default:
      return defaultFilter.rentTime;
  }
}

function getIsResaleOnly(value?: number): boolean {
  return value === 1;
}

function getElectronicTrading(value?: string): EElectronicTradingType | undefined {
  switch (value) {
    case '1':
      return EElectronicTradingType.ElectronicTradingOnly;
    case '2':
      return EElectronicTradingType.DoNotDisplay;
    default:
      return undefined;
  }
}

function getRooms(
  value?: number[],
): Record<'oneRoom' | `${'two' | 'three' | 'four' | 'five' | 'six'}Rooms` | 'freeLayout' | 'studio', boolean> {
  const rooms = {
    oneRoom: false,
    twoRooms: false,
    threeRooms: false,
    fourRooms: false,
    fiveRooms: false,
    sixRooms: false,
    freeLayout: false,
    studio: false,
  };

  function containsSome(array: number[], elems: number[]): boolean {
    return elems.some(e => array.includes(e));
  }

  if (value) {
    set(rooms, 'oneRoom', containsSome(value, [1]));
    set(rooms, 'twoRooms', containsSome(value, [2]));
    set(rooms, 'threeRooms', containsSome(value, [3]));
    set(rooms, 'fourRooms', containsSome(value, [4]));
    set(rooms, 'fiveRooms', containsSome(value, [5]));
    set(rooms, 'sixRooms', containsSome(value, [6]));
    set(rooms, 'freeLayout', containsSome(value, [7]));
    set(rooms, 'studio', containsSome(value, [9]));
  }

  return rooms;
}

function getSaleTypesNewbuilding(values?: number[]): ISaleTypesNewbuilding {
  const saleTypes = Object.assign({}, defaultFilter.saleTypesNewbuilding);

  function contains(array: number[], elems: number[]): boolean {
    return elems.every(e => array.includes(e));
  }

  if (values) {
    set(saleTypes, 'FZ214', contains(values, [3]));
    set(saleTypes, 'cessionOfRights', contains(values, [4]));
    set(saleTypes, 'ZhSK', contains(values, [5]));

    return saleTypes;
  }

  return defaultFilter.saleTypesNewbuilding;
}

function getRegion(value?: number[]): number {
  if (isEqual(value, [Regions.Moscow, Regions.MoscowRegion])) {
    return Regions.MoscowAndRegion;
  }
  if (isEqual(value, [Regions.SaintPetersburg, Regions.LeningradRegion])) {
    return Regions.SaintPetersburgAndRegion;
  }

  return value != null ? value[0] : 0;
}

const geoTagsEmpty = (geo?: IGeoTags): boolean => {
  if (!geo) {
    return true;
  }

  return deepEmpty(geo.geoTags);
};

function getRegionFromGeo(getIn: any, geo?: IGeoTags): number | undefined {
  if (geo && (!geoTagsEmpty(geo) || getIn('geo.value', getHighways).length > 0)) {
    if (geo.region && geo.region.id) {
      return geo.region.id;
    }
  }

  return getIn('region.value', getRegion);
}

function getObjectTypes(getIn: any, jsonQuery: IJsonQuery): any[] {
  let result: any[] = [];

  function getSuburbanTypes(value?: number[]): Array<'dom' | 'chast_doma' | 'taunkhaus' | 'uchastok' | null> | null {
    const isFlatObject = getIn('_type', parseObjectType) === 'flat';

    if (value && !getWithNewobject(getIn) && !isFlatObject) {
      return value.map(n => {
        switch (n) {
          case 1:
            return suburbanObjectType.House;
          case 2:
            return suburbanObjectType.PartOfHouse;
          case 3:
            return suburbanObjectType.Stead;
          case 4:
            return suburbanObjectType.TownHouse;
          default:
            return null;
        }
      });
    }

    return null;
  }

  function getCommercialOfficeTypes(
    jsonQueryParam: IJsonQuery,
  ): Array<(typeof commercialObjectType)[keyof typeof commercialObjectType]> | null {
    const officeTypes = jsonQueryParam.office_type?.value;
    const readyBusinessTypes = jsonQueryParam.ready_business_types?.value;

    if (officeTypes) {
      return officeTypes.reduce((acc, n) => {
        switch (n) {
          case 12:
            acc.push(commercialObjectType.DomesticService);
            break;
          case 6:
            acc.push(commercialObjectType.Garage);
            break;
          case 11:
            acc.push(commercialObjectType.Building);
            break;
          case 4:
            acc.push(commercialObjectType.PublicCatering);
            break;
          case 1:
            acc.push(commercialObjectType.Office);
            break;
          case 5:
            acc.push(commercialObjectType.Psn);
            break;
          case 9:
            acc.push(commercialObjectType.CarService);
            break;
          case 7:
            acc.push(commercialObjectType.Production);
            break;
          case 3:
            acc.push(commercialObjectType.Warehouse);
            break;
          case 2:
            acc.push(commercialObjectType.Marketplace);
            break;
          case 13:
            acc.push(commercialObjectType.Coworking);
            break;
          case 10: {
            if (readyBusinessTypes) {
              acc.push(
                ...readyBusinessTypes.map(type =>
                  type === 1 ? commercialObjectType.RentalBusiness : commercialObjectType.ReadyBusiness,
                ),
              );
              break;
            }

            acc.push(commercialObjectType.RentalBusiness, commercialObjectType.ReadyBusiness);

            break;
          }
          default:
            return null;
        }

        return acc;
      }, Array.of<(typeof commercialObjectType)[keyof typeof commercialObjectType]>());
    }

    return null;
  }

  function getFlatTypes(values?: number[]): Array<string> {
    const flatTypes = Array.of<string>();
    if (values) {
      const isApartament = values.some(value => {
        return range(1, 7).some(rangeValue => rangeValue === value) || value === 9;
      });

      if (isApartament) {
        flatTypes.push(flatObjectType.Apartment);
      }

      if (values.some(value => value === 0)) {
        flatTypes.push(flatObjectType.Room);
      }

      if (values.some(value => value === 8)) {
        flatTypes.push(flatObjectType.Part);
      }

      if (values.some(value => value === 10)) {
        flatTypes.push(flatObjectType.Bed);
      }
    }

    return flatTypes;
  }

  function getCommercialLandType(value?: TCategoryType[]): ('commercialLand' | null)[] | null {
    if (value) {
      return value.map(t => (t.startsWith('commercialLand') ? commercialObjectType.CommercialLand : null));
    }

    return null;
  }

  const officeType = getCommercialOfficeTypes(jsonQuery);
  const coworkingOfferType1 = getIn('coworking_offer_type.value', getCoworkingOfferType);
  const isOffice = officeType && officeType.length === 1 && officeType[0] === commercialObjectType.Office;
  const officeTypePrepared =
    isOffice && coworkingOfferType1 && coworkingOfferType1.length > 0 ? [commercialObjectType.Coworking] : officeType;

  if (getIn) {
    result = result
      .concat(getIn('object_type.value', getSuburbanTypes))
      .concat(officeTypePrepared)
      .concat(getIn('category.value', getCommercialLandType))
      .concat(getIn('room.value', getFlatTypes));
  }

  result = result.filter(type => type != null);

  if (result.length > 0) {
    return result;
  } else {
    if (getIn('_type', (type: string) => type.includes('commercial'))) {
      return Object.keys(commercialObjectType)
        .map((key: keyof typeof commercialObjectType) => commercialObjectType[key])
        .filter(type => type !== commercialObjectType.CommercialLand);
    }

    return defaultFilter.selectedObjectTypes;
  }
}

function withDefault<T>(defaultValue: T) {
  return function (value?: T): T {
    if (value == null) {
      return defaultValue;
    }

    return value;
  };
}

function getHighways(geo: IJsonQueryHighway[] = []): Array<number> {
  const result = defaultFilter.highways.concat();

  geo.forEach(highway => {
    if (highway.id && highway.type === 'highway') {
      result.push(highway.id);
    }
  });

  return result;
}

function getGeoTags(value?: IGeoTags): IGeoTag[] {
  let result: IGeoTag[] = [];

  if (value != null && value.geoTags != null) {
    result = result.concat(
      value.geoTags.address_tags
        .map((t: IAddressTag) => {
          const address = t.addressNodes[0] != null ? t.addressNodes[0] : ({} as IAddressNode);

          const regionTitle = t.text.split(',')[0].trim();

          const region = getRegionByTitle(regionTitle);

          return {
            id: address.id,
            text: t.text,
            name: getGeoTagName(t.text),
            kind: address.geoType.toLowerCase() as GeoTagKind,
            regionId: region != null ? region.id : 0,
            isParsed: true,
          };
        })
        .reverse(),
    ); //нужен, так как тэги приходят в обратном порядке

    result = result.concat(
      value.geoTags.metro_tags
        .map((t: IMetroTag): IGeoTag => {
          return {
            id: t.id,
            text: t.text,
            name: t.text,
            kind: 'underground' as GeoTagKind,
            regionId: 0,
            isParsed: true,
            color: t.color,
            underConstruction: t.underConstruction,
            releaseYear: t.releaseYear,
          };
        })
        .reverse(),
    ); //нужен, так как тэги приходят в обратном порядке

    result = result.concat(
      value.geoTags.district_tags
        .map((t: IDistrictTag): IGeoTag => {
          return {
            id: t.id,
            text: t.text,
            name: t.text,
            kind: 'district' as GeoTagKind,
            regionId: 0,
            isParsed: true,
          };
        })
        .reverse(),
    ); //нужен, так как тэги приходят в обратном порядке

    result = result.concat(
      value.geoTags.polygon_tags
        .map((t: IPolygonTag): IGeoTag => {
          return {
            text: t.text,
            name: t.text,
            kind: 'polygon' as GeoTagKind,
            regionId: 0,
            geo: t.geoObject.source.geometry.coordinates[0].map(coords => coords.map(String)),
          };
        })
        .reverse(),
    ); //нужен, так как тэги приходят в обратном порядке

    result = result.concat(
      value.geoTags.newobject_tags
        .map((t: INewobjectTag): IGeoTag => {
          return {
            id: parseInt(t.id, 10),
            text: t.text,
            name: t.text,
            kind: t.type as GeoTagKind,
            regionId: 0,
            isParsed: true,
          };
        })
        .reverse(),
    ); //нужен, так как тэги приходят в обратном порядке

    result = value.geoTags.railway_tags
      ? result.concat(
          value.geoTags.railway_tags
            .map((tag: IRailwayTag): IGeoTag => {
              const { id, text } = tag;

              return {
                id,
                text,
                name: text,
                kind: 'railway',
                regionId: 0,
                isParsed: true,
              };
            })
            .reverse(),
        ) //нужен, так как тэги приходят в обратном порядке
      : result;

    result = value.geoTags.village_tags
      ? result.concat(
          value.geoTags.village_tags
            .map((tag: IVillageTag): IGeoTag => {
              const { id, text } = tag;

              return {
                id,
                text,
                name: text,
                kind: 'village',
                regionId: 0,
                isParsed: true,
              };
            })
            .reverse(),
        ) //нужен, так как тэги приходят в обратном порядке
      : result;

    result = value.geoTags.businesscenter_tags
      ? result.concat(
          value.geoTags.businesscenter_tags
            .map((tag: IBCTag): IGeoTag => {
              const { id, text } = tag;

              return {
                id,
                text,
                name: text,
                kind: 'businesscenter',
                regionId: 0,
                isParsed: true,
              };
            })
            .reverse(),
        ) //нужен, так как тэги приходят в обратном порядке
      : result;

    result = value.geoTags.shoppingcenter_tags
      ? result.concat(
          value.geoTags.shoppingcenter_tags
            .map((tag: ISCTag): IGeoTag => {
              const { id, text } = tag;

              return {
                id,
                text,
                name: text,
                kind: 'shoppingcenter',
                regionId: 0,
                isParsed: true,
              };
            })
            .reverse(),
        ) //нужен, так как тэги приходят в обратном порядке
      : result;
  }

  return result;
}

function getSteadStatus(statuses: number[]): SteadStatus[] {
  if (!statuses) {
    return defaultFilter.steadStatuses;
  }

  return statuses.map(Number);
}

function getBalconiesStatus(value?: { gte?: number; lte?: number }): boolean {
  return (value && value.gte && value.gte > 0) || false;
}

function getConditions<T>(jsonQuery: IJsonQuery, associations: { path: string; type: T }[]): T[] {
  return associations
    .map(item => {
      const queryValue: boolean = get(jsonQuery, item.path);

      return queryValue && item.type;
    })
    .filter(v => v != null) as T[];
}

function getSteadConditions(jsonQuery: IJsonQuery): SteadCondition[] {
  return getConditions(jsonQuery, [
    { path: 'electricity.value', type: SteadCondition.Electricity },
    { path: 'has_drainage.value', type: SteadCondition.Sewerage },
    { path: 'has_water.value', type: SteadCondition.Water },
    { path: 'gas.value', type: SteadCondition.Gas },
  ]);
}

function getFlatConditions(jsonQuery: IJsonQuery): FlatCondition[] {
  return getConditions(jsonQuery, [
    { path: 'has_fridge.value', type: FlatCondition.Fridge },
    { path: 'has_washer.value', type: FlatCondition.Washer },
    { path: 'has_kitchen_furniture.value', type: FlatCondition.KitchenFurniture },
    { path: 'internet.value', type: FlatCondition.Internet },
    { path: 'tv.value', type: FlatCondition.Tv },
    { path: 'conditioner.value', type: FlatCondition.Conditioner },
    { path: 'dish_washer.value', type: FlatCondition.DishWasher },
    { path: 'phone.value', type: FlatCondition.Phone },
    { path: 'bath.value', type: FlatCondition.Bath },
    { path: 'shower.value', type: FlatCondition.Shower },
    { path: 'pets.value', type: FlatCondition.Pets },
    { path: 'kids.value', type: FlatCondition.Kids },
    { path: 'bathhouse.value', type: FlatCondition.Bathhouse },
    { path: 'garage.value', type: FlatCondition.Garage },
    { path: 'pool.value', type: FlatCondition.Pool },
  ]);
}

function getSubwayDistance(jsonQuery: IJsonQuery): ISubwayDistance {
  const onlyfoot = get(jsonQuery, 'only_foot.value');
  const footMin: number | null = get(jsonQuery, 'foot_min.value.lte');
  if (onlyfoot == null || footMin == null) {
    return {};
  }

  return {
    movementMethod: onlyfoot > 0 ? MovementMethod.Walk : MovementMethod.Transport,
    maxDistance: footMin,
  };
}

function getGarageSubtypes(value: number[]): string[] {
  return mapByDict<string>(value, garageSubtypeDictById);
}

function getHouseTypes(value?: number[]): string[] {
  return (value || []).map(String);
}

function getBuildingClasses(classTypes: number[]): BuildingClassType[] {
  if (!classTypes) {
    return defaultFilter.selectedBuildingClasses;
  }

  return classTypes.map(Number);
}

function getBathroomsCount(value?: number): number {
  return value ? Number(value) : 0;
}

function getBathroomType(value?: number): BathroomType {
  return value != null ? (Number(value) as BathroomType) : BathroomType.Any;
}

function getRoomType(value?: number): RoomType {
  return value != null ? (Number(value) as RoomType) : RoomType.Any;
}

function getBathroomPlacement(value?: number): BathroomPlacement {
  return value != null ? (Number(value) as BathroomPlacement) : BathroomPlacement.Any;
}

function getAgentNoFee(value?: CommissionType): boolean {
  return value === 0;
}

function getWithDeposit(value?: boolean): boolean | undefined {
  return value === false ? value : undefined;
}

function getRentRoomsCount(value?: number): number | undefined {
  return value != null ? Number(value) : undefined;
}

function getEnterTypes(types: number[]): EnterType[] {
  if (!types) {
    return defaultFilter.enterTypes;
  }

  return types.map(Number);
}

function getBuilderId(builderData: IJsonQueryBuilder[]): number | undefined {
  if (!Array.isArray(builderData)) {
    return undefined;
  }
  const builder: IJsonQueryBuilder | undefined = builderData.find((item: IJsonQueryBuilder) => item.type === 'builder');

  return builder !== undefined ? builder.id : undefined;
}

function getCoworkingOfferType(value: number[]): TCoworkingOfferType[] | null {
  if (value) {
    return value.map(n => {
      switch (n) {
        case 1:
          return coworkingOfferType.Office;
        case 2:
          return coworkingOfferType.FixedWorkplace;
        case 3:
          return coworkingOfferType.FlexibleWorkplace;
        default:
          return coworkingOfferType.Office;
      }
    });
  }

  return null;
}

function getCoworkingAmenities(value: number[]): Array<string> {
  if (value) {
    return uniq(value)
      .map(amenityIndex => amenitiesOptions[amenityIndex - 1].key)
      .filter(Boolean);
  }

  return [];
}

function getCoworkingAccess(jsonQuery: IJsonQuery): ICoworkingAccess | undefined {
  const allDay = get(jsonQuery, 'all_day_access.value') as boolean | undefined;
  const allWeek = get(jsonQuery, 'all_week_access.value') as boolean | undefined;

  if (!allDay && !allWeek) {
    return undefined;
  }

  return {
    allDay,
    allWeek,
  };
}

function getPriceType(value: TPriceType): PriceType {
  return value ? PriceType.SM : PriceType.Total;
}

export function jsonQueryToFilter(
  jsonQuery: IJsonQuery,
  geo?: IGeoTags,
  defaultRegion?: number,
  specialPromo?: ISpecialPromo,
): FilterState {
  function getIn<T1, T2>(path: string, getValue: (jqValue: T1) => T2): T2 {
    const value = get(jsonQuery, path) as T1;

    return getValue(value);
  }

  const isWithCustom = specialPromo && getIn('geo.value', getBuilderId) !== undefined;

  return {
    agentNoFee: getIn('commission_type.value', getAgentNoFee),
    apartment: jsonQuery.apartment && jsonQuery.apartment.value,
    amenities: getIn('amenity.value', getCoworkingAmenities),
    coworkingAccess: getCoworkingAccess(jsonQuery),
    windowsType: jsonQuery.windows_type ? jsonQuery.windows_type.value : undefined,
    ceilingHeight: jsonQuery.ceiling_height ? Number(jsonQuery.ceiling_height.value.gte) : undefined,
    bathroomsCount: getIn('wc.value.gte', getBathroomsCount),
    bathroomPlacement: getIn('wc_site.value', getBathroomPlacement),
    bathroomType: getIn('wc_type.value', getBathroomType),
    roomType: getIn('room_type.value', getRoomType),
    boxTypes: (get(jsonQuery, 'garage_material.value') as number[]) || [],
    builderId: getIn('geo.value', getBuilderId),
    currency: getIn('currency.value', getCurrency),
    dealType: getIn('_type', getDealType),
    demolishedInMoscowProgramm: get(jsonQuery, 'demolished_in_moscow_programm.value'),
    engineVersion: getIn('engine_version.value', withDefault(defaultFilter.engineVersion)),
    geoTags: getGeoTags(geo),
    flatConditions: getFlatConditions(jsonQuery),
    floorsNumber: getIn('floorn.value', getRange),
    hasVideo: getIn('has_video.value', Boolean),
    isByHomeOwner: getIn('is_by_homeowner.value', Boolean),
    isByCommercialOwner: getIn('is_by_commercial_owner.value', Boolean),
    isOfferCountFetching: false,
    liftsCount: getIn('lifts.value', getLifts),
    onlyFlat: jsonQuery.only_flat && jsonQuery.only_flat.value,
    price: getIn('price.value', getRange),
    priceType: getIn('price_sm.value', getPriceType),
    jsonQueryPriceType: jsonQuery.price_type ? jsonQuery.price_type.value : undefined,
    publicationPeriod: getIn('publish_period.value', getPublicationPeriod),
    roomsCount: get(jsonQuery, 'rooms_count.value') || [],
    rentRoomsCount: getIn('rooms_for_sale.value.gte', getRentRoomsCount),
    rentTime: getIn('for_day.value', withDefault(defaultFilter.rentTime)),
    region: getRegionFromGeo(getIn, geo) || defaultRegion || Regions.Moscow,
    rooms: getIn('room.value', getRooms),
    saleTypeFlat: (get(jsonQuery, 'sost_type.value') as number[]) || [],
    saleTypesNewbuilding: getIn('sost_type.value', getSaleTypesNewbuilding),
    selectedBuildingClasses: getIn('building_class_type.value', getBuildingClasses),
    selectedGarageSubtypes: getIn('garage_garage_type.value', getGarageSubtypes),
    selectedGarageTypes: (get(jsonQuery, 'garage_type.value') as number[]) || [],
    selectedHouseTypes: getIn('house_material.value', getHouseTypes),
    selectedObjectTypes: getObjectTypes(getIn, jsonQuery),
    selectedCoworkingOfferType: getIn('coworking_offer_type.value', getCoworkingOfferType),
    selectedSellers: getIn('from_developer.value', getSelectedSeller),
    selectedYears: getYears(getIn),
    sizeKitchen: getIn('kitchen.value', getRange),
    sizeLiving: getIn('living_area.value', getRange),
    sizeTotal: getIn('total_area.value', getRange),
    sizeStead: getIn('site.value', getRange),
    businessAppointments: getIn('specialty_types.value', (value: Array<number> | undefined) => value ?? []),
    steadConditions: getSteadConditions(jsonQuery),
    steadStatuses: getIn('land_status2.value', getSteadStatus),
    subwayDistance: getSubwayDistance(jsonQuery),
    withBalcony: getIn('balconies.value', getBalconiesStatus),
    withDeposit: getIn('zalog.value', getWithDeposit),
    withDiscount: getIn('promo.value', Boolean),
    withLoggia: getIn('loggia.value', Boolean),
    withCustom: getIn('is_special_promo.value', Boolean) || !!isWithCustom,
    withMortgage: getWithMortgage(getIn),
    withLayout: getWithLayout(getIn),
    withNewobject: getWithNewobject(getIn),
    withPhoto: getIn('wp.value', Boolean),
    withPik: getIn('is_pik_promo.value', Boolean),
    highways: getIn('geo.value', getHighways),
    houseMaterials: get(jsonQuery, 'house_material.value') || [],
    houseYear: getIn('house_year.value', getRange),
    withFurniture: getIn('has_furniture.value', ifNull),
    fromRepresentative: getIn('from_offrep.value', Boolean),
    withServiceLift: getIn('lift_service.value', Boolean),
    resaleOnly: getIn('building_status.value', getIsResaleOnly),
    firstFloor: getIn('is_first_floor.value', ifNull),
    notLastFloor: getIn('not_last_floor.value', ifNull),
    basementFloor: getIn('is_basement.value', Boolean),
    semibasementFloor: getIn('is_semibasement.value', Boolean),
    floor: getIn('floor.value', getRange),
    distanceToMKAD: getIn('from_mcad_km.value', getRange),
    enterTypes: getIn('input_type.value', getEnterTypes),
    accessSystem: getIn('enter.value', ifNull),
    contractTypes: (get(jsonQuery, 'contract.value') as ContractType[]) || [],
    decoration: jsonQuery.has_decoration && jsonQuery.has_decoration.value,
    multiObject: jsonQuery.multi_id && { id: jsonQuery.multi_id.value },
    kpId: get(jsonQuery, 'kp_id.value'),
    suburbanOfferFilter: get(jsonQuery, 'suburban_offer_filter.value'),
    isFromDomrf: getIn('domrf.value', Boolean),
    workplace_count: getIn('workplace_count.value', getRange),
    newobjectCompilation: {
      isSalesLeader: getIn('is_sales_leader.value', Boolean),
      isSalesStart: getIn('is_sales_leader.value', Boolean),
    },
    isUpcomingSale: getIn('is_upcoming_sale', Boolean),
    electronicTrading: getIn('electronic_trading.value', getElectronicTrading),
  } as FilterState;
}
