import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IRegionInfo } from '../api/api';
import { DeviceType } from '../redux/common/model';
import { FilterState } from '../redux/filters/model';
import { FixedComponentsState } from '../redux/fixed_components/model';
import { ReduxState, TTypedThunkDispatch } from '../redux/model';
import { createIsPopupOpened } from '../redux/popups/actions';
import { RegionsState } from '../redux/regions/model';
import { ISpecialPromo } from '../redux/special_promo/model';
import { ISpecialPromosInfoState } from '../redux/special_promos_info/reducer';
import { createDI } from '../utils/context_provider';
import { Regions } from '../utils/regions';
import { FieldType, FiltersPlace, IFieldProps, IOptionalRegionInfo } from './fields/field';

import { IStyleConfig } from '@cian/components';
import { ISpecialtySchema } from '../../shared/repositories/monolith-python/entities/schemas/SpecialtySchema';

interface IOwnProps {
  field: FieldType;
  inPopup?: boolean;
  specialPromoTitle?: string;
  specialStyles?: IStyleConfig;
  isCommercialFilter?: boolean;

  onClick?(nextState: boolean): void;
}

export interface IFieldContext {
  viewMode: DeviceType;
  forceMobile?: boolean;
  filtersPlace: FiltersPlace;
}

interface IDispatchProps {
  // any вместо typeof filtersActions (import * as filtersActions from '...'),
  // потому что import создаёт циклическую зависимость и ломает тесты
  actions: any; // tslint:disable-line:no-any
  popupActions: any; // tslint:disable-line:no-any
  mapActions: any; // tslint:disable-line:no-any
}

interface IStoreProps {
  builderId?: number;
  filter: FilterState;
  userAgent: string;
  fixedComponents: FixedComponentsState;
  regions: RegionsState;
  search: string;
  specialPromoTitle?: string;
  isNewbuildingsMapMode: boolean;
  isCustomSpecialPromo?: boolean;
  currentRegionInfo?: IRegionInfo;
  isPopupOpened: (param: string) => boolean;
  isCommercialOwnerFilersAvailable?: boolean;
  specialties: ISpecialtySchema[];
  isNewFiltersAvailable?: boolean;
}

type Props = IOwnProps & IDispatchProps & IStoreProps & IFieldContext;

class FieldPure extends React.Component<Props, object> {
  public shouldComponentUpdate(newProps: Props) {
    const { filter, field, isNewbuildingsMapMode, isCustomSpecialPromo, currentRegionInfo } = this.props;

    return (
      field.isVisible(filter, {
        isNewbuildingsMapMode: Boolean(isNewbuildingsMapMode),
        isCustomSpecialPromo,
        optionalRegionInfo: getOptionalRegionInfo(currentRegionInfo),
      }) ||
      newProps.field.isVisible(newProps.filter, {
        isNewbuildingsMapMode: Boolean(newProps.isNewbuildingsMapMode),
        isCustomSpecialPromo,
        optionalRegionInfo: getOptionalRegionInfo(currentRegionInfo),
      })
    );
  }

  public render() {
    const props = this.props;
    const {
      field,
      isNewbuildingsMapMode = false,
      isCustomSpecialPromo,
      currentRegionInfo,
    } = props;
    const fieldProps: IFieldProps = {
      actions: props.actions,
      builderId: props.builderId,
      filter: props.filter,
      filtersPlace: props.filtersPlace,
      isNewbuildingsMapMode: Boolean(props.isNewbuildingsMapMode),
      isCustomSpecialPromo,
      fixedComponents: props.fixedComponents,
      inPopup: props.inPopup,
      isCommercialFilter: props.isCommercialFilter,
      isPopupOpened: props.isPopupOpened,
      mapActions: props.mapActions,
      onClick: props.onClick,
      popupActions: props.popupActions,
      regions: props.regions,
      search: props.search,
      specialStyles: props.specialStyles,
      specialPromoTitle: props.specialPromoTitle,
      userAgent: props.userAgent,
      viewMode: props.viewMode,
      isCommercialOwnerFilersAvailable: props.isCommercialOwnerFilersAvailable,
      specialties: props.specialties,
      isNewFiltersAvailable: props.isNewFiltersAvailable,
    };

    const shouldRender = field.isVisible(props.filter, {
        isNewbuildingsMapMode,
        isCustomSpecialPromo,
        optionalRegionInfo: getOptionalRegionInfo(currentRegionInfo),
    })
      && (!field.shouldRender || field.shouldRender(fieldProps));

    return (
      shouldRender ? (
        <field.Component {...fieldProps} />
      ) : null as any // tslint:disable-line:no-any
    );
  }
}

function mapStateToProps(state: ReduxState): IStoreProps {
  const { isCommercialOwnerFilersAvailable } = state;
  const spRegion = state.regions.regionMeta.location;
  let specialPromo = state.specialPromo;
  const extractedSp = extractSpecialPromo(state.specialPromosInfo, state.filter.region, !!state.specialPromo, spRegion);
  const isExtractedSpDifferent = specialPromo && extractedSp && specialPromo.builderId !== extractedSp.builderId;

  if (isExtractedSpDifferent || !specialPromo) {
    specialPromo = extractedSp;
  }

  const currentRegionInfo = state.regions.currentRegionInfo;
  const isRegionInList = state.specialPromo
    && currentRegionInfo
    && Array.isArray(state.specialPromo.regionList)
    && state.specialPromo.regionList.includes(currentRegionInfo.id);

  // Хак для линтера
  const spInfo =  specialPromo && state.specialPromosInfo.specialPromosInfo
    .find(item => item.id === (specialPromo && specialPromo.id));

  const isCustomSpecialPromo = isPromoSpecial(specialPromo) &&
    state.filter.withNewobject &&
    specialPromo &&
    spInfo && (!spInfo.isPreview || state.specialPromosInfo.canPreviewSpecialPromos) &&
    currentRegionInfo && (
      isRegionValid(currentRegionInfo, specialPromo) || isRegionInList
    );

  return {
    userAgent: state.userAgent,
    filter: state.filter,
    fixedComponents: state.fixedComponents,
    regions: state.regions,
    search: state.routing.locationBeforeTransitions.search,
    specialPromoTitle: specialPromo && specialPromo.nameGenitive,
    isPopupOpened: createIsPopupOpened(state),
    isNewbuildingsMapMode: state.mapPage.newbuildingsPinsEnabled,
    isCustomSpecialPromo,
    builderId: specialPromo && specialPromo.builderId,
    currentRegionInfo: state.regions.currentRegionInfo,
    isCommercialOwnerFilersAvailable,
    specialties: state.specialties,
    isNewFiltersAvailable: state.isNewFiltersAvailable,
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch): IDispatchProps {
  return {
    // require, потому что import создаёт циклическую зависимость и ломает тесты
    actions: bindActionCreators(require('../redux/filters/actions'), dispatch),
    popupActions: bindActionCreators(require('../redux/popups/actions'), dispatch),
    mapActions: bindActionCreators(require('../redux/map_page/actions'), dispatch),
  };
}

export const getOptionalRegionInfo = (currentRegionInfo?: IRegionInfo): IOptionalRegionInfo => {

  return {
    isRegionInMO: Boolean(currentRegionInfo && currentRegionInfo.parentId === Regions.MoscowRegion),
    isRegionInLO: Boolean(currentRegionInfo && currentRegionInfo.parentId === Regions.LeningradRegion),
  };
};

export const isRegionValid = (currentRegionInfo: IRegionInfo, specialPromo: ISpecialPromo) => {
  const { id, parentId } = currentRegionInfo;

  return Array.isArray(specialPromo.regionList)
    ? specialPromo.regionList.includes(parentId || id)
    : specialPromo.region === (parentId || id);
};

export const isPromoSpecial = (specialPromo?: ISpecialPromo): boolean => {
  return !!specialPromo && !!specialPromo.nameGenitive;
};

export const extractSpecialPromo = (spInfo: ISpecialPromosInfoState,
                                    regionId?: number,
                                    isSpPage?: boolean,
                                    regionInfo?: IRegionInfo): ISpecialPromo | undefined => {
  const { specialPromosInfoRegion, specialPromosInfo } = spInfo;
  if (!Array.isArray(specialPromosInfo)) { return undefined; }
  const regionInfoParentId = regionInfo && (regionInfo.parentId || regionInfo.id);

  const spByRegionId = specialPromosInfo
    .find(sp => [regionInfoParentId, regionId].includes(sp.region));

  return spByRegionId || isSpPage && specialPromosInfo
    .find(sp => sp.region === specialPromosInfoRegion) as any; // tslint:disable-line:no-any
};
const fieldContextDI = createDI<IFieldContext>('fieldContext');

export const FieldContextProvider = fieldContextDI.ContextProvider;
export const injectAll = fieldContextDI.injectAll;

export const Field = connect<IStoreProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(injectAll<IStoreProps & IDispatchProps & IOwnProps>(FieldPure));
