import { RedirectFunction } from 'react-router';
import { ReduxState } from '../redux/model';

export function GKUrlCheckRedirect(userPath: string, state: ReduxState, replace: RedirectFunction) {
  const newPath = state.GKPage.GK.url;

  if (newPath !== userPath) {
    replace(newPath);
  }
}
