import * as React from 'react';

import { EMergeType, MergeNotification } from '../../components/MergeNotification';

export const AVAILABLE_MERGE_TYPES: EMergeType[] = Object.keys(EMergeType)
  .filter((key): key is keyof typeof EMergeType => isNaN(+key))
  .map(name => EMergeType[name]);

export const MergeNotificationContainer: React.FC = () => {
  const [mergeType, setMergeType] = React.useState<EMergeType | null>(null);

  const handleClose = React.useCallback(() => {
    setMergeType(null);
  }, []);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('utm_medium') && searchParams.has('utm_source')) {
      const utmMedium = searchParams.get('utm_medium');
      const utmSource = searchParams.get('utm_source');

      // tslint:disable-next-line: no-any
      if (utmMedium === '301' && AVAILABLE_MERGE_TYPES.includes(utmSource as any)) {
        setMergeType(utmSource as EMergeType);
      }
    }
  }, []);

  if (!mergeType) {
    return null;
  }

  return <MergeNotification type={mergeType} onClose={handleClose} />;
};
