import * as React from 'react';

import { TypeaheadField, VisualSize } from '@cian/components';

const styles = require('./search_field.css');
const fieldStyles = require('../../../filters/fields/field.css');

interface IState {
  value: string;
}

interface IProps {
  onChange: (value: string) => void;
  onClear: () => void;
  value: string;
}

export class SearchField extends React.Component<IProps, IState> {

  public render() {
    const { value } = this.props;

    return (
      <TypeaheadField
        inputStyle={[styles.searchFieldWithIcon]}
        placeholder="Введите регион или город"
        value={value}
        onValueChange={this.props.onChange}
        onClear={this.props.onClear}
        size={VisualSize.MEDIUM}
        popupContentValueStyle={styles.searchPopupValue}
        autoCorrect="off"
        suggestions={[]}
        containerStyle={[
          styles.searchFieldContainer,
          fieldStyles.inputContainer,
          !value && styles.searchFieldContainerWithIcon,
        ]}
      />
    );
  }
}
