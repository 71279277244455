import { IBuilderInfo, ISeller } from '../../../api/models/gk_preview';
import { CompanyRole, IBuilderContact } from '../model';

const getName = (c: { name?: string, realty_user_id?: number }) => {
  return c.name ? c.name : `ID: ${c.realty_user_id}`;
};

const getPhone = (phone?: string) => {
  if (!phone || /\D/.test(phone)) {
    return '';
  }
  return phone;
};

const getSiteBlockId = (contact: IBuilderInfo | ISeller) => {
  return contact.newbuilding_dynamic_calltracking ? contact.newbuilding_dynamic_calltracking.site_block_id : null;
}

export const parseContacts = (buildersInfo?: IBuilderInfo[], sellers?: ISeller[]): IBuilderContact[] => {
  if (!Array.isArray(buildersInfo) || !Array.isArray(sellers)) { return []; }
  const rawBuilders = buildersInfo.map(b => ({
    ...b,
    role: CompanyRole.Builder,
  }));

  const rawSellers = sellers.map(s => ({
    ...s,
    role: CompanyRole.Seller,
  }));

  return [...rawBuilders, ...rawSellers].map(contact => ({
    name: getName(contact),
    phone: getPhone(contact.phone),
    role: contact.role,
    logoUrl: contact.logo_url,
    phone_data_layer: contact.phone_data_layer,
    siteBlockId: getSiteBlockId(contact),
  })).filter(contact => contact.phone);
};
