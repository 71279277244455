import { TInfrastructureCategory } from '../../api/api';
import { INewbuildingSpecialPromo, PropertyType, TNewbuildingStatus } from '../../api/models/map';
import { TGeoPolygon } from '../../components/polygons_drawer/typings';
import { DEFAULT_SEARCH_QS, DEFAULT_SEARCH_QS_WITHOUT_GEO } from '../../components/search_button/search_button';
import { IYmapsMap, YMapsBoundedBox, YMapsCoords } from '../../utils/ymaps';
import { ResourceStatus } from '../common/model';
import { IGk } from '../offers_list/gk';
import { IKp } from '../offers_list/model';

export const DEFAULT_MAP_ZOOM = 17;

export interface INewbuildingsMapData {
  urlListingNewbuilding?: string;
}

export interface INewbuildingPolygonData {
  id: number;
  name: string;
  newbuildingStatusDisplay: string;
}

export interface INewbuildingPolygon extends INewbuildingPolygonData {
  points: TGeoPolygon;
}

export type MapPageState = {
  mapObjectsStatus: ResourceStatus;
  mapObjects: MapObject[]; // данные объектов, отображаемых на карте.
  mapGeoObjects: INewbuildingPolygon[]; // полигоны ЖК
  mapBounds: YMapsBoundedBox | null;
  mapZoom: number;
  mapCenter?: [number, number];
  legend: PropertyType[];
  offersCount: number;
  mapRequestQueryString: string; // последние параметры запроса объектов на карте.
  isDrawPending: boolean;
  infrastructurePinsEnabled: boolean;
  fetchingDataPinPopupInfo: boolean;
  filterQueryString: string;
  filterQueryStringWithoutGeo: string;
  filterQueryStringOnlyGeo?: string;
  mapObjectsType: MapObjectType, // Находятся ли на карте только кластеры или только пины.
  favoriteSearchQueries: string[];
  activePin?: IPin;
  fromDeveloperMapFilter: boolean | undefined; // state для FromDeveloperModifier, вынесен из-за хитрой логики в экшенах
  subscriptionIsPending: boolean;
  subscriptionEmail?: string;
  lastSubscribtionUrl?: string;
  openedPinGkInfo?: IGk;
  openedPinKpInfo?: IKp;
  openedPinPopupId?: string;
  openedPinType?: PinType;
  openedPinViewType?: TPinViewType;
  minBbox?: YMapsBoundedBox; // минимальная область, содержащая все объекты по текущему запросу.
  newbuildingsPinsEnabled: boolean;
  newbuildingsCount: number;
  newbuildingsMapData?: INewbuildingsMapData;
  mapContainer?: HTMLDivElement;
  mapSize?: [number, number];
  ymap?: IYmapsMap;
};

/* Тип пина: fixed - в рамках эксперимента всегда висит справа, opened - открыт пользователем при клике на пин */
export type TPinViewType = 'fixed' | 'opened';

export type PinType = 'single' | 'multi';

export const defaultMapPageState: MapPageState = {
  mapObjectsStatus: ResourceStatus.Initial,
  mapObjects: [],
  mapGeoObjects: [],
  mapObjectsType: 'cluster',
  mapBounds: null,
  mapZoom: DEFAULT_MAP_ZOOM,
  legend: [],
  offersCount: 0,
  newbuildingsCount: 0,
  mapRequestQueryString: '',
  infrastructurePinsEnabled: false,
  isDrawPending: false,
  fetchingDataPinPopupInfo: false,
  filterQueryString: DEFAULT_SEARCH_QS,
  filterQueryStringWithoutGeo: DEFAULT_SEARCH_QS_WITHOUT_GEO,
  favoriteSearchQueries: [],
  fromDeveloperMapFilter: undefined,
  subscriptionIsPending: false,
  newbuildingsPinsEnabled: false,
  ymap: undefined,
  mapContainer: undefined,
  mapSize: [0, 0],
};

export type MapObject = IPin | ICluster | IGKCluster | INewbuildingPin;
export type MapObjectType = 'pin' | 'cluster' | 'gk_cluster' | 'gk_pin' | 'infrastructure' | 'infrastructure_cluster';
export type TPinType = 'pin' | 'infrastructure' | 'infrastructure_cluster';

export interface IAbstractPin {
  id: string;
  coords: YMapsCoords;
  count: number;
  closest?: boolean;
  viewed?: boolean;
  favourite?: boolean;
  active?: boolean;
  hover?: boolean;
}

export interface IPin extends IAbstractPin {
  type: TPinType;
  offersIds: string[];
  // todo изменить это поле. не может быть нескольких ЖК у одного пина
  newObjectsIds: number[];
  propertyType: number[];
  category?: TInfrastructureCategory;
  name?: string;
  phone?: string;
  url?: string;
  social?: string;
  isNew?: boolean;
}

export interface INewbuildingPin extends IAbstractPin {
  type: 'gk_pin';
  newbuildingId: number;
  name: string;
  newbuildingStatus: TNewbuildingStatus;
  newbuildingStatusDisplay: string;
  offersCount: number;
  apartmentsCount: number;
  minPrice: string;
  maxPrice: string;
  specialPromo: INewbuildingSpecialPromo;
}

export interface IAbstractCluster {
  coords: YMapsCoords;
  count: number;
  id: string;
  closest?: boolean;
}

export interface ICluster extends IAbstractCluster {
  type: 'cluster';
}

export interface IGKCluster extends IAbstractCluster {
  type: 'gk_cluster';
}
