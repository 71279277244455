import * as React from 'react';
import { findDOMNode } from 'react-dom';

export interface IOutsideClickProps extends React.Props<OutsideClick> {
  /* tslint:disable:no-any */
  rootElement?: React.ReactElement<any>;
  /* tslint:enable:no-any */
  onOutsideClick: () => void;
}

export default class OutsideClick extends React.Component<IOutsideClickProps, {}> {
  public static defaultProps = {
    rootElement: <div />,
  };

  private clickHandler: (event: Event) => void;

  public componentDidMount() {
    const node = findDOMNode(this);

    if (!node) {
      return;
    }

    this.clickHandler = (event) => {
      const target = event.target as Element;

      if (target !== node && !node.contains(target)) {
        this.props.onOutsideClick();
      }
    };

    window.document.addEventListener('click', this.clickHandler);
  }

  public componentWillUnmount() {
    window.document.removeEventListener('click', this.clickHandler);
  }

  public render() {
    const args = [
      this.props.rootElement,
      {},
    ].concat(this.props.children || []);

    return React.cloneElement.apply(null, args);
  }
}
