import * as React from 'react';
import { IFieldContext, injectAll } from '../../field';

import { IMultiselectProps, Multiselect as StoreConnectedSelect } from '../../../ui/multiselect/multiselect';

const styles = require('./select.css');

const Select: React.SFC<IMultiselectProps & IFieldContext> = (props) => {
  return (
    <StoreConnectedSelect
      containerStyles={[
        !props.forceMobile && styles.tabletNoFlex,
      ].concat(props.containerStyles)}
      {...props}
    />
  );
};

export const SelectMobile = injectAll(Select);
