import { difference } from 'ramda';

import { TObjectType } from '../../../../mobile_website/redux/filters/model';
import { IGetBusinessPossibleAppointmentItem } from '../../../repositories/announcements/entities/AnnouncementReferences/GetBusinessPossibleAppointmentItem';
import { IReadyBusinessGroupItem } from '../../../repositories/announcements/entities/AnnouncementReferences/ReadyBusinessGroupItem';
import { IGetBusinessPossibleAppointmentsResponse } from '../../../repositories/announcements/v1/get-business-possible-appointments';
import { getIsBusinessCategory } from '../../../utils/getIsBusinessCategory';
import { IBusinessAppointmentsIdsService } from '../IBusinessAppointmentsIdsService';

export class BusinessAppointmentsIdsService implements IBusinessAppointmentsIdsService {
  private readonly ids: Array<number>;
  private readonly idsCollection: Set<number>;

  public constructor(ids: Array<number>) {
    this.ids = ids;
    this.idsCollection = new Set<number>(this.ids);
  }

  public getAvailableReadyBusinessIds(groups: IReadyBusinessGroupItem[]): Array<number> {
    return groups.reduce((accumulator, group) => {
      group.items.forEach(item => {
        if (this.idsCollection.has(item.id)) {
          accumulator.push(item.id);
        }
      });

      return accumulator;
    }, Array.of<number>());
  }

  public getAvailableRentalBusinessIds(items: IGetBusinessPossibleAppointmentItem[]): Array<number> {
    return items.reduce((accumulator, item) => {
      if (this.idsCollection.has(item.id)) {
        accumulator.push(item.id);
      }

      return accumulator;
    }, Array.of<number>());
  }

  public getAvailableBusinessAppointmentsIds(
    businessPossibleAppointments: IGetBusinessPossibleAppointmentsResponse,
    selectedObjectTypes: TObjectType[],
  ): Array<number> {
    const { rentalBusiness, readyBusiness } = businessPossibleAppointments;

    const availableIds = Array.of<number>();

    const { isRentalBusiness, isReadyBusiness } = getIsBusinessCategory(selectedObjectTypes);

    if (isReadyBusiness) {
      availableIds.push(...this.getAvailableReadyBusinessIds(readyBusiness.groups));
    }

    if (isRentalBusiness) {
      availableIds.push(...this.getAvailableRentalBusinessIds(rentalBusiness.items));
    }

    return availableIds;
  }

  public difference(ids: Array<number>): Array<number> {
    return difference(this.ids, ids);
  }
}
