import { RouterState as ReduxRouterState  } from 'react-router-redux';

export type RouteName
  = 'main'
  | 'listing'
  | 'gkCard'
  | 'offerCard'
  | 'advancedSearch'
  | 'advertPromo'
  | 'contacts'
  | 'map'
  | 'favorites'
  | '404'
  | 'sign_in_up'
  | 'resetPassword'
  | 'myOffers';

interface ILocationBeforeTransitions {
  query: { [key: string]: string };
  search: string;
  pathname: string;
  hash: string;
}

export type RouterState = ReduxRouterState & {
  isPageviewSent: boolean,
  routeName: RouteName,
  locationBeforeTransitions: ILocationBeforeTransitions;
};

export const defaultRouteState: RouterState = {
  routeName: 'main',
// tslint:disable-next-line:no-any
} as any;
