import * as React from 'react';

import { CheckboxGroup as CheckboxGroupComponent } from './CheckboxGroup';

import { amenitiesOptions, TAmenitiesOptions, amenitiesOptionsKeys } from '../../constants';

interface ICheckboxGroup {
  checkedList: string[];
  onItemChange(checked: boolean, key: string): void;
}

/** Сколько элементов не скрывается */
const ELEMENT_BEFORE_CUT = 5;

export function CheckboxGroup({ checkedList, onItemChange }: ICheckboxGroup) {
  const uncutElementsLength = checkedList.length > ELEMENT_BEFORE_CUT ? checkedList.length : ELEMENT_BEFORE_CUT;

  const [isCollapsed, setCollapsed] = React.useState(true);
  const prevState = React.useRef({
    prevStateCollapsed: isCollapsed,
    prevSortedAmenitiesPositions: [
      ...checkedList,
      ...amenitiesOptionsKeys.filter(key => !checkedList.includes(key)),
    ],
  });

  const onCollapsedButtonClick = React.useCallback(() => {
    setCollapsed(!isCollapsed);

    // запоминаем предыдущее состояние кнопки
    prevState.current.prevStateCollapsed = isCollapsed;
  }, [isCollapsed, setCollapsed]);

  // возвращаем позиции чекбоксов по ключам
  const sortedAmenitiesPositions = React.useMemo(() => {
    // если предыдущее состояние было расхлопнутом, а текущее - схлопнуто
    // то все активные чекбоксы поднимаем в топ
    if (isCollapsed && !prevState.current.prevStateCollapsed) {
      const unchecked = amenitiesOptionsKeys.filter(key => !checkedList.includes(key));

      // сбрасываем состояние на предыдущее, чтобы в схлопнутом виде
      // отмеченные чекбоксы не перестраиввались при изменении своего состояния
      prevState.current.prevStateCollapsed = !prevState.current.prevStateCollapsed;

      return [...checkedList, ...unchecked];
    }

    // в остальных случая чекбоксы сохраняют свои позиции
    // и не "всплывают"
    return prevState.current.prevSortedAmenitiesPositions;
  }, [checkedList, isCollapsed]);

  // маппим ключи и позиции с значениями
  const sortedAmenitiesOptions = React.useMemo(
    () => sortedAmenitiesPositions.map(key => ({
      ...amenitiesOptions.find(item => item.key === key),
      value: checkedList.includes(key),
    })),
    [sortedAmenitiesPositions, checkedList],
  ) as TAmenitiesOptions[];

  React.useEffect(() => {
    prevState.current.prevSortedAmenitiesPositions = [...sortedAmenitiesPositions];
  }, [sortedAmenitiesPositions]);

  const areAllElementChecked = checkedList.length === sortedAmenitiesPositions.length;

  return(
    <CheckboxGroupComponent
      isCollapsed={isCollapsed}
      onCollapsedButtonClick={onCollapsedButtonClick}
      checkedList={sortedAmenitiesOptions}
      uncutElementsLength={uncutElementsLength}
      onItemChange={onItemChange}
      areAllElementChecked={areAllElementChecked}
    />
  );
}
