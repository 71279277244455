import { ca } from '@cian/analytics';
import { ITeaserEventProps, sendTeaserEvent } from '../../components/TeaserEvent';

const getBannerLocation = (isOfferCard: boolean) => ({
  banner_location: isOfferCard ? 'after_similar' : 'before_pagination',
});

const getPage = (isOfferCard: boolean) => ({
  pageType: isOfferCard ? 'Card' : 'Listing',
  device_type: 'phone',
});

export type TBannerLocation =
 'after_pagination' |
 'infinite' |
 'before_pagination' |
 'before_pagination_step2' |
 'after_similar';

export interface ITrackRequestDemandIntoViewParams {
  userId: string | null;
  abGroup: number;
  offerId: number;
  soprApi: string;
  demandLocation: TBannerLocation;
}

export function trackBannerShow(params: ITrackRequestDemandIntoViewParams) {
  try {
    const event: ITeaserEventProps = {
      user_id: params.userId,
      pageType: 'Card',
      device_type: 'desktop',
      ab_group: params.abGroup,
      announcement_id: params.offerId,
      block_type: 'demand',
      isTeaserShow: 1,
      pagenumber: null,
      position: null,
      sc: null,
      soprApi: params.soprApi,
      demand_location: params.demandLocation,
    };
    sendTeaserEvent([event]);
  } catch (e) {}
}

/**
 *  Нажатие на кнопку "Оставить заявку" (вызов формы с заявкой).
 */
export function onShowPopupButtonClick(isOfferCard: boolean) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: 'Click',
    label: 'Banner',
    page: {
      ...getPage(isOfferCard),
      extra: {
        ...getBannerLocation(isOfferCard),
      }
    }
  });
}

/**
 * Переход на второй шаг в форме заявки (вызывается 1 раз)
 */
export function onSecondPageClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'DemandBlock',
    action: 'Click',
    label: 'before_pagination_step2',
  });
}

/**
 * Успешная отправка формы спроса!
 */
export function onSendSuccess() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'DemandBlock',
    action: 'Send',
    label: 'before_pagination',
  });
}

export const trackOpenSmsPopup = (ishomeowner: boolean, isOfferCard: boolean) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: 'Open',
    label: 'SMS',
    page: {
      ...getPage(isOfferCard),
      extra: {
        ...getBannerLocation(isOfferCard),
        selected_user_type: ishomeowner ? 'owner' : 'searcher',
      }
    }
  });
};

export const trackSmsAgain = (ishomeowner: boolean, isOfferCard: boolean) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: 'Click_again',
    label: 'SMS',
    page: {
      ...getPage(isOfferCard),
      extra: {
        ...getBannerLocation(isOfferCard),
        selected_user_type: ishomeowner ? 'owner' : 'searcher',
      },
    },
  });
};

export const trackBackToOffer = (ishomeowner: boolean, isOfferCard: boolean) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: 'Click_back_to_offer',
    label: 'sent',
    page: {
      ...getPage(isOfferCard),
      extra: {
        ...getBannerLocation(isOfferCard),
        selected_user_type: ishomeowner ? 'owner' : 'searcher',
      },
    },
  });
};

export const trackCloseBannerPopup = (ishomeowner: boolean, isOfferCard: boolean) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: 'Close',
    label: 'Banner_popup',
    page: {
      ...getPage(isOfferCard),
      extra: {
        ...getBannerLocation(isOfferCard),
        selected_user_type: ishomeowner ? 'owner' : 'searcher',
      },
    },
  });
};

export const trackCloseSmsPopup = (ishomeowner: boolean, isOfferCard: boolean) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: 'Close',
    label: 'SMS_popup',
    page: {
      ...getPage(isOfferCard),
      extra: {
        ...getBannerLocation(isOfferCard),
        selected_user_type: ishomeowner ? 'owner' : 'searcher',
      },
    },
  });
};

export const trackVerifyPhone = (ishomeowner: boolean, isOfferCard: boolean) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: 'Click_confirm',
    label: 'SMS',
    page: {
      ...getPage(isOfferCard),
      extra: {
        ...getBannerLocation(isOfferCard),
        selected_user_type: ishomeowner ? 'owner' : 'searcher',
      }
    }
  });
};

export const trackPhoneVerificationSuccess = (ishomeowner: boolean, isOfferCard: boolean) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: 'Send',
    label: 'SMS',
    page: {
      ...getPage(isOfferCard),
      extra: {
        ...getBannerLocation(isOfferCard),
        selected_user_type: ishomeowner ? 'owner' : 'searcher',
      }
    }
  });
};

export const trackSendFormSuccess = (ishomeowner: boolean, isOfferCard: boolean) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: 'Send',
    label: 'Page',
    page: {
      ...getPage(isOfferCard),
      extra: {
        ...getBannerLocation(isOfferCard),
        selected_user_type: ishomeowner ? 'owner' : 'searcher',
      }
    }
  });
};

export const trackVerificationPhoneError = (ishomeowner: boolean, isOfferCard: boolean) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: 'Wrong_code',
    label: 'SMS',
    page: {
      ...getPage(isOfferCard),
      extra: {
        ...getBannerLocation(isOfferCard),
        selected_user_type: ishomeowner ? 'owner' : 'searcher',
      }
    }
  });
};

export const trackChangedUserType = (ishomeowner: boolean) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: ishomeowner ? 'Change_to_owner' : 'Change_to_searcher',
    label: 'Banner',
    page: {
      ...getPage(true),
      extra: {
        ...getBannerLocation(true)
      },
    },
  });
};

export const trackBannerOpen = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Application_to_realtor',
    action: 'Open',
    label: 'Banner',
    page: {
      ...getPage(true),
      extra: {
        ...getBannerLocation(true)
      },
    },
  });
};
