import { first } from 'lodash';

import { IOfferInfo } from '../../../redux/offer/model';
import { IAddressInfo, IUndergroundInfo } from '../../../api/models/offer_card';

const getUndergroundName = (underground: IUndergroundInfo | undefined): string | void => {
  const name = underground && underground.name;

  return name && `м. ${name}`;
};

export const getGeoLabel = (offer: IOfferInfo) => {
  const { geo } = offer;

  if (!geo) {
    return '';
  }

  let address: IAddressInfo[] = [ ...(geo.address || []) ];
  const street = address.find(({ type }) => type === 'street');

  if (street) {
    const house = address.find(({ type }) => type === 'house');
    const city = address.find(({ locationTypeId }) => locationTypeId === 1);
    const head = [street, house, city].filter(Boolean) as IAddressInfo[];

    address = [
      ...head,
      ...address.filter(item => !head.includes(item)),
    ];
  }

  return [
    address.map(({ shortName }) => shortName).filter(Boolean).join(', '),
    getUndergroundName(first(geo.undergrounds)),
  ].filter(Boolean).join(' ');
};
