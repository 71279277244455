import { INewobjectSerialized, ISearchOffersData } from '../../api/api';
import {
  IBuilderInfo,
  IFlatType,
  IPromoInfo,
  IQueryFlatTypesStats,
  IQueryStats,
  ISeller,
  IUndergroundInfo,
} from '../../api/models/gk_preview';
import { INewBuildingFeaturesSchema, INewBuildingFeaturesV2Schema } from '../../api/models/offer_card';
import { IBuilderContact, IPriceSummary, IGKProblem } from '../../redux/gk_page/model';
import { responseToAnalyticsInfo } from '../../redux/offer/response_parser/analytics_info_parser';
import { IOfferAnalyticsData } from '../../utils/analytics';
import { IUnderground } from '../common/types';
import { parseContacts } from '../gk_page/reponse_parser/parse_contacts';
import { parsePriceRange } from '../gk_page/reponse_parser/parse_price_summary';
import { IReliableHouse } from '../../api/models/gk_card';

export interface IGk {
  id: string;
  name: string;
  images: string[];
  url: string;
  full_url_with_subdomain: string;
  buildingStatus: string;
  completionYear: number;
  price: IPriceSummary;
  query_flat_types_stats?: IQueryFlatTypesStats[];
  query_stats?: IQueryStats;
  fullAddress: string;
  undergrounds: IUnderground[];
  promoName?: string;
  contacts: IBuilderContact[];
  gaEvent: string;
  analyticsData: IOfferAnalyticsData;
  reliable_houses?: IReliableHouse[];
  isDealRequestSubstitutionPhone?: boolean;
  isFavorite?: boolean;
  isProblem?: boolean;
  isReliable?: boolean;
  isSalesLeader?: boolean;
  problems: IGKProblem[];
  analytics?: any;
  showReliableFlag?: boolean;
  has3dTour: boolean;
  hasBooking: boolean;
  promoInfoList?: IPromoInfo[];
  promo_info_list?: IPromoInfo[];
  newbuildingFeatures?: INewBuildingFeaturesSchema;
  flat_types_agent: IFlatType[];
  flat_types_developer: IFlatType[];
  builders_info: IBuilderInfo[];
  sellers: ISeller[];
  salesStartLabel?: string;
  isUpcomingSale?: boolean | null;
  newbuildingFeaturesV2?: INewBuildingFeaturesV2Schema[];
}

function parseUnderground(underground: IUndergroundInfo): IUnderground {
  return {
    type: underground.transport_id === 502 ? 'transport' : 'foot',
    lineColor: '#' + underground.line_color,
    time: underground.time,
    name: underground.name,
  };
}

export const parseGk = (data: INewobjectSerialized, analyticsData: IOfferAnalyticsData): IGk => {
  return {
    id: data.id,
    name: data.name,
    images: data.images.map(image => image.thumbnail_url),
    url: data.url,
    full_url_with_subdomain: data.full_url_with_subdomain,
    buildingStatus: data.building_status_display,
    price: parsePriceRange(data.price_for_object),
    completionYear: data.completion_year,
    query_flat_types_stats: data.query_flat_types_stats,
    query_stats: data.query_stats || extractQueryStats(data),
    fullAddress: data.full_address,
    undergrounds: data.geo ? data.geo.underground_info.map(parseUnderground) : [],
    promoName: data.promo_info && data.promo_info.name,
    contacts: parseContacts(data.builders_info, data.sellers),
    builders_info: data.builders_info,
    gaEvent: data.ga_event_action,
    analyticsData,
    showReliableFlag: Boolean(data.show_jk_reliable_flag),
    has3dTour: data.has_3d_tour,
    reliable_houses: data.reliable_houses || [],
    problems: data.problems,
    hasBooking: Boolean(data.booking && data.booking.hasBooking),
    isDealRequestSubstitutionPhone: data.is_deal_request_substitution_phone,
    isFavorite: Boolean(data.is_favorite),
    isProblem: Boolean(data.is_problem),
    isSalesLeader: Boolean(data.is_sales_leader),
    promoInfoList: data.promo_info_list,
    newbuildingFeatures: data.newbuilding_features,
    newbuildingFeaturesV2: data.newbuilding_features_v2,
    flat_types_agent: data.flat_types_agent,
    flat_types_developer: data.flat_types_developer,
    sellers: data.sellers,
    salesStartLabel: data.sales_start_label || '',
    isUpcomingSale: Boolean(data.is_upcoming_sale),
  };
};

export function parseGks(data: ISearchOffersData): IGk[] {
  const offer = data.offersSerialized[0];
  const analyticsData = offer ? responseToAnalyticsInfo(data.offersSerialized[0]) : {};
  return data.newobjectsSerialized.map(newobject => parseGk(newobject, analyticsData));
}

export const extractQueryStats = (data: INewobjectSerialized): IQueryStats | undefined => {
  const { qs, offers_count } = data;
  if (!qs || !offers_count) { return undefined; }

  return {
    max_price: 0,
    max_total_area: 0,
    min_price: 0,
    min_total_area: 0,
    offers_count,
    query_string: qs,
  };
};
