import { ISimilarOffersResponseItem } from '../../api/api';
import { IAgentInfo } from '../../components/agent_block/agent_block';
import { toPromise } from '../../utils/streams';
import { TTypedThunkAction } from '../model';
import { isEmptyArray } from '../../utils/helpers';
import { trackShowSimilarInCallRate } from '../../utils/analytics';

export type TCallRatingActions =
  ICallRatingOptionsWasSetted |
  IRecallSimilarOffersReceived;

export interface ICallRatingOptions {
  agent?: IAgentInfo;
  offerId?: number;
  offerTrackingLabel?: string;
  onCall?: () => void;
}

interface ICallRatingOptionsWasSetted {
  options: ICallRatingOptions;
  type: 'ICallRatingOptionsWasSetted';
}

interface IRecallSimilarOffersReceived {
  withoutCompetitors: boolean;
  similarOffers: ISimilarOffersResponseItem[];
  modelVersion: string | number | null;
  type: 'IRecallSimilarOffersReceived';
}

export interface ICallRatingState {
  agent?: IAgentInfo;
  offerId?: number;
  offerTrackingLabel?: string;
  onCall?: () => void;
  similarOffers: ISimilarOffersResponseItem[];
  withoutCompetitors?: boolean;
  modelVersion: string | number | null;
}

/* istanbul ignore next */
export function setCallRatingOptions(
  options: ICallRatingOptions,
): ICallRatingOptionsWasSetted {
  return {
    options,
    type: 'ICallRatingOptionsWasSetted',
  };
}

export function requestSimilarOffersForRecallPopup(id: string): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    const state = getState();
    const { searchList } = state;
    const jsonQuery = searchList.initialized
      ? searchList.jsonQuery
      : undefined;

    return toPromise(api.getSimilarOffers(id))
      .then(response => {
        const { data: { similarOffers, withoutCompetitors, modelVersion } } = response.result;
        if (isEmptyArray(similarOffers)) {
          trackShowSimilarInCallRate([], jsonQuery, withoutCompetitors);
        }
        dispatch({
          similarOffers,
          withoutCompetitors,
          modelVersion,
          type: 'IRecallSimilarOffersReceived',
        });
      })
      .catch(error => {
        logger.error(error);
      });
  };
}

/* istanbul ignore next */
const defaultState = {
  similarOffers: [],
};

/* istanbul ignore next */
export function callRatingReducer(
  state: ICallRatingState,
  action: TCallRatingActions,
): ICallRatingState {
  switch (action.type) {
    case 'ICallRatingOptionsWasSetted':
      const { options } = action;

      return {
        ...state,
        agent: options.agent,
        offerId: options.offerId,
        offerTrackingLabel: options.offerTrackingLabel,
        onCall: options.onCall,
        similarOffers: [],
      };

    case 'IRecallSimilarOffersReceived':
      return {
        ...state,
        similarOffers: action.similarOffers,
        withoutCompetitors: action.withoutCompetitors,
        modelVersion: action.modelVersion,
      };

    default:
      return state || defaultState;
  }
}
