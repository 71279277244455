import * as React from 'react';

import { Label } from '@cian/components';
import { without } from 'lodash';
import { CheckboxList, ICheckboxListItem } from '../../../components/filters_checkbox_list/filters_ckeckbox_list';
import { SteadCondition, suburbanObjectTypes } from '../../../redux/filters/model';
import { IFieldProps, IsVisibleCheck } from '../field';

const styles = require('./stead_conditions.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const conditions: ICheckboxListItem[] = [{
      id: SteadCondition.Electricity,
      label: 'Электричество',
    }, {
      id: SteadCondition.Gas,
      label: 'Газ',
    }, {
      id: SteadCondition.Sewerage,
      label: 'Канализация',
    }, {
      id: SteadCondition.Water,
      label: 'Водоснабжение',
    }];
    const selectedConditions = this.props.filter.steadConditions;

    return (
      <div data-mark="FieldSteadConditions">
        <Label label="Наличие на участке">
          <CheckboxList
            containerStyles={styles.checkboxListContainer}
            list={conditions}
            selected={selectedConditions}
            onItemChecked={(checked: boolean, id: number) => this.props.actions.toggleSteadCondition(id)}
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter =>
  filter.selectedObjectTypes.length > 0
  && without(filter.selectedObjectTypes, ...suburbanObjectTypes).length === 0;
