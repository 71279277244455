import * as React from 'react';

import { ButtonCheckbox } from '@cian/components';
import { SaleTypeFlat } from '../../../redux/filters/model';
import ButtonCheckboxContainer from '../../../ui/button_checkbox_container/button_checkbox_container';
import { IFieldProps, IsVisibleCheck, showSaleTypeFlat } from '../field';

const styles = require('./sale_type_flat.css');
const fieldStyles = require('../field.css');

const saleTypesOptions: Array<{id: number, text: string}> = [
  {
    id: SaleTypeFlat.Free,
    text: 'Свободная',
  },
  {
    id: SaleTypeFlat.Alternative,
    text: 'Альтернатива',
  },
];

export class Component extends React.Component<IFieldProps, object> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    return this.props.filter.saleTypeFlat !== nextProps.filter.saleTypeFlat;
  }

  public render() {
    const { filter: { saleTypeFlat }, actions: { setSaleTypeFlat }} = this.props;
    const buttonCheckboxStyle = [
      fieldStyles.buttonCheckbox,
      { flexGrow: 0 },
    ];

    return (
      <div className={styles.saleTypes} data-mark="FieldSaleTypeFlat">
        <ButtonCheckboxContainer>
          {saleTypesOptions.map(saleType =>
            <ButtonCheckbox
              mobile
              checked={saleTypeFlat.includes(saleType.id)}
              onChange={() => setSaleTypeFlat(saleType.id)}
              key={saleType.id}
              buttonCheckboxStyle={buttonCheckboxStyle}
              wide
            >
              {saleType.text}
            </ButtonCheckbox>,
          )}
        </ButtonCheckboxContainer>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = showSaleTypeFlat;
