import * as React from 'react';
import {AnimatedTooltip, TooltipPosition} from '../../../../ui/tooltip/tooltip';
import {IInfrastructureDropdownState} from '../InfrastructureDropdown';

const styles = require('./tip.css');

interface ITipProps {
  content?: string | React.ReactNode;
  children?: React.ReactNode;
  showTip: IInfrastructureDropdownState['showTip'];
}

export function Tip({content, children, showTip}: ITipProps) {
  return(
    <AnimatedTooltip
      position={TooltipPosition.Bottom}
      visible={showTip}
      tooltipStyle={styles['tip']}
    >
      {content || children}
    </AnimatedTooltip>
  );
}
