import * as React from 'react';

import { BuildingPresenterClassType, ECoworkingOfferType, OfferDetailCategory } from '../../../api/models/offer_card';
import { ApartmentType, AreaUnitType, IBaseInfo, IOfferInfo, IRange, ObjectType } from '../../../redux/offer/model';
import Features from '../../../ui/features/features';
import declension from '../../../utils/declension';
import logException from '../../../utils/exceptions';
import { getCommercialCoworkingTitle, ICommercialParams } from '../../../utils/getCoworkingTypeTitle';
import { getIsReadyBusinessSale } from '../../../utils/getIsReadyBusinessSale';
import { getIsWorkplaceInCoworking } from '../../../utils/getIsWorkplaceInCoworking';
import { getReadyBusinessSpecialty } from '../../../utils/getReadyBusinessSpecialty';
import { getAreaLabel } from '../../../utils/labels/getAreaLabel';
import { getAreaPartsAreaLabel } from '../../../utils/labels/getAreaPartsAreaLabel';
import { getFloor } from '../../../utils/labels/getFloor';
import { getFloorsRange } from '../../../utils/labels/getFloorsRange';
import { getFloorsRangeWithTotalFloors } from '../../../utils/labels/getFloorsRangeWithTotalFloors';

import { getCategoryTitle } from './utils/getCategoryTitle';
import { getFloorsText } from './utils/getFloorsText';

const styles = require('./offer_features.css');

export interface IOfferFeaturesState {}

export interface IOfferFeaturesProps extends IOfferFeaturesState {
  apartmentType?: ApartmentType;
  area?: number | IRange;
  areaUnitType?: AreaUnitType;
  bedroomsCount?: number;
  canParts?: boolean;
  category?: OfferDetailCategory;
  classType?: BuildingPresenterClassType;
  coworkingOfferType?: ECoworkingOfferType | null;
  decoration?: string;
  decorationStrict?: string;
  floor?: number;
  floorFrom?: number;
  floorTo?: number;
  isCutOffIconsExp?: boolean;
  nowrap?: boolean;
  offer?: IOfferInfo;
  rooms?: number;
  roomsForSale?: number;
  shareAmount?: string;
  totalFloors?: number;
  type?: ObjectType;
  workplaceCount?: number | null;
}

export type GetTypeTextArgs = Pick<
  IBaseInfo,
  | 'apartmentType'
  | 'canParts'
  | 'category'
  | 'coworkingOfferType'
  | 'rooms'
  | 'roomsForSale'
  | 'shareAmount'
  | 'type'
>;

export type TGetTypeTextParams = ICommercialParams;

export function getTypeText(data: GetTypeTextArgs, params?: TGetTypeTextParams): string {
  const { type, rooms, roomsForSale, canParts, shareAmount, category, apartmentType } = data;
  const roomsCount = rooms || roomsForSale;
  const isApartments = apartmentType === ApartmentType.Apartment;
  const getFlatText = (isShort?: boolean) => isApartments
    ? isShort ? 'апарт.' : 'Апартаменты'
    : isShort ? 'квартира' : 'Квартира';

  const flatWithRoomsText = roomsCount ? `${roomsCount}-комн. ${getFlatText(true)}` : getFlatText();

  switch (type) {
    case ObjectType.Bed:
      return 'Койко-место';
    case ObjectType.Building:
      return 'Здание';
    case ObjectType.Business:
      return 'Готовый бизнес';
    case ObjectType.CarService:
      return 'Автосервис';
    case ObjectType.CommercialLand:
      return 'Коммерческая земля';
    case ObjectType.Cottage:
      return 'Коттедж';
    case ObjectType.Flat:
      if (category === 'flatShareSale') {
        return 'Доля';
      }
      if (shareAmount) {
        return getPartOfText('квартиры', 'Квартира', canParts, shareAmount);
      }
      return flatWithRoomsText;
    case ObjectType.House:
      return getPartOfText('дома', 'Дом', canParts, shareAmount);
    case ObjectType.Room:
      return roomsForSale
        ? `${roomsForSale} ${declension(roomsForSale, ['комната', 'комнаты', 'комнат'])}`
        : '1 комната';
    case ObjectType.Studio:
      return isApartments ? `Апартаменты-студия` : 'Студия';
    case ObjectType.OpenPlan:
      return 'Своб. планировка';
    case ObjectType.DomesticService:
      return 'Бытовые услуги';
    case ObjectType.FreeAppointmentObject:
      return 'Своб. назначения';
    case ObjectType.Garage:
      return 'Гараж';
    case ObjectType.Industry:
      return 'Производство';
    case ObjectType.Land:
      return 'Земля';
    case ObjectType.NewBuilding:
      return `${flatWithRoomsText} в новостройке`;
    case ObjectType.Office:
      return getCommercialCoworkingTitle(data, 'Офис', params);
    case ObjectType.PublicCatering:
      return 'Общепит';
    case ObjectType.ShoppingArea:
      return 'Торговая площадь';
    case ObjectType.Townhouse:
      return 'Таунхаус';
    case ObjectType.Warehouse:
      return 'Склад';
    default:
      logException(new Error(`Unknown ObjectType ${type}`));
      return '';
  }
}

function getPartOfText(text: string, defaultText: string, canParts?: boolean, shareAmount?: string): string {
  if (shareAmount) {
    return `${shareAmount} ${text}`;
  } else if (canParts) {
    return `Часть ${text}`;
  }
  return defaultText;
}

export const OfferFeatures: React.FC<IOfferFeaturesProps> = props => {
  const {
    area,
    areaUnitType,
    bedroomsCount,
    coworkingOfferType,
    decorationStrict,
    floor,
    floorFrom,
    floorTo,
    nowrap,
    totalFloors,
    type,
    offer,
  } = props;

  const isReadyBusinessSale = offer && getIsReadyBusinessSale(offer);

  const values: string[] = [];

  if (!isReadyBusinessSale) {
    if (type != null) {
      values.push(getCategoryTitle(props));
    }
  } else {
    if (offer) {
      values.push(getReadyBusinessSpecialty(offer));
    }
  }

  const isWorkplaceInCoworking = getIsWorkplaceInCoworking(coworkingOfferType);
  const isAreaParts = offer && offer.areaParts && offer.areaParts.length > 0;

  if (area != null && !isWorkplaceInCoworking) {
    const label = isAreaParts
        ? getAreaPartsAreaLabel(area, areaUnitType)
        : getAreaLabel(area, areaUnitType);

    values.push(label);
  }

  if (totalFloors != null) {
    if (floorFrom && floorTo) {
    values.push(!isReadyBusinessSale
      ? getFloorsRangeWithTotalFloors('withFloor')(floorFrom, floorTo, totalFloors) 
      : getFloorsRange(floorFrom, floorTo));
    } else {
      const floorText = (() => {
        if (isReadyBusinessSale) { 
          return getFloor(floor || totalFloors) 
        };

        if (isAreaParts) {
          return `${totalFloors} ${declension(totalFloors, ['этаж', 'этажа', 'этажей'])}`
        }

        return getFloorsText(totalFloors, floor);
      })();

      values.push(floorText);
    }
  }

  if (totalFloors == null) {
    if (floorFrom && floorTo) {
      values.push(getFloorsRange(floorFrom, floorTo));
    } else if (floor != null) {
      values.push(getFloor(floor));
    }
  }

  if (bedroomsCount) {
    values.push(`${bedroomsCount} ${declension(bedroomsCount, ['спальня', 'спальни', 'спален'])}`);
  }

  if (decorationStrict != null) {
    values.push(decorationStrict);
  }

  const result = values.map(v => <span key={v}>{v}</span>);

  return result ? <Features {...{nowrap}} containerStyle={styles.miniblock}>{result}</Features> : <noscript />;
};

OfferFeatures.displayName = 'OfferFeatures';
