import { locationPayload, TUsersActions } from './actions';
import { defaultUsersState, UsersState } from './model';

type Reducer = (state: UsersState, action: TUsersActions) => UsersState;

const AuthReducer: Reducer = (state = defaultUsersState, action: TUsersActions) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      const { payload } = action;

      if (locationPayload.isNotLoggedLocationChange(payload)) {
        return {
          ...state,
          status: 'not_logged',
        };
      }

      return state;

    case 'AuthSuccess':
      return {
        ...state,
        status: 'logged',
      };

    case 'SetSuccessLogonUrl':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default AuthReducer;
