import * as React from 'react';

import ButtonRadioGroupField, {
  IButtonRadioGroupFieldValue,
} from '../../../ui/button_radio_group_field/button_radio_group_field';
import { IFieldProps, IsVisibleCheck } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const values: IButtonRadioGroupFieldValue[] = [{
      value: 1,
      label: 'Есть мебель',
    }, {
      value: 0,
      label: 'Без мебели',
    }];
    const withFurniture = this.props.filter.withFurniture;
    const currentValue = withFurniture != null ? Number(withFurniture) : -1;
    return (
      <ButtonRadioGroupField
        onValueChange={this.onValueChange}
        uncheckable
        emptyValue={-1}
        value={currentValue}
        values={values}
        buttonStyle={styles.buttonRadio}
        activeButtonStyle={styles.active}
      />
    );
  }

  private onValueChange = (value: number) => {
    const withFurniture = value < 0 ? undefined : Boolean(value);
    this.props.actions.setWithFurniture(withFurniture);
  }
}

export const isVisible: IsVisibleCheck = filter => true;
