import * as React from 'react';

import { IStyleConfig, mergeStyles } from '@cian/components';
import { connect } from 'react-redux';
import { closePopup, ClosePopupDispatchProp, PopupParamName } from '../../redux/popups/actions';
import Icon from '../icon/icon';

const styles = require('./popup_header.css');

export interface IHeadingProps {
  children?: React.ReactNode;
  headingStyle?: IStyleConfig;
}

const DefaultHeading: React.SFC<IHeadingProps> = props => {
  return <h3 {...mergeStyles(styles['heading'], props.headingStyle)}>{props.children}</h3>;
};

export interface IPopupHeaderOwnProps {
  children?: React.ReactNode;
  heading?: React.ComponentClass<IHeadingProps> | React.SFC<IHeadingProps>;
  backStyle?: IStyleConfig;
  headerStyle?: IStyleConfig;
  closeStyle?: IStyleConfig;
  headingStyle?: IStyleConfig;
  footer?: React.ReactNode;
  queryParam?: PopupParamName;
  onBackClick?: React.MouseEventHandler<HTMLElement>;
  onCloseClick?: React.MouseEventHandler<HTMLElement>;
  dataMark?: string;
}

type IPopupHeaderProps = IPopupHeaderOwnProps & Partial<ClosePopupDispatchProp>;

export class PopupHeader extends React.PureComponent<IPopupHeaderProps, {}> {
  public render() {
    const Heading = this.props.heading || DefaultHeading;
    const isRegionSelect = this.props.dataMark === 'SelectRegion';

    return (
      <header
        data-mark={`${this.props.dataMark ? this.props.dataMark + '|' : ''}header`}
        {...mergeStyles(
          styles['header'],
          this.props.headerStyle,
          isRegionSelect && styles['header--region_select'],
        )}
      >
        {this.props.onBackClick
          ? <button
            type="button"
            onClick={this.handleBackClick}
            {...mergeStyles(styles['back-button'], this.props.backStyle) }
          >
            <Icon name="arrow" />
          </button>
          : <div className={styles['fake-left']} />
        }

        <Heading headingStyle={this.props.headingStyle}>
          {this.props.children}
        </Heading>

        <button
          type="button"
          data-mark={`${this.props.dataMark ? this.props.dataMark + '|' : ''}close`}
          onClick={this.handleCloseClick}
          {...mergeStyles(styles['close-button'], this.props.closeStyle)}
        >
          <Icon name="close" styleConfig={styles['close-icon']} />
        </button>

        {this.props.footer}
      </header>
    );
  }

  private handleBackClick = (e: React.MouseEvent<HTMLElement>) => {
    const { onBackClick } = this.props;

    if (onBackClick) {
      onBackClick(e);
    }
  }

  private handleCloseClick = (e: React.MouseEvent<HTMLElement>) => {
    const { onCloseClick, queryParam } = this.props;
    if (onCloseClick) {
      onCloseClick(e);
    }
    if (queryParam && typeof this.props.closePopup === 'function') {
      this.props.closePopup(queryParam);
    }
  }
}

export const PopupHeaderContainer = connect<{}, Partial<ClosePopupDispatchProp>, IPopupHeaderOwnProps>
  (() => ({}), { closePopup })(PopupHeader);
