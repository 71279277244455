import 'rxjs/add/observable/of';
import { Observable } from 'rxjs/Observable';

import { ICacheStore } from './cache';

// tslint:disable-next-line:no-any
type Value = any;

export class MemoryStore implements ICacheStore {
  private store: { key: string, value: Value }[];
  private limit: number;

  public constructor(limit: number = Infinity) {
    this.store = [];
    this.limit = limit;
  }

  public put(key: string, value: Value): Observable<void> {
    this.store.push({ key, value });
    this.fitToLimit();
    return Observable.of(undefined);
  }

  public get<V>(key: string): Observable<V | undefined> {
    const element = this.store.find(item => item.key === key);
    return Observable.of(element && element.value);
  }

  private fitToLimit() {
    if (this.store.length > this.limit) {
      this.store = this.store.slice(-this.limit);
    }
  }
}
