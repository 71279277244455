import { PropertyType, PropertyTypeCommercial, PropertyTypeFlat, PropertyTypeSuburban } from '../../api/models/map';
import logException from '../../utils/exceptions';

const commercial: { [key in PropertyTypeCommercial]: string } = {
  Office: 'Офис',
  FloorSpace: 'Торг. площ.',
  Warehouse: 'Склад',
  FreeAppointment: 'ПСН',
  PublicCatering: 'Общепит',
  Garage: 'Гараж',
  Production: 'Производство',
  CarCare: 'Автосервис',
  Business: 'Готовый бизнес',
  Building: 'Здание',
  HouseholdServices: 'Бытовые услуги',
  LegalAddress: 'Адрес',
};

const flat: { [key in PropertyTypeFlat]: string } = {
  Bed: 'Койко-место',
  Share: 'Доля',
  Room: 'Комната',
  Studio: 'Студия',
  OneRoom: '1-комн.',
  TwoRoom: '2-комн.',
  ThreeRoom: '3-комн.',
  FourRoom: '4-комн.',
  FiveRoom: '5-комн.',
  Large: '6+ комн.',
  OpenPlan: 'Свободная планировка',
};

const suburban: { [key in PropertyTypeSuburban]: string } = {
  House: 'Дом',
  Part: 'Часть дома',
  Area: 'Участок',
  TownHouse: 'Таунхаус',
};

const namesDict = { ...commercial, ...flat, ...suburban };

export function getNameForPropertyType(type: PropertyType): string {
  if (!namesDict[type]) {
    logException(new Error(`Unexpected property type ${type}`));
  }
  return namesDict[type] || '';
}
