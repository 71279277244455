//@disclaimer
//Это копипаст кода из @cian/components с небольшими доработками.
//При переходе на @cian/components 8 перенести изменения туда.
//ВНИМАНИЕ! При внесении изменении в этот компонент, перечисляйте их ниже, чтобы потом можно было перенести.
//Доработки:
// 1) Добавлено свойство useNative в ISelectMobileProps - флаг "рисовать нативный селект"
// 2) добавлен класс 'native' к label при наличии флага `useNative`
// 3) добавлен класс 'native' к select при наличии флага `useNative`
// 4) Изменен путь импорта стилей `button.css`. Его нужно будет вернуть в исходное состояние
// 5) Если флаг useNative не рисуем кастомный label
// 6) Добавлено свойство "containerStyles", которое примешивается к классу "selectMobile"
// Также нужно перенести изменения из `select_mobile.css`. Обратить внимание на стр.82 (убрать url-loader);

import * as React from 'react';

import { IStyleConfig, mergeStyles } from '@cian/components';

require('./button.css');
const style = require('./select_mobile.css');

export interface ISelectMobileProps {
  desktop?: boolean;
  id?: string;
  name?: string;
  label?: string;
  multiple?: boolean;
  options: ISelectMobileGroupOrOption[]; // all options
  value: string[]; // ids of selected options
  onClick?: React.EventHandler<React.MouseEvent<HTMLElement>>;
  onValueChange?: (options: string[]) => void;
  useNative?: boolean;
  containerStyles?: IStyleConfig;
  labelNode?: React.ReactNode;
}

export type ISelectMobileGroupOrOption = ISelectMobileGroup | ISelectMobileOption;

export interface ISelectMobileGroup {
  label: string;
  options: ISelectMobileOption[];
}

export interface ISelectMobileOption {
  id: string;
  label: string;
}

export interface ISelectMobileState {
  selectedValues?: string;
  values?: string[] | string; // if not multiple => shoud be scalar value
}

// tslint:disable-next-line:no-any
function isSelectMobileGroup(group: ISelectMobileGroupOrOption): group is ISelectMobileGroup {
  return (group as ISelectMobileGroup).options !== undefined;
}

export default class SelectMobile extends React.Component<ISelectMobileProps, ISelectMobileState> {
  public static defaultProps = {
    label: 'Выберите',
    options: [] as ISelectMobileOption[],
  };

  public constructor(props: ISelectMobileProps) {
    super(props);

    this.state = {
      selectedValues: '',
      values: [],
    };
  }

  public componentWillMount() {
    this.updateValuesState(this.props);
  }

  public componentWillReceiveProps(nextProps: ISelectMobileProps) {
    this.updateValuesState(nextProps);
  }

  public render() {
    const { multiple, options, label, id, name, onClick, useNative, labelNode, desktop } = this.props;
    const labelText = this.state.selectedValues || label;

    const containerStyles = mergeStyles([
      style.selectMobile,
    ].concat(this.props.containerStyles));

    const labelStyles = labelNode ? {} : mergeStyles([
      style.label,
      useNative && style.native,
      desktop && style.desktop,
    ]);

    const selectStyles = mergeStyles([
      style.select,
      useNative && style.native,
      desktop && style.desktop,
    ]);

    const select = <select
      value={this.state.values}
      {...selectStyles}
      id={id}
      name={name}
      multiple={multiple}
      onChange={this.handleChange}
    >
      {/*
        hidden first optgroup for bug in iOS
        http://stackoverflow.com/questions/34660500/mobile-safari-multi-select-bug
      */}
      <optgroup disabled hidden />
      {options.map(this.renderOptions)}
    </select>;

    return (
      <div {...containerStyles}>
        <label htmlFor={id} {...labelStyles} onClick={onClick}>
          {useNative || labelNode ? null : labelText}
          {labelNode || null}
          {select}
        </label>
      </div>
    );
  }

  private renderOptions = (option: ISelectMobileGroupOrOption, index: number) => {
    if (isSelectMobileGroup(option)) {
      return (
        <optgroup className={style.optgroup} label={option.label} key={index}>
          {option.options.map(this.renderOption)}
        </optgroup>
      );
    } else {
      return this.renderOption(option);
    }
  }

  private renderOption = (option: ISelectMobileOption) => {
    return (
      <option
        key={option.id}
        value={option.id}
        className={style.option}
      >
        {option.label}
      </option>
    );
  }

  private handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const allOptions: ISelectMobileGroupOrOption[] = this.collectAllOptions(this.props);
    const target = event.target as HTMLSelectElement;
    // turns HTMLCollection to Array
    const options: HTMLOptionElement[] = Array.prototype.slice.call(target.options);
    let selectedIdsArray: string[] = [];

    let selectedValuesString = options.reduce(
      (str: string, option: HTMLOptionElement) => {
        if (option.selected) {
          str = str.concat(`${option.text}, `);

          // finds and pushes selected element in array of all options
          allOptions.forEach((allOption: ISelectMobileOption) => {
            if (allOption.id === option.value) {
              selectedIdsArray.push(option.value);
            }
          });
        }

        return str;
      }, '',
    ).slice(0, -2); // cuts ', ' from the end

    if (this.props.onValueChange) {
      this.props.onValueChange(selectedIdsArray);
    }

    this.setState({
      selectedValues: selectedValuesString,
    });
  }

  private collectAllOptions = (props: ISelectMobileProps) => {
    let allOptions: ISelectMobileGroupOrOption[] = [];

    props.options.forEach((option: ISelectMobileGroupOrOption) => {
      isSelectMobileGroup(option) ?
        allOptions = allOptions.concat(option.options) :
        allOptions.push(option);
    });

    return allOptions;
  }

  // go through all options and looking for selected
  // to write them to defaultValue atribute
  private updateValuesState = (props: ISelectMobileProps) => {
    const allOptions: ISelectMobileGroupOrOption[] = this.collectAllOptions(props);
    let values: string[] | string = props.multiple ? [] : '';
    let reduced = false; // if (!multiple && selected options > 1) then select only first

    let selectedValuesString = allOptions.reduce(
      (str: string, option: ISelectMobileOption) => {
        const isSelected = (!!props.value) && (props.value.indexOf(option.id) > -1);

        if (isSelected && !reduced) {
          str = str.concat(`${option.label}, `);

          // Хак сделан потому, что для ts метод concat строки и массива разные.
          values = (values as string).concat(option.id);

          if (!props.multiple) {
            reduced = true;
          }
        }

        return str;
      }, '',
    ).slice(0, -2); // cuts ', ' from the end

    this.setState({
      selectedValues: selectedValuesString,
      values,
    });
  }
}
