export type TFiltersPageActions =
  | { type: 'FiltersPageChangePendingState'; pending: boolean; }
  | { type: 'FiltersPageSkipNextLoading'; skip: boolean; };

export function changeFiltersPagePendingState(
  pending: boolean,
): TFiltersPageActions {
  return {
    type: 'FiltersPageChangePendingState',
    pending,
  };
}

export function changeFiltersPageNextLoading(
  skip: boolean,
): TFiltersPageActions {
  return {
    type: 'FiltersPageSkipNextLoading',
    skip,
  };
}
