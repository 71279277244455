import * as React from 'react';

import { Label, mergeStyles } from '@cian/components';
import ButtonRadioGroupField from '../../../ui/button_radio_group_field/button_radio_group_field';
import { IFieldProps, IsVisibleCheck, isFlatObjectGroup } from '../field';

const styles = require('../field.css');

export enum EWindowsType {
  yard = 0,
  street = 1,
}

export class Component extends React.Component<IFieldProps, {}> {

  public handleValueChange = (value: EWindowsType) => this.props.actions.setWindowsType(value);

  public render() {
    const { filter: { windowsType }, specialStyles } = this.props;

    return (
      <div {...mergeStyles(
          styles.field,
          specialStyles,
        )}
        data-mark="FieldWindowsType"
      >
        <Label label="Вид из окна">
          <ButtonRadioGroupField
            uncheckable
            value={windowsType}
            onValueChange={this.handleValueChange}
            values={[
              { value: EWindowsType.yard, label: 'Во двор' },
              { value: EWindowsType.street, label: 'На улицу' },
            ]}
            buttonStyle={styles.apartmentButton}
            activeButtonStyle={styles.active}
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => isFlatObjectGroup(filter);
