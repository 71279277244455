import { Label } from '@cian/components';
import * as React from 'react';

import ButtonRadioGroupField from '../../../ui/button_radio_group_field/button_radio_group_field';
import { RoomType, IFieldProps, IsVisibleCheck, isFlatObjectGroup } from '../field';

const fieldStyles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    return this.props.filter.roomType !== nextProps.filter.roomType;
  }

  public render() {
    const { filter: { roomType } } = this.props;

    return (
      <div className={fieldStyles.field} data-mark="FieldRoomType">
        <Label label="Комнаты">
          <ButtonRadioGroupField
            value={roomType}
            onValueChange={this.props.actions.setRoomType}
            uncheckable
            values={[
              { value: RoomType.Separated, label: 'Изолированные' },
              { value: RoomType.Combined, label: 'Смежные' },
            ]}
            buttonStyle={fieldStyles.bathroomButton}
            activeButtonStyle={fieldStyles.active}
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = (filter) => isFlatObjectGroup(filter)
  && ((!filter.rooms ||
      (filter.rooms.twoRooms ||
        filter.rooms.threeRooms ||
        filter.rooms.fourRooms ||
        filter.rooms.fiveRooms ||
        filter.rooms.sixRooms
      ))
    || filter.selectedObjectTypes.includes('komnata'));
