import { actionGenerator, ITypedReduxAction } from '../../utils/actionCreator';
import { TBannerLocation, trackBannerShow } from '../../utils/demand/tracking';
import { TTypedThunkAction } from '../model';

export enum EDemandActionTypes {
  HOME_OWNER_ON_CHANGE = 'demand/homeOwnerOnChange',
  ON_COMMENT_CHANGE = 'demand/onMessageChange',
}

type TDemandOnHomeownerChange = ITypedReduxAction<EDemandActionTypes.HOME_OWNER_ON_CHANGE, boolean>;
type TDemandOnMessageChange = ITypedReduxAction<EDemandActionTypes.ON_COMMENT_CHANGE, string>;

export type TDemandActions = TDemandOnHomeownerChange | TDemandOnMessageChange;

export const demandOnHomeownerChange: (payload: boolean) => TDemandOnHomeownerChange =
  actionGenerator<EDemandActionTypes.HOME_OWNER_ON_CHANGE, boolean>(EDemandActionTypes.HOME_OWNER_ON_CHANGE);

export const demandOnCommentChange =
  actionGenerator<EDemandActionTypes.ON_COMMENT_CHANGE, string>(EDemandActionTypes.ON_COMMENT_CHANGE);

export const trackInViewPort = (demandLocation: TBannerLocation): TTypedThunkAction => {
  return (dispatch, getState) => {
    const state = getState();
    const { offerPage: { offer: { id: offerId } }, soprApi, dataLayer } = state;

    if (!soprApi || !dataLayer.initialized) {
      return;
    }

    const { userId, abGroup } = dataLayer.data;

    trackBannerShow({
      abGroup: Number(abGroup),
      userId: userId || null,
      soprApi,
      offerId,
      demandLocation,
    });
  };
};
