import { mergeStyles } from '@cian/utils';
import { toPairs } from 'lodash';
import * as React from 'react';
import Counter, { CounterSize, CounterTheme } from '../../../../ui/counter/counter';
import Icon from '../../../../ui/icon/icon';
import { IInfrastructureDropdownProps, IInfrastructureDropdownState } from '../InfrastructureDropdown';

const styles = require('./button.css');

interface IButtonProps {
  opened: IInfrastructureDropdownState['opened'];
  isEnabled?: boolean;
  withLeftMargin: IInfrastructureDropdownProps['withLeftMargin'];
  infrastructure: IInfrastructureDropdownProps['infrastructure'];
  onClick(): void;
  onMouseEnter(): void;
  onMouseLeave(): void;
}

export function Button(props: IButtonProps) {
  const {withLeftMargin, onClick, onMouseEnter, opened, onMouseLeave, infrastructure, isEnabled} = props;
  const count = toPairs(infrastructure).filter(pair => !!pair[1]).length;
  const componentStyles = mergeStyles(
    styles['button'],
    withLeftMargin && styles['leftMargin'],
    !isEnabled && styles['disabled'],
    opened && styles['opened'],
  );

  return(
    <div
      {...componentStyles}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles['content']}>
        <div>Инфраструктура</div>
        {count > 0 &&
         <Counter
           children={count}
           size={CounterSize.S}
           theme={CounterTheme.DARK_GRAY}
           styleConfig={styles['counter']}
         />}
        <Icon name="arrow" styleConfig={styles['arrow']}/>
      </div>
    </div>
  );
}
