import * as React from 'react';

/** Для рендера сгруппированных списков по алфавиту. Не выносено в компонент из-за generic типизации. */
export function renderByGroups<T>(
  groups: {[key: string]: T[]},
  renderer: (group: T[]) => React.ReactNode,
  className: string,
  containerClassName?: string,
) {
  return Object.keys(groups).sort().map((groupName: string) =>
    <div key={groupName} className={containerClassName}>
      <div className={className}>{groupName}</div>
      {renderer(groups[groupName])}
    </div>,
  );
}
