import * as React from 'react';

import ButtonRadioGroupField from '../../../ui/button_radio_group_field/button_radio_group_field';
import { DealType, IFieldProps, IsVisibleCheck, rentTimeDict } from '../field';

const fieldStyles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    return this.props.filter.rentTime !== nextProps.filter.rentTime;
  }

  public render() {
    let { filter: { rentTime }, isNewFiltersAvailable, filtersPlace } = this.props;

    if (isNewFiltersAvailable && filtersPlace === 'advanced_search') {
      return null;
    }

    if (rentTime === rentTimeDict.LongGtYear || rentTime === rentTimeDict.LongLtYear) {
      rentTime = rentTimeDict.Long;
    }

    return (
      <div className={fieldStyles.field} data-mark="FieldRentTime">
        <ButtonRadioGroupField
          value={rentTime}
          onValueChange={this.props.actions.setRentTime}
          values={[
            { value: rentTimeDict.Long, label: 'Длительно' },
            { value: rentTimeDict.Short, label: 'Посуточно' },
          ]}
        />
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => {
  return filter.dealType === DealType.Rent;
};
