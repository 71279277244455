import { generateOneLinkUrl } from '@cian/appsflyer-onelink';
import * as React from 'react';

import { IStyleConfig, mergeStyles } from '@cian/components';
import Icon from '../../ui/icon/icon';
import { AnalyticsPlace, event } from '../../utils/analytics';

const styles = require('./download_app_button.css');

export enum DownloadAppButtonTheme {
  GRAY,
  LIGHT,
  BLACK,
}

const buttonStyles = {
  [DownloadAppButtonTheme.LIGHT]: styles.buttonLight,
  [DownloadAppButtonTheme.GRAY]: styles.buttonGray,
  [DownloadAppButtonTheme.BLACK]: styles.buttonBlack,
};

export type TOs = 'apple' | 'android' | undefined;

interface IAppLinks {
  [key: string]: string;
}

const appLinks: IAppLinks = {
  apple: 'https://cian.onelink.me/2426377992/5a271098',
  android: 'https://cian.onelink.me/2426377992/5a271098',
  androidSidebar: 'http://m.onelink.me/700152f8',
};

interface IDownloadAppButtonProps {
  analyticsPlace: AnalyticsPlace;
  os?: TOs;
  sidebar?: boolean;
  styleConfig?: IStyleConfig;
  theme?: DownloadAppButtonTheme;
}

const DownloadAppButton: React.SFC<IDownloadAppButtonProps> = (props: IDownloadAppButtonProps) => {
  const { analyticsPlace, os, styleConfig, theme } = props;

  if (!os) {
    return null;
  }

  const onClick = () => {
    event(analyticsPlace)('download')(os === 'apple' ? 'app_ios' : 'app_android');
  };

  const linkRaw = appLinks[(os as string) + (props.sidebar && os !== 'apple' ? 'Sidebar' : '')];
  const link = typeof window !== 'undefined' && generateOneLinkUrl(linkRaw) || linkRaw;

  return (
    <a
      href={link}
      onClick={onClick}
      {...mergeStyles(styleConfig, buttonStyles[theme as DownloadAppButtonTheme])}
    >
      <Icon styleConfig={styles[`${os}Icon`]} name={os as string} />
      <span className={styles.text}>Скачать приложение</span>
    </a>
  );
};

DownloadAppButton.defaultProps = {
  theme: DownloadAppButtonTheme.LIGHT,
} as any; // tslint:disable-line

export default DownloadAppButton;
