import { isEqual } from 'lodash';
import * as React from 'react';

import { ButtonCheckbox, Label } from '@cian/components';
import { boxTypeDict, garageTypeDict } from '../../../redux/filters/dictionaries';
import { commercialObjectType } from '../../../redux/filters/model';
import ButtonCheckboxContainer from '../../../ui/button_checkbox_container/button_checkbox_container';
import { IFieldProps, isCommercialObjectGroup, IsVisibleCheck } from '../field';

const styles = require('../field.css');

const boxTypesOptions: Array<{id: number, text: string}> = [
  {
    id: boxTypeDict.Metal,
    text: 'Металлический',
  },
  {
    id: boxTypeDict.Brick,
    text: 'Кирпичный',
  },
];

export class Component extends React.Component<IFieldProps, object> {

  public shouldComponentUpdate(nextProps: IFieldProps) {
    const { filter: { boxTypes: next } } = nextProps;
    const { filter: { boxTypes: current } } = this.props;
    return !isEqual(current, next);
  }

  public render() {
    const {
      filter: { boxTypes: selectedBoxTypes },
      actions: { toggleBoxType },
    } = this.props;

    const buttonCheckboxStyle = [
      styles.buttonCheckbox,
      { flexGrow: 0 },
    ];

    return (
      <div className={styles.field} data-mark="FieldBoxType">
        <Label label="Тип бокса">
          <ButtonCheckboxContainer>
            {boxTypesOptions.map(boxType =>
              <ButtonCheckbox
                mobile
                checked={selectedBoxTypes.includes(boxType.id)}
                onChange={() => toggleBoxType(boxType.id)}
                key={boxType.id}
                buttonCheckboxStyle={buttonCheckboxStyle}
                wide
              >
                {boxType.text}
              </ButtonCheckbox>,
            )}
          </ButtonCheckboxContainer>
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter =>
  isCommercialObjectGroup(filter)
  && isEqual(filter.selectedObjectTypes, [commercialObjectType.Garage])
  && filter.selectedGarageTypes.includes(garageTypeDict.Box);
