import { IReadyBusinessGroupItem } from '../../../../../../../../../shared/repositories/announcements/entities/AnnouncementReferences/ReadyBusinessGroupItem';
import { IBusinessAppointmentsIdsService } from '../../../../../../../../../shared/services/BusinessAppointmentsIdsService';
import { IAssociateStringWithNumberService } from '../../../../services/AssociateStringWithNumberService';

export function prepareSaveCategoryValues(
  groups: IReadyBusinessGroupItem[],
  categoryValues: Array<number>,
  values: Array<number>,
  groupTypes: Array<number>,
  associateGroupTypeWithHashService: IAssociateStringWithNumberService,
  businessAppointmentsIdsService: IBusinessAppointmentsIdsService,
): Array<number> {
  const valuesCollection = new Set<number>(values);
  const categoryValuesCollection = new Set<number>(categoryValues);

  return groupTypes.reduce((accumulator, groupType) => {
    if (categoryValuesCollection.has(groupType)) {
      for (const group of groups) {
        if (group.groupType === associateGroupTypeWithHashService.get(groupType)) {
          group.items.forEach(item => {
            if (valuesCollection.has(item.id)) {
              accumulator.push(item.id);
            }
          });
        }
      }
    } else {
      for (const group of groups) {
        if (group.groupType === associateGroupTypeWithHashService.get(groupType)) {
          group.items.forEach(item => accumulator.push(item.id));
        }
      }
    }

    return accumulator;
  }, businessAppointmentsIdsService.difference(values));
}
