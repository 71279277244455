import { FetchQueryOptions, QueryClient } from '@tanstack/react-query';

import { IGetBusinessPossibleAppointmentsResponse } from '../../../../../repositories/announcements/v1/get-business-possible-appointments';
import { ApplicationContextModel } from '../../../../../utils/applicationContext';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchGetBusinessPossibleAppointmentsService } from './internal/fetchService';

export class FetchGetBusinessPossibleAppointmentsLoadService {
  private readonly queryClient: QueryClient;

  public constructor(queryClient: QueryClient) {
    this.queryClient = queryClient;
  }

  public async prefetchQuery<TData = IGetBusinessPossibleAppointmentsResponse>(
    applicationContext: ApplicationContextModel,
    options?: FetchQueryOptions<IGetBusinessPossibleAppointmentsResponse, QueryError, TData, QueryKey>,
  ): Promise<void> {
    return this.queryClient.prefetchQuery<IGetBusinessPossibleAppointmentsResponse, QueryError, TData, QueryKey>({
      queryKey: getQueryKey(),
      queryFn: () => fetchGetBusinessPossibleAppointmentsService(applicationContext),
      ...options,
    });
  }

  public getQueryData(): IGetBusinessPossibleAppointmentsResponse | undefined {
    return this.queryClient.getQueryData<IGetBusinessPossibleAppointmentsResponse>(getQueryKey());
  }
}
