import { CheckboxField } from '@cian/components';

import cn from 'classnames';
import * as React from 'react';

import { DealType } from '../../../redux/offer/model';
import { Regions } from '../../../utils/regions';
import { IFieldProps, IsVisibleCheck, rentTimeDict } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps> {
  public shouldComponentUpdate(nextProps: IFieldProps): boolean {
    return this.props.filter.isFromDomrf !== nextProps.filter.isFromDomrf;
  }

  public render(): JSX.Element {
    const {
      filter: { isFromDomrf },
    } = this.props;
    const labelStyles = cn(styles['labelCheckbox'], isFromDomrf && styles['checked']);

    return (
      <div className={styles['checkbox']} data-mark="FieldFromDomrf">
        <CheckboxField
          mobile
          label="Аренда квартир от ДОМ.РФ"
          labelStyle={labelStyles}
          value={isFromDomrf}
          onValueChange={this.props.actions.setFromDomrf}
        />
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = (filter, optionalVisibilityParams) => {
  const { region, dealType, rentTime, selectedObjectTypes } = filter;

  return (
    dealType === DealType.Rent && // только аренда
    rentTime !== rentTimeDict.Short && // только длительный срок
    selectedObjectTypes.some(type => type === 'kvartira') && // только квартиры
    ([Regions.Moscow, Regions.MoscowRegion, Regions.MoscowAndRegion].some(r => r === region) || // Только Москва и МО
      !!optionalVisibilityParams?.optionalRegionInfo?.isRegionInMO)
  );
};
