import { IStyleConfig, mergeStyles } from '@cian/components';
import * as React from 'react';

import { footerClick, phonesCall } from '../../utils/analytics';
import { formatPhone } from '../../utils/formatters';
import { PromoType } from './index';

const styles = require('./special_footer.css');

interface ISpecialFooterProps {
  type: PromoType;
  phone?: string;
}

interface ISpecialFooterData {
  phone?: string;
  schedule?: string;
  link?: ISpecialFooterLink;
  analitycsKey?: string;
  styles?: IStyleConfig;
}

interface ISpecialFooterLink {
  url: string;
  text: string;
}

const specials: { [key: string]: ISpecialFooterData } = {
  pik: {
    schedule: 'Ежедневно с 09:00 до 21:00',
    link: { url: 'https://www.pik.ru/', text: 'www.pik.ru' },
    analitycsKey: 'PIK',
    styles: styles.pik,
  },
};

export default class SpecialFooter extends React.Component<ISpecialFooterProps, object> {
  public render() {
    const data = specials[this.props.type];
    const phoneNumber = this.props.phone || data.phone || '';

    const phone = <a
      href={`tel:${phoneNumber}`}
      className={mergeStyles(styles.button, data.styles).className}
      onClick={this.handleCall}>
        {formatPhone(phoneNumber)}
      </a>;
    const schedule = !!data.schedule && <p className={styles.schedule}>{data.schedule}</p>;
    const link = !!data.link &&
      <a href={data.link.url}
        target="_blank"
        className={mergeStyles(styles.link, data.styles).className}
        onClick={this.handleLinkClick}
      >{data.link.text}</a>;

    return (
      <footer className={styles.footer}>
        {phone}
        {schedule}
        {link}
      </footer>
    );
  }

  private handleCall = (e: React.MouseEvent<HTMLElement>) => {
    const data = specials[this.props.type];
    phonesCall(data.analitycsKey);
  }

  private handleLinkClick = (e: React.MouseEvent<HTMLElement>) => {
    const data = specials[this.props.type];
    footerClick(data.analitycsKey);
  }
}
