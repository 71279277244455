import { IStyleConfig, mergeStyles } from '@cian/utils';
import { Component } from 'react';
import * as React from 'react';
import {
  ButtonTheme,
  getStyles,
  IButtonStyleProps,
  ILinkStyleProps,
} from '../common/buttonLink';
import { VisualSize } from '../utils/style';

import { IButtonStyles } from './button.css';

export interface IButtonProps extends React.Props<Button>, IButtonStyleProps, ILinkStyleProps {
  buttonStyle?: IStyleConfig;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
  type?: string;
  styles?: IButtonStyles;
  dataMark?: string;
}

export default class Button extends Component<IButtonProps, {}> {
  public static defaultProps = {
    theme: ButtonTheme.BLUE,
    size: VisualSize.SMALL,
    type: 'button',
  };

  private styles: IButtonStyles;
  private buttonThemeStyles: { [key: number]: string; };
  private buttonSizeStyles: { [key: number]: string; };

  private button: HTMLButtonElement | null;

  public constructor(props: IButtonProps) {
    super(props);

    this.styles = require('./button.css');

    this.buttonThemeStyles = {
      [ButtonTheme.BLUE]: this.styles['blue'],
      [ButtonTheme.BLUE_SOLID]: this.styles['blue_solid'],
      [ButtonTheme.ORANGE]: this.styles['orange'],
      [ButtonTheme.ORANGE_SOLID]: this.styles['orange_solid'],
      [ButtonTheme.WHITE]: this.styles['white'],
      [ButtonTheme.TOGGLE]: this.styles['toggle'],
      [ButtonTheme.TOGGLE_ACTIVE]: this.styles['toggle_active'],
      [ButtonTheme.TOGGLE_ACTIVE_UNCKECKABLE]: this.styles['toggle_active_uncheckable'],
      [ButtonTheme.BLUELIGHT_SOLID]: this.styles['bluelight_solid'],
    };

    this.buttonSizeStyles = {
      [VisualSize.SMALL]: this.styles['small'],
      [VisualSize.MEDIUM]: this.styles['medium'],
      [VisualSize.LARGE]: this.styles['large'],
      [VisualSize.MOBILE_MEDIUM]: this.styles['mobileMedium'],
    };
  }

  public focus() {
    if (this.button) {
      this.button.focus();
    }
  }

  public blur() {
    if (this.button) {
      this.button.blur();
    }
  }

  public render() {
    return (
      <button
        {...mergeStyles(
          getStyles(this.props),
          this.props.buttonStyle,
        )}
        ref={(button) => this.button = button}
        type={this.props.type as any}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        onKeyDown={this.props.onKeyDown}
        data-mark={`${this.props.dataMark ? this.props.dataMark + '|' : ''}button`}
      >
        {this.props.children}
      </button>
    );
  }

  public getStyles({ size, theme, mobile }: IButtonStyleProps & ILinkStyleProps ) {
    let sizeStyles: {[key: string]: string} = {};
    let themeStyles: {[key: string]: string} = {};
    let extraStyles = [];

    let containerStyle = this.styles['container'];
    sizeStyles = this.buttonSizeStyles;
    themeStyles = this.buttonThemeStyles;
    if (mobile) {
      extraStyles.push(this.styles['mobile']);
    }

    return [
      containerStyle,
      ...extraStyles,
      sizeStyles[size || VisualSize.SMALL],
      themeStyles[theme || ButtonTheme.BLUE],
    ];
  }
}
