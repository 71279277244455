import * as React from 'react';

import { Label } from '@cian/components';
import ButtonRadioGroupField from '../../../ui/button_radio_group_field/button_radio_group_field';
import { BathroomPlacement, IFieldProps, IsVisibleCheck, suburbanObjectType } from '../field';

const fieldStyles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    return this.props.filter.bathroomPlacement !== nextProps.filter.bathroomPlacement;
  }

  public render() {
    const { filter: { bathroomPlacement } } = this.props;

    return (
      <div className={fieldStyles.field} data-mark="FieldBathroomPlacement">
        <Label label="Санузел">
          <ButtonRadioGroupField
            value={bathroomPlacement}
            onValueChange={this.props.actions.setBathroomPlacement}
            uncheckable
            values={[
              { value: BathroomPlacement.InHouse, label: 'В доме' },
              { value: BathroomPlacement.Outdoors, label: 'На улице' },
            ]}
            buttonStyle={fieldStyles.buttonRadio}
            activeButtonStyle={fieldStyles.active}
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => {
  const allowedTypes = [suburbanObjectType.House, suburbanObjectType.TownHouse, suburbanObjectType.PartOfHouse];

  return filter.selectedObjectTypes.length > 0 && filter.selectedObjectTypes.some(type1 => {
    return allowedTypes.some(type2 => type1 === type2);
  });
};
