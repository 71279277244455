import { CheckboxField, mergeStyles } from '@cian/components';
import * as React from 'react';

import { IFieldProps, IsVisibleCheck, isCommercialObjectGroup, isSpecialCommercialObjectGroup } from '../field';
import { DealType } from '../../../redux/offer/model';

const styles = require('../field.css');

export const CORRECT_OFFREP_REGION_IDS = [
  -1,     /* Москва и область */
  1,      /* Москва */
  -2,     /* Спб и област */
  2,      /* СПБ */
  4593,
  4588,
  4608,   /* Самарская область */
  4966,   /* Самара */
  4820,   /* Краснодар */
  4998,   /* Сочи */
  4584,   /* Краснодарский край */
  4885,   /* Нижний новгород */
  4596,   /* Нижненов. область */
  176245, /* Уфа */
  4560,   /* Башкортостан */
  4777,   /* Казань */
  4618,   /* Респблика Татарстан */
  4959,   /* Ростов */
  4606,   /* Ростовская область */
];

export class Component extends React.Component<IFieldProps, {}> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    const { isCommercialOwnerFilersAvailable, filter } = this.props;

    if (isCommercialOwnerFilersAvailable || nextProps.isCommercialOwnerFilersAvailable) {
      return filter.isByHomeOwner !== nextProps.filter.isByHomeOwner ||
        filter.isByCommercialOwner !== nextProps.filter.isByCommercialOwner;
    }

    return this.props.filter.isByHomeOwner !== nextProps.filter.isByHomeOwner;
  }

  public render() {
    const {
      filter: { isByHomeOwner, isByCommercialOwner },
      isCommercialOwnerFilersAvailable,
      actions: { setFromOwner, setFromCommercialOwner }
    } = this.props;

    const isCommercial = isCommercialObjectGroup(this.props.filter);
    const fromOwnerValue = (isCommercialOwnerFilersAvailable && isCommercial) ? !!isByCommercialOwner : isByHomeOwner;
    const handleValueChange = (isCommercialOwnerFilersAvailable && isCommercial)
      ? setFromCommercialOwner
      : setFromOwner;

    const labelStyles = mergeStyles(
      styles['labelCheckbox'],
      fromOwnerValue && styles['checked'],
    ).className;

    return (
      <div className={styles['checkbox']} data-mark="FieldFromOwner">
        <CheckboxField
          mobile
          label="От собственника"
          labelStyle={labelStyles}
          value={fromOwnerValue}
          onValueChange={handleValueChange}
        />
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => {
  const isCommercialSale = filter.dealType === DealType.Sale && isCommercialObjectGroup(filter);
  const correctRegion = CORRECT_OFFREP_REGION_IDS.includes(filter.region);

  return (!filter.withNewobject && !isCommercialSale) ||
    (isSpecialCommercialObjectGroup(filter) && correctRegion);
};
