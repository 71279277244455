import * as React from 'react';
import { connect } from 'react-redux';
import { ReduxState, TTypedThunkDispatch } from '../../redux/model';
import { isSidebarOpened, setSidebarState } from '../../redux/popups/actions';
import Sandwich from '../../ui/sandwich/sandwich';
import { mobHeaderClick } from '../../utils/analytics';

const styles = require('./sidebar_button.css');

interface ISidebarButtonProps {
  sidebarOpened: boolean;
  setSidebarState: (state: boolean) => void;
}

class SidebarButton extends React.Component<ISidebarButtonProps, object> {
  public shouldComponentUpdate(newProps: ISidebarButtonProps) {
    return newProps.sidebarOpened !== this.props.sidebarOpened;
  }

  public render() {
    return (
      <button className={styles.sandwich} onClick={this.openSidebar}>
        <Sandwich showAsClose={this.props.sidebarOpened} light={this.props.sidebarOpened} />
      </button>
    );
  }

  private openSidebar = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    mobHeaderClick('menu');
    this.props.setSidebarState(!this.props.sidebarOpened);
  }
}

export default connect<Pick<ISidebarButtonProps, 'sidebarOpened'>, Pick<ISidebarButtonProps, 'setSidebarState'>>(
  (state: ReduxState) => ({
    sidebarOpened: isSidebarOpened(state),
  }),
  (dispatch: TTypedThunkDispatch) => ({
    setSidebarState: (state) => dispatch(setSidebarState(state)),
  }),
)(SidebarButton);
