import { IOfferDetail } from '../../../api/models/offer_card';
import {
  IOfferIconField,
  IOfferIconListMap,
} from '../../../redux/offer/model';

export const allowedMap: IOfferIconListMap = new Map([
  ['childrenAllowed', {
    name: 'Можно с детьми',
    icon: 'card/ic-kids',
  }],
  ['petsAllowed', {
    name: 'Можно с животными',
    icon: 'card/ic-pets',
  }],
]);

export const responseToAllowed = (offer: IOfferDetail): IOfferIconField[] => {
  const newState: IOfferIconField[] = [];

  allowedMap.forEach((value: { name: string; icon: string; }, key: keyof IOfferDetail) => {
    if (offer[key]) {
      newState.push(value);
    }
  });

  return newState;
};
