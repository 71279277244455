import { ButtonCheckbox, Label } from '@cian/components';

import * as React from 'react';
import cn from 'classnames';

import ButtonCheckboxContainer from '../../../ui/button_checkbox_container/button_checkbox_container';
import { coworkingOfferType } from '../../../redux/filters/model';

import { IFieldProps, IsVisibleCheck } from '../field';
const styles = require('../field.css');

const fieldStyle = require('./coworking_workplaces.css');

export function Component({
  filter,
  actions: {
    setSelectedCoworkingOfferType,
    clearCoworkingWokrplaceFilter,
    setCoworkingWokrplaceFilter,
  }
}: IFieldProps) {
  const { selectedCoworkingOfferType = [] } = filter;

  const options = React.useMemo(() => ([
    {
      title: 'Закреплённое',
      key: coworkingOfferType.FixedWorkplace,
      value: selectedCoworkingOfferType.includes(coworkingOfferType.FixedWorkplace),
      onChange: () => setSelectedCoworkingOfferType(coworkingOfferType.FixedWorkplace),
      disabled: selectedCoworkingOfferType.includes(coworkingOfferType.FixedWorkplace) &&
        !selectedCoworkingOfferType.includes(coworkingOfferType.FlexibleWorkplace)
    },
    {
      title: 'Незакреплённое',
      key: coworkingOfferType.FlexibleWorkplace,
      value: selectedCoworkingOfferType.includes(coworkingOfferType.FlexibleWorkplace),
      onChange: () => setSelectedCoworkingOfferType(coworkingOfferType.FlexibleWorkplace),
      disabled: selectedCoworkingOfferType.includes(coworkingOfferType.FlexibleWorkplace) &&
        !selectedCoworkingOfferType.includes(coworkingOfferType.FixedWorkplace)
    },
  ]), [selectedCoworkingOfferType, setSelectedCoworkingOfferType]);

  React.useEffect(() => {
    const workplaceSelected = selectedCoworkingOfferType.some(selectedOfferType => [
      coworkingOfferType.FixedWorkplace as string,
      coworkingOfferType.FlexibleWorkplace as string,
    ].includes(selectedOfferType));

    if (!workplaceSelected) {
      setCoworkingWokrplaceFilter();
    }
  }, [])

  React.useEffect(() => {
    return () => {
      clearCoworkingWokrplaceFilter();
    }
  }, [])

  return (
    <div className={cn(styles['field'], fieldStyle['worplace-container'])} data-mark="FieldCoworkingWorkplace">
      <Label label="Тип рабочего места" contentWrapperStyle={fieldStyle.label}>
        <ButtonCheckboxContainer>
          {
            options.map(item => (
              <ButtonCheckbox
                key={item.key}
                id={item.key}
                name={item.key}
                mobile
                checked={item.value}
                disabled={item.disabled}
                onValueChange={item.onChange}
                buttonCheckboxStyle={[
                  styles['buttonCheckbox'],
                  fieldStyle['coworking-workplaces'],
                  item.disabled && fieldStyle['disabled'],
                ]}
              >
                {item.title}
              </ButtonCheckbox>
            ))
          }
        </ButtonCheckboxContainer>
      </Label>
    </div>
  );
}

export const isVisible: IsVisibleCheck = () => true;
