import { QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';

import { IServerSideState } from '../../services/ServerSideState';

import { ReactQueryDevtools } from './internal/components/ReactQueryDevtools';

type ServerSideStateProviderProps = {
  serverSideState: IServerSideState;
  children: React.ReactNode;
};

export const ServerSideStateProvider: React.FC<ServerSideStateProviderProps> = props => {
  const {
    serverSideState: { queryClient },
    children,
  } = props;

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      {children}
    </QueryClientProvider>
  );
};
