import {
  Authentication,
  EEventType,
  ESentEmailType,
  ESocialType,
  IAuthenticationContext,
  Provider,
} from '@cian/authentication-widget';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { AuthRouteParamsMessages, EAuthRouteParams } from '../../constants/auth_route_params';
import { closeAuthenticationPopup } from '../../redux/authenticationPopup/actions';
import { openChatsPopup } from '../../redux/chats/actions';
import { hideOfferAfterAuth as hideOfferAfterAuthAction } from '../../redux/hide_offer/actions';
import { ReduxState, TTypedThunkDispatch } from '../../redux/model';
import { authenticationSucceed } from '../../redux/users/actions';
import * as tracking from '../../utils/authentication/tracking';
import { ErrorHandlerLogger } from '../errors_handler_logger';

const styles = require('./AuthenticationPopup.css');

export interface IAuthenticationPopupStateProps {
  isOpen: boolean;
  message?: string;
  authenticationWidgetContext: IAuthenticationContext;
  disabledSocials: ESocialType[];
}

export interface IAuthenticationPopupDispatchProps {
  close(): void;
  authenticationSucceed(): Promise<void>;
  hideOfferAfterAuth(): void;
  openChats(): void;
}

export type TAuthenticationPopupProps = IAuthenticationPopupStateProps & IAuthenticationPopupDispatchProps;

export interface IAuthenticationPopupState {
  isMounted: boolean;
}

class AuthenticationPopupComponent extends React.Component<TAuthenticationPopupProps, IAuthenticationPopupState> {
  public state: IAuthenticationPopupState = {
    isMounted: false,
  };

  private wrapperRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    const { isOpen, authenticationWidgetContext } = this.props;

    this.setState({
      isMounted: true,
    });

    if (isOpen) {
      disableBodyScroll();
      tracking.trackShow();
    }
    authenticationWidgetContext.on(EEventType.AuthenticateSucceed, this.authenticationSucceed);
    authenticationWidgetContext.on(EEventType.RegisterSucceed, this.handleRegisterSucceed);
    authenticationWidgetContext.on(EEventType.EmailSentClosed, this.handleEmailSentClosed);
    authenticationWidgetContext.on(EEventType.SocialAuthenticateSucceed, this.handleSocialAuthenticateSucceed);
  }

  // eslint-disable-next-line react/no-deprecated
  public componentWillReceiveProps(nextProps: TAuthenticationPopupProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      if (nextProps.isOpen) {
        disableBodyScroll();
        tracking.trackShow();
      } else {
        enableBodyScroll();
      }
    }
  }

  public componentWillUnmount() {
    const { isOpen, authenticationWidgetContext } = this.props;
    if (isOpen) {
      enableBodyScroll();
    }
    authenticationWidgetContext.off(EEventType.AuthenticateSucceed, this.authenticationSucceed);
    authenticationWidgetContext.off(EEventType.RegisterSucceed, this.handleRegisterSucceed);
    authenticationWidgetContext.off(EEventType.EmailSentClosed, this.handleEmailSentClosed);
    authenticationWidgetContext.off(EEventType.SocialAuthenticateSucceed, this.handleSocialAuthenticateSucceed);
  }

  public render() {
    const { isOpen, message, authenticationWidgetContext } = this.props;
    const { isMounted } = this.state;

    if (!isMounted || !isOpen) {
      return null;
    }

    return ReactDOM.createPortal(
      <ErrorHandlerLogger>
        <Provider context={authenticationWidgetContext}>
          <div className={styles['container']} onClick={this.handleOutsideClick}>
            <div className={styles['wrapper']} ref={this.wrapperRef}>
              {!!message && <div className={styles['message']}>{message}</div>}
              <Authentication disabledSocials={this.props.disabledSocials} />
              <button className={styles['close']} onClick={this.handleCloseClick} />
            </div>
          </div>
        </Provider>
      </ErrorHandlerLogger>,
      document.body,
    );
  }

  private handleSocialAuthenticateSucceed = ({ isNewUser }: { isNewUser: boolean }) => {
    if (!isNewUser) {
      this.authenticationSucceed();
    }
  };

  private handleRegisterSucceed = (event?: { senderType: string }) => {
    if (event?.senderType) {
      this.authenticationSucceed();
    }
  };

  private handleEmailSentClosed = ({ type }: { type: ESentEmailType }) => {
    if (type === ESentEmailType.Registration) {
      this.authenticationSucceed();
    }
  };

  private handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target as Node)) {
      this.props.close();
      tracking.trackClose(this.props.authenticationWidgetContext.state.view.active);
    }
  };

  private handleCloseClick = () => {
    this.props.close();
    tracking.trackClose(this.props.authenticationWidgetContext.state.view.active);
  };

  private authenticationSucceed = () => {
    this.props.authenticationSucceed().then(() => {
      const isHideMessageType = this.props.message === AuthRouteParamsMessages[EAuthRouteParams.Hide];
      const isChatsMessageType = this.props.message === AuthRouteParamsMessages[EAuthRouteParams.Chats];

      if (this.props.message && isHideMessageType) {
        this.props.hideOfferAfterAuth();
      }
      if (this.props.message && isChatsMessageType) {
        this.props.openChats();
      }
    });
    this.props.close();
  };
}

function disableBodyScroll() {
  document.body.style.overflow = 'hidden';
}

function enableBodyScroll() {
  document.body.style.overflow = 'visible';
}

function mapStateToProps(state: ReduxState): IAuthenticationPopupStateProps {
  return {
    isOpen: state.authenticationPopup.isOpen,
    message: state.authenticationPopup.message,
    authenticationWidgetContext: state.authenticationWidgetContext,
    disabledSocials: state.authentication.disabledSocials,
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch): IAuthenticationPopupDispatchProps {
  return {
    close: () => dispatch(closeAuthenticationPopup()),
    authenticationSucceed: () => dispatch(authenticationSucceed({ noRedirect: true })),
    hideOfferAfterAuth: () => dispatch(hideOfferAfterAuthAction()),
    openChats: () => dispatch(openChatsPopup()),
  };
}

export const AuthenticationPopup = connect(mapStateToProps, mapDispatchToProps)(AuthenticationPopupComponent);
