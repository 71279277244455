import { TPopupsActions } from './actions';
import { defaultPopupsState, PopupsState } from './model';

export default (state: PopupsState = defaultPopupsState, action: TPopupsActions): PopupsState => {
  switch (action.type) {
    case 'SetSidebarState':
      return {
        ...state,
        sidebarIsOpened: action.payload.sidebarIsOpened,
      };
    case 'SetActivePopup':
      return {
        ...state,
        activePopup: action.payload.activePopup,
      };
    case 'ClearActivePopup':
      return {
        ...state,
        activePopup: 'none',
      };
    default:
      return state;
  }
};
