import { getAgentListsMapper } from '../../../../shared/utils/agentListsMapper';
import { getIsElectronicTradingEnabled } from '../../../../shared/utils/isElectronicTradingEnabled';
import { IOfferDetail } from '../../../api/models/offer_card';
import { EColorLabel, IPhotoLabelSchema } from '../model';

export const responseToPhotoLabels = (offer: IOfferDetail): IPhotoLabelSchema[] => {
  const agentListsMap = getAgentListsMapper();

  const { user } = offer;
  if (!(user?.agentLists && user?.agentLists.length) || !agentListsMap) {
    return [];
  }

  const photoLabels: IPhotoLabelSchema[] = [];

  /**
   * @todo Удалить код с RS
   * @description Данный функционал появился в задаче CD-197673, будет удалён в задаче CD-197395
   */
  const isElectronicTradingEnabled = getIsElectronicTradingEnabled();

  if (isElectronicTradingEnabled) {
    user.agentLists.forEach(label => {
      if (typeof agentListsMap?.[label] !== 'undefined') {
        photoLabels.push({
          color: EColorLabel.White,
          name: agentListsMap?.[label],
        });
      }
    });
  }

  return photoLabels;
};
