import { ECoworkingOfferType } from '../../api/models/offer_card';
import { getFixedWorkplaceCategoryTitle } from './getFixedWorkplaceCategoryTitle';
import { getFlexibleWorkplaceCategoryTitle } from './getFlexibleWorkplaceCategoryTitle';

export function getCoworkingCategoryTitle(coworkingOfferType: ECoworkingOfferType, workplaceCount: number): string {
  switch (coworkingOfferType) {
    case ECoworkingOfferType.FixedWorkplace:
      return getFixedWorkplaceCategoryTitle(workplaceCount);

    case ECoworkingOfferType.FlexibleWorkplace:
      return getFlexibleWorkplaceCategoryTitle(workplaceCount);

    case ECoworkingOfferType.Office:
      return `Отдельный офис на ${workplaceCount} чел.`;

    case ECoworkingOfferType.MeetingRoom:
      return `Переговорная комната на ${workplaceCount} чел.`;

    case ECoworkingOfferType.ConferenceHall:
      return `Конференц-зал на ${workplaceCount} чел.`;

    default:
      throw new Error(`В утилите ${getCoworkingCategoryTitle.name} кейс ${coworkingOfferType} не определён`);
  }
}
