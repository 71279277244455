import { IStyleConfig } from '@cian/components';

import * as React from 'react';

import Icon from '../icon/icon';

import MenuItem from './menu_item';

const helperStyles = require('../../styles/helpers.css');
const styles = require('./menu.css');

export interface IMenuLink {
  id: number;
  content: React.ReactNode;
  href?: string;
  to?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  icon?: string;
  hideOnTablet?: boolean;
  hide?: boolean;
  styleConfig?: IStyleConfig;
  dataTestid?: string;
}

interface IMenuProps {
  children?: React.ReactNode;
  links: Array<IMenuLink>;
  dataMark?: string;
}

const Menu: React.SFC<IMenuProps> = ({ links, dataMark }: IMenuProps) => {
  const menuLinks = links.map(link => (
    <MenuItem
      href={link.href}
      id={link.id}
      key={link.id}
      styleConfig={[link.hideOnTablet && helperStyles.onlyPhone, link.hide && helperStyles.hidden, link.styleConfig]}
      to={link.to}
      onClick={link.onClick}
    >
      {link.icon && <Icon name={link.icon} styleConfig={styles.itemIcon} />}
      <div data-testid={link.dataTestid}>{link.content}</div>
    </MenuItem>
  ));

  return (
    <nav className={styles.menu} data-mark={`Menu${dataMark || ''}`}>
      {menuLinks}
    </nav>
  );
};

// eslint-disable-next-line import/no-default-export
export default Menu;
