import * as React from 'react';

import { mergeStyles } from '@cian/components';
import { IMenuLink } from './menu';

const styles = require('./menu.css');
const helperStyles = require('../../styles/helpers.css');

interface IMenuProps {
  links?: Array<IMenuLink>;
}

const MenuDivider: React.SFC<IMenuProps> = ({ links = [] }: IMenuProps) => {
  const isHidden = links.length && !links.some(({ hide }) => !hide);
  const isHideOnTablet = links.length && !links.some(({ hideOnTablet }) => !hideOnTablet);
  const { className } = mergeStyles(styles.divider, isHideOnTablet && helperStyles.onlyPhone);

  return !isHidden ? <hr className={className} /> : null;
};

export default MenuDivider;
