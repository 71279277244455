import { IAuthenticationContext } from '@cian/authentication-widget';
import { ILogger } from '@cian/logger';

import { QueryClient } from '@tanstack/react-query';
import { combineReducers } from 'redux';

import { ISpecialtySchema } from '../../shared/repositories/monolith-python/entities/schemas/SpecialtySchema';
import { DEFAULT_FLAT_TOUR_BOOKING, INewbuildingFlatTourBooking } from '../../shared/types/newbuildingFlatTourBooking';
import { DEFAULT_PIK_REGIONS } from '../constants/promo';

import experiments from './ab_tests/reducer';
import { agentContactsPopupReducer } from './agentContactsPopup/reducer';
import { authPageReducer } from './auth_page/reducer';
import { authenticationReducer } from './authentication/reducer';
import { authenticationPopupReducer } from './authenticationPopup/reducer';
import { autoOfferChangeHintReducer } from './autoOfferChangeHint/reducer';
import { chatsReducer } from './chats/reducer';
import { commonReducer as common } from './common/reducer';
import { complaintFeedbackReducer } from './complaint/reducer';
import { reducer as contacts } from './contacts';
import { reducer as dataLayer } from './data_layer';
import { demandReducer } from './demand/demandReducer';
import favorites from './favorites/reducer';
import { favoritesCountByOfferReducer } from './favoritesCountByOffer';
import { queryStringReducer } from './filters/queryStringReducer';
import filter from './filters/reducer';
import { filtersPageReducer } from './filters_page/reducer';
import fixedComponents from './fixed_components/reducer';
import fullscreenAppBanner from './fullscreenAppBanner/reducer';
import GKPage from './gk_page/reducer';
import header from './header/reducer';
import hideOffer from './hide_offer/reducer';
import { LocationSwitcherReducer } from './location_switcher/location_switcher_reducer';
import geoSeoLinks from './main_page/geo_seo_links/reducer';
import promoBlocks from './main_page/promo_blocks/reducer';
import seoMain from './main_page/seo_main/reducer';
import mapPage from './map_page/reducer';
import seoMap from './map_page/seo_map/reducer';
import { INewMobileWebsiteInfo, ReduxState, TReduxActions } from './model';
import { newbuildingChatReducer } from './newbuildingChat/reducer';
import { offerReducer } from './offer/reducer';
import searchList from './offers_list/reducer';
import { callRatingReducer } from './popups/call_rating';
import popups from './popups/reducer';
import { pushSubscriptionReducer } from './push_subscription/reducer';
import { quickLinksReducer } from './quick_links/reducer';
import regionsReducer from './regions/reducer';
import { reducer as resetPassword } from './reset_password';
import routing from './routing/reducer';
import SaveFilterPopupState from './save_filter_popup';
import { scrollPositionReducer } from './scroll_position';
import { specialPromoReducer } from './special_promo/reducer';
import { specialPromosInfoReducer } from './special_promos_info/reducer';
import transitionMetrics from './transition_metrics/reducer';
import { upcomingSale } from './upcomingSale/reducer';
import auth from './users/reducer';

const reducer = combineReducers<ReduxState, TReduxActions>({
  GKPage,
  auth,
  authenticationPopup: authenticationPopupReducer,
  authentication: authenticationReducer,
  authPage: authPageReducer,
  autoOfferChangeHint: autoOfferChangeHintReducer,
  callRating: callRatingReducer,
  chats: chatsReducer,
  common,
  contacts,
  cookie: (state: string = '') => state,
  countrysideBuilderLandingData: (state = null) => state,
  pikPromoRegions: (state = DEFAULT_PIK_REGIONS) => state,
  showCountrysideBuilderBadge: (state = false) => state,
  searchOfferSlowdownTimeout: (state = 0) => state,
  dataLayer,
  experiments,
  favorites,
  hideOffer,
  filter,
  filtersPage: filtersPageReducer,
  fixedComponents,
  geoSeoLinks,
  header,
  SaveFilterPopupState,
  lkUrl: (state: string = '') => state,
  mapPage,
  offerPage: offerReducer,
  popups,
  profileSessionKey: (state: string = '') => state,
  promoBlocks,
  quickLinks: quickLinksReducer,
  regions: regionsReducer,
  resetPassword,
  routing,
  scrollPosition: scrollPositionReducer,
  searchList,
  seoMain,
  seoMap,
  transitionMetrics,
  userAgent: (state: string = '') => state,
  year: (state: number = new Date().getFullYear()) => state,
  specialPromo: specialPromoReducer as any,
  specialPromosInfo: specialPromosInfoReducer,
  logger: ((state: ILogger | null = null) => state) as any,
  pushSubscription: pushSubscriptionReducer,
  complaintFeedbackFrom: complaintFeedbackReducer,
  authenticationWidgetContext: (state: IAuthenticationContext) => state || null,
  fullscreenAppBanner,
  // @ts-ignore
  isNewbuildingSubdomain: (state: boolean = false) => state,
  demand: demandReducer as any,
  locationSwitcher: LocationSwitcherReducer,
  soprApi: (state: string = '') => state,
  favoritesCountByOffer: favoritesCountByOfferReducer,
  nextMobileSearchAvailableTypes: (state: string = '') => state,
  isNewLogo: (state: boolean = false) => state,
  isNewYearLogo: (state: boolean = false) => state,
  newMobileWebsiteInfo: (state: INewMobileWebsiteInfo = { userInExperiment: false, experimentEnabled: false }) => state,
  agentContactsPopup: agentContactsPopupReducer,
  flatTourBookingBanner: (state: INewbuildingFlatTourBooking = DEFAULT_FLAT_TOUR_BOOKING) => state,
  headerApiUrl: (state: string = '') => state,
  newbuildingChat: newbuildingChatReducer as any,
  isE2eDealVisible: (state: boolean = false) => state,
  /**
   * @todo Удалить строку с RS
   * @description Данный функционал появился в задаче CD-98438,
   * будет удалён в задаче CD-103422
   */
  isCommercialOwnerFilersAvailable: /* istanbul ignore next */ (state = false) => state,
  captchaSpamSiteKey: (state = '') => state,
  queryString: queryStringReducer,
  upcomingSale,
  specialties: (state: ISpecialtySchema[] = Array.of<ISpecialtySchema>()) => state,
  isNewFiltersAvailable: (state = false) => state,
  queryClient: (queryClient = new QueryClient()) => queryClient,
});

// eslint-disable-next-line import/no-default-export
export default reducer;
