import * as React from 'react';
import { ReduxState } from '../../../redux/model';
import OutsideClick from '../../../ui/cian_components/outside_click/outside_click';
import { Body } from './Body';
import { Button } from './Button';
import { Tip } from './Tip';
import { trackInfrastructureButtonClick } from './trackings';

export interface IInfrastructureDropdownStateProps {
  mapZoom: ReduxState['mapPage']['mapZoom'];
  infrastructure: ReduxState['filter']['infrastructure'];
}

export interface IInfrastructureDropdownOwnProps {
  withLeftMargin?: boolean;
}

export type IInfrastructureDropdownProps = IInfrastructureDropdownOwnProps & IInfrastructureDropdownStateProps;

export interface IInfrastructureDropdownState {
  opened: boolean;
  showTip: boolean;
  isEnabled: boolean;
}

export class InfrastructureDropdown extends React.PureComponent<IInfrastructureDropdownProps,
                                                                IInfrastructureDropdownState> {
  public state = {
    opened: false,
    showTip: false,
    isEnabled: true,
  };

  public componentWillReceiveProps({mapZoom: nextMapZoom}: IInfrastructureDropdownProps) {
    if (this.props.mapZoom !== nextMapZoom) {
      this.enableDropdownClick(nextMapZoom);
      if (!this.isAppropriateScale(nextMapZoom)) { this.setBody(false)(); }
    }
  }

  public componentDidMount() {
    this.enableDropdownClick(this.props.mapZoom);
  }

  public render() {
    const {withLeftMargin, mapZoom, infrastructure} = this.props;
    const {opened, showTip, isEnabled} = this.state;
    const tipText = mapZoom > 17 ? 'уменьшите' : 'увеличьте';

    return (
      <div>
        <OutsideClick onOutsideClick={this.setBody(false)}>
          <Button
            opened={opened}
            isEnabled={isEnabled}
            onClick={this.handleClick}
            infrastructure={infrastructure}
            withLeftMargin={withLeftMargin}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          />
          <Body opened={opened} />
        </OutsideClick>

        <Tip
          showTip={showTip}
          content={`Для отображения инфраструктуры ${tipText} масштаб карты`}
        />
      </div>
    );
  }

  private handleClick = () => {
    const {isEnabled, opened} = this.state;
    if (isEnabled) {
      this.setBody(!opened)();
      /**
       * Отправляем аналитику клика по кнопке только если
       * кнопка доступна для кликов (zoom больше 15)
       */
      trackInfrastructureButtonClick();
    }
  }

  private handleMouseEnter = () => {
    if (!this.state.isEnabled) { this.showTip(); }
  }

  private handleMouseLeave = () => {
    if (!this.state.isEnabled) { this.hideTip(); }
  }

  private enableDropdownClick(mapZoom: IInfrastructureDropdownProps['mapZoom']) {
    this.isAppropriateScale(mapZoom) ? this.enableDropdown() : this.disableDropdown();
  }

  private setBody = (opened: boolean) => () => {
    this.setState({opened});
  }

  private isAppropriateScale(mapZoom: ReduxState['mapPage']['mapZoom']): boolean {
    return  mapZoom >= 15;
  }

  private enableDropdown = () => {
    this.setState({
      isEnabled: true,
      showTip: false,
    });
  }

  private disableDropdown = () => {
    this.setState({isEnabled: false});
  }

  private showTip = () => {
    this.setState({showTip: true});
  }

  private hideTip = () => {
    this.setState({showTip: false});
  }
}
