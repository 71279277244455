import * as React from 'react';

import { ButtonCheckbox, Label } from '@cian/components';
import ButtonCheckboxContainer from '../../../ui/button_checkbox_container/button_checkbox_container';
import { IFieldProps, isFlatObjectGroup, IsVisibleCheck } from '../field';

const styles = require('./balcony.css');
const fieldStyles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const { withBalcony, withLoggia } = this.props.filter;
    const buttonCheckboxStyle = [
      fieldStyles.buttonCheckbox,
    ];

    return (
      <div className={styles.field} data-mark="FieldBalcony">
        <Label label="Балкон">
          <div className={styles.balcony}>
            <ButtonCheckboxContainer>
              <ButtonCheckbox
                mobile
                checked={withBalcony}
                onValueChange={this.props.actions.setWithBalcony}
                id="balcony"
                name="balcony"
                wide
                buttonCheckboxStyle={buttonCheckboxStyle}
              >
                Есть балкон
              </ButtonCheckbox>
              <ButtonCheckbox
                mobile
                checked={withLoggia}
                onValueChange={this.props.actions.setWithLoggia}
                id="loggia"
                name="loggia"
                wide
                buttonCheckboxStyle={buttonCheckboxStyle}
              >
                Есть лоджия
              </ButtonCheckbox>
            </ButtonCheckboxContainer>
          </div>
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => isFlatObjectGroup(filter)
  && !filter.withNewobject;
