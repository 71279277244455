/* eslint-disable max-lines */

import { difference, values } from 'lodash';

import { GeoTagKind, IRegionInfo, SectionType } from '../../api/api';
import { Regions } from '../../utils/regions';

import { boxTypeDict, rentTimeDict } from './dictionaries';

// types

export enum DealType {
  Rent,
  Sale,
}

export enum ESuburbanOfferFilter {
  Any = 0,
  Builder = 1,
  Individual = 2,
}

/**
 * @enum
 * @description Тип цены
 */
export enum EJsonQueryPriceType {
  /**
   * @internal
   * @type {EJsonQueryPriceType.All}
   * @description Общий тип прайса, не реализован на беке и не должен быть использован для отправки на бек
   */
  All = 'all',
  /**
   * @type {EJsonQueryPriceType.Workplace}
   * @description Цена за рабочее место
   */
  Workplace = 'workplace',
}

export type RentTime = '1' | '!1' | '0' | '2';

export type RangeType = 'min' | 'max';

export enum SaleTypeFlat {
  Free = 1,
  Alternative = 2,
}

export enum SaleTypeNewbuilding {
  FZ214 = 3,
  cessionOfRights = 4,
  ZhSK = 5,
}

export enum BathroomType {
  Any = 0,
  Combined = 1,
  Separated = 2,
}

export enum RoomType {
  Any = 0,
  Separated = 1,
  Combined = 2,
}

export enum BathroomPlacement {
  Any = 0,
  InHouse = 1,
  Outdoors = 2,
}

export enum BuildingStatus {
  Any,
  Resale,
  New,
}

export enum CommissionType {
  Zero = 0,
  Exclusive = 1,
}

export enum Currency {
  USD = 1,
  RUB = 2,
  EUR = 3,
}

export enum PublicationPeriod {
  Unexpected = 0,
  Today = -2,
  Minutes5 = 300,
  Minutes10 = 600,
  Minutes15 = 900,
  Minutes20 = 1200,
  Minutes30 = 1800,
  Hours1 = 3600,
  Hours2 = 7200,
  Hours3 = 10800,
  Hours4 = 14400,
  Hours5 = 18000,
  Hours6 = 21600,
  Hours12 = 43200,
  Hours18 = 64800,
  Hours24 = 86400,
  Hours36 = 129600,
  Days2 = 172800,
  Days3 = 259200,
  Days5 = 432000,
  Days10 = 864000,
  Month = 2592000,
}

export interface IRange {
  min?: number;
  max?: number;
}

export enum PriceType {
  SM = 1,
  Total = 2,
}

export enum BuildingClassType {
  A = 1,
  APlus = 2,
  BMinus = 8,
  B = 3,
  BPlus = 4,
  C = 5,
}

export enum HouseMaterial {
  Brick = 1,
  Monolithic = 2,
  Panel = 3,
  Block = 4,
  Wood = 5,
  Stalin = 6,
  Shield = 7,
  MonolithicBrick = 8,
  Old = 9,
}

export interface IRooms {
  oneRoom: boolean;
  twoRooms: boolean;
  threeRooms: boolean;
  fourRooms: boolean;
  fiveRooms: boolean;
  sixRooms: boolean;
  freeLayout: boolean;
  studio: boolean;
}

export interface ISaleTypesNewbuilding {
  FZ214: boolean;
  ZhSK: boolean;
  cessionOfRights: boolean;
}

export interface IGeoTag {
  id?: number;
  text: string;
  name: string;
  kind: GeoTagKind;
  regionId?: number;
  geo?: string[][];
  isParsed?: boolean;
  color?: string;
  details?: IGeoTagDetails[];
  underConstruction?: boolean;
  releaseYear?: number;
}
export interface IGeoTagDetails {
  fullName: string;
  geoType: GeoTagKind;
  id: number;
  name: string;
  locationInfo?: IRegionInfo;
}

export interface IMapInfrastructure {
  [key: string]: boolean | undefined;
  withSchools?: boolean;
  withKindergartens?: boolean;
  withHospitals?: boolean;
  withGroceryStores?: boolean;
  withCafes?: boolean;
}

export type TInfrastructureLabel = 'schools' | 'kindergatens' | 'polyclinics' | 'shops' | 'cafes';

export enum SteadStatus {
  Farm = 3,
  Gardening = 2,
  IJS = 1,
  Industry = 5,
  DNP = 4,
}

export enum SteadCondition {
  Electricity,
  Gas,
  Sewerage,
  Water,
}

export enum ContractType {
  DirectRent = 1,
  Subrent,
  AssignmentRentRights,
  SaleObject,
}

export enum FlatCondition {
  Fridge,
  Washer,
  KitchenFurniture,
  Internet,
  Tv,
  Conditioner,
  DishWasher,
  Phone,
  Bath,
  Shower,
  Pets,
  Kids,
  Bathhouse,
  Garage,
  Pool,
}

export enum EnterType {
  SeparateFromYard = 1,
  SeparateFromStreet = 2,
  CommonFromYard = 3,
  CommonFromStreet = 4,
}

export interface IError {
  message: string;
  onRefresh?: () => void;
}

export interface IRegionsSettings {
  isPikPromoEnabled: boolean;
  isOfferNewEnabled: boolean;
  isDealRentEnabled: boolean;
  initialized: boolean;
}

export enum MovementMethod {
  Transport,
  Walk,
}

export interface ISubwayDistance {
  movementMethod?: MovementMethod;
  maxDistance?: number;
}

export const yearType = {
  PutIntoOperation: 'putIntoOperation',
  Later: 'later',
  getYear(yearShift: number): string {
    return `${new Date().getFullYear() + yearShift}`;
  },
};

export const BathroomsCountType = {
  Any: 0,
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
};

export const LiftsCountType = {
  Any: '0',
  One: '1',
  Two: '2',
  Three: '3',
  Four: '4',
};

export const sellerType = {
  All: 'all',
  Developer: 'developer',
  Agent: 'agent',
};

export type TObjectType =
  | 'kvartira'
  | 'komnata'
  | 'dolya'
  | 'koyko-mesto'
  | 'dom'
  | 'chast_doma'
  | 'taunkhaus'
  | 'uchastok'
  | 'ofis'
  | 'torgovaya_ploschad'
  | 'sklad'
  | 'psn'
  | 'obschepit'
  | 'garazh'
  | 'proizvodstvo'
  | 'avtoservis'
  | 'gotovyy_biznes'
  | 'zdanie'
  | 'bytovye_uslugi'
  | 'commercialLand'
  | 'coworking'
  | 'rentalBusiness'
  | 'readyBusiness';

export const flatObjectType = {
  Apartment: 'kvartira' as const,
  Room: 'komnata' as const,
  Part: 'dolya' as const,
  Bed: 'koyko-mesto' as const,
};

export const suburbanObjectType = {
  House: 'dom' as const,
  PartOfHouse: 'chast_doma' as const,
  TownHouse: 'taunkhaus' as const,
  Stead: 'uchastok' as const,
};

export const commercialObjectType = {
  Office: 'ofis',
  Marketplace: 'torgovaya_ploschad',
  Warehouse: 'sklad',
  Psn: 'psn',
  PublicCatering: 'obschepit',
  Garage: 'garazh',
  Production: 'proizvodstvo',
  CarService: 'avtoservis',
  Business: 'gotovyy_biznes',
  RentalBusiness: 'rentalBusiness',
  ReadyBusiness: 'readyBusiness',
  Building: 'zdanie',
  DomesticService: 'bytovye_uslugi',
  CommercialLand: 'commercialLand',
  Coworking: 'coworking',
} as const;

export type TCoworkingOfferType =
  | 'office'
  | 'fixed_workplace'
  | 'flexible_workplace'
  | 'conference_hall'
  | 'meeting_room';

export const coworkingOfferType = {
  /** Отдельный офис */
  Office: 'office' as const,
  /** Закреплённое рабочее место */
  FixedWorkplace: 'fixed_workplace' as const,
  /** Незакреплённое рабочее место */
  FlexibleWorkplace: 'flexible_workplace' as const,
  /** Конференц-зал */
  ConferenceHall: 'conference_hall' as const,
  /** Переговорная комната */
  MeetingRoom: 'meeting_room' as const,
};

export const specialCommercialObjectTypes = {
  Office: 'ofis' as const,
  Warehouse: 'sklad' as const,
  Marketplace: 'torgovaya_ploschad' as const,
  Psn: 'psn' as const,
};

export type TCategoryType = 'commercialLandRent' | 'commercialLandSale';

export const flatObjectTypes = values(flatObjectType);
export function isFlatObjectType(objectType: TObjectType): boolean {
  // tslint:disable-next-line
  return flatObjectTypes.includes(objectType as any);
}

export function isFlatObjectTypes(objectTypes: TObjectType[]): boolean {
  return difference(objectTypes, flatObjectTypes).length === 0;
}

export const suburbanObjectTypes = values(suburbanObjectType);
export function isSuburbanObjectType(objectType: TObjectType): boolean {
  // tslint:disable-next-line
  return suburbanObjectTypes.includes(objectType as any);
}

export function isSuburbanObjectTypes(objectTypes: TObjectType[]): boolean {
  return difference(objectTypes, suburbanObjectTypes).length === 0;
}

export const commercialObjectTypes = values(commercialObjectType);
export function isCommercialObjectType(objectType: TObjectType): boolean {
  // tslint:disable-next-line
  return commercialObjectTypes.includes(objectType as any);
}

export const coworkingOfferTypes = values(coworkingOfferType);
export function isCoworkingOfferType(offerType: TCoworkingOfferType): boolean {
  // tslint:disable-next-line
  return coworkingOfferTypes.includes(offerType as any);
}

export function isCommercialObjectTypes(objectTypes: TObjectType[]): boolean {
  return difference(objectTypes, commercialObjectTypes).length === 0;
}

export function isSpecialCommercialObjectTypes(objectTypes: TObjectType[]): boolean {
  return difference(objectTypes, values(specialCommercialObjectTypes)).length === 0;
}

export enum FiltersSection {
  Index,
  LongRent,
  Sale,
  NewBuildings,
  ShortRent,
  Commercial,
}

export const FiltersSectionQuery: { [key: number]: SectionType } = {
  [FiltersSection.Index]: 'index',
  [FiltersSection.Sale]: 'sale',
  [FiltersSection.ShortRent]: 'dailyRent',
  [FiltersSection.LongRent]: 'rent',
  [FiltersSection.NewBuildings]: 'newBuildings',
  [FiltersSection.Commercial]: 'commercial',
};

// Цена за 1 - квадратный метр, 2 - за всё
export type TPriceType = 1 | 2;

export interface IMultiObject {
  id: number;
}

export interface ICoworkingAccess {
  allDay?: boolean;
  allWeek?: boolean;
}

export interface INewobjectCompilation {
  isSalesLeader: boolean;
  isSalesStart: boolean;
}

export enum EReadyBusinessType {
  /** Арендный бизнес */
  RentalBusiness = 'rentalBusiness',
  /** Другое */
  Other = 'other',
  /** Готовый бизнес */
  ReadyBusiness = 'readyBusiness',
}

/** Тип электронных торгов */
export enum EElectronicTradingType {
  /** Неважно */
  Any = 0,
  /** Не показывать */
  DoNotDisplay = 2,
  /** Только торги и залоговая недвижимость */
  ElectronicTradingOnly = 1,
}

// alphabetical order for keys
export type FilterState = {
  windowsType?: number;
  ceilingHeight?: number;
  accessSystem?: boolean;
  amenities: string[];
  advancedFiltersCount?: number;
  agentNoFee: boolean;
  apartment?: boolean;
  basementFloor: boolean;
  bathroomsCount: number;
  bathroomPlacement: BathroomPlacement;
  bathroomType: BathroomType;
  boxTypes: boxTypeDict[];
  builderId?: number;
  newobjectCompilation: INewobjectCompilation;
  contractTypes: ContractType[];
  coworkingAccess?: ICoworkingAccess;
  currency: Currency;
  dealType: DealType;
  decoration?: boolean;
  demolishedInMoscowProgramm?: boolean;
  distanceToMKAD: IRange;
  engineVersion: number;
  enterTypes: EnterType[];
  error?: IError;
  firstFloor?: boolean;
  flatConditions: FlatCondition[];
  floor: IRange;
  floorsNumber: IRange;
  fromRepresentative?: boolean;
  geoTags: IGeoTag[];
  hasVideo: boolean;
  highways: number[];
  houseMaterials: string[];
  houseYear: IRange;
  infrastructure: IMapInfrastructure;
  isByHomeOwner: boolean;
  isGeoTagsLoading: boolean;
  isOfferCountFetching: boolean;
  isByCommercialOwner?: boolean;
  kpId?: number;
  lastUrl: string;
  liftsCount?: number;
  multiObject?: IMultiObject;
  notLastFloor?: boolean;
  offerCount?: number;
  onlyFlat?: boolean;
  price: IRange;
  priceType: TPriceType;
  jsonQueryPriceType: EJsonQueryPriceType | undefined;
  publicationPeriod?: number;
  readyBusinessTypes?: EReadyBusinessType[];
  region: number;
  regionSettings: IRegionsSettings;
  roomsCount: number[];
  rentRoomsCount?: number;
  rentTime: RentTime;
  resaleOnly: boolean;
  rooms: IRooms;
  section?: FiltersSection;
  saleTypeFlat: SaleTypeFlat[];
  saleTypesNewbuilding: ISaleTypesNewbuilding;
  selectedBuildingClasses: BuildingClassType[];
  selectedGarageSubtypes: string[];
  selectedGarageTypes: number[];
  selectedHouseTypes: string[];
  selectedObjectTypes: TObjectType[];
  selectedSellers: string[];
  selectedYears: string[];
  semibasementFloor: boolean;
  showAdvancedFilters: boolean;
  showAdvancedFiltersInPopup?: boolean;
  sizeKitchen: IRange;
  sizeLiving: IRange;
  sizeStead: IRange;
  sizeTotal: IRange;
  businessAppointments: Array<number>;
  steadConditions: SteadCondition[];
  steadStatuses: SteadStatus[];
  suburbanOfferFilter?: ESuburbanOfferFilter;
  subwayDistance: ISubwayDistance;
  withBalcony: boolean;
  withCustom: boolean;
  withDeposit?: boolean;
  withDiscount: boolean;
  withFurniture?: boolean;
  withLoggia: boolean;
  withMortgage: boolean;
  withNewobject: boolean;
  withPhoto: boolean;
  withPik: boolean;
  withServiceLift: boolean;
  roomType: RoomType;
  withLayout: boolean;
  userInput?: string;
  isFromDomrf: boolean;
  isUpcomingSale?: boolean;
  selectedCoworkingOfferType?: TCoworkingOfferType[];
  workplace_count?: IRange;
  electronicTrading?: EElectronicTradingType;
};

export const defaultRegionsSettings: IRegionsSettings = {
  isPikPromoEnabled: false,
  isOfferNewEnabled: true,
  isDealRentEnabled: false,
  initialized: false,
};

const emptyRange: IRange = {};

// default filter
export const defaultFilter: FilterState = {
  /**
   * По умолчанию когда мы заходим на любую страничку /snyat-ofis /kupit-kvartiry etc.
   * фильтры находятся в дефолтном состоянии хотя это !! -> не правда <- !!. К сожалению перекорчевать
   * фильтры так что-бы они были всегда актуальны очень долго, по этому оринтеруйтесь по экшену GetInitialFiltersSuccess
   * но он 100% случаев тоже не покрывает.
   * добавлено в рамках постмортема "https://cianru.atlassian.net/browse/FAIL-145"
   */
  advancedFiltersCount: 0,
  agentNoFee: false,
  apartment: undefined,
  onlyFlat: undefined,
  amenities: [],
  publicationPeriod: undefined,
  bathroomsCount: BathroomsCountType.Any,
  bathroomPlacement: BathroomPlacement.Any,
  bathroomType: BathroomType.Any,
  boxTypes: [],
  currency: Currency.RUB,
  coworkingAccess: undefined,
  ceilingHeight: undefined,
  dealType: DealType.Sale,
  demolishedInMoscowProgramm: undefined,
  engineVersion: 2,
  flatConditions: [],
  floorsNumber: emptyRange,
  fromRepresentative: undefined,
  geoTags: [],
  hasVideo: false,
  infrastructure: {},
  isByHomeOwner: false,
  isGeoTagsLoading: false,
  highways: [],
  houseMaterials: [],
  houseYear: emptyRange,
  selectedObjectTypes: [flatObjectType.Apartment],
  selectedSellers: [],
  selectedYears: [],
  isOfferCountFetching: false,
  liftsCount: undefined,
  price: emptyRange,
  priceType: 2,
  jsonQueryPriceType: undefined,
  region: Regions.Moscow,
  rentTime: rentTimeDict.Long,
  roomsCount: [],
  rentRoomsCount: undefined,
  rooms: {
    oneRoom: true,
    twoRooms: true,
    threeRooms: false,
    fourRooms: false,
    fiveRooms: false,
    sixRooms: false,
    freeLayout: false,
    studio: false,
  },
  saleTypeFlat: [],
  saleTypesNewbuilding: {
    FZ214: false,
    ZhSK: false,
    cessionOfRights: false,
  },
  showAdvancedFilters: false,
  showAdvancedFiltersInPopup: undefined,
  sizeKitchen: emptyRange,
  sizeLiving: emptyRange,
  sizeTotal: emptyRange,
  selectedBuildingClasses: [],
  selectedGarageSubtypes: [],
  selectedGarageTypes: [],
  selectedHouseTypes: [],
  steadConditions: [],
  steadStatuses: [],
  subwayDistance: {},
  withBalcony: false,
  withDeposit: undefined,
  withDiscount: false,
  withFurniture: undefined,
  withLoggia: false,
  withMortgage: false,
  withNewobject: false,
  withServiceLift: false,
  withPhoto: false,
  withPik: false,
  withCustom: false,
  windowsType: undefined,
  resaleOnly: false,
  accessSystem: undefined,
  enterTypes: [],
  contractTypes: [],
  decoration: undefined,
  firstFloor: undefined,
  notLastFloor: undefined,
  basementFloor: false,
  semibasementFloor: false,
  floor: emptyRange,
  distanceToMKAD: emptyRange,
  sizeStead: emptyRange,
  section: FiltersSection.Index,
  regionSettings: defaultRegionsSettings,
  lastUrl: '',
  withLayout: false,
  roomType: RoomType.Any,
  userInput: '',
  isFromDomrf: false,
  isUpcomingSale: undefined,
  workplace_count: emptyRange,
  newobjectCompilation: {
    isSalesLeader: false,
    isSalesStart: false,
  },
  businessAppointments: [],
  electronicTrading: undefined,
};

const defaultVerticalFilter: FilterState = {
  ...defaultFilter,
};

// "/kupit" и "/snyat" на квладке "продажа"
export const defaultSaleFilter: FilterState = {
  ...defaultVerticalFilter,
  section: FiltersSection.Sale,
};

// "/snyat" и "/kupit" на квладке "аренда"
export const defaultRentFilter: FilterState = {
  ...defaultVerticalFilter,
  dealType: DealType.Rent,
  section: FiltersSection.LongRent,
};

// "/novostrojki"
export const defaultNewBuildingFilter: FilterState = {
  ...defaultVerticalFilter,
  withNewobject: true,
  section: FiltersSection.NewBuildings,
};

// "/posutochno"
export const defaultShortRentFilter: FilterState = {
  ...defaultVerticalFilter,
  dealType: DealType.Rent,
  rentTime: rentTimeDict.Short,
  section: FiltersSection.ShortRent,
};

// "/commercial"
export const defaultCommercialFilter: FilterState = {
  ...defaultVerticalFilter,
  dealType: DealType.Rent,
  selectedObjectTypes: [commercialObjectType.Office],
  section: FiltersSection.Commercial,
};

export type DisablePredicate = (filter: FilterState) => boolean;

export const sale: DisablePredicate = filter => filter.dealType === DealType.Sale;
export const rent: DisablePredicate = filter => filter.dealType === DealType.Rent;
export const short: DisablePredicate = filter => rent(filter) && filter.rentTime === rentTimeDict.Short;
export const long: DisablePredicate = filter => sale(filter) || filter.rentTime !== rentTimeDict.Long;
export const isSaleOrRent: DisablePredicate = filter => sale(filter) || rent(filter);

export const objectTypeVisibility: { [key: string]: DisablePredicate[] } = {
  [flatObjectType.Part]: [rent],
  [flatObjectType.Bed]: [sale],
  [suburbanObjectType.PartOfHouse]: [short],
  [suburbanObjectType.TownHouse]: [short],
  [suburbanObjectType.Stead]: [rent],
  [commercialObjectType.Office]: [short],
  [commercialObjectType.Marketplace]: [short],
  [commercialObjectType.Warehouse]: [short],
  [commercialObjectType.Psn]: [short],
  [commercialObjectType.PublicCatering]: [short],
  [commercialObjectType.Garage]: [short],
  [commercialObjectType.Production]: [short],
  [commercialObjectType.CarService]: [short],
  [commercialObjectType.RentalBusiness]: [rent],
  [commercialObjectType.ReadyBusiness]: [rent],
  [commercialObjectType.Building]: [short],
  [commercialObjectType.DomesticService]: [short],
  [commercialObjectType.CommercialLand]: [short],
  [commercialObjectType.Coworking]: [long],
};

export const objectTypeIsNotMultiple: { [key: string]: boolean } = {
  [commercialObjectType.CommercialLand]: true,
  [commercialObjectType.Coworking]: true,
};
