import * as React from 'react';
import { connect } from 'react-redux';

import { ReduxState } from '../../redux/model';

import { CopyrightColor } from '../../ui/copyright';
import { CopyrightContainer } from '../../ui/copyright/container';
import { footerClick } from '../../utils/analytics';
import toDesktop from '../../utils/to_desktop';
import DownloadAppButton, { DownloadAppButtonTheme, TOs } from '../download_app_button/download_app_button';

const styles = require('./footer.css');

interface IFooterProps {
  os?: TOs;
}

interface IFooterStoreProps {
  isNotFoundPage?: boolean;
}

type IProps = IFooterStoreProps & IFooterProps;

const fullVersionClick = () => {
  footerClick('full-version');
  toDesktop();
};

class Footer extends React.PureComponent<IProps, object> {
  public render() {
    if (this.props.isNotFoundPage) {
      // tslint:disable-next-line:no-any
      return null as any;
    }

    return (
      <footer className={styles.footer}>
        <CopyrightContainer color={CopyrightColor.Gray}/>
        <button className={styles.fullVersion} type="button" onClick={fullVersionClick}>
          Полная версия сайта
        </button>
        <div className={styles.downloadAppButton}>
          <DownloadAppButton
            analyticsPlace="Mob_footer"
            os={this.props.os}
            styleConfig={{marginTop: 20}}
            theme={DownloadAppButtonTheme.GRAY}
          />
        </div>
      </footer>
    );
  }
}

function mapStateToProps(state: ReduxState): IFooterStoreProps {
  return {
    isNotFoundPage: state.isNotFound || state.searchList.isNotFound || state.offerPage.isNotFoundOffer,
  };
}

export default connect<IFooterStoreProps, {}, IFooterProps>(mapStateToProps)(Footer);
