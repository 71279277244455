export enum RouteName {
    MAIN = 'main',
    ADVANCED_SEARCH = 'advancedSearch',
    LISTING = 'listing',
    OFFER_CARD = 'offerCard',
    FAVORITES = 'favorites',
    CONTACTS = 'contacts',
    GK_CARD = 'gkCard',
    ADVERTISE_PROPMO = 'advertPromo',
    NOT_FOUND = '404',
    MAP = 'map',
    SIGN_IN_UP = 'sign_in_up',
    RESET_PASSWORD = 'resetPassword',
    MY_OFFERS_PROMO = 'myOffers',
}
