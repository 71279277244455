import { getValues } from '../utils/object_helpers';
import { VisualSize } from '../utils/style';

const linkStyle = require('../link/link.css');
const buttonStyle = require('../button/button.css');

export interface ILinkStyleProps {
  size?: VisualSize;
  theme?: LinkThemeType | ButtonThemeType;
}

export type LinkThemeType = 'link/blue';

export interface ILinkTheme {
  BLUE: LinkThemeType;
}

// tslint:disable-next-line:variable-name
export const LinkTheme: ILinkTheme = {
  BLUE: 'link/blue',
};

export const linkThemeStyles: { [K: string]: string; } = {
  [LinkTheme.BLUE]: '',
};

export const linkSizeStyles: { [K: number]: string; } = {
  [VisualSize.SMALL]: linkStyle['small'],
  [VisualSize.MEDIUM]: linkStyle['medium'],
  [VisualSize.LARGE]: linkStyle['large'],
};

export const isLinkTheme = (theme: string) => getValues(LinkTheme).indexOf(theme) !== -1;

export interface IButtonStyleProps {
  size?: VisualSize;
  theme?: ButtonThemeType | LinkThemeType;
  mobile?: boolean;
}

export type ButtonThemeType
  = 'button/toggle'
  | 'button/toggleActive'
  | 'button/toggleActiveUncheckable'
  | 'button/blue'
  | 'button/solid'
  | 'button/orange'
  | 'button/orangeSolid'
  | 'button/white'
  | 'button/blueLightSolid';

export interface IButtonTheme {
  TOGGLE: ButtonThemeType;
  TOGGLE_ACTIVE: ButtonThemeType;
  TOGGLE_ACTIVE_UNCKECKABLE: ButtonThemeType;
  BLUE: ButtonThemeType;
  BLUE_SOLID: ButtonThemeType;
  ORANGE: ButtonThemeType;
  ORANGE_SOLID: ButtonThemeType;
  WHITE: ButtonThemeType;
  BLUELIGHT_SOLID: ButtonThemeType;
}

// tslint:disable-next-line:variable-name
export const ButtonTheme: IButtonTheme = {
  TOGGLE: 'button/toggle',
  TOGGLE_ACTIVE: 'button/toggleActive',
  TOGGLE_ACTIVE_UNCKECKABLE: 'button/toggleActiveUncheckable',
  BLUE: 'button/blue',
  BLUE_SOLID: 'button/solid',
  ORANGE: 'button/orange',
  ORANGE_SOLID: 'button/orangeSolid',
  WHITE: 'button/white',
  BLUELIGHT_SOLID: 'button/blueLightSolid',
};

export const buttonThemeStyles = {
  [ButtonTheme.BLUE]: buttonStyle['blue'],
  [ButtonTheme.BLUE_SOLID]: buttonStyle['blue_solid'],
  [ButtonTheme.ORANGE]: buttonStyle['orange'],
  [ButtonTheme.ORANGE_SOLID]: buttonStyle['orange_solid'],
  [ButtonTheme.WHITE]: buttonStyle['white'],
  [ButtonTheme.TOGGLE]: buttonStyle['toggle'],
  [ButtonTheme.TOGGLE_ACTIVE]: buttonStyle['toggle_active'],
  [ButtonTheme.TOGGLE_ACTIVE_UNCKECKABLE]: buttonStyle['toggle_active_uncheckable'],
  [ButtonTheme.BLUELIGHT_SOLID]: buttonStyle['bluelight_solid'],
};

export const buttonSizeStyles = {
  [VisualSize.SMALL]: buttonStyle['small'],
  [VisualSize.MEDIUM]: buttonStyle['medium'],
  [VisualSize.LARGE]: buttonStyle['large'],
  [VisualSize.MOBILE_MEDIUM]: buttonStyle['mobileMedium'],
};

export const isButtonTheme = (theme: string) => getValues(ButtonTheme).indexOf(theme) !== -1;

export const getStyles = ({ size, theme, mobile }: IButtonStyleProps & ILinkStyleProps ) => {
  let sizeStyles: {[key: string]: string} = {};
  let themeStyles: {[key: string]: string} = {};
  let containerStyle = [];
  let extraStyles = [];

  if (isButtonTheme(theme || ButtonTheme.BLUE)) {
    containerStyle = buttonStyle['container'];
    sizeStyles = buttonSizeStyles;
    themeStyles = buttonThemeStyles;
    if (mobile) {
      extraStyles.push(buttonStyle['mobile']);
    }
  } else if (isLinkTheme(theme || ButtonTheme.BLUE)) {
    containerStyle = linkStyle['container'];
    sizeStyles = linkSizeStyles;
    themeStyles = linkThemeStyles;
  } else {
    throw new Error('Unknown theme supplied to Button or Link!');
  }

  return [
    containerStyle,
    ...extraStyles,
    sizeStyles[size || VisualSize.SMALL],
    themeStyles[theme || ButtonTheme.BLUE],
  ];
};
