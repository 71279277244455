import { IStyleConfig, mergeStyles } from '@cian/utils';
import * as React from 'react';
import { connect } from 'react-redux';

import { DeviceType } from '../../../redux/common/model';
import { setRange } from '../../../redux/filters/actions';
import { FilterState, IRange, RangeType } from '../../../redux/filters/model';
import { ReduxState, TTypedThunkDispatch } from '../../../redux/model';
import { IFieldContext, injectAll } from '../../field';

import { RangeInputGroup } from '../../../ui/cian_components/range_input_group/range_input_group';

const styles = require('./range.css');
const fieldStyles = require('../../../filters/fields/field.css');

interface IRangeState {
  errorTextCommon: string;
}

interface IStoreProps {
  filter: FilterState;
  userAgent?: string;
  deviceType: DeviceType;
}

interface IDispatchProps {
  setRange(valueType: RangeType, value: number, field: string): void;
}

interface IOwnProps {
  field: keyof FilterState;
  fromPlaceholder?: string;
  toPlaceholder?: string;
  metric?: React.ReactNode;
  containerStyles?: IStyleConfig;
  rangeGroupStyles?: IStyleConfig;
  disablePrettyFormating?: boolean;
  inputStyle?: IStyleConfig;
  dataMark?: string;
}

class RangeComponent extends React.Component<IStoreProps & IDispatchProps & IOwnProps & IFieldContext, IRangeState> {
  public render() {
    const { min, max } = this.props.filter[this.props.field] as IRange;
    const filterMinId = 'filter-' + this.props.field + '-min';
    const filterMaxId = 'filter-' + this.props.field + '-max';
    const fromPlaceholder = this.props.fromPlaceholder || 'от';
    const toPlaceholder = this.props.toPlaceholder || 'до';
    const rangeContainerStyles = mergeStyles(
      styles.container,
      fieldStyles.inputContainer,
      this.props.rangeGroupStyles,
    );

    return (
      <div
        {...mergeStyles([styles.range].concat(this.props.containerStyles)) }
        data-mark={`${this.props.dataMark ? this.props.dataMark + '|' : ''}RageComponent`}>
        <RangeInputGroup
          rangeGroupStyle={[rangeContainerStyles.className, rangeContainerStyles.style]}
          min={{
            placeholder: fromPlaceholder,
            id: filterMinId,
            inputMode: 'numeric',
            inputType: 'text',
            name: 'min',
            value: min,
            disableNegative: true,
            disableFractional: true,
            disablePrettyFormating: this.props.disablePrettyFormating,
            minValue: 0,
            maxValue: 999999,
            maxLength: 12,
            pattern: '[0-9]*',
            onValueChange: this.updateMin,
            inputStyle: this.props.inputStyle,
          }}
          max={{
            placeholder: toPlaceholder,
            id: filterMaxId,
            inputMode: 'numeric',
            inputType: 'text',
            name: 'max',
            value: max,
            disableNegative: true,
            disableFractional: true,
            disablePrettyFormating: this.props.disablePrettyFormating,
            minValue: 0,
            maxValue: 999999,
            maxLength: 12,
            pattern: '[0-9]*',
            onValueChange: this.updateMax,
            onBlur: this.validate,
            inputStyle: this.props.inputStyle,
          }}
        />
        {!!this.props.metric &&
          <div {...mergeStyles(styles.sign)}>
            {this.props.metric}
          </div>
        }
      </div>
    );
  }

  private updateMin = (value: number) => {
    this.props.setRange('min', value, this.props.field);
  }

  private updateMax = (value: number) => {
    this.props.setRange('max', value, this.props.field);
  }

  private validate = (e: React.FocusEvent<HTMLElement>) => {
    const { min, max } = this.props.filter[this.props.field] as IRange;

    if ((min && max) && min > max) {
      // Таймаут нужен так как updateMax срабатывает на blur срабатывает раньше чем на onValueChange
      setTimeout(() => {
        this.updateMax(min);
      }, 0);
    }
  }
}
function mapStateToProps(state: ReduxState): IStoreProps {
  return {
    filter: state.filter,
    userAgent: state.userAgent,
    deviceType: state.common.deviceType,
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    setRange: (valueType: RangeType, value: number, field: string) =>
      dispatch(setRange(valueType, value, field)),
  };
}

const ConnectedToStoreRage = connect<IStoreProps, IDispatchProps, IOwnProps & IFieldContext>(
  mapStateToProps,
  mapDispatchToProps,
)(RangeComponent);

export const AdaptiveAdvancedFiltersRange: React.SFC<IOwnProps & IFieldContext> =
  (props) => {
    const {
      rangeGroupStyles,
      containerStyles,
      forceMobile,
      ...otherProps } = props;

    return <ConnectedToStoreRage
      {...otherProps}
      containerStyles={[
        styles.adaptiveContainer,
        forceMobile && styles.fullWidth,
      ].concat(containerStyles)}
      rangeGroupStyles={[
        !forceMobile && styles.tabletNoFlexRangeGroup,
      ].concat(rangeGroupStyles)}
    />;
  };

const TabletFastFiltersRangeComponent: React.SFC<IOwnProps & IFieldContext> =
  ({ rangeGroupStyles, containerStyles, ...otherProps }) => {
    return <ConnectedToStoreRage
      {...otherProps}
      containerStyles={[styles.fullWidth].concat(containerStyles)}
      rangeGroupStyles={[rangeGroupStyles].concat(styles.tabletFlexRangeGroup)}
    />;
  };

export const TabletFastFiltersRange = injectAll(TabletFastFiltersRangeComponent);

export default injectAll(AdaptiveAdvancedFiltersRange);
