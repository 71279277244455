import * as React from 'react';

const styles = require('./button_checkbox_container.css');

export default class ButtonCheckboxContainer extends React.Component<React.Props<object>, object> {
  public render() {
    return <div className={styles.buttons}>
      {this.props.children}
    </div>;
  }
}
