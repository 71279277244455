import { get } from 'lodash';
import * as React from 'react';

import { IFieldProps, IsVisibleCheck } from '../field';

import { IRoadsHighway, IRoadsResultData } from '../../../api/api';

import { inject, MobileWebsiteApi } from '../../../utils/context_provider';
import { toPromise } from '../../../utils/streams';

import { ISelectMobileOption, Label } from '@cian/components';
import { SelectMobile } from '../../common/select/select';
import { createSelectOptions } from '../../common/select_helper';

const style = require('../field.css');

interface IConnectedProps {
  api: MobileWebsiteApi;
}

interface IHighwayProps extends IFieldProps, IConnectedProps {}

class Highway extends React.Component<IHighwayProps, object> {
  private options: ISelectMobileOption[] = [];

  public componentWillMount() {
    toPromise(this.props.api.getRoads())
      .then(data => {
        const result = data.result as IRoadsResultData[];

        result.forEach((direction: IRoadsResultData) =>
          get(direction, 'highways', [] as IRoadsHighway[])
            .forEach((highway: IRoadsHighway) => this.options.push({
              id: String(highway.id),
              label: highway.fullName,
            })),
        );
      })
      .catch(err => {
        throw err;
      });
  }

  public render() {
    if (this.props.filter.highways.length === 0) {
      return null as any; // tslint:disable-line:no-any
    }

    const processedOptions = createSelectOptions(
      [{ label: '', options: this.options }],
      this.props.filter.highways.map(String),
      this.props.filter,
    );

    return (
      <div className={style.field} data-mark="FieldSelectHighway">
        <Label label="Шоссе" containerStyle={style.label} >
          <SelectMobile
            multiple
            id="select-highway"
            name="highway"
            label="Неважно"
            onValueChange={this.handleValueChange}
            value={processedOptions.value}
            options={processedOptions.options[0].options}
          />
        </Label>
      </div>
    );
  }

  private handleValueChange = (values: string[]) =>
    this.props.actions.setHighways(values.map(Number))
}

export const isVisible: IsVisibleCheck = filter => true;

export const Component = inject(['api'])(Highway);
