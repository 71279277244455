import * as React from 'react';
import { Field } from '../../../field';
import * as fields from '../../../fields/';
import { Section } from '../../advanced';
import Block from '../../../../ui/block/block';

const { fieldBlock } = require('../../../fields/field.css');

interface IAdvancedCoworkingFilters {
  children?: React.ReactNode;
}

export function AdvancedCoworkingFilters({ children }: IAdvancedCoworkingFilters) {
  return (
    <div data-mark="AdvancedFiltersList">
      <Field field={fields.coworkingAmenities} />
      <Section>
        <Block>
          <div className={fieldBlock}>
            <Field field={fields.floorCommerce} />
            <Field field={fields.floorsNumber} />
            <Field field={fields.coworkingAccess} />
            <Field field={fields.publicationPeriodAB} />
            <Field field={fields.withPhoto} />
            <Field field={fields.hasVideo} />
          </div>
        </Block>
      </Section>
      {children}
    </div>
  );
}
