import * as httpApi from '@cian/http-api/browser';
import * as logger from '@cian/logger/browser';
import * as config from '@cian/config/browser';
import * as telemetry from '@cian/telemetry/browser';
import { init as initGlobalBehaviors } from '@cian/global-behaviors/browser';
import { assertStatusCode } from '../../../../network_framework/utils';

export function initClientDependencies() {
  config.init();

  telemetry.setup();
  httpApi.setup({ assertStatusCode });

  logger.init();
  telemetry.init();
  httpApi.init();
  initGlobalBehaviors();
}
