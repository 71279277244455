/* eslint-disable max-lines, @typescript-eslint/explicit-function-return-type,sort-keys */

import { ChatsNodeHttp, MessagesNodeHttp, UserNodeHttp } from '@cian/chats-api';
import { ChatsHttp } from '@cian/chats-api/lib/shared/chats';
import { MessagesHttp } from '@cian/chats-api/lib/shared/messages';
import { UserHttp } from '@cian/chats-api/lib/shared/users';
import { Config } from '@cian/config';
import { IHTTPRequestConfig, IUserLayersConfig, parseURI } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger';

import * as express from 'express';
import { snakeCase } from 'lodash';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';

import { NetworkLayer } from '../../network_framework/network_layer';
import { IRequestConfiguration, Response } from '../../network_framework/request';
import { ICianApiSiteV1CreateSubscriptionResponse200 } from '../../shared/repositories/monolith-python/cian-api/site/v1/create-subscription';
import { ESavedSearchType, TSource, createSubscription } from '../../shared/services/createSubscription';
import { IChatsConfig } from '../../shared/types/chatsConfig';
import { IGKMVKDataResponse } from '../components/gk_flat_selector_widget/typings';
import { TNotificationFrequency } from '../components/save_filter_popup/ab/utils/notificationFrequency';
import { IRedirectData, IUserRegion, ResourceStatus } from '../redux/common/model';
import { defaultFavoritesState } from '../redux/favorites/model';
import { ESuburbanOfferFilter } from '../redux/filters/model';
import { ISubscriptionStatus } from '../redux/offer/model';
import { IKp, IKpOffers } from '../redux/offers_list/model';
import { ISpecialPromo } from '../redux/special_promo/model';
import { ISpecialPromoInfo } from '../redux/special_promos_info/model';
import { IPromoLink } from '../ui/promo/promo';
import { ICacheStore, Memoized, createCacheFunction, noMemoized } from '../utils/cache';
import { isServer } from '../utils/isomorphic';
import { serializeParams } from '../utils/location';
import { Regions, patchIdForCompositeRegion } from '../utils/regions';
import { toPromise } from '../utils/streams';
import { isTimeoutError, timeoutPromise } from '../utils/timeout_promise';
import { YMapsBoundedBox, YMapsCoords } from '../utils/ymaps';

import { IUserGaDataLayerResponse } from './models/data_layer';
import { IFavouritesIDsResponseData } from './models/favourites';
import { IGeoSuggestRequestScheme, IGeoSuggestResponseScheme } from './models/geo_suggest';
import { IGetGKResponseData } from './models/gk_card';
import { INewobjectSerialized } from './models/gk_preview';
import { IJsonQuery } from './models/json_query';
import { INewbuildingSearchMapResponse } from './models/map';
import {
  ICreateUpcomingSaleRequestRequest,
  ICreateUpcomingSaleRequestResponse,
} from './models/newbuilding_flat_view_order';
import { IDemandJsonQuery, IOfferDetail, OfferDetailStatus } from './models/offer_card';
import { IOfferSerialized } from './models/offer_preview';
import { IQuickLinksResponse } from './models/quick_links';
import {
  IServerResponse,
  IUserGetResetPasswordDataResponse,
  IUserLogonRequest,
  IUserLogoutUrlsResponse,
} from './models/users';

const HOST_REGEX = /^https?:\/\/|(\/)+$/g;

export { IOfferSerialized, INewobjectSerialized };

export interface IAddOfferToFavorites {
  count: {
    total: number;
  };
  newbuilding_id: number;
  status: string;
}

export interface ISubdomainSearchOffers {
  _subdomain?: string;
  _path?: string;
}

export interface IJsonQuerySearchOffers {
  jsonQuery: IJsonQuery;
}

export interface IGetGKRequest {
  _subdomain: string;
  _path: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: number;
  calltest?: number;
}

export interface IBreadcrumb {
  url: string;
  title: string;
}

export interface ISeoData {
  h1?: string;
  description?: string;
  metaDescription?: string;
  title?: string;
  canonical?: string;
  noindex?: boolean;
  text?: string;
  notFoundDescription?: string | null;
  notFoundText?: string | null;
}

export interface IMarkedList {
  /** Блоки текстов **/
  items: IMarkedListItem[];
  /** Микроразметка **/
  schema?: string;
}

export interface IMarkedListItem {
  /** Заголовок блока текстов **/
  title: string;
  /** Блок текстов **/
  content: string;
}

export interface ISeoSchemas {
  /** Информация об организации **/
  organizationInfo: string;
  /** Онлайн-показ **/
  onlineShow: string;
  /** Огромный выбор **/
  wideSelection: string;
  /** Скидка на ипотеку **/
  mortgageDiscount: string;
}

export interface IPromoBlock {
  titleLink: IPromoLink;
  type: string;
  id: string;
  links: IPromoLink[];
  title: string;
  objectCount?: number;
}

export interface ISearchOffersData {
  jsonQuery: IJsonQuery;
  queryString: string;
  breadcrumbs: IBreadcrumb[];
  seoData: ISeoData;
  /** Маркированный список **/
  markedList?: IMarkedList;
  /** Сео схемы **/
  seoSchemas?: ISeoSchemas;
  offerCount: number;
  aggregatedCount: number;
  offersSerialized: IOfferSerialized[];
  newobjectsSerialized: INewobjectSerialized[];
  kp: IKp | IKpOffers | null;
  pikPhoneReplacement?: string;
  redirectData: IRedirectData;
  suggestionsQuery?: string;
  // CD-13359 эксперимент для коммерческой с карточкой
  callButtonVariant: number;
  // CD-14005 эксперимент для коммерческой с кнопкой позвонить
  isCallButtonEnabled: boolean;
  isNewobjectsEnabled: boolean;
  top3IsOn: boolean;
  noResultSuggest?: INoResultSugest;
  searchUuid: string | null;
  mlRankingGuid: string | null;
  mlRankingModelVersion: string | null;
  salesDescription?: ISalesDescriptionSchema | null;
}

export interface INoResultSugest {
  offerCount: number;
  offersSerialized: IOfferSerialized[];
  suggestionsQuery: string;
}

export interface IGeoSeoLinksData {
  geoSeoLinks?: IGeoSeoLink[];
}

export interface IGeoSeoLink {
  groupType?: string;
  items?: IGeoSeoItem[][];
  title?: string;
}

export interface IGeoSeoItem {
  url?: string;
  title?: string;
}

export interface IPromoBlocksData {
  promoBlocks: IPromoBlock[];
}

export interface ISearchOffersResponse {
  data: ISearchOffersData;
}

export interface ISearchOffersApiResponse {
  result: ISearchOffersResponse;
}

export type TModelVersion = string | number | null;

export interface ISimilarOffersResponse {
  status: string;
  data: {
    similarOffers: ISimilarOffersResponseItem[];
    withoutCompetitors: boolean;
    // Версия модели подбора похожих объявления
    modelVersion: TModelVersion;
  };
}

export interface ISimilarOffersResponseItem {
  // Адрес
  address: string | null;
  // Инфо по автору объявления
  brand: string | null;
  // Представление о категории объявления для Google Analytics
  category: string | null;
  // CIAN_ID объявления
  id: string | null;
  // ga_event_action - уникальная ссылка для оффера для Google Analytics
  label: string | null;
  // URL фото
  photoUrl: string | null;
  // Заголовок
  title: string | null;
  // Итоговая цена в рублях
  totalPriceRur: number | null;
  // Метро
  underground: {
    // Цвет линии метро, hex,
    lineColor: string | null;
    text: string | null;
  } | null;
  // Ссылка на объявление
  url: string | null;
  // Примененные к объявлению платные продукты для Google Analytics
  variant: string | null;
  // Статус объявления
  status: OfferDetailStatus;
  // id пользователя разместившего объявление
  userId: number;
  // id пользователя разместившего объявление
  publishedUserId: number;
}

export interface IGetSpecialPromosInfoResponse {
  specialPromos: ISpecialPromoInfo[];
}

interface IGetRegionBySubdomainResponseData {
  id: number;
  mainTownId?: number;
  displayName: string;
  parentId?: number;
  baseHost: string;
}

export interface IGetRegionBySubdomainResponse {
  status: string;
  data: IGetRegionBySubdomainResponseData;
}

export interface IGetLocationByRegionResponse {
  status: string;
  data: {
    id: number;
    displayName: string;
    baseHost: string;
    parentId?: number;
    mainTownId?: number;
  };
}

export interface IGetLocationByRegionResponseNext {
  id: number;
  displayName: string;
  baseHost: string;
  parentId?: number;
  mainTownId?: number;
}

export interface ILocation {
  id: number;
}

export interface ICurrentLocation {
  items: ILocation[];
}

export interface IGetCurrentLocationResponse {
  status: string;
  data: ICurrentLocation;
}

export interface IGeoSeoLinksResponse {
  data: IGeoSeoLinksData;
}

export interface IPromoBlocksResponse {
  data: IPromoBlocksData;
}

export interface IGetGKResponse {
  status: string;
  data: IGetGKResponseData;
}

export interface IGetRegionCitiesResponse {
  status: string;
  data: {
    items: IRegionInfo[];
  };
}

export interface IGetFavoritesIdsResponseOffers {
  rent: IGetFavoritesIdsResponseOffer[]; // избраннное в аренде
  sale: IGetFavoritesIdsResponseOffer[]; // избраннное в продаже
}

export interface IGetFavoritesIdsResponseOffer {
  id?: number;
  lat?: number; // широта
  lon?: number; // долгота
  propertyType?: number; // тип недвижимости, либо комнатность
}

export interface IGetFavoritesResponseBody {
  data: IGetFavoritesResponseData;
  status: string;
}

export interface IGetFavoritesResponseData {
  favorites: IGetFavouritesResponseOrder[];
  stats: IFavoriteStats;
  offers: IOfferDetail[];
  newobjects: INewobjectSerialized[];
  perPage: number; // константа, показывает максимальное кол-во объявлений на странице
}

export type TFavourite = 'offer' | 'newobject';

export interface IGetFavouritesResponseOrder {
  id: number;
  position?: number;
  entityType: TFavourite;
}

export interface IFavoriteStats {
  total: number;
  perDealType: IPerDealType;
  perOfferType: IPerOfferType;
}

export interface IPerDealType {
  sale: number;
  rent: number;
  [key: string]: number;
}

export interface IPerOfferType {
  residential: number;
  commercial: number;
  newobject: number;
  [key: string]: number;
}

export interface IFavoritesOptions {
  dealType?: DealType;
  offerType?: OfferType;
  sortType?: FavoritesSortType;
  sortOrder?: FavoritesSortOrderType;
  page?: number;
  offset?: number; // позволяет получить выборку, начиная с указанной позиции.
  [key: string]: any;
}

export type DealType = 'rent' | 'sale' | 'all' | undefined;
export type OfferType = 'residential' | 'commercial' | 'newobject' | undefined;
export type FavoritesSortType = 'date' | 'price' | 'datePublished';
export type FavoritesSortOrderType = 'asc' | 'desc' | undefined;

export type ISearchOffersRequest = IJsonQuerySearchOffers | IJsonQuery | ISubdomainSearchOffers;

export interface IGetSearchUrl {
  querystring: string;
  seoPath?: string;
  subdomain: string;
  count: number;
  aggregatedCount: number;
}

export interface IOffersByIdsResponse {
  status: string;
  data: TOffersByIdsResponseData;
}

export type TOffersByIdsResponseData = {
  offersSerialized: IOfferSerialized[];
};

export interface IGetSearchUrlResponse {
  status: string;
  data: IGetSearchUrl;
}

export interface IFavouritesIDsResponse {
  status: string;
  data: IFavouritesIDsResponseData;
}

export interface IAddressNode {
  fullName: string;
  geoType: string;
  id: number;
  name: string;
}

export interface IAddressTag {
  addressNodes: IAddressNode[];
  geoObjects: {
    options: {
      preset: string;
    };
    source: {
      geometry: {
        coordinates: number[];
        type: string;
      };
    };
  };
  text: string;
  type: string;
}

export interface INewobjectTag {
  id: string;
  lat: string;
  lng: string;
  text: string;
  type: string;
}

export interface IMetroTag {
  id: number;
  color: string;
  text: string;
  type: string;
  underConstruction?: boolean;
  releaseYear?: number;
}

export interface IVillageTag {
  id: number;
  text: string;
  type: 'village';
}
export interface IBCTag {
  id: number;
  text: string;
  type: 'businesscenter';
}
export interface ISCTag {
  id: number;
  text: string;
  type: 'shoppingcenter';
}
export interface IRailwayTag {
  id: number;
  text: string;
  type: 'railway';
}

export interface IDistrictTag {
  id: number;
  parentId?: number;
  text: string;
  type: string;
}

export interface IPolygonTag {
  geoObject: {
    options?: {
      preset?: string;
    };
    source: {
      geometry: {
        coordinates: string[][][];
        type: string;
      };
      properties: {
        isHighlighted?: boolean;
      };
    };
  };
  text: string;
  type: string;
}

export interface IGeoTags {
  geoTags: {
    address_tags: IAddressTag[];
    newobject_tags: INewobjectTag[];
    metro_tags: IMetroTag[];
    polygon_tags: IPolygonTag[];
    district_tags: IDistrictTag[];
    railway_tags?: IRailwayTag[];
    village_tags?: IVillageTag[];
    shoppingcenter_tags?: ISCTag[];
    businesscenter_tags?: IBCTag[];
  };
  region?: {
    fullname: string;
    id: number;
  };
}

export interface IGeoTagResponse {
  status: string;
  data: IGeoTags;
}

export interface ISeoMainResponse {
  data: ISeoMainData;
}

export interface ISeoMainData {
  seoText?: string[];
  description?: string;
  title?: string;
  seoTextHeading?: string;
  h1?: string;
  keywords?: string;
}

export interface ISeoMapData {
  description?: string;
  title?: string;
}

export interface ISubscribeRequest {
  email: string;
  title?: string;
  jsonQuery?: IJsonQuery;
  queryString?: string;
  source?: TSubscribeSource;
  subscribeNews?: boolean;
  notificationFrequency?: TNotificationFrequency;
}

export interface ISubscribeResponse {
  data: {
    logOnInfo?: {
      logOnUrl: string;
      token: string;
    }[];
    login?: string;
    isEmailConfirmed?: boolean;
  };
  status: string;
}

export type TDealType = 'sale' | 'rent';
export type TOfferType = 'flat' | 'suburban' | 'commercial' | 'newobject';

export interface ISubscribePriceRequest {
  email: string;
  offerId: number;
  offerType: TOfferType;
  dealType: TDealType;
  subscribeNews: boolean;
}

export interface ISubscriptionStatusError {
  errors: object[];
  message: string;
}

export interface ILogTokenResponse {
  status: string;
  response: ILogTokenResponseData;
}

export interface ILogTokenResponseData {
  statusCode?: number;
}

export interface IOfferToBIResponse {
  status: string;
}

export interface IGeocodeItem {
  text: string;
  name: string;
  kind: string;
  coordinates: YMapsCoords;
  boundedBy: YMapsBoundedBox;
}

export interface IGeocodeCachedResponse {
  items: IGeocodeItem[];
}

export enum ELKType {
  Searcher = 'searcher',
  Specialist = 'specialist',
  Homeowner = 'homeowner',
}

export interface IHeaderData {
  displayUsername?: string;
  isAuthenticated?: boolean;
  favoritesCount?: number;
  storedFiltersCount?: number;
  email?: string;
  firstName?: string;
  lastName: string;
  phone?: string;
  userId?: number;
  isProfi?: boolean;
  villagesUrl: string;
  /** Тип ЛК пользователя */
  lkType: ELKType | null;
  kpnWebimChecksum: string | null;
}

export interface IHeaderDataResponse {
  status: string;
  data: IHeaderData;
}

export interface IGeocodeRequest {
  lat: number;
  lng: number;
  kind: string;
  address: string;
}

export interface ICoords {
  lat: number;
  lng: number;
}

// FIXME: не должно быть string
export type TSubscribeSource = 'map' | 'listing' | string;

export type GeoTagKind =
  | 'Location'
  | 'Newobject'
  | 'Street'
  | 'House'
  | 'Underground'
  | 'District'
  | 'Highway'
  | 'Builder'
  | 'Polygon'
  | 'Distance'
  | 'Railway'
  | 'Village'
  | 'Shoppingcenter'
  | 'Businesscenter'

  // the same types, but in lowercase
  | 'location'
  | 'newobject'
  | 'street'
  | 'house'
  | 'underground'
  | 'district'
  | 'highway'
  | 'builder'
  | 'polygon'
  | 'distance'
  | 'railway'
  | 'village'
  | 'shoppingcenter'
  | 'businesscenter';

export interface IGeoDetails {
  id: number;
  name: string;
  fullName: string;
  geoType: GeoTagKind;
}

export interface IGeoMicroDistrict {
  id: number;
}

export interface IGeoLocationCatalogLink {
  id: number;
  objectType: GeoTagKind;
}

export interface IGeocodeResponse {
  geo: ICoords;
  geoLocationCatalogLink: IGeoLocationCatalogLink;
  text: string;
  isParsed: boolean;
  details: IGeoDetails[];
  countryId: number;
  regionId?: number;
  microDistricts?: IGeoMicroDistrict[];
  color?: string;
}

interface IGetJsonQueryData {
  jsonQuery: IJsonQuery;
}

export interface IGetJsonQueryResponse {
  status: string;
  data: IGetJsonQueryData;
}

export interface IRegions {
  [key: number]: string;
}

export interface IRegionsResponseData {
  default: IRegions;
  regions: IRegions;
}

export interface ISettingsResponseData {
  isPikPromoEnabled: boolean;
  isNewobjectsEnabled: boolean;
  isOfferNewEnabled: boolean;
  isDealRentEnabled: boolean;
}

export interface IJKSuggestionsData {
  Id: number;
  Info: string;
  Lat: string;
  Lng: string;
  LocationId: number;
  Name: string;
  Type: number;
  Value: string;
}

export interface IJKSuggestionItem {
  value: string;
  data: IJKSuggestionsData;
}

export interface IJKSuggestionsData {
  Id: number;
  Info: string;
  Lat: string;
  Lng: string;
  LocationId: number;
  Name: string;
  Type: number;
  Value: string;
}

export interface IJKSuggestionItem {
  value: string;
  data: IJKSuggestionsData;
}

export interface IRegionsResponse {
  status: string;
  data: IRegionsResponseData;
}

export interface ISearchRegionsResponse {
  status: string;
  data: ISearchRegionsResponseData;
}

export interface ISearchRegionsResponseData {
  items: IRegionInfo[];
}

type DistrictType = 'okrug' | 'poselenie' | 'raion';

export interface IRegionMetaResponse {
  status: string;
  data: IRegionMetaData;
}

export interface IRegionMetaData {
  status: ResourceStatus;
  districts: IDistrict[];
  underground: IUnderground[];
  location: IRegionInfo;
  parentRoads?: IRoadsResultData[];
}

export interface IDistrict {
  id: number;
  name: string;
  type: DistrictType;
  childs: ISubdistrict[];
  direction: IDirection;
}

export interface IDirection {
  code?: 'n' | 's' | 'w' | 'e' | 'nw' | 'ne' | 'sw' | 'se' | 'center' | 'null';
  name?: string;
}

export interface ISubdistrict {
  direction: IDirection;
  type: 'mikroRaion' | DistrictType;
  id: number;
  name: string;
}

export interface IUnderground {
  id?: number;
  lineName?: string;
  prepositionalCase?: string;
  name?: string;
  genitiveCase?: string;
  lineColor?: string;
  transitName?: string;
  nativeName?: string;
  lineId?: number;
  underConstruction?: boolean;
  releaseYear?: number;
}

export interface IRoadsResultData {
  name: string;
  highways: Array<IRoadsHighway>;
}

export interface IRoadsHighway {
  id: number;
  name?: string;
  fullName: string;
}

export interface ISettingsResponse {
  status: string;
  data: ISettingsResponseData;
}

export interface IExperiment {
  abGroup: number;
  name: string;
}

export interface IComplaintsTreeItem {
  id: string;
  name?: string;
  title: string;
  items: IComplaintsTreeItem[];
  hint?: string;
  blockType?: IComplainBlockType;
  categories?: number[];
  url?: string;
  isCommentRequired?: boolean;
}

export type IComplainBlockType = 'finish' | 'textarea' | 'transitional' | 'withVirtualPath' | 'link';

export interface ISendComplaintRequest {
  realtyObjectId: number;
  complaintOnSiteId: string;
  message?: string;
  userContextInfo: {
    uniqueId?: string | null;
    ip?: string;
  };
}

export interface ISendComplaintResponse {
  complaintId: number;
  getUserFeedback: boolean;
}

export interface ISendContactsRequest {
  name?: string;
  email: string;
  subject: EContactsSubject;
  message: string;
  files?: IContactsFile[];
  twoFactor?: boolean;
  fromLkHomeowner?: boolean;
  cadastralNumber?: string;
  announcementLink?: string;
}

export enum EContactsSubject {
  ErrorMessage = 'errorMessage',
  AdvertisingOffers = 'advertisingOffers',
  ModeratingOffers = 'moderatingOffers',
  AuthRegPass = 'authRegPass',
  PhotoOwner = 'photoOwner',
  ObjectOwner = 'objectOwner',
  Other = 'other',
}

export interface IContactsFile {
  name: string;
  content: string;
}

export interface ISendContactsResponse {
  status: string;
  data: ISendContactsResponseData;
}

interface IGetRegionsResponseData {
  items: IRegionInfo[];
}

export interface IGetRegionsResponse {
  status: string;
  data: IGetRegionsResponseData;
}
export interface IGetRegionResponse {
  status: string;
  data: IRegionInfo;
}

export interface ISendContactsResponseData {
  errors?: IContactsError[];
}

export interface IContactsError {
  name: string;
  message: string;
}

export interface IMobileWebsiteOptions {
  readonly config: Config;
  readonly chats?: IChatsConfig;
  readonly cookie?: string;
  readonly longTermStore?: ICacheStore;
  readonly memoryStore?: ICacheStore;
  readonly request?: express.Request;
  readonly demand?: {
    httpApiBaseUrl: string;
  };
}

export interface IRequestSubscribeNewResponse {
  auth: boolean;
  subscribe: boolean;
}

export interface IRegionInfo {
  id: number;
  baseHost?: string;
  fullName?: string;
  displayName?: string;
  name?: string;
  hasMetro?: boolean;
  hasHighway?: boolean;
  hasDistricts?: boolean;
  mainTownId?: number | null;
  parentId?: number;
  lat?: number;
  lng?: number;
  boundedBy?: IBoundedBy;
}

export interface IBoundedBy {
  lowerCorner: {
    lat: number;
    lng: number;
  };
  upperCorner: {
    lat: number;
    lng: number;
  };
}

export type SectionType =
  | 'index'
  | 'sale'
  | 'rent'
  | 'dailyRent'
  | 'commercial'
  | 'newBuildings'
  | 'newBuildingsMap'
  | 'map';

export interface IGetSuggestIdsBody {
  jsonQuery: IJsonQuery;
  offersIds: number[];
  pageNumber: number;
  queryString: string;
}

export interface IInfiniteSearchResult {
  itemId: number;
  modelVersion: number | string;
  score: number;
}

export interface IGetSuggestIdsResponse {
  infiniteSearchResult: IInfiniteSearchResult[];
}

export const InfrastructureCategory = {
  cafe: 'cafe' as const,
  clinic: 'clinic' as const,
  school: 'school' as const,
  grocery: 'grocery' as const,
  nursery: 'nursery' as const,
};

export type TInfrastructureCategory = keyof typeof InfrastructureCategory;

export interface ICreateDemandRequestParams {
  comment?: string;
  companyName?: string;
  email: string;
  fullName: string;
  hasAgentProfile?: boolean;
  isHomeowner?: boolean;
  jsonQuery: IJsonQuery | IDemandJsonQuery;
  phone: string;
  confirmationCode?: string;
  source: string;
}

export interface IHideOfferRequestScheme {
  offerId: number;
  dealType: 'sale' | 'rent';
  offerType: 'flat' | 'suburban' | 'commercial' | 'newobject';
  publishedUserId: number;
  houseId?: number;
}

export interface IRestoreOfferRequestScheme {
  offerId: number;
}

export interface IHideOffersSuccessResponseScheme {
  status: string;
}

export interface IHideOffersErrorResponseScheme {
  errors: object[];
  message: string;
}

export type THideOffersResponseScheme = IHideOffersSuccessResponseScheme | IHideOffersErrorResponseScheme;

export interface IRestoreOffersSuccessResponseScheme {
  status: string;
}

export interface IRestoreOffersErrorResponseScheme {
  errors: object[];
  message: string;
}

export type TRestoreOffersResponseScheme = IRestoreOffersSuccessResponseScheme | IRestoreOffersErrorResponseScheme;

export interface IComplaintFeedbackFormBody {
  email: string;
  complaintId: number;
}

export interface IRailwaysData {
  id: number;
  name: string;
}

export interface IReadAllOffersForSavedSearchRequestBody {
  savedSearchId: number;
}

export interface IReadAllOffersForSavedSearchResponseBody {
  status: string;
}

export interface IMortgageWidgetRequestParams {
  price_from: number | undefined;
}

export interface IGetOfferChatsParams {
  offerId: number;
  timeout: number;
  logger: ILogger | null;
}

export interface IGetOfferChatsEnables {
  offerId: number;
  dealType: TDealType;
  offerType: TOfferType;
}

export interface IGetOfferChatsStatusParams {
  params: IGetOfferChatsEnables;
  timeout: number;
  logger: ILogger | null;
}

export interface IGetRegionsByPointItemSchema {
  id: number;
  displayName: string;
  mainTownId: number | null;
}

export interface IGeoRegionByPointResponse {
  regionLocations: IGetRegionsByPointItemSchema[];
}

export enum EDealType {
  /** Аренда **/
  Rent = 'rent',
  /** Продажа **/
  Sale = 'sale',
}

export enum EOfferType {
  /** коммерческая **/
  Commercial = 'commercial',
  /** квартиры **/
  Flat = 'flat',
  /** новостройки **/
  Newobject = 'newobject',
  /** загородка **/
  Suburban = 'suburban',
}

export interface IGetFavoriteCountByOfferRequest {
  /** Cian offer ID **/
  cianOfferId: number;
  /** Тип сделки **/
  dealType: EDealType;
  /** Тип объявления **/
  offerType: EOfferType;
}

export interface IGetFavoriteCountByOfferResponse {
  /** Кол-во добавленного объекта в избранное у анонимного пользователя **/
  anonymous?: number | null;
  /** Кол-во добавленного объекта в избранное у зарегистрированного пользователя **/
  registered?: number | null;
  /** Общее кол-во добавленного объекта в избранное у всех пользователей **/
  total?: number | null;
}

export interface ISalesDescriptionSchema {
  text: string;
  salesType: ESalesType;
}

export enum ESalesType {
  SalesLeader = 'salesLeader',
  SalesStart = 'salesStart',
  UpcomingSale = 'upcomingSale',
}

/* istanbul ignore next */
export class MobileWebsiteApi {
  private network: NetworkLayer;
  private cacheInMemory: Memoized;
  private longTermCache: Memoized;
  private cookie: string;
  private chatsHttp: ChatsHttp | undefined;
  private chatsUserHttp: UserHttp | undefined;
  // @ts-ignore
  private messagesHttp: MessagesHttp | undefined;
  private demandHttpApiBaseUrl: string | undefined;
  private config: Config;

  public constructor(
    network: NetworkLayer,
    { config, memoryStore, longTermStore, cookie, chats, request, demand }: IMobileWebsiteOptions,
  ) {
    this.network = network;
    this.cacheInMemory = memoryStore ? createCacheFunction(memoryStore) : noMemoized;
    this.longTermCache = longTermStore ? createCacheFunction(longTermStore) : noMemoized;
    this.cookie = cookie || '';
    this.demandHttpApiBaseUrl = demand ? demand.httpApiBaseUrl : undefined;
    this.config = config;

    if (chats) {
      if (request) {
        this.chatsHttp = new ChatsNodeHttp({
          baseUri: parseURI(chats.httpApiServerUrl),
          client: this.getHttpApiAdapter(),
          headers: [],
        });
      } else {
        this.chatsHttp = new ChatsHttp({
          baseUri: parseURI(chats.httpApiBrowserUrl),
          client: this.getHttpApiAdapter(),
        });
      }

      if (request) {
        this.chatsUserHttp = new UserNodeHttp({
          baseUri: parseURI(chats.httpApiServerUrl),
          client: this.getHttpApiAdapter(),
          headers: [],
        });
      } else {
        this.chatsUserHttp = new UserHttp({
          baseUri: parseURI(chats.httpApiBrowserUrl),
          client: this.getHttpApiAdapter(),
        });
      }

      if (request) {
        this.messagesHttp = new MessagesNodeHttp({
          baseUri: parseURI(chats.httpApiServerUrl),
          client: this.getHttpApiAdapter(),
          headers: [],
        });
      } else {
        this.messagesHttp = new MessagesHttp({
          baseUri: parseURI(chats.httpApiBrowserUrl),
          client: this.getHttpApiAdapter(),
        });
      }
    }
  }

  public getQuickLinks(jsonQuery: IJsonQuery) {
    return this.network.json<IQuickLinksResponse>({
      method: 'POST',
      microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
      body: JSON.stringify({ jsonQuery }),
      url: '/cian-api/site/v1/get-quick-links/',
    });
  }

  public getSearchUrl(jsonQuery: IJsonQuery) {
    if (this.config.get<boolean>('frontendMobileWebsite.meta.next')) {
      return this.getSearchUrlNext(jsonQuery);
    }

    return this.cacheInMemory({
      key: 'getSearchUrl' + JSON.stringify(jsonQuery),
      getValue: () => {
        return this.network.json<IGetSearchUrlResponse>({
          method: 'POST',
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: '/cian-api/mobile-site/v1/search-offers/meta/',
          body: jsonQuery,
          withCredentials: true,
        });
      },
    });
  }

  public getSearchUrlNext(jsonQuery: IJsonQuery) {
    return this.cacheInMemory({
      key: 'getSearchUrl' + JSON.stringify(jsonQuery),
      getValue: () => {
        return fromPromise(
          toPromise(
            this.network.json<IGetSearchUrl>({
              method: 'POST',
              url: '/v1/get-meta/',
              microserviceApiName: isServer ? 'searchOffersIndexOnServer' : 'searchOffersIndexInBrowser',
              body: { jsonQuery },
              withCredentials: true,
            }),
          ).then(result => ({
            ...result,
            result: {
              status: 'ok',
              data: result.result,
            },
          })),
        );
      },
    });
  }

  public searchOffersNew = (body: ISearchOffersRequest) => {
    return this.cacheInMemory({
      key: 'searchOffersNew' + JSON.stringify(body),
      getValue: () => {
        return this.network.json<ISearchOffersResponse>({
          method: 'POST',
          url: '/v1/search-offers-mobile-site/',
          body,
          microserviceApiName: isServer ? 'searchOffersOnServer' : 'searchOffersInBrowser',
        });
      },
    });
  };

  public mapSearchOffers = (body: ISearchOffersRequest) => {
    return this.cacheInMemory({
      key: 'searchOffers' + JSON.stringify(body),
      getValue: () => {
        return this.network.json<ISearchOffersResponse>({
          method: 'POST',
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: '/cian-api/mobile-site/v1/map-search-offers/',
          body,
        });
      },
    });
  };

  public getFavoritesCountByOffer = (body: IGetFavoriteCountByOfferRequest) => {
    return this.network.json<IGetFavoriteCountByOfferResponse>({
      method: 'POST',
      url: 'v1/get-favorite-count-by-offer/',
      body,
      absoluteUrl: true,
      microserviceApiName: 'favorites',
    });
  };

  public getSuggestIds = (body: IGetSuggestIdsBody): Promise<IInfiniteSearchResult[]> => {
    return toPromise(
      this.network.json<IGetSuggestIdsResponse>({
        method: 'POST',
        url: 'v1/get-infinite-search-result-mobile-site/',
        body,
        absoluteUrl: true,
        microserviceApiName: isServer ? 'searchOffersOnServer' : 'searchOffersInBrowser',
      }),
    ).then(response => response.result.infiniteSearchResult);
  };

  public getGeoSuggest = (params: IGeoSuggestRequestScheme) => {
    const { query, regionId, offerType, dealType = 'sale', suburbanOfferFilter = ESuburbanOfferFilter.Any } = params;

    return toPromise(
      this.network.json<IGeoSuggestResponseScheme>({
        method: 'GET',
        url: `v1/suggest/?query=${query}&regionId=${regionId}&offerType=${offerType}&dealType=${dealType}&suburbanOfferFilter=${suburbanOfferFilter}&source=mobileWebsite`,
        absoluteUrl: true,
        microserviceApiName: 'geoSuggest',
      }),
    ).then(response => {
      if (response.result.status && response.result.status === 'ok') {
        return response.result.data;
      }

      return null;
    });
  };

  public getOffersByIds = (cianOfferIds: number[]) => {
    return this.network.json<IOffersByIdsResponse>({
      method: 'POST',
      microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
      url: '/cian-api/mobile-site/v1/get-offers-by-ids/',
      body: {
        cianOfferIds,
      },
    });
  };

  public seoMain() {
    return this.longTermCache({
      key: 'seoMain',
      getValue: () => {
        return this.network.json<ISeoMainResponse>({
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: '/cian-api/mobile-site/v1/get-mainpage-seo-data/',
        });
      },
    });
  }

  public seoSection(section: SectionType) {
    return this.longTermCache({
      key: `seoSection-${section}`,
      getValue: () => {
        return this.network.json<ISeoMainResponse>({
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: `/cian-api/mobile-site/v1/get-sections-seo-data/?sectionType=${section}`,
          timeout: this.config.get<number>('frontendMobileWebsite.seoSection.timeout') || 3000,
        });
      },
    });
  }

  public geoSeoLinks() {
    return this.longTermCache({
      key: 'geoSeoLinks',
      getValue: () => {
        return this.network.json<IGeoSeoLinksResponse>({
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: '/cian-api/mobile-site/v1/get-mainpage-geo-seo-links/',
        });
      },
    });
  }

  public promoBlocks(sectionType: string) {
    return this.longTermCache({
      key: `promoBlocksForSection:${sectionType}`,
      getValue: () => {
        return this.network.json<IPromoBlocksResponse>({
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: `/cian-api/mobile-site/v1/get-sections-promo-blocks/?sectionType=${sectionType}`,
        });
      },
    });
  }

  public logToken(token: string) {
    return this.network.json<ILogTokenResponse>({
      method: 'POST',
      url: '/ajax/log_token/',
      body: { token },
    });
  }

  public sendOfferToBI(dealType: string, objectType: string, id: string) {
    return this.network.json<IOfferToBIResponse>({
      url: `/ajax/phone_show/mobile/${dealType}/${objectType}/${id}/`,
    });
  }

  public setOfferFavoriteStatus(dealType: string, objectType: string, offerId: number, state: boolean) {
    return this.network.json<IAddOfferToFavorites>({
      method: 'POST',
      url: `/ajax/${dealType}/${objectType}/favorites`,
      body: {
        action: state ? 'assign' : 'remove',
        oid: offerId,
      },
    });
  }

  public subscribe(body: ISubscribeRequest): Promise<IRequestSubscribeNewResponse> {
    return toPromise<ISubscribeResponse>(
      this.network.json({
        method: 'POST',
        url: '/ajax/subscriptions',
        body: {
          action: 'subscribe',
          title: body.title || document.title,
          filter_params: '?' + body.queryString,
          email: body.email,
        },
      }),
    ).then(res => ({
      auth: false,
      subscribe: Boolean(res.result.status && res.result.status === 'ok'),
    }));
  }

  public subscribeNew(parameters: ISubscribeRequest): Promise<IRequestSubscribeNewResponse> {
    const generatedJsonQuery = parameters.jsonQuery;

    const httpApi = this.network.getHttpApi();
    const requestContext = { httpApi };
    const requestParameters = {
      title: parameters.title,
      email: parameters.email,
      subscribeNews: parameters.subscribeNews,
      source: parameters.source as TSource,
      notificationFrequency: parameters.notificationFrequency as any,
    };

    const requestSubscribeSearch = (): Promise<ICianApiSiteV1CreateSubscriptionResponse200> => {
      if (generatedJsonQuery) {
        return createSubscription(requestContext, {
          ...requestParameters,
          jsonQuery: generatedJsonQuery,
          savedSearchType: ESavedSearchType.BuildingAddress,
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return toPromise(this.getJsonQuery(parameters.queryString!)).then(res => {
          const jsonQuery = res.result.data.jsonQuery;

          return createSubscription(requestContext, {
            ...requestParameters,
            jsonQuery,
          });
        });
      }
    };

    return requestSubscribeSearch().then(res => {
      const logonInfo = res.response.data && res.response.data.logonInfo;
      const subscribe = res.response.status && res.response.status === 'ok';
      if (logonInfo && logonInfo.logOnInfo) {
        return Promise.all(
          logonInfo.logOnInfo.map(data => {
            return toPromise(
              this.userLogon({
                login: logonInfo.login || '',
                token: data.token || '',
                persistent: true,
                url: data.logOnUrl || '',
              }),
            );
          }),
        ).then(() => {
          return {
            subscribe,
            auth: true,
          };
        });
      } else {
        return {
          subscribe,
          auth: false,
        };
      }
    });
  }

  public subscribePrice({ email, offerId, dealType, offerType, subscribeNews }: ISubscribePriceRequest) {
    return this.network.json<ISubscribeResponse>({
      method: 'POST',
      url: '/cian-api/mobile-site/v1/change-price-subscribe/',
      microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
      body: { email, offerId, dealType, offerType, subscribeNews },
    });
  }

  public geocodeCached(text: string) {
    return this.network.json<IGeocodeCachedResponse>({
      url: `/api/geo/geocode-cached/?request=${encodeURIComponent(text)}`,
    });
  }

  public getHeaderData(region: IUserRegion, customGetHeaderHost?: string) {
    const body = {};
    let reqConfig: IRequestConfiguration = {
      url: isServer ? '/v1/get-header/' : `${customGetHeaderHost || ''}/header/v1/get-header/`,
      absoluteUrl: !!customGetHeaderHost,
      body: {
        locationId: region.id,
        subdomain: region.subdomain,
      },
      microserviceApiName: isServer ? 'headerOnServer' : undefined,
      withCredentials: true,
    };

    if (Object.keys(body).length > 0) {
      reqConfig = {
        ...reqConfig,
        body,
      };
    }

    return this.network.json<IHeaderDataResponse>(reqConfig);
  }

  public geocode(body: IGeocodeRequest) {
    return this.network.json<IGeocodeResponse>({
      method: 'POST',
      url: '/api/geo/geocoded-for-search/',
      body: {
        Lat: body.lat,
        Lng: body.lng,
        Kind: body.kind,
        Address: body.address,
      },
    });
  }

  public getGeoTagsByQueryString(queryString: string, subdomain: string, logger: ILogger) {
    if (this.config.get<boolean>('frontendMobileWebsite.getGeoTagsByQueryString.next')) {
      return this.getGeoTagsByQueryStringNext(queryString, subdomain, logger);
    }

    return this.cacheInMemory({
      key: 'getGeoTagsByQueryString' + queryString,
      getValue: () => {
        return this.network.json<IGeoTagResponse>({
          method: 'POST',
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: '/cian-api/mobile-site/v1/query-to-geo-tags/',
          body: { queryString },
        });
      },
    });
  }

  public getGeoTagsByQueryStringNext(queryString: string, subdomainName: string, logger: ILogger) {
    return this.cacheInMemory({
      key: `getGeoTagsByQueryString_${queryString}_${subdomainName}`,
      getValue: () => {
        return fromPromise(
          toPromise(
            this.network.json<IGeoTags>({
              method: 'POST',
              url: '/v1/get-geo-tags/',
              microserviceApiName: isServer ? 'geoTempLayerOnServer' : 'geoTempLayerInBrowser',
              body: { queryString, subdomainName },
            }),
          )
            .then(response => ({
              ...response,
              result: {
                status: 'ok',
                data: {
                  ...response.result,
                  // tslint:disable-next-line:no-any
                  geoTags: Object.keys(response.result.geoTags).reduce((result: any, key) => {
                    // tslint:disable-next-line:no-any
                    const value = (response.result.geoTags as any)[key];

                    return {
                      ...result,
                      [snakeCase(key)]: value,
                    };
                  }, {}),
                },
              },
            }))
            .catch(err => {
              if (logger) {
                if (isTimeoutError(err)) {
                  logger.error('getGeoTagsByQueryStringNext timeout', err);
                } else {
                  logger.error('getGeoTagsByQueryStringNext', err);
                }
              }
            }),
        );
      },
    });
  }

  public getJsonQuery(queryString: string) {
    if (this.config.get<boolean>('frontendMobileWebsite.getJsonQuery.next')) {
      return this.getJsonQueryNext(queryString);
    }

    return this.cacheInMemory({
      key: 'getJsonQuery' + queryString,
      getValue: () => {
        return this.network.json<IGetJsonQueryResponse>({
          method: 'POST',
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: '/cian-api/site/v1/querystring/tojsonquery/',
          body: { querystring: queryString },
        });
      },
    });
  }

  public getJsonQueryNext(queryString: string) {
    return this.cacheInMemory({
      key: 'getJsonQuery' + queryString,
      getValue: () => {
        return fromPromise(
          toPromise(
            this.network.json<IGetJsonQueryData>({
              method: 'POST',
              url: '/v1/querystring-to-jsonquery/',
              microserviceApiName: isServer ? 'searchOffersIndexOnServer' : 'searchOffersIndexInBrowser',
              body: { querystring: queryString },
            }),
          ).then(response => ({
            ...response,
            result: {
              status: 'ok',
              data: response.result,
            },
          })),
        );
      },
    });
  }

  public getRegionsBySubdomain(subdomain: string, logger: ILogger): Observable<void | Response<IRegionsResponse>> {
    if (this.config.get<boolean>('frontendMobileWebsite.getRegionsBySubdomain.next')) {
      return this.getRegionsBySubdomainNext(subdomain, logger);
    }

    return this.longTermCache({
      key: 'getRegionsBySubdomain' + subdomain,
      getValue: () => {
        return this.network.json<IRegionsResponse>({
          method: 'POST',
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: '/cian-api/mobile-site/v1/get-mainpage-regions/',
          body: { subdomain },
        });
      },
    });
  }

  public getRegionsBySubdomainNext(subdomain: string, logger: ILogger) {
    return this.longTermCache({
      key: 'getRegionsBySubdomain' + subdomain,
      getValue: () => {
        return fromPromise(
          toPromise(
            this.network.json<IRegionsResponseData>({
              method: 'GET',
              url: `/v1/get-mainpage-regions/?subdomain=${subdomain}`,
              microserviceApiName: isServer ? 'geoTempLayerOnServer' : 'geoTempLayerInBrowser',
            }),
          )
            .then(response => ({
              ...response,
              result: {
                status: 'ok',
                data: response.result,
              },
            }))
            .catch(err => {
              if (isTimeoutError(err)) {
                logger.error(err, {
                  message: 'getRegionsBySubdomainNext timeout',
                  domain: 'src/mobile_website/api/api.ts',
                });

                throw err;
              }

              if (err.meta.response.statusCode < 500) {
                logger.warning(err, {
                  message: 'getRegionsBySubdomainNext warning',
                  domain: 'src/mobile_website/api/api.ts',
                  errorInfo: JSON.stringify(err.meta),
                });

                throw err;
              }

              logger.error(err, {
                message: 'getRegionsBySubdomainNext error',
                domain: 'src/mobile_website/api/api.ts',
                errorInfo: JSON.stringify(err.meta),
              });

              throw err;
            }),
        );
      },
    });
  }

  public searchRegions(text: string) {
    return this.longTermCache({
      key: 'searchRegions' + text,
      getValue: () => {
        return this.network.json<ISearchRegionsResponse>({
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: `/cian-api/mobile-site/v1/search-regions-cities/?text=${encodeURIComponent(text)}`,
        });
      },
    });
  }

  public getRegionSettingsByRegionId(subdomain: string, region_id: number) {
    if (this.config.get<boolean>('frontendMobileWebsite.getRegionSettingsByRegionId.next')) {
      return this.getRegionSettingsByRegionIdNext(subdomain, region_id);
    }

    return this.network.json<ISettingsResponse>({
      method: 'POST',
      microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
      url: '/cian-api/mobile-site/v1/settings/',
      body: {
        subdomain,
        region_id: patchIdForCompositeRegion(region_id),
      },
    });
  }

  public getRegionSettingsByRegionIdNext(subdomain: string, region_id: number) {
    return fromPromise(
      toPromise(
        this.network.json<ISettingsResponseData>({
          method: 'GET',
          url: `/v1/get-mobile-site-settings/?subdomain=${subdomain}&regionId=${region_id}`,
          microserviceApiName: isServer ? 'geoTempLayerOnServer' : 'geoTempLayerInBrowser',
        }),
      ).then(response => ({
        ...response,
        result: {
          status: 'ok',
          data: response.result,
        },
      })),
    );
  }

  public getOffer(dealType: string, objectType: string, id: string) {
    const headers = this.cookie ? ([['Cookie', this.cookie]] as [string, string][]) : undefined;

    const monolithUrl = (this.network.getConfig() as any).api.microservicesUrls[
      isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser'
    ];

    const host = monolithUrl.replace(HOST_REGEX, '');
    const scheme = /(https?):\/\//.exec(monolithUrl);

    if (host) {
      return this.network.getHttpApi().httpRequest({
        method: 'GET',
        uri: {
          host,
          path: `/cian-api/mobile-site/v1/offer/${dealType}/${objectType}/${id}/`,
          scheme: scheme ? (scheme[1] as 'http' | 'https') : 'http',
        },
        headers,
      });
    }

    return Promise.reject('Env baseUrl not configured');
  }

  public getSimilarOffers(id: string | number) {
    return this.network.json<ISimilarOffersResponse>({
      microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
      url: `/cian-api/mobile-site/v2/get-similar-offers-by-offer-id/?cianOfferId=${id}`,
    });
  }

  public getGK(body: IGetGKRequest) {
    return this.cacheInMemory({
      key: 'searchGKs' + JSON.stringify(body),
      getValue: () => {
        return this.network.json<IGetGKResponse>({
          method: 'POST',
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: '/cian-api/mobile-site/v1/newobject/',
          body,
        });
      },
    });
  }

  public readAllOffersForSavedSearch = (body: IReadAllOffersForSavedSearchRequestBody) => {
    return this.network.json<IReadAllOffersForSavedSearchResponseBody>({
      method: 'POST',
      url: '/v1/delayed-mark-saved-search-as-read-desktop/',
      absoluteUrl: true,
      body,
      microserviceApiName: isServer ? 'savedSearchesOnServer' : 'savedSearchesInBrowser',
    });
  };

  public getSimilarGKOffers(offerId: number) {
    return this.network.json<IGKMVKDataResponse>({
      method: 'GET',
      url: `/v1/get-newbuilding-similar-offers/?offerId=${offerId}`,
      absoluteUrl: true,
      microserviceApiName: isServer ? 'newbuildingMvkServerUrl' : 'newbuildingMvk',
    });
  }

  public sendComplaintFeedbackForm(body: IComplaintFeedbackFormBody) {
    return this.network
      .json<{}>({
        method: 'POST',
        url: '/v1/add-feedback-request/',
        absoluteUrl: true,
        microserviceApiName: 'moderationComplaints',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: false,
      })
      .toPromise();
  }

  public getUserGaDataLayer() {
    return this.network.json<IUserGaDataLayerResponse>({
      method: 'GET',
      url: '/cian-api/site/v1/get-user-ga-data-layer-data/',
      microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
      timeout: this.config.get<number>('frontendMobileWebsite.getUserGaDataLayer.timeout') || 3000,
    });
  }

  public getRegionMeta(region: number, logger: ILogger) {
    if (this.config.get<boolean>('frontendMobileWebsite.getRegionMeta.next')) {
      return this.getRegionMetaNext(region, logger);
    }

    return this.longTermCache({
      key: 'getRegionMeta' + region,
      getValue: () => {
        return this.network.json<IRegionMetaResponse>({
          method: 'GET',
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: `/cian-api/mobile-site/v1/region_meta/?locationId=${region}`,
        });
      },
    });
  }

  public getRegionMetaNext(region: number, logger: ILogger) {
    return this.longTermCache({
      key: 'getRegionMeta_next' + region,
      getValue: () => {
        return fromPromise(
          toPromise(
            this.network.json<IRegionMetaData>({
              method: 'GET',
              url: `/v1/get-region-meta/?locationId=${region}`,
              microserviceApiName: isServer ? 'geoTempLayerOnServer' : 'geoTempLayerInBrowser',
            }),
          )
            .then(response => ({
              ...response,
              result: {
                status: 'ok',
                data: response.result,
              },
            }))
            .catch(err => {
              if (isTimeoutError(err)) {
                logger.error(err, {
                  message: 'getRegionMetaNext timeout',
                  domain: 'src/mobile_website/api/api.ts',
                });
              } else {
                logger.error(err, {
                  message: 'getRegionMetaNext error',
                  domain: 'src/mobile_website/api/api.ts',
                });
              }

              throw err;
            }),
        );
      },
    });
  }

  public getComplaintsTree(category: number, region: number) {
    return this.network.json<IComplaintsTreeItem[]>({
      microserviceApiName: 'moderationComplaints',
      url: `/v2/get-complaint-types/?categoryId=${category}&regionId=${region}`,
      withCredentials: true,
    });
  }

  public sendComplaint(complaint: ISendComplaintRequest) {
    return this.network.json<ISendComplaintResponse>({
      microserviceApiName: 'moderationComplaints',
      method: 'POST',
      url: '/v2/create-complaint/',
      body: complaint,
      withCredentials: true,
    });
  }

  public getRoads() {
    return this.network.json<IRoadsResultData[]>({
      method: 'GET',
      url: `/api/geo/get-roads/?regionId=${Regions.MoscowRegion}`,
      microserviceApiName: isServer ? 'monolithCianRealtyServerUrl' : undefined,
    });
  }

  public getFavoritesIDs() {
    return this.network.json<IFavouritesIDsResponse>({
      method: 'GET',
      microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
      url: `/cian-api/mobile-site/v1/favorites/map/ids/`,
    });
  }

  public sendContacts(contacts: ISendContactsRequest) {
    return this.network.json<ISendContactsResponse>({
      method: 'POST',
      microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
      url: '/cian-api/mobile-site/v1/contacts/',
      body: contacts,
    });
  }

  public getRegions(): Observable<Response<IGetRegionsResponse>> {
    if (this.config.get<boolean>('frontendMobileWebsite.getRegions.next')) {
      return this.getRegionsNext();
    }

    return this.longTermCache({
      key: 'getRegions_v1',
      getValue: () => {
        return this.network.json<IGetRegionsResponse>({
          method: 'GET',
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: '/cian-api/site/v1/get-regions/',
        });
      },
    });
  }

  public getRegionsNext() {
    return this.longTermCache({
      key: 'getRegions_v1',
      getValue: () => {
        return fromPromise(
          toPromise(
            this.network.json<IGetRegionsResponseData>({
              method: 'GET',
              url: `/v1/get-federal-subjects-of-russia/`,
              microserviceApiName: isServer ? 'geoTempLayerOnServer' : 'geoTempLayerInBrowser',
            }),
          ).then(response => ({
            ...response,
            result: {
              status: 'ok',
              data: response.result,
            },
          })),
        );
      },
    });
  }

  public getRegion(regionId: string) {
    return this.longTermCache({
      key: `getRegions${regionId}`,
      getValue: () => {
        return this.network.json<IGetRegionResponse>({
          method: 'GET',
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: `/cian-api/site/v1/get-region/?regionId=${regionId}`,
        });
      },
    });
  }

  public getRegionCities(regionId: number) {
    return this.longTermCache({
      key: `getRegionCities_${regionId}`,
      getValue: () => {
        return this.network.json<IGetRegionCitiesResponse>({
          method: 'GET',
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
          url: `/cian-api/mobile-site/v1/get-region-cities?regionId=${regionId}`,
        });
      },
    });
  }

  public newbuildingsSearchMap = (queryString: string) => {
    return this.network.json<INewbuildingSearchMapResponse>({
      microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
      url: `/cian-api/mobile-site/v1/newbuildings/clusters/?${queryString}`,
    });
  };

  public fetchFavorites(options: IFavoritesOptions | undefined = defaultFavoritesState.options) {
    return this.network.json<IGetFavoritesResponseBody>({
      method: 'POST',
      url: '/cian-api/site/v1/get-favorites-v3/',
      microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
      body: {
        query: options,
      },
    });
  }

  public userGetResetPasswordData(userId: string, token: string) {
    return this.network.json<IUserGetResetPasswordDataResponse>({
      method: 'GET',
      microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
      url: `/cian-api/site/v1/user-get-reset-password-data/?userId=${userId}&token=${token}`,
    });
  }

  public validatePasswordComplexity(password: string) {
    return this.network.json<IServerResponse>({
      method: 'POST',
      microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
      url: '/cian-api/site/v1/validate-password/',
      body: {
        password,
      },
    });
  }

  /** Логонит пользователя по токену - устанавливает аутентификационную куку, если токен правильный */
  public userLogon({ login, token, persistent, url }: IUserLogonRequest) {
    const serializedParams = serializeParams(
      {
        login,
        token,
        persistent,
      },
      true,
    );

    return this.network.text({
      method: 'GET',
      jsonp: true,
      url: `${url}?${serializedParams}`,
    });
  }

  /** Присылает урлы для разлогина */
  public getUserLogoutUrls() {
    return this.network.json<IUserLogoutUrlsResponse>({
      method: 'GET',
      url: `/api/users/logout-urls`,
    });
  }

  public logout(url: string) {
    return this.network.text({
      method: 'GET',
      url: `https:${url}`,
      jsonp: true,
    });
  }

  public resetPassword(data: { newPassword: string; confirmationCode?: string; userId: string; token: string }) {
    return this.network.text({
      method: 'POST',
      url: '/api/users/reset-password/',
      body: {
        UserId: data.userId,
        token: data.token,
        passwordA: data.newPassword,
        passwordB: data.newPassword,
        confirmationCode: data.confirmationCode,
      },
    });
  }

  public sendConfirmationCode(body: { userId: string; token: string }) {
    return this.network.text({
      method: 'POST',
      url: '/api/users/reset-password/send-confirmation-code/',
      body,
    });
  }

  /* istanbul ignore next */
  public getOfferChatsStatus({ params, timeout, logger }: IGetOfferChatsStatusParams): Promise<boolean> {
    if (!this.chatsUserHttp) {
      return Promise.resolve(false);
    }

    const { offerId, dealType, offerType } = params;

    return timeoutPromise(this.chatsUserHttp.getOfferChatsStatusV2(offerId, offerType, dealType), timeout)
      .then(({ data: { enabled: isOfferChatsEnabled } }) => {
        return isOfferChatsEnabled;
      })
      .catch(error => {
        if (logger) {
          if (isTimeoutError(error)) {
            logger.warning(error, {
              message: 'getOfferChatsStatus timeout',
              domain: 'src/mobile_website/api/api.ts',
            });
          } else {
            logger.error(error, {
              message: 'getOfferChatsStatus error',
              domain: 'src/mobile_website/api/api.ts',
            });
          }
        }

        return false;
      });
  }

  public getOfferChat({ logger, offerId, timeout }: IGetOfferChatsParams): Promise<string | null> {
    if (!this.chatsHttp) {
      return Promise.resolve(null);
    }

    return timeoutPromise(this.chatsHttp.getOfferChat(offerId), timeout)
      .then(({ data: { chatId } }) => {
        return chatId;
      })
      .catch(error => {
        const meta = {
          message: error.message,
          stackTrace: error.stack,
          meta: error.errors,
        };

        if (logger) {
          if (isTimeoutError(error)) {
            logger.warning('Failed to load v1/chats/offer/', meta);
          } else {
            if (!['offerChatNotFound', 'userUnauthorized'].includes(error.message)) {
              logger.error('Error occurred: v1/chats/offer/', meta);
            }
          }
        }

        return null;
      });
  }

  public getSpecialPromo(builderId: number) {
    return this.network.json<ISpecialPromo>({
      method: 'GET',
      url: `/v1/get-special-promo/?builderId=${builderId}`,
      absoluteUrl: true,
      microserviceApiName: isServer ? 'specialPromosOnServer' : 'specialPromosInBrowser',
    });
  }

  public getSpecialPromosInfo() {
    return this.network.json<IGetSpecialPromosInfoResponse>({
      method: 'GET',
      absoluteUrl: true,
      microserviceApiName: isServer ? 'specialPromosOnServer' : 'specialPromosInBrowser',
      url: '/v1/get-special-promo-thumbs/',
    });
  }

  public getRegionBySubdomain(
    subdomain: string,
    logger: ILogger,
  ): Observable<void | Response<IGetRegionBySubdomainResponse>> {
    if (this.config.get<boolean>('frontendMobileWebsite.getRegionBySubdomain.next')) {
      return this.getRegionBySubdomainNext(subdomain, logger);
    }

    return this.longTermCache({
      key: `getRegionBySubdomain_${subdomain}`,
      getValue: () => {
        return this.network.json<IGetRegionBySubdomainResponse>({
          method: 'GET',
          url: `/cian-api/site/v1/get-region-by-domain/?domain=${subdomain}`,
          microserviceApiName: isServer ? 'monolithPythonOnServer' : 'monolithPythonInBrowser',
        });
      },
    });
  }

  public getRegionBySubdomainNext(subdomain: string, logger: ILogger) {
    return this.longTermCache({
      key: `getRegionBySubdomain_${subdomain}`,
      getValue: () => {
        return fromPromise(
          toPromise(
            this.network.json<IGetRegionBySubdomainResponseData>({
              method: 'GET',
              url: `/v1/get-extended-location-by-subdomain/?subdomain=${subdomain}`,
              microserviceApiName: isServer ? 'geoTempLayerOnServer' : 'geoTempLayerInBrowser',
            }),
          )
            .then(response => ({
              ...response,
              result: {
                status: 'ok',
                data: response.result,
              },
            }))
            .catch(err => {
              if (isTimeoutError(err)) {
                logger.error(err, {
                  message: 'getRegionBySubdomainNext timeout',
                  domain: 'src/mobile_website/api/api.ts',
                });

                throw err;
              }

              if (err.meta.response.statusCode < 500) {
                logger.warning(err, {
                  message: 'getRegionBySubdomainNext warning',
                  domain: 'src/mobile_website/api/api.ts',
                  errorInfo: JSON.stringify(err.meta),
                });

                throw err;
              }

              logger.error(err, {
                message: 'getRegionBySubdomainNext error',
                domain: 'src/mobile_website/api/api.ts',
                errorInfo: JSON.stringify(err.meta),
              });

              throw err;
            }),
        );
      },
    });
  }

  public getLocationByRegion(regionId: number) {
    if (this.config.get<boolean>('frontendMobileWebsite.getLocation.next')) {
      return this.getLocationByRegionNext(regionId);
    }

    return this.longTermCache({
      key: `getLocationByRegion_${regionId}`,
      getValue: () => {
        return this.network.json<IGetLocationByRegionResponse>({
          method: 'GET',
          url: `/cian-api/site/v1/get-location/?regionId=${regionId}`,
        });
      },
    });
  }

  public getLocationByRegionNext(regionId: number) {
    return this.longTermCache({
      key: `getLocationByRegion_${regionId}`,
      getValue: () => {
        return fromPromise(
          toPromise(
            this.network.json<IGetLocationByRegionResponseNext>({
              method: 'GET',
              url: `/v1/get-search-catalog-location/?currentLocationId=${regionId}`,
              microserviceApiName: isServer ? 'geoTempLayerOnServer' : 'geoTempLayerInBrowser',
            }),
          ).then(response => ({
            ...response,
            result: {
              status: 'ok',
              data: response.result,
            },
          })),
        );
      },
    });
  }

  public getCurrentLocation(path: string, subdomain: string) {
    if (this.config.get<boolean>('frontendMobileWebsite.getCurrentLocation.next')) {
      return this.getCurrentLocationNext(path, subdomain);
    }

    return this.longTermCache({
      key: `getCurrentLocation_${path}`,
      getValue: () => {
        return this.network.json<IGetCurrentLocationResponse>({
          method: 'POST',
          url: '/cian-api/site/v1/get-current-roots-v2/',
          body: JSON.stringify({ path }),
        });
      },
    });
  }

  public getCurrentLocationNext(path: string, subdomain: string) {
    return this.longTermCache({
      key: `getCurrentLocation_next_${path}_${subdomain}`,
      getValue: () => {
        return fromPromise(
          toPromise(
            this.network.json<ICurrentLocation>({
              method: 'POST',
              url: '/v1/get-geo-roots/',
              microserviceApiName: isServer ? 'searchOffersIndexOnServer' : 'searchOffersIndexInBrowser',
              body: JSON.stringify({ path, subdomain }),
            }),
          ).then(response => ({
            ...response,
            result: {
              status: 'ok',
              data: response.result,
            },
          })),
        );
      },
    });
  }

  public postHideOffer(parameters: IHideOfferRequestScheme) {
    return this.network.json<THideOffersResponseScheme>({
      method: 'POST',
      url: '/v1/hide-offer-website/',
      absoluteUrl: true,
      microserviceApiName: 'hiddenObjects',
      body: parameters,
      withCredentials: true,
    });
  }

  public postRestoreOffer(parameters: IRestoreOfferRequestScheme) {
    return this.network.json<TRestoreOffersResponseScheme>({
      method: 'POST',
      url: '/v1/restore-offer-website/',
      absoluteUrl: true,
      microserviceApiName: 'hiddenObjects',
      body: parameters,
      withCredentials: true,
    });
  }

  public addJkToFavourites(jkId: number) {
    return this.network.json({
      method: 'POST',
      url: '/cian-api/mobile-site/v1/add-newbuilding-to-favorites/',
      body: JSON.stringify({ newbuildingId: jkId }),
    });
  }

  public removeJkFromFavourites(jkId: number) {
    return this.network.json({
      method: 'POST',
      url: '/cian-api/mobile-site/v1/remove-newbuilding-from-favorites/',
      body: JSON.stringify({ newbuildingId: jkId }),
    });
  }

  public getPriceSubcriptionStatus(offerId: string) {
    return this.network.json<ISubscriptionStatus | ISubscriptionStatusError>({
      method: 'GET',
      absoluteUrl: true,
      microserviceApiName: isServer ? 'subscriptionsServerUrl' : 'subscriptions',
      url: `/v1/get-user-subscriptions-by-offer/?offerId=${offerId}`,
      withCredentials: true,
    });
  }

  public getRegionsByPoint(lat: number, lng: number) {
    return this.network.json<IGeoRegionByPointResponse>({
      method: 'GET',
      absoluteUrl: true,
      microserviceApiName: 'geo',
      url: `/v1/get-regions-by-point/?lat=${lat}&lng=${lng}`,
    });
  }

  public createDemandRequest(params: ICreateDemandRequestParams) {
    if (this.demandHttpApiBaseUrl) {
      const scheme = /(https?):\/\//.exec(this.demandHttpApiBaseUrl);

      return this.network.getHttpApi().httpRequest({
        method: 'POST',
        uri: {
          host: this.demandHttpApiBaseUrl.replace(HOST_REGEX, ''),
          path: '/v2/create-message/',
          scheme: scheme ? (scheme[1] as 'http' | 'https') : 'http',
        },
        body: JSON.stringify(params),
      });
    }

    return Promise.reject('cant call demand api');
  }

  public getMortgageCalculatorWidget(gkParams: IMortgageWidgetRequestParams) {
    return this.network.text({
      method: 'POST',
      timeout: this.getTimeOut(),
      absoluteUrl: true,
      microserviceApiName: 'mortgageCalculatorWidget',
      url: '',
      body: gkParams,
    });
  }

  public createUpcomingSaleRequest(body: ICreateUpcomingSaleRequestRequest) {
    return this.network.json<ICreateUpcomingSaleRequestResponse>({
      method: 'POST',
      absoluteUrl: true,
      microserviceApiName: 'newbuildingFlatViewOrder',
      url: '/v1/create-upcoming-sale-request/',
      body,
    });
  }

  private getTimeOut(): number {
    if (typeof window !== 'undefined') {
      return 2000;
    } else {
      return 500;
    }
  }

  private getHttpApiAdapter() {
    const httpApi = this.network.getHttpApi();

    return {
      makeRequest: (config: IHTTPRequestConfig, ecnhancersConfig?: IUserLayersConfig) =>
        httpApi.httpRequest({ ...config, withCredentials: true }, ecnhancersConfig),
    };
  }
}
