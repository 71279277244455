export interface IFeedbackComplaintState {
  status: EFeedbackComplaint;
  complaintId: number | undefined;
  getUserFeedback: boolean | undefined;
}

export enum EFeedbackComplaint {
  default,
  loading,
  success,
  error,
}
