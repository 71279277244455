import { IStyleConfig } from '@cian/components';
import * as React from 'react';

import {
  ButtonRadioGroupField as NativeRadioGroup,
  ButtonRadioGroupFieldFit,
} from '@cian/components';

const styles = require('./button_radio_group_field.css');

type Value = string | number | boolean;

export interface IButtonRadioGroupFieldValue {
    value: Value;
    label: React.ReactNode;
    disabled?: boolean;
    uncheckable?: boolean;
}
export interface IButtonRadioGroupFieldProps {
    activeButtonStyle?: IStyleConfig;
    disabledButtonStyle?: IStyleConfig;
    buttonStyle?: IStyleConfig;
    containerStyle?: IStyleConfig;
    fit?: ButtonRadioGroupFieldFit;
    emptyValue?: Value;
    invalid?: boolean;
    disabled?: boolean;
    onValueChange: (value: Value) => void;
    required?: boolean;
    uncheckable?: boolean;
    value?: Value;
    values: IButtonRadioGroupFieldValue[];
}

const ButtonRadioGroupField: React.SFC<IButtonRadioGroupFieldProps> = (props) => {
  return (
    <NativeRadioGroup
      fit={ButtonRadioGroupFieldFit.CONTAINER}
      {...props as any /* tslint:disable-line:no-any */}
      containerStyle={[styles.container, props.containerStyle]}
      buttonStyle={[styles.button, props.buttonStyle]}
    />
  );
};

export default ButtonRadioGroupField;
