import { Label } from '@cian/components';

import * as React from 'react';
import { connect } from 'react-redux';

import { commercialObjectType, FilterState } from '../../../redux/filters/model';
import { ReduxState } from '../../../redux/model';
import { shallowEqual } from '../../../utils/react_helpers';

import RangeComponent from '../../common/range/range';

import { IFieldProps, isNotUpcomingSale, IsVisibleCheck } from '../field';

import { getMetrics } from './helpers';
import { CoworkingPriceTypeRadioButtons } from './internal/components/CoworkingPriceTypeRadioButtons';

const styles = require('../field.css');

export interface IComponentProps {
  isCoworking?: boolean;
}

export type TComponentBase = IFieldProps & IComponentProps;

export class ComponentBase extends React.Component<TComponentBase, object> {

  public shouldComponentUpdate(nextProps: IFieldProps) {
    const filter: FilterState = this.props.filter;
    const nextFilter: FilterState = nextProps.filter;

    return !shallowEqual(filter.price, nextFilter.price)
      || filter.selectedObjectTypes !== nextFilter.selectedObjectTypes
      || filter.currency !== nextFilter.currency
      || filter.price !== nextFilter.price
      || filter.dealType !== nextFilter.dealType;
  }

  public render() {
    const { isCoworking } = this.props;

    return (
      <div className={styles['label']} data-mark="FieldPrice">
        <Label label="Цена">
          <div className={styles['field-price-content']}>
            {isCoworking && (
              <div
                data-testId="coworking-price-type-radio-buttons-container"
                className={styles['coworking-price-type-radio-buttons-container']}
              >
                <CoworkingPriceTypeRadioButtons />
              </div>
            )}
            <RangeComponent
              field="price"
              fromPlaceholder="от"
              metric={this.metric()}
            />
          </div>
        </Label>
      </div>
    );
  }

  private metric = () => {
    const { isCoworking, userAgent } = this.props;
    const { dealType, rentTime, selectedObjectTypes } = this.props.filter;

    return getMetrics({
      dealType,
      rentTime,
      selectedObjectTypes,
      userAgent,
      isCoworking,
    });
  }
}

export const isVisible: IsVisibleCheck = isNotUpcomingSale;

/* istanbul ignore next */
export function mapStateToProps(state: ReduxState) {
  const { filter } = state;
  let isCoworking = false;

  if (filter.selectedObjectTypes && filter.selectedObjectTypes.length === 1 &&
    filter.selectedObjectTypes[0] === commercialObjectType.Coworking &&
  filter.selectedCoworkingOfferType && filter.selectedCoworkingOfferType.length > 0) {
    isCoworking = true;
  }

  return {
    isCoworking,
  };
}
/* istanbul ignore next */
export const Component =
  connect<IComponentProps, undefined>(mapStateToProps, undefined)(ComponentBase);
