import * as React from 'react';

import { Label } from '@cian/components';
import { without } from 'lodash';
import { commercialObjectType } from '../../../redux/filters/model';
import { Field } from '../../field';
import * as fields from '../../fields/';
import { IFieldProps, IsVisibleCheck } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    return (
      <div className={styles.field} data-mark="FieldWithFurnitureCommerce">
        <Label label="Наличие мебели">
          <Field field={fields.withFurniture} />
        </Label>
      </div>
    );
  }
}

const allowedCommercialObjectTypes = [
  commercialObjectType.Office,
  commercialObjectType.Marketplace,
  commercialObjectType.Building,
  commercialObjectType.RentalBusiness,
  commercialObjectType.ReadyBusiness,
];

export const isVisible: IsVisibleCheck = filter =>
  filter.selectedObjectTypes.length > 0
  && without(filter.selectedObjectTypes, ...allowedCommercialObjectTypes).length === 0;
