import { ca } from '@cian/analytics';

import { TInfrastructureLabel } from '../../../redux/filters/model';

export function trackInfrastructureButtonClick() {
  ca('event', {
    name: 'oldevent',
    category: 'Map',
    action: 'infrastructure_show',
    label: '',
  });
}

export function trackInfrastructureCheckboxChoice(label: TInfrastructureLabel) {
  ca('event', {
    name: 'oldevent',
    category: 'Map',
    action: 'infrastructure_select',
    label,
  });
}
