import { Currency as FilterCurrency } from '../redux/filters/model';
import { Currency } from '../redux/offer/model';
import { DeadlineQuarter, ICoworking } from '../api/models/offer_card';
import { isIE } from '../utils/device_detector';
import { IContact, IAgentInfo } from '../redux/offer/model';
import { IContact as IPopupContact } from '../components/rate_call/rate_call';
import { IBuilderContact } from '../redux/gk_page/model';

export function splitLongNumber(num: number) {
  return String(num)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatNumber(num: number) {
  return splitLongNumber(num).replace('.', ',');
}

export function formatWithRound(num: number) {
  return formatNumber(Math.round(num));
}

export function formatPhone(phone: string, cityCodeNotNecessary?: boolean): string {
  let mask = cityCodeNotNecessary ? '$1 ($2) $3-$4-$5' : '$1 $2 $3-$4-$5';
  if (/^7\d{10}$/.test(phone)) {
    mask = `+${mask}`;
  }

  return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, mask);
}

export function getCurrencyMarkFromFilterState(currency?: FilterCurrency, userAgent?: string): string {
  switch (currency) {
    case FilterCurrency.RUB:
      return ruble(userAgent);
    case FilterCurrency.USD:
      return '$';
    case FilterCurrency.EUR:
      return '€';
    default:
      return ruble(userAgent);
  }
}

export function getCurrencyMark(currency?: Currency, userAgent?: string): string {
  switch (currency) {
    case Currency.RUB:
      return ruble(userAgent);
    case Currency.USD:
      return '$';
    case Currency.EUR:
      return '€';
    default:
      return ruble(userAgent);
  }
}

export function ruble(userAgent?: string): string {
  return isIE(userAgent) ? 'Р' : '₽';
}

export const getPrice = (min: number, max: number, userAgent?: string): string => {
  if (!min && !max) {
    return '';
  }
  if (min === max) {
    return `от ${formatWithRound(min)} ${ruble(userAgent)}`;
  }
  if (!min) {
    return `до ${formatWithRound(max)} ${ruble(userAgent)}`;
  }
  if (!max) {
    return `от ${formatWithRound(min)} ${ruble(userAgent)}`;
  }
  return `от ${formatWithRound(min)} ${ruble(userAgent)} до ${formatWithRound(max)} ${ruble(userAgent)}`;
};

export const formatUrl = (url: string) => {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }

  return `http://${url}`;
};

export const formatPathname = (pathname: string) => pathname.endsWith('/') ? pathname : pathname + '/';

const Quarters: { [K in DeadlineQuarter]: number } = {
  first: 1,
  second: 2,
  third: 3,
  fourth: 4,
};

export function getFormattedQuarter(quarter?: DeadlineQuarter | null): number | null {
  return quarter ? Quarters[quarter] : null;
}

export function mapContactToNumberHelper(contact: IContact, agent?: IAgentInfo): IPopupContact {
  return {
    number: {
      callNumber: `${contact.countryCode}${contact.number}`,
      displayNumber: formatPhone(`${contact.countryCode}${contact.number}`),
    },
    agent,
  };
}

export const builderContactsToContacts = (contacts: IBuilderContact[]): IContact[] =>
  contacts.map(contact => ({
    number: `+${contact.phone}`,
    countryCode: '',
  }));

export function formatAccessTime(coworking: ICoworking | undefined) {
  if (!coworking || !coworking.accessType || (!coworking.workTime && !coworking.isRoundTheClock)) {
    return null;
  }

  const { accessType, isRoundTheClock, workTime } = coworking;

  const accessTypeText = accessType === 'weekdays' ? 'пн — пт' : 'ежедневно';
  let workTimeText = '';

  if (!isRoundTheClock && workTime && workTime.timeFrom && workTime.timeTo) {
    workTimeText = `с ${workTime.timeFrom} до ${workTime.timeTo}`;
  }

  if (isRoundTheClock) {
    workTimeText = 'круглосуточно';
  }

  return { accessTypeText, workTimeText };
}
