import { IRegionInfo } from '../../api/api';
import { IUserRegion } from '../../redux/common/model';
import { PopupParamName } from '../../redux/popups/actions';
import { ISpecialPromoInfo } from '../../redux/special_promos_info/model';

export enum Screen {
  Regions,
  Cities,
  CapitalDetail,
}

/** Универсальный интерфейс для итемов в попапе "Указать регион" (может быть городом) */
export interface IRegionItem {
  type: IRegionItemType;
  name: string;
  value: number;
  parentId?: number;
  subdomain?: string;
  specialPromoInfo?: ISpecialPromoInfo;
}

export type IRegionItemType = 'radio' | 'link';

export interface IRegionItemPageData {
  first: IRegionItem[];
  groups: {
    [key: string]: IRegionItem[];
  };
}

export interface ISelectRegionState {
  search: string;
  selectedRegionId: number;
  regionPath: number;
}

export interface ISelectRegionConnectedProps {
  userAgent: string;
  regions: IRegionItem[];
  selectedRegionId: number;
  meta: IRegionInfo;
  regionCities: IRegionItem[];
  isLoading: Boolean;
  withoutCities?: boolean;
  detectedRegionId?: number;
  opened: boolean;
  routeName?: string;
}

export interface ISelectRegionOwnProps {
  onSave?: (region: IUserRegion) => void;
  // используется для случая, когда нужно открыть попап
  // с выбранным городом, который не нужно сохранять в модели
  ownSelectedRegionId?: number | undefined;
  onNavigateClick?: () => void;
  onClose?: () => void;
  popupQueryParam: PopupParamName;
}

export interface ISelectRegionDispatchProps {
  getRegionCities: (regionId: number) => void;
  onSave?: (region: IUserRegion) => void;
  onClose: (isMapPage?: boolean) => void;
}

export type ISelectRegionProps =
  ISelectRegionOwnProps & ISelectRegionConnectedProps & ISelectRegionDispatchProps;
