import * as React from 'react';

import Block from '../../../ui/block/block';
import { Regions } from '../../../utils/regions';
import { Field } from '../../field';
import * as fields from '../../fields/';
import {
  IFieldProps,
  isSuburbanObjectGroupHouse,
  IsVisibleCheck,
} from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    return (
      <Block
        title="Направление"
        dataMark="BlockDirection"
      >
        <div className={styles.fieldBlock}>
          <Field field={fields.selectHighway} />
          <Field field={fields.distanceToMKAD} />
        </div>
      </Block>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => isSuburbanObjectGroupHouse(filter)
  && [Regions.Moscow, Regions.MoscowRegion].includes(filter.region);
