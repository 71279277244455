import { CheckboxGroup, convertCheckboxGroupItemsToCheckboxGroup } from '@cian/nested-checkbox-groups';

import * as React from 'react';

import { IGetBusinessPossibleAppointmentItem } from '../../../../../../../../../shared/repositories/announcements/entities/AnnouncementReferences/GetBusinessPossibleAppointmentItem';

export function useRentalBusinessGroups(items: Array<IGetBusinessPossibleAppointmentItem>): Array<CheckboxGroup> {
  return React.useMemo<Array<CheckboxGroup>>(
    () => [
      convertCheckboxGroupItemsToCheckboxGroup(
        items.map(item => ({
          label: item.name,
          value: item.id,
        })),
        'Арендный бизнес',
        'rentalBusiness',
      ),
    ],
    [items],
  );
}
