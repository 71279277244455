import { ILogger } from '@cian/logger/shared';
import { HttpBadStatusCodeError, NotFoundError, UnknownError } from '@cian/peperrors/shared';

import { ILogRequestService, LogRequestService } from '../../LogRequestService';
import { IFetchAssertsService } from '../IFetchAssertsService';

export class FetchAssertsService implements IFetchAssertsService {
  private readonly domain: string;
  private readonly model: object;
  private readonly logRequestService: ILogRequestService;

  public constructor(model: object, domain: string, logger: ILogger) {
    this.domain = domain;
    this.model = model;
    this.logRequestService = new LogRequestService(logger);
  }

  public assertUnknownServerError(error: unknown): UnknownError {
    const message =
      error instanceof Error ? error.message : 'Не была передана ошибка, передалось ни известно что, нужно разбираться';

    const unknownError = new UnknownError({
      details: {
        description: `Неизвестная ошибка в запросе`,
        level: 'error',
        model: this.getSerializedModel(),
      },
      domain: this.domain,
      message,
    });

    this.logRequestService.logFetchError(unknownError);

    return unknownError;
  }

  public assertUnknownStatusCodeError(statusCode: number): HttpBadStatusCodeError {
    const httpBadStatusCodeError = new HttpBadStatusCodeError({
      details: {
        level: 'error',
        model: this.getSerializedModel(),
      },
      domain: this.domain,
      message: 'Неизвестный статус ответа',
      statusCode,
    });

    this.logRequestService.logFetchError(httpBadStatusCodeError);

    return httpBadStatusCodeError;
  }

  public assert400StatusCodeError<Result extends { response?: { errors?: unknown[] | object; message?: string } }>(
    result: Result,
  ): HttpBadStatusCodeError {
    const errors = JSON.stringify(
      result.response?.errors ? result.response.errors : { message: 'Сервер не прислал список из ошибок' },
    );

    const httpBadStatusCodeError = new HttpBadStatusCodeError({
      details: {
        errors,
        level: 'warning',
        model: this.getSerializedModel(),
      },
      domain: this.domain,
      message: result.response?.message || 'Нет сообщения от сервера, нужно разбираться',
      statusCode: 400,
    });

    this.logRequestService.logFetchError(httpBadStatusCodeError);

    return httpBadStatusCodeError;
  }

  public assert404StatusCodeError<Result extends { response?: { errors?: unknown[] | object; message?: string } }>(
    result: Result,
  ): NotFoundError {
    const errors = JSON.stringify(
      result.response?.errors ? result.response.errors : { message: 'Сервер не прислал список из ошибок' },
    );

    const notFoundError = new NotFoundError({
      details: {
        errors,
        level: 'warning',
        model: this.getSerializedModel(),
      },
      domain: this.domain,
      message: result.response?.message || 'Нет сообщения от сервера, нужно разбираться',
    });

    this.logRequestService.logFetchError(notFoundError);

    return notFoundError;
  }

  private getSerializedModel(): string {
    return JSON.stringify(this.model);
  }
}
