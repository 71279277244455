import { replace } from 'react-router-redux';
import { toPromise } from '../../utils/streams';
import { TTypedThunkAction } from '../model';

interface IResetPasswordData {
  userId: string;
  token: string;
  phone?: string;
}

export interface ISetResetPasswordData {
  type: 'SetResetPasswordData';
  data: IResetPasswordData;
}

export type TResetPasswordActions = ISetResetPasswordData;

export function getResetPasswordData(userId: string, token: string): TTypedThunkAction<Promise<void>> {
  return (dispatch, _, { api }) => {
    return toPromise(api.userGetResetPasswordData(userId, token))
      .then(({ result }) => {
        dispatch<TResetPasswordActions>({
          type: 'SetResetPasswordData',
          data: {
            userId,
            token,
            phone: result.phoneConfirmationNeeded && result.masterPhone && result.masterPhone.number || undefined,
          },
        });
      })
      .catch(err => {
        dispatch(replace('/'));
      });
  };
}

export interface IInitializedState {
  data: IResetPasswordData;
  initialized: true;
}

interface INotInitializedState {
  initialized: false;
}

const initialState: INotInitializedState = {
  initialized: false,
};

export type ResetPasswordState = IInitializedState | INotInitializedState;

export function reducer(state: ResetPasswordState = initialState, action: TResetPasswordActions): ResetPasswordState {
  switch (action.type) {
    case 'SetResetPasswordData':
      return { initialized: true, data: action.data };

    default:
      return state;
  }
}
