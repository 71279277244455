import { CheckboxField, ISelectMobileGroup } from '@cian/components';
import { mergeStyles } from '@cian/utils';

import { includes, memoize } from 'lodash';
import * as React from 'react';

import * as filterActions from '../../../redux/filters/actions';
import { coworkingOfferType, DealType, EJsonQueryPriceType, TCoworkingOfferType } from '../../../redux/filters/model';
import { PopupMobile } from '../../../ui/popup/popup';
import Text from '../../../ui/text/text';
import { createBodyScroll } from '../../../utils/body_scroll';

import { IOption, IOptionsList } from '../../common/select_helper';

type TSelectOptions = TCoworkingOfferType | 'workplace';

const style = require('./select_coworking_offer_type.css');
const fieldStyle = require('../field.css');

const bodyScroll = createBodyScroll();

interface ICoworkingOfferTypesPopupProps {
  options: ISelectMobileGroup[];
  selectedCoworkingOfferTypes?: TCoworkingOfferType[];
  opened: boolean;
  onClose: () => void;
  extraFilters?: boolean;
  actions: typeof filterActions;
  dealType?: DealType;
  dataMark?: string;
  isWorkplaceSelected: boolean;
  onWorkplaceSelect: (isVisible: boolean) => void;
}

export class CoworkingOfferTypesPopup extends React.Component<ICoworkingOfferTypesPopupProps> {

  public render() {
    const popupButtons = [{
        id: 'cancel',
        text: 'Отменить',
        onClick: this.cancel,
      }, {
        id: 'apply',
        text: 'Применить',
        onClick: this.confirm,
      }];

    return (
      <PopupMobile
        opened={this.props.opened}
        innerStyle={[style.popupContainer]}
        contentStyle={[style.popupContent]}
        onToggle={this.cancel}
        bodyScroll={bodyScroll}
        buttons={popupButtons}
        dataMark={this.props.dataMark}
      >
        {this.props.options.map(this.renderOption)}
      </PopupMobile>
    );
  }

  private renderOption = (option: IOptionsList, index: number) => {
    const groupStyles = mergeStyles(
      style.group,
    );

    return (
      <div key={index} {...groupStyles}>
        {option.label && <Text
          textStyles={[style.groupTitle]}
          title
        >
          {option.label}
        </Text>}
        <div>
          {option.options.map(this.renderCheckbox)}
        </div>
      </div>
    );
  }

  private renderCheckbox = (option: IOption, index: number) => {
    const { isWorkplaceSelected, selectedCoworkingOfferTypes } = this.props;
    let checked = includes(selectedCoworkingOfferTypes, option.id);

    if (option.id === 'workplace') {
      checked = isWorkplaceSelected;
    }

    const checkboxStyles = mergeStyles(
      fieldStyle.labelCheckbox,
      style.labelCheckbox,
      checked && fieldStyle.checked,
    );

    const customLabelStyles = mergeStyles(
      style.customLabelCheckbox,
      option.disabled &&
      option.id !== coworkingOfferType.Office &&
      option.id !== 'workplace'
      && style.customLabelCheckboxDisable,
    );

    const checkboxContainerStyles = mergeStyles(
      style.checkboxContainer,
      style.itemContainer,
      (option.id === coworkingOfferType.Office || option.id === 'workplace') &&
      style.itemOneLine,
    );

    // TODO: после открытия РМ в прод, нужно переделать метод и убрать костыли
    return (
      <div
        key={option.id + index} {...checkboxContainerStyles}
        data-mark={`${this.props.dataMark ? this.props.dataMark + '|' : ''}checkbox`}>
        <CheckboxField
          mobile
          label=""
          value={checked}
          onValueChange={this.handleObjectTypeChange(option.id, option.isNotMultiple)}
          labelStyle={checkboxStyles.className}
          disabled={option.disabled}
        />
        <span
          className={customLabelStyles.className}
          onClick={() => this.handleObjectTypeChange(option.id)(!checked, option.disabled)}
        >
          {`${option.label}${option.comingsoon ? ' (cкоро)' : ''}`}
        </span>
      </div>
    );
  }

  private handleObjectTypeChange: any = memoize(
    (id: TSelectOptions): any =>  (value: boolean, disabled: boolean = false) => {
      if (disabled) {
        return;
      }

      if ('workplace' === id) {
        if (value) {
          this.props.actions.setJsonQueryPriceType(EJsonQueryPriceType.Workplace);
        } else {
          this.props.actions.setJsonQueryPriceType(undefined);
        }
      } else {
        if (id === 'office' && !value) {
          this.props.actions.setJsonQueryPriceType(EJsonQueryPriceType.Workplace);
        } else {
          this.props.actions.setJsonQueryPriceType(undefined);
        }
      }

      if (id === 'workplace') {
        this.props.onWorkplaceSelect(value);

        return;
      }

      this.props.actions.setSelectedCoworkingOfferType(id);
    },
  );

  private cancel = () => {
    this.props.onClose();
  }

  private confirm = () => {
    this.props.onClose();
  }
}
