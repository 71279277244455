import { pathOr } from 'ramda';
import { IGk } from '../redux/offers_list/gk';

export function getNewbuildingExtra(gk: IGk) {
  const builders = Array.isArray(gk.builders_info) ? gk.builders_info : [];
  const sellers = Array.isArray(gk.sellers) ? gk.sellers : [];

  const siteBlockId = builders.some(builder => {
    return !!pathOr(null, ['newbuilding_dynamic_calltracking', 'site_block_id'], builder);
  });

  const isFromDeveloper = builders.some(builder => !!builder.phone)
    || sellers.some(seller => !!seller.phone);
  const isFromLeadFactory = sellers.some(seller => !!seller.is_lead_factory);

  return {
    cplDynamicCalltracking: siteBlockId ? 1 : 0,
    cplCalltracking: isFromDeveloper && !isFromLeadFactory ? 1 : 0,
  };
}
