import { EHideOfferState, IHideOfferInfo, EHideOfferErrorCode } from './actions';

export type HideOfferState = {
  hidingOffer: IHideOfferInfo | null;
  hideOfferState: EHideOfferState;
  errorCode?: EHideOfferErrorCode;
  isTopPopupOpened: boolean;
  lkUrl: string;
};

export const defaultHideOfferState: HideOfferState = {
  hidingOffer: null,
  hideOfferState: EHideOfferState.INIT,
  isTopPopupOpened: false,
  lkUrl: '',
};
