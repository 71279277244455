import * as React from 'react';

import { AsyncTypeaheadField, ITypeaheadSuggestion, VisualSize } from '@cian/components';
import { MobileWebsiteApi } from '../../../api/api';
import { searchRegions } from '../../../redux/regions/actions';
import { inject } from '../../../utils/context_provider';

const styles = require('../search_field/search_field.css');
const fieldStyles = require('../../../filters/fields/field.css');

export interface IRegionSuggestion {
  id: number;
  parentId?: number;
  name?: string;
}

interface IState {
  value: string;
}

interface IProps {
  dataMark?: string;
  onSuggestionSelect: (suggestion: IRegionSuggestion) => void;
}

interface IConnectedProps {
  api: MobileWebsiteApi;
}

type Props = IProps & IConnectedProps;

class Component extends React.Component<Props, IState> {

  public state = {
    value: '',
  };

  public render() {
    const { value } = this.state;

    return (
      <div
        className={styles['async_search_field']}
        data-mark={this.props.dataMark}>
        <AsyncTypeaheadField
          inputStyle={[styles.searchFieldWithIcon]}
          placeholder="Введите регион или город"
          value={value}
          size={VisualSize.MEDIUM}
          popupContentValueStyle={styles.searchPopupValue}
          popupContainerStyle={styles.searchPopup}
          autoCorrect="off"
          containerStyle={[
            fieldStyles.inputContainer,
            styles.searchFieldContainer,
            !value && styles.searchFieldContainerWithIcon,
          ]}
          onSuggestionSelect={this.onSuggestionSelect}
          requestSuggestions={this.search}
          onValueChange={this.onChange}
          onClear={this.onClear}
        />
      </div>
    );
  }

  private onChange = (value: string) => this.setState({ value });
  private onClear = () => this.setState({ value: '' });

  private search = (query: string): Promise<ITypeaheadSuggestion[]> => {
    return searchRegions(this.props.api, query).then(regions =>
      regions.map(region => {
        const meta: IRegionSuggestion = {
          id: region.id,
          parentId: region.parentId,
          name: region.name,
        };

        return {
          label: region.displayName || '',
          meta,
        };
      }),
    );
  };

  private onSuggestionSelect = (suggestion: ITypeaheadSuggestion) => {
    this.props.onSuggestionSelect(suggestion.meta);
  }
}

export const AsyncSearchRegionField = inject(['api'])(Component);
