/* eslint-disable max-lines */

import { IJsonQuery } from '../../api/models/json_query';
import {
  IListPageAnalytics,
  ITrackingLocation,
  PageType,
  TListingType,
  TVariant,
  getDealTypeForList,
  getHeadline,
  getObjectTypeForList,
  sendInitPageDataForListPage,
  trackListingPageview,
} from '../../utils/analytics';
import { getNewbuildingExtra } from '../../utils/getNewbuildingExtra';
import { getOfferExtra } from '../../utils/getOfferExtra';
import { isSuburbanBuilderList } from '../../utils/isSuburbanBuilderList';
import { defaultRegionId } from '../../utils/regions';
import { isSuburbanOfferFromBuilder } from '../../utils/suburban';
import { DataLayerState } from '../data_layer';
import { IOfferInfo } from '../offer/model';
import { responseToAnalyticsInfo } from '../offer/response_parser/analytics_info_parser';

import { isGkList } from './actions';
import { IGk } from './gk';
import { SearchOffersState } from './model';

/* istanbul ignore next */
const getAnalyticsForListing = (offers: IOfferInfo[]) => {
  return offers.map((offer: IOfferInfo, index) => {
    const parentId =
      (offer.newbuilding && offer.newbuilding.id) ||
      (offer.businessShoppingCenter && offer.businessShoppingCenter.parentId);
    const parentName =
      (offer.newbuilding && offer.newbuilding.name) ||
      (offer.businessShoppingCenter && offer.businessShoppingCenter.parentName);
    const analyticsInfo = offer.offerAnalyticsInfo || responseToAnalyticsInfo(offer);

    return {
      id: analyticsInfo.id,
      price: analyticsInfo.price,
      brand: analyticsInfo.brand,
      category: analyticsInfo.category,
      headline: getHeadline(offer),
      variant: analyticsInfo.variant,
      photosCount: offer.photos ? offer.photos.length : 0,
      parentId,
      parentName,
      customURL: offer.gaLabel,
      position: index + 1,
    };
  });
};

export type TDealTypeForSOPR = 'rent_day' | 'rent_long' | 'sale' | 'other';
export type TObjectTypeForSOPR =
  | 'flat_new'
  | 'flat_new_dev'
  | 'flat_old'
  | 'commercial'
  | 'newobject'
  | 'suburban'
  | 'suburban_dev'
  | 'flat_old,flat_new'
  | 'JK';

/* istanbul ignore next */
export function getDealTypeForSOPR(offer: IOfferInfo) {
  if (offer.dealType === 'rent') {
    const isDaily = offer.baseInfo.category && offer.baseInfo.category.indexOf('daily') >= 0;

    return isDaily ? 'rent_day' : 'rent_long';
  }

  return 'sale';
}

/* istanbul ignore next */
export function getDealTypeForListing(jsonQuery: IJsonQuery) {
  if (jsonQuery._type.includes('rent')) {
    return jsonQuery.for_day && jsonQuery.for_day.value === '1' ? 'rent_day' : 'rent_long';
  }

  return 'sale';
}

/* istanbul ignore next */
export function getObjectTypeForListing(jsonQuery: IJsonQuery) {
  if (jsonQuery._type.includes('flat')) {
    return jsonQuery.with_newobject && jsonQuery.with_newobject.value ? 'flat_new' : 'flat_old';
  }

  if (jsonQuery._type.includes('commercial')) {
    return 'commercial';
  }

  return 'flat_old,flat_new';
}

/* istanbul ignore next */
export function getObjectTypeForSOPR(offer: IOfferInfo) {
  const {
    offerType,
    baseInfo: { category },
    isFromBuilder,
    newbuilding,
  } = offer;

  if (offerType === 'flat') {
    const isFromBuilderPresenter = Boolean(newbuilding && newbuilding.isFromDeveloper);
    const isNewBuilding = category && category.toLowerCase().includes('newbuilding');

    return isNewBuilding ? (isFromBuilder || isFromBuilderPresenter ? 'flat_new_dev' : 'flat_new') : 'flat_old';
  }

  if (isSuburbanOfferFromBuilder(offer)) {
    return 'suburban_dev';
  }

  return offerType || 'flat_old,flat_new';
}

/* istanbul ignore next */
function getVariantsForSOPR(offer: IOfferInfo) {
  const variants: TVariant[] = [];

  if (offer.valueAddedServices) {
    const { isFree, isStandard, isPremium, isTop3 } = offer.valueAddedServices;

    if (isStandard) {
      variants.push('standard');
    }
    if (isPremium) {
      variants.push('premium');
    }
    if (isTop3) {
      variants.push('top3');
    }
    if (isFree) {
      variants.push('free');
    }
  }

  if (offer.baseInfo.isAuction) {
    variants.push('auction');
  }

  return variants;
}

/* istanbul ignore next */
function getBrandSOPR(offer: IOfferInfo) {
  const { user, fromDeveloper, isPro } = offer;

  const getAutorType = (): string | undefined => {
    if (fromDeveloper) {
      return 'developer';
    }
    if (isPro) {
      return 'realtor_prof';
    }
    if (user && !user.isAgent) {
      return 'physical';
    }

    return user && user.agentAccountType ? user.agentAccountType : '';
  };

  return [user ? user.cianUserId || user.userId : undefined, getAutorType()].filter(Boolean).join('/') || '';
}

/* istanbul ignore next */
function getProductsForSOPR(offersList: IOfferInfo[] | IGk[], isGk?: boolean) {
  if (isGk) {
    const gks = offersList as IGk[];

    return gks.map((gk, index) => {
      return {
        id: Number(gk.id),
        dealType: 'other',
        objectType: 'JK',
        position: index + 1,
        name: gk.name || undefined,
        nv: Boolean(gk.analyticsData && gk.analyticsData.nv),
        brand: gk.analyticsData && gk.analyticsData.brand ? gk.analyticsData.brand : undefined,
        consultant: gk.isDealRequestSubstitutionPhone,
        extra: getNewbuildingExtra(gk),
      };
    });
  }

  const offers = offersList as IOfferInfo[];

  return offers.map((offer, index) => {
    const { businessShoppingCenter, newbuilding, kp } = offer;
    const objectType = getObjectTypeForSOPR(offer);
    const parentId = (() => {
      if (objectType === 'suburban') {
        return kp && kp.id;
      }

      if (offer.baseInfo.coworkingOfferType && offer.baseInfo.coworking) {
        return offer.baseInfo.coworking.id;
      }

      return (newbuilding && newbuilding.id) || (businessShoppingCenter && businessShoppingCenter.parentId);
    })();

    const dealType = getDealTypeForSOPR(offer);

    return {
      brand: getBrandSOPR(offer),
      cianId: offer.cianId,
      consultant: offer.isDealRequestSubstitutionPhone,
      dealType,
      extra: getOfferExtra(offer),
      headline: getHeadline(offer),
      hiddenBase: Boolean(offer.isInHiddenBase),
      id: offer.id,
      objectType,
      owner: Boolean(offer.isByHomeowner),
      ownerCianId: offer.user ? Number(offer.user.cianUserId) : undefined,
      ownerId: offer.user ? Number(offer.user.userId) : undefined,
      parentId: Number(parentId) || undefined,
      photosCount: offer.photos ? offer.photos.length : 0,
      podSnos: Boolean(offer.demolishedInMoscowProgramm),
      position: index + 1,
      price: offer.baseInfo.priceRubles ? Number(offer.baseInfo.priceRubles) : undefined,
      published: offer.status === 'published',
      variant: getVariantsForSOPR(offer),
    };
  });
}

export function sendAnalyticsForListing(
  searchList: SearchOffersState,
  dataLayer: DataLayerState,
  params: {
    pageType?: PageType;
    listingType?: TListingType;
    trackingLocation?: ITrackingLocation;
    sendProducts?: boolean;
  } = {},
) {
  if (dataLayer.initialized && searchList.initialized) {
    const isGk = isGkList(searchList);
    const productsIds = isGk
      ? searchList.pages[0].gks.map(gk => Number(gk.data.id))
      : searchList.pages[0].offers.map(o => o.data.urlParams.id).filter(o => o !== undefined);

    const { breadcrumbs, jsonQuery, offersCountOnNextPage, mlRankingGuid, mlRankingModelVersion } = searchList;
    const { region } = jsonQuery;

    if (!params.pageType) {
      params.pageType = isGk ? 'ListingJK' : 'Listing';
    }

    const page: IListPageAnalytics = {
      pageType: params.pageType,
      region: (region && region.value && region.value[0]) || defaultRegionId,
      breadCrumbs: breadcrumbs.map(br => br.title),
      objectType: getObjectTypeForList(jsonQuery),
      dealType: getDealTypeForList(jsonQuery),
      offersQty: offersCountOnNextPage,
      listingType: params.listingType === 'listing' ? 'list' : params.listingType || ('list' as TListingType),
      mlRankingGuid,
      mlRankingModelVersion,
    };

    const offers = searchList.pages[0].offers.map(offer => offer.data);
    const products = getAnalyticsForListing(offers);

    sendInitPageDataForListPage(
      dataLayer.data,
      page,
      productsIds.slice(0, 5),
      jsonQuery,
      productsIds,
      params.trackingLocation,
      params.sendProducts ? products : undefined,
    );
  }
}

/* istanbul ignore next */
export function trackPageviewListing(
  searchList: SearchOffersState,
  dataLayer: DataLayerState,
  params: {
    pageType?: PageType;
    listingType?: TListingType;
    trackingLocation?: ITrackingLocation;
    sendProducts?: boolean;
  } = {},
) {
  if (dataLayer.initialized && searchList.initialized) {
    const isGk = isGkList(searchList);
    const productsIds = isGk
      ? searchList.pages[0].gks.map(gk => Number(gk.data.id))
      : searchList.pages[0].offers.map(o => o.data.urlParams.id).filter(o => o !== undefined);

    const { jsonQuery } = searchList;
    const { region } = jsonQuery;

    if (!params.pageType) {
      params.pageType = isGk ? 'ListingJK' : 'Listing';
    }

    let pageNumber = 0;

    const getOffersList = (isGkListing: boolean) => {
      if (isGkListing) {
        return searchList.pages.reduce((gkInfo, next) => {
          if (next.num && next.num > pageNumber) {
            pageNumber = next.num;
          }
          const gkData = next.gks.map(gk => gk.data);
          gkInfo.push(...gkData);

          return gkInfo;
        }, [] as IGk[]);
      }

      return searchList.pages.reduce((offerInfo, next) => {
        if (next.num && next.num > pageNumber) {
          pageNumber = next.num;
        }
        const offerData = next.offers.map(offer => offer.data);
        offerInfo.push(...offerData);

        return offerInfo;
      }, [] as IOfferInfo[]);
    };
    const offersList = getOffersList(isGk);
    const isCountrysideDeveloperListing = isSuburbanBuilderList(jsonQuery);

    const page: IListPageAnalytics = {
      breadCrumbs: searchList.breadcrumbs ? searchList.breadcrumbs.map(breadcrumb => breadcrumb.title) : [],
      pageType: params.pageType,
      region: region && region.value && region.value[0] ? String(region.value[0]) : String(defaultRegionId),
      objectType: isGk ? 'JK' : getObjectTypeForList(jsonQuery, false, isCountrysideDeveloperListing),
      dealType: isGk ? 'other' : getDealTypeForList(jsonQuery),
      offersQty: parseInt(getOffersQty(searchList.seoData.description)[isGk ? 1 : 0], 10), // добавляем кол-во объявок или уол-во жк
      listingType:
        params.listingType && params.listingType !== 'listing' ? params.listingType : ('list' as TListingType),
      pageNumber,
      queryString: searchList.queryString,
      sortType: searchList.jsonQuery.sort ? searchList.jsonQuery.sort.value : 'default',
      searchGuid: searchList.searchUuid ? searchList.searchUuid : undefined,
      mlRankingGuid: searchList.mlRankingGuid,
      mlRankingModelVersion: searchList.mlRankingModelVersion,
    };

    const products = getProductsForSOPR(offersList, isGk);

    trackListingPageview(dataLayer.data, page, productsIds.slice(0, 5), jsonQuery, params.trackingLocation, products);
  }
}

/* istanbul ignore next */
function getOffersQty(description?: string) {
  const defaultOffersQtyCount = ['0', '0'];
  const seoDataDescription = description // описание без пробелов
    ? description.replace(/ /g, '')
    : '';
  const offersQtyMatches = seoDataDescription.match(/\d+/g); // ищем все цифры

  if (!description || !seoDataDescription || !offersQtyMatches) {
    return defaultOffersQtyCount; // если пустая строка или мы ничего не нашли то кол-во объявок 0
  }

  return offersQtyMatches; // если находим - возвращаем кол-во объявок
}
