import { getCoworkingCategoryTitle } from '../../../../utils/labels/getCoworkingCategoryTitle';

import { getTypeText, IOfferFeaturesProps, TGetTypeTextParams } from '../offer_features';

const buildingClasses: { [key: string]: string } = {
  a: 'A',
  aPlus: 'A+',
  b: 'B',
  bMinus: 'B-',
  bPlus: 'B+',
  c: 'C',
  cPlus: 'C+',
  d: 'D',
};

export function getCategoryTitle(props: IOfferFeaturesProps, params?: TGetTypeTextParams) {
  const { classType, coworkingOfferType, workplaceCount } = props;

  if (workplaceCount && coworkingOfferType) {
    return getCoworkingCategoryTitle(coworkingOfferType, workplaceCount);
  }

  const buildingClassText = classType != null && !coworkingOfferType
      ? `(${buildingClasses[classType]})`
      : '';

  return `${getTypeText(props, params)} ${buildingClassText}`.trim();
}
