import { TPromoBlocksActions } from './actions';
import { defaultPromoBlocksState, PromoBlocksState } from './model';

type Reducer = (state: PromoBlocksState, action: TPromoBlocksActions) => PromoBlocksState;

const promoBlocksReducer: Reducer = (state = defaultPromoBlocksState, action) => {
  switch (action.type) {
    case 'GetPromoBlocksSuccess':
      return action.payload.promoBlocks;

    default:
      return state;
  }
};

export default promoBlocksReducer;
