import { IRegionInfo, IRegionMetaData, IRegionsResponseData } from '../../api/api';
import { getRegionById, getRegionTitleById, Regions } from '../../utils/regions';
import { ResourceStatus } from '../common/model';

export type RegionsState = {
  status: ResourceStatus;
  data: IRegionsResponseData; /** Информация о всех регионах и выбранном по дефолту (опр-ся доменом) */
  regionMeta: IRegionMetaData; /** Информация о выбранном регионе/городе */
  regionsInfo: IRegionInfo[]; /** Все регионы  */
  regionCities: IRegionCitiesState; /** Список городов региона для попапа "Указать регион"  */
  /**
   * Информация о текущем регионе, который находится в гео фильтре,
   * regionsInfo - работает некоректно для регионов, у которых нет своего субдомена.
   */
  currentRegionInfo?: IRegionInfo;
};

export const defaultRegions: RegionsState = {
  status: ResourceStatus.Initial,
  data: {
    default: {
      // tslint:disable-next-line:no-any
      [Regions.Moscow]: getRegionById(Regions.Moscow) as any,
    },
    regions: {},
  },
  regionCities: {
    status: ResourceStatus.Initial,
    data: [],
  },
  regionMeta: {
    status: ResourceStatus.Initial,
    districts: [],
    underground: [],
    location: {
      id: Regions.Moscow,
      displayName: getRegionTitleById(Regions.Moscow),
      parentId: Regions.MoscowRegion,
    },
  },
  regionsInfo: [],
};

export interface IRegionCitiesState {
  status: ResourceStatus;
  region?: number;
  data: IRegionInfo[];
}
