import { IOfferInfo } from '../../redux/offer/model';

import { FDealType, getDealTypeFromOffer } from '../category';

import { EWordCase } from './partials/constants';
import { getOfferTypeVariant } from './partials/getOfferTypeVariant';
import { getAreaLabel } from './partials/getAreaLabel';
import { getGeoLabel } from './partials/getGeoLabel';

const getHeadLabels = (offer: IOfferInfo) => {
  const dealType = getDealTypeFromOffer(offer);
  const variant = ((offer.cianId || 0) % 3) as 0 | 1 | 2;

  // tslint:disable-next-line: switch-default
  switch (variant) {
    case 0:
      return [
        dealType === FDealType.Sale ? 'Купить' : 'Снять',
        getOfferTypeVariant(offer, EWordCase.Accusative),
      ];

    case 1:
      return [
        dealType === FDealType.Sale ? 'Продажа' : 'Аренда',
        getOfferTypeVariant(offer, EWordCase.Genitive),
      ];

    case 2:
      return [
        dealType === FDealType.Sale ? 'Продаю' : 'Сдам',
        getOfferTypeVariant(offer, EWordCase.Accusative),
      ];
  }
};

export const getTitle = (offer: IOfferInfo) => {
  const { cianId } = offer;

  return [
    ...getHeadLabels(offer),
    getAreaLabel(offer),
    getGeoLabel(offer),
    cianId && `- база ЦИАН, объявление ${cianId}`,
  ].filter(Boolean).join(' ');
};
