import { get, without } from 'lodash';
import * as React from 'react';

import {
  IButtonRadioGroupFieldValue,
  TextField,
  VisualSize,
} from '@cian/components';
import { mergeStyles } from '@cian/utils';

import { commercialObjectTypes, flatObjectTypes, MovementMethod } from '../../../redux/filters/model';
import Block from '../../../ui/block/block';
import ButtonRadioGroupField from '../../../ui/button_radio_group_field/button_radio_group_field';
import { IFieldProps, IsVisibleCheck } from '../field';

const fieldStyles = require('../field.css');
const styles = require('./subway_distance.css');

export class Component extends React.Component<IFieldProps, object> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    return this.props.filter.subwayDistance.maxDistance !== nextProps.filter.subwayDistance.maxDistance
      || this.props.filter.subwayDistance.movementMethod !== nextProps.filter.subwayDistance.movementMethod;
  }

  public render() {
    const { filter } = this.props;

    const movementMethods: IButtonRadioGroupFieldValue[] = [{
      value: MovementMethod.Walk.toString(),
      label: 'Пешком',
    }, {
      value: MovementMethod.Transport.toString(),
      label: 'Транспортом',
    }];

    const currentDistance = get(filter, 'subwayDistance.maxDistance', '').toString();
    const currentMovement = get(filter, 'subwayDistance.movementMethod', '').toString();

    const timeInputContainerStyles = mergeStyles(
      styles.timeInputContainer,
      fieldStyles.field,
    );

    const inputStyles = [
      styles.timeInput,
    ];

    return (
      <Block
        title="Расстояние до метро"
        dataMark="BlockSubwayDistance"
      >
        <div className={fieldStyles.fieldBlock}>
          <div {...timeInputContainerStyles} data-mark="FieldDistance">
            <span>Не более</span>
            <TextField
              value={currentDistance}
              inputStyle={inputStyles}
              containerStyle={[
                { display: 'inline-block' },
                fieldStyles.inputContainer,
              ]}
              size={VisualSize.MEDIUM}
              onValueChange={this.onDistanceChange}
            />
            <span>мин.</span>
          </div>
          <div className={fieldStyles.field} data-mark="FieldMovementMethod">
            <ButtonRadioGroupField
              onValueChange={this.onMovementMethodChange}
              uncheckable
              value={currentMovement}
              values={movementMethods}
              buttonStyle={fieldStyles.buttonRadio}
              activeButtonStyle={fieldStyles.active}
            />
          </div>
        </div>
      </Block>
    );
  }

  private correctValue = (value: string): number | undefined => {
    const distance = Number(value.replace(',', '.'));

    if (value === '') {
      return undefined;
    } else if (distance !== distance) {
      return 25;
    } else if (distance <= 0) {
      return 1;
    } else if (distance > 999) {
      return 999;
    }

    return Math.ceil(distance);
  }

  private onDistanceChange = (value: string) => {
    this.props.actions.setSubwayMaxDistance(this.correctValue(value));
  }

  private onMovementMethodChange = (value: string) => {
    const method = value ? Number(value) : undefined;
    this.props.actions.setSubwayDistanceMovement(method);
  }

}

const allowedObjectTypes = [
  ...flatObjectTypes,
  ...commercialObjectTypes,
];

export const isVisible: IsVisibleCheck = filter =>
  filter.selectedObjectTypes.length > 0
  && without(filter.selectedObjectTypes, ...allowedObjectTypes).length === 0
  && !!filter.geoTags.find(t => t.kind.toLowerCase() === 'underground');
