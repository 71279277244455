import { Label } from '@cian/components';
import * as React from 'react';

import { SelectMobile } from '../../common/select/select';

import {
  IFieldProps,
  isFlatObjectGroup,
  IsVisibleCheck,
} from '../field';

export enum ECeilingHeight {
  Any = 0,
  TwoAndFive = 1,
  TwoAndSeven = 2,
  Three = 3,
}

export const ceilingHeightValues = {
  [ECeilingHeight.Any]: 0,
  [ECeilingHeight.TwoAndFive]: 2.5,
  [ECeilingHeight.TwoAndSeven]: 2.7,
  [ECeilingHeight.Three]: 3,
};

export class Component extends React.Component<IFieldProps, object> {
  private options = [{
    label: '',
    options: [{
      id: String(ECeilingHeight.Any),
      label: 'Любая',
    }, {
      id: String(ECeilingHeight.TwoAndFive),
      label: 'От 2,5 м',
    }, {
      id: String(ECeilingHeight.TwoAndSeven),
      label: 'От 2,7 м',
    }, {
      id: String(ECeilingHeight.Three),
      label: 'От 3 м',
    }],
  }];

  public render() {
    return (
      <div data-mark="FieldCielingHeight">
        <Label label="Высота потолков">
          <SelectMobile
            id="select-ceiling-height"
            name="selectMobile"
            label="Любая"
            onValueChange={this.setCeilingHeight}
            value={this.getValue()}
            options={this.options[0].options}
          />
        </Label>
      </div>
    );
  }

  private getValue = () => {
    const { filter: { ceilingHeight } } = this.props;

    if (ceilingHeight === ceilingHeightValues[ECeilingHeight.TwoAndFive]) {
      return [String(ECeilingHeight.TwoAndFive)];
    }

    if (ceilingHeight === ceilingHeightValues[ECeilingHeight.TwoAndSeven]) {
      return [String(ECeilingHeight.TwoAndSeven)];
    }

    if (ceilingHeight === ceilingHeightValues[ECeilingHeight.Three]) {
      return [String(ECeilingHeight.Three)];
    }

    return [String(ECeilingHeight.Any)];
  }

  private setCeilingHeight = (value: string[]) => {
    const rawValue = Number(value[0]) as ECeilingHeight;
    let newValue = ceilingHeightValues[ECeilingHeight.Any];

    if (rawValue === ECeilingHeight.TwoAndFive) {
      newValue = ceilingHeightValues[ECeilingHeight.TwoAndFive];
    }

    if (rawValue === ECeilingHeight.TwoAndSeven) {
      newValue = ceilingHeightValues[ECeilingHeight.TwoAndSeven];
    }

    if (rawValue === ECeilingHeight.Three) {
      newValue = ceilingHeightValues[ECeilingHeight.Three];
    }

    this.props.actions.setCeilingHeight(newValue);
  }
}

export const isVisible: IsVisibleCheck = filter => isFlatObjectGroup(filter);
