import { IOfferDetail } from '../../../api/models/offer_card';
import { QueryDealType, getDealTypeForOffer, getHeadline } from '../../../utils/analytics';
import { isSuburbanOfferFromBuilder } from '../../../utils/suburban';
import { IOfferAnalyticsInfo, IRange } from '../model';

import { parsePrice as mapPriceBase, parsePriceRub as mapPriceRub } from './base_info_parser';

const parseCategory = (offer: IOfferDetail): string => {
  const { dealType, offerType, category, isFromBuilder, newbuilding } = offer;

  const getDealType = (): string => {
    if (offerType !== 'flat' || dealType !== 'rent' || !offer.category) {
      return dealType as string;
    }

    return offer.category.toLowerCase().includes('daily') ? 'rent_day' : 'rent_long';
  };

  const getOfferType = (): string => {
    if (offerType === 'flat') {
      const isFromBuilderPresenter = Boolean(newbuilding && newbuilding.isFromDeveloper);
      const isNewBuilding = category && category.toLowerCase().includes('newbuilding');

      return isNewBuilding ? (isFromBuilder || isFromBuilderPresenter ? 'flat_new_dev' : 'flat_new') : 'flat_old';
    }

    if (isSuburbanOfferFromBuilder(offer)) {
      return 'suburban_dev';
    }

    return offerType as string;
  };

  return `${getDealType()}/${getOfferType()}`;
};

const parseBrand = (offer: IOfferDetail): string | undefined => {
  const { user, cianUserId, fromDeveloper, isPro } = offer;

  const getAutorType = (): string | undefined => {
    if (fromDeveloper) {
      return 'developer';
    }
    if (isPro) {
      return 'realtor_prof';
    }
    if (user && !user.isAgent) {
      return 'physical';
    }
    if (user && user.userType) {
      return user.userType;
    }

    return;
  };

  const id = user ? user.userId || user.cianUserId : cianUserId;

  return [id, getAutorType()].filter(Boolean).join('/') || undefined;
};

type TVariantOutput = 'string' | 'array';

const parseVariant = (offer: IOfferDetail, variantOutput?: TVariantOutput): string | string[] | undefined => {
  const { isStandard, isPremium, isTop3, isAuction } = offer;
  const isFree = !isStandard && !isPremium && !isTop3;

  const params = [
    isAuction && 'auction',
    isPremium && 'premium',
    isTop3 && 'top3',
    isStandard && 'standard',
    isFree && 'free',
  ];

  if (variantOutput && variantOutput === 'array') {
    return params ? (params.filter(Boolean) as string[]) : undefined;
  }

  return params ? params.filter(Boolean).join('/') : undefined;
};

const parsePrice = (offer: IOfferDetail): string | undefined => {
  const price = mapPriceRub(offer) || mapPriceBase(offer);

  if (price == null) {
    return;
  }

  if (typeof price === 'number') {
    return price.toString();
  }

  const priceRange = price as IRange;

  return `${priceRange.min}/${price.max}`;
};

export const responseToAnalyticsInfo = (offer: IOfferDetail, position?: number): IOfferAnalyticsInfo => {
  const {
    kp,
    newbuilding,
    businessShoppingCenter,
    userId,
    user,
    dealType,
    status,
    photos,
    isInHiddenBase,
    demolishedInMoscowProgramm,
    isDealRequestSubstitutionPhone,
    coworking,
    coworkingOfferType,
  } = offer;

  const price = parsePrice(offer);
  const objectType = parseCategory(offer).split('/')[1];
  const parentId = (() => {
    if (objectType === 'suburban') {
      return kp && kp.id;
    }

    if (coworkingOfferType) {
      return coworking && coworking.id;
    }

    return (newbuilding && newbuilding.id) || (businessShoppingCenter && businessShoppingCenter.parentId) || undefined;
  })();
  const cianUserId = (user && user.cianUserId) || undefined;

  return {
    id: offer.id, // realtyObjectId
    cianId: offer.cianId,
    category: parseCategory(offer),
    brand: parseBrand(offer),
    variant: parseVariant(offer, 'array'),
    price: price ? Number(price) : undefined,
    quantity: 1,
    parentId: parentId ? Number(parentId) : undefined,
    ownerId: userId,
    ownerCianId: cianUserId ? Number(cianUserId) : undefined,
    dealType: getDealTypeForOffer(dealType as QueryDealType),
    objectType,
    position: position || 1,
    published: status === 'published',
    photosCount: photos ? photos.length : 0,
    headline: getHeadline(offer),
    hiddenBase: Boolean(isInHiddenBase),
    repres: Boolean(businessShoppingCenter && businessShoppingCenter.fromRepresentative),
    podSnos: Boolean(demolishedInMoscowProgramm),
    nv: Boolean(newbuilding && newbuilding.showJkReliableFlag),
    owner: Boolean(offer.isByHomeowner),
    consultant: isDealRequestSubstitutionPhone,
  };
};
