import * as React from 'react';

import { mergeStyles } from '@cian/utils';
import { FixedContainer } from '../../components/fixed/fixed';
import { createBodyScroll } from '../../utils/body_scroll';

const styles = require('./sidebar.css');
const publicStyles = require('./public.css');

const bodyScroll = createBodyScroll();

interface ISidebarProps {
  children?: React.ReactNode;
  setSidebarState: (state: boolean) => void;
  opened: boolean;
  showBanner?: boolean;
  sidebarContent: React.ReactNode;
  onOverlayClick?(): void;
}

interface ISidebarState {
  lastTouch?: {
    identifier: number;
    clientX: number;
  };
  transition: boolean;
}

export default class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
  public static defaultProps = {
    showBanner: false,
  };

  public state: ISidebarState = {
    transition: false,
  };

  public componentWillUpdate(nextProps: ISidebarProps) {
    if (nextProps.opened !== this.props.opened) {
      if (nextProps.opened) {
        bodyScroll.disable();
      } else {
        bodyScroll.enable();
      }
    }
  }

  public render() {
    const { children, sidebarContent, opened, showBanner } = this.props;
    const openedStyles = {};

    return (
      <div {...mergeStyles(styles.root, publicStyles.sideBarRoot) }>
        <div
          {...mergeStyles(
            styles.sidebar,
            opened && styles.sidebarOpened,
          ) }
          onTouchStart={this.onTouchStart}
          onTouchMove={this.onTouchMove}
        >
          {sidebarContent}
          <div className={styles.overlay}  onClick={this.props.onOverlayClick}/>
        </div>
        <div>
          <FixedContainer
            name="main"
            styles={[styles.slideAnimation, opened && openedStyles]}
          />
        </div>
        <div {...mergeStyles(
          [styles.page, opened ? styles.sidebarOpened : undefined],
          showBanner && styles.sidebarOpenedWithBanner,
          opened ? openedStyles : undefined,
        ) }>
          {children}
        </div>
      </div>
    );
  }

  private onTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    if (!e.touches[0]) {
      return;
    }

    const lastTouch = {
      identifier: e.touches[0].identifier,
      clientX: e.touches[0].clientX,
    };
    this.setState({ lastTouch } as ISidebarState);
  }

  // проверяем, нужно ли закрыть сайдбар в onTouchMove, а не в onTouchEnd,
  // чтобы событие вызывалось быстрее, иначе есть ощущение задержки
  private onTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    const startTouch = this.state.lastTouch;
    const endTouch = e.changedTouches[0];
    if (!startTouch || !endTouch) {
      return;
    }

    const isSameTouch = endTouch.identifier === startTouch.identifier;
    const swipeDistance = 50;

    if (isSameTouch && (startTouch.clientX - endTouch.clientX) > swipeDistance) {
      this.props.setSidebarState(false);

      this.setState({ lastTouch: undefined } as ISidebarState);
    }
  }
}
