import { isEqual } from "lodash";
import * as React from "react";

import { SelectMobile } from "../../common/select/select";
import { createSelectOptions, IOption } from "../../common/select_helper";
import {
  ESuburbanOfferFilter,
  IFieldProps,
  IsVisibleCheck,
  isSuburbanObjectGroup,
  isSaleType,
} from "../field";

import * as styles from "../field.css";

export const SUBURBAN_OFFER_FILTER_LABELS_EXP: { [key: number]: string } = {
  [ESuburbanOfferFilter.Any]: "От любого владельца",
  [ESuburbanOfferFilter.Builder]: "От застройщиков",
  [ESuburbanOfferFilter.Individual]: "От собственников",
};

const suburbanOfferFilterExpOptions: IOption[] = Object.keys(
  SUBURBAN_OFFER_FILTER_LABELS_EXP
).map((key) => ({
  id: key,
  label: SUBURBAN_OFFER_FILTER_LABELS_EXP[Number(key)],
}));

export class Component extends React.Component<IFieldProps> {
  private options = [
    {
      label: "",
      options: suburbanOfferFilterExpOptions,
    },
  ];

  public shouldComponentUpdate(nextProps: IFieldProps) {
    return (
      !isEqual(
        this.props.filter.suburbanOfferFilter,
        nextProps.filter.suburbanOfferFilter
      ) ||
      !isEqual(this.props.filter.isByHomeOwner, nextProps.filter.isByHomeOwner)
    );
  }

  public render() {
    const {
      filter: { isByHomeOwner, suburbanOfferFilter },
      actions: { setFromOwner, setSuburbanOfferFilter },
    } = this.props;
    const type = isByHomeOwner
      ? [String(ESuburbanOfferFilter.Individual)]
      : [String(suburbanOfferFilter || 0)];
    const processedOptions = createSelectOptions(
      this.options,
      type,
      this.props.filter
    );

    function handleValueChange(selectedIdsArray: string[]) {
      if (selectedIdsArray[0] === String(ESuburbanOfferFilter.Individual)) {
        setFromOwner(true);
        setSuburbanOfferFilter([String(ESuburbanOfferFilter.Any)]);
      } else {
        setSuburbanOfferFilter(selectedIdsArray);
        setFromOwner(false);
      }
    }

    return (
      <div className={styles["field"]} data-mark="FieldSuburbanOfferFilterExp">
        <h3 className={styles["title"]}>Об объявлении</h3>
        <span className={styles["filter-label"]}>Продавец</span>
        <SelectMobile
          id="suburban-offer-filter_exp"
          name="suburbanOfferFilterExp"
          label="От любого владельца"
          onValueChange={handleValueChange}
          value={processedOptions.value}
          options={processedOptions.options[0].options}
        />
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = (filter) =>
  isSaleType(filter) && isSuburbanObjectGroup(filter);
