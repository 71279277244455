import * as React from 'react';

import { Label } from '@cian/components';
import { commercialObjectType } from '../../../redux/filters/model';
import ButtonRadioGroupField, {
  IButtonRadioGroupFieldValue,
} from '../../../ui/button_radio_group_field/button_radio_group_field';
import { IFieldProps, IsVisibleCheck } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const values: IButtonRadioGroupFieldValue[] = [{
      value: 0,
      label: 'Свободный',
    }, {
      value: 1,
      label: 'По пропускам',
    }];

    const accessSystem = this.props.filter.accessSystem;
    const currentValue = accessSystem != null ? Number(accessSystem) : -1;

    return (
      <div className={styles.field} data-mark="FieldAccessSystem">
        <Label label="Вход">
          <ButtonRadioGroupField
            onValueChange={this.onValueChange}
            uncheckable
            emptyValue={-1}
            value={currentValue}
            values={values}
            buttonStyle={styles.buttonRadio}
            activeButtonStyle={styles.active}
          />
        </Label>
      </div>
    );
  }

  private onValueChange = (value: number) => {
    const accessSystem = value < 0 ? undefined : Boolean(value);
    this.props.actions.setAccessSystem(accessSystem);
  }
}

export const isVisible: IsVisibleCheck = filter =>
  filter.selectedObjectTypes.length === 1
  && filter.selectedObjectTypes[0] === commercialObjectType.Office;
