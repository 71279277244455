// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface ICreateSubscriptionRequestSchema {
  /** Email для подписки **/
  email: string;
  /** json query **/
  jsonQuery: object;
  /** Частота рассылки **/
  notificationFrequency?: ENotificationFrequency;
  /** Тип СП, например по адресу дома building_address **/
  savedSearchType?: ESavedSearchType;
  /** Где создана подписка **/
  source?: ESource;
  /** Подписка на новости **/
  subscribeNews?: boolean;
  /** Название закладки **/
  title?: string;
}
export enum ENotificationFrequency {
  /** Оперативно (по мере поступления, с 9-21) **/
  'Asap' = 'asap',
  /** 1 раз в сутки **/
  'EveryDay' = 'everyDay',
  /** 1 раз в час (9-21) **/
  'EveryHour' = 'everyHour',
  /** 1 раз в неделю **/
  'EveryWeek' = 'everyWeek',
  /** Не активирована **/
  'NotActivated' = 'notActivated',
  /** Удалено **/
  'Removed' = 'removed',
}
export enum ESavedSearchType {
  /** По адресу дома **/
  'BuildingAddress' = 'buildingAddress',
  /** Обычный **/
  'Common' = 'common',
  /** Без дома **/
  'NonBuildingAddress' = 'nonBuildingAddress',
}
export enum ESource {
  /** Подписка создата на выдаче **/
  'Listing' = 'listing',
  /** Подписка создата на карте **/
  'Map' = 'map',
}
