import { FavouritesIDs } from '../favorites/model';
import { DealType } from '../filters/model';
import {
  IPin,
} from './model';

export function addFavouritesToPins(
  pins: IPin[],
  favouritesIDs: FavouritesIDs,
  dealType: DealType,
): IPin[] {

  const favouritesIDsForDealType = dealType === DealType.Rent
    ? favouritesIDs.rent
    : favouritesIDs.sale;

  return pins.map(pin => {
    const pinOffersIDs = pin.offersIds.map(Number);

    return {
      ...pin,
      favourite: pinOffersIDs.some(pinOfferId => favouritesIDsForDealType.includes(pinOfferId)),
    };
  });
}
