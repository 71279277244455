import { IOfferDetail } from '../../../api/models/offer_card';
import {
  IOfferIconField,
  IOfferIconListMap,
} from '../../../redux/offer/model';

export const suburbanConditionsMap: IOfferIconListMap = new Map([
  ['hasBathhouse', {
    name: 'Баня',
    icon: 'card/ic-wash',
  }],
  ['hasGarage', {
    name: 'Гараж',
    icon: 'card/ic-garage',
  }],
  ['hasPool', {
    name: 'Бассейн',
    icon: 'card/ic-pool',
  }],
]);

export const responseToSuburbanRentConditions = (offer: IOfferDetail): IOfferIconField[] => {
  const newState: IOfferIconField[] = [];

  if (offer.offerType !== 'suburban' || offer.dealType !== 'rent') {
    return [];
  }

  suburbanConditionsMap.forEach((value: { name: string; icon: string; }, key: keyof IOfferDetail) => {
    if (offer[key]) {
      newState.push(value);
    }
  });

  return newState;
};
