import { isEqual } from 'lodash';
import * as React from 'react';

import { Label } from '@cian/components';
import { SelectMobile } from '../../common/select/select';

import { createSelectOptions } from '../../common/select_helper';
import {
  IFieldProps,
  IsVisibleCheck,
  withNewobjectSelected,
  yearType,
  isCommonFlatSale,
  isNotUpcomingSale,
  reduceVisibilityCheck,
} from '../field';

const style = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  private options = [{
    label: '',
    options: [{
      id: yearType.PutIntoOperation,
      label: 'Сдан',
    }, {
      id: yearType.getYear(0),
      label: yearType.getYear(0),
    }, {
      id: yearType.getYear(1),
      label: yearType.getYear(1),
    }, {
      id: yearType.getYear(2),
      label: yearType.getYear(2),
    }, {
      id: yearType.getYear(3),
      label: yearType.getYear(3),
    }, {
      id: yearType.Later,
      label: 'Позднее',
    }],
  }];

  public shouldComponentUpdate(nextProps: IFieldProps) {
    const selectedYears = this.props.filter.selectedYears;
    const nextSelectedYears = nextProps.filter.selectedYears;

    return !isEqual(selectedYears, nextSelectedYears);
  }

  public render() {
    const processedOptions = createSelectOptions(
      this.options,
      this.props.filter.selectedYears,
      this.props.filter,
    );

    return (
      <div className={style.field} data-mark="FieldSelectYear">
        <Label labelStyle={style.containerLabel} label="Год сдачи">
          <SelectMobile
            multiple
            id="select-object-year"
            name="selectMobile"
            label="Неважно"
            onValueChange={this.props.actions.setYear}
            value={processedOptions.value}
            options={processedOptions.options[0].options}
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = reduceVisibilityCheck(
  isNotUpcomingSale,
  (filter, optionalVisibilityParams) => {
    const isNewbuildingMapFilters = Boolean(optionalVisibilityParams && optionalVisibilityParams.isNewbuildingsMapMode);
    const commonFlatSale = isCommonFlatSale(filter);

    return withNewobjectSelected(filter) || (isNewbuildingMapFilters && commonFlatSale);
  },
)
