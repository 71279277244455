import { IOfferInfo } from '../../offer/model';
import { SearchOffersState } from '../../offers_list/model';

export function getJsonQuery(offer: IOfferInfo, searchList: SearchOffersState) {
  const { dealType, offerType, geo, category } = offer;
  const house = geo && geo.address && geo.address.find(item => item.type === 'house');
  // tslint:disable-next-line:no-any
  const jsonQuery: any = {
    _type: `${offerType}${dealType}`,
    engine_version: {
      type: 'term',
      value: 2,
    },
    geo: {
      type: 'geo',
      value: [{
        type: 'house',
        id: house && house.id,
      }],
    },
  };

  if (category && category.includes('daily')) {
    Object.assign(jsonQuery, {
      for_day: {
        type: 'term',
        value: '1',
      },
    });
  }

  if (searchList.initialized && searchList.jsonQuery && searchList.jsonQuery.room) {
    Object.assign(jsonQuery, {
      room: searchList.jsonQuery.room,
    });
  }

  return jsonQuery;
}
