import { TCommonActions } from './actions';
import { CommonState, defaultCommonState } from './model';

type Reducer = (state: CommonState, action: TCommonActions) => CommonState;

export const commonReducer: Reducer = (state = defaultCommonState, action: TCommonActions) => {
  switch (action.type) {
    case 'GetHeaderDataSuccess': {
      const {
        displayUsername,
        isAuthenticated,
        storedFiltersCount,
        userId,
        email,
        isProfi,
        firstName,
        lastName,
        phone,
        villagesUrl,
        lkType,
      } = action.payload;

      if (displayUsername == null || isAuthenticated == null) {
        return {
          ...state,
          villagesUrl,
          isAuthenticated: false,
        };
      }

      return {
        ...state,
        displayUsername,
        isAuthenticated,
        storedFiltersCount,
        authInfoInitialized: true,
        userId,
        userEmail: email,
        isProfi,
        firstName,
        lastName,
        phone,
        villagesUrl,
        lkType,
      };
    }

    case 'ClearHeaderData':
      return defaultCommonState;

    case 'SetRedirectData':
      return {
        ...state,
        redirectData: action.data,
      };

    case 'SetLastUrl':
      return {
        ...state,
        lastUrl: action.payload,
      };

    case 'SetUserRegion':
      return {
        ...state,
        userRegion: action.payload,
      };

    case 'SetFirstClientLoad':
      return {
        ...state,
        isFirstClientLoad: action.payload,
      };

    case 'SetOrienation':
      return state.orientation !== action.payload ? { ...state, orientation: action.payload } : state;

    case 'newPageEventSent':
      return {
        ...state,
        hasNewPageEventSent: true,
      };

    default:
      return state;
  }
};
