import { EHideOfferState, THideOfferActions } from './actions';
import { defaultHideOfferState, HideOfferState } from './model';

type Reducer = (state: HideOfferState, action: THideOfferActions) => HideOfferState;

const reducer: Reducer = (state = defaultHideOfferState, action) => {
  switch (action.type) {

    case 'SetTopPopupState': {

      return {
        ...state,
        isTopPopupOpened: action.payload,
      };
    }

    case 'SetHidingOffer': {

      return {
        ...state,
        hidingOffer: action.payload,
      };
    }

    case 'SetHideOfferState': {

      let isTopPopupOpened = state.isTopPopupOpened;
      if (
        action.payload.state === EHideOfferState.SUCCESS ||
        action.payload.state === EHideOfferState.ERROR
      ) {
        isTopPopupOpened = true;
      }

      return {
        ...state,
        hideOfferState: action.payload.state,
        errorCode: action.payload.errorCode,
        isTopPopupOpened,
      };
    }

    default:
      return state;
  }
};

export default reducer;
