import * as React from 'react';

import { IReadyBusinessGroupItem } from '../../../../../../../../../shared/repositories/announcements/entities/AnnouncementReferences/ReadyBusinessGroupItem';
import { IAssociateStringWithNumberService } from '../../../../services/AssociateStringWithNumberService';

export function useCategoryValues(
  groups: IReadyBusinessGroupItem[],
  values: Array<number>,
  associateStringWithNumberService: IAssociateStringWithNumberService,
): Array<number> {
  return React.useMemo<Array<number>>(() => {
    const valuesCollection = new Set<number>(values);

    return groups.reduce((accumulator, group) => {
      if (group.items.some(item => valuesCollection.has(item.id))) {
        accumulator.push(associateStringWithNumberService.get(group.groupType));
      }

      return accumulator;
    }, Array.of<number>());
  }, [groups, values, associateStringWithNumberService]);
}
