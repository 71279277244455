import { queryStringParams } from '../../map/components/InfrastructureDropdown/Item';

export const MAP_SEARCH_QUERY_PARAM = 'from_map';
export const MAP_NEWBUILDINGS_SEARCH_QUERY_PARAM = 'newbuildings_search';

const hasBooleanQueryParamChecker = (param: string) =>
  (queryString: string): boolean => queryString.includes(`${param}=true`);

export const hasMapNewbuildingsSearchQueryParam = hasBooleanQueryParamChecker(MAP_NEWBUILDINGS_SEARCH_QUERY_PARAM);

export function infrastructureFromQueryString(queryString: string) {
  return {
    withSchools: hasBooleanQueryParamChecker(queryStringParams.withSchools)(queryString),
    withKindergartens: hasBooleanQueryParamChecker(queryStringParams.withKindergartens)(queryString),
    withHospitals: hasBooleanQueryParamChecker(queryStringParams.withHospitals)(queryString),
    withGroceryStores: hasBooleanQueryParamChecker(queryStringParams.withGroceryStores)(queryString),
    withCafes: hasBooleanQueryParamChecker(queryStringParams.withCafes)(queryString),
  };
}
