export function isYouTube(src: string | undefined): boolean {
  return !!src && /youtu.?be/.test(src);
}

export function getYoutubeID(src: string): string | null {
  const ID_REGEXP = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i;
  const urlSplitted = src.match(ID_REGEXP) || [];
  return urlSplitted.length > 0 ? urlSplitted[1] : '';
}

export function getYoutubeVideoPreviewUrl(src: string): string {
  return `https://img.youtube.com/vi/${getYoutubeID(src)}/hqdefault.jpg`;
}

export function getYoutubeEmbedUrl(src: string): string {
  const id = getYoutubeID(src);

  return `https://www.youtube.com/embed/${id}`;
}

export interface IYouTubeEvent extends Event {
  target: IYouTubeEventTarget;
  data: PlayerState;
}

interface IYouTubeEventTarget extends EventTarget {
  playVideo(): void;
}

export enum PlayerState {
  NOT_PLAYED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  BUFFERING = 3,
  CUED = 5,
}

export const VIDEO_NOT_AVAILABLE_COMBINATION = [
  PlayerState.NOT_PLAYED,
  PlayerState.BUFFERING,
  PlayerState.NOT_PLAYED,
];

export const VIDEO_IS_PLAYING_STATES = [
  PlayerState.PLAYING,
  PlayerState.PAUSED,
  PlayerState.BUFFERING,
];
