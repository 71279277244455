/**
 * @todo Удалить файл с RS
 * @description Данный функционал появился в CD-223451
 */
let isNewAuthEnabled: null | boolean = null;

export function setIsNewAuthEnabled(enabled: boolean): void {
  isNewAuthEnabled = enabled;
}

export function getIsNewAuthEnabled(): boolean {
  return !!isNewAuthEnabled;
}
