import * as React from 'react';
import { ArticleHeading3, ArticleParagraph2, Switch } from '@cian/ui-kit';
import { FilterState, IFieldProps, IsVisibleCheck, withNewobjectSelected } from '../field';

const fieldStyles = require('../field.css');
const styles = require('./upcoming_sale.css');

export function Component({ filter, actions }: IFieldProps) {
  const { isUpcomingSale } = filter;
  const { setIsUpcomingSale } = actions;

  const handleChange = React.useCallback((event, value) => setIsUpcomingSale(value), [setIsUpcomingSale]);

  return (
    <div className={fieldStyles['label']} data-mark={'FieldIsUpcomingSale'}>
      <div className={styles['container']}>
        <label className={styles['description']}>
          <ArticleHeading3>
            Скоро в продаже
          </ArticleHeading3>
          <ArticleParagraph2 color="black_60">
            Продажи начнутся в ближайшие месяцы
          </ArticleParagraph2>
        </label>
        <div className={styles['toggle']}>
          <Switch
            name="is-upcoming-sale"
            checked={isUpcomingSale}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export const isVisible: IsVisibleCheck = (filter: FilterState) => {
  return withNewobjectSelected(filter);
};
