import { TOs } from '../components/download_app_button/download_app_button';
import { IMenuLink } from '../ui/menu/menu';

import { menuClick } from './analytics';
import { replaceNewbuildingSubdomainToGeo } from './newbuildingSubdomain';
import { routeFor } from './routeFor';
import { DESKTOP_LINKS } from './to_desktop';

export interface IGeOfferLinksData {
  authenticated: boolean;
  os?: TOs;
  requestUrl: string;
  geoSubdomain: string;
  homeIsOnOtherDomain: boolean;
  // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
  text: string | null;
  url: string | null;
}

export function myOffersClickHandler() {
  menuClick('my_offers');
}

export function addAdvClickHandler() {
  menuClick('add_adv');
}

export const getOfferLinks = (data: IGeOfferLinksData): IMenuLink[] => {
  const { os, requestUrl, homeIsOnOtherDomain, geoSubdomain, text, url } = data;
  const geoSubdomainHost = replaceNewbuildingSubdomainToGeo(requestUrl, geoSubdomain);

  return [
    {
      id: 1,
      content: 'Мои объявления',
      to: homeIsOnOtherDomain ? undefined : routeFor.MY_OFFERS_PROMO,
      href: homeIsOnOtherDomain ? geoSubdomainHost + routeFor.MY_OFFERS_PROMO : undefined,
      onClick: myOffersClickHandler,
      icon: 'ads-menu',
      hideOnTablet: true,
      // Скрываем только для устройство не Apple и не Android
      hide: !(os && ['apple', 'android'].includes(os)),
    },
    {
      id: 2,
      content: text || 'Разместить объявление',
      to: undefined,
      href: url || (homeIsOnOtherDomain ? geoSubdomainHost + DESKTOP_LINKS.ADD : DESKTOP_LINKS.ADD),
      onClick: addAdvClickHandler,
      icon: 'add-menu',
      hideOnTablet: true,
      // Показываем пункт только на устройствах Apple и Android
      hide: !(os && ['apple', 'android'].includes(os)),
      dataTestid: 'menu-add-offer-link',
    },
  ];
};
