import { IStyleConfig, mergeStyles } from '@cian/components';
import * as React from 'react';

import Icon from '../icon/icon';

const styles = require('./error.css');

interface IErrorProps {
  children?: React.ReactNode;
  onRefreshClick?: (e: React.MouseEvent<HTMLElement>) => void;
  errorStyle?: IStyleConfig;
}
/** Входит на все страницы по умолчанию в составе FixedContainer */
const Error: React.SFC<IErrorProps> = ({ children, onRefreshClick, errorStyle }: IErrorProps) => {
  return (
    <div {...mergeStyles(styles.error, onRefreshClick && styles.withRefresh, errorStyle)}>
      <div className={styles.message}>{children}</div>
      {onRefreshClick &&
        <div className={styles.refreshArea} onClick={onRefreshClick}>
          <Icon styleConfig={styles.refresh} name="refresh" />
        </div>}
    </div>
  );
};

export default Error;
