import { TAuthPageAction } from './actions';
import { AuthPageState, defaultAuthPageState } from './model';

type Reducer = (state: AuthPageState, action: TAuthPageAction) => AuthPageState;

export const authPageReducer: Reducer = (state = defaultAuthPageState, action: TAuthPageAction) => {
  switch (action.type) {
    case 'SetAuthPageDrawPending':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
