import { IHttpApi } from '@cian/http-api';

import {
  ICianApiSiteV1CreateSubscriptionRequest,
  ICianApiSiteV1CreateSubscriptionResponse200,
  ICianApiSiteV1CreateSubscriptionResponse400,
  fetchCianApiSiteV1CreateSubscription,
} from '../../repositories/monolith-python/cian-api/site/v1/create-subscription';

interface ICreateSubscriptionContext {
  httpApi: IHttpApi;
}

export async function createSubscription(
  { httpApi }: ICreateSubscriptionContext,
  parameters: ICianApiSiteV1CreateSubscriptionRequest,
): Promise<ICianApiSiteV1CreateSubscriptionResponse200> {
  const subscriptionResponse = await fetchCianApiSiteV1CreateSubscription<
    ICianApiSiteV1CreateSubscriptionResponse200,
    ICianApiSiteV1CreateSubscriptionResponse400
  >({
    httpApi,
    parameters,
  });

  if (subscriptionResponse.statusCode !== 200) {
    const { message, errors } = subscriptionResponse.response.data;

    throw new Error(`Ошибка при сохранении поиска: ${message}\n${errors}`);
  }

  return subscriptionResponse;
}
