import {
  ISelectMobileGroupOrOption,
  IStyleConfig,
} from '@cian/components';
import * as React from 'react';
import CianSelect from '../select_mobile/select_mobile';

export interface IMultiselectProps {
  id?: string;
  name?: string;
  label?: string;
  multiple?: boolean;
  options: ISelectMobileGroupOrOption[]; // all options
  value: string[]; // ids of selected options
  onClick?: React.EventHandler<React.MouseEvent<HTMLElement>>;
  onValueChange?: (options: string[]) => void;
  useNative?: boolean;
  containerStyles?: IStyleConfig;
  labelNode?: React.ReactNode;
}
// TODO: DELETE THIS COMPONENT
export class Multiselect extends React.Component<IMultiselectProps, {}> {
  public render() {
    return <CianSelect {...this.props} />;
  }
}
