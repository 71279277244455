import { defaultState, IFiltersPageState } from './model';
import { TFiltersPageActions } from './actions';

type Reducer = (
  state: IFiltersPageState,
  action: TFiltersPageActions,
) => IFiltersPageState;

export const filtersPageReducer: Reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'FiltersPageChangePendingState':
      return {
        ...state,
        pending: action.pending,
      };
    case 'FiltersPageSkipNextLoading':
      return {
        ...state,
        skipNextLoading: action.skip,
      };
    default: {
      return state;
    }
  }
};
