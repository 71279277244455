import { RedirectFunction, RouterState } from 'react-router';

export function finishDateCheckRedirect(nextState: RouterState, replace: RedirectFunction) {
  const { pathname, search, query } = nextState.location;
  const finishDate = query['finish_date'];

  if (typeof finishDate === 'string' && finishDate !== '') {
    const year = Number(finishDate.split('.')[1]);

    if (!isNaN(year)) {
      const finishDateQueryParam = `finish_date=${finishDate}`;
      const maxYear = new Date().getFullYear() + 4;
      const yearQueryParam = year >= maxYear
        ? `yeargte=${maxYear}`
        : `year%5B0%5D=${year}`;
      const newSearch = search.replace(finishDateQueryParam, yearQueryParam);

      replace(pathname + newSearch);
    }
  }
}
