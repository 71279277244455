import { ECoworkingOfferType } from '../../api/models/offer_card';

export function coworkingOfferTypeToAnalyticsRoadMap(coworkingOfferType: ECoworkingOfferType): number {
  switch (coworkingOfferType) {
    case ECoworkingOfferType.Office:
      return 15;
    case ECoworkingOfferType.FixedWorkplace:
      return 16;
    case ECoworkingOfferType.FlexibleWorkplace:
      return 17;
    case ECoworkingOfferType.ConferenceHall:
      return 18;
    case ECoworkingOfferType.MeetingRoom:
      return 19;
    default:
      return 14;
  }
}
