import { numberToPrettyString } from '@cian/components';
import { IOfferInfo } from '../../../redux/offer/model';

import { FDealType, getDealTypeFromOffer } from '../../category';

const CURRENCY_UNIT = {
  eur: 'евро',
  rur: 'руб.',
  usd: 'USD',
};

export const getPriceLabel = (offer: IOfferInfo) => {
  const dealType = getDealTypeFromOffer(offer);

  const { bargainTerms } = offer;

  if (!bargainTerms) {
    return null;
  }

  const { utilitiesTerms, currency } = bargainTerms;

  if (dealType === FDealType.Sale && currency && bargainTerms.price) {
    // tslint:disable-next-line: max-line-length
    return `Цена продажи - ${numberToPrettyString(bargainTerms.price)} ${CURRENCY_UNIT[currency]}.`.replace(/\.{1,}$/, '.');
  }

  if ((dealType & FDealType.Rent) && currency) {
    const duration = dealType & FDealType.RentDaily ? 'в сутки' : 'в месяц';

    let price: number = bargainTerms.price || 0;

    if (utilitiesTerms && !utilitiesTerms.includedInPrice && utilitiesTerms.price && utilitiesTerms.price > 0) {
      price += utilitiesTerms.price;
    }

    return `Цена аренды - ${numberToPrettyString(price)} ${CURRENCY_UNIT[currency]} ${duration}.`;
  }

  return null;
};
