import * as React from 'react';
import { connect } from 'react-redux';
import { TTypedThunkDispatch } from '../../../redux/model';
import { openSaveFilterPopupAction } from '../../../redux/save_filter_popup';
import { IFieldProps, IsVisibleCheck } from '../field';
import { trackSaveSearchClick } from './tracking';

const styles = require('./index.css');

interface ISaveButtonDispatchProps {
  openSaveFilterPopup(): void;
}

type SaveButtonProps = ISaveButtonDispatchProps & IFieldProps;

export class SaveButton extends React.Component<SaveButtonProps, {}> {
  public render() {
    return (
      <div className={styles['container']} data-mark="ButtonSaveSearch">
        <button type="button" className={styles['button']} onClick={this.handleSaveSearch}>
          Сохранить поиск
        </button>
      </div>
    );
  }

  private handleSaveSearch = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    trackSaveSearchClick();

    this.props.openSaveFilterPopup();
  }
}

export const Component = connect<
  {},
  ISaveButtonDispatchProps,
  IFieldProps
>(
  () => ({}),
  (dispatch: TTypedThunkDispatch) => ({
    openSaveFilterPopup: () => {
      dispatch(openSaveFilterPopupAction());
    },
  }),
)(SaveButton);

export const isVisible: IsVisibleCheck = filter => true;
