import * as React from 'react';

import { ButtonCheckbox, mergeStyles } from '@cian/components';
import * as fields from '../../fields/';
import { commercialObjectType, IFieldProps, IsVisibleCheck, TObjectType } from '../field';

const styles = require('../basement_floors/basement_floors.css');
const fieldStyles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const { basementFloor, semibasementFloor, firstFloor } = this.props.filter;
    const buttonCheckboxStyle = [
      fieldStyles.buttonCheckbox,
    ];

    return (
      <div {...mergeStyles(fieldStyles.field, fieldStyles.fakeLabel)} data-mark="FieldBasementFloors">
        <div className={styles.buttons}>
          <ButtonCheckbox
            mobile
            checked={basementFloor}
            onValueChange={this.props.actions.setBasementFloor}
            id="basement-floor"
            name="basement-floor"
            buttonCheckboxStyle={buttonCheckboxStyle}
          >
            Подвал
          </ButtonCheckbox>
          <ButtonCheckbox
            mobile
            checked={semibasementFloor}
            onValueChange={this.props.actions.setSemibasementFloor}
            buttonCheckboxStyle={buttonCheckboxStyle}
            id="semibasement-floor"
            name="semibasement-floor"
          >
            Полуподвал
          </ButtonCheckbox>
          <ButtonCheckbox
            mobile
            checked={firstFloor === true}
            onValueChange={this.props.actions.setFirstFloor}
            buttonCheckboxStyle={buttonCheckboxStyle}
            id="first-floor"
            name="first-floor"
          >
            Первый этаж
          </ButtonCheckbox>
        </div>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => {
  const notAllowedTypes: TObjectType[] = [
    commercialObjectType.Building,
    commercialObjectType.RentalBusiness,
    commercialObjectType.ReadyBusiness,
  ];

  return fields.floorCommerce.isVisible(filter)
    && filter.selectedObjectTypes.length > 0
    && filter.selectedObjectTypes.every((type: TObjectType) => !notAllowedTypes.includes(type));
};
