import { CheckboxField, mergeStyles } from '@cian/components';
import * as React from 'react';

import { IFieldProps, IsVisibleCheck } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    return this.props.filter.hasVideo !== nextProps.filter.hasVideo;
  }

  public render() {
    const { filter: { withPhoto } } = this.props;
    const labelStyles = mergeStyles(
      styles['labelCheckbox'],
      withPhoto && styles['checked'],
    ).className;

    return (
      <div className={styles['checkbox']} data-mark="FieldWithVideo">
        <CheckboxField
          mobile
          label="Видео"
          labelStyle={labelStyles}
          value={this.props.filter.hasVideo}
          onValueChange={this.props.actions.setHasVideo}
        />
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => true;
