import { ResourceStatus } from '../../common/model';
import { TSeoMapActions } from './actions';
import { defaultSeoMapState, SeoMapState } from './model';

type Reducer = (state: SeoMapState, action: TSeoMapActions) => SeoMapState;

const seoMapReducer: Reducer = (state = defaultSeoMapState, action) => {
  switch (action.type) {
    case 'GetSeoMapPending':
      return { ...state, status: ResourceStatus.Pending };

    case 'GetSeoMapSuccess':
      return { ...action.payload.seoMain, status: ResourceStatus.Success };

    default:
      return state;
  }
};

export default seoMapReducer;
