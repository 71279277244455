import { Label } from '@cian/components';
import * as React from 'react';

import { SelectMobile } from '../../../common/select/select';

import {
  IFieldProps,
  isFlatObjectGroup,
  IsVisibleCheck,
  BathroomType,
} from '../../field';

const style = require('../../field.css');

export enum EBathroomsType {
  Any = 0,
  Merge = 1,
  Separate = 2,
  MoreTwo = 3,
}

export class Component extends React.Component<IFieldProps, object> {
  private options = [{
    label: '',
    options: [{
      id: String(EBathroomsType.Any),
      label: 'Любой',
    }, {
      id: String(EBathroomsType.Merge),
      label: 'Совмещенный',
    }, {
      id: String(EBathroomsType.Separate),
      label: 'Раздельный',
    }, {
      id: String(EBathroomsType.MoreTwo),
      label: '2 и более',
    }],
  }];

  public render() {
    return (
      <div className={style['filter-layout']}>
        <div className={style.field} data-mark="FieldBathroomType">
          <Label label="Санузел">
            <SelectMobile
              id="select-bathrooms-type"
              name="selectMobile"
              label="Любой"
              onValueChange={this.setBathroomsType}
              value={this.getValue()}
              options={this.options[0].options}
            />
          </Label>
        </div>
      </div>
    );
  }

  private getValue = () => {
    const { filter: { bathroomType, bathroomsCount } } = this.props;

    if (bathroomType === BathroomType.Separated || bathroomType === BathroomType.Combined) {
      return [String(bathroomType)];
    }

    if (bathroomsCount) {
      return [String(EBathroomsType.MoreTwo)];
    }

    return [String(EBathroomsType.Any)];
  }

  private setBathroomsType = (value: string[]) => {
    const newValue = Number(value[0]);

    if (newValue === EBathroomsType.MoreTwo) {
      this.props.actions.setBathroomsCount(2);
      this.props.actions.setBathroomType(BathroomType.Any);

      return;
    }

    if (newValue === EBathroomsType.Merge) {
      this.props.actions.setBathroomsCount(0);
      this.props.actions.setBathroomType(BathroomType.Combined);

      return;
    }

    if (newValue === EBathroomsType.Separate) {
      this.props.actions.setBathroomsCount(0);
      this.props.actions.setBathroomType(BathroomType.Separated);

      return;
    }

    this.props.actions.setBathroomsCount(0);
    this.props.actions.setBathroomType(BathroomType.Any);
  }
}

export const isVisible: IsVisibleCheck = filter => isFlatObjectGroup(filter) && !filter.withNewobject;
