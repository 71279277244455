import * as React from 'react';
import { ButtonCheckbox, Label } from '@cian/components';
import { IFieldProps, isNotUpcomingSale, IsVisibleCheck, reduceVisibilityCheck, withNewobjectSelected } from '../field';
import ButtonCheckboxContainer from '../../../ui/button_checkbox_container/button_checkbox_container';

const styles = require('../field.css');

export function Component({ filter, actions: { setNewobjectCompilation }}: IFieldProps) {
  const { newobjectCompilation } = filter;
  const { isSalesLeader, isSalesStart } = newobjectCompilation;

  const optionList = React.useMemo(() => ([
    {
      key: 'sales-start',
      value: isSalesStart,
      onChange: (value: boolean) => setNewobjectCompilation('isSalesStart', value),
      label: 'Старт продаж',
    },
    {
      key: 'sales-leader',
      value: isSalesLeader,
      onChange: (value: boolean) => setNewobjectCompilation('isSalesLeader', value),
      label: 'Лидеры продаж',
    },
  ]), [newobjectCompilation, isSalesStart, isSalesLeader, setNewobjectCompilation]);

  return (
    <div className={styles.label} data-mark={'FieldNewobjectCompilation'}>
      <Label label={'Подборки ЖК'}>
        <ButtonCheckboxContainer data-testId={'newobject-compilation-checkbox-container'}>
          {optionList.map(optionItem => (
            <ButtonCheckbox
              key={optionItem.key}
              mobile
              buttonCheckboxStyle={styles.buttonCheckbox}
              checked={optionItem.value}
              onValueChange={optionItem.onChange}
            >
              {optionItem.label}
            </ButtonCheckbox>

          ))}
        </ButtonCheckboxContainer>
      </Label>

    </div>
  );
}

export const isVisible: IsVisibleCheck = reduceVisibilityCheck(
  isNotUpcomingSale,
  withNewobjectSelected,
);
