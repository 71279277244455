import { EBehavior, NestedCheckboxGroupsMobile, SearchField } from '@cian/nested-checkbox-groups';
import { ArticleHeading4, Button, Close16, ModalFullscreen } from '@cian/ui-kit';

import cn from 'classnames';
import * as React from 'react';

import {
  HandleNestedCheckboxClick,
  HandleSearchFieldChange,
  NestedCheckboxGroupsModalProps,
  NestedCheckboxGroupsModalState,
} from './types';

import * as styles from './NestedCheckboxGroupsModal.css';

export class NestedCheckboxGroupsModal extends React.PureComponent<
  NestedCheckboxGroupsModalProps,
  NestedCheckboxGroupsModalState
> {
  public static getDerivedStateFromProps(
    props: NestedCheckboxGroupsModalProps,
    state: NestedCheckboxGroupsModalState,
  ): NestedCheckboxGroupsModalState | null {
    if (state.isOpen) {
      return null;
    }

    return { ...state, values: props.values };
  }

  public constructor(props: NestedCheckboxGroupsModalProps) {
    super(props);

    this.state = {
      isOpen: false,
      searchValue: '',
      values: props.values,
    };
  }

  public render(): React.ReactNode {
    const { groups, children, title, withoutSearch } = this.props;
    const { values, isOpen, searchValue } = this.state;

    return (
      <>
        <ModalFullscreen open={isOpen} onClose={this.handleClose}>
          <section className={styles['container']}>
            <header className={styles['header']}>
              <div className={styles['heading']}>
                <div className={styles['title-wrapper']}>
                  <ArticleHeading4>{title}</ArticleHeading4>
                </div>
                <button className={styles['close-button']} type="button" onClick={this.handleClose}>
                  <Close16 />
                </button>
              </div>
              {!withoutSearch && (
                <div className={styles['search-field-wrapper']}>
                  <SearchField value={searchValue} onChange={this.handleSearchFieldChange} />
                </div>
              )}
            </header>
            <div className={styles['content']}>
              <div
                className={cn(styles['nested-checkbox-groups-wrapper'], {
                  [styles['multi-groups']]: groups.length > 1,
                })}
              >
                <NestedCheckboxGroupsMobile
                  behavior={EBehavior.Multiply}
                  groups={groups}
                  searchValue={searchValue}
                  values={values}
                  onNestedCheckboxClick={this.handleNestedCheckboxClick}
                />
              </div>
            </div>
            <footer className={styles['footer']}>
              <Button fullWidth disabled={!values.length} size="M" theme="fill_secondary" onClick={this.handleReset}>
                Сбросить
              </Button>
              <Button fullWidth size="M" theme="fill_primary" onClick={this.handleSave}>
                Применить
              </Button>
            </footer>
          </section>
        </ModalFullscreen>
        {children({ isOpen, setClose: this.handleClose, setOpen: this.handleOpen })}
      </>
    );
  }

  private readonly handleSearchFieldChange: HandleSearchFieldChange = (_event, value) => {
    this.setState({ searchValue: value });
  };

  private readonly handleClose: VoidFunction = () => {
    this.setState({ isOpen: false });
  };

  private readonly handleOpen: VoidFunction = () => {
    this.setState({ isOpen: true });
  };

  private readonly handleNestedCheckboxClick: HandleNestedCheckboxClick = checkboxGroupsItemChangeModel => {
    const newValues = checkboxGroupsItemChangeModel.checkboxGroupItemChangeModel.checkboxGroupChangeModel.allValues;

    this.setState({ values: newValues });
  };

  private readonly handleReset: VoidFunction = () => {
    const { onReset, closeOnReset } = this.props;

    this.setState({ values: [] });

    onReset?.([]);

    if (closeOnReset) {
      this.handleClose();
    }
  };

  private readonly handleSave: VoidFunction = () => {
    const { onSave, closeOnSave } = this.props;
    const { values } = this.state;

    onSave?.(values);

    if (closeOnSave) {
      this.handleClose();
    }
  };
}
