import { without } from 'lodash';
import * as React from 'react';

import { BuildingClassType, commercialObjectType } from '../../../redux/filters/model';
import { IFieldProps, IsVisibleCheck } from '../field';

import {
  ButtonCheckbox,
  Label,
} from '@cian/components';

const styles = require('./building_class.css');
const fieldStyles = require('../field.css');

type ClassMap = { value: BuildingClassType, label: string };

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const classesMap: ClassMap[] = [{
      value: BuildingClassType.APlus,
      label: 'A+',
    }, {
      value: BuildingClassType.A,
      label: 'A',
    }, {
      value: BuildingClassType.BPlus,
      label: 'B+',
    }, {
      value: BuildingClassType.B,
      label: 'B',
    }, {
      value: BuildingClassType.BMinus,
      label: 'B-',
    }, {
      value: BuildingClassType.C,
      label: 'C',
    }];

    const buttonCheckboxStyle = [
      styles.checkbox,
      fieldStyles.buttonCheckbox,
    ];

    return (
      <div className={styles.field} data-mark="FieldBuildingClass">
        <Label label="Класс здания">
          <div className={styles.classes}>
            {classesMap.map((item: ClassMap, index: number) => (
              <ButtonCheckbox
                mobile
                buttonCheckboxStyle={buttonCheckboxStyle}
                name={`filter_bulding_class_${item.value}`}
                key={index}
                checked={this.props.filter.selectedBuildingClasses.includes(item.value)}
                onValueChange={() => this.props.actions.toggleBuildingClass(item.value)}
                >
                {item.label}
              </ButtonCheckbox>
            ))}
          </div>
        </Label>
      </div>
    );
  }
}

const allowedObjectTypes = [
  commercialObjectType.Building,
  commercialObjectType.Marketplace,
  commercialObjectType.Office,
  commercialObjectType.Production,
  commercialObjectType.Warehouse,
];

export const isVisible: IsVisibleCheck = filter =>
  filter.selectedObjectTypes.length > 0
  && without(filter.selectedObjectTypes, ...allowedObjectTypes).length === 0;
