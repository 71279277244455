import { memoize, groupBy, values } from 'lodash';
import * as v4 from 'uuid/v4';
import { DeviceType } from '../../redux/common/model';
import { parseCookies } from '@cian/utils/lib/shared/cookie';

export interface ITeaserEventProps {
  soprApi: string;
  user_id: string | null;
  announcement_id?: number;
  demand_location?: string;
  pagenumber: number | null;
  position: number | null;
  pageType: string;
  device_type: DeviceType;
  ab_group: number | null;
  block_type: string;
  isTeaserShow: 1 | 0;
  sc: string | null;
  list_id?: string | null;
}

const getSearchId = memoize((sc: string | null) => v4());

export const getViewportHeight = () =>
 Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
export const getViewportWidth = () =>
 Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

export function sendTeaserEvent(events: Array<ITeaserEventProps>) {
  const viewport = {
    width: getViewportWidth(),
    height: getViewportHeight(),
  };
  const cid = parseCookies(document.cookie)['_CIAN_GK'];

  const uniqEventChunks = values(groupBy(events, 'sc'));

  uniqEventChunks.forEach(items => {
    const firstEvent = items[0];
    const { soprApi, user_id, ab_group, pageType, device_type, sc, pagenumber, list_id } = firstEvent;
    fetch(`${soprApi}teaser/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          user_id,
          list_id: list_id !== undefined ? list_id : getSearchId(sc),
          device_type,
          viewport,
          ab_group,
          cid,
          pageType,
          pagenumber,
          sc,
          items: items.map(
            ({soprApi, user_id, ab_group, pageType, device_type, sc, pagenumber, list_id, ...props}) => props),
      }),
    });
  });
}
