import { isLetter } from './text';

export interface IQueryParams {
  [key: string]: string;
}

export function isTesting(url: string) {
  return /localhost/.test(url);
}

export function getHost(url: string) {
  return url.replace(/https?:\/\//, '').replace(/(\/.*)/, '').replace(/(\?.*)/, '');
}

export function isIpHost(url: string) {
  const preparedUrl = getHost(url);
  //tslint:disable-next-line:max-line-length
  const validIpRegexp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(:([0-9]{1,5}))?$/;
  return validIpRegexp.test(preparedUrl);
}

export function getSubdomain(url: string) {
  const host = getHost(url);
  const domainLevels = host.split('.');

  if (isTesting(host)) {
    return domainLevels[0];
  }

  return isIpHost(host) || domainLevels.length < 3
    ? 'www'
    : domainLevels[0];
}

export function getPathname(url: string) {
  const urlWithoutProtocol = url
    .replace(/https?:\/\//, '');

  if (urlWithoutProtocol.includes('/')) {
    const result = '/' + urlWithoutProtocol
        .split('/').slice(1).join('/')
        .split('?')[0];

    return result !== '/'
      ? removeTrailingSlash(result)
      : result;
  }
  return '/';
}

export function getSearch(url: string) {
  const params = url.split('?')[1];
  return params
    ? '?' + params
    : '';
}

export function getUrlForRequest(url: string = ''): string {
  const preparedUrl = url
    .replace(/https?:\/\//, '');

  if (preparedUrl.includes('/')) {
    return '/' + preparedUrl
      .split('/')
      .slice(1)
      .join('/');
  }

  return '/';
}

export type Match = {
  [key: string]: string,
};

export function matchMask(url: string, urlMask: string): Match | null {
  const segmentsSeparator = '/';
  const maskPrefix = ':';

  const compareSegments = function(urlSegment: string, maskSegment: string) {
    return (maskSegment && maskSegment[0] === maskPrefix) ||
      maskSegment === urlSegment;
  };

  const removeLastSeparator = function(value: string) {
    const lastPart = value.slice(-segmentsSeparator.length);
    if (lastPart === segmentsSeparator) {
      return value.slice(0, -segmentsSeparator.length);
    }
    return value;
  };

  const urlSegments = removeLastSeparator(url.trim()).split(segmentsSeparator);
  const maskSegments = removeLastSeparator(urlMask.trim()).split(segmentsSeparator);

  if (urlSegments.length !== maskSegments.length) {
    return null;
  }

  return urlSegments.reduce(function(match: Match | null, urlSegment: string, index: number) {
    const maskSegment = maskSegments[index];
    if (match == null) {
      return null;
    }

    if (match[maskSegment]) {
      throw new Error(`duplicate mask key ${maskSegment}`);
    }

    const compareResult = compareSegments(urlSegment, maskSegment);
    if (!compareResult) {
      return null;
    }
    if (urlSegment && maskSegment) {
      match[maskSegment] = urlSegment;
    }
    return match;
  }, {});
}

export function getUrlParams(url: string): string[] {
  return url
    .replace('://', '')
    .split('/')
    .slice(1);
}

export function replaceSubdomain(currentUrl: string, subdomain: string) {
  const currentSubdomain = getSubdomain(currentUrl);
  return isTesting(currentSubdomain)
    ? currentUrl.replace('localhost', `${subdomain}.localhost`)
    : currentUrl.replace(currentSubdomain, subdomain);
}

// return query string without `?`
export function getQueryString(url: string): string {
  return url.includes('?')
    ? url.replace(/[^?]*\?/, '')
    : '';
}

export function queryStringFromLocation()  {
  return typeof window !== 'undefined'
    ? location.search.replace('?', '')
    : '';
}

export function getQueryParams(search: string = ''): IQueryParams {
  let params: IQueryParams = {};

  if (search) {
    search
      .split('&')
      .forEach((param) => {
        const [key, value] = param.split('=');

        params[key] = value;
      });
  }

  return params;
}

export function stringifyQueryParams(params: IQueryParams): string {
  const search = Object.keys(params)
    .map((paramName) => [paramName, params[paramName]].join('='))
    .join('&');

  return search ? '?' + search : '';
}

export function mergeQueryParams(initialQuery: string, newParams: IQueryParams): string {
  let search = initialQuery.replace(/^\?/, '');

  const prevParams = getQueryParams(search);
  const nextParams = Object.assign({}, prevParams, newParams);

  return stringifyQueryParams(nextParams);
}

export function appendQueryParam(initialQuery: string, addParam: string): string {
  const paramSeparator = initialQuery && addParam ? '&' : '';
  return `${initialQuery}${paramSeparator}${addParam}`;
}

import { toPairs } from 'lodash';
import { ILogger } from '@cian/logger';

export function appendQueryParams(initialSearch: string, params: IQueryParams): string {
  return toPairs(params).reduce((search, [key, value]) => {
    return appendQueryParam(search, `${key}=${value}`);
  }, initialSearch);
}

export function removeQueryParam(initialQuery: string, ...removeParams: string[]): string {
  const [url, search] = initialQuery.split('?');
  const filteredSearch = (search || '')
    .split('&')
    .map(param => param.split('='))
    .filter(([key, value]) => !removeParams.includes(key))
    .map(pair => pair.join('='))
    .join('&');
  const searchSeparator = filteredSearch ? '?' : '';
  return `${url}${searchSeparator}${filteredSearch}`;
}

export function removeQueryParamsWithKeysContainWord(initialQuery: string, word: string): string {
  const [url, search] = initialQuery.split('?');
  const filteredSearch = (search || '')
    .split('&')
    .map(param => param.split('='))
    .filter(([key, value]) => !key.includes(word))
    .map(pair => pair.join('='))
    .join('&');
  const searchSeparator = filteredSearch ? '?' : '';
  return `${url}${searchSeparator}${filteredSearch}`;
}

export function removeTrailingSlash(str: string) {
  if (str.slice(-1) === '/') {
    return str.slice(0, -1);
  }
  return str;
}

export function getFullUrl(subdomain: string, relativeUrl: string): string {
  const hostnameParts = window.location.host.split('.');
  let oldSubdomain: string | undefined = '';
  if (hostnameParts.length > 2) {
    oldSubdomain = hostnameParts.shift();
  }
  if (oldSubdomain !== subdomain) {
    hostnameParts.unshift(subdomain);
  } else {
    hostnameParts.unshift(oldSubdomain);
  }

  const hostname = hostnameParts.filter(Boolean).join('.');

  return `${window.location.protocol}//${hostname}${relativeUrl}`;
}

// проверка на валидность символов для url
// см. https://tools.ietf.org/html/rfc3986#section-2
export function isValidForUrl(text: string | number): boolean {
  const validChars = '0123456789-._~:/?#[]@!$&\'()*+,;=`.';

  return text.toString()
    .split('')
    .every(char => isLetter(char) || validChars.includes(char.toLowerCase()));
}

// tslint:disable-next-line:no-any
export const serializeParams = (obj: any, forceEncode: boolean = false) =>
  Object.keys(obj)
    .filter(k => obj[k] != null)
    .map(k => {
      const value = isValidForUrl(obj[k]) && !forceEncode
        ? obj[k]
        : encodeURIComponent(obj[k]);

      return `${encodeURIComponent(k)}=${value}`;
    })
    .join('&');

export const deserializeParams = (str: string, logger: ILogger | null) => {
  const pairs = str.split('&');
  const result: { [key: string ]: string | undefined} = {};
  try {
    pairs.forEach(pair => {
      const match = pair.match(/(.+)=(.+)/);
      if (match && match[1] && match[2]) {
        const value = isValidForUrl(match[2])
          ? match[2]
          : decodeURIComponent(match[2]);

        result[decodeURIComponent(match[1])] = value;
      }
    });
  } catch (e) {
    if (logger) {
      logger.error(new Error(`Invalid decode ${str} from deserializeParams`));
    }

    return result;
  }

  return result;
};

export function makeRedirectRes(location: string) {
  return { result: { data: { redirectData: { location } } } };
}
