import * as React from 'react';

import { Label, mergeStyles } from '@cian/components';
import ButtonRadioGroupField from '../../../ui/button_radio_group_field/button_radio_group_field';
import { IFieldProps, isCommonFlatSale, IsVisibleCheck, withNewobjectSelected } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const { filter: { decoration }, specialStyles } = this.props;

    return (
      <div {...mergeStyles(
          styles.field,
          specialStyles,
        )}
        data-mark="FieldDecoration"
      >
        <Label label="Отделка">
          <ButtonRadioGroupField
            uncheckable
            value={decoration}
            onValueChange={this.props.actions.setDecoration}
            values={[
              { value: true, label: 'С отделкой' },
              { value: false, label: 'Без отделки' },
            ]}
            buttonStyle={styles.buttonRadio}
            activeButtonStyle={styles.active}
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = (filter, optionalVisibilityParams) => {
  const isNewbuildingMapFilters = Boolean(optionalVisibilityParams && optionalVisibilityParams.isNewbuildingsMapMode);
  const commonFlatSale = isCommonFlatSale(filter);

  return withNewobjectSelected(filter) || (isNewbuildingMapFilters && commonFlatSale);
}
