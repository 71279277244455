import { IRegionInfo } from '../../api/api';
import { ReduxState } from '../../redux/model';
import { defaultRegions } from '../../redux/regions/model';

export function selectCurrentRegion(state: ReduxState): IRegionInfo {
  const { regions } = state;

  const currentLocation = regions.regionMeta.location;
  const defaultLocation = defaultRegions.regionMeta.location;

  return currentLocation || defaultLocation;
}
