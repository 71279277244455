import { isEqual } from 'lodash';
import * as React from 'react';

import { SelectMobile } from '../../common/select/select';
import { createSelectOptions } from '../../common/select_helper';
import {
  IFieldProps,
  IsVisibleCheck,
  sellerType,
  withNewobjectSelected,
  isCommonFlatSale,
  isNotUpcomingSale,
  reduceVisibilityCheck,
} from '../field';

const style = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  private options = [{
    label: '',
    options: [{
      id: sellerType.All,
      label: 'От застройщиков и агентов',
    }, {
      id: sellerType.Developer,
      label: 'От застройщиков',
    }, {
      id: sellerType.Agent,
      label: 'От агентов и частных лиц',
    }],
  }];

  public shouldComponentUpdate(nextProps: IFieldProps) {
    return !isEqual(this.props.filter.selectedSellers, nextProps.filter.selectedSellers);
  }

  public render() {
    const processedOptions = createSelectOptions(
      this.options,
      this.props.filter.selectedSellers,
      this.props.filter,
    );

    return (
      <div className={[style.field, style.fakeLabel].join(' ')} data-mark="FieldSelectSeller">
        <SelectMobile
          id="select-object-seller"
          name="selectMobile"
          label="От застройщиков и агентов"
          onValueChange={this.props.actions.setSeller}
          value={processedOptions.value}
          options={processedOptions.options[0].options}
        />
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = reduceVisibilityCheck(
  isNotUpcomingSale,
  (filter, optionalVisibilityParams) => {
    const isNewbuildingMapFilters = Boolean(optionalVisibilityParams && optionalVisibilityParams.isNewbuildingsMapMode);
    const commonFlatSale = isCommonFlatSale(filter);

    return withNewobjectSelected(filter) || (isNewbuildingMapFilters && commonFlatSale);
  },
);
