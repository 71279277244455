import { TMicroserviceName } from '../../network_framework/microservices_urls';
import { IChatsConfig } from '../../shared/types/chatsConfig';

export interface IClientConfig {
  projectName: string;
  api: {
    microservicesUrls?: { [key in TMicroserviceName]: string };
    baseApiHost?: string;
  };
  chats?: IChatsConfig;
  demand?: {
    httpApiBaseUrl: string;
  };
  hiddenObjects?: {
    lkUrl: string;
  };
  timeout: number;
  indexedDBversion?: string;
  networkFramework: {
    timeout: number;
    circuitBreaker: {
      failureTreshold: number;
      samplingDuration: number;
      minimumSamples: number;
      openDuration: number;
    };
  };
  getUserDataTimeout?: number;
}

export const defaultConfig: IClientConfig = {
  projectName: 'frontend-mobile-website',
  api: {},
  timeout: 30000,
  networkFramework: {
    timeout: 30000,
    circuitBreaker: {
      failureTreshold: 0.9,
      samplingDuration: 10000,
      minimumSamples: 100,
      openDuration: 1000,
    },
  },
  getUserDataTimeout: 1000,
};
