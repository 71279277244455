import { Observable } from 'rxjs/Observable';
import { Subscriber } from 'rxjs/Subscriber';
import * as superagent from 'superagent';
import * as jsonp from 'superagent-jsonp';
import { TMicroserviceName } from './microservices_urls';
import { isServer } from '../mobile_website/utils/isomorphic';
import { IClientConfig } from '../client/DEPRECATED/config';
import { IConfig } from '../server/DEPRECATED/config';

export type TRequestMethod = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface IRequestConfiguration {
  url: string;
  method?: TRequestMethod;
  body?: any; // tslint:disable-line:no-any
  timeout?: number;
  headers?: {
    [name: string]: string;
  };
  absoluteUrl?: boolean;
  jsonp?: boolean;
  microserviceApiName?: TMicroserviceName;
  withCredentials?: boolean;
}

export class Response<T> {
  public readonly result: T;
  public readonly response?: superagent.Response;

  public constructor(result: T, response?: superagent.Response) {
    this.result = result;
    this.response = response;
  }
}

export abstract class Request<T> extends Observable<Response<T>> {
  public constructor(requestConfig: IRequestConfiguration, config: IClientConfig | IConfig) {
    const init = (subscriber: Subscriber<Response<T>>) => {
      function handleError(error: any, response: superagent.Response) { // tslint:disable-line:no-any
        let newError: Error;

        if (error instanceof Error) {
          newError = new Error(`Unable to request "${requestConfig.url}": ${error.message}`);
          // newError.cause = error;
        } else {
          newError = new Error(`Unable to request "${requestConfig.url}": ${String(error)}`);
        }

        newError.meta = {
          request: JSON.stringify(requestConfig),
        };

        if (response) {
          newError.meta.response = {
            body: response.text,
            headers: response.header,
            statusCode: response.status,
          };
        }

        subscriber.error(newError);
      }

      const method: TRequestMethod = requestConfig.method || 'GET';

      let request: superagent.SuperAgentRequest = superagent(
        method,
        requestConfig.url,
      );

      if (requestConfig.withCredentials) {
        request = request.withCredentials();
      }

      if (requestConfig.headers) {
        for (const header of Object.keys(requestConfig.headers)) {
          request.set(header, requestConfig.headers[header]);
        }
      }

      if (isServer) {
        request.set('user-agent', config.projectName);
      }

      if (requestConfig.body) {
        request.send(requestConfig.body);
      }

      const timeout = typeof requestConfig.timeout === 'number' ? requestConfig.timeout : 60000;
      request.timeout(timeout);

      this.prepareRequest(request, requestConfig);

      if (requestConfig.jsonp) {
        // FIXME: какие-тонепонятные тайпинги
        // tslint:disable-next-line:no-any
        request.use(jsonp({timeout: timeout}) as any);
      }

      request
        .end((error: any, response: superagent.Response) => { // tslint:disable-line:no-any
          if (subscriber.closed) {
            return;
          }

          if (error) {
            handleError(error, response);
            return;
          }

          let result: T;

          try {
            result = this.prepareResult(response, requestConfig);
          } catch (error) {
            handleError(error, response);
            return;
          }

          const resultResponse = new Response(result, response);

          subscriber.next(resultResponse);
          subscriber.complete();
        });

      return () => {
        request.abort();
      };
    };

    super(init);
  }

  protected prepareRequest(request: superagent.SuperAgentRequest, config: IRequestConfiguration) {
  }

  protected abstract prepareResult(response: superagent.Response, config: IRequestConfiguration): T;
}

export class JsonRequest<T> extends Request<T> {
  protected prepareRequest(request: superagent.SuperAgentRequest, config: IRequestConfiguration) {
    request.type('json');
    request.accept('json');
  }

  protected prepareResult(response: superagent.Response, config: IRequestConfiguration): T {
    if (!response.header['content-type'] || response.header['content-type'].indexOf('application/json') < 0) {
      try {
        if (response.status === 204) {
          return JSON.parse('{}');
        }

        return JSON.parse(response.text);
      } catch (err) {
        const newError = new Error(`Unable to parse response body: ${response.text.slice(0, 16)}...`);
        newError.cause = err;
        throw newError;
      }
    }

    return response.body;
  }
}

export class TextRequest extends Request<string> {
  protected prepareResult(response: superagent.Response, config: IRequestConfiguration): string {
    return response.text;
  }
}
