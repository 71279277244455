export function round(num: number, keepAfterComma: number = 0) {
  const multiplier = Math.pow(10, keepAfterComma);
  return Math.round(num * multiplier) / multiplier;
}

export function parsePrice(value: number | string) {
  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  return round(value, 1);
}
