import { IServerResponse } from '../api/models/users';

const FAKE_EMAIL_REGX = /@social\.cian\.ru$/;

export function isFakeEmail(email: string): boolean {
  return FAKE_EMAIL_REGX.test(email.toLowerCase());
}

export function isNotAbsent(value: string | null | undefined): boolean {
  return !!value && !!value.trim();
}

export function isEmail(email: string): boolean {
  return /^.+@.+\..{2,}$/.test(email);
}

const MIN_PASSWORD_LENGTH = 6;

export function isLengthGte(input: string, value: number): boolean {
  return input.length >= value;
}

export function isLongPassword(input: string): boolean {
  return isLengthGte(input, MIN_PASSWORD_LENGTH);
}

interface IValidatePasswordResult {
  isValid: boolean;
  message?: string;
}

export function isValidPassword<T extends IServerResponse>(
  password: string,
  checkComplexity?: (password: string) => Promise<T>,
): Promise<IValidatePasswordResult> {
  if (!password) {
    return Promise.resolve({
      isValid: false,
      message: 'Введите пароль',
    });
  }

  if (!password.trim()) {
    return Promise.resolve({
      isValid: false,
      message: 'Пароль не может состоять из одних пробелов',
    });
  }

  if (!isLongPassword(password)) {
    return Promise.resolve({
      isValid: false,
      message: 'Пароль слишком короткий',
    });
  }

  if (typeof checkComplexity === 'function') {
    return checkComplexity(password).then((result) => {
      if (result.value === 'PasswordError' && result.message) {
        return {
          isValid: false,
          message: result.message,
        };
      }

      return {
        isValid: true,
      };
    });
  }

  return Promise.resolve({
    isValid: true,
  });
}
