export type TFixedComponentsActions
  = { type: 'SetSearchButtonFixed', payload: { searchButtonFixed: boolean } }
  | { type: 'SetAppBannerState', payload: { appBannerOn: boolean } }
  | { type: 'SetShowContactsButtonFixed', payload: { showContactsButtonFixed: boolean } }
  | { type: 'SetShowContactsPopupVisibile', payload: { showContactsPopupVisible: boolean } };

export function setSearchButtonFixed(searchButtonFixed: boolean): TFixedComponentsActions {
  return {
    type: 'SetSearchButtonFixed',
    payload: { searchButtonFixed },
  };
}

export function setAppBannerState(appBannerOn: boolean): TFixedComponentsActions {
  return {
    type: 'SetAppBannerState',
    payload: { appBannerOn },
  };
}

export function setShowContactsButtonFixed(showContactsButtonFixed: boolean): TFixedComponentsActions {
  return {
    type: 'SetShowContactsButtonFixed',
    payload: { showContactsButtonFixed },
  };
}

export function setShowContactsPopupVisibile(showContactsPopupVisible: boolean): TFixedComponentsActions {
  return {
    type: 'SetShowContactsPopupVisibile',
    payload: { showContactsPopupVisible },
  };
}
