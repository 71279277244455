import { ca } from '@cian/analytics';

export function trackVillagesLinkClick() {
  ca('event', {
    name: 'oldevent',
    category: 'Mob_menu',
    action: 'click',
    label: 'ListingKP',
  });
}
