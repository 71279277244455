import { FormField } from '@cian/ui-kit';

import * as React from 'react';

import { FilterButton } from '../../../mobile_website/ui/FilterDropdown/internal/components/FilterButton';
import { NestedCheckboxGroupsModal } from '../NestedCheckboxGroupsModal';

import { ILabelService, LabelService } from './internal/services/LabelService';
import { GetModalTriggerButtonView, NestedCheckboxGroupsFilterProps } from './types';

export const NestedCheckboxGroupsFilter: React.FC<NestedCheckboxGroupsFilterProps> = props => {
  const {
    allValuesLabel,
    closeOnReset,
    closeOnSave,
    defaultLabel,
    groups,
    title,
    values,
    withoutSearch,
    onReset,
    onSave,
  } = props;

  const labelService = React.useMemo<ILabelService>(
    () => new LabelService(defaultLabel, allValuesLabel),
    [allValuesLabel, defaultLabel],
  );

  labelService.calcFields(values, groups);

  const getModalTriggerButtonView = React.useCallback<GetModalTriggerButtonView>(
    ({ setOpen, isOpen }) => (
      <FormField label={title}>
        <FilterButton
          count={labelService.count}
          isActive={isOpen}
          isPlaceholderLabel={labelService.isDefaultLabel}
          label={labelService.label}
          onClick={setOpen}
        />
      </FormField>
    ),
    [labelService.count, labelService.isDefaultLabel, labelService.label, title],
  );

  return (
    <NestedCheckboxGroupsModal
      closeOnReset={closeOnReset}
      closeOnSave={closeOnSave}
      groups={groups}
      title={title}
      values={values}
      withoutSearch={withoutSearch}
      onReset={onReset}
      onSave={onSave}
    >
      {getModalTriggerButtonView}
    </NestedCheckboxGroupsModal>
  );
};
