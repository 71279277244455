import { get } from 'lodash';
import { EHideOfferErrorCode } from '../actions';

export interface IError {
  meta?: {
    response?: {
      body?: {
        errors?: { code: EHideOfferErrorCode }[];
      };
    };
  };
}

export function getErrorCode(error: IError) {
  const errorArr = get(error, 'meta.response.body.errors');

  if (errorArr) {
    return errorArr[0] && errorArr[0].code;
  }

  return;
}
