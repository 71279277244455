import { AreaUnitType, IRange } from '../../redux/offer/model';
import { formatNumber } from '../../utils/formatters';

export function getAreaPartsAreaLabel(area: number | IRange | undefined, areaUnitType?: AreaUnitType): string {
  if (!area) {
    return '';
  }

  const areaUnit = areaUnitType || 'м²';

  if (typeof area === 'number') {
    return `от ${formatNumber(area)} ${areaUnit}`;
  } else {
    return `${formatNumber(area.min)} \u2014 ${formatNumber(area.max)} ${areaUnit}`;
  }
}
