import { IGKData } from '../../../api/models/gk_card';
import { getRegionFromGaLabel, IGKPageAnalytics } from '../../../utils/analytics';
import { IBuilderContact, IGKFlats } from '../model';

export const parseAnalytics = (gk: IGKData, contacts: IBuilderContact[], flats: IGKFlats): IGKPageAnalytics => {
  return {
    customPageURL: gk.ga_event_action,
    dealType: 'sale',
    fromDeveloper: String(flats.developer.totalCount),
    objectType: 'JK',
    offerID: gk.id,
    offerName: gk.name,
    offerPhone: contacts[0] && contacts[0].phone,
    pageType: 'CardJK',
    region: getRegionFromGaLabel(gk.ga_event_action),
    reviewsQty: 0,
  };
};
