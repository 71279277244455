import { omit } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Dispatch } from 'redux';
import { TReduxActions } from '../../redux/model';
import { setSidebarState } from '../../redux/popups/actions';

import { IStyleConfig, mergeStyles } from '@cian/components';

const styles = require('./menu.css');

interface IOwnProps {
  id?: number;
  children?: React.ReactNode;
  href?: string;
  to?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  icon?: string;
  styleConfig?: IStyleConfig;
}
interface IDispatchProps {
  closeSidebar: () => void;
}

type IMenuItemProps = IOwnProps & IDispatchProps;

const MenuItem: React.SFC<IMenuItemProps> = (props: IMenuItemProps) => {
  // tslint:disable-next-line:no-any
  const Component: any = ((): string | Link => {
    if (props.to) {
      return Link;
    }

    if (props.href) {
      return 'a';
    }

    return 'button';
  })();

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    if (props.onClick) {
      props.onClick(e);
    }

    props.closeSidebar();
  };

  return (
    <Component
      {...mergeStyles(styles.item, props.styleConfig, isButton(props) && styles.button)}
      {...omit(props, ['closeSidebar', 'onClick', 'styleConfig'])}
      onClick={onClick}
      data-mark={`MenuItem${props.id || ''}`}
    >
      {props.children}
    </Component>
  );
};

function mapDispatchToProps(dispatch: Dispatch<TReduxActions>): IDispatchProps {
  return {
    closeSidebar: () => dispatch(setSidebarState(false)),
  };
}

export default connect<{}, IDispatchProps, IOwnProps>(() => ({}), mapDispatchToProps)(MenuItem);

const isButton = (props: IMenuItemProps) => {
  return !props.to && !props.href;
};
