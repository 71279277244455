import { equals, omit } from 'ramda';
import { IJsonQuery } from '../../../../api/models/json_query';


export function compareJsonQuery(prevJsonQuery: IJsonQuery, nextJsonQuery: IJsonQuery): boolean {
  // Не учитываем страницу выдачи page, поскольку сессия поиска не должна меняться при смене страницы
  const prepareJsonQuery = omit(['page']);

  return equals(prepareJsonQuery(prevJsonQuery), prepareJsonQuery(nextJsonQuery));
}
