/**
 * @todo Удалить файл с RS
 * @description Данный функционал появился в CD-154318, будет удален в CD-154562
 */
let isCommercialMultiAdsBannerEnabled: null | boolean = null;

export function setIsCommercialMultiAdsBannerEnabled(enabled: boolean): void {
  isCommercialMultiAdsBannerEnabled = enabled;
}

export function getIsCommercialMultiAdsBannerEnabled(): boolean {
  return !!isCommercialMultiAdsBannerEnabled;
}
