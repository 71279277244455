import * as React from 'react';

import { ButtonCheckbox, Label } from '@cian/components';
import { IRooms } from '../../../redux/filters/model';
import { flatObjectType, IFieldProps, isNotUpcomingSale, IsVisibleCheck, reduceVisibilityCheck } from '../field';

const styles = require('./rooms.css');

export type IRoomsSelectorProps = IRooms & {
  setRooms(roomType: string, checked: boolean): void;
};

export class RoomsSelector extends React.PureComponent<IRoomsSelectorProps, object> {
  public render() {
    const {
      oneRoom,
      twoRooms,
      threeRooms,
      fourRooms,
      fiveRooms,
      sixRooms,
      freeLayout,
      studio,
    } = this.props;

    return (
      <div className={styles.rooms} data-mark="FieldRooms">
        <div className={styles['rooms-count-row']}>
          <ButtonCheckbox
            mobile
            checked={oneRoom}
            onChange={this.setRoomValue}
            id="rooms-count-2"
            key="rooms-count-2"
            name="oneRoom"
            buttonCheckboxStyle={styles.button}
          >
            1
          </ButtonCheckbox>
          <ButtonCheckbox
            mobile
            checked={twoRooms}
            onChange={this.setRoomValue}
            id="rooms-count-3"
            key="rooms-count-3"
            name="twoRooms"
            buttonCheckboxStyle={styles.button}
          >
            2
          </ButtonCheckbox>
          <ButtonCheckbox
            mobile
            checked={threeRooms}
            onChange={this.setRoomValue}
            id="rooms-count-4"
            key="rooms-count-4"
            name="threeRooms"
            buttonCheckboxStyle={styles.button}
          >
            3
          </ButtonCheckbox>
          <ButtonCheckbox
            mobile
            checked={fourRooms}
            onChange={this.setRoomValue}
            id="rooms-count-5"
            key="rooms-count-5"
            name="fourRooms"
            buttonCheckboxStyle={styles.button}
          >
            4
          </ButtonCheckbox>
          <ButtonCheckbox
            mobile
            checked={fiveRooms}
            onChange={this.setRoomValue}
            id="rooms-count-6"
            key="rooms-count-6"
            name="fiveRooms"
            buttonCheckboxStyle={styles.button}
          >
            5
          </ButtonCheckbox>
          <ButtonCheckbox
            mobile
            checked={sixRooms}
            onChange={this.setRoomValue}
            id="rooms-count-7"
            key="rooms-count-7"
            name="sixRooms"
            buttonCheckboxStyle={styles.button}
          >
            6+
          </ButtonCheckbox>
        </div>
        <div className={styles['rooms-special-counts']}>
          <ButtonCheckbox
            mobile
            checked={studio}
            onChange={this.setRoomValue}
            id="rooms-count-1"
            key="rooms-count-1"
            name="studio"
            wide
            buttonCheckboxStyle={styles.button}
          >
            Студия
          </ButtonCheckbox>
          <ButtonCheckbox
            mobile
            checked={freeLayout}
            onChange={this.setRoomValue}
            id="rooms-count-8"
            key="rooms-count-8"
            name="freeLayout"
            wide
            buttonCheckboxStyle={styles.button}
          >
            Свобод. планировка
          </ButtonCheckbox>
        </div>
      </div>
    );
  }
  private setRoomValue = (room: React.FormEvent<HTMLFormElement>) => {
    const { name, checked } = room.target as HTMLInputElement;
    this.props.setRooms(name, checked);
  }
}

export class Component extends React.Component<IFieldProps, object> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    const {
      oneRoom,
      twoRooms,
      threeRooms,
      fourRooms,
      fiveRooms,
      sixRooms,
      freeLayout,
      studio,
    } = this.props.filter.rooms;

    const {
      oneRoom: nextOneRoom,
      twoRooms: nextTwoRooms,
      threeRooms: nextThreeRooms,
      fourRooms: nextFourRooms,
      fiveRooms: nextFiveRooms,
      sixRooms: nextSixRooms,
      freeLayout: nextFreeLayout,
      studio: nextStudio,
    } = nextProps.filter.rooms;

    return oneRoom !== nextOneRoom ||
      twoRooms !== nextTwoRooms ||
      threeRooms !== nextThreeRooms ||
      fourRooms !== nextFourRooms ||
      fiveRooms !== nextFiveRooms ||
      sixRooms !== nextSixRooms ||
      freeLayout !== nextFreeLayout ||
      studio !== nextStudio;
  }

  public render() {
    return (
      <div className={styles.field}>
        <Label label="Количество комнат">
          <RoomsSelector {...this.props.filter.rooms} setRooms={this.props.actions.setRooms} />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = reduceVisibilityCheck(
  isNotUpcomingSale,
  filter => filter.selectedObjectTypes.some(t => t === flatObjectType.Apartment),
);
