import { THeaderActions } from './actions';

const initialState = {
  isVisible: true,
};

export interface IHeaderState {
  isVisible: boolean;
}

export default (state: IHeaderState = initialState, action: THeaderActions) => {
  switch (action.type) {
    case 'setHeaderVisibility': {
      return { isVisible: action.isVisible };
    }

    default:
      return state;
  }
};
