/* eslint-disable max-lines */

import { push } from 'react-router-redux';

import {
  IInfiniteSearchResult,
  IRequestSubscribeNewResponse,
  ISearchOffersApiResponse,
  ISearchOffersData,
  ISearchOffersRequest,
  TSubscribeSource,
} from '../../api/api';
import { IJsonQueryLocation, term } from '../../api/models/json_query';
import { IOfferSerialized } from '../../api/models/offer_preview';
import { RouteName } from '../../constants/route_name';
import {
  changeGkMapFavoriteStatus,
  fetchFavoritesCount,
  removeFavoriteOfferFromStore,
  setFavoriteTotal,
} from '../../redux/favorites/actions';
import {
  EFavoritesAuthenticateGroup,
  getFavoritesAuthenticateExperimentStatus,
} from '../../utils/experiments/favorites_authenticate_mob';
import { pipe } from '../../utils/functional';
import { getCurrentRegionId } from '../../utils/geo';
import { cutDescriptionFromOffers } from '../../utils/helpers';
import { getQueryString, getSubdomain, removeQueryParam } from '../../utils/location';
import { Regions } from '../../utils/regions';
import { routeFor } from '../../utils/routeFor';
import { toPromise } from '../../utils/streams';
import { openAuthenticationPopup } from '../authenticationPopup/actions';
import { checkRedirectData, getHeaderData } from '../common/actions';
import { getInitialFiltersOnServer, getRegionSettings, setRegion } from '../filters/actions';
import { IHideOfferInfo } from '../hide_offer/actions';
import { TTypedThunkAction } from '../model';
import { TQuickLinksActions, getQuickLinks, resetQuickLinks } from '../quick_links/actions';
import { getRegionByLocation, updateRegionMeta } from '../regions/actions';

import { isNewMobileSearchAvailable } from './isNewMobileSearchAvailable';
import {
  IInitializedSearchOffersState,
  IOfferSource,
  SearchOffersState,
  SubscriptionStatus,
  checkIfListWasSubscridedBefore,
} from './model';

export type AddSearchOffersAction = {
  type: 'AddSearchOffers';
  offers: ISearchOffersData;
  isNewobjectOnly: boolean;
  lastUrl: string;
  withoutSuggestions: boolean;
};

export type TOffersListActions =
  | AddSearchOffersAction
  | {
      type: 'MLSuggestionsSuccess';
      suggestions: IOfferSerialized[];
      pageNumber: number;
    }
  | { type: 'AddFavoriteQueryString'; payload: string }
  | { type: 'SetSearchOffersLoading'; loading: boolean }
  | { type: 'SetOfferFavorite'; payload: ISetOfferFavoriteStatusParams }
  | { type: 'SetHideOffer'; payload: IHideOfferInfo }
  | { type: 'SetSubscription'; status: SubscriptionStatus; email?: string }
  | { type: 'ResetSearchOffers' }
  | { type: 'NotFoundPage' }
  | { type: 'SubscribeList'; payload: { queryString: string; email: string } }
  | { type: 'HideShowMoreButton' }
  | { type: 'changeSorting'; payload: { sortType: string } }
  | { type: 'ChangeGkFavoriteStatus'; payload: { id: number; state: boolean } };

function setLoading(loading: boolean): TOffersListActions {
  return { type: 'SetSearchOffersLoading', loading };
}

export function addSearchOffers(
  offers: ISearchOffersData,
  isNewobjectOnly: boolean,
  lastUrl: string,
  withoutSuggestions: boolean = false,
): AddSearchOffersAction {
  return {
    type: 'AddSearchOffers',
    offers,
    isNewobjectOnly,
    lastUrl,
    withoutSuggestions,
  };
}

export function searchOffers(
  reqData: ISearchOffersRequest,
  subdomain: string,
  regionsPromise?: Promise<void>,
): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    dispatch<TOffersListActions>(setLoading(true));

    const {
      searchList,
      routing: {
        routeName,
        locationBeforeTransitions: { pathname, search },
      },
    } = getState();
    const noOffersOnNextPage = searchList.initialized && searchList.offersCountOnNextPage === 0;
    let responseWithRedirect = false;

    const searchOffersRequest = routeName === RouteName.MAP ? api.mapSearchOffers : api.searchOffersNew;

    const searchOffersP = noOffersOnNextPage
      ? Promise.resolve({} as ISearchOffersData)
      : toPromise(searchOffersRequest(reqData))
          .then(res => {
            const { data } = res.result;
            responseWithRedirect = Boolean(data && data.redirectData);
            dispatch(checkRedirectData(res));

            if (responseWithRedirect && typeof window !== 'undefined') {
              window.location.reload();
            }

            return res;
          })
          .then(res => {
            if (responseWithRedirect) {
              return res;
            }

            const {
              filter: { regionSettings },
            } = getState();
            const { jsonQuery, queryString = '' } = res.result.data;
            const { region: regionTerm } = jsonQuery;
            const locationTag =
              jsonQuery.geo &&
              (jsonQuery.geo.value.filter(tag => tag.type.toLowerCase() === 'location')[0] as IJsonQueryLocation);

            const alternativeRegion = locationTag ? locationTag.id : null;

            const region = (regionTerm && regionTerm.value[0]) || alternativeRegion || Regions.Moscow;

            if (!regionSettings.initialized) {
              dispatch(setRegion({ region }));
            }

            return new Promise<ISearchOffersApiResponse>((resolve, reject) => {
              Promise.all([
                dispatch(getQuickLinks(queryString || '')),
                !regionSettings.initialized && subdomain
                  ? dispatch(getRegionSettings(subdomain, region)).then(() => res)
                  : Promise.resolve(res),
                getRegionByLocation(api, pathname + search, subdomain).then(data => {
                  const { items } = data;
                  const currentRegionId = getCurrentRegionId(items);

                  if (currentRegionId !== region) {
                    dispatch(updateRegionMeta(currentRegionId));
                  } else {
                    Promise.resolve();
                  }
                }),
                dispatch(getInitialFiltersOnServer(queryString, subdomain, regionsPromise)),
              ])
                .then(([quickLinks, response]) => resolve(response))
                .catch(reject);
            });
          })
          .then(res => {
            if (responseWithRedirect) {
              return;
            }

            const {
              filter: { regionSettings },
              routing: {
                locationBeforeTransitions: { query },
              },
              userAgent,
            } = getState();

            res.result.data.offersSerialized = cutDescriptionFromOffers(res.result.data.offersSerialized, userAgent);

            if (!regionSettings) {
              logger.error(new Error('regionSettings should be loaded before adding search offers'));
            }
            dispatch<TOffersListActions>(addSearchOffers(res.result.data, !!query['multi_id'], pathname + search));

            return res.result.data;
          });

    return searchOffersP
      .then((offersData: ISearchOffersData) => {
        if (responseWithRedirect) {
          return;
        }

        const newState = getState();
        const { searchList: newSearchList } = newState;

        const isPromoPage = !!(newSearchList as IInitializedSearchOffersState).pikPhone;
        const isLastPageShown = newSearchList.initialized && newSearchList.offersCountOnNextPage === 0;

        if (isPromoPage) {
          if (isLastPageShown) {
            dispatch(hideShowMoreButton());
          }

          return Promise.resolve();
        }

        const isSuggestions =
          newSearchList.initialized && newSearchList.suggestionsMl && newSearchList.suggestionsMl.suggestions;

        if (!isSuggestions && isLastPageShown) {
          return dispatch(getMLSuggestions());
        }

        return Promise.resolve();
      })
      .then(() => {
        dispatch<TOffersListActions>(setLoading(false));
      })
      .catch(err => {
        dispatch<TOffersListActions>(setLoading(false));
        dispatch<TOffersListActions>({ type: 'NotFoundPage' });
        throw err;
      });
  };
}

export function resetAndSearchOffersByUrl(
  subdomain: string,
  path: string,
  regionsPromise?: Promise<void>,
): TTypedThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    if (isNewMobileSearchAvailable(getState()) && typeof window !== 'undefined') {
      window.location.reload();
    }

    dispatch<TOffersListActions>(resetSearchOffers());
    dispatch<TQuickLinksActions>(resetQuickLinks());
    const reqData = {
      _subdomain: subdomain,
      _path: removeQueryParam(path, 'p'), //не поддерживаем пагинацию по query-string
    };

    return dispatch(searchOffers(reqData, subdomain, regionsPromise));
  };
}

export function searchOffersByPage(page: number, subdomain: string): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState) => {
    const { searchList } = getState();
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const subdomain = getSubdomain(window.location.href);

    if (!searchList.initialized) {
      return Promise.reject('You should load at least one page before using searchOffersByPage');
    }

    const reqData = Object.assign({}, searchList.jsonQuery, { page: term(page) });

    return dispatch(searchOffers({ jsonQuery: reqData }, subdomain));
  };
}

export function loadNextPage(): TTypedThunkAction<Promise<{} | void | Error>> {
  return (dispatch, getState) => {
    const { searchList } = getState();

    if (searchList.initialized) {
      const isPromo = searchList.pikPhone;
      /**
       * Если есть еще офферы из выдачи, подгружаем их,
       * иначе запрашиваем следующую страницу добивок.
       */
      if (searchList.offersCountOnNextPage > 0) {
        return dispatch(searchOffersByPage(searchList.pages.length + 1, getSubdomain(location.href)));
      } else if (isPromo) {
        return Promise.resolve();
      } else {
        dispatch<TOffersListActions>(setLoading(true));

        return dispatch(getMLSuggestions());
      }
    }

    return Promise.reject(new Error('search list not initialized'));
  };
}

export function resetSearchOffers(): TOffersListActions {
  return { type: 'ResetSearchOffers' };
}

export function hideShowMoreButton(): TOffersListActions {
  return { type: 'HideShowMoreButton' };
}

function getMLSuggestions(): TTypedThunkAction<Promise<void | {}>> {
  return (dispatch, getState, { api, logger }) => {
    const { searchList, userAgent } = getState();

    if (!searchList.initialized) {
      return Promise.reject('searchList is not initialized');
    }

    const { jsonQuery, suggestionsMl, queryString } = searchList;

    const offersList = (searchList.pages || []).map(page => page.offers);
    const offersIds = ([] as IOfferSource[]).concat(...offersList).map(offer => offer.data.id);
    const suggestionsIds =
      suggestionsMl && suggestionsMl.suggestions ? suggestionsMl.suggestions.map(suggestion => suggestion.cianId) : [];

    const allVisibleOffersIds = [...offersIds, ...suggestionsIds].filter(Boolean);

    const pageNumber = suggestionsMl && suggestionsMl.pageNumber !== undefined ? suggestionsMl.pageNumber + 1 : 0;

    return api
      .getSuggestIds({
        jsonQuery,
        offersIds: allVisibleOffersIds as number[],
        pageNumber,
        queryString,
      })
      .then((response: IInfiniteSearchResult[]) => {
        const modelVersion = response && response.length > 0 ? Number(response[0].modelVersion) : undefined;

        const suggestOffersIds = response.map(searchResult => searchResult.itemId);

        if (!suggestOffersIds.length) {
          dispatch(hideShowMoreButton());

          return Promise.resolve();
        }

        return toPromise(api.getOffersByIds(suggestOffersIds)).then(offersByIdsResponse => {
          const offersSerialized = cutDescriptionFromOffers(
            offersByIdsResponse.result.data.offersSerialized,
            userAgent,
          ).map(offer => ({ ...offer, modelVersion }));

          dispatch({
            type: 'MLSuggestionsSuccess',
            suggestions: offersSerialized,
            pageNumber,
          });

          const isSuggestions = suggestionsMl && suggestionsMl.suggestions;
          /**
           * Если pageNumber > 1 и есть добивки, значит происходит пагинация по добивкам,
           * следовательно состояние загрузки выдачи нужно выключать после получения
           * новой страницы добивок.
           */
          if (pageNumber >= 1 && isSuggestions) {
            dispatch<TOffersListActions>(setLoading(false));
          }
        });
      })
      .catch(error => logger.error(error));
  };
}

export interface ISetOfferFavoriteStatusParams {
  dealType: string;
  objectType: string;
  offerId: number;
  state: boolean;
  pageNum?: number;
  isFavoritePage?: boolean;
}

export function setOfferFavoriteStatus(params: ISetOfferFavoriteStatusParams): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const {
      searchList: { lastUrl },
      favorites,
      common: { isAuthenticated },
    } = state;

    dispatch<TOffersListActions>({ type: 'AddFavoriteQueryString', payload: getQueryString(lastUrl || '') });
    dispatch<TOffersListActions>({ type: 'SetOfferFavorite', payload: params });

    const isAdded = params.state;
    const favoritesAuthenticateExperimentGroup = getFavoritesAuthenticateExperimentStatus(state);
    if (
      !isAuthenticated &&
      isAdded &&
      favoritesAuthenticateExperimentGroup !== EFavoritesAuthenticateGroup.NothingNew
    ) {
      const preventAndOpenAuthenticationPopup = (): void => {
        dispatch(setFavoriteTotal(favorites.count - 1));
        dispatch<TOffersListActions>({
          type: 'SetOfferFavorite',
          payload: {
            ...params,
            state: !params.state,
          },
        });
        dispatch(openAuthenticationPopup('Войдите, чтобы добавлять в избранное'));
      };

      switch (favoritesAuthenticateExperimentGroup) {
        case EFavoritesAuthenticateGroup.Limit5:
          if (favorites.count > 5) {
            preventAndOpenAuthenticationPopup();

            return Promise.reject();
          }

          break;
        case EFavoritesAuthenticateGroup.Limit3:
          if (favorites.count > 3) {
            preventAndOpenAuthenticationPopup();

            return Promise.reject();
          }

          break;
        case EFavoritesAuthenticateGroup.Limit1:
          if (favorites.count > 1) {
            preventAndOpenAuthenticationPopup();

            return Promise.reject();
          }

          break;
        default:
          break;
      }
    }

    return toPromise(api.setOfferFavoriteStatus(params.dealType, params.objectType, params.offerId, params.state))
      .then(res => {
        const { routing } = getState();
        const isFavoritesRoute = routing.routeName === 'favorites';
        const { newbuilding_id } = res.result;

        if (newbuilding_id) {
          dispatch(setFavoriteTotal(favorites.count + 1));

          if (routing.routeName === 'map') {
            dispatch(changeGkMapFavoriteStatus(true));
          }
          if (window.__header_updateFavorites__) {
            window.__header_updateFavorites__(true);
          }
        }

        dispatch(checkRedirectData(res));

        if (isFavoritesRoute) {
          const offset = (favorites.options.offset || 0) + 1;

          dispatch(removeFavoriteOfferFromStore(favorites, params.offerId, params.pageNum || 1, offset, false));
        }

        /// TODO: вернуть, когда бэк удалит архивные объявы из счетчик
        // dispatch(setFavoritesCount(res.result.count.total));
        dispatch(fetchFavoritesCount(isFavoritesRoute));
      })
      .catch(err => {
        const counter = !params.state ? 1 : -1;

        dispatch(setFavoriteTotal(favorites.count + counter));
        dispatch<TOffersListActions>({
          type: 'SetOfferFavorite',
          payload: {
            ...params,
            state: !params.state,
          },
        });
      });
  };
}

export function subscribe(
  email: string,
  queryString: string,
  subscribeNews: boolean,
  source: TSubscribeSource,
): TTypedThunkAction<Promise<void | IRequestSubscribeNewResponse>> {
  return (dispatch, getState, { api }) => {
    dispatch<TOffersListActions>({ type: 'SetSubscription', status: 'pending' });

    return api
      .subscribeNew({ queryString, email, source, subscribeNews })
      .then(res => {
        const { searchList } = getState();
        const subscribedBefore = checkIfListWasSubscridedBefore(searchList.subscribedLists, queryString);

        if (res && res.auth) {
          dispatch(getHeaderData())
            .then(() => dispatch({ type: 'AuthSuccess' }))
            .then(() => {
              window.__reloadHeader__?.();
            });
        }

        if (!subscribedBefore) {
          dispatch<TOffersListActions>({ type: 'SubscribeList', payload: { queryString, email } });
        }

        dispatch<TOffersListActions>({ type: 'SetSubscription', status: 'subscribed', email });

        return res;
      })
      .catch(err => {
        dispatch<TOffersListActions>({ type: 'SetSubscription', status: 'errored' });
        throw err;
      });
  };
}

export function isGkList(searchList: SearchOffersState): boolean {
  return searchList.initialized && searchList.isGkList;
}

export function changeSorting(sortType: string): TOffersListActions {
  return {
    type: 'changeSorting',
    payload: { sortType },
  };
}

export function searchOffersWithSorting(sortType: string): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState) => {
    dispatch(changeSorting(sortType));

    const { searchList } = getState();
    const { search, pathname } = location;
    const queryParams = search
      ? /**
         * Если search из window.location.search, отрезаем знак вопроса сразу
         */
        getParams(search.slice(1))
      : getParams(searchList.queryString || '');
    const newQueryParams = changeSortInQueryString(searchList, queryParams);

    const newQuery =
      pathname === routeFor.OFFERS_LIST
        ? getNewQuery(pathname, newQueryParams)
        : getNewQuery(routeFor.OFFERS_LIST, newQueryParams);

    dispatch(push(newQuery));

    return Promise.resolve();
  };
}

function getParams(qeury: string): string[] {
  return qeury.split('&');
}

function getNewQuery(pathname: string, params: string[]): string {
  return `${pathname}?${params.join('&')}`;
}

export function changeSortInQueryString(searchListState: SearchOffersState, queryParams: string[]): string[] {
  const sortState = searchListState.initialized && searchListState.jsonQuery.sort;
  const sortParam = queryParams.find(param => param.search('sort=') >= 0);

  if (sortState) {
    if (sortParam) {
      const newSortParam = `sort=${sortState.value}`;

      return sortParam === sortState.value
        ? queryParams
        : pipe(excludeParam(sortParam), addNewParam(newSortParam))(queryParams);
    } else {
      return queryParams.concat(`sort=${sortState.value}`);
    }
  } else {
    return sortParam ? queryParams.filter(p => p !== sortParam) : queryParams;
  }
}

function excludeParam(param: string) {
  return function (queryParams: string[]): string[] {
    return queryParams.filter(p => p !== param);
  };
}

function addNewParam(newParam: string) {
  return function (queryParams: string): string {
    return queryParams.concat(newParam);
  };
}
