import { CheckboxGroup, convertCheckboxGroupItemsToCheckboxGroup } from '@cian/nested-checkbox-groups';

import * as React from 'react';

import { IReadyBusinessGroupItem } from '../../../../../../../../../shared/repositories/announcements/entities/AnnouncementReferences/ReadyBusinessGroupItem';
import { IAssociateStringWithNumberService } from '../../../../services/AssociateStringWithNumberService';

export function useCategoryGroups(
  groups: Array<IReadyBusinessGroupItem>,
  associateStringWithNumberService: IAssociateStringWithNumberService,
): Array<CheckboxGroup> {
  return React.useMemo<Array<CheckboxGroup>>(
    () => [
      convertCheckboxGroupItemsToCheckboxGroup(
        groups.map(group => ({
          label: group.name,
          value: associateStringWithNumberService.get(group.groupType),
        })),
        'Категории готового бизнеса',
        'readyBusinessGroup',
      ),
    ],
    [associateStringWithNumberService, groups],
  );
}
