import * as React from 'react';
import { ResourceStatus } from '../../../redux/common/model';
import { PopupParamNames } from '../../../redux/popups/actions';
import { Region } from '../../common/region/region';
import { transformToField } from '../../fields/field';
import { IsVisibleCheck } from '../field';

import { defaultRegions } from '../../../redux/regions/model';

const styles = require('./region.css');

interface IRegionFieldProps {
  isLoading: boolean;
  displayName: string;
}

export class RegionField extends React.PureComponent<IRegionFieldProps, object> {
  public render() {
    return (
      <div className={styles.field} data-mark="FieldRegion">
        Где: <span style={{ marginLeft: 10 }}><Region popupQueryParam={PopupParamNames.Where} {...this.props} /></span>
      </div>
    );
  }
}

export const Component = transformToField(({ filter, regions }) => {
  const { regionMeta } = regions;
  const displayName = regionMeta.location && regionMeta.location.displayName
    ? regionMeta.location.displayName
    : defaultRegions.regionMeta.location.displayName;

  return {
    displayName,
    isLoading: regionMeta.status === ResourceStatus.Pending,
  };
})(RegionField);

export const isVisible: IsVisibleCheck = filter => true;
