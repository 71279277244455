import { last } from 'lodash';

import { IGeocodeItem, IGeocodeResponse, IJKSuggestionsData, IRailwaysData } from '../../../api/api';
import { IGeoTag } from '../../fields/field';

const createPolygon = (bbox: Array<Array<number>>): Array<Array<number>> => {
  const [[x1, y1], [x2, y2]] = bbox;
  return [[x1, y1], [x1, y2], [x2, y2], [x2, y1], [x1, y1]];
};

export const createGeoTag = (geoItem: IGeocodeItem, response: IGeocodeResponse): IGeoTag => {
  const { regionId, details, isParsed, microDistricts, color, geoLocationCatalogLink } = response;

  const detail = last(details);

  if (detail && geoLocationCatalogLink) {
    detail.id = geoLocationCatalogLink.id;
    detail.geoType = geoLocationCatalogLink.objectType;
  }

  const geo = geoItem && geoItem.boundedBy != null
    ? createPolygon(geoItem.boundedBy).map(pair => pair.map(String))
    : [];

  let result = {
    text: geoItem.text.trim(),
    name: geoItem.name,
    regionId,
    geo,
    isParsed,
    color,
    details,
  } as IGeoTag;

  if (microDistricts && microDistricts.length) {
    result.id = microDistricts[0].id;
    result.kind = 'District';
    return result;
  }

  result.id = detail && detail.id;
  result.kind = detail && detail.geoType || 'polygon';

  return result;
};

export function createJKGeoTag(jkData: IJKSuggestionsData): IGeoTag {
  return {
    id: jkData.Id,
    text: jkData.Value,
    name: jkData.Name,
    kind: 'Newobject',
    regionId: jkData.LocationId,
    geo: [],
    isParsed: true,
  };
}

export function createRailwayTag(railwayData: IRailwaysData, regionId: number): IGeoTag {
  const { id, name } = railwayData;

  return {
    id,
    text: name,
    name,
    kind: 'railway',
    regionId,
    isParsed: true,
  };
}
