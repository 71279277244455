import { getOptionalRegionInfo } from '../../filters/field';
import { filterToJsonQuery } from '../filters/filter_to_json_query';
import { ReduxState } from '../model';

export function isNewMobileSearchAvailable(state: ReduxState): boolean {
  const { nextMobileSearchAvailableTypes, nextMobileSearchAvailibleNewbuildings, searchList, filter, queryClient } =
    state;

  const jsonQuery = searchList.initialized
    ? searchList.jsonQuery
    : filterToJsonQuery(
        filter,
        {
          isNewbuildingsMapMode: false,
          optionalRegionInfo: getOptionalRegionInfo(state.regions.currentRegionInfo),
        },
        queryClient,
      );

  // временный хак закрывающий мажор CD-72829
  if (jsonQuery.geo && jsonQuery.geo.value.find(x => x.type === 'newobject')) {
    return false;
  }

  const nextOfferType = jsonQuery._type;
  const availableNewobject =
    nextMobileSearchAvailibleNewbuildings || !(jsonQuery.with_newobject && jsonQuery.with_newobject.value);

  return nextMobileSearchAvailableTypes.split(',').includes(nextOfferType) && availableNewobject;
}
