import { TChatsActions }                       from './actions';
import { IChatsState, initialChatsState } from './model';

export function chatsReducer(state: IChatsState = initialChatsState, action: TChatsActions): IChatsState {
  switch (action.type) {
    case 'chats/offer_chats_is_enabled':
      return {
        ...state,
        areOfferChatsEnabled: action.payload.enabled,
      };
    case 'chats/offer_chats_popup_open':
      return {
        ...state,
        popupIsOpen: true,
        offerId: action.payload || state.offerId,
      };
    case 'chats/offer_chats_popup_close':
      return {
        ...state,
        popupIsOpen: false,
        mapPositionY: undefined,
        mapPositionX: undefined,
        offerId: undefined,
        agentAvailability: undefined,
        contacts: undefined,
        isOwn: false,
      };
    case 'chats/message_sent':
      return {
        ...state,
        isOfferFirstChatMessageSent: true,
      };
    case 'chats/map_chats_popup_open':
      return {
        ...state,
        offerId: action.payload.offerId,
        mapPositionX: action.payload.mapPositionX,
        mapPositionY: action.payload.mapPositionY,
        agentAvailability: action.payload.agentAvailability,
        contacts: action.payload.contacts,
        isOwn: !!action.payload.isOwn,
      };
    default:
      return state;
  }
}
