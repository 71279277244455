import * as React from 'react';
import { Link } from 'react-router';

const styles = require('./link.css');

// tslint:disable-next-line:no-any
const CustomLink = (props: any) => {
  return <Link {...props} className={[styles.link, props.className].filter(Boolean).join(' ')} />;
};

// tslint:disable-next-line:no-any
export default CustomLink as any as Link;
