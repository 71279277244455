import { get } from 'lodash';
import { parseNumberLike } from '../../../utils/helpers';
import { parseSpecialty } from '../response_parser/base_info_parser';

import {
  ICranageType,
  ILiftType as IApiLift, //tslint:disable-line:no-use-before-declare
  IOfferDetail,
  IParking as IApiParking, //tslint:disable-line:no-use-before-declare
  OfferDetailCategory,
  OfferDetailOfferType,
} from '../../../api/models/offer_card';

import {
  Currency,
  IChangableType,
  ICommercialAdditionalInfo,
  ICommercialInfo,
  ICommercialInfoLayout,
  ILift,
  IParking,
  IUtility,
  PaymentPeriod,
  Placement,
} from '../model';

import { parseObjectType } from './base_info_parser';

export {
  responseToCommercialInfo,
  responseToCommercialInfoLayout,
  responseToCommercialAdditionalInfo,
  responseToCommercialParking,
};

type ValueFormatter = (string: string) => string;

const upperFirst = (value: string): string => value.substr(0, 1).toUpperCase() + value.substr(1);

function tryParseEnum<TResult>(
  value: string,
  enumType: any, //tslint:disable-line:no-any
  valueFormatter: ValueFormatter = upperFirst,
): TResult | undefined {
  if (!value) {
    return undefined;
  }
  const result = enumType[valueFormatter(value)];
  if (result == null) {
    return undefined;
  }
  return result as TResult;
}

function mapAccess(data: IOfferDetail): string | undefined {
  const map: { [key: string]: string } = {
    'free': 'Свободный',
    'passSystem': 'Пропускная система',
  };
  return map[data.accessType as string];
}

function mapClassType(data: IOfferDetail): string | undefined {
  const map: { [key: string]: string } = {
    'a': 'A',
    'aPlus': 'A+',
    'b': 'B',
    'bMinus': 'B-',
    'bPlus': 'B+',
    'c': 'C',
    'cPlus': 'C+',
    'd': 'D',
  };
  return map[data.building && data.building.classType || ''];
}

function mapCurrency(currency?: string): Currency | undefined {
  switch (currency) {
    case 'eur':
      return Currency.EUR;
    case 'rur':
      return Currency.RUB;
    case 'usd':
      return Currency.USD;
    default:
      return undefined;
  }
}

function mappCommercialRoomType(data: IOfferDetail): string | undefined {
  const map: { [key: string]: string } = {
    'shoppingMall': 'Помещение в торговом комплексе',
    'streetRetail': 'Street retail',
  };
  return map[data.placementType as string];
}

function mapEnterType(data: IOfferDetail): string | undefined {
  const map: { [key: string]: string } = {
    'separateFromYard': 'Отдельный со двора',
    'separateFromStreet': 'Отдельный с улицы',
    'commonFromYard': 'Общий со двора',
    'commonFromStreet': 'Общий с улицы',
  };
  return map[data.inputType as string];
}

function mapCarStoreTypeString(data: IOfferDetail): string | undefined {
  const map: { [key: string]: string } = {
    'parkingPlace': 'Машиноместо',
    'garage': 'Гараж',
    'box': 'Бокс',
  };
  const carStoreType = (get(data, 'garage.type'));
  return map[carStoreType];
}
function mapCarStoreStatusString(data: IOfferDetail): string | undefined {
  const map: { [key: string]: string } = {
    'byProxy': 'По доверенности',
    'cooperative': 'Кооператив',
    'ownership': 'Собственность',
  };
  const carStoreType = (get(data, 'garage.status'));
  return map[carStoreType];
}

function mapGarageType(data: IOfferDetail): string | undefined {
  if (get(data, 'garage.type') !== 'garage') {
    return undefined;
  }
  const map: { [key: string]: string } = {
    'builtIn': 'Встроенный',
    'capital': 'Капитальный',
    'shell': 'Ракушка',
    'samostroy': 'Самострой',
  };
  const garageType = get(data, 'garage.garageType');
  return map[garageType];
}

function mapBoxType(data: IOfferDetail): string | undefined {
  if (get(data, 'garage.type') !== 'box') {
    return undefined;
  }
  const map: { [key: string]: string } = {
    'metal': 'Металлический',
    'brick': 'Кирпичный',
  };
  const boxType = get(data, 'garage.material');
  return map[boxType];

}

function mapLayoutType(data: IOfferDetail): string | undefined {
  const map: { [key: string]: string } = {
    'cabinet': 'Кабинетная',
    'openSpace': 'Открытая',
    'mixed': 'Смешанная',
  };
  return map[data.layout as string];
}

function mapPaymentPeriod(data: IOfferDetail): PaymentPeriod | undefined {
  switch (get(data, 'bargainTerms.paymentPeriod')) {
    case 'annual':
      return PaymentPeriod.Year;
    case 'monthly':
      return PaymentPeriod.Month;
    default:
      return undefined;
  }
}

function mapPermittedTypeUsage(data: IOfferDetail): IChangableType | undefined {
  if (!data.permittedUseType) {
    return undefined;
  }
  const map: { [key: string]: string } = {
    'agricultural': 'Cельскохозяйственное использование',
    'individualHousingConstruction': 'Индивидуальное жилищное строительство (ИЖС)',
    'lowriseHousing': 'Малоэтажное жилищное строительство (МЖС)',
    'highriseBuildings': 'Высотная застройка',
    'publicUseOfCapitalConstruction': 'Общественное использование объектов капитального строительства',
    'businessManagement': 'Деловое управление',
    'shoppingCenters': 'Торговые центры',
    'hotelAmenities': 'Гостиничное обслуживание',
    'serviceVehicles': 'Обслуживание автотранспорта',
    'leisure': 'Отдых (рекреация)',
    'industry': 'Промышленность',
    'warehouses': 'Склады',
    'commonUseArea': 'Общее пользование территории',
  };
  return {
    possibleChange: data.possibleToChangePermittedUseType,
    type: map[data.permittedUseType as string],
  };
}

function mapPossiblePurposeType(data: IOfferDetail): string[] | undefined {
  const hasInCategory = [
    'shoppingAreaRent',
    'shoppingAreaSale',
    'freeAppointmentObjectRent',
    'freeAppointmentObjectSale',
  ].includes(data.category as string);

  if (!hasInCategory) {
    return undefined;
  }
  const specialities = get(data, 'specialty.types');
  const additionalSpecialities = get(data, 'specialty.additionalTypes');

  let result: string[] = [];
  if (specialities) {
    result = parseSpecialty(data);
  }

  if (additionalSpecialities) {
    result.push(...additionalSpecialities);
  }

  result = result.filter(Boolean);

  return result.length > 0 ? result : undefined;
}

function mapStateType(data: IOfferDetail): string | undefined {
  const map: { [key: string]: string } = {
    'cosmeticRepairsRequired': 'Требуется косметический ремонт',
    'design': 'Дизайнернский ремонт',
    'finishing': 'Под чистовую отделку',
    'majorRepairsRequired': 'Требуется капитальный ремонт',
    'office': 'Офисная отделка',
    'typical': 'Типовой ремонт',
  };
  return map[data.conditionType as string];
}

function mapLandCategory(data: IOfferDetail): IChangableType | undefined {
  if (data.land == null || !data.land.status) {
    return undefined;
  }
  const map: { [key: string]: string } = {
    'individualHousingConstruction': 'Индивидуальное жилищное строительство',
    'gardening': 'Садоводство',
    'farm': 'Фермерское хозяйство',
    'suburbanNonProfitPartnership': 'Дачное некоммерческое партнерство',
    'industrialLand': 'Земля промышленного назначения',
    'gardeningNonProfitPartnership': 'Садовое некоммерческое товарищество',
    'suburbanNonProfitSettlementsPartnership': 'Дачное некоммерческое партнерство поселений',
    'forAgriculturalPurposes': 'Участок сельскохозяйственного назначения',
    'industryTransportCommunications': 'Участок промышленности, транспорта, связи и иного не сельхоз. назначения',
    'ofProtectedCategories': 'Особо охраняемых категорий',
    'forestArea': 'Участок лесного фонда',
    'waterArea': 'Участок водного фонда',
    'reserve': 'Участок запаса',
    'investmentProject': 'Инвестпроект',
    'settlements': 'Участок поселений',
  };

  const status = data.land.status;
  return {
    type: map[status],
    possibleChange: data.land.possibleToChangeStatus,
  };
}

function mapGateType(data: IOfferDetail): string | undefined {
  const map: { [key: string]: string } = {
    'atZero': 'На нулевой отметке',
    'dockType': 'Докового типа',
    'onRamp': 'На пандусе',
  };

  return map[get(data, 'building.gatesType')];
}

function mapPropertyType(data: IOfferDetail): string | undefined {
  const availCategories: OfferDetailCategory[] = ['businessRent', 'businessSale'];
  if (!data.propertyType || !data.category || !availCategories.includes(data.category)) {
    return undefined;
  }

  const map: { [key: string]: string } = {
    'building': 'Здание',
    'freeAppointment': 'ПСН',
    'garage': 'Гараж',
    'industry': 'Производство',
    'land': 'Земля',
    'office': 'Офис',
    'shoppingArea': 'Торг. площадь',
    'warehouse': 'Склад',
  };

  return map[data.propertyType];
}

function responseToCommercialInfo(offer: IOfferDetail): ICommercialInfo | null {
  const availCategories: OfferDetailCategory[] = [
    'officeRent',
    'shoppingAreaRent',
    'freeAppointmentObjectRent',
    'industryRent',
    'warehouseRent',
    'garageRent',
    'businessRent',
    'commercialLandRent',
    'officeSale',
    'shoppingAreaSale',
    'freeAppointmentObjectSale',
    'industrySale',
    'warehouseSale',
    'garageSale',
    'businessSale',
    'commercialLandSale',
  ];

  if (!offer.category || !availCategories.includes(offer.category)) {
    return null;
  }

  const { land } = offer;
  const steadArea = land && parseNumberLike(land.area);

  const commercialInfo: ICommercialInfo = {
    area: parseNumberLike(offer.totalArea),
    floor: parseNumberLike(offer.floorNumber),
    totalFloors: parseNumberLike(get(offer, 'building.floorsCount')),
    landCategory: mapLandCategory(offer),
    ceilingHeight: parseNumberLike(get(offer, 'building.ceilingHeight')),
    class: mapClassType(offer),
    columnGrid: get(offer, 'building.columnGrid'),
    commercialRoomType: mappCommercialRoomType(offer),
    currency: mapCurrency(get(offer, 'bargainTerms.currency')),
    encumbrance: get(offer, 'hasEncumbrances'),
    enter: mapEnterType(offer),
    carStoreType: mapCarStoreTypeString(offer),
    carStoreStatus: mapCarStoreStatusString(offer),
    boxType: mapBoxType(offer),
    garageType: mapGarageType(offer),
    investmentProject: get(offer, 'hasInvestmentProject'),
    isFree: offer.isOccupied != null ? !offer.isOccupied : undefined,
    paymentPeriod: mapPaymentPeriod(offer),
    permittedUsageType: mapPermittedTypeUsage(offer),
    possiblePurpose: mapPossiblePurposeType(offer),
    steadArea: land && steadArea && (
      steadArea * (land.areaUnitType === 'sotka' ? 0.01 : 1)
    ),
    objectType: parseObjectType(offer),
    propertyType: mapPropertyType(offer),
    state: mapStateType(offer),
  };

  return commercialInfo;
}

function responseToCommercialInfoLayout(data: IOfferDetail): ICommercialInfoLayout | null {
  const filteringCategories = ['buildingSale', 'buildingRent'];
  if (data.offerType !== 'commercial' || filteringCategories.includes(data.category as string)) {
    return null;
  }

  const commercialInfo: ICommercialInfoLayout = {
    access: mapAccess(data),
    furniture: get(data, 'hasFurniture'),
    layout: mapLayoutType(data),
    state: mapStateType(data),
    enter: mapEnterType(data),
    gate: mapGateType(data),
    equipment: get(data, 'hasEquipment'),
  };

  return commercialInfo;
}

function mapCrane(data: IOfferDetail): ILift[] | undefined {
  const cranesData = get(data, 'building.cranageTypes');

  if (!cranesData || !cranesData.length) {
    return undefined;
  }

  const cranesMap: { [key: string]: string } = {
    'overhead': 'Мостовой',
    'beam': 'Кран-балка',
    'railway': 'Ж/Д кран',
    'gantry': 'Козловой кран',
  };

  const cranes: ILift[] = cranesData
    .filter(Boolean)
    .map((item: ICranageType) => {
      const crane: ILift = {
        capacity: item.loadCapacity,
        type: cranesMap[item.type as string],
        count: item.count,
      };
      return crane;
    })
    .filter((crane: ILift) => crane.count != null);

  return cranes;
}

function mapElevator(data: IOfferDetail): ILift[] | undefined {
  const elevatorsData: IApiLift[] = get(data, 'building.liftTypes');

  if (!elevatorsData || !elevatorsData.length) {
    return undefined;
  }

  const elevatorsMap: { [key: string]: string } = {
    cargo: 'Грузовой',
    telpher: 'Тельфер',
    passenger: 'Пассажирский',
  };

  const elevators: ILift[] = elevatorsData
    .filter((lift: IApiLift) => {
      return Object.keys(elevatorsMap).includes(lift.type as string)
        && lift.count != null;
    })
    .map((item: IApiLift): ILift => {
      const elevator: ILift = {
        capacity: parseNumberLike(item.loadCapacity),
        type: item.additionalType || elevatorsMap[item.type as string],
        count: item.count,
      };
      return elevator;
    });

  return elevators;

}

function mapDireways(data: IOfferDetail): string | undefined {
  const map: { [key: string]: string } = {
    'asphalt': 'Асфальтированная дорога',
    'ground': 'Грунтовая дорога',
    'no': 'Нет',
  };
  return map[data.drivewayType as string];
}

function mapElectricity(data: IOfferDetail): IUtility | undefined {
  const electricityData = data.electricity;
  if (!electricityData) {
    return undefined;
  }

  const electricity: IUtility = {
    capacity: electricityData.power,
    possibleToConnect: electricityData.possibleToConnect,
    placement: tryParseEnum<Placement>(electricityData.locationType as string, Placement) as Placement,
  };
  return electricity;
}

function mapWaterSupply(data: IOfferDetail): IUtility | undefined {
  const waterData = data.water;
  if (!waterData) {
    return undefined;
  }

  const typesMap: { [key: string]: string } = {
    'waterIntakeFacility': 'Водозаборный узел',
    'waterTower': 'Водонапорная башня',
    'pumpingStation': 'Водонапорная станция',
    'central': 'Центральное',
    'autonomous': 'Автономное',
  };
  const utility: IUtility = {
    capacity: waterData.capacity,
    possibleToConnect: waterData.possibleToConnect,
    placement: tryParseEnum<Placement>(waterData.locationType as string, Placement) as Placement,
    type: typesMap[waterData.type as string],
  };
  return utility;
}

function mapGas(data: IOfferDetail): IUtility | undefined {
  const gasData = data.gas;
  if (!gasData) {
    return undefined;
  }

  const pressureMap: { [key: string]: string } = {
    'high': 'Высокое',
    'low': 'Низкое',
    'middle': 'Среднее',
  };
  const gas: IUtility = {
    capacity: gasData.capacity,
    placement: tryParseEnum<Placement>(gasData.locationType as string, Placement) as Placement,
    pressure: pressureMap[gasData.pressureType as string],
    possibleToConnect: gasData.possibleToConnect,
  };
  return gas;
}

function mapSewerage(data: IOfferDetail): IUtility | undefined {
  const sewerageData = data.drainage;
  if (!sewerageData) {
    return undefined;
  }

  const typeMap: { [key: string]: string } = {
    'storm': 'Ливневая',
    'treatmentFacilities': 'Очистные сооружения',
    'septicTank': 'Септик',
    'central': 'Центральная',
    'autonomous': 'Автономная',
  };
  const sewerage: IUtility = {
    capacity: sewerageData.capacity,
    placement: tryParseEnum<Placement>(sewerageData.locationType as string, Placement) as Placement,
    type: typeMap[sewerageData.type as string],
    possibleToConnect: sewerageData.possibleToConnect,
  };
  return sewerage;
}

function mapParking(data: IOfferDetail): IParking | undefined {
  const parkingData: IApiParking | void = get(data, 'building.parking');
  if (!parkingData) {
    return undefined;
  }

  const typeMap: { [key: string]: string } = {
    'roof': 'На крыше',
    'underground': 'Подземная',
    'ground': 'Наземная',
    'multilevel': 'Многоуровневая',
    'open': 'Открытая',
  };

  const purposeMap: { [key: string]: string } = {
    'cargo': 'Для грузового транспорта',
    'passenger': 'Для легковесного транспорта',
  };

  const placementMap: { [key: string]: string } = {
    'internal': 'На территории объекта',
    'external': 'За территорией объекта',
  };

  const parking: IParking = {
    capacity: parkingData.placesCount,
    type: typeMap[parkingData.type as string],
    purpose: purposeMap[parkingData.purposeType as string],
    currency: mapCurrency(parkingData.currency),
    placement: placementMap[parkingData.locationType as string],
    priceMonthly: parkingData.priceMonthly,
    priceEntry: parkingData.priceEntry,
  };
  return parking;
}

function responseToCommercialAdditionalInfo(data: IOfferDetail): ICommercialAdditionalInfo | null {
  const filteringCategories: OfferDetailCategory[] = [
    'buildingRent',
    'buildingSale',
    'businessRent',
    'businessSale',
    'garageRent',
    'garageSale',
  ];

  const availOfferTypes: OfferDetailOfferType[] = ['suburban', 'commercial'];

  if (!data.offerType || !data.category) {
    return null;
  }

  if (!availOfferTypes.includes(data.offerType) || filteringCategories.includes(data.category)) {
    return null;
  }

  const includeForCategory = function <T>(value: () => T, categories: string[]): T | undefined {
    if (!categories.includes(data.category as string)) {
      return undefined;
    }
    return value();
  };

  const liftInfoCategories = [
    'industrySale',
    'industryRent',
    'warehouseSale',
    'warehouseRent',
  ];
  const utilityCategories = [
    'commercialLandSale',
    'commercialLandRent',
  ];

  const commercialInfo: ICommercialAdditionalInfo = {
    cranes: includeForCategory(() => mapCrane(data), liftInfoCategories),
    elevators: includeForCategory(() => mapElevator(data), liftInfoCategories),
    driveways: includeForCategory(() => mapDireways(data), utilityCategories),
    electricity: includeForCategory(() => mapElectricity(data), utilityCategories),
    waterSupply: includeForCategory(() => mapWaterSupply(data), utilityCategories),
    gas: includeForCategory(() => mapGas(data), utilityCategories),
    sewerage: includeForCategory(() => mapSewerage(data), utilityCategories),
  };

  return commercialInfo;
}

function responseToCommercialParking(data: IOfferDetail): IParking | null {
  const availOfferTypes: OfferDetailOfferType[] = ['suburban', 'commercial'];

  if (!data.offerType || !data.category || !availOfferTypes.includes(data.offerType)) {
    return null;
  }

  const includeForCategory = function <T>(value: () => T, categories: string[]): T | undefined {
    if (!categories.includes(data.category as string)) {
      return undefined;
    }
    return value();
  };

  const parkingCategories = [
    'officeRent',
    'officeSale',
    'shoppingAreaRent',
    'shoppingAreaSale',
    'buildingRent',
    'buildingSale',
    'warehouseRent',
    'warehouseSale',
    'industryRent',
    'industrySale',
    'garageRent',
    'garageSale',
  ];

  const commercialParkingInfo: IParking | undefined = includeForCategory(() => mapParking(data), parkingCategories);

  return commercialParkingInfo || null;
}
