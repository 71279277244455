import { decodeStringWithEmoji } from './decode_emoji';

export function setTitle(title: string) {
  if (typeof document === 'undefined') {
    return;
  }

  document.title = decodeStringWithEmoji(title);
}

export function setNoindex(noindex: boolean) {
  if (typeof document === 'undefined' || !noindex) {
    return;
  }

  const metas = document.getElementsByTagName('meta');
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].name === 'noindex') {
      metas[i].content = 'noindex';
      break;
    }
  }
}

export function setDescription(description: string) {
  if (typeof document === 'undefined') {
    return;
  }

  const metas = document.getElementsByTagName('meta');
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].name === 'description') {
      metas[i].content = description;
      break;
    }
  }
}

export interface IMetaData {
  title?: string;
  description?: string;
  canonical?: string;
  noindex?: boolean;
}

export function setMetaData(metaData: IMetaData) {
  const { title, description, noindex } = metaData;

  if (title) {
    setTitle(title);
  }

  if (description) {
    setDescription(description);
  }

  if (noindex) {
    setNoindex(noindex);
  }
}
