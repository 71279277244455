import * as React from 'react';

import { FiltersSection } from '../../redux/filters/model';
import { VisualSize } from '../../ui/cian_components/utils';
import Text, { TextAlign, TextColor } from '../../ui/text/text';

import * as styles from './index.css';

interface ISpecialPromoBannerProps {
  isPikRegion: boolean;
  section: FiltersSection;
}

export const PIK_LANDING_RELATIVE_PATH = `/cat.php?${[
    'deal_type=sale',
    'engine_version=2',
    'from_developer=1',
    'builder=9',
    'offer_type=flat',
    'with_newobject=1',
    'object_type%5B0%5D=2',
    'location%5B1%5D=1',
    'location%5B0%5D=4593',
    'is_pik_promo=1',
  ].join('&')}`;

export class SpecialPromoBanner extends React.Component<ISpecialPromoBannerProps> {
  public render() {
    const { isPikRegion, section } = this.props;
    const isSectionSuitable = [
      FiltersSection.Index,
      FiltersSection.Sale,
      FiltersSection.NewBuildings,
    ].includes(section);

    if (!isPikRegion || !isSectionSuitable) { return null; }

    return (
      <a
        href={PIK_LANDING_RELATIVE_PATH}
        className={styles['special_promo_block']}
      >
        <Text
          title
          align={TextAlign.LEFT}
          size={VisualSize.SMALL}
          color={TextColor.WHITE}
          textStyles={styles['title']}
          tag="h3"
        >
          Районы, продуманные<br/>до мелочей
        </Text>
        <img
          className={styles['special_promo_block-icon']}
          src="https://static.cdn-cian.ru/media/images/pik_white.svg"
          alt="ПИК"
        />
      </a>
    );
  }
}
