import { omit } from 'lodash';
import { Dispatch } from 'redux';

import {
  DealType,
  IFavoritesOptions,
  IFavoriteStats,
  IGetFavoritesIdsResponseOffers,
  IGetFavoritesResponseData,
  OfferType,
} from '../../api/api';
import { toPromise } from '../../utils/streams';
import { checkRedirectData } from '../common/actions';
import { TTypedThunkAction } from '../model';
import { IOfferInfo } from '../offer/model';
import { IGk } from '../offers_list/gk';
import { FavoritesState, FavouritesIDs, IFavouritePage } from './model';
import { transformFavoritesIDsResponse } from './parser';

export type TFavouritesActions
  = { type: 'SetFavoritesIDs', payload: FavouritesIDs }
  | { type: 'SetFavoritesOffers', favouriteItems: IGetFavoritesResponseData, options: IFavoritesOptions }
  | { type: 'AddFavoritesOffers', favouriteItems: IGetFavoritesResponseData, options: IFavoritesOptions }
  | { type: 'RemoveFavoriteOfferFromStore', options: IFavoritesOptions, pages: IFavouritePage[], stats: IFavoriteStats }
  | { type: 'SetFavoritesCountSuccess', count: number }
  | { type: 'SetFavoritesStats', stats: IFavoriteStats }
  | { type: 'SetFavoritesOffersIds', favoriteOffersIds: IGetFavoritesIdsResponseOffers }
  | { type: 'SetFavoriteOffersLoading', loading: boolean }
  | { type: 'SetFavoriteFilters', dealType: DealType, offerType: OfferType }
  | { type: 'SetFavoriteTotal', total: number }
  | { type: 'ClearPages' }
  | { type: 'ClearFavorites' }
  | { type: 'ChangeGkFavoriteStatus', payload: { id: number, state: boolean }}
  | { type: 'ChangeGkMapFavoriteStatus', payload: { state: boolean }};

export const FAVORITES_PER_PAGE_COUNT = 25;
const DELAY_FOR_VAFORITES_UPDATE = 1000;

function getOptionsForQuery(options: IFavoritesOptions, perPage: number, resetOffest?: boolean): IFavoritesOptions {
  const newOptions = { ...options };

  // в запрос вместо all отправляем undefined, но в стор записываем all
  if (options.dealType === 'all') {
    newOptions.dealType = undefined;
  }

  if (options.page != null && options.offset != null && !resetOffest) {
    newOptions.offset = Math.max(options.page - 1, 0) * perPage - options.offset;
  } else {
    newOptions.offset = 0;
  }

  if (newOptions.offset < 0) {
    newOptions.offset = newOptions.offset * -1;
  }

  return omit(newOptions, ['totalCurrent']);
}

export function setLoading(loading: boolean): TFavouritesActions {
  return { type: 'SetFavoriteOffersLoading', loading };
}

export function setFavoriteTotal(total: number): TFavouritesActions {
  return { type: 'SetFavoriteTotal', total };
}

export function clearFavorites(): TFavouritesActions {
  return { type: 'ClearFavorites' };
}

export function changeGkFavoriteStatus(id: string, state: boolean): TFavouritesActions {
  return {
    type: 'ChangeGkFavoriteStatus',
    payload: {
      id: parseInt(id, 10),
      state,
    },
  };
}

export function changeGkMapFavoriteStatus(state: boolean): TFavouritesActions {
  return {
    type: 'ChangeGkMapFavoriteStatus',
    payload: {
      state,
    },
  };
}

export function addGkToFavorites(id: string, isMap?: boolean): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    let { count } = getState().favorites;
    const favoriteId = isMap ? undefined : id;
    dispatch(setFavoriteTotal(++count));
    setGkFavoriteStatus(dispatch, true, favoriteId);

    return toPromise(api.addJkToFavourites(Number(id)))
      .then(() => {
        dispatch(fetchFavoritesCount(false));
      })
      .catch(() => {
        dispatch(setFavoriteTotal(--count));
        setGkFavoriteStatus(dispatch, false, favoriteId);
      });
  };
}

const setGkFavoriteStatus = (dispatch: Dispatch<TFavouritesActions>, state: boolean, id?: string) => {
  if (id === undefined) {
    dispatch(changeGkMapFavoriteStatus(state));
  } else {
    dispatch(changeGkFavoriteStatus(id, state));
  }
};

// TODO: https://cianru.atlassian.net/browse/CD-47948
/* ignore-istanbul-next */
export function removeGkFromFavorites(id: string, pageNum?: number, isMap?: boolean):
  TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    const { favorites, routing } = getState();
    let count = favorites.count;

    const favoriteId = isMap ? undefined : id;
    dispatch(setFavoriteTotal(--count));
    setGkFavoriteStatus(dispatch, false, favoriteId);

    return toPromise(api.removeJkFromFavourites(Number(id)))
      .then(() => {

        if (routing.routeName === 'favorites') {
          const offset = (favorites.options.offset || 0) + 1;

          dispatch(removeFavoriteOfferFromStore(
            favorites,
            Number(id),
            pageNum || 1,
            offset,
            true,
          ));
        }

        // TODO: https://cianru.atlassian.net/wiki/spaces/audience/pages/1081999572/-+Cassandra
        setTimeout(() => dispatch(fetchFavoritesCount(false)), DELAY_FOR_VAFORITES_UPDATE);
      })
      .catch(() => {
        dispatch(setFavoriteTotal(++count));
        setGkFavoriteStatus(dispatch, true, favoriteId);
      });
  };
}

// TODO: https://cianru.atlassian.net/browse/CD-47948
/* ignore-istanbul-next */
export function removeFavoriteOfferFromStore(
  favorites: FavoritesState,
  offerId: number,
  pageNum: number,
  offset: number,
  isGk?: boolean,
): TTypedThunkAction<Promise<void>> {
  const newPages = favorites.pages.map((page: IFavouritePage) => {
    if (page.num !== pageNum) {
      return page;
    }

    return {
      num: pageNum,
      offers: isGk ? page.offers : page.offers
        .filter((offer: IOfferInfo) => offer.urlParams.id !== offerId),
      newobjects: isGk ? page.newobjects
        .filter((gk: IGk) => parseInt(gk.id, 10) !== offerId)  : page.newobjects,
      favourites: page.favourites.filter(item => item.id !== offerId),
    };
  }).filter((page: IFavouritePage) => page.offers.length || page.newobjects.length);

  const newOptionsForStore = {
    ...favorites.options,
    offset,
    totalCurrent: favorites.favouriteItems.totalCurrent - offset,
  };

  let needToResetFavorites = false;

  if (newPages.length === 0) {
    needToResetFavorites = true;
    newOptionsForStore.offset = 0;

    if (!newOptionsForStore.offerType && newOptionsForStore.dealType) {
      newOptionsForStore.dealType = 'all';
    }

    if (newOptionsForStore.offerType) {
      newOptionsForStore.offerType = undefined;
    }
  }

  return (dispatch, getState, { api }) => {
    if (needToResetFavorites) {
      dispatch<TFavouritesActions>(setLoading(true));
    }

    const newOptionsForQuery = getOptionsForQuery(newOptionsForStore, favorites.favouriteItems.perPage);

    // TODO: https://cianru.atlassian.net/wiki/spaces/audience/pages/1081999572/-+Cassandra
    return Promise.resolve().then(() => {
      setTimeout(() => {
        toPromise(api.fetchFavorites(newOptionsForQuery))
          .then(res => {
            dispatch<TFavouritesActions>(setLoading(false));

            const { data } = res.result;

            if (needToResetFavorites) {
              dispatch<TFavouritesActions>(setFavoriteOffers(data, newOptionsForStore));
            } else {
              dispatch({
                type: 'RemoveFavoriteOfferFromStore',
                options: newOptionsForStore,
                pages: newPages,
                stats: data.stats,
              });
            }
          });
      }, DELAY_FOR_VAFORITES_UPDATE);
    });
  };
}

export function setFavoritesCount(count: number): TFavouritesActions {
  return { type: 'SetFavoritesCountSuccess', count };
}

export function setFavoritesStats(stats: IFavoriteStats): TFavouritesActions {
  return { type: 'SetFavoritesStats', stats };
}

export function setFavoriteOffers(items: IGetFavoritesResponseData, options: IFavoritesOptions): TFavouritesActions {
  return {
    type: 'SetFavoritesOffers',
    favouriteItems: items,
    options,
  };
}

export function setFavoritesOffersIds(offers: IGetFavoritesIdsResponseOffers): TFavouritesActions {
  return { type: 'SetFavoritesOffersIds', favoriteOffersIds: offers };
}

export function fetchFavoritesCount(isDeleting?: boolean,
                                    shouldResetOfferType?: boolean): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    const { favorites, routing } = getState();
    const isFavoritePage = routing.routeName === 'favorites';
    const shouldResetPage = isFavoritePage && shouldResetOfferType;

    const options = {
      ...favorites.options,
      offerType: (!favorites.favouriteItems.newobjects.length && shouldResetOfferType)
        ? undefined
        : favorites.options.offerType,
      page: shouldResetPage ? 1 : favorites.options.page,
      offset: shouldResetPage ? 0 : favorites.options.offset,
    };

    const newOptions = getOptionsForQuery(options, favorites.favouriteItems.perPage, shouldResetOfferType);

    /// TODO уточнить у бэка, когда уберут архивнутые объявы из /ajax/mobile/favorites_count/
    // return toPromise(api.getFavoritesCount())
    return toPromise(api.fetchFavorites(newOptions))
      .then(res => {
        const { stats } = res.result.data;
        dispatch(checkRedirectData(res));
        dispatch<TFavouritesActions>(setFavoritesCount(stats.total));

        if (isDeleting) {
          dispatch<TFavouritesActions>(setFavoritesStats(stats));
        } else if (!isFavoritePage) {
          dispatch<TFavouritesActions>(setFavoriteOffers(res.result.data, options));
        }

        if (shouldResetPage) {
          dispatch<TFavouritesActions>(setFavoriteOffers(res.result.data, options));
        }
      })
      .catch((ex) => {
        logger.error(new Error(`Failed to load favorites with error: ${JSON.stringify(ex)}`));
      });
  };
}

export function fetchFavoritesIDs(): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    return toPromise(api.getFavoritesIDs())
      .then(res => {
        const payload = transformFavoritesIDsResponse(res.result.data);
        dispatch<TFavouritesActions>({ type: 'SetFavoritesIDs', payload });
      })
      .catch(err => {
        logger.error(new Error('fetchFavoritesIDs error.'));
      });
  };
}
