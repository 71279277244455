export type TAmenitiesOptions = {
  key: string,
  text: string,
  value?: boolean;
};

export const amenitiesOptions: TAmenitiesOptions[] = [
  { key: 'wifi', text: 'Wi-Fi' },
  { key: 'bikeParking', text: 'Велопарковка' },
  { key: 'internet', text: 'Высокоскоростной интернет' },
  { key: 'restZone', text: 'Зоны отдыха' },
  { key: 'cafe', text: 'Кафе' },
  { key: 'cleaning', text: 'Клининг' },
  { key: 'kitchen', text: 'Кухня' },
  { key: 'furniture', text: 'Мебель' },
  { key: 'security', text: 'Охрана' },
  { key: 'parking', text: 'Парковка' },
  { key: 'meetingRoom', text: 'Переговорные комнаты' },
  { key: 'printer', text: 'Принтер и сканер' },
  { key: 'reception', text: 'Ресепшн' },
  { key: 'modern', text: 'Современный ремонт' },
  { key: 'gym', text: 'Спорт-зал' },
  { key: 'phoneBooth', text: 'Телефонные будки' },
  { key: 'techSupport', text: 'Техническая поддержка' },
  { key: 'events', text: 'Участие в мероприятиях' },
  { key: 'fax', text: 'Факс' },
  { key: 'flipchart', text: 'Флипчарт' },
  { key: 'fruits', text: 'Фрукты и закуски' },
  { key: 'tea', text: 'Чай/кофе' },
  { key: 'lockers', text: 'Шкафчики' },
  { key: 'legalAddress', text: 'Юридический адрес' },
];

export const amenitiesOptionsKeys = amenitiesOptions.map(item => item.key);
