/* eslint-disable max-lines */

import { Link as CianLink, mergeStyles } from '@cian/components';

import * as React from 'react';
import { connect } from 'react-redux';

import { MergeNotificationContainer } from '../../../shared/containers/MergeNotificationContainer';
import { ApplicationContext, ApplicationContextModel } from '../../../shared/utils/applicationContext';
import { IExperimentResultSchema } from '../../api/models/ab_use';
import { TUserGaType } from '../../api/models/data_layer';
import {
  filterToMobileAdvancedFiltersCount,
  jsonQueryToMobileAdvancedFiltersCount,
} from '../../redux/filters/json_query_to_advanced_filters_count';
import { setAppBannerState } from '../../redux/fixed_components/actions';
import { ReduxState, TTypedThunkDispatch } from '../../redux/model';
import { isSidebarOpened, setSidebarState } from '../../redux/popups/actions';
import { RouteName } from '../../redux/routing/model';
import Button from '../../ui/cian_components/button/button';
import Counter, { CounterSize } from '../../ui/counter/counter';
import Icon from '../../ui/icon/icon';
import BlueLink from '../../ui/link/link';
import Logo from '../../ui/logo/logo';
import { ScrollFolding } from '../../ui/scroll_folding/scroll_folding';
import { mobHeaderClick, tabletHeaderClick } from '../../utils/analytics';
import { IChatsConfig, inject } from '../../utils/context_provider';
import { isWinPhone } from '../../utils/device_detector';
import { getPlacementProps } from '../../utils/getPlacementProps/getPlacementProps';
import { getHost } from '../../utils/location';
import { routeFor } from '../../utils/routeFor';
import { DESKTOP_LINKS } from '../../utils/to_desktop';
import ErrorContainer from '../error_container/error_container';
import { LoginMotivationPopupContainer } from '../login_motivation_popup/login_motivation_popup';
import Search from '../search/search';
import SidebarButton from '../sidebar_button/sidebar_button';

import { HeaderChatsIconContainer } from './chats_icon';
import { IconLikeSearch } from './icon_search';

const styles = require('./header.css');

interface IHeaderStoreProps {
  isNotFoundPage?: boolean;
  isNewLogo: boolean;
  isNewYearLogo: boolean;
  advancedFiltersCount: number;
  deviceType: string;
  favoritesCount?: number;
  isUserAuthenticated: boolean;
  isVisible: boolean;
  lkUrl: string;
  queryString: string;
  routeName: RouteName;
  showBanner: boolean;
  sidebarOpened: boolean;
  storedFiltersCount: number;
  userAgent: string;
  userName: string;
  withAdvancedSearch: boolean;
  homeUrl: string;
  homeIsOnOtherDomain: boolean;
  addUrl: string;
  favoritesUrl: string;
  authUrl: string;
  // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
  experiments?: IExperimentResultSchema[];
  userType: TUserGaType;
}

interface IHeaderContextProps {
  chats: IChatsConfig;
}

interface IHeaderDispatchProps {
  setSidebarState: (state: boolean) => void;
  setAppBannerState: (state: boolean) => void;
}

type IHeaderProps = IHeaderStoreProps & IHeaderContextProps & IHeaderDispatchProps;

export class HeaderContent extends React.Component<IHeaderProps, object> {
  // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
  public static contextType?: React.Context<ApplicationContextModel> | undefined = ApplicationContext;

  public constructor(props: IHeaderProps) {
    super(props);
  }

  public shouldComponentUpdate(newProps: IHeaderProps): boolean {
    return (
      newProps.isUserAuthenticated !== this.props.isUserAuthenticated ||
      newProps.storedFiltersCount !== this.props.storedFiltersCount ||
      newProps.sidebarOpened !== this.props.sidebarOpened ||
      newProps.favoritesCount !== this.props.favoritesCount ||
      newProps.showBanner !== this.props.showBanner ||
      newProps.withAdvancedSearch !== this.props.withAdvancedSearch ||
      newProps.routeName !== this.props.routeName ||
      newProps.advancedFiltersCount !== this.props.advancedFiltersCount ||
      newProps.userName !== this.props.userName ||
      newProps.queryString !== this.props.queryString
    );
  }

  public render(): JSX.Element {
    const {
      advancedFiltersCount,
      favoritesCount,
      isUserAuthenticated,
      isNewLogo,
      isNewYearLogo,
      lkUrl,
      queryString,
      routeName,
      storedFiltersCount,
      userName,
      withAdvancedSearch,
      homeUrl,
      homeIsOnOtherDomain,
      addUrl,
      favoritesUrl,
      authUrl,
      // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
      experiments,
      userType,
    } = this.props;
    const headerItemLink = `${styles.headerItem} ${styles.headerLink}`;

    // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
    const { config } = this.context as ApplicationContextModel;
    const { text, url } = getPlacementProps({
      config,
      experiments,
      userType,
    });

    return (
      <div>
        {/* <AppBanner utils={this.bannerUtils}} /> */}
        <MergeNotificationContainer />
        <header className={styles['header']}>
          <div className={styles['leftGroup']}>
            <SidebarButton />
            <a className={styles.logoArea} href={homeUrl} onClick={this.handleLogoClick}>
              <Logo isNewLogo={isNewLogo} isNewYearLogo={isNewYearLogo} />
            </a>
          </div>
          <div className={styles.rightGroup}>
            <CianLink href={url || addUrl} linkStyle={headerItemLink} onClick={this.handleAddAdvClick}>
              <Icon name="add-header" styleConfig={styles.itemIcon} />
              <span data-testid="header-add-offer-button">{text || 'Разместить объявление'}</span>
            </CianLink>
            {userName ? (
              <CianLink href={lkUrl} linkStyle={headerItemLink} onClick={this.handleLKClick}>
                <Icon name="lk" styleConfig={styles.itemIcon} />
                <span>
                  <div className={styles.lkLabel}>Личный кабинет</div>
                  <Button buttonStyle={styles.userName} theme="link/blue">
                    {userName}
                  </Button>
                </span>
              </CianLink>
            ) : (
              <div className={styles.headerItem}>
                {homeIsOnOtherDomain ? (
                  <a className={[styles.link, styles.headerLink].join(' ')} href={authUrl}>
                    <Icon name="lk" styleConfig={styles.itemIcon} />
                    Войти
                  </a>
                ) : (
                  <BlueLink className={styles.headerLink} to={authUrl}>
                    <Icon name="lk" styleConfig={styles.itemIcon} />
                    Войти
                  </BlueLink>
                )}
                {this.props.deviceType !== 'phone' ? <LoginMotivationPopupContainer /> : null}
              </div>
            )}
            <div className={styles['icon-container']}>
              <HeaderChatsIconContainer />
              {isUserAuthenticated && (
                <a
                  className={styles['icon-area']}
                  href={this.props.lkUrl + 'saved-search'}
                  onClick={this.handleSavedSearchesClick}
                >
                  <div className={styles.icon}>
                    <IconLikeSearch />
                    <div className={styles.counter}>
                      <div className={styles.counterInner}>
                        <Counter size={CounterSize.S}>{storedFiltersCount}</Counter>
                      </div>
                    </div>
                  </div>
                </a>
              )}
              <a className={styles['icon-area']} href={favoritesUrl} onClick={this.handleFavoritesClick}>
                {this.getFavoritesLinkBody(favoritesCount)}
              </a>
            </div>
          </div>
        </header>
        {withAdvancedSearch && (
          <Search
            advancedFiltersCount={advancedFiltersCount}
            link={`/nd/search/?${queryString}`}
            routeName={routeName}
          />
        )}
        <ErrorContainer />
      </div>
    );
  }

  private getFavoritesLinkBody(favoritesCount?: number): JSX.Element {
    return (
      <div className={styles.icon}>
        <Icon name="heart" />
        <div className={styles.counter}>
          <div className={styles.counterInner}>
            <Counter size={CounterSize.S}>{favoritesCount}</Counter>
          </div>
        </div>
      </div>
    );
  }

  private handleSavedSearchesClick: VoidFunction = () => mobHeaderClick('saved_search');
  private handleFavoritesClick: VoidFunction = () => mobHeaderClick('favorite');
  private handleLogoClick: VoidFunction = () => mobHeaderClick('logo');
  private handleAddAdvClick: VoidFunction = () => tabletHeaderClick('add_adv');
  private handleLKClick: VoidFunction = () => tabletHeaderClick('mycian');
}

const getHeaderHeight = (props: IHeaderProps): number => {
  const headerHeight = 50; // завязан на css = высота + тень
  const appBannerHeight = 84; // завязан на css
  const searchHeight = 40; // завязан на css

  return [headerHeight, props.showBanner && appBannerHeight, props.withAdvancedSearch && searchHeight]
    .filter(Boolean)
    .map(Number)
    .reduce((s, x) => x + s, 0);
};

class HeaderFixed extends React.Component<IHeaderProps> {
  public render(): JSX.Element {
    const containerStyles = mergeStyles([styles.headerFixed, this.props.showBanner && styles.withBanner]);

    return (
      <ScrollFolding
        containerStyle={containerStyles.className}
        hideScrollOffset={this.getHeaderHeight}
        noFold={isWinPhone(this.props.userAgent)}
      >
        <HeaderContent {...this.props} />
      </ScrollFolding>
    );
  }

  private readonly getHeaderHeight = (): number => {
    return getHeaderHeight(this.props);
  };
}

class HeaderStub extends React.Component<IHeaderProps> {
  public shouldComponentUpdate(newProps: IHeaderProps): boolean {
    return (
      newProps.showBanner !== this.props.showBanner || newProps.withAdvancedSearch !== this.props.withAdvancedSearch
    );
  }

  public render(): JSX.Element {
    let headerHeight;

    if (!this.props.isNotFoundPage) {
      headerHeight = getHeaderHeight(this.props);
    }

    /**
     * 50px стандартная высота header
     */
    const containerStyles = mergeStyles([styles.headerStub, { height: headerHeight || '50px' }]);

    return <div {...containerStyles} />;
  }
}

class Header extends React.Component<IHeaderProps> {
  public render(): JSX.Element {
    return (
      <div className={styles['headers_container']}>
        <HeaderStub {...this.props} />
        <HeaderFixed {...this.props} />
      </div>
    );
  }
}

const mapStoreProps = (state: ReduxState): IHeaderStoreProps => {
  const { routeName } = state.routing;
  const { searchList, favorites, filter, mapPage, queryClient } = state;
  const isNewbuildingsMapMode = mapPage.newbuildingsPinsEnabled;
  // на карте фильтр может поменяться только при выделении области,
  // но это не влияет на счетчик расширенных фильтров,
  // поэтому можно считать кол-во расширенных фильтров, взяв текущее состояние
  // фильтров в сторе
  const count =
    routeName === 'map'
      ? filterToMobileAdvancedFiltersCount(filter, { isNewbuildingsMapMode }, queryClient) || 0
      : searchList.initialized
      ? jsonQueryToMobileAdvancedFiltersCount(searchList.jsonQuery)
      : 0;

  const homeUrl = getHomeUrl(state);
  const homeIsOnOtherDomain = state.common.homeIsOnOtherDomain;

  return {
    isNotFoundPage: state.isNotFound || searchList.isNotFound,
    isNewLogo: state.isNewLogo,
    isNewYearLogo: state.isNewYearLogo,
    advancedFiltersCount: count,
    deviceType: state.common.deviceType,
    favoritesCount: favorites.count,
    isUserAuthenticated: state.common.isAuthenticated,
    isVisible: state.header.isVisible,
    lkUrl: state.lkUrl,
    queryString: searchList.initialized ? searchList.queryString : '',
    routeName,
    showBanner: state.fixedComponents.appBannerOn,
    sidebarOpened: isSidebarOpened(state),
    storedFiltersCount: state.common.storedFiltersCount || 0,
    userAgent: state.userAgent,
    userName: state.common.displayUsername,
    withAdvancedSearch: showAdvancedSearchBlock(state),
    homeUrl,
    homeIsOnOtherDomain,
    addUrl: homeIsOnOtherDomain ? `${homeUrl}${DESKTOP_LINKS.ADD}` : DESKTOP_LINKS.ADD,
    favoritesUrl: homeIsOnOtherDomain ? `${homeUrl}${routeFor.FAVORITES}` : routeFor.FAVORITES,
    authUrl: `/authenticate/${
      typeof window !== 'undefined' ? `?returnUrl=${encodeURIComponent(window.location.href)}` : ''
    }`,
    experiments: state.experiments,
    userType: (state.dataLayer.initialized && state.dataLayer.data.userType) || 'not_authorized',
  };
};

function showAdvancedSearchBlock(state: ReduxState): boolean {
  const {
    routing: { routeName },
    common: { deviceType },
  } = state;

  return routeName === 'listing' || (routeName === 'map' && deviceType === 'phone');
}

function getHomeUrl(state: ReduxState): string {
  const { requestUrl, homeIsOnOtherDomain, geoSubdomain } = state.common;

  if (homeIsOnOtherDomain) {
    const host = getHost(requestUrl).split('.').slice(1).join('.') || 'cian.ru';

    return `https://${geoSubdomain || 'www'}.${host}`;
  }

  return '/';
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch): IHeaderDispatchProps {
  return {
    setSidebarState: state => dispatch(setSidebarState(state)),
    setAppBannerState: state => dispatch(setAppBannerState(state)),
  };
}

// eslint-disable-next-line import/no-default-export
export default connect<IHeaderStoreProps, IHeaderDispatchProps>(
  mapStoreProps,
  mapDispatchToProps,
)(inject(['chats'])<IHeaderStoreProps & IHeaderDispatchProps>(Header));
