import * as React from 'react';

import { IStyleConfig, mergeStyles } from '@cian/components';

const styles = require('./counter.css');

export enum CounterSize {
  S = 'S',
  M = 'M',
}

export enum CounterTheme {
  ORANGE = 'ORANGE',
  GRAY = 'GRAY',
  DARK_GRAY = 'DARK_GRAY',
  RED = 'RED',
}

const sizeClassName = {
  [CounterSize.S]: styles.sizeS,
  [CounterSize.M]: styles.sizeM,
};

const themeClassName = {
  [CounterTheme.ORANGE]: styles.themeOrange,
  [CounterTheme.GRAY]: styles.themeGray,
  [CounterTheme.DARK_GRAY]: styles.themeDarkGray,
  [CounterTheme.RED]: styles.themeRed,
};

interface ICounterProps {
  children?: number;
  size?: CounterSize;
  theme?: CounterTheme;
  styleConfig?: IStyleConfig;
}

const Counter: React.SFC<ICounterProps> = (props: ICounterProps) => {
  const size = props.size || CounterSize.M;
  const theme = props.theme || CounterTheme.RED;
  const count = props.children || 0;
  return (
    <div {...mergeStyles(
      sizeClassName[size],
      themeClassName[theme],
      count === 0 && styles.hidden,
      props.styleConfig,
    )}>
      <div className={styles.content}>
        {count}
      </div>
    </div>
  );
};

export default Counter;
