import { IGKSeoData, IIconField, IParking, IReliableHouse } from '../../api/models/gk_card';
import { IAdditionalDescriptions, IPromoInfo, IQueryFlatTypesStats } from '../../api/models/gk_preview';
import { IGKPhoneDataLayer } from '../../api/models/gk_preview';
import { IJKStatFull } from '../../api/models/gk_preview';
import { IGKMVKDataResponse } from '../../components/gk_flat_selector_widget/typings';
import { IGKPageAnalytics } from '../../utils/analytics';
import { IAddressInfo } from '../offer/model';

export interface IGKPage {
  seoData: IGKSeoData;
  GK: IGK;
  isFetching: boolean;
  GKMVK?: IGKMVKDataResponse;
  mortgageCalculatorWidget?: string;
}

export interface IGK {
  name: string;
  parking: IParking[];
  photos: IPhoto[];
  description: string;
  additional_descriptions: IAdditionalDescriptions;
  url: string;
  builders: IBuilder[];
  contacts: IBuilderContact[];
  completion_year?: number;
  address: IAddressInfo;
  flats: IGKFlats;
  infrastructure: IIconField[];
  security: IIconField[];
  aboutGK: IAboutGK;
  video_urls?: IVideoURL[];
  priceSummary?: IPriceSummary;
  constructionProgress: IConstructionStage[];
  promo?: IPromo;
  analytics?: IGKPageAnalytics;
  id: string;
  is_reliable?: boolean;
  show_jk_reliable_flag?: boolean;
  is_problem?: boolean;
  problems?: IGKProblem[];
  reliable_houses?: IReliableHouse[];
  is_favorite?: boolean;
  promoInfoList?: IPromoInfo[];
  promo_info_list?: IPromoInfo[];
  is_deal_request_substitution_phone?: boolean;
  web_site_url_utm?: string;
  web_site_url?: string;
  is_test?: boolean;
}

export interface IGKProblem {
  source: string;
  description: string;
  source_logo_url: string | null;
  source_url: string;
}

const defaultGKAdditionalDescriptions = {
  infrastructure: '',
  developer_reliability: '',
  architecture: '',
};

const defaultGKFlats: IGKFlats = {
  developer: {
    flats: [],
    totalCount: 0,
    link: '',
  },
  agent: {
    totalCount: 0,
    link: '',
  },
};

export interface IVideoURL {
  url: string;
}

export interface IAboutGK {
  stage?: string;
  queues?: string[];
  status?: string;
  buildingClass?: string;
  houses?: number;
  floors?: string;
  material?: string;
  ceilings?: string;
}

export const defaultGKState: IGK = {
  name: '',
  description: '',
  additional_descriptions: defaultGKAdditionalDescriptions,
  url: '',
  parking: [],
  builders: [],
  contacts: [],
  address: {},
  infrastructure: [],
  constructionProgress: [],
  security: [],
  aboutGK: {},
  photos: [],
  flats: defaultGKFlats,
  id: '',
  is_test: false,
};

export const defaultSeoData = {
  keywords: '',
  description: '',
  title: '',
};

export const defaultGKPageState: IGKPage = {
  seoData: defaultSeoData,
  GK: defaultGKState,
  isFetching: false,
};

export interface IPhoto {
  fullUrl?: string;
  miniUrl?: string;
  thumbnailUrl?: string;
  thumbnail2Url?: string;
}

export interface IBuilder {
  id?: number;
  name: string;
  logoUrl?: string;
  phone_data_layer: IGKPhoneDataLayer;
  year_foundation?: number;
  jk_stat_full?: IJKStatFull;
  website_url?: string;
}

export interface IBuilderContact extends IBuilder {
  phone: string;
  role: CompanyRole;
  siteBlockId?: number | null;
}

export enum CompanyRole {
  Builder,
  Seller,
}

export interface IPriceSummary {
  min?: number;
  max?: number;
  pricePerUnit?: number;
}

export interface IGKFlats {
  developer: {
    flats: IQueryFlatTypesStats[];
    totalCount: number;
    link: string;
  };
  agent: {
    totalCount: number;
    link: string;
  };
}

export interface IPromo {
  name: string;
  end?: string;
  count: number;
  qs: string;
  logoUrl?: string;
}

export interface IConstructionProgressPhoto {
  text: string;
  fullUrl: string;
  miniUrl: string;
}

export interface IConstructionStage {
  date: string;
  images: IConstructionProgressPhoto[];
}

export interface IGalleryTab {
  title: string;
  startIndex: number;
}
