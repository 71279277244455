import { ErrorLogComponent } from '@cian/error-log-component';

import * as PropTypes from 'prop-types';
import * as React from 'react';
import { Component } from 'react';

import { IAppContext } from '../../utils/context_provider';

export class ErrorHandlerLogger extends Component<{}, {}> {
  public static contextTypes = {
    appContext: PropTypes.object,
  };

  public context: { appContext: IAppContext };

  public render(): JSX.Element {
    const { logger } = this.context.appContext;

    return <ErrorLogComponent logger={logger}>{this.props.children}</ErrorLogComponent>;
  }
}
