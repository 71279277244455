/* eslint-disable max-lines */
import { mergeStyles } from '@cian/utils';

import * as React from 'react';
import { connect } from 'react-redux';

import { ApplicationContext, ApplicationContextModel } from '../../../shared/utils/applicationContext';
import { IRegionInfo } from '../../api/api';
import { IExperimentResultSchema } from '../../api/models/ab_use';
import { TUserGaType } from '../../api/models/data_layer';
import { IRegionsSettings } from '../../redux/filters/model';
import { ReduxState } from '../../redux/model';
import { ISpecialPromoInfo } from '../../redux/special_promos_info/model';
import { ISpecialPromosInfoState } from '../../redux/special_promos_info/reducer';
import Counter, { CounterTheme } from '../../ui/counter/counter';
import Icon from '../../ui/icon/icon';
import Menu, { IMenuLink } from '../../ui/menu/menu';
import MenuDivider from '../../ui/menu/menu_divider';
import { menuClick } from '../../utils/analytics';
import { getIsPikRegion } from '../../utils/getIsPikRegion';
import { getPlacementProps } from '../../utils/getPlacementProps/getPlacementProps';
import { getOfferLinks } from '../../utils/get_offer_links';
import { getFullUrl } from '../../utils/location';
import { replaceNewbuildingSubdomainToGeo } from '../../utils/newbuildingSubdomain';
import { isSaintPetersburgAndRegion } from '../../utils/regions';
import { routeFor } from '../../utils/routeFor';
import { DESKTOP_LINKS } from '../../utils/to_desktop';
import { SidebarUnreadsContainer } from '../chats_unreads_count';
import DownloadAppButton, { TOs } from '../download_app_button/download_app_button';
import SidebarButton from '../sidebar_button/sidebar_button';
import { PIK_LANDING_RELATIVE_PATH } from '../special_promo_banner';

import { trackVillagesLinkClick } from './tracking';

const styles = require('./sidebar_content.css');

interface IEnvLinks {
  lk: string;
}

interface ISidebarContentStateProps {
  lkUrl: string;
  storedFiltersCount: number;
  specialPromosInfoState: ISpecialPromosInfoState;
  currentRegionInfo?: IRegionInfo;
  villagesUrl?: string;
  homeIsOnOtherDomain: boolean;
  requestUrl: string;
  geoSubdomain: string;
  pikPromoRegions: number[];
  isE2eDealVisible: boolean;
  userId?: number;
  // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
  experiments?: IExperimentResultSchema[];
  userType: TUserGaType;
}

interface ISidebarContentProps {
  children?: React.ReactNode;
  favoritesTotal: number;
  os?: TOs;
  region: number;
  regionSettings?: IRegionsSettings;
  authenticated: boolean;
  userName?: string;
  onLogOut?(): void;
}

type Props = ISidebarContentStateProps & ISidebarContentProps;

interface IPersonalLinksProps {
  authenticated: boolean;
  favoritesTotal: number;
  os?: TOs;
  lkUrl: string;
  storedFiltersCount: number;
  homeIsOnOtherDomain: boolean;
  requestUrl: string;
  geoSubdomain: string;
  isE2eDealVisible: boolean;
}

interface ILinks {
  href?: string;
  to?: string;
}

const getRouteAndHrefLinksByNewbuildingSubdomain = (
  url: string,
  path: string,
  geoSubdomain: string,
  homeIsOnOtherDomain: boolean,
): ILinks => ({
  to: homeIsOnOtherDomain ? undefined : path,
  href: homeIsOnOtherDomain ? replaceNewbuildingSubdomainToGeo(url, geoSubdomain) + path : undefined,
});

const getPersonalLinks = ({
  authenticated,
  favoritesTotal,
  lkUrl,
  storedFiltersCount,
  homeIsOnOtherDomain,
  requestUrl,
  geoSubdomain,
  isE2eDealVisible,
}: IPersonalLinksProps): IMenuLink[] =>
  [
    {
      id: 1,
      content: 'Электронная регистрация',
      onClick: () => menuClick('e2e-deal-sale'),
      icon: 'e2e-deal-sale-menu',
      hide: !isE2eDealVisible,
      href: `${lkUrl}${routeFor.MY_E2E_DEAL_SALE}`,
    },
    {
      id: 2,
      content: 'Новый поиск',
      onClick: () => menuClick('new_search'),
      icon: 'search-menu',
      ...getRouteAndHrefLinksByNewbuildingSubdomain(requestUrl, routeFor.INDEX, geoSubdomain, homeIsOnOtherDomain),
    },
    {
      id: 3,
      content: (
        <span>
          Избранное&nbsp;
          <span className={styles.counter}>
            <Counter theme={CounterTheme.GRAY}>{favoritesTotal}</Counter>
          </span>
        </span>
      ),
      onClick: () => menuClick('favorite'),
      icon: 'favorite-menu',
      href: routeFor.FAVORITES,
    },
    {
      id: 4,
      content: (
        <span>
          Сохранённые поиски
          {storedFiltersCount ? <span className={styles['count-container']}>{storedFiltersCount}</span> : ''}
        </span>
      ),
      onClick: () => menuClick('saved_search'),
      href: `${lkUrl}${routeFor.SAVED_SEARCH}`,
      icon: 'search-menu',
      hide: !authenticated,
    },
    {
      id: 5,
      content: 'Скрытые объявления',
      onClick: () => menuClick('hidden_offers'),
      href: `${lkUrl}${routeFor.HIDDEN_OFFERS}`,
      icon: 'hidden-offers',
      hide: !authenticated,
    },
    {
      id: 6,
      content: (
        <span>
          Сообщения <SidebarUnreadsContainer />
        </span>
      ),
      onClick: () => menuClick('messages'),
      icon: 'chats-menu',
      href: DESKTOP_LINKS.CHATS,
    },
    {
      id: 7,
      content: 'Справочный центр',
      onClick: (): void => {
        window.open('https://hc.cian.ru/hc/ru');
      },
      icon: 'c-question',
    },
    {
      id: 8,
      content: 'Сообщить об ошибке',
      onClick: () => menuClick('feedback'),
      href: '/contacts',
      icon: 'problem-menu',
    },
    {
      id: 9,
      content: 'Ипотечная анкета',
      onClick: (): void => {
        window.open('https://www.cian.ru/cf/anketa?utm_source=cian_mobile_menu&utm_medium=mortgage_button');
      },
      icon: 'mortgage',
    },
    {
      id: 10,
      content: 'Куплю / Сниму',
      onClick: () => menuClick('sale-rent'),
      href: '/application-form/?fromSaleRent=1',
      icon: 'sale-rent',
    },
    {
      id: 11,
      content: 'Онлайн оценка квартир',
      href: '/kalkulator-nedvizhimosti/',
      icon: 'valuation',
    },
    {
      id: 12,
      content: (
        <span>
          Аналитика для бизнеса <Icon name="label-new" styleConfig={styles['new']} />
        </span>
      ),
      href: '/analiz-rynka-nedvizhimosti-b2b/',
      icon: 'valuation-b2b',
    },
  ].filter(Boolean) as IMenuLink[];

const getSectionsLinks = (
  regionSettings: IRegionsSettings | undefined,
  isPikRegion: boolean,
  isSaintPetersburg: boolean,
  homeIsOnOtherDomain: boolean,
  requestUrl: string,
  geoSubdomain: string,
  specialPromoInfo?: ISpecialPromoInfo,
  specialPromoInfoDomain?: string,
  villagesUrl?: string,
): IMenuLink[] =>
  [
    {
      id: 1,
      content: 'Аренда',
      onClick: () => menuClick('rent'),
      ...getRouteAndHrefLinksByNewbuildingSubdomain(requestUrl, `/snyat/`, geoSubdomain, homeIsOnOtherDomain),
    },
    {
      id: 2,
      content: 'Продажа',
      onClick: () => menuClick('sale'),
      ...getRouteAndHrefLinksByNewbuildingSubdomain(requestUrl, `/kupit/`, geoSubdomain, homeIsOnOtherDomain),
    },
    {
      id: 3,
      content: 'Новостройки',
      onClick: () => menuClick('newobjects'),
      ...getRouteAndHrefLinksByNewbuildingSubdomain(requestUrl, `/novostrojki/`, geoSubdomain, homeIsOnOtherDomain),
    },
    {
      id: 4,
      content: 'Коттеджные посёлки',
      onClick: trackVillagesLinkClick,
      href: villagesUrl,
      hide: !villagesUrl,
    },
    {
      id: 5,
      content: 'Посуточно',
      onClick: () => menuClick('daily'),
      ...getRouteAndHrefLinksByNewbuildingSubdomain(requestUrl, `/posutochno/`, geoSubdomain, homeIsOnOtherDomain),
    },
    {
      id: 6,
      content: 'Коммерческая',
      onClick: () => menuClick('commercial'),
      ...getRouteAndHrefLinksByNewbuildingSubdomain(requestUrl, `/commercial/`, geoSubdomain, homeIsOnOtherDomain),
    },
    {
      id: 7,
      content: 'Ипотека',
      onClick: () => menuClick('mortgage'),
      href: DESKTOP_LINKS.MORTGAGE,
    },
    {
      id: 8,
      content: 'Журнал',
      href: DESKTOP_LINKS.CONTENT_MAIN,
    },
    {
      id: 9,
      content: <span>Вопросы риэлтору</span>,
      href: DESKTOP_LINKS.CONTENT_ADVICE,
    },
    isPikRegion &&
      regionSettings &&
      regionSettings.isPikPromoEnabled && {
        id: 10,
        content: (
          <span className={styles['region_special']}>
            Новостройки от
            <span className={styles['region_special-endline']}>
              &nbsp;ПИК
              <img
                {...mergeStyles(styles.icon, styles.pik)}
                alt="ПИК"
                src="https://static.cdn-cian.ru/media/images/pik_orange.svg"
              />
            </span>
          </span>
        ),
        onClick: (): void => {
          menuClick('PIK');

          location.href = getFullUrl('www', PIK_LANDING_RELATIVE_PATH);
        },
      },
    !isPikRegion &&
      specialPromoInfo &&
      specialPromoInfoDomain && {
        id: 11,
        content: (
          <span className={styles['region_special']}>
            Новостройки от
            <span className={styles['region_special-endline']}>
              &nbsp;{specialPromoInfo.nameGenitive}
              <img className={styles['icon']} src={specialPromoInfo.logoMobileUrl || specialPromoInfo.logoUrl} />
            </span>
          </span>
        ),
        onClick: (): void => {
          const url = `/cat.php?${[
            'deal_type=sale',
            'engine_version=2',
            'from_developer=1',
            `builder=${specialPromoInfo.builderId}`,
            'offer_type=flat',
            'with_newobject=1',
            'object_type%5B0%5D=2',
            `location%5B0%5D=${specialPromoInfo.region}`,
            'is_special_promo=1',
          ].join('&')}`;

          location.href = getFullUrl(specialPromoInfoDomain, url);
        },
      },
  ].filter(Boolean) as IMenuLink[];

interface IGetProfileLinksData {
  envLinks: IEnvLinks;
  authenticated: boolean;
  userName?: string;
  requestUrl: string;
  geoSubdomain: string;
  homeIsOnOtherDomain: boolean;
}

const getProfileLinks = (data: IGetProfileLinksData): IMenuLink[] => {
  const { envLinks, authenticated, userName } = data;

  const menuItem =
    authenticated && userName
      ? {
          content: (
            <div
              style={{
                overflow: 'hidden',
              }}
            >
              <div className={styles.lk}>Личный кабинет</div>
              <div className={styles.userName}>{userName}</div>
            </div>
          ),
          href: envLinks.lk,
          styleConfig: styles.lkLink,
        }
      : {
          content: 'Войти',
          styleConfig: styles.loginLink,

          href: `/authenticate/${
            typeof window !== 'undefined' ? `?returnUrl=${encodeURIComponent(window.location.href)}` : ''
          }`,
        };

  return [
    {
      id: 1,
      icon: 'ic-profile',
      ...menuItem,
    },
  ];
};

const getLogoutLinks = (onLogOut?: () => void): IMenuLink[] => [
  {
    id: 1,
    icon: 'ic-exit',
    content: 'Выйти',
    onClick: onLogOut,
    styleConfig: styles.logoutLink,
  },
];

export class SidebarContent extends React.PureComponent<Props, object> {
  // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
  public static contextType?: React.Context<ApplicationContextModel> | undefined = ApplicationContext;

  public render(): JSX.Element {
    const {
      favoritesTotal,
      os,
      region,
      regionSettings,
      authenticated,
      userName,
      onLogOut,
      lkUrl,
      storedFiltersCount,
      specialPromosInfoState,
      currentRegionInfo,
      villagesUrl,
      homeIsOnOtherDomain,
      requestUrl,
      geoSubdomain,
      pikPromoRegions,
      isE2eDealVisible,
      // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
      experiments,
      userType,
    } = this.props;

    // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
    const { config } = this.context as ApplicationContextModel;

    let specialPromoInfo;
    let specialPromoInfoDomain;
    const parentId =
      currentRegionInfo && currentRegionInfo.id === region && (currentRegionInfo.parentId || currentRegionInfo.id);
    const currentSpecialPromoInfo = specialPromosInfoState.specialPromosInfo.find(spInfo =>
      [specialPromosInfoState.specialPromosInfoRegion, parentId].includes(spInfo.region),
    );

    if (
      currentSpecialPromoInfo &&
      (!currentSpecialPromoInfo.isPreview || specialPromosInfoState.canPreviewSpecialPromos)
    ) {
      specialPromoInfo = currentSpecialPromoInfo;
      specialPromoInfoDomain = specialPromosInfoState.specialPromosInfoDomain;
    }

    const profileLinks = getProfileLinks({
      envLinks: { lk: lkUrl },
      authenticated,
      userName,
      homeIsOnOtherDomain,
      requestUrl,
      geoSubdomain,
    });
    const offerLinks = getOfferLinks({
      authenticated,
      os,
      homeIsOnOtherDomain,
      requestUrl,
      geoSubdomain,
      // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
      ...getPlacementProps({
        config,
        experiments,
        userType,
      }),
    });
    const personalLinks = getPersonalLinks({
      authenticated,
      favoritesTotal,
      os,
      lkUrl,
      storedFiltersCount,
      homeIsOnOtherDomain,
      requestUrl,
      geoSubdomain,
      isE2eDealVisible,
    });
    const sectionsLinks = getSectionsLinks(
      regionSettings,
      getIsPikRegion(pikPromoRegions, parentId || region),
      isSaintPetersburgAndRegion(parentId || region),
      homeIsOnOtherDomain,
      requestUrl,
      geoSubdomain,
      specialPromoInfo,
      specialPromoInfoDomain,
      villagesUrl,
    );
    const logoutLinks = getLogoutLinks(onLogOut);

    return (
      <div className={styles.sidebarContent} data-mark="SidebarContent">
        <div className={styles.toggleButtonContainer}>
          <SidebarButton />
        </div>
        <div className={styles.menuContainer}>
          <Menu dataMark="Profile" links={profileLinks} />
          <MenuDivider links={profileLinks} />
          <Menu dataMark="Offer" links={offerLinks} />
          <MenuDivider links={offerLinks} />
          <Menu dataMark="Personal" links={personalLinks} />
          <MenuDivider links={personalLinks} />
          <Menu dataMark="Sections" links={sectionsLinks} />
          {authenticated && (
            <div>
              <MenuDivider links={sectionsLinks} />
              <Menu dataMark="Logout" links={logoutLinks} />
              <MenuDivider links={logoutLinks} />
            </div>
          )}
          <div className={styles.downloadAppButton} data-mark="DownloadAppButton">
            <DownloadAppButton sidebar analyticsPlace="Mob_menu" os={os} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): ISidebarContentStateProps => ({
  lkUrl: state.lkUrl,
  storedFiltersCount: state.common.storedFiltersCount || 0,
  specialPromosInfoState: state.specialPromosInfo,
  currentRegionInfo: state.regions.currentRegionInfo,
  villagesUrl: state.common.villagesUrl,
  homeIsOnOtherDomain: state.common.homeIsOnOtherDomain,

  requestUrl: state.common.requestUrl,
  geoSubdomain: state.common.geoSubdomain,
  pikPromoRegions: state.pikPromoRegions,
  isE2eDealVisible: state.isE2eDealVisible,
  userId: Number(state.dataLayer.initialized && state.dataLayer.data.realtyUserId) || undefined,
  // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
  experiments: state.experiments,
  userType: (state.dataLayer.initialized && state.dataLayer.data.userType) || 'not_authorized',
});

// eslint-disable-next-line import/no-default-export
export default connect<ISidebarContentStateProps, {}, ISidebarContentProps>(mapStateToProps)(SidebarContent);
