import * as React from 'react';

import { CheckboxField, IStyleProps, mergeStyles } from '@cian/components';

const styles = require('./filters_checkbox_list.css');
const fieldStyles = require('../../filters/fields/field.css');

export type Value = string | number;

export interface ICheckboxListItem {
  id: Value;
  label: string;
}

export interface ICheckboxListProps {
  list: ICheckboxListItem[];
  selected: Value[];
  desktop?: boolean;
  containerStyles?: IStyleProps;
  onItemChecked?: (checked: boolean, itemId: Value) => void;
  dataMark?: string;
}

export class CheckboxList extends React.Component<ICheckboxListProps, object> {
  public render() {
    const { list, selected, containerStyles, desktop, dataMark } = this.props;
    const checkboxContainerStyles = mergeStyles(
      styles.checkboxContainer,
      desktop && styles.desktop,
    );

    return (
      <div {...mergeStyles(containerStyles)} data-mark={dataMark}>
        {list.map(item => {
          const value = selected.includes(item.id);
          const labelStyles = mergeStyles(
            fieldStyles.labelCheckbox,
            value && fieldStyles.checked,
            desktop && fieldStyles.desktop,
          ).className;

          return <div {...checkboxContainerStyles} key={item.id} data-mark={`CheckboxListItem${item.id}`}>
            <CheckboxField
              label={item.label}
              value={value}
              onValueChange={(checked) => this.onItemChecked(checked, item.id)}
              labelStyle={labelStyles}
            />
          </div>;
        })}
      </div>
    );
  }
  private onItemChecked = (checked: boolean, item: Value) => {
    if (this.props.onItemChecked) {
      this.props.onItemChecked(checked, item);
    }
  }
}
