import { ReduxState } from '../../redux/model';

export enum EFavoritesAuthenticateGroup {
  NothingNew = 0,
  Limit5 = 1,
  Limit3 = 2,
  Limit1 = 3,
}

export function getFavoritesAuthenticateExperimentStatus(state: ReduxState): EFavoritesAuthenticateGroup {
  return EFavoritesAuthenticateGroup.Limit5;
}
