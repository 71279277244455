import { formatNumber } from '../../utils/formatters';
import { AreaUnitType, IRange } from '../../redux/offer/model';

export function getAreaLabel(area: number | IRange | undefined, areaUnitType?: AreaUnitType): string {
  if (!area) { return ''; }

  const areaUnit = areaUnitType || 'м²';

  if (typeof area === 'number') {
    return `${formatNumber(area)} ${areaUnit}`;
  } else {
    return `от ${formatNumber(area.min)} до ${formatNumber(area.max)} ${areaUnit}`;
  }
}
