import { isCommercialObjectType } from '../../common/select_helper';
import { rentTimeDict } from '../../../redux/filters/dictionaries';
import { ruble } from '../../../utils/formatters';
import { DealType, RentTime, TObjectType } from '../../../redux/filters/model';

export interface IGetMetrics {
  dealType: DealType;
  rentTime: RentTime;
  selectedObjectTypes: TObjectType[];
  userAgent?: string;
  isCoworking?: boolean;
}

export function getMetrics({
  dealType,
  rentTime,
  selectedObjectTypes,
  userAgent,
  isCoworking,
}: IGetMetrics) {
  const isCommercial = isCommercialObjectType(selectedObjectTypes);
  const period = rentTime === rentTimeDict.Short ? 'день' : 'мес.';

  if (isCoworking) {
    return `${ruble(userAgent)}/мес.`;
  } else if (isCommercial) {
    return undefined;
  }

  return `${ruble(userAgent)}${dealType === DealType.Rent ? `/${period}` : ''}`;
}
