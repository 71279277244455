import { values } from 'lodash';

import { IOfferDetail, IOfferDetailResponse } from '../../../api/models/offer_card';
import { replaceOfficeUrlToCoworkingUrl } from '../../../utils/url';
import { IOfferInfo, IOfferPageInfo, IOfferURLParams, defaultOfferState } from '../model';

import { responseToAddressInfo } from './address_info_parser';
import { responseToAgentInfo } from './agent_info_parser';
import { responseToAllowed } from './allowed_parser';
import { responseToAnalyticsInfo } from './analytics_info_parser';
import { responseToApartmentConditions } from './apartment_conditions_parser';
import { responseToBaseInfo } from './base_info_parser';
import { responseToBuildingInfoModel } from './building_info_parser';
import { responseToBuildingInfrastructure } from './building_infrastructure_parser';
import { responseToCommercialInfrastructure } from './commercial_infrastructure_parser';
import { responseToGarageInfrastructure } from './garage_infrastructure_parser';
import { responseToHouseInfo } from './house_info_parser';
import { responseToPessimisation } from './pessimisation_parser';
import { responseToPhotoLabels } from './photo_labels_parser';
import { responseToPhotos } from './photos_parser';
import { responseToPlaceInfo } from './place_info_parser';
import {
  responseToCommercialAdditionalInfo,
  responseToCommercialInfo,
  responseToCommercialInfoLayout,
  responseToCommercialParking,
} from './response_to_commercial_info';
import { responseToAdditionalInfo, responseToCommonInfo } from './response_to_common_info';
import { responseToSpecialty } from './specialty_parser';
import { responseToSuburbanRentConditions } from './suburban_rent_conditions_parser';
import { responseToSuburbanSaleConditions } from './suburban_sale_conditions_parser';
import { responseToTenantsComment } from './tenants_comment_parser';
import { responseToTenants } from './tenants_parser';
import { responseToVideos } from './videos_parser';

export function parseOfferDetail(offer: IOfferDetail, urlParams: IOfferURLParams, position?: number): IOfferInfo {
  const userInfo = { ...offer.user, userId: offer.userId };
  const { modelVersion } = offer;

  const isCommercialOwnershipVerified =
    offer.isCommercialOwnershipVerified || (offer.flags && offer.flags.isCommercialOwnershipVerified);

  return {
    auctionPosition: offer.auctionPosition,
    additionalInfo: compact(responseToAdditionalInfo(offer)),
    addressInfo: responseToAddressInfo(offer),
    agentInfo: responseToAgentInfo(offer),
    allowed: responseToAllowed(offer),
    apartmentConditions: responseToApartmentConditions(offer),
    baseInfo: responseToBaseInfo(offer),
    booking: offer.booking,
    buildingInfo: responseToBuildingInfoModel(offer),
    buildingInfrastructure: responseToBuildingInfrastructure(offer),
    businessShoppingCenter: offer.businessShoppingCenter,
    category: offer.category,
    categoriesIds: offer.categoriesIds,
    callTrackingProvider: offer.callTrackingProvider || null,
    commercialAdditionalInfo: compact(responseToCommercialAdditionalInfo(offer)),
    commercialInfo: compact(responseToCommercialInfo(offer)),
    commercialInfoLayout: compact(responseToCommercialInfoLayout(offer)),
    commercialInfrastructure: responseToCommercialInfrastructure(offer),
    commercialParking: compact(responseToCommercialParking(offer)),
    commonInfo: compact(responseToCommonInfo(offer)),
    demandJsonQuery: offer.demandJsonQuery,
    demolishedInMoscowProgramm: offer.demolishedInMoscowProgramm,
    description: offer.description,
    flags: offer.flags || {},
    fromDeveloper: offer.fromDeveloper,
    gaLabel:
      offer.gaLabel && offer.coworkingOfferType
        ? replaceOfficeUrlToCoworkingUrl(offer.coworkingOfferType)(offer.gaLabel)
        : offer.gaLabel,
    garageInfrastructure: responseToGarageInfrastructure(offer),
    geo: offer.geo,
    houseInfo: responseToHouseInfo(offer),
    id: offer.id as number,
    cianId: offer.cianId,
    cianUserId: offer.cianUserId,
    isByHomeowner: offer.isByHomeowner,
    isByCommercialOwner: offer.isByCommercialOwner,
    isCommercialOwnershipVerified,
    isDuplicatedDescription: Boolean(offer.isDuplicatedDescription),
    isFavorite: Boolean(offer.isFavorite),
    isFromBuilder: offer.isFromBuilder,
    isInHiddenBase: Boolean(offer.isInHiddenBase),
    isCalltrackingEnabled: Boolean(offer.isEnabledCallTracking),
    isPro: Boolean(offer.isPro),
    kp: offer.kp,
    multioffer: offer.similar,
    modelVersion:
      typeof modelVersion === 'number' || typeof modelVersion === 'string' ? Number(offer.modelVersion) : undefined,
    offerAnalyticsInfo: responseToAnalyticsInfo(offer, position),
    pessimisation: responseToPessimisation(offer),
    photos: responseToPhotos(offer),
    placeInfo: responseToPlaceInfo(offer),
    district: offer.district,
    priceChanges: offer.priceChanges,
    removalPublication: offer.status !== 'published',
    status: offer.status,
    roomsCount: offer.roomsCount,
    newbuilding: offer.newbuilding,
    suburbanRentConditions: responseToSuburbanRentConditions(offer),
    suburbanSaleConditions: responseToSuburbanSaleConditions(offer),
    tenants: responseToTenants(offer),
    tenantsComment: responseToTenantsComment(offer),
    title: offer.title,
    urlParams,
    videos: responseToVideos(offer),
    user: userInfo,
    dealType: offer.dealType,
    offerType: offer.offerType,
    valueAddedServices: offer.valueAddedServices,
    workTimeInfo: offer.workTimeInfo,
    isAgentOnline: offer.isAgentOnline,
    publishedUserId: offer.publishedUserId,
    isHiddenByUser: offer.isHiddenByUser,
    isNew: offer.isNew,
    isDealRequestSubstitutionPhone: offer.isDealRequestSubstitutionPhone,

    isApartments: offer.isApartments,
    flatType: offer.flatType,
    totalArea: offer.totalArea,
    minArea: offer.minArea,
    maxArea: offer.maxArea,
    land: offer.land,
    bargainTerms: offer.bargainTerms,
    homeownerProofs: offer.homeownerProofs,
    newbuildingDynamicCalltracking: offer.newbuildingDynamicCalltracking,
    isResale: offer.isResale,
    areaParts: offer.areaParts,
    repairType: offer.repairType,
    livingConditions: {
      childrenAllowed: offer.childrenAllowed,
      petsAllowed: offer.petsAllowed,
    },
    amenities: {
      hasFridge: offer.hasFridge,
      hasDishwasher: offer.hasDishwasher,
      hasWasher: offer.hasWasher,
      hasFurniture: offer.hasFurniture,
      hasKitchenFurniture: offer.hasKitchenFurniture,
      hasConditioner: offer.hasConditioner,
      hasTv: offer.hasTv,
      hasInternet: offer.hasInternet,
      hasPhone: offer.hasPhone,
      hasBathtub: offer.hasBathtub,
      hasShower: offer.hasShower,
    },
    specialty: responseToSpecialty(offer),
    readyBusinessType: offer.readyBusinessType,
    isNeedHideExactAddress: offer.isNeedHideExactAddress,
    commercialOwnership: offer.commercialOwnership,
    brandingLevel: offer.brandingLevel || null,
    isIllegalConstruction: offer.isIllegalConstruction,
    tradingLink: offer.tradingLink || offer.collateralObjectSchema?.lotUrl || '',
    photoLabels: responseToPhotoLabels(offer),
  };
}

export const responseToOffer = (
  respData: IOfferDetailResponse,
  currentState: IOfferPageInfo = defaultOfferState,
  urlParams: IOfferURLParams,
): IOfferPageInfo => {
  return {
    ...currentState,
    offer: parseOfferDetail(respData.offer, urlParams),
    breadcrumbs: respData.breadcrumbs,
    statisticToken: respData.statisticToken,
    categoriesIds: respData.offer.categoriesIds,
    complaints: currentState.complaints,
  };
};

export function compact<T>(object?: T | null): T | undefined {
  if (object == null) {
    return undefined;
  }
  const hasValues = values(object).filter(value => value != null).length;

  return hasValues ? object : undefined;
}
