import {
  numberToPrettyString,
  parseNumber,
  stringToNumber,
  TextField,
} from '@cian/components';
import * as React from 'react';

import {
  numberIsEgal,
  numberTrunc,
} from '../utils/number';
import { IStyleConfig, VisualSize } from '../utils/style';

export interface INumberFieldProps extends React.Props<NumberField> {
  id?: string;
  name?: string;
  disabled?: boolean;
  disableFractional?: boolean;
  disableNegative?: boolean;
  invalid?: boolean;
  maxLength?: number;
  maxValue?: number;
  minValue?: number;
  inputMode?: string;
  pattern?: string;
  maxFractionalLength?: number;
  containerStyle?: IStyleConfig;
  inputStyle?: IStyleConfig;
  disablePrettyFormating?: boolean;
  size?: VisualSize;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  onValueChange: (value: number | void) => void;
  placeholder?: string;
  required?: boolean;
  // note: при type='number' не будет форматирования чисел,
  // только при type='text'
  type?: string;
  value: number | void;
  defaultValue?: number;
}

export interface INumberFieldState {
  textValue: string;
  caretPosition: number | null;
  maxLength?: number;
}

export default class NumberField extends React.Component<INumberFieldProps, INumberFieldState> {
  public static defaultProps = {
    maxLength: 13,
    maxFractionalLength: 2,
  };

  private textField: TextField;
  private focused = false;
  private isPreviousKeyDelimiter = false;

  public constructor(props: INumberFieldProps) {
    super(props);

    this.state = {
      caretPosition: null,
      maxLength: (props.maxLength && props.maxFractionalLength)
        && (!props.disableNegative ? 1 : 0) + props.maxLength + numberTrunc(props.maxLength / 3)
        + (!props.disableFractional ? 1 + props.maxFractionalLength : 0),
      textValue: props.disablePrettyFormating
        ? props.value && String(props.value) || ''
        : numberToPrettyString(props.value),
    };
  }

  public componentWillReceiveProps(nextProps: INumberFieldProps) {
    if (this.props.value !== nextProps.value && !this.focused) {
      this.setState({
        maxLength: (nextProps.maxLength && nextProps.maxFractionalLength) &&
          (!nextProps.disableNegative ? 1 : 0) + nextProps.maxLength + numberTrunc(nextProps.maxLength / 3)
          + (!this.props.disableFractional ? 1 + nextProps.maxFractionalLength : 0),
        textValue: this.props.disablePrettyFormating
          ? nextProps.value && String(nextProps.value) || ''
          : numberToPrettyString(nextProps.value),
      });
    }
  }

  public componentDidUpdate(prevProps: INumberFieldProps, prevState: INumberFieldState) {
    if (this.focused && this.state.caretPosition) {
      if (this.props.type === 'text') {
        this.textField.setSelectionRange(this.state.caretPosition, this.state.caretPosition);
      }
    }
  }

  public focus() {
    this.textField.focus();
  }

  public render() {
    const { type } = this.props;
    const value = type === 'number' ?
      this.state.textValue.replace(/ /g, '') :
      this.state.textValue.replace(/\./, ',');

    return (
      <TextField
        {...this.props}
        ref={(textField: TextField) => this.textField = textField}
        value={value}
        onKeyDown={this.handleKeyDown}
        onPaste={this.handlePaste}
        onValueChange={this.handleValueChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        maxLength={this.state.maxLength}
      />
    );
  }

  private handleFocus = (event: React.FocusEvent<HTMLElement>) => {
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }

    this.focused = true;
  }

  private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isPreviousKeyDelimiter = this.isPreviousKeyDelimiter;
    this.isPreviousKeyDelimiter = false;

    // backspace, delete, tab, escape, enter
    if ([46, 8, 9, 27, 13, 110].indexOf(event.keyCode) !== -1) {
      return;
    }

    // Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
    if ((event.keyCode === 65 || event.keyCode === 86 || event.keyCode === 67 || event.keyCode === 88) &&
      (event.ctrlKey || event.metaKey)) {
      return;
    }

    // home, end, left, right, down, up
    if (event.keyCode >= 35 && event.keyCode <= 40) {
      return;
    }

    if (['.', ',', 'б', 'ю'].indexOf(event.key) !== -1) {
      this.isPreviousKeyDelimiter = true;

      if (this.props.disableFractional) {
        event.preventDefault();

        return;
      }

      /**
       * Мы не можем узнать позицию каретки у input[type=number] и некоторых других в браузере Chrome
       * Просто запрещаем ввод запятой, если она уже есть
       */
      if (typeof this.textField.selectionStart === 'undefined') {
        if (this.state.textValue.length === 0 || this.state.textValue.indexOf('.') !== -1 || isPreviousKeyDelimiter) {
          event.preventDefault();
        }

        return;
      }

      const valueAfterCut = (this.textField.selectionStart && this.textField.selectionEnd) &&
        [this.state.textValue.slice(0, this.textField.selectionStart),
        this.state.textValue.slice(this.textField.selectionStart +
          (this.textField.selectionEnd - this.textField.selectionStart))].join('');

      if (valueAfterCut && valueAfterCut.length === 0 || valueAfterCut && valueAfterCut.indexOf('.') !== -1) {
        event.preventDefault();
      }
    } else if (
      (event.keyCode < 48 || event.keyCode > 57 && event.keyCode < 96 || event.keyCode > 105) &&
      event.keyCode !== 189
    ) {
      this.isPreviousKeyDelimiter = isPreviousKeyDelimiter;

      event.preventDefault();
    }
  }

  private handlePaste(event: React.ClipboardEvent<HTMLInputElement>) {
    const text = event.clipboardData.getData('text/plain');

    if (!/^[0-9\.\-,бю]+$/ig.test(text)) {
      event.preventDefault();

      return;
    }

    if (/^[\.,бю]+$/ig.test(text)) {
      if (typeof this.textField.selectionStart === 'undefined') {
        if (this.state.textValue.indexOf('.') !== -1 ||
          this.isPreviousKeyDelimiter) {
          event.preventDefault();
        }

        return;
      }

      const valueAfterCut = (this.textField.selectionStart && this.textField.selectionEnd) &&
        [this.state.textValue.slice(0, this.textField.selectionStart),
        this.state.textValue.slice(this.textField.selectionStart +
          (this.textField.selectionEnd - this.textField.selectionStart))].join('');

      if (valueAfterCut && valueAfterCut.indexOf('.') !== -1) {
        event.preventDefault();
      }

      this.isPreviousKeyDelimiter = false;
    }
  }

  private handleValueChange = (value: string) => {
    const textValue = value
      .replace(/[,бю]/g, '.');

    let parsedNumber = parseNumber(textValue);

    let integerTrim = parsedNumber.integer.replace(/^0+(?!$)/, '');
    if (!this.props.disableFractional && parsedNumber.integer.startsWith('0') && integerTrim.length > 1) {
      parsedNumber = parseNumber(`${parsedNumber.sign}0.${integerTrim}`);
      integerTrim = '0';
    }

    const newTextValue = [
      this.props.disableNegative ? '' : parsedNumber.sign,
      integerTrim.slice(0, this.props.maxLength),
      this.props.disableFractional || parsedNumber.fractional === null ? '' : '.',
      (parsedNumber.fractional || '').slice(0, this.props.maxFractionalLength),
    ].join('');

    let numberValue = newTextValue === ''
      ? undefined
      : stringToNumber(newTextValue);

    if (numberValue && isNaN(numberValue)) {
      numberValue = undefined;
    }

    let newPrettyTextValue = newTextValue;
    if (typeof this.textField.selectionStart !== 'undefined') {
      if (typeof numberValue !== 'undefined') {
        const integer = numberTrunc(numberValue);

        if (!numberIsEgal(integer, -0)) {
          newPrettyTextValue = this.props.disablePrettyFormating
            ? integer && String(integer) || ''
            : numberToPrettyString(integer);
          if (!this.props.disableFractional && parsedNumber.fractional !== null) {
            newPrettyTextValue += '.' + (parsedNumber.fractional || '').slice(0, this.props.maxFractionalLength);
          }
        }
      }

      const caretPosition = this.textField.selectionStart;
      const oldCaretPosition = caretPosition &&
        caretPosition - (value.length - this.state.textValue.length);

      let newCaretPosition = oldCaretPosition ?
        oldCaretPosition + (newPrettyTextValue.length - this.state.textValue.length) : null;
      if (caretPosition && oldCaretPosition) {
        if (oldCaretPosition === newCaretPosition && caretPosition < oldCaretPosition) {
          newCaretPosition = caretPosition;
        }
      }

      this.props.onValueChange(numberValue);
      this.setState({
        caretPosition: newCaretPosition,
        textValue: newPrettyTextValue,
      });
    } else {
      this.props.onValueChange(numberValue);
      this.setState({
        textValue: newPrettyTextValue,
      });
    }
  }

  private handleBlur = (event: React.FocusEvent<HTMLElement>) => {
    this.focused = false;

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }

    const textValue = this.state.textValue
      .replace(/ /g, '');

    const { integer, fractional } = parseNumber(textValue);

    if (!integer && !fractional) {
      this.setState({
        textValue: '',
      });

      return;
    }

    let numberValue = stringToNumber(textValue);
    this.setState({
      textValue: this.props.disablePrettyFormating
        ? numberValue && String(numberValue) || ''
        : numberToPrettyString(numberValue),
    });
  }
  // tslint:disable-next-line:max-file-line-count
}
