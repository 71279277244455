import { connect } from 'react-redux';
import { IUserRegion, ResourceStatus } from '../../redux/common/model';
import { getRegionSettings, setRegion, updateNewobjectCheckboxesByPromoState } from '../../redux/filters/actions';
import { ReduxState, TTypedThunkDispatch } from '../../redux/model';
import { closePopup, isPopupOpened } from '../../redux/popups/actions';
import { getRegionCities } from '../../redux/regions/actions';
import { refineSearchChangeGeo } from '../../utils/analytics';
import { getSubdomain } from '../../utils/location';
import { parseRegions } from '../select_region_base/helpers';
import {
  ISelectRegionWithCitiesStateProps,
  ISelectRegionWithCitiesDispatchProps,
  ISelectRegionWithCitiesOwnProps,
  ISelectRegionWithCitiesMergeProps,
} from './model';
import { SelectRegion } from '../select_region_base/select_region';
import { getChangeGeoGaLabel } from '../../utils/getChangeGeoGaLabel';

export interface ISpecialPromosState {
  withPik?: boolean;
  withCustom?: boolean;
}

const stateToProps = (
  state: ReduxState,
  ownProps: ISelectRegionWithCitiesOwnProps,
): ISelectRegionWithCitiesStateProps => {
  const { specialPromosInfo } = state.specialPromosInfo;

  return {
    userAgent: state.userAgent,
    regions: state.regions.regionsInfo ? parseRegions(state.regions.regionsInfo, 'link', specialPromosInfo) : [],
    selectedRegionId: state.filter.region,
    meta: state.regions.regionMeta.location,
    regionCities: state.regions.regionCities
      ? parseRegions(state.regions.regionCities.data, 'radio', specialPromosInfo)
      : [],
    isLoading: state.regions.regionCities.status === ResourceStatus.Pending,
    opened: isPopupOpened(state, ownProps.popupQueryParam),
    routeName: state.routing.routeName,
    filter: state.filter,
  };
};

const dispatchToProps = (
  dispatch: TTypedThunkDispatch,
  ownProps: ISelectRegionWithCitiesOwnProps,
): ISelectRegionWithCitiesDispatchProps => {
  return {
    getRegionCities(regionId: number) {
      dispatch(getRegionCities(regionId));
    },
    onSave(region: IUserRegion) {
      const subdomain = getSubdomain(location.href);
      dispatch(setRegion({ region: region.id, resetTags: true }));
      dispatch(getRegionSettings(subdomain, region.id));
      dispatch(updateNewobjectCheckboxesByPromoState(region));
    },
    onClose(isMapPage?: boolean) {
      dispatch(closePopup(ownProps.popupQueryParam, isMapPage));
    },
  };
};

const mergeProps = (
  stateProps: ISelectRegionWithCitiesStateProps,
  dispatchProps: ISelectRegionWithCitiesDispatchProps,
  ownProps: ISelectRegionWithCitiesOwnProps,
): ISelectRegionWithCitiesMergeProps => {
  const { filter, ...restStateProps } = stateProps;

  function onSave(region: IUserRegion) {
    dispatchProps.onSave(region);

    const gaLabel = getChangeGeoGaLabel({ filter, region });

    refineSearchChangeGeo(gaLabel);
  }

  return {
    ...restStateProps,
    ...dispatchProps,
    ...ownProps,
    onSave,
  };
};

export default connect(stateToProps, dispatchToProps, mergeProps)(SelectRegion);
