import { get, isEmpty } from 'lodash';
import { ISendContactsRequest } from '../../api/api';
import { toPromise } from '../../utils/streams';
import { ResourceStatus } from '../common/model';
import { setError, TFiltersActions } from '../filters/actions';
import { TTypedThunkAction } from '../model';

export interface ISendContactsErrors {
  name?: ISendContactsIError[];
  email?: ISendContactsIError[];
  subject?: ISendContactsIError[];
  files?: ISendContactsIError[];
  message?: ISendContactsIError[];
  cadastralNumber?: ISendContactsIError[];
}

export interface ISendContactsIError {
  code: string;
  message: string;
}

export type TContactsActions
  = { type: 'ClearContacts' }
  | { type: 'SendContactsPending' }
  | { type: 'SendContactsSuccess' }
  | { type: 'SendContactsError', payload: ISendContactsErrors };

export function sendContacts(contacts: ISendContactsRequest): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    dispatch<TContactsActions>({ type: 'SendContactsPending' });

    return toPromise(api.sendContacts(contacts))
      .then(resp => {
        const { status } = resp.result;

        if (status && status.toLowerCase() === 'ok') {
          dispatch<TContactsActions>({ type: 'SendContactsSuccess' });
        } else {
          throw new Error(`Wrong response: ${status}`);
        }
      }).catch(err => {
        const errors = get(err, 'cause.response.body.data', {});

        if (isEmpty(errors)) {
          dispatch<TFiltersActions>(setError('Не удалось отправить сообщение. Проверьте подключение к сети.'));
        }

        dispatch<TContactsActions>({ type: 'SendContactsError', payload: errors });

        logger.error(err);

        throw err;
      });
  };
}

export function clearContacts(): TContactsActions {
  return { type: 'ClearContacts' };
}

export interface IContactsState {
  status: ResourceStatus;
  errors: ISendContactsErrors;
}

export const defaultContactsState: IContactsState = {
  status: ResourceStatus.Initial,
  errors: {},
};

export type ContactsState = IContactsState;

export function reducer(state: IContactsState = defaultContactsState, action: TContactsActions): IContactsState {
  switch (action.type) {
    case 'ClearContacts':
      return defaultContactsState;

    case 'SendContactsPending':
      return {
        ...state,
        status: ResourceStatus.Pending,
      };

    case 'SendContactsError':
      return {
        ...state,
        status: ResourceStatus.Error,
        errors: action.payload,
      };

    case 'SendContactsSuccess':
      return {
        ...state,
        status: ResourceStatus.Success,
      };

    default:
      return state;
  }
}
