/* eslint-disable max-lines */

/**
 * Этот файл очень большой, но его можно сделать меньше,
 * если растащить хотя бы по разновидностям типов: enums, interfaces и types.
 * Задача максимум — отделить тайпинги API от типов стейта redux store.
 */
import { IPriceHistoryEvent } from '@cian/price-history-widget';

import {
  EReadyBusinessType,
  IAreaPart,
  IOfferAnalyticsInfo,
  IOfferKp,
  IValueAddedServices,
  RawAreaPart,
  TCalltrackingProvider,
  THomeownerProof,
} from '../../../redux/offer/model';
import { ICommercialOwnershipSchema } from '../../../types/ICommercialOwnershipSchema';
import { ObjectType, QueryDealType } from '../../../utils/analytics';
import { IJsonQueryGeo, IJsonQueryRange, IJsonQueryTerm, IJsonQueryTerms } from '../json_query';

import { EBrandingLevel } from './enums';

export enum EHeatingType {
  /** Есть */
  Has = 'has',
  /** Не указано */
  None = 'none',
  /** Нет (без отопления) */
  Without = 'without',
  /** Центральное газовое */
  Gas = 'gas',
  /** Центральное угольное */
  Coal = 'coal',
  /** Печь */
  Stove = 'stove',
  /** Камин */
  Fireplace = 'fireplace',
  /** Электрическое */
  Electric = 'electric',
  /** Автономное */
  Autonomous = 'autonomous',
  /** Котел | Квартирное отопление (квартирный котел) */
  Boiler = 'boiler',
  /** Центральное отопление */
  Central = 'central',
  /** Другое */
  Other = 'other',
  /** Автономное газовое */
  AutonomousGas = 'autonomousGas',
  /** Дизельное */
  Diesel = 'diesel',
  /** Твердотопливный котел */
  SolidFuelBoiler = 'solidFuelBoiler',
  /** Индивидуальный тепловой пункт (ИТП) */
  ITP = 'itp',
  /** Индивидуальный тепловой пункт (ИТП) */
  GasWaterHeater = 'gasWaterHeater',
  /** Автономная котельная (крышная, встроенно-пристроенная) */
  AutonomousBoiler = 'autonomousBoiler',
}

export enum EGasSupplyType {
  /** Не известно */
  Unknown = 'unknown',
  /** Автономное */
  Autonomous = 'autonomous',
  /** Центральное */
  Central = 'central',
  /** Отсутствует */
  Without = 'without',
}
export enum EOverlapType {
  /** Не известно */
  Unknown = 'unknown',
  /** Деревянные */
  Wood = 'wood',
  /** Железобетонные */
  Concrete = 'concrete',
  /** Смешанные */
  Mixed = 'mixed',
  /** Иные */
  Other = 'other',
}

export interface IBreadcrumb {
  // заголовок
  title: string;
  // ссылка
  url: string;
}

export interface IDemandJsonQuery {
  _type: string;
  room?: IJsonQueryTerms<number>;
  price?: IJsonQueryRange;
  total_area?: IJsonQueryRange;
  currency?: IJsonQueryTerm<number>;
  geo?: IJsonQueryGeo;
  region?: IJsonQueryTerms<number>;
}

export interface IOfferDetailResponse {
  correctHost: string;
  breadcrumbs?: IBreadcrumb[];
  offer: IOfferDetail;
  // Токен для статистики
  statisticToken?: string;
}

// Доступ
export type OfferDetailAccessType =
  // Свободный
  | 'free'
  // Пропускная система
  | 'passSystem';

// Категория объявления
export type OfferDetailCategory =
  // Аренда койко-места
  | 'bedRent'
  // Аренда здания
  | 'buildingRent'
  // Продажа здания
  | 'buildingSale'
  // Аренда готового бизнеса
  | 'businessRent'
  // Продажа готового бизнеса
  | 'businessSale'
  // Аренда автосервис
  | 'carServiceRent'
  // Продажа автосервиса
  | 'carServiceSale'
  // Аренда коммерческой земли
  | 'commercialLandRent'
  // Продажа коммерческой земли
  | 'commercialLandSale'
  // Аренда коттеджа
  | 'cottageRent'
  // Продажа коттеджа
  | 'cottageSale'
  // Посуточная аренда койко-места
  | 'dailyBedRent'
  // Посуточная аренда квартиры
  | 'dailyFlatRent'
  // Посуточная аренда дома, дачи, коттеджа
  | 'dailyHouseRent'
  // Посуточная аренда комнаты
  | 'dailyRoomRent'
  // Аренда помещения под бытовые услуги
  | 'domesticServicesRent'
  // Продажа помещения под бытовые услуги
  | 'domesticServicesSale'
  // Аренда квартиры
  | 'flatRent'
  // Продажа квартиры
  | 'flatSale'
  // Продажа доли в квартире
  | 'flatShareSale'
  // Аренда помещения свободного назначения
  | 'freeAppointmentObjectRent'
  // Продажа помещения свободного назначения
  | 'freeAppointmentObjectSale'
  // Аренда гаража
  | 'garageRent'
  // Продажа гаража
  | 'garageSale'
  // Аренда дома/дачи
  | 'houseRent'
  // Продажа дома/дачи
  | 'houseSale'
  // Аренда части дома
  | 'houseShareRent'
  // Продажа части дома
  | 'houseShareSale'
  // Аренда производства
  | 'industryRent'
  // Продажа производство
  | 'industrySale'
  // Продажа участка
  | 'landSale'
  // Продажа квартиры в новостройке
  | 'newBuildingFlatSale'
  // Аренда офиса
  | 'officeRent'
  // Продажа офиса
  | 'officeSale'
  // Аренда общепита
  | 'publicCateringRent'
  // Продажа общепита
  | 'publicCateringSale'
  // Аренда комнаты
  | 'roomRent'
  // Продажа комнаты
  | 'roomSale'
  // Аренда торговой площади
  | 'shoppingAreaRent'
  // Продажа торговой площади
  | 'shoppingAreaSale'
  // Аренда таунхауса
  | 'townhouseRent'
  // Продажа таунхауса
  | 'townhouseSale'
  // Аренда склада
  | 'warehouseRent'
  // Продажа склада
  | 'warehouseSale';

// Состояние
export type OfferDetailConditionRatingType =
  // Отличное
  | 'excellent'
  // Хорошее
  | 'good'
  // Удовлетворительное
  | 'satisfactory';

// Состояние
export type OfferDetailConditionType =
  // Требуется косметический ремонт
  | 'cosmeticRepairsRequired'
  // Дизайнерский ремонт
  | 'design'
  // Под чистовую отделку
  | 'finishing'
  // Требуется капитальный ремонт
  | 'majorRepairsRequired'
  // Офисная отделка
  | 'office'
  // Типовой ремонт
  | 'typical';

// Тип сделки
export type OfferDetailDealType =
  // Аренда
  | 'rent'
  // Продажа
  | 'sale';

// Отделка
export type OfferDetailDecoration =
  // Чистовая
  | 'fine'
  // Черновая
  | 'rough'
  // Без отделки
  | 'without';

// Тип канализации
export type OfferDetailDrainageType =
  // Автономная
  | 'autonomous'
  // Центральная
  | 'central'
  // Септик
  | 'septicTank'
  // Ливневая
  | 'storm'
  // Очистные сооружения
  | 'treatmentFacilities';

// Подъездные пути
export type OfferDetailDrivewayType =
  // Асфальтированная дорога
  | 'asphalt'
  // Грунтовая дорога
  | 'ground'
  // Нет
  | 'no';

// Тип электроснабжения
export type OfferDetailElectricityType =
  // По границе участка
  | 'border'
  // На участке
  | 'location'
  // Нет
  | 'no';

// Недвижимость
export type OfferDetailEstateType =
  // В собственности
  | 'owned'
  // В аренде
  | 'rent';

// Тип квартиры
export type OfferDetailFlatType =
  // Свободная планировка
  | 'openPlan'
  // Комнаты
  | 'rooms'
  // Студия
  | 'studio';

// Мебель
export type OfferDetailFurniturePresence =
  // Нет
  | 'no'
  // По желанию
  | 'optional'
  // Есть
  | 'yes';

// Тип газоснабжения
export type OfferDetailGasType =
  // По границе участка
  | 'border'
  // На участке
  | 'location'
  // Нет
  | 'no';

// Вход
export type OfferDetailInputType =
  // Общий с улицы
  | 'commonFromStreet'
  // Общий со двора
  | 'commonFromYard'
  // Отдельный с улицы
  | 'separateFromStreet'
  // Отдельный со двора
  | 'separateFromYard';

// Планировка
export type OfferDetailLayout =
  // Кабинетная
  | 'cabinet'
  // Смешанная
  | 'mixed'
  // Открытая
  | 'openSpace';

// Тип недвижимости
export type OfferDetailOfferType =
  // Коммерческая
  | 'commercial'
  // Квартиры
  | 'flat'
  // Новостройки
  | 'newobject'
  // Загородка
  | 'suburban';

// Вид разрешенного использования (ВРИ)
export type OfferDetailPermittedUseType =
  // Cельскохозяйственное использование
  | 'agricultural'
  // Деловое управление
  | 'businessManagement'
  // Общее пользование территории
  | 'commonUseArea'
  // Высотная застройка
  | 'highriseBuildings'
  // Гостиничное обслуживание
  | 'hotelAmenities'
  // Индивидуальное жилищное строительство (ИЖС)
  | 'individualHousingConstruction'
  // Промышленность
  | 'industry'
  // Отдых (рекреация)
  | 'leisure'
  // Малоэтажное жилищное строительство (МЖС)
  | 'lowriseHousing'
  // Общественное использование объектов капитального строительства
  | 'publicUseOfCapitalConstruction'
  // Обслуживание автотранспорта
  | 'serviceVehicles'
  // Торговые центры
  | 'shoppingCenters'
  // Склады
  | 'warehouses';

// Тип помещения
export type OfferDetailPlacementType =
  // Помещение в торговом комплексе
  | 'shoppingMall'
  // Street retail
  | 'streetRetail';

// Тип недвижимости
export type OfferDetailPropertyType =
  // здание
  | 'building'
  // помещение свободного назначения
  | 'freeAppointment'
  // гараж
  | 'garage'
  // производство
  | 'industry'
  // земля
  | 'land'
  // офис
  | 'office'
  // торговая площадь
  | 'shoppingArea'
  // склад
  | 'warehouse';

// Тип ремонта
export type OfferDetailRepairType =
  // Косметический
  | 'cosmetic'
  // Дизайнерский
  | 'design'
  // Евроремонт
  | 'euro'
  // Без ремонта
  | 'no';

// Тип комнаты (комната)
export type OfferDetailRoomType =
  // Оба варианта
  | 'both'
  // Совмещенная
  | 'combined'
  // Изолированная
  | 'separate';

// Статус объявления
export type OfferDetailStatus =
  // Заблокировано
  | 'blocked'
  // Деактивировано
  | 'deactivated'
  // Удалено
  | 'deleted'
  // Черновик
  | 'draft'
  // Промодерировано
  | 'moderate'
  // Опубликовано
  | 'published'
  // Отклонено
  | 'refused'
  // Удалено модератором
  | 'removedByModerator'
  // Продано
  | 'sold';

// Тип водоснабжения
export type OfferDetailWaterType =
  // Автономное
  | 'autonomous'
  // Центральное
  | 'central'
  // Водонапорная станция
  | 'pumpingStation'
  // Водозаборный узел
  | 'waterIntakeFacility'
  // Водонапорная башня
  | 'waterTower';

// Расположение санузла
export type OfferDetailWcLocationType =
  // В доме
  | 'indoors'
  // На улице
  | 'outdoors';

// Тип санузла (комната)
export type OfferDetailWcType =
  // Совмещенный
  | 'combined'
  // Раздельный
  | 'separate';

// Куда выходят окна
export type OfferDetailWindowsViewType =
  // На улицу
  | 'street'
  // Во двор
  | 'yard'
  // На улицу и двор
  | 'yardAndStreet';

export interface IWorkTimeInfo {
  callFrom: string | null;
  callTo: string | null;
  isAvailable: boolean | null;
  timezoneText: string | null;
}

export type TAgentAccountType = 'specialist' | 'agency' | 'managementCompany' | 'rentDepartment';

export type TRosreestrCheckStatus =
  // Проверка на стороне росреестра
  | 'inProgress'
  // Проверка прошла успешно
  | 'success'
  // Проверка прошла неуспешно
  | 'fail';

export interface IRosreestrCheck {
  status: TRosreestrCheckStatus;
}

/** Типы коворкингов */
export enum ECoworkingOfferType {
  /** Отдельный офис */
  Office = 'office',
  /** Закреплённое рабочее место */
  FixedWorkplace = 'fixedWorkplace',
  /** Незакреплённое рабочее место */
  FlexibleWorkplace = 'flexibleWorkplace',
  /** Конференц-зал */
  ConferenceHall = 'conferenceHall',
  /** Переговорная комната */
  MeetingRoom = 'meetingRoom',
}
/** Рабочее время коворкинга */
export interface ICoworkingWorkTime {
  /** Работает с */
  timeFrom: string;
  /** Работает до */
  timeTo: string;
}
export declare type TCoworkingAmenityNames =
  | 'wifi'
  | 'bikeParking'
  | 'internet'
  | 'restZone'
  | 'cafe'
  | 'cleaning'
  | 'kitchen'
  | 'furniture'
  | 'security'
  | 'parking'
  | 'meetingRoom'
  | 'printer'
  | 'reception'
  | 'modern'
  | 'gym'
  | 'phoneBooth'
  | 'techSupport'
  | 'events'
  | 'fax'
  | 'flipchart'
  | 'fruits'
  | 'tea'
  | 'lockers'
  | 'legalAddress';
export interface ICoworkingAmenity {
  key: TCoworkingAmenityNames;
  title: string;
}
/** Объект коворкинга */
export interface ICoworking {
  /** Название */
  name?: string;
  /** Доступ - С понедельника по пятницу | Ежедневно */
  accessType?: 'weekdays' | 'daily';
  /** Круглосуточно */
  isRoundTheClock?: boolean;
  /** Рабочее время */
  workTime?: ICoworkingWorkTime;
  /** Включенные Удобства */
  includedAmenities?: ICoworkingAmenity[];
  /** Невключенные Удобства */
  notIncludedAmenities?: ICoworkingAmenity[];
  /** ID коворкинга */
  id?: number;
}

export interface IOfferDetail {
  // Версия модели ML, которая вернула похожие
  modelVersion?: number;
  offerAnalyticsInfo?: IOfferAnalyticsInfo;
  valueAddedServices?: IValueAddedServices;
  // Броинрование квартиры в ЖК
  booking?: IOfferBooking;
  // Доступ
  accessType?: OfferDetailAccessType;
  // Дата подачи объявления
  added?: string;
  // Доп. линии
  additionalPhoneLinesAllowed?: boolean;
  // Площадь комнат, м2
  allRoomsArea?: string;
  // Дата перемещения объявления в архив
  archivedDate?: string;
  // Аукционная позиция объявления
  auctionPosition?: number;
  // Количество балконов
  balconiesCount?: number;
  // Условия сделки
  bargainTerms?: IBargainTermsPresenter;
  // Количество спален
  bedroomsCount?: number;
  // Количество спальных мест
  bedsCount?: number;
  /** Уровень брендинга по подписке **/
  brandingLevel?: EBrandingLevel | null;
  // Информация о доме
  building?: IBuildingPresenter;
  // БЦ/ТЦ
  businessShoppingCenter?: IBusinessShoppingCenter;
  // Кадастровый номер
  cadastralNumber?: string;
  // Провайдер услуги подмены номера
  callTrackingProvider?: TCalltrackingProvider;
  // Можно частями
  canParts?: boolean;
  // Категории
  categoriesIds?: number[];
  // Категория объявления
  category?: OfferDetailCategory;
  // Можно с детьми
  childrenAllowed?: boolean;
  // ID объявления на ЦИАНе
  cianId?: number;
  // ID пользователя в ЦИАНе
  cianUserId?: number;
  // Типы помещения в коворкинге
  coworkingOfferType?: ECoworkingOfferType | null;
  // Объект коворкинга
  coworking?: ICoworking;
  // Количество совместных санузлов
  combinedWcsCount?: number;
  // Состояние
  conditionRatingType?: OfferDetailConditionRatingType;
  // Состояние
  conditionType?: OfferDetailConditionType;
  // инфа для виджета яркого спроса
  demandJsonQuery?: IDemandJsonQuery;
  // Тип сделки
  dealType?: OfferDetailDealType;
  // Отделка
  decoration?: OfferDetailDecoration;
  // Текст объявления
  description?: string;
  // Информация о районе
  district?: IDistrictBTIData;
  // Канализация
  drainage?: IDrainage;
  // Объем, м3/сутки
  drainageCapacity?: number;
  // Тип канализации
  drainageType?: OfferDetailDrainageType;
  // Подъездные пути
  drivewayType?: OfferDetailDrivewayType;
  // Электроснабжение
  electricity?: IElectricity;
  // Мощность, кВТ
  electricityPower?: number;
  // Тип электроснабжения
  electricityType?: OfferDetailElectricityType;
  // Недвижимость
  estateType?: OfferDetailEstateType;
  // Флаги состояния объявления
  flags?: IFlags;
  // Тип квартиры
  flatType?: OfferDetailFlatType;
  // Этаж
  floorNumber?: number;
  // Этаж с
  floorFrom?: number;
  // Этаж по
  floorTo?: number;
  // От застройщика (тариф застройщик)
  fromDeveloper?: boolean;
  // Мебель
  furniture?: string;
  // Мебель
  furniturePresence?: OfferDetailFurniturePresence;
  // Google analytics label
  gaLabel?: string;
  // Тип гаража
  garage?: IGarage;
  // Газоснабжение
  gas?: IGas;
  // Емкость, м3/час
  gasCapacity?: number;
  // Давление, Мпа
  gasPressure?: number;
  // Тип газоснабжения
  gasType?: OfferDetailGasType;
  geo?: IGeo;
  // Есть баня
  hasBathhouse?: boolean;
  // Есть ванна
  hasBathtub?: boolean;
  // Заселение 24 часа
  hasCheckIn_24?: boolean;
  // Есть кондиционер
  hasConditioner?: boolean;
  // Есть посудомоечная машина
  hasDishwasher?: boolean;
  // Есть канализация
  hasDrainage?: boolean;
  // Есть электричество
  hasElectricity?: boolean;
  // Есть обременение
  hasEncumbrances?: boolean;
  // Есть оборудование
  hasEquipment?: boolean;
  // Организация экскурсий
  hasExcursions?: boolean;
  // Есть система пожаротушения
  hasExtinguishingSystem?: boolean;
  // Есть холодильник
  hasFridge?: boolean;
  // Есть мебель (в комнатах для живой)
  hasFurniture?: boolean;
  // Есть гараж
  hasGarage?: boolean;
  // Есть газ
  hasGas?: boolean;
  // Фен
  hasHairdryer?: boolean;
  // Есть отопление
  hasHeating?: boolean;
  // Есть интернет
  hasInternet?: boolean;
  // Есть инвестпроект
  hasInvestmentProject?: boolean;
  // Утюг
  hasIron?: boolean;
  // Джакузи
  hasJacuzzi?: boolean;
  // Есть мебель на кухне
  hasKitchenFurniture?: boolean;
  // Есть лифт
  hasLift?: boolean;
  // Есть свет
  hasLight?: boolean;
  // Есть парковка
  hasParking?: boolean;
  // Есть телефон
  hasPhone?: boolean;
  // Есть бассейн
  hasPool?: boolean;
  // Ответственное хранение
  hasSafeCustody?: boolean;
  // Документы командированным
  hasSecondedDocs?: boolean;
  // Есть охрана
  hasSecurity?: boolean;
  // Есть душевая кабина
  hasShower?: boolean;
  // Чай-кофе-сахар
  hasTeaCoffeeSugar?: boolean;
  // Трансфер
  hasTransfer?: boolean;
  // Транспортные услуги
  hasTransportServices?: boolean;
  // Есть телевизор
  hasTv?: boolean;
  // Есть стиральная машина
  hasWasher?: boolean;
  // Есть водоснабжение
  hasWater?: boolean;
  // Есть Wi-Fi
  hasWifi?: boolean;
  // Платные ТВ-каналы
  hasWiredInternet?: boolean;
  // ID объявления в Realty
  id?: number;
  // Вход
  inputType?: OfferDetailInputType;
  // Апартаменты
  isApartments?: boolean;
  // В состоянии аукциона
  isAuction?: boolean;
  // Дубль, нужно скрывать описание таких объявлений от поисковиков
  isDuplicatedDescription?: boolean | null;
  // Таможня
  isCustoms?: boolean;
  // Работаем честно
  isFairplay?: boolean;
  // В избранном
  isFavorite?: boolean;
  // От застройщика
  isFromBuilder?: boolean;
  // В фабрике лидов
  isLeadFactory?: boolean;
  // Объявление закрытой базы
  isInHiddenBase?: boolean;
  // Флаг включённости колтрекинга
  isEnabledCallTracking?: boolean;
  // Помещение занято
  isOccupied?: boolean;
  // Стандарт ВАС
  isStandard?: boolean;
  // Платное
  // Пентхаус
  isPenthouse?: boolean;
  // Премиум
  isPremium?: boolean;
  // Значок профессионала
  isPro?: boolean;
  // Топ 3
  isTop3?: boolean;
  // Ссылка на ЖК
  jkUrl?: string;
  // Ссылка на ЖК с поддоменом
  jkUrlWithSubdomain?: string;
  // Площадь кухни, м2
  kitchenArea?: string | number;
  // Коттеджный посёлок
  kp?: IOfferKp;
  // Информация об участке
  land?: ILand;
  // Планировка
  layout?: OfferDetailLayout;
  // Планировка
  layoutPhoto?: IPhoto;
  // Инфраструктура и досуг
  leisure?: string;
  // Жилая площадь, м2
  livingArea?: string | number;
  // Количество лоджий
  loggiasCount?: number;
  // Площадь до
  maxArea?: string | number;
  // Площадь от
  minArea?: string | number;
  // Цена за весь объект за минимальную возможную площадь
  minPriceTotal?: number;
  // Цена за весь объект в месяц за минимальную возможную площадь
  minPriceTotalPerMonth?: number;
  // Цена за весь объект в месяц за минимальную возможную площадь
  minPriceTotalPerMonthRur?: number;
  // Цена за весь объект за минимальную возможную площадь
  minPriceTotalRur?: number;
  // Месячная прибыль
  monthlyIncome?: IMonthlyIincome;
  // Наименование
  name?: string;
  //ЖК
  newbuilding?: IOfferNewbuilding | null;
  // Временный ID объявления/черновика (GUID)
  objectGuid?: string;
  // Тип недвижимости
  offerType?: OfferDetailOfferType;
  // Вид разрешенного использования (ВРИ)
  permittedUseType?: OfferDetailPermittedUseType;
  // Можно с животными
  petsAllowed?: boolean;
  // Кол-во телефонных линий
  phoneLinesCount?: number;
  // Телефон
  phones?: IPhone[];
  // Фотография объекта
  photos?: IPhoto[];
  // Видео об объекте
  videos?: IVideo[];
  // Тип помещения
  placementType?: OfferDetailPlacementType;
  // Возможно изменить ВРИ
  possibleToChangePermittedUseType?: boolean;
  // История изменения цены
  priceChanges?: IPriceHistoryEvent[];
  // Цена за единицу площади
  pricePerUnitArea?: number;
  // Цена за единицу площади
  pricePerUnitAreaEur?: number;
  // Цена за кв.м. в месяц
  pricePerUnitAreaPerMonth?: number;
  // Цена за кв.м. в год
  pricePerUnitAreaPerYear?: number;
  // Цена за кв.м. в год
  pricePerUnitAreaPerYearEur?: number;
  // Цена за кв.м. в год
  pricePerUnitAreaPerYearRur?: number;
  // Цена за кв.м. в год
  pricePerUnitAreaPerYearUsd?: number;
  // Цена за единицу площади
  pricePerUnitAreaRur?: number;
  // Цена за единицу площади
  pricePerUnitAreaUsd?: number;
  // Цена за весь объект
  priceTotal?: number;
  // Цена за весь объект
  priceTotalEur?: number;
  // Цена за весь объект в месяц
  priceTotalPerMonth?: number;
  // Цена за весь объект в месяц
  priceTotalPerMonthEur?: number;
  // Цена за весь объект в месяц
  priceTotalPerMonthRur?: number;
  // Цена за весь объект в месяц
  priceTotalPerMonthUsd?: number;
  // Цена за весь объект
  priceTotalRur?: number;
  // Цена за весь объект
  priceTotalUsd?: number;
  // Проектная декларация
  projectDeclarationUrl?: string;
  // Рекламные акции
  promoInfo?: IPromoInfo;
  // Тип недвижимости
  propertyType?: OfferDetailPropertyType;
  // Условия размещения объявления
  publishTerms?: IPublishTerms;
  // Id пользователя, разместившего объявление
  publishedUserId?: number;
  // Тип ремонта
  repairType?: OfferDetailRepairType;
  // Площадь комнаты (комната, койко-место)
  roomArea?: string | number;
  // Тип комнаты (комната)
  roomType?: OfferDetailRoomType;
  // Площадь комнат
  roomsArea?: string | number;
  // Количество комнат
  roomsCount?: number;
  // Количество комнат в продажу/аренду
  roomsForSaleCount?: number;
  // Состояние проверки собственника в Росреестре
  rosreestrCheck?: IRosreestrCheck | null;
  // Количество раздельных санузлов
  separateWcsCount?: number;
  // Название коттеджного поселка
  settlementName?: string;
  // Размер доли (доля в квартире, доля в доме)
  shareAmount?: string;
  // Querystring объявлений в этом доме
  similar?: ISimilarOffers;
  // Возможное назначение
  specialty?: ICommercialSpecialty;
  // Статистика
  statistic?: IStatistic;
  // Статус объявления
  status?: OfferDetailStatus;
  // Заголовок объявления
  title?: string;
  // Общая площадь, м2
  totalArea?: string | number;
  // Заметка пользователя
  user?: IUserPresenter;
  // ID пользователя в Realty
  userId?: number;
  // Водоснабжение
  water?: IWater;
  // Объем, м3/сутки
  waterCapacity?: number;
  // Тип водоснабжения
  waterType?: OfferDetailWaterType;
  // Расположение санузла
  wcLocationType?: OfferDetailWcLocationType;
  // Тип санузла (комната)
  wcType?: OfferDetailWcType;
  // Куда выходят окна
  windowsViewType?: OfferDetailWindowsViewType;
  // Объявление от собственника
  isByHomeowner?: boolean;
  // Объявление от собственника коммерческой
  isByCommercialOwner?: boolean;
  // Подтверждение что пользак прошел верификацию как соб коммерческой
  isCommercialOwnershipVerified?: boolean;
  // Наличие комиссии в пользу риэлтора за заключение договора для аредатора
  withoutClientFee?: boolean;
  // Дом под снос?
  demolishedInMoscowProgramm?: boolean;
  // Часы работы офиса застройщика
  workTimeInfo?: IWorkTimeInfo;
  // Количество рабочих мест
  workplaceCount?: number | null;
  // минимальная сумма налога в месяц
  minVatPriceTotalPerMonthRur?: number;
  // общая сумма налога в месяц
  vatPriceTotalPerMonthRur?: number;
  // минимальная сумма налога
  minVatPriceTotalRur?: number;
  // общая сумма налога
  vatPriceTotalRur?: number;
  //эксперимент продавец онлайн
  isAgentOnline?: boolean;
  //флаг скрытия объявления
  isHiddenByUser?: boolean;
  /**
   * Отвечает за показ лейбла - "Новое"
   * Актуально для выдачи по сохраненному поиску
   */
  isNew?: boolean;
  // была ли подмена номера
  isDealRequestSubstitutionPhone?: boolean;
  /** Список проверок, пройденных пользователем */
  homeownerProofs: Array<THomeownerProof> | null;
  // Айди для подмены номера с comagic
  newbuildingDynamicCalltracking?: {
    siteBlockId?: number | null;
  };
  isResale?: boolean;
  /**
   * @todo Оставить только RawAreaPart
   * @description Данный функционал появился в CD-150883, будет дополнен в CD-152964
   */
  areaParts?: IAreaPart[] | RawAreaPart[];
  /** Тип готового бизнеса */
  readyBusinessType?: EReadyBusinessType;
  /** Скрываем ли точный адрес */
  isNeedHideExactAddress?: boolean;
  /** Данные о собственнике коммерческой недвижимости **/
  commercialOwnership?: ICommercialOwnershipSchema | null;
  /** Флаг нелегальной застройки **/
  isIllegalConstruction: boolean;
  /** Ссылка на торги на электронной площадке **/
  tradingLink?: string | null;
  /** Ссылка на торги на электронной площадке для карточки **/
  collateralObjectSchema?: ICollateralObjectSchema;
}

export interface IOfferBooking {
  canBooking: boolean;
}

export interface IOfferNewbuilding {
  isFromDeveloper: boolean;
  name: string;
  isFromBuilder: boolean;
  isFromSeller: boolean;
  isFromLeadFactory: boolean;
  isReliable: boolean;
  showReliableFlag?: boolean;
  showJkReliableFlag?: boolean;
  id: string;
  house?: IOfferNewbuildingHouse;
  newbuildingFeatures?: INewBuildingFeaturesSchema;
  isSalesLeader?: boolean;
  isUpcomingSale?: boolean | null;
}

export interface INewBuildingFeaturesSchema {
  imagesCount: number | null;
  videosCount: number | null;
  reviewsCount: number | null;
  firstImageUrl?: string;
  deadlineInfo?: string;
}

export enum ENewBuildingFeatureType {
  /** Наличие 3D тура **/
  Has3dTour = 'has3dTour',
  /** Наличие Записи на просмотр **/
  HasFlatTourBooking = 'hasFlatTourBooking',
}

export interface INewBuildingFeaturesV2Schema {
  /** Тип **/
  type: ENewBuildingFeatureType;
}

export interface IOfferNewbuildingHouse {
  isReliable: boolean;
  isFinished: boolean;
  finishDate?: IOfferNewbuildingHouseFinishDate;
  id: number;
  name: string;
}

export interface IOfferNewbuildingHouseFinishDate {
  quarter?: number;
  year?: number;
}

// Тип гео-привязки
export type AddressInfoType =
  // Страна
  | 'country'
  // Район
  | 'district'
  // Дом
  | 'house'
  // Местоположение
  | 'location'
  // Шоссе
  | 'road'
  // Улица
  | 'street'
  // Метро
  | 'underground';

export interface IAddressInfo {
  // Полное название
  fullName?: string;
  // ID гео-привязки
  id?: number;
  // Участвует в образовании адреса
  isFormingAddress?: boolean;
  // ID типа субъекта
  locationTypeId?: number;
  // Название
  name?: string;
  // Короткое название
  shortName?: string;
  // Тип гео-привязки
  type?: AddressInfoType;
}

// Валюта
export type AgentBonusCurrency =
  // €
  | 'eur'
  // руб.
  | 'rur'
  // $
  | 'usd';

// Тип бонуса
export type AgentBonusPaymentType =
  // Фиксированый
  | 'fixed'
  // Процент от сделки
  | 'percent';

export interface IAgentBonus {
  // Валюта
  currency?: AgentBonusCurrency;
  // Тип бонуса
  paymentType?: AgentBonusPaymentType;
  // Размер бонуса
  value?: string | number;
}

// Тип договора
export type BargainTermsPresenterContractType =
  // Переуступка прав аренды
  | 'leaseAssignment'
  // Продажа
  | 'sale';

// Валюта
export type BargainTermsPresenterCurrency =
  // €
  | 'eur'
  // руб.
  | 'rur'
  // $
  | 'usd';

// Срок аренды
export type BargainTermsPresenterLeaseTermType =
  // На несколько месяцев
  | 'fewMonths'
  // Длительный
  | 'longTerm';

// Тип аренды
export type BargainTermsPresenterLeaseType =
  // Прямая аренда
  | 'direct'
  // Договор совместной деятельности
  | 'jointVenture'
  // Субаренда
  | 'sublease';

// Период оплаты
export type BargainTermsPresenterPaymentPeriod =
  // За год
  | 'annual'
  // За месяц
  | 'monthly';

// Тип цены
export type BargainTermsPresenterPriceType =
  // Цена за все
  | 'all'
  // Цена за гектар
  | 'hectare'
  // Цена за сотку
  | 'sotka'
  // Цена за квадратный метр
  | 'squareMeter';

// Тип продажи
export type BargainTermsPresenterSaleType =
  // Альтернатива
  | 'alternative'
  // Договор уступки права требования
  | 'dupt'
  // Договор ЖСК
  | 'dzhsk'
  // Свободная продажа
  | 'free'
  // 214-ФЗ
  | 'fz214'
  // Договор инвестирования
  | 'investment'
  // Предварительный договор купли-продажи
  | 'pdkp';

// Состав съемщиков
export type BargainTermsPresenterTenantsType =
  // Любой
  | 'any'
  // Семья
  | 'family'
  // Женщина
  | 'female'
  // Мужчина
  | 'male';

export type BargainTermsPresenterIncludedOptions =
  // Операционные расходы
  | 'operationalCosts'
  // Коммунальные платежи
  | 'utilityCharges';

export type TBargainTermsVatTypes =
  //НДС включен
  | 'included'
  //НДС не включен - начиная с 01.03.18 notincluded не приходит
  | 'notIncluded'
  //УСН (упрощенная система налогообложения)
  | 'usn';

export type TVatPrices = {
  rur: number;
  eur: number;
  usd: number;
};

export interface IBargainTermsPresenter {
  // Бонус агентам
  agentBonus?: IAgentBonus;
  // Комиссия от другого агента, %%
  agentFee?: number;
  // Возможен торг
  bargainAllowed?: boolean;
  // Условия торга
  bargainConditions?: string;
  // Цена с учетом торга
  bargainPrice?: number;
  // Цена с учетом торга
  bargainPricePrices?: IPricePresenter;
  // Комиссия от прямого клиента, %%
  clientFee?: number;
  // Тип договора
  contractType?: BargainTermsPresenterContractType;
  // Валюта
  currency?: BargainTermsPresenterCurrency;
  // Залог собственнику
  deposit?: number;
  // Залог собственнику
  depositPrices?: IPricePresenter;
  // Арендные каникулы
  hasGracePeriod?: boolean;
  // Включено в ставку
  includedOptions?: BargainTermsPresenterIncludedOptions[];
  // Срок аренды
  leaseTermType?: BargainTermsPresenterLeaseTermType;
  // Тип аренды
  leaseType?: BargainTermsPresenterLeaseType;
  // Минимальный срок аренды, мес
  minLeaseTerm?: number;
  // Ипотека
  mortgageAllowed?: boolean;
  // Период оплаты
  paymentPeriod?: BargainTermsPresenterPaymentPeriod;
  // Предоплата
  prepayMonths?: number;
  // Цена
  price?: number;
  // Цена в евро
  priceEur?: number;
  // Цена в рублях
  priceRur?: number;
  // Тип цены
  priceType?: BargainTermsPresenterPriceType;
  // Цена в долларах
  priceUsd?: number;
  // Цена за рабочее место
  priceForWorkplace?: number | null;
  // Тип продажи
  saleType?: BargainTermsPresenterSaleType;
  // Обеспечительный платеж
  securityDeposit?: number;
  // Обеспечительный платеж
  securityDepositPrices?: IPricePresenter;
  // Состав съемщиков
  tenantsType?: BargainTermsPresenterTenantsType;
  // Коммунальные услуги
  utilitiesTerms?: IUtilitiesTerms;
  // Коммунальные услуги
  utilitiesTermsPrices?: IPricePresenter;
  // НДС включен
  vatIncluded?: boolean;
  // НДС включен/выключен, УСН
  vatType?: TBargainTermsVatTypes;
  // Цена с учетом НДС
  vatPrices?: TVatPrices;
}

// Вход
export type BuildingPresenterAccessType =
  // Свободный
  | 'free'
  // Пропускная система
  | 'passSystem';

// Класс
export type BuildingPresenterClassType =
  // A
  | 'a'
  // A+
  | 'aPlus'
  // B
  | 'b'
  // B-
  | 'bMinus'
  // B+
  | 'bPlus'
  // C
  | 'c'
  // C+
  | 'cPlus'
  // D
  | 'd';

// Состояние
export type BuildingPresenterConditionRatingType =
  // Отличное
  | 'excellent'
  // Хорошее
  | 'good'
  // Удовлетворительное
  | 'satisfactory';

// Кондиционирование
export type BuildingPresenterConditioningType =
  // Центральное
  | 'central'
  // Местное
  | 'local'
  // Нет
  | 'no';

// Система пожаротушения
export type BuildingPresenterExtinguishingSystemType =
  // Сигнализация
  | 'alarm'
  // Газовая
  | 'gas'
  // Гидрантная
  | 'hydrant'
  // Нет
  | 'no'
  // Порошковая
  | 'powder'
  // Спринклерная
  | 'sprinkler';

// Тип ворот
export type BuildingPresenterGatesType =
  // На нулевой отметке
  | 'atZero'
  // Докового типа
  | 'dockType'
  // На пандусе
  | 'onRamp';

// Отопление
export type BuildingPresenterHeatingType =
  // Автономное
  | 'autonomous'
  // Котел
  | 'boiler'
  // Центральное
  | 'central'
  // Центральное угольное
  | 'centralCoal'
  // Центральное газовое
  | 'centralGas'
  // Камин
  | 'fireplace'
  // Нет
  | 'no'
  // Другое
  | 'other'
  // Печь
  | 'stove';

// Линия домов
export type BuildingPresenterHouseLineType =
  // Первая
  | 'first'
  // Иная
  | 'other'
  // Вторая
  | 'second';

// Тип дома
export type BuildingPresenterHouseMaterialType =
  // Блочный
  | 'block'
  // Щитовой
  | 'boards'
  // Кирпичный
  | 'brick'
  // Монолитный
  | 'monolith'
  // Монолитно-кирпичный
  | 'monolithBrick'
  // старый фонд
  | 'old'
  // Панельный
  | 'panel'
  // Сталинский
  | 'stalin'
  // Деревянный
  | 'wood';

// Тип дома
export type BuildingPresenterMaterialType =
  // Блочный
  | 'block'
  // Щитовой
  | 'boards'
  // Кирпичный
  | 'brick'
  // Монолитный
  | 'monolith'
  // Монолитно-кирпичный
  | 'monolithBrick'
  // старый фонд
  | 'old'
  // Панельный
  | 'panel'
  // Сталинский
  | 'stalin'
  // Деревянный
  | 'wood';

// Масштаб торгового центра
export type BuildingPresenterShoppingCenterScaleType =
  // Районный
  | 'district'
  // Микрорайонный
  | 'microdistrict'
  // Окружной
  | 'okrug'
  // Региональный
  | 'regional'
  // Суперокружной
  | 'superOkrug'
  // Суперрегиональный
  | 'superRegional';

// Категория
export type BuildingPresenterStatusType =
  // Новостройка/Строящееся
  | 'newBuilding'
  // Действующее
  | 'operational'
  // Проект
  | 'project'
  // Вторичный рынок
  | 'secondary'
  // Строящееся
  | 'underConstruction';

// Тип
export type BuildingPresenterType =
  // Административное здание
  | 'administrativeBuilding'
  // Бизнес-центр
  | 'businessCenter'
  // Деловой центр
  | 'businessCenter2'
  // Бизнес-парк
  | 'businessPark'
  // Бизнес-квартал
  | 'businessQuarter'
  // Свободное
  | 'free'
  // Объект свободного назначения
  | 'freeAppointmentObject'
  // Производственный комплекс
  | 'industrialComplex'
  // Индустриальный парк
  | 'industrialPark'
  // Промплощадка
  | 'industrialSite'
  // Производственно-складской комплекс
  | 'industrialWarehouseComplex'
  // Логистический центр
  | 'logisticsCenter'
  // Логистический комплекс
  | 'logisticsComplex'
  // Особняк
  | 'mansion'
  // Производственное здание
  | 'manufactureBuilding'
  // Производственный цех
  | 'manufacturingFacility'
  // Модульное здание
  | 'modular'
  // Многофункциональный комплекс
  | 'multifunctionalComplex'
  // Офисно-гостиничный комплекс
  | 'officeAndHotelComplex'
  // Офисно-жилой комплекс
  | 'officeAndResidentialComplex'
  // Офисно-складское
  | 'officeAndWarehouse'
  // Офисно-складской комплекс
  | 'officeAndWarehouseComplex'
  // Офисное здание
  | 'officeBuilding'
  // Офисный центр
  | 'officeCenter'
  // Офисно-производственный комплекс
  | 'officeIndustrialComplex'
  // Офисный квартал
  | 'officeQuarter'
  // Старый фонд
  | 'old'
  // Другое
  | 'other'
  // Аутлет
  | 'outlet'
  // Имущественный комплекс
  | 'propertyComplex'
  // Жилой комплекс
  | 'residentialComplex'
  // Жилой фонд
  | 'residentialFund'
  // Жилой дом
  | 'residentialHouse'
  // Торгово-деловой комплекс
  | 'shoppingAndBusinessComplex'
  // Торгово-общественный центр
  | 'shoppingAndCommunityCenter'
  // Торгово-развлекательный центр
  | 'shoppingAndEntertainmentCenter'
  // Торговый центр
  | 'shoppingCenter'
  // Специализированный торговый центр
  | 'specializedShoppingCenter'
  // Отдельно стоящее здание
  | 'standaloneBuilding'
  // Технопарк
  | 'technopark'
  // Торгово-офисный комплекс
  | 'tradingOfficeComplex'
  // Нежилой фонд
  | 'uninhabitedFund'
  // Склад
  | 'warehouse'
  // Складской комплекс
  | 'warehouseComplex';

// Вентиляция
export type BuildingPresenterVentilationType =
  // Приточная
  | 'forced'
  // Естественная
  | 'natural'
  // Нет
  | 'no';

// Рабочие дни
export type BuildingPresenterWorkingDaysType =
  // Ежедневно
  | 'everyday'
  // Будни
  | 'weekdays'
  // Выходные
  | 'weekends';

export interface IBuildingPresenter {
  // Подъезды
  entrances?: number;
  // Вход
  accessType?: BuildingPresenterAccessType;
  // Серия дома
  seriesName?: string;
  // Отопление
  houseHeatSupplyType?: EHeatingType;
  // Газоснабжение
  houseGasSupplyType?: EGasSupplyType;
  // Тип перекрытий
  houseOverlapType?: EOverlapType;
  // Год постройки
  buildYear?: number;
  // Количество грузовых лифтов
  cargoLiftsCount?: number;
  // Высота потолков, м
  ceilingHeight?: string | number;
  // Класс
  classType?: BuildingPresenterClassType;
  // Сетка колонн
  columnGrid?: string;
  // Состояние
  conditionRatingType?: BuildingPresenterConditionRatingType;
  // Кондиционирование
  conditioningType?: BuildingPresenterConditioningType;
  // Крановое оборудование
  cranageTypes?: ICranageType[];
  deadline?: IDeadline;
  // Застройщик
  developer?: string;
  // Система пожаротушения
  extinguishingSystemType?: BuildingPresenterExtinguishingSystemType;
  // Количество этажей в здании
  floorsCount?: number;
  // Тип ворот
  gatesType?: BuildingPresenterGatesType;
  // Мусоропровод
  hasGarbageChute?: boolean;
  // Отопление
  heatingType?: BuildingPresenterHeatingType;
  // Линия домов
  houseLineType?: BuildingPresenterHouseLineType;
  // Тип дома
  houseMaterialType?: BuildingPresenterHouseMaterialType;
  // Инфраструктура
  infrastructure?: ICommercialBuildingInfrastructure;
  // Аварийный?
  isEmergency?: boolean;
  // Лифт
  liftTypes?: ILiftType[];
  // Управляющая компания
  managementCompany?: string;
  // Тип дома
  materialType?: BuildingPresenterMaterialType;
  // Название
  name?: string;
  // Часы работы
  openingHours?: IOpeningHours;
  // Парковка
  parking?: IParking;
  // Количество пассажирских лифтов
  passengerLiftsCount?: number;
  // Цена за м2)
  priceSqm?: string | number;
  // Динамика цены за м2 за год (в процентах)
  priceSqmDiff?: string | number;
  // Ставка аренды (для данной комнатности)
  roomRentPrice?: string | number;
  // Динамика ставки за год (для данной комнатности) (в процентах)
  roomRentPriceDiff?: string | number;
  // Серия дома
  series?: string;
  // Масштаб торгового центра
  shoppingCenterScaleType?: BuildingPresenterShoppingCenterScaleType;
  // Категория
  statusType?: BuildingPresenterStatusType;
  // Арендаторы
  tenants?: string;
  // Общая площадь, м2
  totalArea?: string | number;
  // Тип
  type?: BuildingPresenterType;
  // Вентиляция
  ventilationType?: BuildingPresenterVentilationType;
  // Рабочие дни
  workingDaysType?: BuildingPresenterWorkingDaysType;

  // Диапазон цен
  priceCian?: number;
  priceCianMin?: number;
  priceCianMax?: number;
}

// Класс здания
export type BusinessShoppingCenterBuildingClassType =
  // A
  | 'a'
  // A+
  | 'aPlus'
  // B
  | 'b'
  // B-
  | 'bMinus'
  // B+
  | 'bPlus'
  // C
  | 'c'
  // C+
  | 'cPlus'
  // D
  | 'd';

// Тип здания
export type BusinessShoppingCenterBuildingType =
  // Административное здание
  | 'administrativeBuilding'
  // Бизнес-центр
  | 'businessCenter'
  // Деловой центр
  | 'businessCenter2'
  // Бизнес-парк
  | 'businessPark'
  // Бизнес-квартал
  | 'businessQuarter'
  // Свободное здание
  | 'free'
  // Объект свободного назначения
  | 'freeAppointmentObject'
  // Производственный комплекс
  | 'industrialComplex'
  // Индустриальный парк
  | 'industrialPark'
  // Промплощадка
  | 'industrialSite'
  // Производственно-складской комплекс
  | 'industrialWarehouseComplex'
  // Логистический центр
  | 'logisticsCenter'
  // Логистический комплекс
  | 'logisticsComplex'
  // Особняк
  | 'mansion'
  // Производственное здание
  | 'manufactureBuilding'
  // Производственный цех
  | 'manufacturingFacility'
  // Модульное здание
  | 'modular'
  // Многофункциональный комплекс
  | 'multifunctionalComplex'
  // Офисно-гостиничный комплекс
  | 'officeAndHotelComplex'
  // Офисно-жилой комплекс
  | 'officeAndResidentialComplex'
  // Офисно-складское
  | 'officeAndWarehouse'
  // Офисно-складской комплекс
  | 'officeAndWarehouseComplex'
  // Офисное здание
  | 'officeBuilding'
  // Офисный центр
  | 'officeCenter'
  // Офисно-производственный комплекс
  | 'officeIndustrialComplex'
  // Офисный квартал
  | 'officeQuarter'
  // Старый фонд
  | 'old'
  // Аутлет
  | 'outlet'
  // Имущественный комплекс
  | 'propertyComplex'
  // Жилой комплекс
  | 'residentialComplex'
  // Жилой дом
  | 'residentialHouse'
  // Торгово-деловой комплекс
  | 'shoppingAndBusinessComplex'
  // Торгово-общественный центр
  | 'shoppingAndCommunityCenter'
  // Торгово-развлекательный центр
  | 'shoppingAndEntertainmentCenter'
  // Торговый центр
  | 'shoppingCenter'
  // Специализированный торговый центр
  | 'specializedShoppingCenter'
  // Отдельно стоящее здание
  | 'standaloneBuilding'
  // Технопарк
  | 'technopark'
  // Торгово-офисный комплекс
  | 'tradingOfficeComplex'
  // Склад
  | 'warehouse'
  // Складской комплекс
  | 'warehouseComplex';

// Тип объекта
export type TBusinessShoppingCenterType =
  // Бизнес-центр
  | 'businessCenter'
  // Торговый центр
  | 'shoppingCenter';

export const BusinessShoppingCenterTypeDict: {
  [K in TBusinessShoppingCenterType]: string;
} = {
  businessCenter: 'БЦ',
  shoppingCenter: 'ТЦ',
};

export interface IBusinessShoppingCenter {
  // Класс здания
  buildingClassType?: BusinessShoppingCenterBuildingClassType;
  // Тип здания
  buildingType?: BusinessShoppingCenterBuildingType;
  // От официального представителя
  fromRepresentative?: boolean;
  // Полный URL на карточку ТЦ/БЦ
  fullUrl?: string;
  // ID объекта
  id?: number;
  // Название объекта
  name?: string;
  // ID основного объекта (для корпусов)
  parentId?: number;
  // Название основного объекта
  parentName?: string;
  // URL на карточку ТЦ/БЦ основного объекта
  parentUrl?: string;
  // Тип объекта
  type?: TBusinessShoppingCenterType;
  // URL на карточку ТЦ/БЦ
  url?: string;
  // Использовать название основного объекта
  useParentName?: boolean;
}

export interface IAnalyticsProducts {
  auctionPosition?: number;
  brand?: string;
  category?: string;
  cianId?: number;
  customURL?: string;
  dealType?: QueryDealType;
  headline?: boolean;
  hiddenBase?: boolean;
  id?: number;
  name?: string;
  nv?: boolean | null;
  objectType?: ObjectType;
  owner?: boolean;
  ownerCianId?: string;
  ownerId?: number;
  parentId?: string | number;
  parentName?: string;
  photosCount?: number;
  podSnos?: boolean;
  position?: number;
  price?: string | number;
  published?: boolean;
  quantity?: number;
  repres?: boolean | null;
  variant?: string | Array<string>;
}

export interface ICommercialBuildingInfrastructure {
  // Аквапарк
  hasAquapark?: boolean;
  // Банкомат
  hasAtm?: boolean;
  // Автоматические ворота
  hasAutomaticGates?: boolean;
  // Беби-ситинг
  hasBabySitting?: boolean;
  // Отделение банка
  hasBankDepartment?: boolean;
  // Подвал/погреб
  hasBasement?: boolean;
  // Салон красоты
  hasBeautyShop?: boolean;
  // Боулинг
  hasBowling?: boolean;
  // Буфет
  hasBuffet?: boolean;
  // Кафе
  hasCafe?: boolean;
  // Столовая
  hasCanteen?: boolean;
  // Автосервис
  hasCarService?: boolean;
  // Автомойка
  hasCarWash?: boolean;
  // Центральная рецепция
  hasCentralReception?: boolean;
  // Кинотеатр
  hasCinema?: boolean;
  // Ателье одежды
  hasClothesStudio?: boolean;
  // Конференц-зал
  hasConferenceRoom?: boolean;
  // Въезд по пропускам
  hasEntryByPass?: boolean;
  // Выставочно-складской комплекс
  hasExhibitionAndWarehouseComplex?: boolean;
  // Фитнес-центр
  hasFitnessCentre?: boolean;
  // Фуд-корт
  hasFoodCourt?: boolean;
  // Игровая комната
  hasGameRoom?: boolean;
  // Гостиница
  hasHotel?: boolean;
  // Круглосуточная охрана
  hasHourSecurity?: boolean;
  // Смотровая яма
  hasInspectionPit?: boolean;
  // Медицинский центр
  hasMedicalCenter?: boolean;
  // Минимаркет
  hasMinimarket?: boolean;
  // Нотариальная контора
  hasNotaryOffice?: boolean;
  // Офисные помещения
  hasOfficeSpace?: boolean;
  // Парк
  hasPark?: boolean;
  // Аптека
  hasPharmacy?: boolean;
  // Бассейн
  hasPool?: boolean;
  // Ресторан
  hasRestaurant?: boolean;
  // Каток
  hasRink?: boolean;
  // Торговая зона
  hasShoppingArea?: boolean;
  // Игровые автоматы
  hasSlotMachines?: boolean;
  // Фотосалон
  hasStudio?: boolean;
  // Супермаркет
  hasSupermarket?: boolean;
  // Шиномонтаж
  hasTire?: boolean;
  // Видеонаблюдение
  hasVideoSurveillance?: boolean;
  // Складские помещения
  hasWarehouse?: boolean;
}

export interface ICommercialSpecialty {
  // Дополнительные виды
  additionalTypes?: string[];
  // Cпециализация
  specialties?: ISpecialty[];
  // Коды специализаций помещений
  types?: string[];
}

export interface ICoordinates {
  // Широта
  lat?: string | number;
  // Долгота
  lng?: string | number;
}

// Тип кранового оборудования
export type CranageTypeType =
  // Кран-балка
  | 'beam'
  // Козловой кран
  | 'gantry'
  // Мостовой кран
  | 'overhead'
  // Ж/д кран
  | 'railway';

export interface ICranageType {
  // Количество
  count?: number;
  // Грузоподъёмность
  loadCapacity?: number;
  // Тип кранового оборудования
  type?: CranageTypeType;
}

// Квартал
export type DeadlineQuarter =
  // Первый
  | 'first'
  // Четвертый
  | 'fourth'
  // Второй
  | 'second'
  // Третий
  | 'third';

export interface IDeadline {
  // Дом сдан
  isComplete?: boolean;
  // Квартал
  quarter?: DeadlineQuarter;
  // Срок сдачи
  year?: number;
}

export interface IDeveloper {
  // ID застройщика
  id?: number;
  // Название
  name?: string;
}

export interface IDistrictBTIData {
  // Средний возраст домов
  avgHouseAge?: string | number;
  // Название района
  districtTitle?: string;
  // Население
  population?: string | number;
  // Цена за м2
  priceSqm?: string | number;
  // Динамика цены за м2 за год (в процентах)
  priceSqmDiff?: string | number;
  // Название региона
  regionTitle?: string;
  // Ставка аренды (для данной комнатности)
  roomRentPrice?: string | number;
  // Динамика ставки за год (для данной комнатности) (в процентах)
  roomRentPriceDiff?: string | number;
  // Цена квартиры (для данной комнатности)
  roomSalePrice?: string | number;
  // Динамика цены квартиры за год (для данной комнатности) (в процентах)
  roomSalePriceDiff?: string | number;
}

export interface IDistrictInfo {
  // ID района
  id?: number;
  // ID гео-привязки
  locationId?: number;
  // Название
  name?: string;
  // ID родительского района
  parentId?: number;
}

// Локация канализации
export type DrainageLocationType =
  // По границе участка
  | 'border'
  // На участке
  | 'location'
  // Нет
  | 'no';

// Тип канализации
export type DrainageType =
  // Автономная
  | 'autonomous'
  // Центральная
  | 'central'
  // Септик
  | 'septicTank'
  // Ливневая
  | 'storm'
  // Очистные сооружения
  | 'treatmentFacilities';

export interface IDrainage {
  // Объём, м3/сутки
  capacity?: number;
  // Локация канализации
  locationType?: DrainageLocationType;
  // Возможно подключить
  possibleToConnect?: boolean;
  // Тип канализации
  type?: DrainageType;
}

// Локация электроснабжения
export type ElectricityLocationType =
  // По границе участка
  | 'border'
  // На участке
  | 'location'
  // Нет
  | 'no';

export interface IElectricity {
  // Локация электроснабжения
  locationType?: ElectricityLocationType;
  // Возможно подключить
  possibleToConnect?: boolean;
  // Мощность, кВТ
  power?: number;
}

export interface IFlags {
  /** Флаг Архива объявления */
  isArchived?: boolean;
  /** Флаг Сдаётся с ЦИАН-помощником */
  isDealRent?: boolean;
  /** Флаг прошел ли пользователь верификацию как соб коммерческой */
  isCommercialOwnershipVerified?: boolean;
}

// Тип гаража
export type GarageGarageType =
  // Встроенный
  | 'builtIn'
  // Капитальный
  | 'capital'
  // Самострой
  | 'samostroy'
  // Ракушка
  | 'shell';

// Тип бокса
export type GarageMaterial =
  // Кирпичный
  | 'brick'
  // Металлический
  | 'metal';

// Статус
export type GarageStatus =
  // По доверенности
  | 'byProxy'
  // Кооператив
  | 'cooperative'
  // Собственность
  | 'ownership';

// Тип
export type GarageType =
  // Бокс
  | 'box'
  // Гараж
  | 'garage'
  // Машиноместо
  | 'parkingPlace';

export interface IGarage {
  // Тип гаража
  garageType?: GarageGarageType;
  // Тип бокса
  material?: GarageMaterial;
  // Статус
  status?: GarageStatus;
  // Тип
  type?: GarageType;
}

// Локация газоснабжения
export type GasLocationType =
  // По границе участка
  | 'border'
  // На участке
  | 'location'
  // Нет
  | 'no';

// Давление
export type GasPressureType =
  // Высокое
  | 'high'
  // Низкое
  | 'low'
  // Среднее
  | 'middle';

export interface IGas {
  // Емкость, м3/час
  capacity?: number;
  // Локация газоснабжения
  locationType?: GasLocationType;
  // Возможно подключить
  possibleToConnect?: boolean;
  // Давление
  pressureType?: GasPressureType;
}

export interface IGeo {
  // Адресный элемент
  address?: IAddressInfo[];
  // Строительный адрес
  buildingAddress?: string;
  // Координаты точки
  coordinates?: ICoordinates;
  // ID страны
  countryId?: number;
  // Район
  districts?: IDistrictInfo[];
  // Шоссе
  highways?: IHighwayInfo[];
  // ЖК
  jk?: IJK;
  // Метро
  undergrounds?: IUndergroundInfo[];
  // Адресная строка, введенная пользователем
  userInput?: string;
}

export interface IHighwayInfo {
  // Расстояние до МКАД, км
  distance?: string | number;
  // ID шоссе
  id?: number;
  // Основное шоссе
  isDefault?: boolean;
  // Название
  name?: string;
}

export interface IJKHouse {
  // ID
  id?: number;
  // Название
  name?: string;
}

export interface IJK {
  developer?: IDeveloper;
  // Название для отображения
  displayName?: string;
  // Полный URL
  fullUrl?: string;
  // Данные гео для GA
  gaGeo?: IJkGaGeo;
  // Корпус
  house?: IJKHouse;
  // ID ЖК
  id?: number;
  // Название ЖК
  name?: string;
  // Ссылка на сайт ЖК
  webSiteUrl?: string;
}

export interface IJkGaGeo {
  // ID города
  cityId?: number;
  // Тип Московской области
  moId?: number;
  // ID области
  oblId?: number;
}

// Единица измерения
export type LandAreaUnitType =
  // Гектар
  | 'hectare'
  // Сотка
  | 'sotka';

// Статус
export type LandStatus =
  // Фермерское хозяйство
  | 'farm'
  // Участок сельскохозяйственного назначения
  | 'forAgriculturalPurposes'
  // Участок лесного фонда
  | 'forestArea'
  // Садоводство
  | 'gardening'
  // Садовое некоммерческое товарищество
  | 'gardeningNonProfitPartnership'
  // Индивидуальное жилищное строительство
  | 'individualHousingConstruction'
  // Земля промышленного назначения
  | 'industrialLand'
  // Участок промышленности, транспорта, связи и иного не сельхоз. назначения
  | 'industryTransportCommunications'
  // Инвестпроект
  | 'investmentProject'
  // Особо охраняемых категорий
  | 'ofProtectedCategories'
  // Участок запаса
  | 'reserve'
  // Участок поселений
  | 'settlements'
  // Дачное некоммерческое партнерство
  | 'suburbanNonProfitPartnership'
  // Дачное некоммерческое партнерство поселений
  | 'suburbanNonProfitSettlementsPartnership'
  // Участок водного фонда
  | 'waterArea';

// Тип участка
export type LandType =
  // В собственности
  | 'owned'
  // В аренде
  | 'rent';

export interface ILand {
  // Площадь участка
  area?: string | number;
  // Единица измерения
  areaUnitType?: LandAreaUnitType;
  // Возможно изменить cтатус
  possibleToChangeStatus?: boolean;
  // Статус
  status?: LandStatus;
  // Тип участка
  type?: LandType;
}

// Тип лифта
export type LiftTypeType =
  // Грузовой
  | 'cargo'
  // Эскалатор
  | 'escalator'
  // Лифт
  | 'lift'
  // Пассажирский
  | 'passenger'
  // Тельфер
  | 'telpher'
  // Траволатор
  | 'travelator';

export interface ILiftType {
  // Дополнительный тип
  additionalType?: string;
  // Количество
  count?: number;
  // Грузоподъёмность
  loadCapacity?: string | number;
  // Тип лифта
  type?: LiftTypeType;
}

// Валюта
export type MonthlyIincomeCurrency =
  // €
  | 'eur'
  // руб.
  | 'rur'
  // $
  | 'usd';

export interface IMonthlyIincome {
  // Валюта
  currency?: MonthlyIincomeCurrency;
  // Прибыль
  income?: number;
}

// От/до или круглосуточно
export type OpeningHoursType =
  // Круглосуточно
  | 'roundTheClock'
  // От/до
  | 'specific';

export interface IOpeningHours {
  // От
  from?: string;
  // До
  to?: string;
  // От/до или круглосуточно
  type?: OpeningHoursType;
}

// Валюта
export type ParkingCurrency =
  // €
  | 'eur'
  // руб.
  | 'rur'
  // $
  | 'usd';

// Расположение парковки
export type ParkingLocationType =
  // За территорией объекта
  | 'external'
  // На территории объекта
  | 'internal';

// Назначение парковки
export type ParkingPurposeType =
  // Для грузового транспорта
  | 'cargo'
  // Для легковесного транспорта
  | 'passenger';

// Тип парковки
export type ParkingType =
  // Наземная
  | 'ground'
  // Многоуровневая
  | 'multilevel'
  // Открытая
  | 'open'
  // На крыше
  | 'roof'
  // Подземная
  | 'underground';

export interface IParking {
  // Валюта
  currency?: ParkingCurrency;
  // Доступна
  isAvailable?: boolean;
  // Бесплатная
  isFree?: boolean;
  // Расположение парковки
  locationType?: ParkingLocationType;
  // Количество мест
  placesCount?: number;
  // Стоимость въезда
  priceEntry?: number;
  // Стоимость в месяц за место
  priceMonthly?: number;
  // Назначение парковки
  purposeType?: ParkingPurposeType;
  // Тип парковки
  type?: ParkingType;
}

export interface IPhoneNumber {
  // Код страны
  countryCode?: string;
  // Номер
  number?: string;
}

export interface IPhone {
  // Код страны
  countryCode?: string;
  // Номер
  number?: string;
}

export interface IPhoto {
  // URL исходного изображения
  fullUrl?: string;
  // ID фотографии
  id?: number;
  // Является ли фото по-умолчанию
  isDefault?: boolean;
  // Является ли фото планировкой
  isLayout?: boolean;
  // URL превью
  miniUrl?: string;
  // Угол поворота
  rotateDegree?: number;
  // URL превью
  thumbnail2Url?: string;
  // URL превью
  thumbnailUrl?: string;
}

export interface IVideo {
  // Продолжительность видео в секундах
  duration: number | null;
  // Тип видео = ['youtube', 'vk']
  type: string | null;
  // Время добавления (публикации) видео на видеохостинге
  uploadDate: string | null;
  // URL видео
  url: string | null;
  // URL превью видео
  previewUrl: string | null;
}

export interface IPricePresenter {
  // Цена в евро
  eur?: number;
  // Цена в рублях
  rur?: number;
  // Цена в долларах
  usd?: number;
}

export interface IPromoInfo {
  // Есть ли сертификат
  certificate?: boolean;
  // Ссылка на скачивание сертификата
  certificateUrl?: string;
  // Дата окончания акции
  end?: string;
  // Название акции
  name?: string;
  // Цена до скидки
  priceBeforeDiscount?: string | number;
}

// Тип тарификации
export type PublishTermType =
  // Ограниченная по дням публикация, подневная тарификация
  | 'dailyLimited'
  // Бессрочная публикация, подневная тарификация
  | 'dailyTermless'
  // Тарификация за весь период
  | 'periodical';

export type PublishTermServices =
  // Бесплатная публикация
  | 'free'
  // Выделение цветом
  | 'highlight'
  // Стандартная публикация
  | 'standard'
  // Премиум публикация
  | 'premium'
  // TOP 3
  | 'top3';

export interface IPublishTerm {
  // Количество дней
  days?: number;
  // Список услуг
  services?: PublishTermServices[];
  // Идентификатор тарифа
  tariffIdentificator?: ITariffIdentificator;
  // Тип тарификации
  type?: PublishTermType;
}

export interface IPublishTerms {
  // Автопродление включено
  autoprolong?: boolean;
  terms?: IPublishTerm[];
}

export interface ISimilarOffers {
  // Количество объявлений в доме
  count?: number;
  // Ссылка
  url?: string;
}

export interface ISpecialty {
  // Английское название
  engName?: string;
  // ID
  id?: number;
  // Русское название
  rusName?: string;
}

export interface IStatistic {
  // Просмотров за день
  daily?: number;
  // Просмотров всего
  total?: number;
  // Уникальные росмотры отформатированной строкой
  totalUniqueViewsFormattedString?: string;
  // Просмотры отформатированной строкой
  totalViewsFormattedString?: string;
}

export interface ITariffIdentificator {
  // Количество дней публикации
  days?: number;
  // Тип периода публикации
  periodType?: string;
  // Список услуг в тарифе
  serviceTypes?: string[];
  // Тип тарифной сетки
  tariffGridType?: string;
  // Id тарифной сетки
  tariffId?: number;
}

// Способ передвижения до метро
export type UndergroundInfoTransportType =
  // На транспорте
  | 'transport'
  // Пешком
  | 'walk';

export interface IUndergroundInfo {
  // ID линии метро на ЦИАНе
  cianId?: number;
  // ID метро
  id?: number;
  // Основная станция метро
  isDefault?: boolean;
  // Цвет линии метро, hex
  lineColor?: string;
  // ID линии метро
  lineId?: number;
  // Название
  name?: string;
  // Время в пути в минутах до метро, мин
  time?: number;
  // Способ передвижения до метро
  transportType?: UndergroundInfoTransportType;
  //признак что станция строится
  underConstruction?: boolean;
  //год открытия
  releaseYear?: number;
}

// Статус профиля
export type UserPresenterCianProfileStatus =
  // Утвержден-отмодерирован публичный профиль
  | 'approved'
  // Скрыт публичный профиль
  | 'hide'
  // Запрос на публикацию публичного профиля
  | 'request';

// Уровень доверия
export type UserPresenterUserTrustLevel =
  // Очень много жалоб
  | 'danger'
  // Исключен из программы
  | 'excluded'
  // участвует
  | 'involved'
  // Новый пользователь
  | 'new'
  // не участвует
  | 'notInvolved';

export interface IUserPresenter {
  // Имя агента
  agentName?: string;
  // Название агенства
  agencyName?: string;
  // Имя агента
  agencyNameV2?: string;
  // Название агенства
  companyName?: string;
  // Тип акканута агента
  agentAccountType?: TAgentAccountType;
  // Ссылка на аватар агента
  agentAvatarUrl?: string;
  // Информация о рейтинге агента
  agentReviews?: IAgentReviewsSchema | null;
  // Статус профиля
  cianProfileStatus?: UserPresenterCianProfileStatus;
  // ID в ЦИАНе
  cianUserId?: string;
  // Опыт работы
  experience?: string;
  // Агент
  isAgent?: boolean;
  // Профиль скрыт
  isHidden?: boolean;
  phoneNumbers?: IPhoneNumber[];
  // Уровень доверия
  userTrustLevel?: UserPresenterUserTrustLevel;
  // Доступность звонка
  agentAvailability?: IAgentAvailability;
  // доступность чатов
  isChatsEnabled?: boolean;
  // Является ли сабагентом
  isSubAgent?: boolean;
  // ID пользователя в Realty
  userId?: number;
  // Тип пользователя
  userType?: string;
  // Объявление скрыто
  isHiddenByUser?: boolean;
  // Парнтер ЦИАН
  isCianPartner?: boolean;
  agentModerationInfo?: IAgentModerationInfoSchema | null;
  // Информация об агентстве
  masterAgent?: IUserMasterAgentSchema | null;
  /** Признаки объявлений (ЭТП, Залоговая, Аукцион) **/
  agentLists?: string[] | null;
  /** Форматированный тип пользователя для электронных торгов **/
  formattedPublisherType?: string;
}

export interface IUserLevelInfoSchema {
  /** Идентифицирован ли пользователь по паспорту **/
  hasVerifiedDocuments?: boolean | null;
  /** Имя уровня РЧ **/
  levelName?: string | null;
  /** Порядок отображения уровня РЧ **/
  levelOrder?: number | null;
  /** Системное имя уровня РЧ **/
  levelSystemName?: string | null;
}

export interface IAgentModerationInfoSchema {
  userLevelInfo?: IUserLevelInfoSchema | null;
  isUserIdentifiedByDocuments?: boolean | null;
}

export interface IAgentReviewsSchema {
  entityRate: number | null;
  totalCount: number;
}

export interface IAgentAvailability {
  /** Доступен ли агент в данный промежуток времени в таймзоне агента */
  available: boolean | null;
  /** тут пользователь - автор объявления */
  userId: string | null;
  /** Заголовок с текстом для не рабочего времени в таймзоне +3 */
  title: string | null;
  /** Сообщение с текстом для не рабочего времени в таймзоне +3 */
  message: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  availableFrom: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  availableTo: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  vacationTo: string | null;
}

export interface IUtilitiesTerms {
  // Включены в стоимость объекта
  includedInPrice?: boolean;
  // Стоимость услуги
  price?: number;
}

// Локация водоснабжения
export type WaterLocationType =
  // По границе участка
  | 'border'
  // На участке
  | 'location'
  // Нет
  | 'no';

// Тип водоснабжения
export type WaterType =
  // Автономное
  | 'autonomous'
  // Центральное
  | 'central'
  // Водонапорная станция
  | 'pumpingStation'
  // Водозаборный узел
  | 'waterIntakeFacility'
  // Водонапорная башня
  | 'waterTower';

export interface IWater {
  // Объём, м3/сутки
  capacity?: number;
  // Локация водоснабжения
  locationType?: WaterLocationType;
  // Возможно подключить
  possibleToConnect?: boolean;
  // Тип водоснабжения
  type?: WaterType;
}

export interface IUserMasterAgentSchema {
  /** Абсолютный URL до мини аватара */
  absoluteAvatarUrl: string;
  /** Id пользователя на ЦИАНе для мастерагента  */
  cianUserId: number;
  /** Достоинства агентства */
  metrics: Array<IUserMasterAgentMetricSchema>;
  /** Id пользователя на Realty для мастерагента */
  realtyUserId: number;
}

export interface IUserMasterAgentMetricSchema {
  /** Заголовок метрики */
  title: string;
  /** Значение метрики */
  value: string;
}
export interface ICollateralObjectSchema {
  /** Ссылка на торги на электронной площадке для карточки **/
  lotUrl?: string;
}
