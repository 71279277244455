import * as React from 'react';
import { IJsonQuery } from '../../../api/models/json_query';
import { RegionsState } from '../../../redux/regions/model';
import { pipe } from '../../../utils/functional';
import { filterSortOptions, isSuburban } from '../../../utils/sorting';

const styles = require('./sortSelect.css');

export const areaOrderLabel = {
  house_area: 'по площади дома',
  total_area: 'по общей площади',
};

export const sortOptionsValues = {
  mcad:             'mcad',
  default:          'default',
  areaOrder:        'area_order',
  siteOrder:        'site_order',
  streetName:       'street_name',
  walkingTime:      'walking_time',
  priceObjectOrder: 'price_object_order',
  priceSquareOrder: 'price_square_order',
};

export const sortOptionsLabels  = {
  mcad:             'по расстоянию до МКАД',
  default:          'по умолчанию',
  areaOrder:        'по площади дома',
  siteOrder:        'по площади участка',
  streetName:       'по улице',
  walkingTime:      'по времени до метро',
  priceObjectOrder: 'по цене',
  priceSquareOrder: 'по цене за квадратный метр',
};

export const valuesToLabels: {[key: string]: string} = {
  mcad:               'по расстоянию до МКАД',
  default:            'по умолчанию',
  area_order:         'по площади дома',
  site_order:         'по площади участка',
  street_name:        'по улице',
  walking_time:       'по времени до метро',
  price_object_order: 'по цене',
  price_square_order: 'по цене за квадратный метр',
};

export const options: TOption[] = [
  {value: sortOptionsValues.mcad,             label: sortOptionsLabels.mcad},
  {value: sortOptionsValues.default,          label: sortOptionsLabels.default},
  {value: sortOptionsValues.areaOrder,        label: sortOptionsLabels.areaOrder},
  {value: sortOptionsValues.siteOrder,        label: sortOptionsLabels.siteOrder},
  {value: sortOptionsValues.streetName,       label: sortOptionsLabels.streetName},
  {value: sortOptionsValues.walkingTime,      label: sortOptionsLabels.walkingTime},
  {value: sortOptionsValues.priceObjectOrder, label: sortOptionsLabels.priceObjectOrder},
  {value: sortOptionsValues.priceSquareOrder, label: sortOptionsLabels.priceSquareOrder},
];

export interface ISortingSelectStateProps {
  jsonQuery: IJsonQuery;
  regions: RegionsState;
  userAgent: string;
  isCoworking?: boolean;
}

export interface ISortingSelectDispatchProps {
  onSelect(sortingType: string): void;
}

export interface ISortingSelectOwnProps {
  isCommercial?: boolean;
  isPhone: boolean;
}

export type TOption = {value: string, label: string};

type ISortingSelectProps = ISortingSelectStateProps & ISortingSelectDispatchProps & ISortingSelectOwnProps;

interface ISortingSelectState {
  selectedValue: string;
}

export class SortingSelect extends React.PureComponent<ISortingSelectProps, ISortingSelectState> {
  public state: Readonly<ISortingSelectState> = { selectedValue: sortOptionsValues.default };

  public componentWillReceiveProps({jsonQuery: {sort}}: ISortingSelectProps) {
    if (sort) { this.setState({selectedValue: sort.value || sortOptionsValues.default}); }
  }

  public componentDidMount() {
   const {jsonQuery: {sort}} = this.props;

   if (sort) { this.setState({selectedValue: sort.value}); }
  }

  public render() {
    const {selectedValue} = this.state;

    return(
      <div className={styles['sort']}>
        {/** зачем any в ивент хегдлере: чья-то вдохновенная душа когда-то написала свои тайпинги в typings/globals
           * которые формируются при yarn install. В этих тайпингах SyntheticEvent не дженерик, тс ругается на
           * то что в currentTarget нету value. А ОН ЕСТЬ. Экстендиться и дописывать тип для value тоже не получается -
           * все фоллбэкает в FormEvent | undefined у которого те же проблемы
           */}
        <select
          value={selectedValue}
          className={styles['sort-select']}
          // tslint:disable-next-line
          onChange={({currentTarget}: any) => this.handleChange(currentTarget.value)}
        >
          {this.getOptions().map(({value, label}) => <option key={value} value={value}>{label}</option>)}
        </select>
        <div className={styles['fake-select']}>
          <div className={styles['title']}>
            {this.getLabel()}
          </div>
          <div className={styles['arrow']}>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7">
              {/* tslint:disable-next-line*/}
              <path fill="#2b87db" fillRule="evenodd" d="M8.372.28L5 3.65 1.628.28A.954.954 0 0 0 .28 1.627l4.046 4.046a.953.953 0 0 0 1.35 0L9.72 1.628A.955.955 0 0 0 8.37.278z"/>
            </svg>
          </div>
        </div>
      </div>
    );
  }

  private handleChange = (selectedValue: string) => {
    this.setState({selectedValue});
    this.props.onSelect(selectedValue);
  }

  private getOptions = (): TOption[] => {
    return pipe(this.filterOptions, this.modifyAreaOrderLabel)();
  }

  private filterOptions = (): TOption[] => {
    const {regions, jsonQuery, isCoworking } = this.props;
    return filterSortOptions(jsonQuery, regions, isCoworking)(options);
  }

  private modifyAreaOrderLabel = (sortOptions: TOption[]): TOption[] => {
    const {jsonQuery: {_type: type}} = this.props;

    return sortOptions.map(({value, label}) => {
      if (value === sortOptionsValues.areaOrder) {
        return isSuburban(type) ? {value, label: areaOrderLabel.house_area} : {value, label: areaOrderLabel.total_area};
      } else {
        return {value, label};
      }
    });
  }

  private getLabel() {
    const {selectedValue} = this.state;
    const selectedOption = this.getOptions().find(({value}) => value === selectedValue);

    return selectedOption ? selectedOption.label : valuesToLabels[selectedValue];
  }
}
