import { get } from 'lodash';
import { IGKData, IIconField } from '../../../api/models/gk_card';
import { IInfrastructure } from '../../../api/models/gk_preview';

const mapper = (item: IInfrastructure): IIconField => {
  const idToIconMap: { [key: number]: string } = {
    5201: 'card/ic-perimetr',    // Огороженный периметр
    5202: 'card/ic-secure-camera',    // Видеонаблюдение
    5203: 'card/ic-propusk',    // Пропускная система
    5204: 'card/ic-fire',    // Противопожарная система
    5205: 'card/ic-24-secure', // Круглосуточная охрана
    5206: 'card/ic-concierge',    // Консьерж
    5207: 'card/ic-parking-secure',    // Охраняемая парковка
    5208: 'card/ic-signal',    // Сигнализация
  };

  return {
    name: item.name,
    icon: idToIconMap[item.id],
  };
};

export const parseSecurity = (gk: IGKData) =>
  (get(gk, 'security', []) as IInfrastructure[]).map(mapper);
