import { MapObject } from '../../redux/map_page/model';

export enum EDataType {
  featureCollection = 'FeatureCollection',
}

export enum EFeatureType {
  feature = 'Feature',
  point = 'Point',
}

export interface IFeatureCollection {
  type: EDataType.featureCollection;
  features: IFeature[];
}

export interface IFeature {
  type: EFeatureType;
  id: string;
  geometry: IGeometry;
}

export interface IGeometry {
  type: EFeatureType;
  coordinates: [number, number];
  properties?: {[key: string]: string};
}

export const EMPTY_FEATURE_COLLECTION = {
  type: EDataType.featureCollection,
  features: [] as IFeature[],
};

export function convertMapObjectsToFeaturesCollection(mapObjects: MapObject[]): IFeatureCollection {
  return {
    type: EDataType.featureCollection,
    features: mapObjects.map(convertMapObjectToFeature),
  };
}

/**
 * Конвертирует объект типа MapObject в объект, который можно скормить
 * яндексовому objectManager.
 */
export function convertMapObjectToFeature({id, coords}: MapObject) {
  return { type: EFeatureType.feature, id, geometry: { type: EFeatureType.point, coordinates: coords } };
}
