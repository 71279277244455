import * as React from 'react';

import { IStyleConfig } from '@cian/components';
import Icon from '../icon/icon';

const styles = require('./preloader.css');

interface IPreloaderProps {
  size?: number;
  containerStyle?: IStyleConfig;
}

const Preloader = (props: IPreloaderProps) => {
  const { size, containerStyle } = props;

  const style = [
    containerStyle,
    styles.preloader,
    size && { height: size, width: size },
  ];

  return <Icon styleConfig={style} name="preloader" />;
};

export default Preloader;
