import * as React from 'react';

import { isEqual } from 'lodash';

import {
  commercialObjectType,
  garageSubtype,
  IFieldProps,
  isCommercialObjectGroup,
  IsVisibleCheck,
} from '../field';

import { Label } from '@cian/components';
import { SelectMobile } from '../../common/select/select';
import { createSelectOptions } from '../../common/select_helper';

import { garageTypeDict } from '../../../redux/filters/dictionaries';

const style = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  private options = [{
    label: '',
    options: [{
      id: garageSubtype.Embedded,
      label: 'Встроенный',
    }, {
      id: garageSubtype.Capital,
      label: 'Капитальный',
    }, {
      id: garageSubtype.Shell,
      label: 'Ракушка',
    }, {
      id: garageSubtype.SelfBuilt,
      label: 'Самострой',
    }],
  }];

  public render() {
    const processedOptions = createSelectOptions(
      this.options,
      this.props.filter.selectedGarageSubtypes,
      this.props.filter,
    );

    return (
      <div className={style.field} data-mark="FieldSelectGarageSubtype">
        <Label label="Тип гаража">
          <SelectMobile
            multiple
            id="select-object-garage-type"
            name="selectMobile"
            label="Неважно"
            onValueChange={this.props.actions.setGarageSubtype}
            value={processedOptions.value}
            options={processedOptions.options[0].options}
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => {
  return isCommercialObjectGroup(filter)
    && isEqual(filter.selectedObjectTypes, [commercialObjectType.Garage])
    && filter.selectedGarageTypes.includes(garageTypeDict.Garage);
};
