import { IConfig } from '@cian/config/shared';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';

import {
  IGetUsersAuctionResponse,
  IMappers,
} from '../../../repositories/commercial-electronic-auction/v1/get-users-auction';

export interface IGetUsersAuctionDependencies {
  config: IConfig;
  httpApi: IHttpApi;
  logger: ILogger;
}

export enum EGetUsersAuctionStatus {
  CriticalError,
  Error,
  Success,
}

export type IGetUsersAuctionResult = {
  userIds: IGetUsersAuctionResponse['userIds'];
} & (
  | {
      status: EGetUsersAuctionStatus.Success;
    }
  | {
      status: EGetUsersAuctionStatus.Error | EGetUsersAuctionStatus.CriticalError;
      message: string;
    }
);

export type GetUsersAuctionMappers = IMappers<IGetUsersAuctionResult, IGetUsersAuctionResult>;
