import { ResourceStatus } from '../common/model';
import { TRegionsActions } from './actions';
import { defaultRegions, RegionsState } from './model';

function regionsReducer(state: RegionsState  = defaultRegions, action: TRegionsActions): RegionsState {
  switch (action.type) {
    case 'RequestRegions':
      return {
        ...state,
        status: ResourceStatus.Pending,
      };

    case 'UpdateRegions':
      return {
        ...state,
        data: action.payload.data,
        status: ResourceStatus.Success,
      };

    case 'RejectRegions':
      return {
        ...state,
        status: ResourceStatus.Error,
      };

    case 'RequestRegionMeta':
      return {
        ...state,
        regionMeta: {
          ...state.regionMeta,
          status: ResourceStatus.Pending,
        },
      };

    case 'RejectRegionMeta':
      return {
        ...state,
        regionMeta: {
          ...state.regionMeta,
          status: ResourceStatus.Error,
        },
        status: ResourceStatus.Error,
      };

    case 'SetRegionsInfo':
      return {
        ...state,
        ...action.payload,
      };

    case 'SetRegionMeta':
      return {
        ...state,
        regionMeta: {
          ...state.regionMeta,
          ...action.payload,
          status: ResourceStatus.Success,
        },
      };

    case 'SetRegionCities':
      return {
        ...state,
        regionCities: {
          ...action.payload,
        },
      };

    case 'RequestRegionCities':
      return {
        ...state,
        regionCities: {
          ...state.regionCities,
          status: ResourceStatus.Pending,
          data: [],
        },
      };

    case 'RequestRegionCitiesFail':
      return {
        ...state,
        regionCities: {
          ...state.regionCities,
          status: ResourceStatus.Error,
        },
      };

    case 'SetCurrentRegionInfo':
      return {
        ...state,
        currentRegionInfo: action.payload.data,
      };

    default:
      return state;
  }
}

export default regionsReducer;
