export function numberTrunc(value: number): number {
  return value < 0 ? Math.ceil(value) : Math.floor(value);
}

export function numberIsEgal(x: number, y: number): boolean {
  if (x === y) {
    return x !== 0 || 1 / x === 1 / y;
  }

  return x !== x && y !== y;
}
