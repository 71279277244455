export type AuthStatus = 'not_logged' | 'need_email' | 'need_merge_email' | 'need_reset_password' | 'error' | 'logged';

interface INotLoggedState {
  status: 'not_logged';
}

interface ILoggedState {
  status: 'logged';
}

export type AuthState
  = INotLoggedState
  | ILoggedState;

export type UsersState = AuthState & {
  // successLogonUrl - урл для редиректа после успешной авторизации и регистрации.
  // (страница до посещения урлов /authenticate и /registration)
  successLogonUrl: string;
};

export const defaultUsersState: UsersState = {
  status: 'not_logged',
  successLogonUrl: '/',
};
