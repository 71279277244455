export function scrollToTop(scrollDuration: number): void {
  if (!window.requestAnimationFrame) {
    // scroll without animation as fallback
    window.scrollTo(0, 0);
    return;
  }

  let cosParameter = window.scrollY / 2;
  let scrollCount = 0;
  let oldTimestamp: number;

  function step(newTimestamp: number = 200) {
    if (oldTimestamp) {
      scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
      if (scrollCount >= Math.PI) {
        window.scrollTo(0, 0);
      }
      if (window.scrollY === 0) {
        return;
      }

      window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
}

export function getScrollTop() {
  return window.pageYOffset
    ? window.pageYOffset
    : document.documentElement.clientHeight
      ? document.documentElement.scrollTop
      : document.body.scrollTop;
}

export function scrollToTopOf(node: any, offset: number = 0) { // tslint:disable-line:no-any
  if (!node) { return; }

  const { top } = node.getBoundingClientRect();
  window.scrollTo(0, getScrollTop() + top + offset);
}

export function scrollContainerToElement(
  container: Element,
  element: HTMLElement,
  offset: number = 0,
) {
  container.scrollTop = element.offsetTop + offset;
}
