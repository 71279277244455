import * as React from 'react';

import { Label } from '@cian/components';
import RangeComponent from '../../common/range/range';
import { IFieldProps, IsVisibleCheck } from '../field';

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    return (
      <div data-mark="FieldDistanceToMkad">
        <Label label="Расстояние от МКАД">
          <RangeComponent
            field="distanceToMKAD"
            metric="км"
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => true;
