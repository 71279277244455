import { TObjectType } from '../../mobile_website/redux/filters/model';

type Args = [isReadyBusiness: boolean, isRentalBusiness: boolean];

export interface IIsBusinessCategoryModel {
  isRentalBusiness: boolean;
  isReadyBusiness: boolean;
  isAll: boolean;
}

class IsBusinessCategoryModel implements IIsBusinessCategoryModel {
  public readonly isAll: boolean;
  public readonly isReadyBusiness: boolean;
  public readonly isRentalBusiness: boolean;

  public constructor(...args: Args) {
    const [isReadyBusiness, isRentalBusiness] = args;

    this.isReadyBusiness = isReadyBusiness;
    this.isRentalBusiness = isRentalBusiness;
    this.isAll = args.every(Boolean);
  }
}

export function getIsBusinessCategory(selectedObjectTypes: TObjectType[]): IIsBusinessCategoryModel {
  let isRentalBusiness = false;
  let isReadyBusiness = false;

  if (
    selectedObjectTypes.length &&
    selectedObjectTypes.every(selectedObjectType => ['rentalBusiness', 'readyBusiness'].includes(selectedObjectType))
  ) {
    for (const readyBusinessType of selectedObjectTypes) {
      if (readyBusinessType === 'rentalBusiness') {
        isRentalBusiness = true;
      } else if (readyBusinessType === 'readyBusiness') {
        isReadyBusiness = true;
      }
    }
  }

  return new IsBusinessCategoryModel(isReadyBusiness, isRentalBusiness);
}
