// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  ICianApiSiteV1CreateSubscriptionRequest,
  TCianApiSiteV1CreateSubscriptionModel,
  IMappers,
  TCianApiSiteV1CreateSubscriptionResponse,
  ICianApiSiteV1CreateSubscriptionResponse,
  ICianApiSiteV1CreateSubscriptionResponseError,
} from './types';

export const defaultConfig: TCianApiSiteV1CreateSubscriptionModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/create-subscription/',
  requestType: 'json',
} as TCianApiSiteV1CreateSubscriptionModel;

export function createCianApiSiteV1CreateSubscriptionModel(
  parameters: ICianApiSiteV1CreateSubscriptionRequest,
): TCianApiSiteV1CreateSubscriptionModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICianApiSiteV1CreateSubscriptionOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: ICianApiSiteV1CreateSubscriptionRequest;
}

export async function fetchCianApiSiteV1CreateSubscription<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ICianApiSiteV1CreateSubscriptionOptions<TResponse200, TResponse400>): Promise<
  TCianApiSiteV1CreateSubscriptionResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createCianApiSiteV1CreateSubscriptionModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ICianApiSiteV1CreateSubscriptionResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as ICianApiSiteV1CreateSubscriptionResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TCianApiSiteV1CreateSubscriptionResponse;
}
