import * as React from 'react';

import { CheckboxField, mergeStyles } from '@cian/components';

import { DealType, sellerType } from '../../../redux/filters/model';
import { IFieldProps, isCommonFlatSale, IsVisibleCheck } from '../field';
import { clearSpecialPromo, setSpecialPromo } from '../../../redux/special_promo/actions';
import { TTypedThunkDispatch } from '../../../redux/model';
import { connect } from 'react-redux';

const style = require('../field.css');

interface IWithCustomDispatchProps {
  toggleSpecialPromo(flag: boolean, builderId?: number | null): void;
}

type WithCustomProps = IWithCustomDispatchProps & IFieldProps;

export class WithCustom extends React.Component<WithCustomProps, {}> {
  public shouldComponentUpdate(nextProps: IFieldProps) {
    const { filter: { withCustom }, specialPromoTitle } = this.props;

    return withCustom !== nextProps.filter.withCustom || specialPromoTitle !== nextProps.specialPromoTitle;
  }

  public render() {
    const { filter: { withCustom }, specialPromoTitle } = this.props;

    const labelStyles = mergeStyles(
      style.labelCheckbox,
      withCustom && style.checked,
      style['checkbox--with_custom'],
    ).className;

    return (
      <div className={style.checkbox} data-mark="FieldWithCustom">
        <CheckboxField
          mobile
          label={`Новостройки от ${specialPromoTitle}`}
          labelStyle={labelStyles}
          value={withCustom}
          onValueChange={this.onValueChange}
        />
      </div>
    );
  }

  private onValueChange = (flag: boolean) => {
    const { builderId } = this.props;

    this.props.toggleSpecialPromo(flag, builderId);
    this.props.actions.setWithCustom(flag, builderId);
  }
}

export const mapDispatchToProps = (dispatch: TTypedThunkDispatch) => ({
  toggleSpecialPromo: (flag: boolean, builderId?: number | null) => {
    dispatch(flag && builderId
      ? setSpecialPromo(builderId)
      // tslint:disable-next-line:no-any
      : (clearSpecialPromo() as any),
    );
  },
});

export const Component = connect<{}, IWithCustomDispatchProps, IFieldProps>(
  undefined,
  mapDispatchToProps,
)(WithCustom);

export const isVisible: IsVisibleCheck = (filter, visibilityParams) => {
  return !!(
    visibilityParams && visibilityParams.isCustomSpecialPromo
    && filter.dealType === DealType.Sale
    && Array.isArray(filter.selectedObjectTypes)
    && filter.selectedObjectTypes.includes('kvartira') && filter.selectedObjectTypes.length === 1
    && (filter.selectedSellers.every(seller => seller !== sellerType.Agent)
      || visibilityParams.isNewbuildingsMapMode && isCommonFlatSale(filter))
  );
};
