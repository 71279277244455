import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/retry';
import { Observable } from 'rxjs/Observable';
import { Subscriber } from 'rxjs/Subscriber';

import { ICacheStore } from './cache';

// tslint:disable-next-line:no-any
type Value = any;

function openDBConnect(databaseName: string, storeName: string, version: number): Observable<IDBDatabase> {
  return Observable.create((observer: Subscriber<IDBDatabase>) => {
    const request = window.indexedDB.open(databaseName, version);

    request.onerror = event => {
      window.indexedDB.deleteDatabase(databaseName);
      observer.error(event);
    };

    request.onsuccess = event => {
      const db: IDBDatabase = (event.target as IDBOpenDBRequest).result;
      observer.next(db);
      observer.complete();
    };

    request.onupgradeneeded = event => {
      const db: IDBDatabase = (event.target as IDBOpenDBRequest).result;
      if (db.objectStoreNames.contains(storeName)) {
        db.deleteObjectStore(storeName);
      }
      db.createObjectStore(storeName);
    };
  });
}

function requestToObservable<T>(request: IDBRequest): Observable<T> {
  return Observable.create((observer: Subscriber<T>) => {
    request.onsuccess = event => {
      observer.next((event.target as IDBRequest).result);
      observer.complete();
    };

    request.onerror = event => {
      observer.error(event);
    };
  });
}

export class IndexedDBStore implements ICacheStore {
  private storeName = 'store';
  private databaseName: string;
  private databaseVersion: number;

  public constructor(name: string, version: number) {
    if (!window.indexedDB) {
      throw new Error('Browser does not support indexedDB');
    }
    this.databaseName = name;
    this.databaseVersion = version;
  }

  public put(key: string, value: Value): Observable<void> {
    const stringifiedValue = JSON.stringify(value);
    return this.transaction('readwrite')
      .map(store => store.put(stringifiedValue, key))
      .map(requestToObservable)
      .mapTo(undefined);
  }

  public get<V>(key: string): Observable<V | undefined> {
    return this.transaction('readonly')
      .map(store => store.get(key))
      .mergeMap(req => requestToObservable<string | undefined>(req))
      .map(value => value && JSON.parse(value));
  }

  private transaction(mode: 'readonly' | 'readwrite') {
    return openDBConnect(this.databaseName, this.storeName, this.databaseVersion)
      .retry(2)
      .map(db => db.transaction([this.storeName], mode).objectStore(this.storeName));
  }
}
