import { get } from 'lodash';
import { goBack, replace } from 'react-router-redux';
import { Observable } from 'rxjs/Observable';

import { Response } from '../../../network_framework/request';
import { CredentialsError, IServerResponse } from '../../api/models/users';
import { getPathname, getSearch } from '../../utils/location';
import { toPromise } from '../../utils/streams';
import { getReturnUrl, parseQueryString, parseUrlHostname } from '../../utils/url';
import { clearHeaderData, getHeaderData } from '../common/actions';
import { fetchGaDataLayer } from '../data_layer';
import { clearFavorites } from '../favorites/actions';
import { setError } from '../filters/actions';
import { TTypedThunkAction, TTypedThunkDispatch } from '../model';

type NotLoggedLocationChange = {
  pathname: string;
  query: { state: undefined };
  state: { socialLoginId: string; email: string };
};

/* istanbul ignore next */
export const locationPayload = {
  isNotLoggedLocationChange(payload: LocationChangePayload): payload is NotLoggedLocationChange {
    return payload.query.state == null;
  },
};

type LocationChangePayload = NotLoggedLocationChange;

export type TUsersActions =
  | { type: 'AuthSuccess' }
  | { type: '@@router/LOCATION_CHANGE'; payload: LocationChangePayload }
  | { type: 'SetSuccessLogonUrl'; payload: { successLogonUrl: string } };

/* istanbul ignore next */
function toRequestRes(condition: boolean, p: Observable<Response<{}>>) {
  if (condition) {
    return Promise.resolve(false);
  }

  return toPromise(p)
    .then(resp => Promise.resolve(resp))
    .catch(err => {
      // почему-то superagent-jsonp выкидывает 404, хотя запрос проходит успешно
      // пока что отлавливаем 404 и считаем, что это был успешный запрос
      if (err.cause && err.cause.message === '404 NotFound') {
        return Promise.resolve(true);
      }

      return Promise.resolve(false);
    });
}

/* istanbul ignore next */
function afterRequests(result: {}[], fn: () => void): Promise<void> | null {
  if (result.length > 0 && result.every(Boolean)) {
    return Promise.resolve().then(fn);
  }

  return null;
}

export interface IAuthenticationSucceedParameters {
  noRedirect?: boolean;
}

// tslint:disable-next-line:no-any
export function authenticationSucceed(parameters?: IAuthenticationSucceedParameters): TTypedThunkAction<Promise<any>> {
  const noRedirect = (parameters && parameters.noRedirect) || false;

  return async (dispatch, getState) => {
    const {
      auth: { successLogonUrl },
      common: { lastUrl },
      chats: { unreadContext },
    } = getState();

    await dispatch(getHeaderData());
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch({ type: 'AuthSuccess' });
    await dispatch(fetchGaDataLayer());

    if (unreadContext) {
      unreadContext.updateUnread();
      unreadContext.reconnect();
    }

    if (!noRedirect) {
      if (successLogonUrl === lastUrl) {
        return dispatch(goBack());
      }

      const query = parseQueryString(window.location.search.substring(1));
      const returnUrl = getReturnUrl(query);

      if (returnUrl) {
        const hostname = parseUrlHostname(returnUrl);
        const hostnameSplit = hostname.split('.');

        if (
          hostnameSplit.length > 1 &&
          hostnameSplit[hostnameSplit.length - 2] === 'cian' &&
          hostnameSplit[hostnameSplit.length - 1] === 'ru'
        ) {
          window.location.href = returnUrl;

          return;
        }
      }

      const redirectUrl = successLogonUrl ? `${getPathname(successLogonUrl)}${getSearch(successLogonUrl)}` : '/';

      return dispatch(replace(redirectUrl));
    }

    return;
  };
}

/* istanbul ignore next */
function showLogoutErrors(err: Error | string, dispatch: TTypedThunkDispatch) {
  if (typeof err === 'string') {
    dispatch(setError(err));

    return;
  }

  const errors = get(err, 'meta.response.body.errors') as CredentialsError[] | undefined;
  if (errors && errors[0] && errors[0].message) {
    dispatch(setError(errors[0].message));
  }
}

/* istanbul ignore next */
export function validatePasswordComplexity(password: string): TTypedThunkAction<Promise<IServerResponse>> {
  return (dispatch, getState, { api }) => {
    return toPromise<IServerResponse>(api.validatePasswordComplexity(password)).then(({ result }) => result);
  };
}

/* istanbul ignore next */
export function logOut(): TTypedThunkAction<Promise<void | null>> {
  return (dispatch, getState, { api, logger }) => {
    return toPromise(api.getUserLogoutUrls())
      .then(res => {
        const logOutLinks = res.result;

        const logoutResult = async () => {
          // Важно сохранить порядок запросов и сделать запрос по каждому
          // url из logOutLinks
          const result = [];
          for (let link of logOutLinks) {
            result.push(await toRequestRes(link == null, api.logout(link)));
          }

          return afterRequests(result, () => {
            dispatch(clearHeaderData());
            dispatch(clearFavorites());
            setTimeout(() => location.reload(), 500);
          });
        };

        return logoutResult();
      })
      .catch(err => {
        logger.error(err);

        showLogoutErrors(err, dispatch);
      });
  };
}

export function setSuccessLogonUrl(successLogonUrl: string): TUsersActions {
  return {
    type: 'SetSuccessLogonUrl',
    payload: { successLogonUrl },
  };
}
