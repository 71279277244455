export const OPEN_TYPE = 'authentication_popup/open';
export const CLOSE_TYPE = 'authentication_popup/close';

export interface IOpenAction {
  type: 'authentication_popup/open';
  message?: string;
}

export function openAuthenticationPopup(message?: string): IOpenAction {
  return {
    type: OPEN_TYPE,
    message,
  };
}

export interface ICloseAction {
  type: 'authentication_popup/close';
}

export function closeAuthenticationPopup(): ICloseAction {
  return {
    type: CLOSE_TYPE,
  };
}

export type TAuthenticationPopupActions = IOpenAction | ICloseAction;
