import { invert } from 'lodash';

interface IStringsToNums { [key: string]: number; }
interface INumsToStrings { [key: number]: string; }

export function mapByDict<T>(src: string[], dict: { [key: string]: T }): T[];
export function mapByDict<T>(src: number[], dict: { [key: number]: T }): T[];
//tslint:disable-next-line: no-any
export function mapByDict<T>(src: (string | number)[] = [], dict: any): T[] {
  return src
    .filter(i => dict.hasOwnProperty(i))
    .map(i => dict[i]);
}

export const garageSubtype = {
  Embedded: 'vstroennyj',
  Capital: 'kapitalnyj',
  Shell: 'rakushka',
  SelfBuilt: 'samostroj',
};

export enum boxTypeDict {
  Metal = 1,
  Brick,
}

export const garageSubtypeDict: IStringsToNums = {
  [garageSubtype.Embedded]: 1,
  [garageSubtype.Capital]: 2,
  [garageSubtype.Shell]: 3,
  [garageSubtype.SelfBuilt]: 4,
};

export const garageSubtypeDictById: INumsToStrings = invert(garageSubtypeDict) as INumsToStrings;

interface IRentTimeDict {
  Short: '1';
  Long: '!1';
  LongGtYear: '0';
  LongLtYear: '2';
}

export const rentTimeDict: IRentTimeDict = {
  Short: '1',
  Long: '!1',
  LongGtYear: '0',
  LongLtYear: '2',
};

export enum garageTypeDict {
  CarPlace = 1,
  Garage,
  Box,
}
