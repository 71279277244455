import * as React from 'react';

import { CheckboxField, mergeStyles } from '@cian/components';
import { IFieldProps, IsVisibleCheck, showWithMortgage } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const { filter: { withMortgage } } = this.props;

    const labelStyles = mergeStyles(
      styles.labelCheckbox,
      withMortgage && styles.checked,
    ).className;

    return (
      <div className={styles.checkbox} data-mark="FieldWithMortgage">
        <CheckboxField
          mobile
          label="Возможна ипотека"
          labelStyle={labelStyles}
          value={withMortgage}
          onValueChange={this.props.actions.setWithMortgage}
        />
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = showWithMortgage;
