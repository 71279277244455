import { Button, ButtonTheme } from '@cian/components';
import * as React from 'react';

const styles = require('./tags.css');

interface IMoreButtonProps {
  count?: number;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const MoreButton: React.SFC<IMoreButtonProps> = props => {
  const text = props.count
    ? `Ещё ${props.count}`
    : 'Ещё';

  return (
    <Button
      theme={ButtonTheme.BLUE_SOLID}
      buttonStyle={styles.moreTagsButton}
      onClick={props.onClick}
    >
      <span>{text}</span>
    </Button>
  );
};

MoreButton.displayName = 'MoreButton';

interface IHideButtonProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const HideButton: React.SFC<IHideButtonProps> = props => {
  return (
    <Button
      theme={ButtonTheme.BLUE_SOLID}
      buttonStyle={styles.moreTagsButton}
      onClick={props.onClick}
    >
      Свернуть
    </Button>
  );
};

HideButton.displayName = 'HideButton';

interface IClearButtonProps {
  count?: number;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const ClearButton: React.SFC<IClearButtonProps> = props => {
  return (
    <Button
      theme={ButtonTheme.WHITE}
      buttonStyle={styles.clearTagsButton}
      onClick={props.onClick}
    >
      Очистить
    </Button>
  );
};

ClearButton.displayName = 'ClearButton';
