import { PropertyType, PropertyTypeCommercial, PropertyTypeFlat, PropertyTypeSuburban } from '../../api/models/map';

const commercial: { [key in PropertyTypeCommercial]: string } = {
  Office: '#5666ce',
  FloorSpace: '#d288ff',
  Warehouse: '#ff9900',
  FreeAppointment: '#57c8da',
  PublicCatering: '#e1d200',
  Garage: '#88e34f',
  Production: '#e8ab7f',
  CarCare: '#00b800',
  Business: '#015c01',
  Building: '#787952',
  HouseholdServices: '#dd157d',
  LegalAddress: '#2b7e8a', // todo д.б. коммерческая земля!
};

const flat: { [key in PropertyTypeFlat]: string } = {
  Bed: '#5666ce',
  Room: '#d288ff',
  Studio: '#ff9900',
  OneRoom: '#57c8da',
  TwoRoom: '#e1d200',
  ThreeRoom: '#88e34f',
  FourRoom: '#e8ab7f',
  FiveRoom: '#787a52',
  Large: '#015c01',
  OpenPlan: '#00b800',
  Share: '#dd157d',
};

const suburban: { [key in PropertyTypeSuburban]: string } = {
  House: '#5666ce',
  Part: '#d288ff',
  TownHouse: '#ff9900',
  Area: '#57c8da',
};

const colorDict = { ...commercial, ...flat, ...suburban };

export function getColorForPropertyType(type: PropertyType): string {
  const FALLBACK_COLOR = '#5666ce';
  return colorDict[type] || FALLBACK_COLOR;
}
