import { ILink } from '@cian/components';
import { flattenDeep, get, sortBy } from 'lodash';

import { IGeoSeoItem } from '../../../api/api';
import { toPromise } from '../../../utils/streams';
import { TTypedThunkAction } from '../../model';

export type TGetGeoSeoLinksActions
  = { type: 'GetGeoSeoLinksPending' }
  | { type: 'GetGeoSeoLinksSuccess', payload: { geoSeoLinks: ILink[] } }
  | { type: 'GetGeoSeoLinksError', payload: { err: Error } };

export function getGeoSeoLinks(): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    dispatch<TGetGeoSeoLinksActions>({ type: 'GetGeoSeoLinksPending' });

    return toPromise(api.geoSeoLinks())
      .then(res => {
        const geoSeoLinks = flattenDeep(get(res, 'result.data.geoSeoLinks[0].items', []));
        let geoLinksWithIds: ILink[] = geoSeoLinks
          .filter((geoItem: IGeoSeoItem) => {
            return geoItem.title && geoItem.url;
          })
          .map((geoItem: IGeoSeoItem): ILink => {
            const id = geoItem.url;
            return Object.assign({}, geoItem, { id }) as ILink;
          });

        // msc and spb go first
        geoLinksWithIds.sort((a: IGeoSeoItem, b: IGeoSeoItem) => {
          if (a.title === 'Москва' || a.title === 'Санкт-Петербург') {
            return -1;
          }

          return 0;
        });

        // other cities alphabetized go after
        geoLinksWithIds = geoLinksWithIds.slice(0, 2)
          .concat(
            sortBy(
              geoLinksWithIds.slice(2), ['title'],
            ),
          );

        dispatch<TGetGeoSeoLinksActions>({
          type: 'GetGeoSeoLinksSuccess',
          payload: {
            geoSeoLinks: geoLinksWithIds,
          },
        });
      })
      .catch(err => {
        dispatch<TGetGeoSeoLinksActions>({ type: 'GetGeoSeoLinksError', payload: { err } });
      });
  };
}
