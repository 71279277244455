import * as React from 'react';

import { commercialObjectType, DealType, FilterState } from '../../../redux/filters/model';
import { shallowEqual } from '../../../utils/react_helpers';
import RangeComponent from '../../common/range/range';
import {
  IFieldProps,
  isFlatObjectGroup,
  isNotUpcomingSale,
  isReadyBusinessSaleFilters,
  isSuburbanObjectGroupHouse,
  IsVisibleCheck,
  reduceVisibilityCheck,
  showWithNewobject,
} from '../field';

import { Label } from '@cian/components';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {

  public shouldComponentUpdate(nextProps: IFieldProps) {
    const filter: FilterState = this.props.filter;
    const nextFilter: FilterState = nextProps.filter;

    return !shallowEqual(filter.sizeTotal, nextFilter.sizeTotal)
      || filter.dealType !== nextFilter.dealType
      || filter.selectedObjectTypes !== nextFilter.selectedObjectTypes;
  }

  public render() {
    return (
      <div className={styles.label} data-mark="FieldSquare">
        <Label label="Площадь дома">
          <RangeComponent
            field="sizeTotal"
            fromPlaceholder="от"
            metric="м²"
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = reduceVisibilityCheck(
  isNotUpcomingSale,
  filter => {
    if (isReadyBusinessSaleFilters(filter)) {
      return true;
    }

    const isFlatObjectType = isFlatObjectGroup(filter);
    const isHousePartObjectType = isSuburbanObjectGroupHouse(filter)
    return (filter.dealType === DealType.Sale && filter.withNewobject && showWithNewobject(filter)) ||
      (!isFlatObjectType && isHousePartObjectType &&
        !filter.selectedObjectTypes.some((type) =>
          type === commercialObjectType.CommercialLand));
  }
);
