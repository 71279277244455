import { pluralize } from '@cian/newbuilding-utils';

const titles: [string, string, string] = [
  'незакреплённое место',
  'незакреплённых места',
  'незакреплённых мест',
];

export function getFlexibleWorkplaceCategoryTitle(workplaceCount: number): string {
  return pluralize(workplaceCount, titles);
}
