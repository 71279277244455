import * as React from 'react';

import { Label } from '@cian/components';
import { HouseMaterial } from '../../../redux/filters/model';
import { SelectMobile } from '../../common/select/select';
import { createSelectOptions } from '../../common/select_helper';
import {
  IFieldProps,
  isFlatObjectGroup,
  IsVisibleCheck,
} from '../field';

const style = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  private options = [{
    label: '',
    options: [{
      id: HouseMaterial.Brick.toString(),
      label: 'Кирпичный',
    }, {
      id: HouseMaterial.Monolithic.toString(),
      label: 'Монолитный',
    }, {
      id: HouseMaterial.Panel.toString(),
      label: 'Панельный',
    }, {
      id: HouseMaterial.Block.toString(),
      label: 'Блочный',
    }, {
      id: HouseMaterial.Wood.toString(),
      label: 'Деревянный',
    }, {
      id: HouseMaterial.Stalin.toString(),
      label: 'Сталинский',
    }, {
      id: HouseMaterial.MonolithicBrick.toString(),
      label: 'Кирпично-монолитный',
    }],
  }];

  public render() {
    const processedOptions = createSelectOptions(
      this.options,
      this.props.filter.selectedHouseTypes.map(String),
      this.props.filter,
    );

    return (
      <div className={style.field} data-mark="FieldSelectHouseType">
        <Label label="Тип дома">
          <SelectMobile
            multiple
            id="select-house-type"
            name="selectHouseType"
            label="Неважно"
            onValueChange={this.props.actions.setSelectedHouseTypes}
            value={processedOptions.value}
            options={processedOptions.options[0].options}
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => filter.selectedObjectTypes.length > 0
  && isFlatObjectGroup(filter)
  && !filter.withNewobject;
