import { IStyleConfig, mergeStyles } from '@cian/components';
import * as React from 'react';
import { ifNull } from '../../utils/helpers';

const styles = require('./section.css');

export interface ISectionProps {
  children?: React.ReactNode;
  containerStyle?: IStyleConfig;
  top?: number | 'auto';
  left?: number | 'auto';
  right?: number | 'auto';
  bottom?: number | 'auto';
  x?: number | 'auto';
  y?: number | 'auto';
  centerContent?: boolean;
  height?: number;
  dataMark?: string;
}

export const Box: React.SFC<ISectionProps> = props => {
  const { x, y } = props;

  const containerMergedStyles = mergeStyles(
    props.containerStyle,
    props.centerContent && styles.centerContent,
    {
      marginTop: ifNull(props.top, y),
      marginLeft: ifNull(props.left, x),
      marginRight: ifNull(props.right, x),
      marginBottom: ifNull(props.bottom, y),
      height: props.height,
    },
  );

  return (
    <div {...containerMergedStyles} data-mark={props.dataMark}>
      {props.children}
    </div>
  );
};

export const Section: React.SFC<ISectionProps> = props => {
  return (
    <Box {...props} containerStyle={[styles.section].concat(props.containerStyle)}>
      {props.children}
    </Box>
  );
};

export default Section;
