export const SHOW_TYPE = 'auto_offer_change_hint/show';

export interface IShowAction {
  type: 'auto_offer_change_hint/show';
  payload: {
    isVisible: boolean;
  };
}

export function showAutoOfferChangeHint(isVisible: boolean): IShowAction {
  return {
    type: SHOW_TYPE,
    payload: {isVisible},
  };
}

export type TAutoOfferChangeHintActions = IShowAction;
