import { Action } from 'redux';

import { TTypedThunkAction } from '../model';
import { toPromise } from '../../utils/streams';
import { IRegionInfo, IGetRegionsByPointItemSchema } from '../../api/api';
import { sort } from '../../components/user_region/LocationSwitcher/utils/sort';
import { getSubdomain } from '../../utils/location';
import { setRegion, getRegionSettings, updateNewobjectCheckboxesByPromoState } from '../filters/actions';

export interface ICitiesAndRegions {
  cities: IRegionInfo[];
  regions: IRegionInfo[];
}

export interface IMergedCitiesAndRegions {
  citiesAndRegions: IRegionInfo[];
}

export type TLocationSwitcherOpen = 'location_switcher/open';
export type TLocationSwitcherClose = 'location_switcher/close';
export type TLocationSwitcherSetCitiesAndRegions = 'location_switcher/set_cities_and_regions';
export type TLocationSwitcherSetGeoByPoint = 'location_switcher/set_geo_by_point';
export type TLocationSwitcherSetSelectedRegionName = 'location_switcher/set_selected_region_name';
export type TLocationSwitcherSetLoad = 'location_switcher/set_load';

export const LOCATION_SWITCHER_OPEN: TLocationSwitcherOpen = 'location_switcher/open';
export const LOCATION_SWITCHER_CLOSE: TLocationSwitcherClose = 'location_switcher/close';
export const LOCATION_SWITCHER_SET_CITIES_AND_REGIONS: TLocationSwitcherSetCitiesAndRegions =
  'location_switcher/set_cities_and_regions';
export const LOCATION_SWITCHER_SET_GEO_BY_POINT: TLocationSwitcherSetGeoByPoint = 'location_switcher/set_geo_by_point';
export const LOCATION_SWITCHER_SET_SELECTED_REGION_NAME: TLocationSwitcherSetSelectedRegionName =
  'location_switcher/set_selected_region_name';
export const LOCATION_SWITCHER_SET_LOAD: TLocationSwitcherSetLoad = 'location_switcher/set_load';

export interface ILocationSwitcherOpen extends Action<TLocationSwitcherOpen> {
  type: TLocationSwitcherOpen;
}

export interface ILocationSwitcherClose extends Action<TLocationSwitcherClose> {
  type: TLocationSwitcherClose;
}

export interface ILocationSwitcherSetCitiesAndRegions extends Action<TLocationSwitcherSetCitiesAndRegions> {
  type: TLocationSwitcherSetCitiesAndRegions;
  payload: IMergedCitiesAndRegions;
}

export interface ILocationSwitcherSetGeoByPoint extends Action<TLocationSwitcherSetGeoByPoint> {
  type: TLocationSwitcherSetGeoByPoint;
  payload: IGetRegionsByPointItemSchema;
}

export interface ILocationSwitcherSetSelectedRegionName extends Action<TLocationSwitcherSetSelectedRegionName> {
  type: TLocationSwitcherSetSelectedRegionName;
  payload: string;
}

export interface ILocationSwitcherSetLoad extends Action<TLocationSwitcherSetLoad> {
  type: TLocationSwitcherSetLoad;
  payload: boolean;
}

export type TLocationSwitcherActions =
  ILocationSwitcherOpen |
  ILocationSwitcherClose |
  ILocationSwitcherSetCitiesAndRegions |
  ILocationSwitcherSetGeoByPoint |
  ILocationSwitcherSetSelectedRegionName |
  ILocationSwitcherSetLoad;

export function setLoadCitiesAndRegions(isLoad: boolean) {
  return {
    type: LOCATION_SWITCHER_SET_LOAD,
    payload: isLoad,
  };
}

/**
 * Открытие попапа выбора гео
 */
export function locationSwitcherOpen() {
  return {
    type: LOCATION_SWITCHER_OPEN,
  };
}

/**
 * Закрытие попапа выбора гео
 */
export function locationSwitcherClose() {
  return {
    type: LOCATION_SWITCHER_CLOSE,
  };
}

export function setCitiesAndRegions(citiesAndRegions: IMergedCitiesAndRegions) {
  return {
    type: LOCATION_SWITCHER_SET_CITIES_AND_REGIONS,
    payload: citiesAndRegions,
  };
}

export function setGeoByPoint(geoByPoint: IGetRegionsByPointItemSchema) {
  return {
    type: LOCATION_SWITCHER_SET_GEO_BY_POINT,
    payload: geoByPoint,
  };
}

export function setSelectedRegionName(name: string) {
  return {
    type: LOCATION_SWITCHER_SET_SELECTED_REGION_NAME,
    payload: name,
  };
}

export function getRegionsByPoint(lat: number, lng: number): TTypedThunkAction {
  return (dispatch, getState, { api }) => {
    return toPromise(api.getRegionsByPoint(lat, lng))
      .then(res => {
        const { result, response } = res;

        if (response && result && response.status === 200) {
          dispatch(setGeoByPoint(result.regionLocations[0]));
        }
      });
  };
}

export function buildRedirectUrl(host: string): string {
  let nextUrl = host;

  if (window.location.port) {
    nextUrl += ':' + window.location.port;
  }

  return `${nextUrl}${window.location.pathname}${window.location.search}`;
}

export function redirectToRightHost(location: IRegionInfo): TTypedThunkAction {
  return (dispatch, getState, { api }) => {
    const { id, displayName } = location;

    dispatch(setSelectedRegionName(displayName as string));

    return toPromise(api.getLocationByRegion(Math.abs(id)))
      .then(res => {
        const { result } = res;

        if (result && result.status === 'ok') {
          const currentSubdomain = window.location.hostname.split('.')[0];
          const nextSubdomain = result.data.baseHost
            .split('//')[1]
            .split('.')[0];

          if (currentSubdomain === nextSubdomain) {
            const subdomain = getSubdomain(window.location.href);

            dispatch(setRegion({ region: id, resetTags: true })); 
            dispatch(getRegionSettings(subdomain, id));
            dispatch(updateNewobjectCheckboxesByPromoState(location));

            return;
          }

          window.location.href = buildRedirectUrl(result.data.baseHost);
        }
      });
  };
}

export function getAllRegionsAndCities(): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    dispatch(setLoadCitiesAndRegions(true));

    return toPromise(api.searchRegions(''))
      .then(res => {
        const { result } = res;

        if (result && result.status && result.status === 'ok') {
          const citiesAndRegions = result.data.items.reduce((acc, next) => {
            if (next.parentId) {
              acc.cities.push(next);

              return acc;
            }

            /**
             * Пропускаем id для Москвы и Санкт-Петербурга
             */
            if (![1, 2, 4593, 4588].includes(next.id)) {
              acc.regions.push(next);
            }

            return acc;
          }, { cities: [], regions: []} as ICitiesAndRegions);

          dispatch(setCitiesAndRegions({
            citiesAndRegions: [
              ...citiesAndRegions.cities.sort(sort('displayName')),
              ...citiesAndRegions.regions.sort(sort('displayName')),
            ],
          }));
        }

        dispatch(setLoadCitiesAndRegions(false));
      });
  };
}
