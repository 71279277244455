import { IUpcomingSaleNewbuilding } from '../../types/upcomingSale';

export enum EUpcomingSale {
  CloseUpcomingSalePopup = 'upcomingSale/closeUpcomingSalePopup',
  OpenUpcomingSalePopup = 'upcomingSale/openUpcomingSalePopup',
}

export interface IOpenUpcomingSalePopupAction {
  type: EUpcomingSale.OpenUpcomingSalePopup;
  payload: IUpcomingSaleNewbuilding;
}

export interface ICloseUpcomingSalePopupAction {
  type: EUpcomingSale.CloseUpcomingSalePopup;
}

export function openUpcomingSalePopup(newbuilding: IUpcomingSaleNewbuilding): IOpenUpcomingSalePopupAction {
  return {
    type: EUpcomingSale.OpenUpcomingSalePopup,
    payload: newbuilding,
  };
}

export function closeUpcomingSalePopup(): ICloseUpcomingSalePopupAction {
  return {
    type: EUpcomingSale.CloseUpcomingSalePopup,
  };
}

export type TUpcomingSaleActions = IOpenUpcomingSalePopupAction | ICloseUpcomingSalePopupAction;
