import { PageType } from '../../utils/analytics';
import { TTypedThunkAction } from '../model';

export type TTransitionMetricsActions
  = { type: 'MarkTransition', payload: { pageType: PageType, transitioned: boolean } }
  | { type: 'SetTransitioned', payload: { transitioned: boolean } };

export function markTransition(pageType: PageType): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { logger }) => {
    const state = getState().transitionMetrics;
    const firstLoad = state.pageType === '';

    if (!firstLoad) {
      try {
        performance.mark(`start transition to ${pageType}`);
      } catch (ex) {
        logger.warning(ex);
      }
    }
    dispatch<TTransitionMetricsActions>({
      type: 'MarkTransition',
      payload: {
        pageType,
        transitioned: !firstLoad,
      },
    });
    return Promise.resolve();
  };
}

export function tryMeasureTransition(pageType: PageType): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { logger }) => {
    const state = getState().transitionMetrics;
    if (state.transitioned && state.pageType === pageType) {
      try {
        performance.measure(`transition to ${state.pageType}`, `start transition to ${state.pageType}`);
      } catch (ex) {
        logger.warning(ex);
      }
      dispatch<TTransitionMetricsActions>({
        type: 'SetTransitioned',
        payload: { transitioned: false },
      });
    }
    return Promise.resolve();
  };
}
