import { connect } from 'react-redux';
import { saveSearchAction } from '../../redux/map_page/actions';
import { ReduxState, TTypedThunkDispatch } from '../../redux/model';
import { closeSaveFilterPopupAction } from '../../redux/save_filter_popup';
import { ISaveFilterPopupProps, SaveFilterPopup } from './save_filter_popup';
import { getOfferTypeText } from '../../redux/offers_list/offer';
import { getRightDeclensionForOfferType } from './utils/getRightDeclension';
import { routeFor } from '../../utils/routeFor';

export type TStateProps = Pick<
  ISaveFilterPopupProps,
  | 'isAuthenticated'
  | 'title'
  | 'email'
  | 'opened'
  | 'pushSubscribed'
  | 'isCardRoute'
  | 'isNewDesignSavedSearch'
  | 'isFromFilter'
  | 'queryString'
>;
export type TDispatchProps = Pick<ISaveFilterPopupProps, 'saveSearch' | 'closeSaveFilterPopup'>;

export function mapStateToProps(state: ReduxState) {
  const { routing, offerPage, searchList } = state;

  let title = '';

  const isCardRoute = routing.routeName === 'offerCard';
  const isFromFilter = routing.locationBeforeTransitions.pathname.includes(routeFor.ADVANCED_SEARCH);
  const queryStringFromSearchList = searchList.initialized && searchList.queryString ? searchList.queryString : '';
  const queryString = isFromFilter ? routing.locationBeforeTransitions.search : queryStringFromSearchList;

  if (isCardRoute) {
    const dealTypeText =
      offerPage.offer.dealType === 'sale'
        ? 'Купить'
        : offerPage.offer.category && offerPage.offer.category.includes('daily')
        ? 'Снять посуточно'
        : 'Снять';
    const geoText =
      offerPage.offer.addressInfo && offerPage.offer.addressInfo.address ? offerPage.offer.addressInfo.address : '';

    const offerType = getRightDeclensionForOfferType(getOfferTypeText(offerPage.offer.baseInfo)).toLowerCase();

    title = `${dealTypeText} ${offerType} ${geoText}`;
  } else {
    title = state.searchList.initialized && state.searchList.seoData.h1 ? state.searchList.seoData.h1 : '';
  }

  const email = state.common.userEmail ? state.common.userEmail : '';
  const { open, isFromBanner } = state.SaveFilterPopupState;

  return {
    isNewDesignSavedSearch: false,
    isCardRoute,
    opened: open,
    isFromBanner,
    isFromFilter,
    queryString,
    isAuthenticated: state.common.isAuthenticated,
    title,
    email,
    pushSubscribed: state.pushSubscription.pushSubscribed,
  };
}

export function mapDispatchToProps(dispatch: TTypedThunkDispatch): TDispatchProps {
  return {
    saveSearch: (email, title, isNews, notificationFrequency) =>
      dispatch(saveSearchAction(email, title, isNews, notificationFrequency)),
    closeSaveFilterPopup: function () {
      dispatch(closeSaveFilterPopupAction());
    },
  };
}

export const SaveFilterPopupContainer = connect<TStateProps, TDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SaveFilterPopup);
