import * as React from 'react';

import { commercialObjectType, FilterState, suburbanObjectType } from '../../../redux/filters/model';
import { shallowEqual } from '../../../utils/react_helpers';
import RangeComponent from '../../common/range/range';
import {
  createField,
  IFieldProps,
  isCommercialObjectGroup,
  isSuburbanObjectGroup,
  isSuburbanObjectGroupHouse,
  isSuburbanObjectGroupWithoutStead,
  IsVisibleCheck,
} from '../field';

import { Label } from '@cian/components';

const styles = require('./square_stead.css');

class Component extends React.Component<IFieldProps, object> {

  public shouldComponentUpdate(nextProps: IFieldProps) {
    const filter: FilterState = this.props.filter;
    const nextFilter: FilterState = nextProps.filter;

    return !shallowEqual(filter.sizeStead, nextFilter.sizeStead)
      || filter.dealType !== nextFilter.dealType
      || filter.selectedObjectTypes !== nextFilter.selectedObjectTypes;
  }

  public render() {
    return (
      <div className={styles.label} data-mark="FieldSquareStead">
        <Label label="Площадь участка">
          <RangeComponent
            field="sizeStead"
            fromPlaceholder="от"
            metric="сот."
          />
        </Label>
      </div>
    );
  }
}

export const isVisibleInFastFilters: IsVisibleCheck = filter =>
  (isSuburbanObjectGroup(filter) || isCommercialObjectGroup(filter))
  && filter.selectedObjectTypes.some( (type) =>
    type === suburbanObjectType.Stead || type === commercialObjectType.CommercialLand,
  );

export const squareSteadFastFilter = createField(
  ({ filter }) => isVisibleInFastFilters(filter),
)(Component);

export const isVisibleInAdvancedFilters: IsVisibleCheck = filter =>
  isSuburbanObjectGroup(filter)
  && isSuburbanObjectGroupHouse(filter)
  && isSuburbanObjectGroupWithoutStead(filter);

export const squareSteadAdvancedFilter = createField(
  ({ filter }) => isVisibleInAdvancedFilters(filter),
)(Component);
