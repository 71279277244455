import { IRegionInfo } from '../../api/api';
import { Regions, regions } from '../../utils/regions';
import {
  IRegionItem, IRegionItemType,
  ISelectRegionProps, Screen,
} from './model';

import { ISpecialPromoInfo } from '../../redux/special_promos_info/model';
import logException from '../../utils/exceptions';
import { getSubdomain } from '../../utils/location';

export const parseRegions = (regionsInfo: IRegionInfo[],
                             type: IRegionItemType,
                             spInfo?: ISpecialPromoInfo[]): IRegionItem[] =>
  regionsInfo.map((region): IRegionItem => ({
      name: region.displayName || '',
      value: region.id,
      type,
      parentId: region.parentId,
      subdomain: region.baseHost ? getSubdomain(region.baseHost) : 'www',
      specialPromoInfo: spInfo ? spInfo.find(sp => sp.region === region.id) : undefined,
  }));

export const capitalsScreenRegions = [
  Regions.MoscowAndRegion,
  Regions.Moscow,
  Regions.SaintPetersburgAndRegion,
  Regions.SaintPetersburg,
];

interface IGetScreenOptions {
  withoutCities?: boolean;
}

export const getScreen = (regionId?: number, options?: IGetScreenOptions): Screen => {
  if (options && options.withoutCities) {
    return Screen.Regions;
  }

  if (!regionId) {
    return Screen.Regions;
  }
  if (capitalsScreenRegions.includes(regionId)) {
    return Screen.CapitalDetail;
  }
  return Screen.Cities;
};

export const getBackButtonTitle = (regionId: number, screen: Screen): string => {
  if (regionId === Regions.MoscowRegion) {
    return 'Московская область';
  }
  if (regionId === Regions.LeningradRegion) {
    return 'Ленинградская область';
  }
  return screen === Screen.Regions ? '' : 'К списку регионов';
};

export const getCapitalDetailItems = (regionId: number): IRegionItem[] => {
  if ([Regions.MoscowAndRegion, Regions.Moscow].includes(regionId)) {
    return [
      {
        type: 'radio',
        name: 'Москва и МО',
        value: Regions.MoscowAndRegion,
      },
      {
        type: 'radio',
        name: 'Москва',
        value: Regions.Moscow,
      },
      {
        type: 'link',
        name: 'Московская область',
        value: Regions.MoscowRegion,
      },
    ];
  }
  if ([Regions.SaintPetersburgAndRegion, Regions.SaintPetersburg].includes(regionId)) {
    return [
      {
        type: 'radio',
        name: 'Санкт-Петербург и ЛО',
        value: Regions.SaintPetersburgAndRegion,
      },
      {
        type: 'radio',
        name: 'Санкт-Петербург',
        value: Regions.SaintPetersburg,
      },
      {
        type: 'link',
        name: 'Ленинградская область',
        value: Regions.LeningradRegion,
      },
    ];
  }
  logException(new Error('getCapitalDetailItems called with regionId not belonging to capital items'));
  return [];
};

export const getFirstRegions = (type: 'link' | 'radio', withoutCities?: boolean): IRegionItem[] => {
  return [
    {
      type,
      name: 'Москва и МО',
      value: withoutCities ? Regions.Moscow : Regions.MoscowAndRegion,
      subdomain: 'www',
    },
    {
      type,
      name: 'Санкт-Петербург и ЛО',
      value: withoutCities ? Regions.SaintPetersburg : Regions.SaintPetersburgAndRegion,
      subdomain: 'spb',
    },
  ];
};

export const getFirstCitiesItem = (regionId: number): IRegionItem[] => {
  const selectedRegion = regions.find(region => region.id === regionId);
  if (selectedRegion) {
    const regionTitle = selectedRegion.title;
    const allWord = selectedRegion.isMale ? 'Весь' : 'Вся';

    return [{
      type: 'radio',
      name: `${allWord} ${regionTitle}`,
      value: selectedRegion.id,
      parentId: selectedRegion.id,
    }];
  }
  return [];
};

export const getDynamicProps = (props: ISelectRegionProps) => {
  const { opened, isLoading, selectedRegionId, ownSelectedRegionId } = props;
  return {
    opened,
    isLoading,
    selectedRegionId,
    ownSelectedRegionId,
  };
};
