/**
 * @todo Удалить файл с RS
 * @description Данный функционал появился в задаче CD-197673, будет удалён в задаче CD-197395
 */
let isElectronicTradingEnabled: null | boolean = null;

export function setIsElectronicTradingEnabled(enabled: boolean): void {
  isElectronicTradingEnabled = enabled;
}

export function getIsElectronicTradingEnabled(): boolean {
  return !!isElectronicTradingEnabled;
}
