import { ECoworkingOfferType } from '../../api/models/offer_card';

import { coworkingOfferTypeToAnalyticsRoadMap } from '../map';

const gaObjTypeRegEx = /ga_obj_type=\d+/;

export const replaceOfficeUrlToCoworkingUrl = (coworkingOfferType?: ECoworkingOfferType) => (url: string): string => {
  if (coworkingOfferType && gaObjTypeRegEx.test(url)) {
    return url.replace(
      gaObjTypeRegEx,
      `ga_obj_type=${coworkingOfferTypeToAnalyticsRoadMap(coworkingOfferType)}`,
    );
  }

  return url;
};
