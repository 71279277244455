import { IOfferDetail } from '../../../api/models/offer_card';
import { IPlaceInfo } from '../../../redux/offer/model';
import { round } from '../../../utils/numbers';
import { RentTime } from '../model';
import { parseRentTime } from './base_info_parser';

export function responseToPlaceInfo(offer: IOfferDetail): IPlaceInfo | undefined {
  if (offer.offerType !== 'flat' && offer.offerType !== 'newobject') {
    return;
  }

  const roomsCount = (() => {
    if (offer.flatType === 'openPlan') {
      return 7;
    } else if (offer.flatType === 'studio') {
      return 9;
    }
    return offer.roomsCount;
  })();

  // Квартира, но не комната, не доля и не койко-место
  const blacklist = [
    0, // Комната
    8, // Доля
    10, // Койко-место
  ];

  const isFlat = roomsCount && !blacklist.includes(roomsCount);
  const isRent = offer.dealType === 'rent';
  const isSale = offer.dealType === 'sale';

  const conditions = [
    isRent && parseRentTime(offer) === RentTime.Long && isFlat,
    isSale && isFlat,
  ];

  if (!conditions.some(Boolean)) {
    return;
  }

  const { district } = offer;

  if (!district) {
    return;
  }

  const rentPrice = isRent
    ? district.roomRentPrice
    : undefined;
  const salePrice = isSale
    ? district.roomSalePrice
    : undefined;

  return {
    name: district.districtTitle,
    region: district.regionTitle,
    rentPrice: rentPrice ? round(parseFloat(rentPrice as string), 1) : undefined,
    salePrice: salePrice ? round(parseFloat(salePrice as string), 1) : undefined,
    roomsCount: (rentPrice || salePrice) && roomsCount || undefined,
    rentPriceDynamics: isRent && district.roomRentPriceDiff
      ? round(parseFloat(district.roomRentPriceDiff as string), 1)
      : undefined,
    salePriceDynamics: isSale && district.roomSalePriceDiff
      ? round(parseFloat(district.roomSalePriceDiff as string), 1)
      : undefined,
  };
}
