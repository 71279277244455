import { EJsonQueryPriceType } from '../../../field';
import { IButtonRadioGroupFieldValue } from '../../../../../ui/button_radio_group_field/button_radio_group_field';

export class CoworkingPriceTypeRadioButtonModel implements IButtonRadioGroupFieldValue {
  public readonly label: string;

  public readonly value: EJsonQueryPriceType;

  public constructor(
    label: CoworkingPriceTypeRadioButtonModel['label'],
    value: CoworkingPriceTypeRadioButtonModel['value'],
  ) {
    this.label = label;

    this.value = value;
  }
}
