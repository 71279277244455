import { ca } from '@cian/analytics';

export function trackSaveSearchClick() {
  ca('eventSite', {
    name: 'oldevent',
    action: 'open',
    category: 'subscription',
    label: 'saved_search',
  });
}
