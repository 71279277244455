import { Label } from '@cian/components';
import * as React from 'react';

import { flatObjectType } from '../../../redux/filters/model';
import { SelectMobile } from '../../common/select/select';
import { createSelectOptions } from '../../common/select_helper';
import { IFieldProps, IsVisibleCheck } from '../field';

const fieldStyles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  private options = [{
    label: '',
    options: [1, 2, 3, 4, 5].map(i => ({
      id: i.toString(),
      label: i.toString(),
    })),
  }];

  public render() {
    const processedOptions = createSelectOptions(
      this.options,
      this.props.filter.roomsCount.map(String),
      this.props.filter,
    );

    return (
      <div className={fieldStyles.field} data-mark="FieldRoomsCount">
        <Label label="Комнат в квартире">
          <SelectMobile
            multiple
            id="select-rooms-count"
            name="selectRoomsCount"
            label="Неважно"
            onValueChange={(options: string[]) => this.props.actions.setSelectedRoomsCount(options.map(Number))}
            value={processedOptions.value}
            options={processedOptions.options[0].options}
          />
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => {
  return filter.selectedObjectTypes.includes(flatObjectType.Room)
    || filter.selectedObjectTypes.includes(flatObjectType.Part)
    || filter.selectedObjectTypes.includes(flatObjectType.Bed);
};
