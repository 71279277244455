/* eslint-disable max-lines */

import { sortBy } from 'lodash';

import { ISearchOffersData, TOffersByIdsResponseData } from '../../api/api';
import { DealType as DealTypeString, ObjectType as ObjectTypeString } from '../../api/models/json_query';
import {
  BuildingPresenterClassType,
  ECoworkingOfferType,
  IAgentAvailability,
  IAgentModerationInfoSchema,
  IBusinessShoppingCenter,
  ICommercialSpecialty,
  ICoworking,
  IGeo,
  IOfferBooking,
  IOfferDetail,
  IOfferNewbuilding,
  IRosreestrCheck,
  IVideo,
  IWorkTimeInfo,
  OfferDetailCategory,
  OfferDetailOfferType,
  OfferDetailStatus,
  TAgentAccountType,
  TBargainTermsVatTypes,
} from '../../api/models/offer_card';
import { GetTypeTextArgs, TGetTypeTextParams, getTypeText } from '../../card/components/offer_features/offer_features';
import { ICommercialOwnershipSchema } from '../../types/ICommercialOwnershipSchema';
import { getMainImageId } from '../../utils/analytics';
import logException from '../../utils/exceptions';
import { formatNumber, formatPhone } from '../../utils/formatters';
import { getAgentModerationInfo } from '../../utils/getAgentModerationInfo';
import { IUnderground } from '../common/types';
import { EReadyBusinessType } from '../filters/model';
import {
  AreaUnitType,
  Currency,
  DealType as DealTypeEnum,
  EBrandingLevel,
  IAgentInfo,
  IAreaPart,
  ICommonInfo,
  IHighway,
  IOfferAnalyticsInfo,
  IOfferInfo,
  IOfferKp,
  IOfferURLParams,
  IPhoto,
  IPhotoLabelSchema,
  IRange,
  Pessimisation,
  RawAreaPart,
  RentTime,
  TCalltrackingProvider,
} from '../offer/model';
import { parseOfferDetail } from '../offer/response_parser/offer_parser';

import { IGk } from './gk';
import { addConstructingInfo } from './parser';

const buildingClasses: { [key in BuildingPresenterClassType]: string } = {
  a: 'A',
  aPlus: 'A+',
  b: 'B',
  bPlus: 'B+',
  bMinus: 'B-',
  c: 'C',
  cPlus: 'C+',
  d: 'D',
};

export type TAdditionalParams = TGetTypeTextParams;

export function getOfferTypeText(
  data: GetTypeTextArgs,
  buildingClass?: BuildingPresenterClassType,
  params?: TGetTypeTextParams,
) {
  const typeText = getTypeText(data, params);

  return typeText === 'Офис' && buildingClass && buildingClasses[buildingClass]
    ? `${typeText} (${buildingClasses[buildingClass]})`
    : typeText;
}

interface IGetTotalArea {
  area?: number | IRange;
  areaUnitType?: AreaUnitType | undefined;
  commonInfo?: ICommonInfo | undefined;
  urlParams: IOfferURLParams;
}

export function getTotalArea(params: IGetTotalArea) {
  const { area, areaUnitType, commonInfo, urlParams } = params;
  if (!area) {
    return undefined;
  }

  const total =
    typeof area === 'number'
      ? formatNumber(area)
      : urlParams.objectType === 'commercial'
      ? `${formatNumber(area.min)} - ${formatNumber(area.max)}`
      : formatNumber(area.min);

  if (commonInfo && commonInfo.category && commonInfo.category.toLowerCase().includes('land')) {
    return `${total}\u00A0${areaUnitType}`;
  }

  return `${total}\u00A0м²`;
}

export interface IGkInfo {
  name: string;
  buildingStatus?: string;
}

export interface IOffer {
  // Версия модели ML, которая вернула офферы
  modelVersion?: number;
  // id главной фото на выдаче
  mainImageId?: number;
  // обязательные
  agentInfo: IAgentInfo;
  id: number;
  url: string;
  price: number | IRange;
  currency: Currency;
  favorite: boolean;
  dealType: DealTypeString;
  objectType: ObjectTypeString;
  offerType?: OfferDetailOfferType;
  // необязательные
  agentAccountType?: TAgentAccountType;
  booking?: IOfferBooking;
  businessShoppingCenter?: IBusinessShoppingCenter;
  gaLabel?: string;
  geo?: IGeo;
  description?: string;
  isSuggestion?: boolean;
  phoneDataLayer?: IOfferAnalyticsInfo;
  bedroomsCount?: number;
  commission?: number;
  deposit?: number;
  flatsOnOtherFloorsCount?: number;
  floor?: number;
  floorTo?: number;
  floorFrom?: number;
  indexOnFloor?: number;
  floorsCount?: number;
  fromDeveloper?: boolean;
  fullAddress?: string;
  gk?: IGkInfo;
  highways?: IHighway[];
  honestWork?: boolean;
  isByCommercialOwner?: boolean;
  isCommercialOwnershipVerified?: boolean;
  kp?: IOfferKp;
  standard: boolean;
  photos?: string[];
  videos?: IVideo[];
  auction?: boolean;
  roomsCount?: number;
  premium?: boolean;
  priceForSquare?: number;
  pro?: boolean;
  promo?: string;
  rosreestrCheck?: IRosreestrCheck | null;
  typeText?: string;
  rentTime?: RentTime;
  top3?: boolean;
  totalArea?: string;
  undergrounds?: IUnderground[];
  agentName?: string;
  contacts?: string[];
  defaultPhotosLength?: number;
  // пессимизация
  wrongDescription?: boolean;
  objectDoesNotExist?: boolean;
  // лейбл "без-комиссии"
  withoutCommissionLabel?: boolean;
  // лейбл "Собственник"
  fromHomeOwnerLabel?: boolean;
  status?: OfferDetailStatus;
  newbuilding?: IOfferNewbuilding | null;
  // данные по не рабочему времени
  workTimeInfo?: IWorkTimeInfo;
  // тип налога. Для коммерческой. начиная с 01.03.18 notincluded не приходит
  vatType?: TBargainTermsVatTypes;
  // Активность услуги подмены номера у объявления
  isCalltrackingEnabled: boolean;
  // Провайдер услуги подмены номера
  callTrackingProvider: TCalltrackingProvider;
  // Доступность чатов
  isChatsEnabled?: boolean;
  agentAvailability?: IAgentAvailability;
  publishedUserId?: number;
  cianUserId?: number;
  title?: string;
  /**
   * Отвечает за показ лейбла - "Новое"
   * Актуально для выдачи по сохраненному поиску
   */
  isNew?: boolean;
  decoration?: string;
  decorationStrict?: string;
  category?: OfferDetailCategory;
  isFromBuilder?: boolean;
  newbuildingDynamicCalltracking?: {
    siteBlockId?: number | null;
  };
  isResale?: boolean;
  areaParts?: IAreaPart[] | RawAreaPart[];
  specialty?: ICommercialSpecialty;
  // коворкинги
  coworkingOfferType?: ECoworkingOfferType | null;
  workplaceCount?: number | null;
  priceForWorkplace?: number | null;
  coworking?: ICoworking;
  readyBusinessType?: EReadyBusinessType;
  monthlyIncome?: number;
  monthlyIncomeCurrency?: Currency;
  isNeedHideExactAddress?: boolean;
  agentModerationInfo: IAgentModerationInfoSchema | null;
  brandingLevel?: EBrandingLevel | null;
  userTitle: string;
  userAvatar?: string | null;
  /** Данные о собственнике коммерческой недвижимости **/
  commercialOwnership?: ICommercialOwnershipSchema | null;
  /** Ссылка на торги на электронной площадке **/
  tradingLink?: string | null;
  /** Лейблы на фото **/
  photoLabels: IPhotoLabelSchema[];
}

export const getPhotos = (photos: IPhoto[]): string[] => {
  return sortBy(photos, p => !p.isDefault)
    .map(photo => photo.fullUrl || photo.thumbnail2Url || photo.thumbnailUrl || photo.miniUrl)
    .filter(Boolean) as string[];
};

export function convertOfferToOfferPreview(
  offerInfo: IOfferInfo,
  gks: IGk[],
  additionalParams?: TAdditionalParams,
): IOffer {
  const {
    urlParams,
    baseInfo,
    modelVersion,
    geo,
    addressInfo,
    buildingInfo,
    multioffer,
    commonInfo,
    tradingLink,
    photoLabels,
  } = offerInfo;
  const { area, currency, areaUnitType } = baseInfo;

  const offerGk = addressInfo && gks.find(gk => !!addressInfo.jk && gk.url === addressInfo.jk.url);

  let price = baseInfo.price ? baseInfo.price : 0;

  /**
   * Если доступно поле - коммунальные услуги (utilityPaymentsPrice),
   * то суммируем коммунальные услуги и стоимость объекта.
   */
  if (typeof price === 'number' && baseInfo.utilityPaymentsPrice) {
    price += baseInfo.utilityPaymentsPrice;
  }
  const totalArea = getTotalArea({ area, areaUnitType, commonInfo, urlParams });
  const specialties = baseInfo.specialty ? [{ rusName: baseInfo.specialty[0] }] : [];

  const parsedOffer: IOffer = {
    // обязательные
    agentInfo: offerInfo.agentInfo,
    auction: baseInfo.isAuction,
    id: urlParams.id,
    url: `/${urlParams.dealType}/${urlParams.objectType}/${urlParams.id}`,
    price,
    currency: currency || Currency.RUB,
    favorite: offerInfo.isFavorite,
    dealType: urlParams.dealType,
    objectType: urlParams.objectType,
    offerType: offerInfo.offerType,
    gaLabel: offerInfo.gaLabel,
    geo: offerInfo.geo,
    // необязательные
    booking: offerInfo.booking,
    businessShoppingCenter: offerInfo.businessShoppingCenter,
    description: offerInfo.description,
    phoneDataLayer: offerInfo.offerAnalyticsInfo,
    bedroomsCount: urlParams.dealType === 'rent' ? baseInfo.bedroomsCount : undefined,
    commission: baseInfo.fee,
    deposit: urlParams.objectType !== 'flat' || urlParams.dealType === 'rent' ? baseInfo.deposit : undefined,
    flatsOnOtherFloorsCount: multioffer && multioffer.count,
    floor: baseInfo.floor,
    floorsCount: baseInfo.totalFloors,
    floorTo: offerInfo.baseInfo.floorTo,
    floorFrom: offerInfo.baseInfo.floorFrom,
    fromDeveloper: offerInfo.fromDeveloper,
    fullAddress: addressInfo && addressInfo.address,
    gk:
      addressInfo && addressInfo.jk && addressInfo.jk.name
        ? {
            name: `ЖК «${addressInfo.jk.name}»`,
            buildingStatus: offerGk && offerGk.buildingStatus,
          }
        : undefined,
    highways: urlParams.objectType === 'suburban' ? addressInfo && addressInfo.highways : undefined,
    honestWork: offerInfo.agentInfo.isHonest,
    kp: offerInfo.kp,
    standard: baseInfo.isStandard,
    photos: getPhotos(offerInfo.photos),
    mainImageId: getMainImageId(offerInfo),
    modelVersion:
      typeof modelVersion === 'number' || typeof modelVersion === 'string' ? Number(modelVersion) : undefined,
    videos: offerInfo.videos,
    premium: baseInfo.isPremium,
    priceForSquare:
      urlParams.objectType === 'commercial' ? baseInfo.pricePerUnit && baseInfo.pricePerUnit.price : undefined,
    pro: baseInfo.isPro,
    promo: baseInfo.promo,
    typeText: getOfferTypeText(baseInfo, buildingInfo && buildingInfo.classType, additionalParams),
    rentTime: baseInfo.rentTime,
    roomsCount: offerInfo.roomsCount,
    rosreestrCheck: offerInfo.rosreestrCheck,
    top3: baseInfo.isTop3,
    totalArea,
    undergrounds:
      addressInfo &&
      addressInfo.undergrounds &&
      addConstructingInfo(addressInfo.undergrounds, (geo && geo.undergrounds) || []),
    agentName: offerInfo.agentInfo.name,
    contacts: offerInfo.agentInfo.contacts.map(contact => formatPhone(`${contact.countryCode}${contact.number}`, true)),
    withoutCommissionLabel: baseInfo.withoutCommissionLabel,
    workTimeInfo: offerInfo.workTimeInfo,
    defaultPhotosLength: offerInfo.photos.filter(photo => photo.isDefault).length,
    // пессимизация
    wrongDescription: offerInfo.pessimisation === Pessimisation.Light,
    objectDoesNotExist: offerInfo.pessimisation === Pessimisation.Strong,
    fromHomeOwnerLabel: offerInfo.isByHomeowner,
    status: offerInfo.status,
    newbuilding: offerInfo.newbuilding,
    vatType: baseInfo && baseInfo.vatType,
    isCalltrackingEnabled: offerInfo.isCalltrackingEnabled,
    callTrackingProvider: offerInfo.callTrackingProvider,
    isChatsEnabled: offerInfo.user && offerInfo.user.isChatsEnabled,
    agentAvailability: offerInfo.user && offerInfo.user.agentAvailability,
    agentAccountType: offerInfo.user && offerInfo.user.agentAccountType,
    publishedUserId: offerInfo.publishedUserId,
    userTitle: offerInfo.user?.agencyNameV2 || `ID: ${offerInfo.cianUserId}`,
    userAvatar: offerInfo.user?.agentAvatarUrl,
    cianUserId: Number(offerInfo.user?.cianUserId) || undefined,
    title: offerInfo.title,
    isNew: offerInfo.isNew,
    decoration: baseInfo.decoration,
    decorationStrict: baseInfo.decorationStrict,
    category: offerInfo.category,
    isFromBuilder: offerInfo.isFromBuilder,
    newbuildingDynamicCalltracking: offerInfo.newbuildingDynamicCalltracking,
    isResale: offerInfo.isResale,
    areaParts: offerInfo.areaParts,
    specialty: {
      ...offerInfo.specialty,
      specialties: [...(offerInfo.specialty?.specialties || []), ...specialties],
    },
    // коворкинги
    coworkingOfferType: baseInfo.coworkingOfferType,
    workplaceCount: baseInfo.workplaceCount,
    priceForWorkplace: baseInfo.priceForWorkplace,
    coworking: baseInfo.coworking,
    isByCommercialOwner: offerInfo.isByCommercialOwner,
    isCommercialOwnershipVerified: offerInfo.isCommercialOwnershipVerified,
    readyBusinessType: offerInfo.readyBusinessType,
    monthlyIncome: baseInfo.monthlyIncome,
    monthlyIncomeCurrency: baseInfo.monthlyIncomeCurrency,
    isNeedHideExactAddress: offerInfo.isNeedHideExactAddress,
    agentModerationInfo: getAgentModerationInfo(offerInfo),
    brandingLevel: offerInfo.brandingLevel,
    commercialOwnership: offerInfo.commercialOwnership,
    tradingLink,
    photoLabels,
  };

  return parsedOffer;
}

export function parseUrlParams(offer: IOfferDetail): IOfferURLParams {
  if (!offer.dealType || !offer.offerType || !offer.cianId) {
    throw logException(new Error('Not found url params in offer (dealType & offerType & id)'), { offer });
  }

  return {
    dealType: offer.dealType,
    objectType: offer.offerType === 'newobject' ? 'flat' : offer.offerType,
    id: offer.cianId,
  };
}

export function parseOffers(data: ISearchOffersData | TOffersByIdsResponseData): IOfferInfo[] {
  return data.offersSerialized.map((offer, index) => {
    return parseOfferDetail(offer, parseUrlParams(offer), index);
  });
}

export function getTimePeriod(dealType?: DealTypeString | DealTypeEnum, rentTime?: RentTime): string | undefined {
  return dealType === 'rent' || dealType === DealTypeEnum.Rent
    ? rentTime === RentTime.Short
      ? 'сутки'
      : 'мес'
    : undefined;
}
