import { uniqueId } from 'lodash';
import { isSafari } from './device_detector';

const disablers: Set<string> = new Set();

export interface IBodyScrollController {
  enable(): void;
  disable(): void;
}

export function createBodyScroll(): IBodyScrollController {
  const controllerId = uniqueId('bodyScroll');
  return {
    enable: () => bodyScrollEnable(controllerId),
    disable: () => bodyScrollDisable(controllerId),
  };
}

export function bodyScrollDisable(disablerName: string) {
  const wasDisabled = disablers.size;
  if (!disablers.has(disablerName)) {
    if (!wasDisabled) {
      disableScroll();
    }
    disablers.add(disablerName);
  }
}

export function bodyScrollEnable(disablerName: string) {
  const wasDisabled = disablers.size;
  disablers.delete(disablerName);
  if (!disablers.size && wasDisabled) {
    enableScroll();
  }
}

const getScrollPosition = (): number => {

  if (typeof pageYOffset !== 'undefined') {
    //most browsers except IE before #9
    return pageYOffset;
  } else {
    const B = document.body; //IE 'quirks'
    let D = document.documentElement; //IE with doctype
    D = (D.clientHeight) ? D : B;
    return D.scrollTop;
  }
};

const setScrollPosition = (value: number) => {
  const eViewport = document.scrollingElement;
  if (eViewport) {
    eViewport.scrollTop = value;
  } else {
    if (isSafari((navigator && navigator.userAgent) || '')) {
      document.body.scrollTop = value;
    } else {
      document.documentElement.scrollTop = value;
    }
 }
};

function disableScroll() {
  document.body.style.overflow = 'hidden';
  document.body.style.top = `-${getScrollPosition()}px`;
  document.body.style.left = '0';
  document.body.style.right = '0';
  document.body.style.bottom = '0';
  document.body.style.maxHeight = '100vh';
  document.body.style.position = 'fixed';
}

function enableScroll() {
  document.body.style.overflow = 'visible';
  document.body.style.position = 'static';
  document.body.style.left = 'auto';
  document.body.style.right = 'auto';
  document.body.style.bottom = 'auto';
  document.body.style.maxHeight = 'initial';
  setScrollPosition(parseInt(document.body.style.top as string, 10) * -1);
  document.body.style.top = 'auto';
  document.body.style.transform = 'none';
}
