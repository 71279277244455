import * as React from 'react';
import { CheckboxField } from '@cian/components';

import Block from '../../../../../ui/block/block';
import { Section } from '../../../../advanced/advanced';

import { CollapsedButton } from '../../../../../components/buttons/CollapsedButton';
import { TAmenitiesOptions } from '../../constants';

const styles = require('./CheckboxGroup.css');

interface ICheckboxGroup {
  isCollapsed: boolean;
  checkedList: TAmenitiesOptions[];
  uncutElementsLength: number;
  areAllElementChecked: boolean;
  onItemChange(checked: boolean, key: string): void;
  onCollapsedButtonClick(): void;
}

export function CheckboxGroup({
  isCollapsed,
  onCollapsedButtonClick,
  checkedList,
  uncutElementsLength,
  onItemChange,
  areAllElementChecked,
}: ICheckboxGroup) {

  const handleValueChange = React.useCallback(
    (key: string) => (value: boolean) => {
      onItemChange(value, key);
    },
    [onItemChange],
  );

  return (
    <Section>
      <Block title="Включено в стоимость" noPadding blockStyle={styles['root']}>
        <div className={styles['amenities-block']}>
          {checkedList.map(({ key, text, value }, index) => {
            const isUnderCut = isCollapsed && index >= uncutElementsLength;

            return (
              <CheckboxField
                key={key}
                label={text}
                containerStyle={[
                  styles['checkbox-container'],
                  isUnderCut && styles['collapsed-block'],
                  !!value && styles['checked'],
                ]}
                labelStyle={styles['checkbox-label']}
                value={!!value}
                onValueChange={handleValueChange(key)}
              />
            );
          })}
          <CollapsedButton
            showButton={!areAllElementChecked}
            className={styles['collapsed-button']}
            isCollapsed={isCollapsed}
            onClick={onCollapsedButtonClick}
          />
        </div>
      </Block>
    </Section>
  );
}
