import { Location } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { setFirstClientLoad, setLastUrl } from '../../redux/common/actions';
import { pushSubscriptionSetValue } from '../../redux/push_subscription/actions';
import { checkFlocktoryLoad } from '../../utils/flocktory';
import Layout from './layout';

interface IDispatchProps {
  setLastUrl(location?: Location): void;
  setFirstClientLoad(value: boolean): void;
  pushSubscriptionSetValue(value: boolean): void;
}

interface IOwnProps extends RouteComponentProps<{}, {}> {}

type TLayoutRouteProps = IDispatchProps & IOwnProps;

class LayoutRoute extends React.Component<TLayoutRouteProps, {}> {
  public componentWillUpdate() {
    this.setLastUrl();
  }

  public componentDidMount() {
    this.setLastUrl();
    this.props.setFirstClientLoad(false);

    checkFlocktoryLoad().then(flocktory => {
      flocktory.getSubscriptionStatus()
        .then(response => {
          /**
           * Если flocktory вернули поле subscribed и оно равно false,
           * значит текущий пользователь не подписан на пуши и нужно показать попап
           */
          if ('subscribed' in response && !response.subscribed) {
            this.props.pushSubscriptionSetValue(true);
          }
      });
    });
  }

  public render() {
    return (
      <Layout>
        {this.props.children}
      </Layout>
    );
  }

  private setLastUrl() {
    this.props.setLastUrl(this.props.location);
  }
}

const mapDispatchToProps = {
  setLastUrl,
  setFirstClientLoad,
  pushSubscriptionSetValue,
};

export default connect<{}, IDispatchProps, IOwnProps>(undefined, mapDispatchToProps)(LayoutRoute);
