import { IStyleConfig } from '@cian/components';

import { values, without } from 'lodash';
import * as React from 'react';

import { ISpecialtySchema } from '../../../shared/repositories/monolith-python/entities/schemas/SpecialtySchema';
import { DeviceType } from '../../redux/common/model';
import * as filtersActionCreators from '../../redux/filters/actions';
import {
  DealType,
  FilterState,
  commercialObjectType,
  commercialObjectTypes,
  flatObjectType,
  flatObjectTypes,
  specialCommercialObjectTypes,
  suburbanObjectType,
  suburbanObjectTypes,
} from '../../redux/filters/model';
import { FixedComponentsState } from '../../redux/fixed_components/model';
import * as mapActionCreators from '../../redux/map_page/actions';
import * as popupActionCreators from '../../redux/popups/actions';
import { RegionsState } from '../../redux/regions/model';
import { hasOne } from '../../utils/helpers';
export * from '../../redux/filters/model';
export * from '../../redux/filters/dictionaries';
import { ReactComponent, transformProps } from '../../utils/react_helpers';

export interface IFiltersVisibilityParams {
  isNewbuildingsMapMode: boolean;
  isCustomSpecialPromo?: boolean;
  optionalRegionInfo?: IOptionalRegionInfo;
}

export interface IOptionalRegionInfo {
  isRegionInMO: boolean;
  isRegionInLO: boolean;
}

export type IsVisible = (data: { filter: FilterState }) => boolean;
export type IsVisibleCheck = (filter: FilterState, optionalVisibilityParams?: IFiltersVisibilityParams) => boolean;
export type ShouldRender = (props: IFieldProps) => boolean;
export type FiltersPlace = 'main' | 'map' | 'advanced_search';

export interface IFieldProps {
  builderId?: number;
  filter: FilterState;
  fixedComponents: FixedComponentsState;
  userAgent?: string;
  actions: typeof filtersActionCreators;
  mapActions: typeof mapActionCreators;
  popupActions: typeof popupActionCreators;
  regions: RegionsState;
  inPopup?: boolean;
  search?: string;
  viewMode: DeviceType;
  filtersPlace: FiltersPlace;
  specialStyles?: IStyleConfig;
  specialPromoTitle?: string;
  isCommercialFilter?: boolean;
  isNewbuildingsMapMode: boolean;
  isCustomSpecialPromo?: boolean;
  isCommercialOwnerFilersAvailable?: boolean;
  specialties?: ISpecialtySchema[];
  isNewFiltersAvailable?: boolean;

  isPopupOpened: (s: string) => boolean;
  onClick?(nextState: boolean): void;
}

export type FieldType = {
  Component: React.ComponentClass<IFieldProps> | React.SFC<IFieldProps>;
  isVisible: IsVisibleCheck;
  shouldRender?: ShouldRender;
  mapSearchDeps?: Array<keyof FilterState>;
};

export interface IFieldPureProps extends IFieldProps {
  field: FieldType;
  dataMark?: string;
}

export const isFlatObjectGroup: IsVisibleCheck = filter => hasOne(flatObjectTypes, filter.selectedObjectTypes);

export const isApartamentObjectGroup: IsVisibleCheck = filter =>
  hasOne([flatObjectType.Apartment, flatObjectType.Part], filter.selectedObjectTypes);

export const isSuburbanObjectGroup: IsVisibleCheck = filter => hasOne(suburbanObjectTypes, filter.selectedObjectTypes);

export const isSuburbanObjectGroupHouse: IsVisibleCheck = filter =>
  hasOne(without(suburbanObjectTypes, suburbanObjectType.Stead), filter.selectedObjectTypes);

export const isSuburbanObjectGroupWithoutStead: IsVisibleCheck = filter =>
  !filter.selectedObjectTypes.filter(t => t === suburbanObjectType.Stead).length;

export const isSteadObject: IsVisibleCheck = filter =>
  filter.selectedObjectTypes.length === 1 && filter.selectedObjectTypes[0] === suburbanObjectType.Stead;

export const isResidentialObjectGroup: IsVisibleCheck = filter =>
  isFlatObjectGroup(filter) || isSuburbanObjectGroup(filter);

export const isCommercialObjectGroup: IsVisibleCheck = filter =>
  hasOne(commercialObjectTypes, filter.selectedObjectTypes);

export const isSpecialCommercialObjectGroup: IsVisibleCheck = filter =>
  hasOne(values(specialCommercialObjectTypes), filter.selectedObjectTypes);

export const isCommercialObjectGroupBuilding: IsVisibleCheck = filter =>
  hasOne(
    without(commercialObjectTypes, commercialObjectType.Garage, commercialObjectType.CommercialLand),
    filter.selectedObjectTypes,
  );

export const isCommercialObjectBuilding: IsVisibleCheck = filter =>
  hasOne(
    without(
      commercialObjectTypes,
      commercialObjectType.RentalBusiness,
      commercialObjectType.ReadyBusiness,
      commercialObjectType.CommercialLand,
    ),
    filter.selectedObjectTypes,
  );

export const isCommercialLand = (filter: FilterState): boolean => filter.selectedObjectTypes.includes('commercialLand');

export const isCoworkingFilters = (filter: FilterState): boolean => filter.selectedObjectTypes.includes('coworking');

export const isGarageFilterOnly = (filter: FilterState): boolean =>
  filter.selectedObjectTypes.length === 1 && filter.selectedObjectTypes.includes('garazh');

export const showWithNewobject: IsVisibleCheck = filter => {
  const isApartament =
    filter.selectedObjectTypes.length === 1 && filter.selectedObjectTypes[0] === flatObjectType.Apartment;

  return filter.dealType === DealType.Sale && isApartament;
};

export const showWithMortgage: IsVisibleCheck = filter => {
  const isFlatObject = isFlatObjectGroup(filter);

  return filter.dealType === DealType.Sale && isFlatObject;
};

export const withNewobjectSelected: IsVisibleCheck = filter => {
  return filter.withNewobject && showWithNewobject(filter);
};

export const isNotUpcomingSale: IsVisibleCheck = filter => {
  return !filter.isUpcomingSale;
};

export const isCommonFlatSale: IsVisibleCheck = filter => {
  return showWithNewobject(filter) && !filter.withNewobject && !filter.resaleOnly;
};

export const showSaleTypeFlat: IsVisibleCheck = filter =>
  !withNewobjectSelected(filter) && isFlatObjectGroup(filter) && filter.dealType === DealType.Sale;

export const isSaleType: IsVisibleCheck = filter => filter.dealType === DealType.Sale;

export const transformToField = transformProps as <Props>(
  transform: (outerProps: IFieldProps) => Props,
) => (Component: ReactComponent<Props>) => React.ComponentClass<IFieldProps>;

const defaultIsVisible = (): boolean => true;

export function createField(isVisible?: IsVisible, shouldRender?: ShouldRender) {
  return (Component: ReactComponent<IFieldProps>): FieldType => {
    return {
      Component,
      shouldRender,
      isVisible: isVisible ? (filter: FilterState): boolean => isVisible({ filter }) : defaultIsVisible,
    };
  };
}

export function createFieldWithPropsTransformation<Props>(
  transformPropsFunc: (outerProps: IFieldProps) => Props,
  isVisible?: IsVisible,
  shouldRender?: ShouldRender,
) {
  return (Component: ReactComponent<Props>): FieldType => {
    return {
      Component: transformToField<Props>(transformPropsFunc)(Component),
      shouldRender,
      isVisible: isVisible ? (filter: FilterState): boolean => isVisible({ filter }) : defaultIsVisible,
    };
  };
}

export function reduceVisibilityCheck(...checkFunctions: IsVisibleCheck[]): IsVisibleCheck {
  return function (filter: FilterState, params?: IFiltersVisibilityParams) {
    const [check, ...fns] = checkFunctions;

    if (typeof check === 'function') {
      return check(filter, params) && reduceVisibilityCheck(...fns)(filter, params);
    }

    return true;
  };
}

export const isBusiness: IsVisibleCheck = filter =>
  filter.selectedObjectTypes.some(type => type === 'rentalBusiness' || type === 'readyBusiness');

export const isReadyBusinessSaleFilters = (filter: FilterState): boolean => {
  return isBusiness(filter) && filter.dealType === DealType.Sale;
};
