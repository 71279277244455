import { connect } from 'react-redux';

import { ReduxState } from '../../redux/model';
import { Copyright, ICopyrightProps } from './index';

type TStateProps = Pick<ICopyrightProps, 'year'>;
type TOwnProps = Pick<ICopyrightProps, 'color'>;

function mapStateToProps(state: ReduxState): TStateProps {
  return {
    year: state.year,
  };
}

export const CopyrightContainer = connect<TStateProps, {}, TOwnProps>(
  mapStateToProps,
)(Copyright);
