import { IEnableResponseSchema } from '@cian/chats-api/lib/shared/users/schema';
import { IAgentAvailability }    from '../../api/models/offer_card';

export interface IOfferChatsIsEnabledAction {
  type: 'chats/offer_chats_is_enabled';
  payload: IEnableResponseSchema;
}

export interface IOfferChatsPopupOpenAction {
  type: 'chats/offer_chats_popup_open';
  payload: number | undefined;
}

export interface IOfferChatsPopupCloseAction {
  type: 'chats/offer_chats_popup_close';
}

export interface IChatsMessageSentAction {
  type: 'chats/message_sent';
}

export interface IMapChatsPopupOpenAction {
  type: 'chats/map_chats_popup_open';
  payload: {
    mapPositionX: number,
    mapPositionY: number,
    offerId: number,
    agentAvailability?: IAgentAvailability,
    contacts?: string[],
    isOwn?: boolean,
  };
}

export const offerChatsIsEnabledAction = (payload: IEnableResponseSchema): IOfferChatsIsEnabledAction => ({
  payload,
  type: 'chats/offer_chats_is_enabled',
});

export const openChatsPopup = (offerId?: number): IOfferChatsPopupOpenAction => ({
  type: 'chats/offer_chats_popup_open',
  payload: offerId,
});

export const closeChatsPopup = (): IOfferChatsPopupCloseAction => ({
  type: 'chats/offer_chats_popup_close',
});

export const chatsMessageSent = (): IChatsMessageSentAction => ({
  type: 'chats/message_sent',
});

export const openChatsOnMap = (payload: IMapChatsPopupOpenAction['payload']): IMapChatsPopupOpenAction => ({
  type: 'chats/map_chats_popup_open',
  payload: payload,
});

export type TChatsActions = IOfferChatsIsEnabledAction
  | IOfferChatsPopupOpenAction
  | IOfferChatsPopupCloseAction
  | IChatsMessageSentAction
  | IMapChatsPopupOpenAction;
