/* eslint-disable max-lines */

import { IStyleConfig } from '@cian/components';
import {
  EInputType,
  ESuggestType,
  IItemClickParams,
  IMessageInfo,
  IMultiSuggestResult,
  GeoSuggest as StructuredSuggest,
} from '@cian/geosuggest-widget';

import { QueryClient } from '@tanstack/react-query';
import { debounce, isEmpty } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { SUGGEST_TWO_LINES_MOB } from '../../../../../shared/constants/experiments';
import { IBoundedBy } from '../../../../api/api';
import * as filterActions from '../../../../redux/filters/actions';
import { filterToJsonQuery } from '../../../../redux/filters/filter_to_json_query';
import { DealType, FilterState, IGeoTag, flatObjectTypes } from '../../../../redux/filters/model';
import { ReduxState } from '../../../../redux/model';
import { MobileWebsiteApi, inject } from '../../../../utils/context_provider';
import { isAbUseExperimentActive } from '../../../../utils/experiments';
import { toPromise } from '../../../../utils/streams';
import { arrCoordsToLatLng } from '../../../../utils/ymaps';
import { createGeoTag } from '../utils';

import { getMultiSuggest, getSuggestOfferType, prepareTagKind } from './api';
import * as Trackings from './trackings';

import * as styles from './suggest.css';

const YANDEX_RESULTS_MAX = 10;
const ERROR = { title: 'Не удалось получить возможные варианты', text: 'Попробуйте еще раз' };
const NOT_FOUND = { title: 'Ничего не найдено', text: 'Укажите другой адрес' };

interface IGeoSuggestInjectedProps {
  api: MobileWebsiteApi;
}

interface IGeoSuggestStoreProps {
  filter: FilterState;
  inSuggestTwoStringExperiment: boolean;
  queryClient: QueryClient;
}

interface IOwnProps {
  region: number;
  isTagLoading: boolean;
  actions: typeof filterActions;
  inputType?: EInputType;
  boundedBy?: IBoundedBy;
  hideClearButton?: boolean;
  inputStyle?: IStyleConfig;
  hidePlaceholder?: boolean;
  onValueChange?(value: string): void;
  onFocus?(): void;
}

interface IGeoSearchState {
  value: string;
  selectedValue: string;
  loading: boolean;
  disabled: boolean;
  error: IMessageInfo;
  suggestData?: IMultiSuggestResult;
}

type TGeoSuggestProps = IGeoSuggestStoreProps & IGeoSuggestInjectedProps & IOwnProps;

const DEAL_TYPE_SUGGEST_MAP: { [key: number]: 'rent' | 'sale' } = {
  [DealType.Rent]: 'rent',
  [DealType.Sale]: 'sale',
};

export class GeoSuggest extends React.Component<TGeoSuggestProps, IGeoSearchState> {
  public state: IGeoSearchState = {
    value: '',
    selectedValue: '',
    loading: false,
    disabled: false,
    error: {},
    suggestData: undefined,
  };

  public componentDidUpdate(_: {}, prevState: IGeoSearchState): void {
    if (this.props.onValueChange && prevState.value !== this.state.value) {
      this.props.onValueChange(this.state.value);
    }
  }

  public render(): JSX.Element {
    const placeholder = this.getPlaceholder();
    const { inputType, inputStyle, inSuggestTwoStringExperiment } = this.props;
    const { disabled, loading, value, selectedValue, suggestData, error } = this.state;

    return (
      <StructuredSuggest
        disabled={disabled}
        error={error}
        inputStyle={inputStyle}
        inputType={inputType || EInputType.mobile}
        isLoading={loading}
        placeholder={placeholder}
        popupStyle={inSuggestTwoStringExperiment ? styles['suggest-two-lines'] : ''}
        selectedValue={selectedValue}
        suggestData={suggestData}
        value={value}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onItemClick={this.onItemClick}
        onValueChange={this.onValueChange}
      />
    );
  }

  private readonly getPlaceholder = (): string => {
    const {
      filter: { selectedObjectTypes },
    } = this.props;

    // as any потому что flatObjectTypes видит типы только для flat'ов,
    // а в objectType может быть любой тип объекта
    const isFlatObject = selectedObjectTypes.find(objectType => flatObjectTypes.includes(objectType as any));

    return isFlatObject ? 'Город, адрес, метро, район, ж/д, шоссе или ЖК' : 'Город, адрес, метро, район, ж/д, шоссе';
  };

  private readonly resetSuggest: VoidFunction = () => {
    this.setState({
      value: '',
      selectedValue: '',
      error: {},
      loading: false,
      suggestData: undefined,
      disabled: false,
    });
  };

  private readonly onFocus: VoidFunction = () => {
    const { value } = this.state;

    this.setState({ error: {} });
    Trackings.trackGeoSuggestClick();
    if (value.length > 2) {
      this.suggestDebounced(value);
    }
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  private readonly onBlur: VoidFunction = () => {
    this.resetSuggest();
  };

  private readonly onValueChange = (value: string): void => {
    this.props.actions.setGeoTagsLoading(false);
    this.setState({ value });
    if (value.length > 2) {
      this.suggestDebounced(value);
    } else {
      this.setState({ loading: false, error: {}, suggestData: undefined });
    }
    if (this.props.onValueChange) {
      this.props.onValueChange(this.state.value);
    }
  };

  private readonly suggest = (valueRaw: string): Promise<void> | undefined => {
    const { api, region, filter, boundedBy, queryClient } = this.props;

    const value = valueRaw.trim();
    const { _type, with_newobject, suburban_offer_filter } = filterToJsonQuery(filter, undefined, queryClient);

    if (!value) {
      return undefined;
    }

    this.setState({ loading: true });

    return getMultiSuggest({
      api,
      structured: {
        offerType: getSuggestOfferType(_type, with_newobject && with_newobject.value),
        query: value,
        regionId: region,
        dealType: DEAL_TYPE_SUGGEST_MAP[filter.dealType],
        suburbanOfferFilter: suburban_offer_filter && suburban_offer_filter.value,
      },
      yandex: {
        options: { boundedBy, results: YANDEX_RESULTS_MAX },
        value,
      },
    })
      .then(result => {
        let error: IMessageInfo = {};
        if (this.state.value) {
          if (isEmpty(result.suggestions)) {
            Trackings.trackGeoSuggestNotFound(value);
            error = NOT_FOUND;
          }
          this.setState({ suggestData: result, loading: false, error });
        } else {
          this.setState({ suggestData: undefined, loading: false });
        }
      })
      .catch(() => {
        this.setState({ loading: false, error: ERROR });
      });
  };

  private suggestDebounced = debounce(this.suggest, 300);
  /**
   * Добаляем гео тэг, снимаем прелоадер, очищаем пользовательский ввод
   */
  private readonly setGeoTag = (tag: IGeoTag): void => {
    this.props.actions.addGeoTag(tag);
    this.props.actions.setGeoTagsLoading(false);
    this.setState({ value: '' });
  };

  private readonly createSuggestTag = (params: IItemClickParams): IGeoTag => {
    const { id, title, group, regionId } = params;
    const { suggestData } = this.state;

    let tag: IGeoTag = {
      id,
      text: title,
      name: title,
      regionId,
      isParsed: true,
      geo: [],
      kind: prepareTagKind(group),
    };

    if (group === ESuggestType.underground && suggestData && suggestData.suggestions) {
      const underground =
        suggestData.suggestions.undergrounds && suggestData.suggestions.undergrounds.find(u => u.id === id);
      if (underground) {
        tag = {
          ...tag,
          color: `#${underground.lines[0].lineColor}`,
          underConstruction: underground.underConstruction,
          releaseYear: underground.releaseYear,
        };
      }
    }
    if (group === ESuggestType.city) {
      tag.regionId = id;
    }

    return tag;
  };

  private readonly onItemClick = (params: IItemClickParams): void | Promise<void> => {
    const { id, title, group } = params;

    this.props.actions.setUserInput(this.state.value);
    this.props.actions.setGeoTagsLoading(true);
    this.setState({ value: '' });

    //структурированный саджест
    if (id) {
      const trackInfo = group === ESuggestType.newbuilding ? 'jk' : group;

      this.setGeoTag(this.createSuggestTag(params));
      this.resetSuggest();

      return Trackings.trackGeoSuggestSelectValue(trackInfo, 'cian');

      //яндекс саджест
    } else {
      this.setState({ loading: true, disabled: true });

      // suggest запрашиваем у Яндекс карт, а здесь получаем данные для выбранного пункта.
      // Получаем данные в два этапа:
      //  1. Запрашиваем geocodeCached у бекенда, чтобы получить kind и координаты
      //  2. Запрашиваем geocode у бекенда, чтобы получить id региона и геообъекта
      return toPromise(this.props.api.geocodeCached(title))
        .then(data => {
          const geoItem = data.result.items[0];
          if (!geoItem) {
            throw new Error('geo item is not found');
          }

          const coords = arrCoordsToLatLng(geoItem.coordinates);

          return toPromise(
            this.props.api.geocode({
              lat: coords.lat,
              lng: coords.lng,
              kind: geoItem.kind,
              address: geoItem.text.trim(),
            }),
          ).then(res => {
            const { result } = res;
            const objectDetails = result.details[result.details.length - 1];
            const type = objectDetails.geoType.toLowerCase().replace('road', 'highway');

            /**
             * Выбор нового значения в гео саджесте
             */
            Trackings.trackGeoSuggestSelectValue(type === 'location' ? 'region' : type, 'yandex');

            this.props.actions.setGeoTagsLoading(false);
            const tag = createGeoTag(geoItem, result);
            this.props.actions.addGeoTag(tag);
            this.resetSuggest();
          });
        })
        .catch(err => {
          this.props.actions.setGeoTagsLoading(false);
          this.props.actions.setError('Невозможно добавить тег', () => {
            this.onItemClick(params);
          });
          this.resetSuggest();
          throw err;
        });
    }
  };
}

export function mapStateToProps(state: ReduxState): IGeoSuggestStoreProps {
  const { filter, queryClient } = state;
  const inSuggestTwoStringExperiment = isAbUseExperimentActive(state.experiments, SUGGEST_TWO_LINES_MOB, 'B');

  return {
    filter,
    inSuggestTwoStringExperiment,
    queryClient,
  };
}

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps)(inject(['api'])(GeoSuggest));
