import { IGKData } from '../../../api/models/gk_card';
import { IImage } from '../../../api/models/gk_preview';
import { IPhoto } from '../model';

export const parsePhotos = (gk: IGKData): IPhoto[] =>
  gk.images.map((image: IImage) => ({
    miniUrl: image.listing_jk_mini,
    thumbnailUrl: image.thumbnail_url,
    thumbnail2Url: image.thumbnail2_url,
    fullUrl: image.full_url,
  }));
