import * as React from 'react';

import { ButtonCheckbox } from '@cian/components';
import ButtonCheckboxContainer from '../../../ui/button_checkbox_container/button_checkbox_container';
import { IFieldProps, IsVisibleCheck, withNewobjectSelected } from '../field';

const styles = require('./sale_type_newbuilding.css');
const fieldStyles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public shouldComponentUpdate(newProps: IFieldProps) {
    return this.props.filter.saleTypesNewbuilding !== newProps.filter.saleTypesNewbuilding;
  }

  public render() {
    const { filter: {
      saleTypesNewbuilding: {
        FZ214,
        ZhSK,
        cessionOfRights,
      },
    }} = this.props;

    const buttonCheckboxStyle = [
      fieldStyles.buttonCheckbox,
    ];

    return (
      <div className={styles.saleTypes} data-mark="FieldSaleTypeNewbuilding">
        <ButtonCheckboxContainer>
          <ButtonCheckbox
            mobile
            buttonCheckboxStyle={buttonCheckboxStyle}
            checked={FZ214}
            onChange={this.setSaleType}
            id="sale-type-214-fz"
            name="FZ214"
          >
            214-ФЗ
          </ButtonCheckbox>
          <ButtonCheckbox
            mobile
            buttonCheckboxStyle={buttonCheckboxStyle}
            checked={ZhSK}
            onChange={this.setSaleType}
            id="sale-type-zhsk"
            name="ZhSK"
          >
            Договор ЖСК
          </ButtonCheckbox>
          <ButtonCheckbox
            mobile
            buttonCheckboxStyle={buttonCheckboxStyle}
            checked={cessionOfRights}
            onChange={this.setSaleType}
            id="sale-type-rights"
            name="cessionOfRights"
          >
            Переуступка прав
          </ButtonCheckbox>
        </ButtonCheckboxContainer>
      </div>
    );
  }

  private setSaleType = (type: React.FormEvent<HTMLFormElement>) => {
    const { name, checked } = type.target as HTMLInputElement;
    this.props.actions.setSaleTypesNewbuilding(name, checked);
  }
}

export const isVisible: IsVisibleCheck = withNewobjectSelected;
