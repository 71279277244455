import { QueryClient } from '@tanstack/react-query';

import { IJsonQuery } from '../../api/models/json_query';
import { getOptionalRegionInfo } from '../../filters/field';
import { ReduxState } from '../model';

import { filterToJsonQuery } from './filter_to_json_query';

export function getNextJsonQuery(
  state: ReduxState,
  preferQueryStringFromLocation: boolean,
  isNewbuildingsMapMode: boolean,
  isCustomSpecialPromo: boolean,
  queryClient: QueryClient,
): IJsonQuery {
  const { filter } = state;

  return preferQueryStringFromLocation && state.searchList.initialized
    ? state.searchList.jsonQuery // для случая, когда переходим с выдачи на расширенные.
    : filterToJsonQuery(
        filter,
        {
          isNewbuildingsMapMode,
          isCustomSpecialPromo,
          optionalRegionInfo: getOptionalRegionInfo(state.regions.currentRegionInfo),
        },
        queryClient,
      ); // для случая, когда переходим с главной на выдачу.
}
