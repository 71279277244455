
//tslint:disable-next-line:no-any
export type ExceptionLogger = (ex: Error, options?: any) => Error;

const defaultExceptionLogger: ExceptionLogger = (ex: Error) => {
  if (typeof console !== 'undefined' && console.error) {
    console.error(ex);
  }
  return ex;
};

let currentLogger = defaultExceptionLogger;

export const setExceptionLogger = (logger: ExceptionLogger) => {
  currentLogger = logger;
};

//tslint:disable-next-line:no-any
const logException = (ex: Error, options?: any) => {
  if (typeof jest !== 'undefined') {
    return;
  }

  return currentLogger(ex, options);
};

export default logException;
