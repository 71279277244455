import * as React from 'react';

import { Tooltip } from '@cian/components';
import { IStyleConfig, mergeStyles } from '@cian/utils';
import NumberField from '../number_field/number_field';

const style = require('./range_input_group.css');

export interface IRangeInputProps {
  id?: string;
  name?: string;
  placeholder?: string;
  value?: number;
  minValue?: number;
  maxValue?: number;
  maxLength?: number;
  disableNegative?: boolean;
  disableFractional?: boolean;
  defaultValue?: number;
  onValueChange: (value: number) => void;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  error?: boolean;
  errorText?: string;
  inputType?: string;
  inputMode?: string;
  disablePrettyFormating?: boolean;
  pattern?: string;
  type?: IRangeType;
  containerStyle?: IStyleConfig;
  inputStyle?: IStyleConfig;
}

export declare type IRangeType = 'min' | 'max';

export interface IRangeInputGroupProps {
  min: IRangeInputProps;
  max: IRangeInputProps;
  rangeGroupStyle?: IStyleConfig;
  errorTextCommon?: string;
}

export interface IRangeInputGroupState {
  focused?: IRangeType;
}

export class RangeInputGroup extends React.Component<IRangeInputGroupProps, IRangeInputGroupState> {
  public static defaultProps = {
    min: {},
    max: {},
    errorTextCommon: 'Неверные значения',
  };

  private minNumberField: NumberField;
  private maxNumberField: NumberField;

  public constructor(props: IRangeInputGroupProps) {
    super(props);

    this.state = {};
  }

  public focusMinField() {
    if (this.minNumberField) {
      this.minNumberField.focus();
    }
  }

  public focusMaxField() {
    if (this.maxNumberField) {
      this.maxNumberField.focus();
    }
  }

  public renderField = (options: IRangeInputProps) => {
    return (
      <NumberField
        ref={(numberField: NumberField) => {
          if (options.type === 'min') {
            this.minNumberField = numberField;
          } else {
            this.maxNumberField = numberField;
          }
        }}
        {...options}
        value={options.value}
        type={options.inputType}
        disablePrettyFormating={options.disablePrettyFormating}
        containerStyle={[
          style.container,
          this.state.focused === options.type && style['focused'],
          options.error && style['error'],
        ]}
        inputStyle={[style['input'], options.inputStyle]}
      />
    );
  }

  public render() {
    const { min, max, rangeGroupStyle } = this.props;

    const rangeGroupStyles = mergeStyles(
      style['rangeGroup'],
      rangeGroupStyle,
    );

    let minField = this.renderField(Object.assign({}, min, {
      type: 'min',
      onFocus: this.handleFocusMin,
      onBlur: this.handleBlurMin,
      onValueChange: this.handleChangeMin,
    }));

    let maxField = this.renderField(Object.assign({}, max, {
      type: 'max',
      onFocus: this.handleFocusMax,
      onBlur: this.handleBlurMax,
      onValueChange: this.handleChangeMax,
    }));

    // if both fields with errors - wrap whole block with tooltip
    if (min.error && max.error) {
      const content = <div {...rangeGroupStyles}>
        {minField}
        {maxField}
      </div>;

      return this.wrapWithTooltip(content, '', true);
    }

    // otherwise wrap one of the fields
    minField = min.error ? this.wrapWithTooltip(minField, min.errorText || '') : minField;
    maxField = max.error ? this.wrapWithTooltip(maxField, max.errorText || '') : maxField;

    return (
      <div {...rangeGroupStyles}>
        {minField}
        {maxField}
      </div>
    );
  }

   private handleFocus = (id: IRangeType) => {
    // tslint:disable-next-line:no-any
    const fieldProps = (this.props as any)[id];

    if (fieldProps.onFocus) {
      fieldProps.onFocus();
    }

    this.setState({
      focused: id,
    });
  }

  private handleBlur = (id: IRangeType) => {
    // tslint:disable-next-line:no-any
    const fieldProps = (this.props as any)[id];

    if (fieldProps.onBlur) {
      fieldProps.onBlur();
    }

    this.setState({
      focused: undefined,
    });
  }

  private handleChange = (id: IRangeType, value: number) => {
    // tslint:disable-next-line:no-any
    const fieldProps = (this.props as any)[id];

    if (fieldProps.onValueChange) {
      fieldProps.onValueChange(value);
    }
  }

  private wrapWithTooltip = (node: React.ReactNode, errorText: string, common?: boolean) => {
    const { errorTextCommon } = this.props;

    if (!errorText) {
      errorText = 'Неверное значение';
    }

    return (
      <Tooltip
        arrow={false}
        thin
        multiline
        tooltipContent={common ? errorTextCommon || '' : errorText}
        tooltipStyles={[
          style.tooltip,
          common && style.wide,
        ]}
        position={2}
       >
          {node}
       </Tooltip>
    );
  }

  private handleFocusMin = () => this.handleFocus('min');
  private handleFocusMax = () => this.handleFocus('max');
  private handleBlurMin = () => this.handleBlur('min');
  private handleBlurMax = () => this.handleBlur('max');
  private handleChangeMin = (value: number) => this.handleChange('min', value);
  private handleChangeMax = (value: number) => this.handleChange('max', value);
}
