import {
  TLocationSwitcherActions,
  LOCATION_SWITCHER_OPEN,
  LOCATION_SWITCHER_CLOSE,
  LOCATION_SWITCHER_SET_CITIES_AND_REGIONS,
  LOCATION_SWITCHER_SET_GEO_BY_POINT,
  LOCATION_SWITCHER_SET_SELECTED_REGION_NAME,
  LOCATION_SWITCHER_SET_LOAD,
} from './actions';
import { IRegionInfo, IGetRegionsByPointItemSchema } from '../../api/api';

export interface ILocationSwitcherState {
  isOpen: boolean;
  isLoadingCitiesAndRegions: boolean;
  citiesAndRegions: IRegionInfo[];
  geoByPoint: IGetRegionsByPointItemSchema | undefined;
  selectedRegionName?: string;
}

const initialState: ILocationSwitcherState = {
  isOpen: false,
  isLoadingCitiesAndRegions: false,
  citiesAndRegions: [],
  geoByPoint: undefined,
};

export type Reducer = (state: ILocationSwitcherState, action: TLocationSwitcherActions) => ILocationSwitcherState;

export const LocationSwitcherReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_SWITCHER_OPEN:
      return { ...state, isOpen: true };

    case LOCATION_SWITCHER_CLOSE:
      return { ...state, isOpen: false };

    case LOCATION_SWITCHER_SET_CITIES_AND_REGIONS:
      return {
        ...state,
        citiesAndRegions: action.payload.citiesAndRegions,
      };

    case LOCATION_SWITCHER_SET_GEO_BY_POINT:
      return {
        ...state,
        geoByPoint: action.payload,
      };

    case LOCATION_SWITCHER_SET_SELECTED_REGION_NAME:
      return {
        ...state,
        selectedRegionName: action.payload,
      };

    case LOCATION_SWITCHER_SET_LOAD:
        return {
          ...state,
          isLoadingCitiesAndRegions: action.payload,
        };

    default:
      return state;
  }
};
