import { ca } from '@cian/analytics';

import { TNotificationFrequencyValue } from './ab/utils/notificationFrequency';

export type TSubscribePageType = 'cat' | 'card';

export function trackSuccessPopupShow(pageType: TSubscribePageType) {
  ca('event', {
    name: 'oldevent',
    category: 'subscription',
    action: 'show_success_popup',
    label: `From_${pageType}_save`,
  });
}

export function trackSuccessActivationPopupShow(pageType: TSubscribePageType) {
  ca('event', {
    name: 'oldevent',
    category: 'subscription',
    action: 'show_need_activation_popup',
    label: `From_${pageType}_save`,
  });
}

export function trackSubscribe(
  notificationFrequency: TNotificationFrequencyValue,
  isFromBanner: boolean,
  isFromFilter: boolean,
  queryString: string,
) {
  const tackNotificationFrequencyMap = {
    1: 'Hourly',
    2: 'Daily',
    3: 'Weekly',
  };

  const pageType = isFromFilter ? { pageType: 'Filter' } : { pageType: 'Listing', listingType: 'list' };

  ca('eventSite', {
    name: 'oldevent',
    category: 'subscription',
    action: 'send',
    label: 'saved_search',
    page: {
      ...pageType,
      extra: {
        period: tackNotificationFrequencyMap[notificationFrequency],
        banner: isFromBanner,
      },
      queryString: queryString
    },
  });
}

export function trackSubscribeWithFlag(pageType: TSubscribePageType) {
  ca('event', {
    name: 'oldevent',
    category: 'subscription',
    action: 'Send',
    label: `news_From_${pageType}_save`,
  });
}

export function trackNewAccountRegistration(pageType: TSubscribePageType) {
  ca('event', {
    name: 'oldevent',
    category: 'Registration',
    action: 'subscription',
    label: `From_${pageType}_save`,
  });
}
