/* eslint-disable max-lines */
/* @typescript-eslint/no-explicit-any */

import { EActiveTab, EActiveView } from '@cian/authentication-widget';
import { IWSUniformResourceIdentifierDeclaration } from '@cian/chats-api/lib/browser/websocket/uri';
import { ICommonHttpClassConfig } from '@cian/chats-api/lib/shared/http/common_class';
import '@cian/components/dist/frontend-components.css';
import { ILogger } from '@cian/logger';
import { UiKitContextProvider, uaToDeviceType } from '@cian/ui-kit';

import { History } from 'history';
import { get } from 'lodash';
import * as React from 'react';
import { Provider } from 'react-redux';
import {
  IndexRouteProps,
  RedirectFunction,
  Route,
  RouteComponent,
  Router,
  RouterState,
  IndexRoute as _IndexRoute,
  applyRouterMiddleware,
} from 'react-router';
import { routerMiddleware, syncHistoryWithStore } from 'react-router-redux';
import { useScroll } from 'react-router-scroll';
import { DeepPartial, Store, applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import {
  getHost,
  getPathname,
  getQueryString,
  getSubdomain,
  getSubdomain as getSubdomainFromString,
  makeRedirectRes,
  replaceSubdomain,
} from '../mobile_website/utils/location';
import { MobileAppBannerContainerLoadable } from '../shared/components/MobileAppBanner/MobileAppBannerContainerLoadable';
import { ServerSideStateProvider } from '../shared/components/ServerSideStateProvider';
import { CatProfilingModalContainerLoadable } from '../shared/containers/CatProfilingModalContainer/CatProfilingModalContainerLoadable';
import { IServerSideState } from '../shared/services/ServerSideState';
import { ApplicationContext, ApplicationContextModel } from '../shared/utils/applicationContext';
import { getIsNewAuthEnabled } from '../shared/utils/isNewAuthEnabled';

import { IGetOfferChatsEnables, IRegionInfo, MobileWebsiteApi, TDealType, TOfferType } from './api/api';
import { ErrorHandlerLogger } from './components/errors_handler_logger';
import Layout from './components/layout/layout_route';
import { setAuthPageDrawPending } from './redux/auth_page/actions';
import { IOfferChatsIsEnabledAction, chatsMessageSent, offerChatsIsEnabledAction } from './redux/chats/actions';
import { checkRedirectData, getHeaderData, setFirstClientLoad } from './redux/common/actions';
import { ResourceStatus } from './redux/common/model';
import { fetchGaDataLayer } from './redux/data_layer';
import { clearFavoritesCountByOffer } from './redux/favoritesCountByOffer';
import {
  getAdvancedFiltersPageInitials,
  getInitialFilters,
  getOfferCount,
  getRegionSettings,
  setWithNewobject,
} from './redux/filters/actions';
import { FiltersSection } from './redux/filters/model';
import { getGK, getMortgageCalculatorWidget } from './redux/gk_page/actions';
import { EHideOfferState, toggleTopPopup } from './redux/hide_offer/actions';
import { getGeoSeoLinks } from './redux/main_page/geo_seo_links/actions';
import { getPromoBlocks } from './redux/main_page/promo_blocks/actions';
import { getSeoMain } from './redux/main_page/seo_main/actions';
import { IReduxStore, IThunkExtraArgument, ReduxState } from './redux/model';
import { getOfferInfo, getPriceSubcriptionStatusAction } from './redux/offer/actions';
import { IBaseInfo, IValueAddedServices } from './redux/offer/model';
import { TOffersListActions, resetAndSearchOffersByUrl } from './redux/offers_list/actions';
import { trackPageviewListing } from './redux/offers_list/analytics';
import { removePopupParams } from './redux/popups/actions';
import reducer from './redux/reducer';
import { getRegionInfo, getRegions } from './redux/regions/actions';
import { getResetPasswordData } from './redux/reset_password';
import { setPageviewSent, setRouteName } from './redux/routing/actions';
import { setSerpScrollPosition } from './redux/scroll_position';
import { clearSpecialPromo, setSpecialPromo } from './redux/special_promo/actions';
import { setSpecialPromosInfo } from './redux/special_promos_info/actions';
import { markTransition, tryMeasureTransition } from './redux/transition_metrics/actions';
import { setSuccessLogonUrl } from './redux/users/actions';
import * as analytics from './utils/analytics';
import {
  PageType,
  getHeadline,
  getOfferVAS,
  getSiteType,
  getTrackingLocation,
  newPageQueueHandler,
  showMLSuggestionsInListing,
} from './utils/analytics';
import { mlSearchSession } from './utils/analytics/mlSearchSession';
import { AppContextProvider, IAppContext } from './utils/context_provider';
import { CookiesUtils } from './utils/cookies';
import { finishDateCheckRedirect } from './utils/finish_date_check_redirect';
import { formatPathname } from './utils/formatters';
import { getOfferMeta } from './utils/getOfferMeta';
import { GKUrlCheckRedirect } from './utils/gk_url_check_redirect';
import { isClient, isServer } from './utils/isomorphic';
import { IMetaData, setMetaData } from './utils/meta';
import {
  FiltersSections,
  OFFER_CARD_ROUTE_REGEXP,
  isOneOfRoutes,
  isRouteFor,
  routeFor,
  routesFor,
} from './utils/routeFor';
import { getReturnUrl, isUrlLkPromoCode, parseQueryString } from './utils/url';

import './styles/app.css';

// Отключаем типизацию IndexRoute, потому что в типе нет onChange
const IndexRoute = _IndexRoute as any;

const cookiesUtils = new CookiesUtils();

export interface IParams {
  id?: string;
  dealType?: string;
  objectType?: string;
}

export function createReduxStore(
  api: MobileWebsiteApi,
  logger: ILogger,
  history: History,
  initialState: DeepPartial<ReduxState>,
): IReduxStore {
  const thunkExtraArgument: IThunkExtraArgument = { api, logger };

  const middlewares = [routerMiddleware(history), thunk.withExtraArgument(thunkExtraArgument)].filter(Boolean);

  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  return createStore(reducer, initialState as any, composeEnhancers(applyMiddleware(...middlewares)));
}

// Пометить все новые офферы в сохраненном поиске как просмотренные
function readAllOffersForSavedSearch(api: MobileWebsiteApi, originQueryString: string): void {
  const queryString = parseQueryString(originQueryString);

  if (queryString && queryString['saved_search_id']) {
    api.readAllOffersForSavedSearch({
      savedSearchId: Number(queryString['saved_search_id']),
    });
  }
}

/**
 * Получение FB_Region и FB_City для отправки в СОПР на выдаче и карточке объекта.
 */
function getTrackingCurrentLocation(
  currentRegion: IRegionInfo | null,
  regions: IRegionInfo[] | null,
): analytics.ITrackingLocation | undefined {
  if (currentRegion && regions) {
    return getTrackingLocation(currentRegion, regions);
  }

  return undefined;
}

// get routes

function noop(...args: any[]): void {}

interface IOnRouteEnterOptions {
  fetchDataP: Promise<any>;
  client?(): Promise<void> | void;
  server?(): Promise<void> | void;
  cb(err?: Error): void;
}

function onRouteEnter({ fetchDataP, client, server, cb }: IOnRouteEnterOptions, logger: ILogger): Promise<void> {
  // На клиенте сразу отдаём страницу
  if (isClient) {
    cb();
  }

  return fetchDataP
    .then(() => {
      if (!isClient) {
        return;
      }
      if (client) {
        return client();
      }
    })
    .then(() => {
      if (!isServer) {
        return;
      }
      if (server) {
        const serverP = server();
        if (!serverP) {
          return;
        }

        return serverP
          .then(() => {
            cb();
          })
          .catch(cb);
      } else {
        cb();

        return;
      }
    })
    .catch(e => {
      logger.error(e);

      cb(e);
    });
}

export interface IRequest {
  header: (str: string) => string | undefined;
}

export function getRoutes(
  history: History,
  url: string,
  api: MobileWebsiteApi,
  chatsConfig:
    | {
        httpConfig: ICommonHttpClassConfig;
        wsApiBaseUrl: IWSUniformResourceIdentifierDeclaration;
        timeout: number;
      }
    | undefined,
  reduxStore: IReduxStore,
  logger: ILogger,
  req?: IRequest,
): JSX.Element {
  const onLeave = (prevState: RouterState): void => {
    if (typeof window !== 'undefined' && Object.defineProperty != null) {
      try {
        const { pathname, search } = prevState.location;

        if ((window as any).customReferrer == null) {
          Object.defineProperty(document, 'referrer', {
            get() {
              return (window as any).customReferrer;
            },
          });
        }
        (window as any).customReferrer = location.origin + pathname + search;
        newPageQueueHandler.clean();
      } catch (e) {
        logger.error(e);
      }
    }
  };

  const syncedHistory = syncHistoryWithStore(history, reduxStore);

  const headerSubdomain = req ? req.header('X-GeoSubdomain') || req.header('X-Subdomain') : '';
  const { isNewbuildingSubdomain, geoSubdomain } = reduxStore.getState().common;

  const subdomain = !!headerSubdomain && headerSubdomain.length ? headerSubdomain : geoSubdomain || getSubdomain(url);

  const regionsPromise = reduxStore.dispatch(getRegions(subdomain));

  const isNewAuthEnabled = getIsNewAuthEnabled();

  if (isClient) {
    reduxStore.dispatch(setFirstClientLoad(true));
  }

  function fetchCommonData(): Promise<void> {
    const {
      regions,
      dataLayer,
      common: { authInfoInitialized, requestUrl },
    } = reduxStore.getState();
    let customGetHeaderHost = '';
    if (isNewbuildingSubdomain) {
      const mainHost =
        getHost(requestUrl || '')
          .split('.')
          .slice(1)
          .join('.') || 'cian.ru';
      customGetHeaderHost = `https://${geoSubdomain}.${mainHost}`;
    }

    return Promise.all([
      !dataLayer.initialized ? reduxStore.dispatch(fetchGaDataLayer()) : Promise.resolve(),
      regions.status === ResourceStatus.Initial ? regionsPromise : Promise.resolve(),
      !authInfoInitialized ? reduxStore.dispatch(getHeaderData(customGetHeaderHost)) : Promise.resolve(),
      reduxStore.dispatch(setSpecialPromosInfo(subdomain)),
    ]).then(noop);
  }

  function fetchSettings(region: number): Promise<void> {
    const {
      filter: { regionSettings },
      logger,
    } = reduxStore.getState();

    const settingInitialized = regionSettings && regionSettings.initialized;

    return !settingInitialized ? reduxStore.dispatch(getRegionSettings(subdomain, region, logger)) : Promise.resolve();
  }

  function fetchSettingsByFilter(): Promise<void> {
    const { region } = reduxStore.getState().filter;

    return fetchSettings(region);
  }

  function fetchOfferChatSetting(
    params: IGetOfferChatsEnables,
    timeout: number,
  ): Promise<(IOfferChatsIsEnabledAction | void)[]> {
    const { dispatch } = reduxStore;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { logger } = reduxStore.getState();
    const { offerId } = params;

    return Promise.all([
      api
        .getOfferChatsStatus({ params, timeout, logger })
        .catch(() => {
          return false;
        })
        .then(isOfferChatsEnabled => dispatch(offerChatsIsEnabledAction({ enabled: isOfferChatsEnabled }))),
      api
        .getOfferChat({ offerId, timeout, logger })
        .catch(() => {
          return null;
        })
        .then(chatId => {
          if (chatId) {
            dispatch(chatsMessageSent());
          }
        }),
    ]);
  }

  function fetchFilterState(search: string, filtersSectionName: FiltersSection): Promise<void> {
    const { dispatch } = reduxStore;
    const qs = getQueryString(removePopupParams(search));

    // всегда переинициализируем фильтры при смене url, чтобы при переходе
    // по ссылкам внутри сайта (например, по лого в шапке) состояние фильтров
    // обновлялось
    return dispatch(getInitialFilters(qs, regionsPromise, filtersSectionName, subdomain)).then(() =>
      dispatch(getOfferCount({})),
    );
  }

  function fetchAdvancedSearchFiltersState(search: string, filtersSectionName: FiltersSection): Promise<void> {
    const { dispatch } = reduxStore;
    const qs = getQueryString(removePopupParams(search));

    return dispatch(getAdvancedFiltersPageInitials(qs, regionsPromise, filtersSectionName, subdomain)).then(() =>
      dispatch(getOfferCount({})),
    );
  }

  function fetchAdvancedSearchPageData(search: string): Promise<void> {
    const { seoMain } = reduxStore.getState();

    return Promise.all([
      fetchCommonData(),
      seoMain.status === ResourceStatus.Initial ? reduxStore.dispatch(getSeoMain()) : Promise.resolve(),
      fetchAdvancedSearchFiltersState(search, FiltersSection.Index).then(fetchSettingsByFilter),
    ]).then(noop);
  }

  function noMetaData(): IMetaData {
    return {};
  }

  function getDefaultMetaData(routeState: RouterState): IMetaData {
    const { seoMain } = reduxStore.getState();
    const noindex = !!routeState.location.search.length;

    return {
      title: seoMain.title || 'ЦИАН – база недвижимости | Продажа, аренда квартир и другой недвижимости',
      description: seoMain.description,
      noindex,
    };
  }

  function getOnMainRouteChange(filtersSectionName: FiltersSection) {
    return (prevState: RouterState, nextState: RouterState) => {
      const { search } = nextState.location;
      const popupWillOpenedOrClosed = prevState.location.search.includes('_popup') || search.includes('_popup');
      if (prevState.location.search !== search && !popupWillOpenedOrClosed) {
        fetchFilterState(search, filtersSectionName).then(() => {
          setMetaData(getDefaultMetaData(nextState));
        });
      }
    };
  }

  function sendPageAnalitics(pageType: 'Home' | 'Filter'): void {
    const { dataLayer, filter } = reduxStore.getState();

    if (dataLayer.initialized) {
      analytics.trackBasePageview(
        dataLayer.data,
        {
          pageType,
        },
        filter.region,
      );
    }
  }

  const mainRoute = {
    getMetaData: getDefaultMetaData,

    fetchData(nextState: RouterState, section: FiltersSection): Promise<void> {
      const search = nextState.location.search;
      const { dispatch } = reduxStore;
      const {
        promoBlocks,
        seoMain,
        geoSeoLinks,
        filter: { section: currentSection },
      } = reduxStore.getState();

      return Promise.all([
        fetchCommonData(),
        currentSection !== section || promoBlocks.length === 0
          ? dispatch(getPromoBlocks(section, logger))
          : Promise.resolve(),
        seoMain.status === ResourceStatus.Initial || currentSection !== section
          ? dispatch(getSeoMain(section))
          : Promise.resolve(),
        geoSeoLinks.length === 0 && subdomain === 'www' ? dispatch(getGeoSeoLinks()) : Promise.resolve(),
        mainRoute.isNewFilterUrl(nextState)
          ? fetchFilterState(search, section).then(fetchSettingsByFilter)
          : Promise.resolve(),
      ]).then(noop);
    },

    isNewFilterUrl(nextState: RouterState): boolean {
      const { pathname, search } = nextState.location;
      const nextPath = pathname + search;
      const lastFilterUrl = reduxStore.getState().filter.lastUrl;

      return nextPath !== lastFilterUrl;
    },

    getOnEnter(filtersSectionName: FiltersSection) {
      return (nextState: RouterState, replace: RedirectFunction, cb: (err?: Error) => void) => {
        const state = reduxStore.getState();

        if (nextState.location.pathname === '/' && typeof window !== 'undefined') {
          window.location.reload();
        }

        const routeName = state.routing.routeName;

        reduxStore.dispatch(setRouteName('main'));
        if (isClient) {
          reduxStore.dispatch(markTransition('Home'));
        }

        onRouteEnter(
          {
            fetchDataP: mainRoute.fetchData(nextState, filtersSectionName),
            client() {
              const pageType: PageType = 'Home';
              setMetaData(getDefaultMetaData(nextState));
              sendPageAnalitics(pageType);
              newPageQueueHandler.fire();
              reduxStore.dispatch(tryMeasureTransition(pageType));

              const sessionCookieRegionId = cookiesUtils.get('session_region_id');
              const foreverCookieRegionId = cookiesUtils.get('forever_region_id');

              if (foreverCookieRegionId && routeName !== 'main' && !sessionCookieRegionId) {
                getRegionInfo(api, foreverCookieRegionId)
                  .then(regionInfo => {
                    const currentSubdomain = getSubdomainFromString(window.location.href);
                    const requiredSubdomain = getSubdomainFromString(regionInfo.baseHost || '');

                    if (requiredSubdomain !== currentSubdomain) {
                      window.location.replace(replaceSubdomain(window.location.href, requiredSubdomain));
                    }
                  })
                  .catch(err => {
                    logger.error(err);
                  });
              }

              window.scrollTo(0, 0);
            },
            cb,
          },
          logger,
        );
      };
    },
  };

  const advancedSearchRoute = {
    getMetaData: getDefaultMetaData,

    onEnter(nextState: RouterState, replace: RedirectFunction, cb: (err?: Error) => void): void {
      const { newbuildings_search: newBuildingsSearchQuery } = nextState.location.query;
      const setNewObject: VoidFunction = () => {
        if (newBuildingsSearchQuery === 'true') {
          reduxStore.dispatch(setWithNewobject(true));
        }
      };

      const { isNewFiltersAvailable } = reduxStore.getState();
      if (isNewFiltersAvailable && isClient) {
        const { offer_type: offerType, 'office_type[0]': officeType } = nextState.location.query;
        if (offerType !== 'offices' || officeType === '6') {
          window.location.reload();

          return;
        }
      }

      reduxStore.dispatch(setRouteName('advancedSearch'));

      onRouteEnter(
        {
          fetchDataP: fetchAdvancedSearchPageData(nextState.location.search),
          client() {
            setNewObject();
            setMetaData(getDefaultMetaData(nextState));
            sendPageAnalitics('Filter');
            newPageQueueHandler.fire();
            window.scrollTo(0, 0);
          },
          cb,
        },
        logger,
      );
    },
  };

  const offersListRoute = {
    getMetaData(routeState: RouterState): IMetaData {
      const { searchList } = reduxStore.getState();
      const noindex = !!routeState.location.search.length;

      return {
        title: searchList.initialized ? searchList.seoData.title : '',
        description: searchList.initialized ? searchList.seoData.metaDescription : '',
        canonical: searchList.initialized ? searchList.seoData.canonical : '',
        noindex,
      };
    },

    sendAnalitics(): void {
      const {
        searchList,
        dataLayer,
        regions,
        common: { deviceType },
      } = reduxStore.getState();

      const currentRegion = regions.currentRegionInfo || regions.regionMeta.location;
      const trackingLocation = getTrackingCurrentLocation(currentRegion, regions.regionsInfo);

      trackPageviewListing(searchList, dataLayer, { trackingLocation, sendProducts: true });

      /**
       * Аналитика при первом рендере первой страницы бесконечной выдачи
       */
      if (searchList.initialized) {
        const suggestions =
          searchList.suggestionsMl && searchList.suggestionsMl.suggestions ? searchList.suggestionsMl.suggestions : [];

        const { jsonQuery, breadcrumbs, queryString } = searchList;

        const suggestionPageNumber =
          searchList.suggestionsMl && searchList.suggestionsMl.pageNumber ? searchList.suggestionsMl.pageNumber : 0;

        const offersQty =
          searchList.initialized && searchList.seoData.description
            ? parseInt(searchList.seoData.description.replace(' ', ''), 10)
            : 0;

        if (suggestions.length > 0) {
          const user = {
            abGroup: dataLayer.initialized && dataLayer.data.abGroup ? Number(dataLayer.data.abGroup) : 100,
            userId:
              dataLayer.initialized && dataLayer.data.realtyUserId ? Number(dataLayer.data.realtyUserId) : undefined,
            isAuthorized: Boolean(dataLayer.initialized && dataLayer.data.realtyUserId),
          };

          showMLSuggestionsInListing(
            jsonQuery,
            suggestions,
            Number(suggestionPageNumber),
            breadcrumbs.map(breadcrumb => breadcrumb.title),
            queryString,
            offersQty,
            deviceType === 'phone' ? 'mobile' : deviceType,
            user,
          );
        }
      }
    },

    shouldUpdateSearchOffers(nextState: RouterState): boolean {
      const { pathname, search } = nextState.location;
      const nextPath = removePopupParams(pathname + search);
      const { lastUrl: lastListingUrl, initialized } = reduxStore.getState().searchList;

      if (lastListingUrl && lastListingUrl.includes('is_from_ad')) {
        return !initialized;
      }

      return nextPath !== lastListingUrl || !initialized;
    },

    onChange(prevState: RouterState, nextState: RouterState): void {
      if (!offersListRoute.shouldUpdateSearchOffers(nextState)) {
        return;
      }

      const { pathname, search } = nextState.location;

      const { searchList } = reduxStore.getState();

      if (searchList.initialized) {
        mlSearchSession.updateSession(searchList.jsonQuery);
      }

      reduxStore.dispatch(resetAndSearchOffersByUrl(subdomain, pathname + search, regionsPromise)).then(() => {
        setMetaData(offersListRoute.getMetaData(nextState));
        offersListRoute.sendAnalitics();
      });
    },

    onEnter(nextState: RouterState, replace: RedirectFunction, cb: (err?: Error) => void): void {
      // если в запросе есть параметр finish_date, меняем его на year или yeargte
      finishDateCheckRedirect(nextState, replace);

      const { pathname, search, query } = nextState.location;
      const {
        seoMain,
        routing,
        searchList,
        hideOffer: { hideOfferState, hidingOffer },
      } = reduxStore.getState();
      const lastRouteName = routing.routeName;
      const shouldUpdateSearchOffers = offersListRoute.shouldUpdateSearchOffers(nextState);

      if (searchList.initialized) {
        mlSearchSession.init(searchList.jsonQuery);
      }

      reduxStore.dispatch(setRouteName('listing'));

      if (isClient) {
        reduxStore.dispatch(markTransition('Listing'));
        reduxStore.dispatch(toggleTopPopup(false));
        //скрыть объявление на выдаче если оно было скрыто на карточке
        if (hidingOffer && hideOfferState === EHideOfferState.SUCCESS) {
          reduxStore.dispatch<TOffersListActions>({ type: 'SetHideOffer', payload: hidingOffer });
        }
      }

      const specialPromoP = shouldUpdateSearchOffers
        ? query['builder'] && query['is_special_promo']
          ? reduxStore.dispatch(setSpecialPromo(Number(query['builder'])))
          : reduxStore.dispatch(clearSpecialPromo() as any)
        : Promise.resolve();

      const searchOffersP = shouldUpdateSearchOffers
        ? reduxStore.dispatch(resetAndSearchOffersByUrl(subdomain, pathname + search, regionsPromise))
        : Promise.resolve();

      const searchOffersPError = searchOffersP.catch(() => ({ notFound: true }));

      onRouteEnter(
        {
          fetchDataP: Promise.all([
            fetchCommonData(),
            searchOffersPError,
            specialPromoP,
            Object.keys(seoMain).length === 0 ? reduxStore.dispatch(getSeoMain()) : Promise.resolve(),
          ]),
          client() {
            setMetaData(offersListRoute.getMetaData(nextState));
            offersListRoute.sendAnalitics();
            newPageQueueHandler.fire();
            const state = reduxStore.getState();
            const { scrollPosition } = state;
            const { lastUrl } = state.common;

            const lastPathName = lastUrl && getPathname(lastUrl);

            const fromFilterPage = lastPathName && isOneOfRoutes(['INDEX'], lastPathName);

            if (fromFilterPage || shouldUpdateSearchOffers) {
              window.scrollTo(0, 0);

              return;
            }

            if (
              scrollPosition.serpScrollPosition &&
              ((lastPathName && OFFER_CARD_ROUTE_REGEXP.test(lastPathName)) || lastRouteName === 'advancedSearch')
            ) {
              window.scrollTo(0, scrollPosition.serpScrollPosition);
            }

            const queryString = window.location.search.substr(1);
            window.addEventListener('beforeunload', () => readAllOffersForSavedSearch(api, queryString));
          },
          cb,
        },
        logger,
      );
    },

    onLeave(prevState: RouterState): void {
      onLeave(prevState);

      const { search } = prevState.location;

      if (typeof window !== 'undefined') {
        readAllOffersForSavedSearch(api, search);

        reduxStore.dispatch(setSerpScrollPosition(window.pageYOffset));
      }
    },
  };

  const cardRoute = {
    getMetaData(): IMetaData {
      return getOfferMeta(reduxStore.getState().offerPage.offer);
    },

    sendAnalitics(): void {
      const { dataLayer, offerPage, regions, routing, favoritesCountByOffer } = reduxStore.getState();
      const newOffer = offerPage.offer;

      // Когда перешли на карточку аяксом, pageview отправляется сразу внутри onEnter карточки
      if (routing.isPageviewSent) {
        return;
      }

      const baseInfo: IBaseInfo = get(newOffer, 'baseInfo', {} as IBaseInfo);
      const valueAddedServices: IValueAddedServices | undefined = get(newOffer, 'valueAddedServices', undefined);

      const countryCode = get(newOffer, 'agentInfo.contacts[0].countryCode', '');
      const number = get(newOffer, 'agentInfo.contacts[0].number', '');
      const offerPhone = `${countryCode}${number}`;
      const region = newOffer.gaLabel ? analytics.getRegionFromGaLabel(newOffer.gaLabel) : '';
      const customPageURL = newOffer.gaLabel || 'ga label not found';

      const offerJKID = get(newOffer, 'addressInfo.jk.id') as string;
      const offerKPID = get(newOffer, 'kp.id') as string;

      // just typecheck, always initialized here
      if (dataLayer.initialized) {
        const gaObjectType = analytics.getObjectTypeForOffer(newOffer);
        const gaDealType = analytics.getDealTypeForOffer(newOffer.urlParams.dealType, newOffer.baseInfo.rentTime);

        const currentRegion = regions.currentRegionInfo || regions.regionMeta.location;
        const trackingLocation = getTrackingCurrentLocation(currentRegion, regions.regionsInfo);

        const variants = getOfferVAS(baseInfo, valueAddedServices);

        const parentId = ((): string | number | undefined => {
          if (gaObjectType === 'suburban') {
            return offerKPID;
          }

          if (newOffer.baseInfo.coworkingOfferType && newOffer.baseInfo.coworking) {
            return newOffer.baseInfo.coworking.id;
          }

          return offerJKID;
        })();

        const extra = ((): { parentId?: number; favoritesCount?: number } | undefined => {
          const _extra = {} as { parentId?: number; favoritesCount?: number };

          if (parentId) {
            _extra.parentId = Number(parentId);
          }

          if (
            favoritesCountByOffer.favoritesUsersCountForCard &&
            favoritesCountByOffer.favoritesUsersCountForCard.total
          ) {
            _extra.favoritesCount = favoritesCountByOffer.favoritesUsersCountForCard.total;
          }

          if (Object.keys(_extra).length === 0) {
            return undefined;
          }

          return _extra;
        })();

        const products = [
          {
            id: newOffer.id,
            cianId: newOffer.cianId || newOffer.id,
            parentId: Number(parentId) || undefined,
            headline: getHeadline(newOffer),
            ownerId: newOffer.agentInfo ? newOffer.agentInfo.id : undefined,
            ownerCianId: newOffer.agentInfo ? newOffer.agentInfo.agentId : undefined,
            objectType: gaObjectType,
            dealType: gaDealType,
            position: 1,
            published: Boolean(!newOffer.removalPublication),
            price: Number(newOffer.offerAnalyticsInfo.price || newOffer.baseInfo.price) || undefined,
            variant: variants as string[],
            brand: newOffer.offerAnalyticsInfo.brand || '',
            photosCount: newOffer.photos.length,
            hiddenBase: Boolean(newOffer.isInHiddenBase),
            owner: Boolean(newOffer.isByHomeowner),
            podSnos: Boolean(newOffer.demolishedInMoscowProgramm),
            consultant: newOffer.isDealRequestSubstitutionPhone,
            extra,
          },
        ];

        const page = {
          breadCrumbs: offerPage.breadcrumbs && offerPage.breadcrumbs.map(b => b.title),
          pageType: 'Card' as PageType,
          siteType: getSiteType(),
          offerID: newOffer.offerAnalyticsInfo.id ? Number(newOffer.offerAnalyticsInfo.id) || newOffer.id : undefined,
          offerPhone,
          objectType: gaObjectType,
          dealType: gaDealType,
          customPageURL,
          region: String(region || currentRegion.id),
          offerType: (gaObjectType === 'JK' ? 'JK' : 'offer') as analytics.TOfferType,
        };

        analytics.sendInitPageDataForOfferPage(dataLayer.data, page, products, newOffer, trackingLocation);

        analytics.sendEventToGoogleAnalytics('set', 'dimension14', dataLayer.data.abGroup); // АБ-группа
        analytics.sendEventToGoogleAnalytics('send', 'pageview', customPageURL, {
          dimension10: region, // регион
          dimension11: gaDealType, // тип сделки
          dimension12: gaObjectType, // тип объекта
          dimension13: 'card',
        });

        reduxStore.dispatch(setPageviewSent(true));
      }
    },

    onEnter(nextState: RouterState, replace: RedirectFunction, cb: (err?: Error) => void): void {
      const { seoMain, offerPage, routing } = reduxStore.getState();
      const { dealType, objectType, id } = nextState.params;

      /**
       * Нужно для того чтобы не делать запрос в апи /v1/offers/:dealType/:offerType/:id,
       * когда не валидные параметры в url.
       */
      if (routing.routeName === '404') {
        return;
      }

      reduxStore.dispatch(setRouteName('offerCard'));

      if (isClient) {
        reduxStore.dispatch(markTransition('Card'));
        reduxStore.dispatch(toggleTopPopup(false));
      }

      const { dispatch } = reduxStore;
      const isNewId = Number(id) !== offerPage.offer.urlParams.id;
      if (isNewId && isClient) {
        window.scrollTo(0, 0);
      }
      const offerPromise = isNewId
        ? dispatch(getOfferInfo(dealType, objectType, id)).then(() => {
            const {
              offerPage: { isNotFoundOffer },
            } = reduxStore.getState();

            /**
             * Если offer не найден, не делаем лишние запросы за чатами и тд.
             */
            if (isNotFoundOffer) {
              return Promise.resolve() as any;
            }

            const getOfferChatsParams = {
              offerId: Number(id),
              dealType: dealType as TDealType,
              offerType: objectType as TOfferType,
            };

            return Promise.all([
              fetchSettingsByFilter(),
              fetchOfferChatSetting(getOfferChatsParams, chatsConfig ? chatsConfig.timeout : 1000),
            ]);
          })
        : Promise.resolve();

      onRouteEnter(
        {
          fetchDataP: Promise.all([
            fetchCommonData(),
            seoMain.status === ResourceStatus.Initial ? dispatch(getSeoMain()) : Promise.resolve(),
            offerPromise,
            dispatch(getPriceSubcriptionStatusAction(id)),
          ]),
          client() {
            setMetaData(cardRoute.getMetaData());

            const trackPageView: VoidFunction = () => {
              cardRoute.sendAnalitics();
              newPageQueueHandler.fire();
            };

            const sendAnalytics: VoidFunction = () => {
              trackPageView();
            };

            sendAnalytics();
          },
          cb,
        },
        logger,
      );
    },

    onLeave: (prevState: RouterState): void => {
      onLeave(prevState);
      reduxStore.dispatch(clearFavoritesCountByOffer());

      /**
       * Сбрасываем флаг "pageview отправленно", чтобы
       * при повторном заходе на карточку pageview отправлялось заново.
       */
      reduxStore.dispatch(setPageviewSent(false));

      reduxStore.dispatch({
        type: 'NotFoundOffer',
        value: false,
      });
    },
  };

  const exampleErrorRoute = {
    getMetaData(renderProps: RouterState): IMetaData {
      return {};
    },

    onEnter(nextState: RouterState, replace: RedirectFunction, cb: (err?: Error) => void): void {
      cb(new Error('Example error'));
    },
  };

  const gkRoute = {
    getMetaData(): IMetaData {
      const { GKPage } = reduxStore.getState();
      const { GK, seoData } = GKPage;

      return {
        ...seoData,
        noindex: GK && GK.is_test,
      };
    },

    sendAnalytics(): void {
      const { GKPage, dataLayer } = reduxStore.getState();

      const pageTrackingData = GKPage.GK.analytics;

      if (dataLayer.initialized && pageTrackingData) {
        const { offerID } = pageTrackingData;
        const { name, contacts, is_reliable, is_deal_request_substitution_phone } = GKPage.GK;

        const products = [
          {
            id: Number(offerID),
            dealType: 'other',
            objectType: 'JK',
            position: 1,
            name: name || undefined,
            brand: contacts && contacts.length > 0 ? contacts[0].phone_data_layer.brand : undefined,
            nv: Boolean(is_reliable),
            consultant: is_deal_request_substitution_phone,
          },
        ];

        analytics.sendInitPageDataForGKPage(dataLayer.data, pageTrackingData, products);
      }
    },

    onEnter(nextState: RouterState, replace: RedirectFunction, cb: (err?: Error) => void): void {
      const { dispatch } = reduxStore;

      dispatch(setRouteName('gkCard'));

      if (isClient) {
        dispatch(markTransition('Card_JK'));
      }
      const { pathname, query } = nextState.location;
      const formatedPathname = formatPathname(pathname);
      const newbuildingPath =
        isNewbuildingSubdomain && formatedPathname === '/' ? reduxStore.getState().GKPage.GK.url : formatedPathname;

      const { utm_source, utm_medium, utm_campaign, calltest } = query;

      onRouteEnter(
        {
          fetchDataP: Promise.all([
            fetchCommonData(),
            fetchSettingsByFilter(),
            dispatch(
              getGK({
                _path: newbuildingPath,
                _subdomain: subdomain,
                utm_source,
                utm_medium,
                utm_campaign: parseInt(utm_campaign, 10) || undefined,
                calltest: parseInt(calltest, 10) || undefined,
              }),
            ),
          ])
            .then(() => {
              // При переходе на URL карточки ЖК делаем редирект на новый урл, если он есть
              if (!isClient) {
                GKUrlCheckRedirect(newbuildingPath, reduxStore.getState(), replace);
              }
            })
            .then(() => dispatch(getMortgageCalculatorWidget()))
            .then(noop),
          client() {
            setMetaData(gkRoute.getMetaData());
            gkRoute.sendAnalytics();
          },
          cb,
        },
        logger,
      );
    },
  };

  const myOffersRoute = {
    getMetaData(): IMetaData {
      return {
        title: 'ЦИАН – Мои объявления',
      };
    },

    onEnter(nextState: RouterState, replace: RedirectFunction, cb: (err?: Error) => void): void {
      reduxStore.dispatch(setRouteName('myOffers'));
      onRouteEnter(
        {
          fetchDataP: fetchCommonData().then(fetchSettingsByFilter).then(noop),
          client() {
            myOffersRoute.sendPageAnalitics();
            newPageQueueHandler.fire();
            setMetaData(myOffersRoute.getMetaData());
          },
          cb,
        },
        logger,
      );
    },

    sendPageAnalitics(): void {
      const { dataLayer, filter } = reduxStore.getState();
      if (dataLayer.initialized) {
        analytics.sendInitPageDataForOtherPage(dataLayer.data, {
          pageType: 'MyOffers',
          region: filter.region,
        });
      }
    },
  };

  const addAdvertPromoRoute = {
    getMetaData(): IMetaData {
      return {
        title: 'ЦИАН – Официальное мобильное приложение',
      };
    },

    onEnter(nextState: RouterState, replace: RedirectFunction, cb: (err?: Error) => void): void {
      reduxStore.dispatch(setRouteName('advertPromo'));

      onRouteEnter(
        {
          fetchDataP: fetchCommonData().then(fetchSettingsByFilter).then(noop),
          client() {
            addAdvertPromoRoute.sendPageAnalitics();
            newPageQueueHandler.fire();
            setMetaData(addAdvertPromoRoute.getMetaData());
          },
          cb,
        },
        logger,
      );
    },

    sendPageAnalitics(): void {
      const { dataLayer, filter } = reduxStore.getState();
      if (dataLayer.initialized) {
        analytics.sendInitPageDataForOtherPage(dataLayer.data, {
          pageType: 'Other',
          region: filter.region,
        });
      }
    },
  };

  const notFoundRoute = {
    getMetaData(): IMetaData {
      return {
        title: 'ЦИАН – Страница не найдена',
      };
    },

    onEnter(nextState: RouterState, replace: RedirectFunction, cb: (err?: Error) => void): void {
      reduxStore.dispatch(setRouteName('404'));
      onRouteEnter(
        {
          fetchDataP: fetchCommonData().then(fetchSettingsByFilter).then(noop),
          client() {
            notFoundRoute.sendPageAnalitics();
            newPageQueueHandler.fire();
            setMetaData(notFoundRoute.getMetaData());
          },
          cb,
        },
        logger,
      );
    },

    sendPageAnalitics(): void {
      const { dataLayer, filter } = reduxStore.getState();
      if (dataLayer.initialized) {
        analytics.sendInitPageDataForOtherPage(dataLayer.data, {
          pageType: '404',
          region: filter.region,
        });
      }
    },
  };

  const signInUpRoute = {
    getMetaDataForRegistration(): IMetaData {
      return {
        title: 'Регистрация на ЦИАН',
      };
    },

    getMetaDataForAuth(): IMetaData {
      return {
        title: 'Вход в личный кабинет ЦИАН',
      };
    },

    onEnter(nextState: RouterState, replace: RedirectFunction, cb: (err?: Error) => void): void {
      reduxStore.dispatch(setRouteName('sign_in_up'));
      if (isClient) {
        reduxStore.dispatch(markTransition('Other'));
        reduxStore.dispatch(setAuthPageDrawPending(true));
      }

      const isRegistration = isRegistrationRoute(nextState);
      const { authenticationWidgetContext } = reduxStore.getState();

      const returnUrl = getReturnUrl(nextState.location.query);
      const isReturnUrlLkPromoCode = isUrlLkPromoCode(returnUrl);

      const { activeTab, view } = authenticationWidgetContext.state;
      const nextTab = isRegistration ? EActiveTab.Registration : EActiveTab.Authentication;

      if (nextTab !== activeTab) {
        authenticationWidgetContext.setTab(nextTab);
      }

      if (isReturnUrlLkPromoCode) {
        authenticationWidgetContext.setView(view.active, {
          ...view.data,
          notice: {
            title: 'Для активации промокода войдите в систему',
          },
        });
      }

      onRouteEnter(
        {
          fetchDataP: fetchCommonData().then(() => {
            if (reduxStore.getState().common.isAuthenticated) {
              reduxStore.dispatch(checkRedirectData(makeRedirectRes(returnUrl || '/')));
            }
          }),
          client() {
            signInUpRoute.sendPageAnalitics();
            newPageQueueHandler.fire();

            const meta = isRegistration
              ? signInUpRoute.getMetaDataForRegistration()
              : signInUpRoute.getMetaDataForAuth();
            setMetaData(meta);
            signInUpRoute.updateSuccessLogonUrl();
            reduxStore.dispatch(setAuthPageDrawPending(false));
          },
          cb,
        },
        logger,
      );

      function isRegistrationRoute(state: RouterState): boolean {
        return isRouteFor('REGISTER', state.location.pathname);
      }
    },

    onLeave(): void {
      const { authenticationWidgetContext } = reduxStore.getState();

      authenticationWidgetContext.setView(EActiveView.PhoneAuth);
      authenticationWidgetContext.resetAuthentication();
    },

    updateSuccessLogonUrl(): void {
      const { lastUrl } = reduxStore.getState().common;
      const withoutPopupsUrl = lastUrl ? removePopupParams(lastUrl) : '';
      const isAuthPath = isOneOfRoutes(['AUTH', 'REGISTER'], getPathname(withoutPopupsUrl));
      if (!isAuthPath) {
        reduxStore.dispatch(setSuccessLogonUrl(withoutPopupsUrl));
      }
    },

    sendPageAnalitics(): void {
      const { dataLayer } = reduxStore.getState();

      if (dataLayer.initialized) {
        analytics.sendInitPageDataForOtherPage(dataLayer.data, {
          pageType: 'Other',
          objectType: 'other',
          dealType: 'other',
          siteType: 'mobile',
        });
      }
    },
  };

  const resetPasswordRoute = {
    getMetaData: getDefaultMetaData,
    sendAnalytics(): void {
      // TODO
    },

    onEnter(nextState: RouterState, replace: RedirectFunction, cb: (err?: Error) => void): void {
      const { dispatch } = reduxStore;

      dispatch(setRouteName('resetPassword'));

      const { token } = nextState.location.query;
      const { userId } = nextState.params;
      onRouteEnter(
        {
          fetchDataP: Promise.all([fetchCommonData(), dispatch(getResetPasswordData(userId, token))]).then(noop),
          client() {
            setMetaData(resetPasswordRoute.getMetaData(nextState));
            resetPasswordRoute.sendAnalytics();
          },
          cb,
        },
        logger,
      );
    },
  };

  function getComponent(
    mod: Promise<{ default: RouteComponent }>,
    getMetaData?: (nextState: RouterState) => IMetaData,
  ): IndexRouteProps['getComponent'] {
    return function (nextState: RouterState, callback: (err: Error | null, component: RouteComponent) => void) {
      mod
        .then(({ default: Component }) => {
          if (getMetaData) {
            (Component as any).getMetaData = getMetaData;
          }
          callback(null, Component);
        })
        .catch(err => callback(err, () => null));
    };
  }

  return (
    <ErrorHandlerLogger>
      {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
      <Router history={syncedHistory} render={applyRouterMiddleware(useScroll())}>
        <Route
          getComponent={getComponent(import('./pages/advanced_search/container'), advancedSearchRoute.getMetaData)}
          path={routeFor.ADVANCED_SEARCH}
          onChange={getOnMainRouteChange(FiltersSection.Index)}
          onEnter={advancedSearchRoute.onEnter}
          onLeave={onLeave}
        />
        <Route component={Layout} path="/">
          {isNewbuildingSubdomain ? (
            <IndexRoute
              getComponent={getComponent(import('./pages/gk_page'), gkRoute.getMetaData)}
              onEnter={gkRoute.onEnter}
              onLeave={onLeave}
            />
          ) : (
            <IndexRoute
              api={api}
              getComponent={getComponent(import('./pages/main_page/container'), mainRoute.getMetaData)}
              onChange={getOnMainRouteChange(FiltersSection.Index)}
              onEnter={mainRoute.getOnEnter(FiltersSection.Index)}
              onLeave={onLeave}
            />
          )}
          {!isNewAuthEnabled && (
            <Route
              getComponent={getComponent(
                import('./pages/reset_password_page/temp_reset_password_page'),
                resetPasswordRoute.getMetaData,
              )}
              path="/users/:userId/reset-password/"
              onEnter={resetPasswordRoute.onEnter}
            />
          )}
          {routesFor.GkList.map(path => (
            <Route
              getComponent={getComponent(import('./pages/offers_list_page'), offersListRoute.getMetaData)}
              key={path}
              path={path}
              onChange={offersListRoute.onChange}
              onEnter={offersListRoute.onEnter}
              onLeave={offersListRoute.onLeave as any}
            />
          ))}
          <Route
            getComponent={getComponent(import('./pages/card_page/card_page_route'), cardRoute.getMetaData)}
            path=":dealType/:objectType/:id"
            onEnter={cardRoute.onEnter}
            onLeave={cardRoute.onLeave as any}
          />
          <Route
            getComponent={getComponent(import('./pages/gk_page'), gkRoute.getMetaData)}
            path="zhiloy-kompleks-*"
            onEnter={gkRoute.onEnter}
            onLeave={onLeave}
          />
          {FiltersSections.map(section => (
            <Route
              getComponent={getComponent(import('./pages/main_page/container'), mainRoute.getMetaData)}
              key={section.path}
              path={section.path}
              onChange={getOnMainRouteChange(section.key)}
              onEnter={mainRoute.getOnEnter(section.key)}
              onLeave={onLeave}
            />
          ))}
          <Route
            getComponent={getComponent(import('./pages/example_error_page'), exampleErrorRoute.getMetaData)}
            path="_/example-error/"
            onEnter={exampleErrorRoute.onEnter}
            onLeave={onLeave}
          />
          <Route
            getComponent={getComponent(
              import('./components/add_advert_promo/add_advert_promo.container'),
              addAdvertPromoRoute.getMetaData,
            )}
            path={routeFor.ADD_ADVERT_PROMO}
            onEnter={addAdvertPromoRoute.onEnter}
            onLeave={onLeave}
          />
          <Route
            getComponent={getComponent(
              import('./components/my_offers_promo/my_offers_promo.container'),
              myOffersRoute.getMetaData,
            )}
            path={routeFor.MY_OFFERS_PROMO}
            onEnter={myOffersRoute.onEnter}
            onLeave={onLeave}
          />
          {!isNewAuthEnabled && (
            <Route
              getComponent={getComponent(import('./pages/sign_in_up_page/sign_in_up_page'), noMetaData)}
              onLeave={signInUpRoute.onLeave}
            >
              <Route path={`${routeFor.REGISTER}`} onEnter={signInUpRoute.onEnter} />
              <Route path={`${routeFor.AUTH}`} onEnter={signInUpRoute.onEnter} />
            </Route>
          )}
          <Route
            getComponent={getComponent(import('./pages/not_found_page/not_found_page'), notFoundRoute.getMetaData)}
            path="*"
            onEnter={notFoundRoute.onEnter}
          />
        </Route>
      </Router>
    </ErrorHandlerLogger>
  );
}

export interface IProvidersProps {
  store: Store<ReduxState>;
  serverSideState: IServerSideState;
  context: ApplicationContextModel;
  legacyContext: IAppContext;
  children?: React.ReactNode;
}

export const Providers: React.FC<IProvidersProps> = props => {
  const { store, serverSideState, context, legacyContext } = props;

  const state = store.getState();

  return (
    <Provider store={props.store}>
      <ApplicationContext.Provider value={context}>
        <ServerSideStateProvider serverSideState={serverSideState}>
          <UiKitContextProvider deviceType={uaToDeviceType(state.userAgent)}>
            <AppContextProvider context={legacyContext}>{props.children}</AppContextProvider>
            <MobileAppBannerContainerLoadable />
            <CatProfilingModalContainerLoadable />
          </UiKitContextProvider>
        </ServerSideStateProvider>
      </ApplicationContext.Provider>
    </Provider>
  );
};
