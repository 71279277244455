import { FiltersSection } from '../redux/filters/model';
export const routeFor = {
  INDEX: '/',
  ADVANCED_SEARCH: '/nd/search',
  OFFERS_LIST: '/cat.php',
  ADD_ADVERT_PROMO: '/mobile',
  CONTACTS: '/contacts/',
  MAP: '/map/',
  MAP_NOVOSTROYKI: '/map-novostroyki/',
  FAVORITES: '/favorites/',
  REGISTER: '/registration',
  AUTH: '/authenticate',
  RESET_PASSWORD: '/reset-password',
  //saved-search без / так как используется всегда вместе с lkURL который имеет формат (//my.cian.ru/)
  SAVED_SEARCH: 'saved-search',
  HIDDEN_OFFERS: 'hidden-offers/',
  MY_OFFERS_PROMO: '/my-offers',
  MY_E2E_DEAL_SALE: 'deal-sale-list/?cian_source=mobile_menu',
};

export const OFFER_CARD_ROUTE_REGEXP = /(sale|rent)\/(flat|commercial|suburban)\/(\d)/;

function normalizePathname(pathname: string): string {
  return pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname;
}

export function isRouteFor(routeKey: keyof typeof routeFor, pathname: string): boolean {
  return normalizePathname(pathname) === normalizePathname(routeFor[routeKey]);
}

export function isOneOfRoutes(routeKeys: Array<keyof typeof routeFor>, pathname: string): boolean {
  return routeKeys.some(routeKey => isRouteFor(routeKey, pathname));
}

export const GkListFriendlyRoutes = ['kupit-*', 'snyat-*', 'novostroyki-*', 'novostroyki'];

export const routesFor = {
  GkList: ['cat.php', ...GkListFriendlyRoutes],
};

// Вертикали
export const FiltersSections: Array<{ path: string; key: FiltersSection }> = [
  {
    path: 'snyat',
    key: FiltersSection.LongRent,
  },
  {
    path: 'kupit',
    key: FiltersSection.Sale,
  },
  {
    path: 'novostrojki',
    key: FiltersSection.NewBuildings,
  },
  {
    path: 'posutochno',
    key: FiltersSection.ShortRent,
  },
  {
    path: 'commercial',
    key: FiltersSection.Commercial,
  },
];
