import { toPromise } from '../../utils/streams';
import { isTimeoutError, timeoutPromise } from '../../utils/timeout_promise';
import { TTypedThunkAction } from '../model';
import { ISpecialPromo } from './model';

export type TSpecialPromoActions
  = { type: 'SetSpecialPromo', payload: ISpecialPromo }
  | { type: 'ClearSpecialPromo' }
;

export function setSpecialPromo(builderId: number): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api, logger }) => {
    return timeoutPromise(toPromise(api.getSpecialPromo(builderId)), 4000)
      .then(response => {
        dispatch({ type: 'SetSpecialPromo', payload: response.result });
      })
      .catch(err => {
        const meta = { message: err.message, stackTrace: err.stack };

        if (logger && isTimeoutError(err)) {
          logger.warning('Failed to load /v1/get-special-promo/', meta);
        }

        logger.error(err);

        throw err;
      });
  };
}

export function clearSpecialPromo(): TTypedThunkAction<Promise<{}>> {
  return (dispatch, getState, { api }) => {
    return Promise.resolve()
      .then(() => dispatch({ type: 'ClearSpecialPromo' }));
  };
}
