import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import { Observable } from 'rxjs/Observable';

import logException from './exceptions';

// tslint:disable-next-line:no-any
type Value = any;

export interface ICacheStore {
  put(key: string, value: Value): Observable<void>;
  get<V>(key: string): Observable<V | undefined>;
}

export class Cache {
  private store: ICacheStore;

  public constructor(store: ICacheStore) {
    this.store = store;
  }

  public set(key: string, value: Value) {
    return this.store.put(key, value);
  }

  public get<V>(key: string) {
    return this.store.get<V>(key);
  }
}

export type Memoized = <T>({ key, getValue }: { key: string, getValue: () => Observable<T> }) => Observable<T>;

export function createCacheFunction(store: ICacheStore): Memoized {
  const cache = new Cache(store);
  return function memoized<T>({ key, getValue }: { key: string, getValue: () => Observable<T> }): Observable<T> {
    return cache
      .get<T>(key)
      .catch(err => {
        logException(err);
        return Observable.of(undefined);
      })
      .mergeMap((cachedValue: T) => {
        return cachedValue !== undefined
          ? Observable.of(cachedValue)
          : getValue()
            .mergeMap(value => cache.set(key, value).catch(() => Observable.of(value)).mapTo(value));
      });
  };
}

export function noMemoized<T>({ key, getValue }: { key: string, getValue: () => Observable<T> }): Observable<T> {
  return getValue();
}
