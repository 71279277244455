import { IGKData } from '../../../api/models/gk_card';
import { IBuilderInfo as IAPIBuilder } from '../../../api/models/gk_preview';
import { IBuilder } from '../model';

const mapper = (builder: IAPIBuilder): IBuilder => {
  const { id, name, logo_url: logoUrl, phone_data_layer, year_foundation, jk_stat_full, website_url } = builder;
  return {
    id,
    name,
    logoUrl,
    phone_data_layer,
    year_foundation,
    jk_stat_full,
    website_url,
  };
};

export const parseBuilders = (gk: IGKData) => gk.builders_info.map(mapper);
