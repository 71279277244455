import { INotificationsCountContext } from '@cian/chats-notifications-connect';
import { IAgentAvailability }         from '../../api/models/offer_card';

export interface IChatsState {
  areOfferChatsEnabled: boolean;
  isOfferFirstChatMessageSent: boolean;
  popupIsOpen: boolean;
  unreadContext: INotificationsCountContext | undefined;
  mapPositionX: number | undefined;
  mapPositionY: number | undefined;
  offerId: number | undefined;
  agentAvailability?: IAgentAvailability;
  contacts?: string[];
  isOwn: boolean;
}

export const initialChatsState: IChatsState = {
  areOfferChatsEnabled: false,
  isOfferFirstChatMessageSent: false,
  popupIsOpen: false,
  unreadContext: undefined,
  mapPositionX: undefined,
  mapPositionY: undefined,
  offerId: undefined,
  contacts: undefined,
  isOwn: false,
};
