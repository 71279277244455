import * as React from 'react';

import { CheckboxField, Label, mergeStyles } from '@cian/components';
import { LiftsCountType } from '../../../redux/filters/model';
import { SelectMobile } from '../../common/select/select';
import { createSelectOptions } from '../../common/select_helper';
import { IFieldProps, isFlatObjectGroup, IsVisibleCheck  } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  private options = [{
    label: '',
    options: [{
      id: LiftsCountType.Any,
      label: 'Неважно',
    }, {
      id: LiftsCountType.One,
      label: '1',
    }, {
      id: LiftsCountType.Two,
      label: '2',
    }, {
      id: LiftsCountType.Three,
      label: '3',
    }, {
      id: LiftsCountType.Four,
      label: '4',
    }],
  }];

  public render() {
    const processedOptions = createSelectOptions(
      this.options,
      [String(this.props.filter.liftsCount || 0)],
      this.props.filter,
    );

    const { withServiceLift } = this.props.filter;
    const labelStyles = mergeStyles(
      styles.labelCheckbox,
      withServiceLift && styles.checked,
    ).className;

    return (
      <div data-mark="FieldLifts">
        <Label
          label="Лифтов минимум"
          containerStyle={styles.label}
        >
          <SelectMobile
            id="select-object-year"
            name="selectMobile"
            label="Неважно"
            onValueChange={(value: string[]) => this.props.actions.setLiftsCount(Number(value[0]))}
            value={processedOptions.value}
            options={processedOptions.options[0].options}
          />
        </Label>
        <div className={styles.checkbox}>
          <CheckboxField
            mobile
            label="Есть грузовой"
            labelStyle={labelStyles}
            value={withServiceLift}
            onValueChange={this.props.actions.setWithServiceLift}
          />
        </div>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter =>
  isFlatObjectGroup(filter) && !filter.withNewobject;
