import { get } from 'lodash';

import {
  IOfferDetail,
  UserPresenterUserTrustLevel,
} from '../../../api/models/offer_card';
import { Pessimisation } from '../../../redux/offer/model';

export const responseToPessimisation = (offer: IOfferDetail): Pessimisation | undefined => {
  switch (get(offer, 'user.userTrustLevel') as UserPresenterUserTrustLevel) {
    case 'danger':
      return Pessimisation.Strong;
    case 'excluded':
      return Pessimisation.Light;
    default:
      return undefined;
  }
};
