import * as React from 'react';
import * as ReactDOM from 'react-dom';

export interface IPortalProps {
  portalId?: string;
}

export interface IPortalState {
  isMounted: boolean;
}

const getPortalElementId = (portalId: string) => `portal-${portalId}`;

export const createPortalTarget = (portalId: string) => {
  return class PortalTarget extends React.Component<{}> {
    public render() {
      return <div id={getPortalElementId(portalId)}></div>;
    }
  };
};

export class Portal extends React.Component<IPortalProps, IPortalState> {
  public state: IPortalState = {
    isMounted: false,
  };

  private portalTarget: HTMLElement | null;

  public componentDidMount() {
    const portalId = this.props.portalId;
    if (portalId) {
      this.portalTarget = document.getElementById(getPortalElementId(portalId));
    } else {
      this.portalTarget = document.createElement('div');
      document.body.appendChild(this.portalTarget);
    }

    this.setState({
      isMounted: true,
    });
  }

  public componentWillUnmount() {
    if (!this.props.portalId && this.portalTarget) {
      document.body.removeChild(this.portalTarget);
    }
  }

  public render() {
    if (!this.state.isMounted) {
      return null;
    }

    return ReactDOM.createPortal(
      this.props.children,
      this.portalTarget as HTMLElement,
    );
  }
}
