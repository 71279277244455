import * as React from 'react';
import { filterAdvancedClear, refineSearchClearClick } from '../../../utils/analytics';
import { isPhone } from '../../../utils/device_detector';
import { scrollToTop } from '../../../utils/scroll';
import { IFieldProps, IsVisibleCheck } from '../field';

import { trackClearButtonClick } from './tracking';

const styles = require('./clear_filters.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const shouldBeShown = this.props.filter.selectedObjectTypes.length > 0;

    if (!shouldBeShown) {
      return null as any; // tslint:disable-line:no-any
    }

    return (
      <div data-mark="ButtonClearFilters">
        <button type="button" className={styles.reset} onClick={this.clearFilters}>
          Очистить фильтры
        </button>
      </div>
    );
  }

  private clearFilters = (room: React.FormEvent<HTMLButtonElement>) => {
    trackClearButtonClick();

    if (this.props.inPopup) {
      refineSearchClearClick();
    } else {
      filterAdvancedClear();
    }

    this.props.actions.clearFilters();
    if (isPhone()) {
      scrollToTop(500);
    }
  }
}

export const isVisible: IsVisibleCheck = filter => true;
