
import { IStyleConfig, mergeStyles } from '@cian/components';
import * as React from 'react';

interface IClickOutsideProps {
  containerStyles?: IStyleConfig;
  getOutsideContainer?: () => (HTMLElement | null);
  // Для обработки кликов вне children, но внутри outsideClickContainer.
  onClickOutside(event?: Event): void;
}

export class ClickOutside extends React.Component<IClickOutsideProps, object> {
  private container: HTMLDivElement | null;
  private handle: (e: Event) => void;

  public componentDidMount() {
    if (this.container) {
      this.handle = createClickOutsideHandler(
        this.props.onClickOutside,
        this.container,
        this.props.getOutsideContainer,
      );
    }
    document.addEventListener('click', this.handle, true);
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handle, true);
  }

  public render() {
    // объявляем onClickOutside, outsideContainer чтобы они не передавался в обертку.
    const { children, containerStyles, onClickOutside, getOutsideContainer , ...props } = this.props;

    return (
      <div
        {...mergeStyles(containerStyles)}
        {...props}
        ref={ref => this.container = ref}
      >
        {children}
      </div>
    );
  }
}

export function createClickOutsideHandler(
  onClickOutside: (e?: Event) => void,
  element: HTMLElement,
  getOutsideContainer?: () => (HTMLElement | null),
): (e: Event) => void {
  return (e: Event) => {
    const clickOutside = !element.contains(e.target as Node);
    const outsideContainer = getOutsideContainer && getOutsideContainer();

    const condition = outsideContainer
      ? clickOutside && outsideContainer.contains(e.target as Node)
      : clickOutside;

    if (condition) {
      onClickOutside(e);
    }
  };
}

export default ClickOutside;
