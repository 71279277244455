import { mergeStyles } from '@cian/utils';
import { flatten, includes } from 'lodash';
import * as React from 'react';

import { isIE } from '../../../utils/device_detector';

import {
  IFieldProps,
  IsVisibleCheck,
} from '../field';

import {
  IOption,
  IOptionsList,
  IProcessedOptions,
} from '../../common/select_helper';

import { PopupParamNames } from '../../../redux/popups/actions';
import { getProcessedOptions, objectTypesList, ObjectTypesPopup } from './object_type_popup';

const style = require('./select_object_type.css');
const fieldStyle = require('../field.css');

const defaultButtonText = 'Выберите тип недвижимости';

interface ISelectObjectTypeState {
  processedOptions: IProcessedOptions;
  // for bug in winphone: disable before mounting
  selectDisabled?: boolean;
}

function isSingleOption(option: IOption | IOption[]): option is IOption {
  return (option as IOption).id !== undefined;
}

export type TComponentProps = IFieldProps;

export class ComponentBase extends React.Component<TComponentProps, ISelectObjectTypeState> {

  public constructor(props: IFieldProps) {
    super(props);

    this.state = {
      selectDisabled: isIE(props.userAgent),
      processedOptions: {
        options: [],
        value: [],
      },
    };
  }

  public componentDidMount() {
    this.setState({ selectDisabled: false });

    this.updateProcessedOptions(this.props);
  }

  public componentWillReceiveProps(newProps: IFieldProps) {
    this.updateProcessedOptions(newProps);
  }

  public render() {
    const { dealType, selectedObjectTypes } = this.props.filter;
    const buttonText = this.renderButtonText(selectedObjectTypes);
    const error = !selectedObjectTypes || !selectedObjectTypes.length;
    const buttonStyles = mergeStyles([
      style.button,
      error && style.buttonError,
    ]);

    return (
      <div className={fieldStyle.field} data-mark="FieldSelectObjectType">
        <button
          type="button"
          disabled={this.state.selectDisabled}
          {...buttonStyles}
          onClick={this.openPopup}
          key={dealType}
        >
          <span className={style.buttonText}>
            {buttonText}
          </span>
          {error && (
            <div className={style.errorMessage}>
              Не выбран тип недвижимости
            </div>
          )}
        </button>
        <ObjectTypesPopup
          opened={this.props.isPopupOpened(PopupParamNames.ObjectType)}
          selectedObjectTypes={this.props.filter.selectedObjectTypes}
          withNewObject={this.props.filter.withNewobject}
          resaleOnly={this.props.filter.resaleOnly}
          isUpcomingSale={this.props.filter.isUpcomingSale}
          userAgent={this.props.userAgent}
          actions={this.props.actions}
          options={this.state.processedOptions.options}
          onClose={this.closePopup}
          isNewFiltersAvailable={this.props.isNewFiltersAvailable}
        />
      </div>
    );
  }

  private openPopup = () => {
    this.props.popupActions.openPopup(PopupParamNames.ObjectType);
  }

  private closePopup = () => {
    this.props.popupActions.closePopup(PopupParamNames.ObjectType);
  }

  private renderButtonText = (selectedValues: string[] = []) => {
    if (selectedValues.length === 0) {
      return defaultButtonText;
    }

    //TODO: Зарефакторить
    const selectedValuesString: string = objectTypesList
      .map((list: IOptionsList) => list.options)
      .reduce((arr: IOption[], option: IOption | IOption[]) => {
        if (isSingleOption(option)) {
          arr.push(option);
        } else {
          arr = option.concat(arr);
        }

        return flatten(arr);
      }, [] as IOption[])
      .reduce((str: string, option: IOption) => {
        const isSelected = includes(selectedValues, option.id);

        if (isSelected) {
          str = str.concat(`${option.label}, `);
        }

        return str;
      }, '').slice(0, -2); // cuts ', ' from the end

    return selectedValuesString;
  }

  private updateProcessedOptions = (props: IFieldProps) => {
    this.setState({
      processedOptions: getProcessedOptions(props.filter, props.isNewFiltersAvailable && props.filtersPlace === 'advanced_search'),
    });
  }
}

export const isVisible: IsVisibleCheck = filter => true;

export const Component = ComponentBase;
