import { IGKData } from '../../../api/models/gk_card';
import { IGK } from '../model';
import { parseAboutGK } from './about_gk_parser';
import { parseAddress } from './address_parser';
import { parseAnalytics } from './parse_analytics';
import { parseBuilders } from './parse_builders';
import { parseContacts } from './parse_contacts';
import { parseFlats } from './parse_flats';
import { parseInfrastructure } from './parse_infrastructure';
import { parsePhotos } from './parse_photos';
import { parsePriceSummary } from './parse_price_summary';
import { parsePromo } from './parse_promo';
import { parseSecurity } from './parse_security';

export const responseToGK = (gk: IGKData): IGK => {
  const contacts = parseContacts(gk.builders_info, gk.sellers);
  const flats = parseFlats(gk);

  return {
    name: gk.name,
    parking: gk.parking,
    photos: parsePhotos(gk),
    description: gk.description,
    additional_descriptions: gk.additional_descriptions,
    url: gk.url,
    builders: parseBuilders(gk),
    contacts,
    completion_year: gk.completion_year,
    address: parseAddress(gk),
    constructionProgress: gk.progress_of_construction,
    flats,
    infrastructure: parseInfrastructure(gk),
    security: parseSecurity(gk),
    aboutGK: parseAboutGK(gk),
    priceSummary: parsePriceSummary(gk),
    promo: parsePromo(gk),
    analytics: parseAnalytics(gk, contacts, flats),
    id: gk.id,
    video_urls: gk.video_urls,
    is_problem: gk.is_problem,
    problems: gk.problems,
    is_reliable: gk.is_reliable,
    show_jk_reliable_flag: gk.show_jk_reliable_flag,
    reliable_houses: gk.reliable_houses,
    is_favorite: gk.is_favorite,
    promo_info_list: gk.promo_info_list,
    is_deal_request_substitution_phone: gk.is_deal_request_substitution_phone,
    web_site_url_utm: gk.web_site_url_utm,
    web_site_url: gk.web_site_url,
    is_test: gk.is_test || false,
  };
};
