import { last } from 'lodash';

import { IOfferInfo } from '../../../redux/offer/model';
import { FOfferType, getOfferTypeFromOffer } from '../../category';
import { getAreaLabel as formatArea } from '../../labels/getAreaLabel';

export const getAreaLabel = (offer: IOfferInfo): string | null | undefined => {
  const offerType = getOfferTypeFromOffer(offer);
  const isAreaParts = Boolean(offer.areaParts?.length)

  if (offerType === FOfferType.Land || offerType === FOfferType.CommercialLand) {
    const { land } = offer;

    // tslint:disable-next-line:max-line-length
    return land && [Number(land.area), land.areaUnitType === 'hectare' ? 'га.' : 'сот.'].filter(Boolean).join('');
  }

  if (offerType & FOfferType.Commercial) {
    const areas: Array<string | number | null | undefined> = [offer.minArea, offer.totalArea, offer.maxArea]
      .filter(Boolean)
      .sort((a, b) => Number(a) - Number(b));

    if (areas.length > 1) {
      const isAreaPartsWithSameAreas = areas[0] === last(areas) && isAreaParts;
      const postfix = !isAreaPartsWithSameAreas ? ` до ${Number(last(areas))}` : '';

      return `от ${Number(areas[0])}${postfix}м²`;
    }
  }

  if (offer.totalArea) {
    return `${Number(offer.totalArea)}м²`;
  }

  const { area } = offer.baseInfo;

  if (area) {
    return formatArea(area);
  }

  return null;
};
