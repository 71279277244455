import { sum } from 'lodash';

import { IGKData } from '../../../api/models/gk_card';
import { IPrice } from '../../../api/models/gk_preview';
import { IPriceSummary } from '../model';

const getPrice = (value?: number, defaultValue?: number): number | undefined =>
  value != null ? Math.floor(value) : defaultValue;

type Range = { min?: number, max?: number };

export const parsePriceRange = (priceForObject: IPrice): Range => {
  return {
    min: getPrice(priceForObject && priceForObject.from),
    max: getPrice(priceForObject && priceForObject.to),
  };
};

export const parseAvgPrice = (priceForMeter: IPrice): number | undefined => {
  const pricesForMeter = [
    priceForMeter && priceForMeter.from,
    priceForMeter && priceForMeter.to,
  ].filter(Boolean);

  const price = pricesForMeter.length ? sum(pricesForMeter) / pricesForMeter.length : undefined;
  return getPrice(price);
};

export const parsePriceSummary = (gk: IGKData): IPriceSummary => {
  const { price_for_object, price_for_meter } = gk;

  const { min, max } = parsePriceRange(price_for_object);

  return {
    min: min,
    max: max,
    pricePerUnit: parseAvgPrice(price_for_meter),
  };
};
