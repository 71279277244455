export interface ISaveFilterPopupState {
  open: boolean;
  isFromBanner: boolean;
}

export type TSaveFilterPopupActions = {
  type: 'ToggleSaveFilterPopup',
  open: boolean,
  isFromBanner: boolean;
};

export function openSaveFilterPopupAction(isFromBanner?: boolean): TSaveFilterPopupActions {
  return {
    type: 'ToggleSaveFilterPopup',
    open: true,
    isFromBanner: isFromBanner || false,
  };
}

export function closeSaveFilterPopupAction(): TSaveFilterPopupActions {
  return {
    type: 'ToggleSaveFilterPopup',
    open: false,
    isFromBanner: false,
  };
}

const defaultState = {
  open: false,
  isFromBanner: false,
};

export default (state = defaultState, action: TSaveFilterPopupActions) => {

  switch (action.type) {
    case 'ToggleSaveFilterPopup': {
      const {open, isFromBanner} = action;
      return {
        ...state,
        open,
        isFromBanner,
      };
    }

    default:
      return state;
  }
};
