import { uniqueId } from 'lodash';
import { PropertyType } from '../../api/models/map';
import { pipe } from '../../utils/functional';
import { getDistanceBetweenTwoPoints } from '../../utils/ymaps';
import { ResourceStatus } from '../common/model';
import { TMapPageActions as Action } from './actions';
import {
  defaultMapPageState,
  IPin,
  MapObject, MapObjectType,
  MapPageState,
} from './model';

type Reducer = (state: MapPageState, action: Action) => MapPageState;
type TPayload = {
  mapObjects: MapObject[],
  mapObjectsType: MapObjectType,
  legend: PropertyType[],
  offersCount: number,
};

const MapPageReducer: Reducer = (state = defaultMapPageState, action: Action) => {
  switch (action.type) {
    case 'SaveLastMapRequestQueryString':
    case 'SetMapState':
    case 'SetDrawPending':
    case 'SetMapObjectsStatus':
    case 'ResetMapState':
    case 'SetMapFilterQueryString':
    case 'SetFromDeveloperMapFilter':
    case 'SetSubscriptionPending':
    case 'SetMinBbox':
      return {
        ...state,
        ...action.payload,
      };

    case 'SetMapData':
      const { zoom, mapObjects: payloadMapObjects } = action.payload;

      return {
        ...state,
        ...action.payload,
        mapObjects: zoom === 14 && state.mapZoom === 15
          ? payloadMapObjects
          : mergeOffersWithInfrastructure(state, action.payload),
      };

    case 'SetMapContainer':
      return {
        ...state,
        mapContainer: action.payload.mapContainer,
      };

    case 'SetMapSize':
      return {
        ...state,
        mapSize: action.payload.mapSize,
      };

    case 'SetMap':
      return {
        ...state,
        ymap: action.payload.ymap,
      };

    case 'SetFetchDataForPinPopupInfo':
      return {
        ...state,
        fetchingDataPinPopupInfo: action.payload.fetching,
      };

    case 'SetOpenedPinPopupInfo':
      return {
        ...state,
        openedPinType: action.payload.openedPinType,
        openedPinGkInfo: action.payload.openedPinGkInfo,
        openedPinKpInfo: action.payload.openedPinKpInfo,
        openedPinPopupId: uniqueId('pin_popup'),
      };

    case 'SetSubscription':
      return {
        ...state,
        ...action.payload,
        subscriptionIsPending: false,
      };

    case 'AddFavoriteQueryString': {
      const newFavoritesUrls = state.favoriteSearchQueries != null
        ? state.favoriteSearchQueries.concat(action.payload)
        : [action.payload];

      return {
        ...state,
        favoriteSearchQueries: newFavoritesUrls,
      };
    }

    case 'SetPinViewType':
      return {
        ...state,
        openedPinViewType: 'opened',
      };

    case 'SetFixedViewType':
      return {
        ...state,
        openedPinViewType: 'fixed',
      };

    case 'UnsetFixedViewType':
      return {
        ...state,
        openedPinViewType: undefined,
      };

    case 'SetActivePin':
      const { id } = action.payload;
      const isTargetPin = (mapObject: MapObject) => ['pin', 'gk_pin'].includes(mapObject.type) && mapObject.id === id;
      /* Устанавливает активность для пина на карте */
      const mapObjects = state.mapObjects.map(mapObject => ({
        ...mapObject,
        active: isTargetPin(mapObject),
      }));

      const changedPin = mapObjects.find(isTargetPin) as IPin;

      return {
        ...state,
        mapObjectsStatus: ResourceStatus.UpdatedOnClient,
        mapObjects,
        activePin: changedPin && changedPin.active ? changedPin : undefined,
      };

    case 'UnsetActivePin':
      return {
        ...state,
        mapObjectsStatus: ResourceStatus.UpdatedOnClient,
        mapObjects: state.mapObjects.map(mapObject => ({
          ...mapObject,
          active: false,
        })),
        activePin: undefined,
      };

    case 'SetPinOrClusterWithOfferActive':
      if (!action.payload) {
        return state;
      }

      const geo = action.payload;

      if (!geo.coordinates) {
        return state;
      }

      const { lat, lng } = geo.coordinates;

      if (typeof lat !== 'number' || typeof lng !== 'number') {
        return state;
      }

      const coordinates = [lat, lng] as [number, number];

      let closestDistance = +Infinity;
      let closestPinId: string;

      state.mapObjects.forEach(pin => {
        const currentDistance = getDistanceBetweenTwoPoints(coordinates, pin.coords);
        if (closestDistance > getDistanceBetweenTwoPoints(coordinates, pin.coords)) {
          closestPinId = pin.id;
          closestDistance = currentDistance;
        }
      });

      return {
        ...state,
        mapObjectsStatus: ResourceStatus.UpdatedOnClient,
        mapObjects: state.mapObjects.map(mapObject => ({
          ...mapObject,
          closest: closestPinId === mapObject.id ? true : false,
        })),
      };

    case 'UnsetPinOrClusterWithOfferActive':
      return {
        ...state,
        mapObjectsStatus: ResourceStatus.UpdatedOnClient,
        mapObjects: state.mapObjects.map(mapObject => ({
          ...mapObject,
          closest: false,
        })),
      };

    case 'SetPinViewed':
      return setPinViewed(state, action);

    case 'SetNewbuildingsPinsEnabled':
      return {
        ...state,
        newbuildingsPinsEnabled: action.payload.enabled,
      };

    case 'toggleInfrastructure':
      return {
        ...state,
        infrastructurePinsEnabled: action.payload.enable,
      };

    case 'ChangeGkMapFavoriteStatus':
      if (!state.openedPinGkInfo) { return state; }

      return {
        ...state,
        openedPinGkInfo: {
          ...state.openedPinGkInfo,
          isFavorite: action.payload.state,
        },
      };

    default:
      return state;
  }
};

function setPinViewed(state: MapPageState, action: Action): MapPageState {
  if (action.type === 'SetPinViewed') {
    const { id } = action.payload;

    const mapObjects = state.mapObjects.map(mapObject => {
      if (mapObject.id === id && ['pin', 'gk_pin'].includes(mapObject.type)) {
        return {
          ...mapObject,
          viewed: true,
        };
      }
      return mapObject;
    });
    return {
      ...state,
      mapObjectsStatus: ResourceStatus.UpdatedOnClient,
      mapObjects,
    };
  }
  return state;
}

/**
 * При обновлении объетков карты, они добавляются к существующей в стейте инфраструктуре
 * @param {MapObject[]} stateMapObjects - объекты карты стора
 * @param {MapObject[]} payloadMapObjects - объекты карты из пэйлоада
 * @returns {MapObject[]}
 */
function mergeOffersWithInfrastructure({mapObjects: stateMapObjects}: MapPageState,
                                       {mapObjects: payloadMapObjects}: TPayload): MapObject[] {
  return pipe(filterMapObjects(onlyInfrastructure), mergeMapObjects(payloadMapObjects))(stateMapObjects);
}

/**
 * Фильтр объектов карты.
 * @param {(data: MapObject) => boolean} predicateFunc - функция-предикат, которая задает правило фильтрации
 * @returns {(filterData: MapObject[]) => MapObject[]} - функция, принимает объекты карты, которые нужно отфильтровать
 */
function filterMapObjects(predicateFunc: (data: MapObject) => boolean) {
  return function(filterData: MapObject[]): MapObject[] {
    return filterData.filter(predicateFunc);
  };
}

/**
 * Функция объединияет массивы объектов карты
 * @param {MapObject[]} newMapObjects
 * @returns {(stateMapObjects: MapObject[]) => MapObject[]}
 */
function mergeMapObjects(newMapObjects: MapObject[]) {
  return function(stateMapObjects: MapObject[]): MapObject[] {
    return stateMapObjects.concat(newMapObjects);
  };
}

/**
 * Функция-предикат, возвращает true только для оъектов и кластеров инфраструктуры
 * @param {TPinType} type - тип объекта карты
 * @returns {boolean}
 */
const onlyInfrastructure = ({type}: MapObject): boolean =>
  type === 'infrastructure' || type === 'infrastructure_cluster';

export default MapPageReducer;
