import { IRegionInfo } from '../api/api';
import { isSuburbanObjectGroup, showWithNewobject } from '../filters/fields/field';
import { rentTimeDict } from '../redux/filters/dictionaries';
import { Currency, DealType, FilterState, yearType } from '../redux/filters/model';
import translit from './translit';
import russianDictionary from './translit_russian';

interface IGetFiltersDataForGAParams {
  filter: FilterState;
  region: IRegionInfo;
}

export function getFiltersDataForGA(params: IGetFiltersDataForGAParams): string {
  const { filter, region } = params;

  const translitRussian = translit(russianDictionary);

  const {
    currency,
    dealType,
    price,
    rentTime,
    rooms,
    selectedObjectTypes,
    selectedYears,
    sizeTotal,
    withNewobject,
    withPik,
  } = filter;

  let gaInfo: Array<string | undefined> = [];

  /* deal type */
  if (dealType === DealType.Sale) {
    gaInfo.push('buy');
  }

  if (dealType === DealType.Rent) {
    if (rentTime === rentTimeDict.Long) {
      gaInfo.push('long');
    }

    if (rentTime === rentTimeDict.Short) {
      gaInfo.push('short');
    }
  }

  /* object types */
  if (selectedObjectTypes.length) {
    let objTypes: string[] = [];

    selectedObjectTypes.forEach((obj: string) => {
      if (withNewobject && showWithNewobject(filter)) {
        objTypes.push(`${obj}_v_novostroyke`);
      } else {
        objTypes.push(obj);
      }
    });

    gaInfo.push(objTypes.join(','));
  } else {
    gaInfo.push(undefined);
  }

  /* rooms */
  let roomsArr: string[] = [];

  if (rooms.oneRoom) {
    roomsArr.push('1');
  }

  if (rooms.twoRooms) {
    roomsArr.push('2');
  }

  if (rooms.threeRooms) {
    roomsArr.push('3');
  }

  if (rooms.fourRooms) {
    roomsArr.push('4');
  }

  if (rooms.fiveRooms) {
    roomsArr.push('5');
  }

  if (rooms.sixRooms) {
    roomsArr.push('6');
  }

  if (rooms.freeLayout) {
    roomsArr.push('7');
  }

  if (rooms.studio) {
    roomsArr.push('studio');
  }

  if (roomsArr.length) {
    gaInfo.push(`rooms:${roomsArr.join(',')}`);
  } else {
    gaInfo.push(undefined);
  }

  /* years */
  if (selectedYears.length && showWithNewobject(filter)) {
    let years: string[] = [];

    selectedYears.forEach((year: string) => {
      if (year === yearType.PutIntoOperation) {
        years.push('done');
      } else if (year === yearType.Later) {
        years.push(`>${yearType.getYear(3)}`);
      } else {
        years.push(`=${year}`);
      }
    });

    // replace first element
    if (years[0] === 'done') {
      years = years.slice(1).concat(years[0]);
    }

    gaInfo.push(years.join(','));
  } else {
    gaInfo.push(undefined);
  }

  /* sizeTotal = square */
  if (sizeTotal.min || sizeTotal.max) {
    let sq: string[] = [];

    if (sizeTotal.min) {
      sq.push(`from${sizeTotal.min}`);
    }

    if (sizeTotal.max) {
      sq.push(`till${sizeTotal.max}`);
    }

    if (isSuburbanObjectGroup(filter)) {
      sq.push('acres');
    } else {
      sq.push('m2');
    }

    gaInfo.push(sq.join(''));
  } else {
    gaInfo.push(undefined);
  }

  /* price */
  if (price.min || price.max) {
    let pr: string[] = [];

    if (price.min) {
      pr.push(`from${price.min}`);
    }

    if (price.max) {
      pr.push(`till${price.max}`);
    }

    switch (currency) {
      case (Currency.USD):
        pr.push('USD');
        break;

      case (Currency.EUR):
        pr.push('EUR');
        break;

      default:
        pr.push('RUB');
    }

    gaInfo.push(pr.join(''));
  } else {
    gaInfo.push(undefined);
  }

  const regionName = region.name;

  if (regionName) {
    const regionTitle = regionName.toLowerCase().replace(/ /g, '_') ;

    gaInfo.push(translitRussian(regionTitle));
  } else {
    gaInfo.push(undefined);
  }

  /* with PIK */
  if (withPik) {
    gaInfo.push('pik');
  } else {
    gaInfo.push(undefined);
  }

  const regionId = region.parentId || region.id;

  if (regionId) {
    gaInfo.push(String(regionId));
  } else {
    gaInfo.push(undefined);
  }

  return `/${gaInfo.join('/')}`;
}
