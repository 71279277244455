import { IOfferDetail, EOverlapType, EHeatingType, EGasSupplyType } from '../../../api/models/offer_card';
import { IHouseInfo, IRentalRate } from '../../../redux/offer/model';
import { parseNumberLike } from '../../../utils/helpers';
import { round } from '../../../utils/numbers';

function mapHouseType(offerData: IOfferDetail): string | undefined {
  if (offerData.building == null || offerData.building.materialType == null) {
    return undefined;
  }
  const map: { [key: string]: string } = {
    'brick': 'Кирпичный',
    'monolith': 'Монолитный',
    'panel': 'Панельный',
    'block': 'Блочный',
    'wood': 'Деревянный',
    'stalin': 'Сталинский',
    'boards': 'Щитовой',
    'monolithBrick': 'Монолитно-кирпичный',
    'old': 'старый фонд',
  };
  return map[offerData.building.materialType];
}

function mapParkingType(offerData: IOfferDetail): string | undefined {
  const map: { [key: string]: string } = {
    'roof': 'На крыше',
    'underground': 'Подземная',
    'ground': 'Наземная',
    'multilevel': 'Многоуровневая',
    'open': 'Открытая',
  };

  const { building } = offerData;
  const parkingType = building && building.parking && building.parking.type;

  return parkingType && map[parkingType];
}

function mapRentalRate(offerData: IOfferDetail): IRentalRate | undefined {
  const { building, flatType } = offerData;
  const rate = building && building.roomRentPrice;

  if (rate == null) {
    return undefined;
  }

  let roomsCount = offerData.roomsCount;

  if (flatType === 'openPlan') {
    roomsCount = 7;
  } else if (flatType === 'studio') {
    roomsCount = 9;
  }

  return {
    rate: parseNumberLike(rate),
    roomsCount,
  };

}

export function mapIsNewBuilding(offerData: IOfferDetail): boolean | undefined {
  const categories = [
    'dailyBedRent',
    'dailyFlatRent',
    'dailyRoomRent',
    'flatSale',
    'newBuildingFlatSale',
    'roomSale',
    'flatShareSale',
  ];

  if (offerData.category != null && categories.indexOf(offerData.category) === -1) {
    return undefined;
  }
  return offerData.category === 'newBuildingFlatSale';
}

const overlapTypeMap: { [key in EOverlapType]: string | null } = {
  [EOverlapType.Concrete]: 'Железобетонные',
  [EOverlapType.Mixed]: 'Смешанные',
  [EOverlapType.Other]: null, // Иные (не отображать)
  [EOverlapType.Unknown]: null, // Не известно (не отображать)
  [EOverlapType.Wood]: 'Деревянные',
};

export function getOverlapType(overlapType?: EOverlapType): string | null {
  return overlapType && overlapTypeMap[overlapType] || null;
}

const heatingTypeMap: { [key in EHeatingType]: string | null } = {
  [EHeatingType.Autonomous]: 'Автономное',
  [EHeatingType.AutonomousBoiler]: 'Автономная котельная',
  [EHeatingType.AutonomousGas]: 'Автономное газовое',
  [EHeatingType.Boiler]: 'Котел/Квартирное отопление',
  [EHeatingType.Central]: 'Центральное',
  [EHeatingType.Coal]: 'Центральное угольное',
  [EHeatingType.Diesel]: 'Дизельное',
  [EHeatingType.Electric]: 'Электрическое',
  [EHeatingType.Fireplace]: 'Камин',
  [EHeatingType.Gas]: 'Центральное газовое',
  [EHeatingType.GasWaterHeater]: 'Индивидуальный тепловой пункт',
  [EHeatingType.Has]: 'Есть',
  [EHeatingType.ITP]: 'Индивидуальный тепловой пункт',
  [EHeatingType.None]: null, // Не указано (не отображать)
  [EHeatingType.Other]: null, // Другое (не отображать)
  [EHeatingType.SolidFuelBoiler]: 'Твердотопливный котел',
  [EHeatingType.Stove]: 'Печь',
  [EHeatingType.Without]: 'Нет',
};

export function getHeatingType(heatingType?: EHeatingType): string | null {
  return heatingType && heatingTypeMap[heatingType] || null;
}

const gasSupplyTypeMap: { [key in EGasSupplyType]: string | null } = {
[EGasSupplyType.Autonomous]: 'Автономное',
[EGasSupplyType.Central]: 'Центральное',
[EGasSupplyType.Unknown]: null, // Не известно (не отображать)
[EGasSupplyType.Without]: null, // Отсутствует (не отображать)
};

export function getGasSupplyType(gasSupplyType?: EGasSupplyType): string | null {
  return gasSupplyType && gasSupplyTypeMap[gasSupplyType] || null;
}

export function responseToHouseInfo(offer: IOfferDetail): IHouseInfo | undefined {
  if (offer.offerType === 'commercial') {
    return undefined;
  }

  const { building, geo } = offer;

  const priceSqmDiff = building && building.priceSqmDiff
    && round(parseNumberLike(building.priceSqmDiff), 1);
  const roomRentPriceDiff = building && building.roomRentPriceDiff
    && round(parseFloat(building.roomRentPriceDiff as string), 1);
  const avgPriceSqm = building && parseNumberLike(building.priceSqm);

  const houseInfo: IHouseInfo = {
    gkName: geo && geo.jk && geo.jk.name,
    seriesName: building && building.seriesName,
    villageName: offer.settlementName,
    buildYear: building && building.buildYear,
    houseType: mapHouseType(offer),
    batch: building && building.series,
    ceilingHeight: (building && building.ceilingHeight)
      ? parseNumberLike(building.ceilingHeight)
      : undefined,
    passengerElevatorCount: building && building.passengerLiftsCount,
    serviceElevatorCount: building && building.cargoLiftsCount,
    garbageChut: building && building.hasGarbageChute,
    parkingType: mapParkingType(offer),
    emergency: building && building.isEmergency,
    priceDynamics: priceSqmDiff ? parseNumberLike(priceSqmDiff) : undefined,
    rentalDynamics: roomRentPriceDiff ? parseNumberLike(roomRentPriceDiff) : undefined,
    rentalRate: mapRentalRate(offer),
    isNewBuilding: mapIsNewBuilding(offer),
    avgPriceSqm: avgPriceSqm,
    priceCian: building && building.priceCian,
    priceCianMin: building && building.priceCianMin,
    priceCianMax: building && building.priceCianMax,
    houseHeatSupplyType: building && getHeatingType(building.houseHeatSupplyType),
    houseGasSupplyType: building && getGasSupplyType(building.houseGasSupplyType),
    houseOverlapType: building && getOverlapType(building.houseOverlapType),
    // Подъезды
    entrances: building && building.entrances,

    hasBTIData: !!(building && (
      building.houseHeatSupplyType ||
      building.houseGasSupplyType ||
      building.houseOverlapType ||
      building.seriesName ||
      building.isEmergency != null ||
      building.materialType
    ))
  };
  return houseInfo;
}
