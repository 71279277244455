// TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348

import { IConfig } from '@cian/config/shared';

import { IExperimentResultSchema } from '../../api/models/ab_use';
import { TUserGaType } from '../../api/models/data_layer';

import {
  AGENT_TYPES,
  CONTROL_GROUP_TEXT_NODE,
  CONTROL_GROUP_URL_NODE,
  EXPERIMENTAL_GROUP_NAME_NODE,
  EXPERIMENTAL_GROUP_TEXT_NODE,
  EXPERIMENTAL_GROUP_URL_NODE,
  EXPERIMENT_NAME_NODE,
} from './constants';

interface IProps {
  config: IConfig;
  experiments?: IExperimentResultSchema[];
  userType: TUserGaType;
}

export const getPlacementProps = ({ config, experiments, userType }: IProps) => {
  if (!config) {
    return {
      text: null,
      url: null,
    };
  }

  const experimentName = config.get<string>(EXPERIMENT_NAME_NODE);
  const experimentalGroupName = config.get<string>(EXPERIMENTAL_GROUP_NAME_NODE);

  const experimentalProps = {
    text: config.get<string>(EXPERIMENTAL_GROUP_TEXT_NODE),
    url: config.get<string>(EXPERIMENTAL_GROUP_URL_NODE),
  };

  const controlProps = {
    text: config.get<string>(CONTROL_GROUP_TEXT_NODE),
    url: config.get<string>(CONTROL_GROUP_URL_NODE),
  };

  const isAgent = AGENT_TYPES.includes(userType);

  if (userType === null || isAgent || !experimentName || !experimentalGroupName) {
    return {
      text: null,
      url: null,
    };
  }

  const experimentGroup = experiments?.find(experiment => experiment.experimentName === experimentName)?.groupName;

  const isExperimentalProps = experimentGroup === experimentalGroupName;

  return isExperimentalProps ? experimentalProps : controlProps;
};
