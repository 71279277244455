export const capitalize = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

/**
 * Обрезает слишком длинный текст и к концу строки добавляется многоточие.
 * @param text Исходный текст
 * @param maxLength максимальная длина строки
 * @returns строку длиной не более чем указанную в maxLength
 */
export function ellipsis(text: string, maxLength: number): string {
  return text.length > maxLength ? `${text.slice(0, maxLength - 1)}…` : text;
}

export const isLetter = (char: string): boolean => {
  return char.length === 1 && char.toLowerCase() !== char.toUpperCase();
};
