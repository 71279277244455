import { isEqual } from 'lodash';
import * as React from 'react';

import { SelectMobile } from '../../common/select/select';
import { createSelectOptions, IOption } from '../../common/select_helper';
import {
  ESuburbanOfferFilter,
  IFieldProps,
  IsVisibleCheck,
  isSuburbanObjectGroup,
  isSaleType,
} from '../field';

import * as styles from '../field.css';

export const SUBURBAN_OFFER_FILTER_LABELS: {[key: number]: string} = {
  [ESuburbanOfferFilter.Any]: 'От любого владельца',
  [ESuburbanOfferFilter.Builder]: 'От застройщиков',
  [ESuburbanOfferFilter.Individual]: 'От собственников и агентов',
};

const suburbanOfferFilterOptions: IOption[] = Object.keys(SUBURBAN_OFFER_FILTER_LABELS).map(key => ({
  id: key,
  label: SUBURBAN_OFFER_FILTER_LABELS[Number(key)],
}));

export class Component extends React.Component<IFieldProps> {
  private options = [{
    label: '',
    options: suburbanOfferFilterOptions,
  }];

  public shouldComponentUpdate(nextProps: IFieldProps) {
    return !isEqual(this.props.filter.suburbanOfferFilter, nextProps.filter.suburbanOfferFilter);
  }

  public render() {
    const processedOptions = createSelectOptions(
      this.options,
      [String(this.props.filter.suburbanOfferFilter || 0)],
      this.props.filter,
    );

    return (
      <div className={styles['field']} data-mark="FieldSuburbanOfferFilter">
        <SelectMobile
          id="suburban-offer-filter"
          name="suburbanOfferFilter"
          label="От любого владельца"
          onValueChange={this.props.actions.setSuburbanOfferFilter}
          value={processedOptions.value}
          options={processedOptions.options[0].options}
        />
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter => isSaleType(filter) && isSuburbanObjectGroup(filter);
