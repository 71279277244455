import { IMetrics } from './shared';

interface IMeasure {
  name: string;
  startMark: string;
  endMark: string;
}

export class ClientMetrics implements IMetrics {
  private queue: Array<IMeasure> = [];
  private isNavigationApiSupported: boolean =
    typeof performance !== 'undefined' &&
    !!performance.mark &&
    !!performance.measure &&
    !!performance.timing &&
    !!performance.navigation;
  private readonly options: { prefix: string };

  public constructor(options: { prefix: string }) {
    this.options = options;

    if (this.isNavigationApiSupported) {
      setTimeout(() => {
        window.addEventListener('load', () => {
          setTimeout(this.tryMeasureQueue);
          setInterval(this.tryMeasureQueue, 10000);
        });
      });
    }
  }

  public mark(markName: string): void {
    if (this.isNavigationApiSupported) {
      performance.mark(markName);
      setTimeout(this.tryMeasureQueue);
    }
  }

  public waitMarksAndMeasure(name: string, startMark: string, endMark: string): void {
    if (this.isNavigationApiSupported) {
      this.queue.push({ name, startMark, endMark });
      setTimeout(this.tryMeasureQueue);
    }
  }

  private tryMeasureQueue = (): void => {
    this.queue.forEach((measure, index) => {
      if (this.isMarkRegistered(measure.startMark) && this.isMarkRegistered(measure.endMark)) {
        performance.measure(`${this.options.prefix}.${measure.name}`, measure.startMark, measure.endMark);
        this.queue.splice(index, 1);
      }
    });
  };

  private isMarkRegistered(markName: string): boolean {
    return performance.getEntriesByName(markName).length > 0 || !!(performance.timing as any)[markName];
  }
}
