
import { INotificationsCountProps, withNotificationsCount } from '@cian/chats-notifications-connect';
import * as React from 'react';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ReduxState } from '../../redux/model';
import { IChatsConfig, inject } from '../../utils/context_provider';

const styles = require('./index.css');

interface ISidebarUnreadsStoreProps {
  isUserAuthenticated: boolean;
}

interface ISidebarUnreadsContextProps {
  chats: IChatsConfig;
}

type TSidebarUnreadsProps = ISidebarUnreadsStoreProps & ISidebarUnreadsContextProps;

export function SidebarUnreads(props: INotificationsCountProps & Partial<TSidebarUnreadsProps>) {
  if (props.notificationsCount && props.notificationsCount > 0) {
    return (
      <span className={styles['icon']}>
        {props.notificationsCount}
      </span>
    );
  }

  return null;
}

class SidebarUnreadsDecorated extends PureComponent<TSidebarUnreadsProps, {}> {
  public render() {
    const { chats: { unreadContext }, isUserAuthenticated } = this.props;

    if (unreadContext) {
      if (!isUserAuthenticated) {
        return <SidebarUnreads {...this.props} notificationsCount={null} />;
      }

      const DecoratedComponent = withNotificationsCount(unreadContext)(SidebarUnreads);
      return <DecoratedComponent {...this.props} />;
    }

    // tslint:disable-next-line:no-any
    return null as any;
  }
}

const mapStoreProps = (state: ReduxState): ISidebarUnreadsStoreProps => {
  return {
    isUserAuthenticated: state.common.isAuthenticated,
  };
};

export const SidebarUnreadsContainer = connect<ISidebarUnreadsStoreProps, {}, {}>(
  mapStoreProps,
)(inject(['chats'])<ISidebarUnreadsStoreProps>(SidebarUnreadsDecorated));
