import { ca } from '@cian/analytics';

export function trackSubscriptionPriceSuccess(
  showPushNotificationCheckbox: boolean,
  isPushCheck: boolean,
) {
  let label = 'From_card_ChangePrice';

  if (showPushNotificationCheckbox) {
    const pushOnOff = isPushCheck ? 'PushOn' : 'PushOff';

    label += `/${pushOnOff}`;
  }

  ca('event', {
    action: 'Send',
    category: 'subscription',
    label,
    name: 'oldevent',
  });
}

export function trackSubscriptionRegistration() {
  ca('event', {
    action: 'subscription',
    category: 'Registration',
    label: 'From_card_ChangePrice',
    name: 'oldevent',
  });
}

export function trackSubscriptionNewsSuccess() {
  ca('event', {
    action: 'Send',
    category: 'subscription',
    label: 'news_From_card_ChangePrice',
    name: 'oldevent',
  });
}

export function trackUnsubscriptionSuccess() {
  ca('event', {
    action: 'Send',
    category: 'unsubscription',
    label: 'From_card_ChangePrice',
    name: 'oldevent',
  });
}
