/* eslint-disable max-lines */
import { last } from 'lodash';
import { Reducer } from 'redux';

import { IJsonQuery } from '../../api/models/json_query';
import { IHideOfferInfo } from '../hide_offer/actions';
import { ISetOfferFavoriteStatusParams, TOffersListActions } from '../offers_list/actions';
import { removePopupParams } from '../popups/actions';

import {
  IGkSource,
  IInitializedSearchOffersState,
  IOfferSource,
  IPage,
  SearchOffersState,
  defaultSearchOffersState,
  defaultSubscriptionState,
  isKpOffers,
} from './model';
import {
  decrementDescription,
  getExistingOffersCount,
  isCommercialList,
  isEmptyList,
  isSpecificGkList,
  isSpecificKpList,
  parsePage,
} from './parser';

const setFavorite =
  (params: ISetOfferFavoriteStatusParams) =>
  (offer: IOfferSource): IOfferSource => {
    if (
      offer.data.urlParams.id !== params.offerId ||
      offer.data.urlParams.objectType !== params.objectType ||
      offer.data.urlParams.dealType !== params.dealType ||
      offer.data.isFavorite === params.state
    ) {
      return offer;
    }

    return {
      ...offer,
      data: {
        ...offer.data,
        isFavorite: params.state,
      },
    };
  };

const filterHidden =
  (params: IHideOfferInfo) =>
  (offer: IOfferSource): boolean => {
    return offer.data.urlParams.id !== params.requestInfo.offerId;
  };

function normalizeCountOnNextPage(num: number): number {
  return Math.max(0, Math.min(num, 25));
}

const reducer: Reducer<SearchOffersState, TOffersListActions> = (state = defaultSearchOffersState, action) => {
  switch (action.type) {
    case 'AddSearchOffers': {
      const jsonQuery = state.initialized ? state.jsonQuery : action.offers.jsonQuery;
      const listIsSpecificGkList = isSpecificGkList(jsonQuery, action.isNewobjectOnly);

      // get isGkList
      const isGkList = action.offers.isNewobjectsEnabled;
      const isNextGkPageEmpty =
        isGkList && Array.isArray(action.offers.newobjectsSerialized) && action.offers.newobjectsSerialized.length < 25;

      const newPage = parsePage(action.offers, {
        isSuggestionPage: false,
        isGkPage: isGkList,
        pageNumber: state.initialized
          ? last(state.pages)!.num + 1 // eslint-disable-line @typescript-eslint/no-non-null-assertion
          : 1,
        isCommercialPageList: isCommercialList(jsonQuery),
      });
      const existingPages = state.initialized ? state.pages : [];
      const pages = [...existingPages, newPage];

      // get offersCountOnNextPage
      const existingsCount = getExistingOffersCount(pages, isGkList);
      const objectsLeft = action.offers.aggregatedCount - existingsCount;
      const offersCountOnNextPage = isNextGkPageEmpty ? 0 : normalizeCountOnNextPage(objectsLeft);
      /** Получаем предложения по КП из ответа */
      const kp = isKpOffers(action.offers.kp) ? action.offers.kp : undefined;

      const { pikPhoneReplacement = '', mlRankingGuid, mlRankingModelVersion } = action.offers;

      /*
        Устанавливаем уникальный id поискового запроса.

        Если пагинация, то берем id текущего запроса из стора, иначе берем новый из апи
        При новом запросе id обнуляется событием ResetSearchOffers
      */
      const searchUuid = (state.initialized && state.searchUuid) || action.offers.searchUuid;

      const suggestionsQuery = state.initialized ? state.suggestionsQuery : action.offers.suggestionsQuery;

      const newState: SearchOffersState = {
        initialized: true,
        isNotFound: false,
        loading: state.initialized ? state.loading : false,
        jsonQuery: state.initialized ? state.jsonQuery : action.offers.jsonQuery,
        queryString: action.offers.queryString,
        suggestionsQuery: !action.withoutSuggestions ? suggestionsQuery : undefined,
        seoData: state.initialized ? state.seoData : action.offers.seoData,
        markedList: state.initialized ? state.markedList : action.offers.markedList,
        seoSchemas: state.initialized ? state.seoSchemas : action.offers.seoSchemas,
        breadcrumbs: state.initialized ? state.breadcrumbs : action.offers.breadcrumbs,
        pages,
        offersCountOnNextPage,
        pikPhone: pikPhoneReplacement.replace(/[ -]/g, ''),
        // CD-13359 эксперимент для коммерческой с карточкой
        callButtonVariant: action.offers.callButtonVariant,
        isCallButtonEnabled: action.offers.isCallButtonEnabled,
        isGkList,
        isSpecificGkList: listIsSpecificGkList,
        isSpecificKpList: isSpecificKpList(jsonQuery),
        subscription: defaultSubscriptionState,
        lastUrl: removePopupParams(action.lastUrl),
        isEmptyList: isEmptyList(pages, isGkList),
        subscribedLists: state.subscribedLists,
        suggestionsMl: {},
        searchUuid,
        mlRankingGuid,
        mlRankingModelVersion,
        kp,
        salesDescription: state.initialized ? state.salesDescription : action.offers.salesDescription,
      };

      return newState;
    }

    case 'HideShowMoreButton': {
      return {
        ...state,
        hideShowMoreButton: true,
      };
    }

    case 'MLSuggestionsSuccess': {
      const suggestions =
        state.initialized && state.suggestionsMl && state.suggestionsMl.suggestions
          ? [...state.suggestionsMl.suggestions, ...action.suggestions]
          : action.suggestions;

      return {
        ...state,
        suggestionsMl: {
          pageNumber: action.pageNumber,
          shown: suggestions.length,
          suggestions,
        },
      };
    }

    case 'ResetSearchOffers': {
      return {
        initialized: false,
        lastUrl: state.lastUrl,
        subscribedLists: state.subscribedLists,
        hideShowMoreButton: false,
      };
    }

    case 'SetOfferFavorite': {
      if (!state.initialized) {
        return state;
      }

      const pages = state.pages.map(page => {
        return {
          ...page,
          offers: page.offers.map(setFavorite(action.payload)),
          suggestions: page.suggestions.map(object => {
            if (object.type === 'offer') {
              return setFavorite(action.payload)(object);
            }

            return object;
          }),
        };
      });

      const suggestions = state.suggestionsMl && state.suggestionsMl.suggestions;

      return {
        ...state,
        pages,
        suggestionsMl: {
          ...state.suggestionsMl,
          suggestions:
            suggestions &&
            suggestions.map(suggestion => {
              if (
                suggestion.id === action.payload.offerId &&
                suggestion.dealType === action.payload.dealType &&
                suggestion.isFavorite !== action.payload.state
              ) {
                return {
                  ...suggestion,
                  isFavorite: action.payload.state,
                };
              } else {
                return suggestion;
              }
            }),
        },
      };
    }

    case 'SetHideOffer': {
      if (!state.initialized) {
        return state;
      }
      const pages = state.pages.map(page => {
        return {
          ...page,
          offers: page.offers.filter(filterHidden(action.payload)),
          suggestions: page.suggestions.filter(object => {
            if (object.type === 'offer') {
              return filterHidden(action.payload)(object);
            }

            return object;
          }),
        };
      });

      const suggestions = state.suggestionsMl && state.suggestionsMl.suggestions;
      const {
        seoData: { description },
      } = state;
      const { isSuggestion } = action.payload;

      return {
        ...state,
        seoData: {
          ...state.seoData,
          description: isSuggestion ? description : decrementDescription(description),
        },
        pages,
        suggestionsMl: {
          ...state.suggestionsMl,
          suggestions:
            suggestions &&
            suggestions.filter(suggestion => {
              return suggestion.id !== action.payload.requestInfo.offerId;
            }),
        },
      };
    }

    case 'SetSubscription': {
      return {
        ...state,
        subscription: {
          status: action.status,
          email: action.email,
        },
      };
    }

    case 'SetSearchOffersLoading': {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case 'SubscribeList':
      return {
        ...state,
        subscribedLists: state.subscribedLists.concat({ ...action.payload }),
      };

    case 'NotFoundPage': {
      return {
        ...state,
        isNotFound: true,
      };
    }

    case 'changeSorting': {
      const {
        payload: { sortType },
      } = action;

      if (state.initialized) {
        const {
          jsonQuery: { sort, ...restJsonQuery },
        } = state;

        return sortType === 'default'
          ? { ...state, jsonQuery: restJsonQuery }
          : { ...state, jsonQuery: setSorting(state.jsonQuery, sortType) };
      } else {
        return state;
      }
    }

    case 'ChangeGkFavoriteStatus': {
      const initedState = state as IInitializedSearchOffersState;
      if (!initedState.pages) {
        return state;
      }

      return {
        ...initedState,
        pages: initedState.pages.map((page: IPage) => ({
          ...page,
          gks: page.gks.map((gk: IGkSource) => ({
            ...gk,
            data: {
              ...gk.data,
              isFavorite: action.payload.id === Number(gk.data.id) ? action.payload.state : gk.data.isFavorite,
            },
          })),
        })),
      };
    }

    default: {
      return state;
    }
  }
};

function setSorting(jsonQuery: IJsonQuery, sortingType: string): IJsonQuery {
  return { ...jsonQuery, sort: { type: 'term', value: sortingType } };
}

// eslint-disable-next-line import/no-default-export
export default reducer;
