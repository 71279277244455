import { isEqual } from 'lodash';
import * as React from 'react';

import { ButtonCheckbox, Label } from '@cian/components';
import { commercialObjectType, DealType } from '../../../redux/filters/model';
import ButtonCheckboxContainer from '../../../ui/button_checkbox_container/button_checkbox_container';
import { ContractType, IFieldProps, isCommercialObjectGroup, IsVisibleCheck } from '../field';

const styles = require('../field.css');

interface IOption {
  id: number;
  text: string;
}

const contractTypeOptions: { Sale: IOption[], Rent: IOption[] } = {
  Sale: [
    {
      id: ContractType.SaleObject,
      text: 'Продажа объекта',
    },
    {
      id: ContractType.AssignmentRentRights,
      text: 'Переуступка прав аренды',
    },
  ],
  Rent: [
    {
      id: ContractType.DirectRent,
      text: 'Прямая аренда',
    },
    {
      id: ContractType.Subrent,
      text: 'Субаренда',
    },
  ],
};

export class Component extends React.Component<IFieldProps, object> {

  public shouldComponentUpdate(nextProps: IFieldProps) {
    const { filter: { contractTypes: nextContract, dealType: nextDeal } } = nextProps;
    const { filter: { contractTypes: currentContract, dealType: currentDeal } } = this.props;
    return !isEqual(currentContract, nextContract) || !isEqual(currentDeal, nextDeal);
  }

  public render() {
    const {
      filter: { contractTypes: selectedContractTypes, dealType },
      actions: { toggleContractType },
    } = this.props;
    const buttonCheckboxStyle = [
      { flexGrow: 0 },
      styles.buttonCheckbox,
    ];

    const options = dealType === DealType.Sale ? contractTypeOptions.Sale : contractTypeOptions.Rent;
    return (
      <div className={styles.field} data-mark="FieldContractType">
        <Label label="Тип договора">
          <ButtonCheckboxContainer>
            {options.map(contractType =>
              <ButtonCheckbox
                mobile
                checked={selectedContractTypes.includes(contractType.id)}
                onChange={() => toggleContractType(contractType.id)}
                key={contractType.id}
                wide
                buttonCheckboxStyle={buttonCheckboxStyle}
              >
                {contractType.text}
              </ButtonCheckbox>,
            )}
          </ButtonCheckboxContainer>
        </Label>
      </div>
    );
  }
}

export const isVisible: IsVisibleCheck = filter =>
  isCommercialObjectGroup(filter) && !filter.selectedObjectTypes.includes(commercialObjectType.Garage)
  && !(filter.selectedObjectTypes.includes(commercialObjectType.CommercialLand) && filter.dealType === DealType.Sale)
  ;
