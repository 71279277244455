import { IFavoritesOptions, IFavoriteStats } from '../../api/api';
import { INewobjectSerialized } from '../../api/models/gk_preview';
import { IOfferDetail } from '../../api/models/offer_card';
import { IOfferInfo } from '../offer/model';
import { parseOfferDetail } from '../offer/response_parser/offer_parser';
import { IGk, parseGk } from '../offers_list/gk';
import { parseUrlParams } from '../offers_list/offer';
import { TFavouritesActions } from './actions';
import { defaultFavoritesState, FavoritesState, IFavouritePage } from './model';

export function parseOffers(offers: IOfferDetail[]): IOfferInfo[] {
  return offers.map((item: IOfferDetail, index: number) => {
    return parseOfferDetail(item, parseUrlParams(item), index);
  });
}

//TODO: Добавить аналитику CD-28397
export const parseNewObjects = (newobjects: INewobjectSerialized[], analytics: {}): IGk[] => {
  if (!Array.isArray(newobjects)) { return []; }

  return newobjects.map(item => parseGk(item, analytics));
};

interface IPageItemLengthInfo {
  prevOffersLength: number;
  prevNewObjectsLength: number;
  prevFavoritesLength: number;
}

const createPagesFromOffers = (
  state: FavoritesState,
  newPageNum: number,
  lengthInfo: IPageItemLengthInfo,
): IFavouritePage[] => {

  const existingPages = state.pagesInitialized
    ? state.pages
    : [] as IFavouritePage[];
  const { offers, newobjects, favourites } = state.favouriteItems;

  return [
    ...existingPages,
    {
      num: newPageNum,
      offers: offers.slice(lengthInfo.prevOffersLength),
      newobjects: newobjects.slice(lengthInfo.prevNewObjectsLength),
      favourites: favourites.slice(lengthInfo.prevFavoritesLength),
    } as IFavouritePage,
  ];
};

const getTotal = (options: IFavoritesOptions, stats: IFavoriteStats) => {
  let total = options.dealType === 'all'
    ? stats.total
    : stats.perDealType[options.dealType as string];

  if (options.offerType !== undefined) {
    total = stats.perOfferType[options.offerType as string];
  }

  return total;
};

type Reducer = (state: FavoritesState, action: TFavouritesActions) => FavoritesState;

// TODO: https://cianru.atlassian.net/browse/CD-47948
/* ignore-istanbul-next */
const reducer: Reducer = (state = defaultFavoritesState, action) => {
  switch (action.type) {
    case 'SetFavoritesOffers': {
      const offers = parseOffers(action.favouriteItems.offers);
      const newobjects = parseNewObjects(action.favouriteItems.newobjects, {});
      const newobject = action.favouriteItems.stats.perOfferType.newobject || 0;

      return {
        ...state,
        options: action.options,
        favouriteItems: {
          ...action.favouriteItems,
          offers,
          newobjects,
          favourites: action.favouriteItems.favorites,
          totalCurrent: getTotal(action.options, action.favouriteItems.stats),
          stats: {
            ...action.favouriteItems.stats,
            perOfferType: {
              ...action.favouriteItems.stats.perOfferType,
              newobject: action.options.dealType === 'rent' ? 0 : newobject,
            },
          },
        },
        loading: state.pagesInitialized ? state.loading : false,
        pagesInitialized: true,
        pages: [{
          num: 1,
          offers,
          newobjects,
          favourites: action.favouriteItems.favorites,
        }],
      };
    }

    case 'AddFavoritesOffers': {
      const offers = parseOffers(action.favouriteItems.offers);
      //TODO: Добавить аналитику CD-28397
      const newobjects = parseNewObjects(action.favouriteItems.newobjects, {});
      const newobject = action.favouriteItems.stats.perOfferType.newobject || 0;

      const newState = {
        ...state,
        options: action.options,
        favouriteItems: {
          ...action.favouriteItems,
          offers: [
            ...state.favouriteItems.offers,
            ...offers,
          ],
          newobjects: [
            ...state.favouriteItems.newobjects,
            ...newobjects,
          ],
          favourites: [
            ...(state.favouriteItems.favourites || []),
            ...action.favouriteItems.favorites,
          ],
          totalCurrent: getTotal(action.options, action.favouriteItems.stats),
          stats: {
            ...action.favouriteItems.stats,
            perOfferType: {
              ...action.favouriteItems.stats.perOfferType,
              newobject: action.options.dealType === 'rent' ? 0 : newobject,
            },
          },
        },
        loading: state.pagesInitialized ? state.loading : false,
        pagesInitialized: true,
      };
      const pages = createPagesFromOffers(newState, action.options.page || 1, {
        prevOffersLength: state.favouriteItems.offers.length,
        prevNewObjectsLength: state.favouriteItems.newobjects.length,
        prevFavoritesLength: (state.favouriteItems.favourites || []).length,
      });

      return {
        ...newState,
        pages,
      };
    }

    case 'RemoveFavoriteOfferFromStore': {
      return {
        ...state,
        options: action.options,
        pages: action.pages,
        favouriteItems: {
          ...state.favouriteItems,
          totalCurrent: getTotal(action.options, action.stats),
          stats: action.stats,
        },
      };
    }

    case 'SetFavoritesCountSuccess':
      return { ...state, initialized: true, count: action.count };

    case 'SetFavoritesIDs':
      return { ...state, ids: { ...action.payload } };

    case 'SetFavoritesStats':
      return {
        ...state,
        favouriteItems: {
          ...state.favouriteItems,
          stats: action.stats,
          totalCurrent: getTotal(state.options, action.stats),
        },
      };

    case 'SetFavoriteFilters': {
      return {
        ...state,
        options: {
          ...state.options,
          dealType: action.dealType,
          offerType: action.offerType,
        },
      };
    }

    case 'SetFavoriteTotal': {
      return {
        ...state,
        count: action.total,
      };
    }

    case 'ClearPages':
      return {
        ...state,
        pages: [],
      };

    case 'SetFavoritesOffersIds' :
    case 'SetFavoriteOffersLoading':
      return { ...state, ...action };

    case 'ClearFavorites':
      return defaultFavoritesState;

    default:
      return state;
  }
};

export default reducer;
