import { IUserRegion } from '../redux/common/model';
import { rentTimeDict } from '../redux/filters/dictionaries';
import { DealType, FilterState, RentTime } from '../redux/filters/model';
import translit from './translit';
import russianDictionary from './translit_russian';

interface IGetChangeGeoGALabelParams {
  region: IUserRegion;
  filter: FilterState;
}

export function getChangeGeoGaLabel(params: IGetChangeGeoGALabelParams): string {
  const { region, filter } = params;
  const { dealType, rentTime, selectedObjectTypes } = filter;

  const gaLabel: string[] = [];

  const dealTypeLabel = mapDealType(dealType, rentTime);
  const regionNameLabel = region.name ? mapRegionName(region.name) : '';
  const objectTypesLabel = mapObjectTypes(selectedObjectTypes);
  const regionIdLabel = mapRegionId(region);

  gaLabel.push(dealTypeLabel);
  gaLabel.push(objectTypesLabel);
  gaLabel.push(regionNameLabel);
  gaLabel.push(regionIdLabel);

  return gaLabel.join('/');
}

function mapDealType(dealType: DealType, rentTime: RentTime): string {
  switch (dealType) {
    case DealType.Sale: {
      return 'buy';
    }

    case DealType.Rent: {
      if (rentTime === rentTimeDict.Short) {
        return 'short';
      } else {
        return 'long';
      }
    }

    default:
      return '';
  }
}

function mapObjectTypes(objectTypes: string[]): string {
  return objectTypes.join(',');
}

function mapRegionName(regionName: string): string {
  const translitRussian = translit(russianDictionary);
  const parsedRegionName = regionName.toLowerCase().replace(/ /g, '_');

  return translitRussian(parsedRegionName);
}

function mapRegionId(region: IUserRegion): string {
  const regionId = region.parentId || region.id;

  return regionId ? String(regionId) : '';
}
