import { chunk, flatMap, without } from 'lodash';

import { IOfferSerialized } from '../api/models/offer_preview';
import { isGoogleBot, isYandexBot } from './user_agent';

export interface IAddEveryOptions<T> {
  count: number;
  getElement: (block: T[], index: number) => T;
  ignoreLast: boolean;
}

export function addEvery<T>({ count, getElement, ignoreLast = true }: IAddEveryOptions<T>, arr: T[]): T[] {
  return flatMap(chunk(arr, count), (block, index) => {
    if (ignoreLast && arr.length <= (index + 1) * count) {
      return block;
    }
    return block.concat(getElement(block, index));
  });
}

/**
 * @description determine if an `arr` contains one or more items from `haystack`.
 * @param {array} haystack the array to search.
 * @param {array} arr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
export function hasOne<T>(haystack: T[], arr: T[]): boolean {
  return arr.some(v => haystack.indexOf(v) > -1);
}

//tslint:disable-next-line: no-any
export function ifNull(value: any, defaultValue?: any): any {
  return value != null ? value : defaultValue;
}

export function toggleItem<T>(arr: T[], item: T): T[] {
  if (arr.includes(item)) {
    return without(arr, item);
  }
  return arr.concat(item);
}

export function parseNumberLike(value: string | number): number;
export function parseNumberLike(value: string | number | undefined | null): number | undefined;
export function parseNumberLike(value: string | number | undefined | null): number | undefined {
  if (value == null) {
    return undefined;
  }
  if (isNaN(+value)) {
    return undefined;
  }
  return parseFloat(value.toString());
}

export function limitStringSize(str: string, maximumSymbols: number) {
  if (maximumSymbols < 1) {
    return '';
  }

  return str.length > maximumSymbols
    ? `${str.slice(0, maximumSymbols - 1)}…`
    : str;
}

export function identity<T>(data: T): T {
  return data;
}

export function cutDescriptionFromOffers(offers: IOfferSerialized[], userAgent: string): IOfferSerialized[] {
  if (isYandexBot(userAgent) || isGoogleBot(userAgent)) {
    return offers.map(offer => ({
      ...offer,
      description: offer.isDuplicatedDescription ? undefined : offer.description,
    }));
  }

  return offers;
}

// tslint:disable-next-line:no-any
export function isArrayWithItems<T>(value: any): value is T[] {
  return Array.isArray(value) && value.length > 0;
}

// tslint:disable-next-line:no-any
export function isEmptyArray<T>(value: any): value is T[] {
  return Array.isArray(value) && value.length === 0;
}
