export function getRightDeclensionForOfferType(offerTypeText: string): string {
  if (offerTypeText.toLowerCase().startsWith('многокомнатная')) {
    return 'многокомнатную квартиру';
  }

  if (offerTypeText.toLowerCase().endsWith('квартира')) {
    return offerTypeText.replace('квартира', 'квартиру');
  }

  if (offerTypeText.toLowerCase().endsWith('комната')) {
    return 'комнату';
  }

  switch (offerTypeText.toLowerCase()) {
    case 'доля':
      return 'долю';

    case 'квартира своб. планировка':
      return 'квартиру своб. планировки';

    case 'студия':
      return 'студию';

    case 'апартаменты-студия':
      return 'апартаменты-студию';

    default:
      return offerTypeText;
  }
}
