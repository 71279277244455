import { PopupParamName } from './actions';

export type PopupsState = {
  activePopup: PopupParamName;
  sidebarIsOpened: boolean;
};

export const defaultPopupsState: PopupsState = {
  activePopup: 'none',
  sidebarIsOpened: false,
};
