import * as React from 'react';

import { Field } from '../../field';
import * as fields from '../../fields/';
import { IFieldProps, isFlatObjectGroup, IsVisibleCheck } from '../field';

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    return (
      <Field field={fields.floor} />
    );
  }
}

export const isVisible: IsVisibleCheck = isFlatObjectGroup;
