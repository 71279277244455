export const COMMERCIAL_MOBILE_SLICE = 'commercial_mobile_slice';
export const SUGGEST_TWO_LINES_MOB = 'suggest_two_string_mainpage_mob';
export const SUBURBAN_OFFER_FILTER_HIDDEN = 'suburban_offer_filter_hidden';
export const AUDIENCE_NEW_MOBILE_FILTERS = 'audience_new_mobile_filters';

export const EXPERIMENTS: string[] = [
  COMMERCIAL_MOBILE_SLICE,
  SUGGEST_TWO_LINES_MOB,
  SUBURBAN_OFFER_FILTER_HIDDEN,
  AUDIENCE_NEW_MOBILE_FILTERS,
];
