import * as React from 'react';

import { IStyleConfig, mergeStyles } from '@cian/components';
import Icon from '../icon/icon';

const styles = require('./block_list.css');

interface IBlockListProps {
  listStyle?: IStyleConfig;
  listItemStyle?: IStyleConfig;
  data: IBlockArrowItemData[];
  onItemClick: (item: IBlockArrowItemData) => void;
}

interface IBlockArrowItemProps {
  itemStyles?: IStyleConfig;
  item: IBlockArrowItemData;
  dataMark?: string;
  onItemClick: (item: IBlockArrowItemData) => void;
}

interface IBlockArrowItemData {
  name?: string;
}

export const BlockArrowItem: React.SFC<IBlockArrowItemProps> =
  ({ item, onItemClick, dataMark }: IBlockArrowItemProps) => {
    return (
      <li
        className={styles.item}
        data-mark={`${dataMark ? dataMark + '|' : ''}BlockArrowItem`} >
        <button
          {...mergeStyles(
            styles.itemButton,
          )}
          type="button"
          onClick={() => onItemClick(item)}
        >
          <div {...mergeStyles(
            styles.text,
          )}>
            {item.name}
          </div>
          <Icon name="arrow" styleConfig={styles.arrow}/>
        </button>
      </li>
    );
  };

/** Список в виде блоков */
// todo scu
export const BlockArrowList: React.SFC<IBlockListProps> =
  ({ data, listStyle, onItemClick }: IBlockListProps) => {
    const listMergedStyles = mergeStyles(
      styles.list,
      listStyle,
    );

    return (
      <ul {...listMergedStyles}>
        {data.map((item: IBlockArrowItemData, index) =>
          <BlockArrowItem onItemClick={onItemClick} item={item} key={index} />,
        )}
      </ul>
    );
  };
