import { TTypedThunkAction } from '../model';
import { IGetFavoriteCountByOfferRequest } from '../../api/api';
import { toPromise } from '../../utils/streams';

export interface IGetFavoriteCountByOfferResponse {
  anonymous?: number | null;
  total?: number | null;
  registered?: number | null;
}

export interface IFavoritesCountByOfferState {
  favoritesUsersCountForCard: IGetFavoriteCountByOfferResponse | null;
  favoritesMinUsersCountForCard: number;
}

export interface ISetFavoritesCountByOffer {
  type: 'setFavoritesUsersCountForCard';
  payload: {
    data: IGetFavoriteCountByOfferResponse;
  };
}

export interface IClearFavoritesCountByOffer {
  type: 'clearFavoritesCountByOffer';
}

export type TFavoritesCountByOfferActions = ISetFavoritesCountByOffer | IClearFavoritesCountByOffer;

export function setFavoritesUsersCountForCard(data: IGetFavoriteCountByOfferResponse): ISetFavoritesCountByOffer {
  return {
    type: 'setFavoritesUsersCountForCard',
    payload: {
      data,
    },
  };
}

export function clearFavoritesCountByOffer(): IClearFavoritesCountByOffer {
  return {
    type: 'clearFavoritesCountByOffer',
  };
}

const defaultState = {
  favoritesUsersCountForCard: null,
  // 6 дефолтное значение из рантайм сетингов (переменная favoritesMinCountByOffer)
  favoritesMinUsersCountForCard: 6,
};

export function getFavoritesUsersCountForCard(
  params: IGetFavoriteCountByOfferRequest,
): TTypedThunkAction<Promise<void | {}>> {
  return (dispatch, _, { api, logger }) => {
    return toPromise(api.getFavoritesCountByOffer(params))
      .then(response => {
        const { result } = response;

        dispatch(setFavoritesUsersCountForCard(result));
      })
      .catch(error => {
        logger.error(error);
      });
  };
}

export function favoritesCountByOfferReducer(
  state: IFavoritesCountByOfferState = defaultState,
  action: TFavoritesCountByOfferActions,
) {
  switch (action.type) {
    case 'setFavoritesUsersCountForCard':
      return {
        ...state,
        favoritesUsersCountForCard: action.payload.data,
      };

    case 'clearFavoritesCountByOffer':
      return {
        ...state,
        favoritesUsersCountForCard: null,
      };

    default:
      return state;
  }
}
