import { fetchGetUsersAuction } from '../../../repositories/commercial-electronic-auction/v1/get-users-auction';

import { mappers } from './mappers';
import { EGetUsersAuctionStatus, IGetUsersAuctionDependencies, IGetUsersAuctionResult } from './types';

export async function getUsersAuction(dependencies: IGetUsersAuctionDependencies): Promise<IGetUsersAuctionResult> {
  const { httpApi, logger, config } = dependencies;

  const timeout = config.get<number>('frontendMobileWebsite.getUsersAuction.timeout') || 1000;

  try {
    const result = (await fetchGetUsersAuction({
      config: { ecnhancersConfig: { timeout } },
      httpApi,
      mappers,
    })) as IGetUsersAuctionResult;

    if (result.status === EGetUsersAuctionStatus.Error) {
      logger.warning(result.message, { description: 'При запросе данных по торгам пришёл 400 статус от сервера' });
    }

    return result;
  } catch (e) {
    const message = 'При запросе коллекции id торгов на аукционах произошла ошибка';

    logger.error(e, { description: message });

    return {
      message,
      status: EGetUsersAuctionStatus.CriticalError,
      userIds: [],
    };
  }
}
