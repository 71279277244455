import { IStyleConfig, mergeStyles } from '@cian/components';
import * as React from 'react';
import { connect } from 'react-redux';
import SelectRegionWithCities from '../../../components/select_region_with_cities/select_region_with_cities';
import { openPopup, OpenPopupDispatchProp, PopupParamName } from '../../../redux/popups/actions';
import Preloader from '../../../ui/preloader/preloader';

const styles = require('./region.css');

type RegionOwnProps = {
  isLoading: boolean;
  displayName: string;
  selectedRegionStyle?: IStyleConfig;
  popupQueryParam: PopupParamName;
  dataMark?: string;
};

type RegionProps = RegionOwnProps & OpenPopupDispatchProp;

class Component extends React.PureComponent<RegionProps, object> {
  public render() {
    const { props } = this;

    return (
      <span>
        {props.isLoading
          ? <Preloader
              size={16}
              containerStyle={styles.preloader}
            />
          : <button
            {...mergeStyles(styles.selectedRegion, props.selectedRegionStyle)}
            type="button"
            onClick={this.handleRegionClick}
            data-mark={`${this.props.dataMark ? this.props.dataMark + '|' : ''}button`}
          >
              {props.displayName}
            </button>
        }
        <SelectRegionWithCities popupQueryParam={this.props.popupQueryParam} />
      </span>
    );
  }

  private handleRegionClick = () => this.props.openPopup(this.props.popupQueryParam);
}

export const Region = connect<{}, OpenPopupDispatchProp, RegionOwnProps>
(() => ({}), { openPopup })(Component);
