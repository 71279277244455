export interface ICookiesUtilsSettings {
  domain: string;
  path: string;
}

const defaultSettings = {
  path: '/',
  domain: '.cian.ru',
};

/**
 * Подключаем через connectContext(['cookiesUtils'])(Component)
 * см. offers_list
 * */
export class CookiesUtils {
  private cookieParams: string;
  private source: Map<string, string>;

  public constructor(settings: ICookiesUtilsSettings = defaultSettings, source: string = '') {
    this.cookieParams = this.serialize(this.decorateCookieParams(settings));
    this.source = new Map(this.parseCookieString(source));
  }

  public get = (key: string): string | undefined => {
    if (typeof document !== 'undefined') {
      const value = this.parseCookieString(document.cookie).find(pair => pair[0] === key);

      return value ? decodeURIComponent(value[1]) : undefined;
    } else {
      return this.source.get(key);
    }
  };

  public set = (key: string, value: string, maxAge?: number) => {
    if (typeof document !== 'undefined') {
      document.cookie = `${key}=${encodeURIComponent(value)};${this.cookieParams}${
        maxAge !== undefined ? `Max-Age=${maxAge}` : ''
      }`;
    } else {
      if (!!value && (!maxAge || maxAge >= 0)) {
        this.source.set(key, value);
      } else {
        this.source.delete(key);
      }
    }
  };

  public delete = (key: string) => {
    this.set(key, '', -1);
  };

  public toString() {
    if (typeof document !== 'undefined') {
      return document.cookie;
    } else {
      return Array.from(this.source, ([key, value]) => `${key}=${encodeURIComponent(value)}`).join('; ');
    }
  }

  private parseCookieString(cookie: string): [string, string][] {
    return cookie.split(/;\s+/).map<[string, string]>(pairString => {
      const pair = pairString.split('=');

      return [pair[0], decodeURIComponent(pair[1])];
    });
  }

  private decorateCookieParams(settings: ICookiesUtilsSettings) {
    const isLocalhost = typeof location !== 'undefined' && location.host.includes('localhost');
    const isTest = typeof location !== 'undefined' && location.host === '';
    const overrideSettings = isLocalhost || isTest ? { domain: '' } : {};

    return { ...settings, ...overrideSettings };
  }

  // tslint:disable-next-line: no-any
  private serialize(obj: any): string {
    return Object.keys(obj).reduce((acc, x) => {
      return `${acc}${x}=${obj[x]}; `;
    }, '');
  }
}
