import { ca } from '@cian/analytics';
import { IOfferAnalyticsData } from '../analytics';

export enum EHideOfferCategories {
  auth = 'Authorization_hideObjects',
  authShow = 'Auth_popup_hideObjects',
  hideObjects = 'HideObject',
  registration = 'Registration_hideObjects',
  restoreObject = 'RestoreObject',
  onboardingShow = 'HideObjectOnboarding',
}

export enum EHideOfferCaActions {
  hide = 'Hide',
  show = 'Show',
  success = 'success',
  clickIcon = 'Click_icon',
  restoreIcon = 'Restore_icon',
}

export enum EAuthType {
  vk = 'vk',
  fb = 'fb',
  ok = 'ok',
  email = 'email',
  google = 'google',
  mailRu = 'mailru',
}

export type TAuthLabel = EAuthType | '';

export function trackHideButtonClick(offerData: IOfferAnalyticsData, label?: string | null) {
  ca('event', {
    name: 'oldevent',
    category: EHideOfferCategories.hideObjects,
    action: EHideOfferCaActions.clickIcon,
    label,
    products: [offerData],
  });
}

export function trackSuccessHiding(offerData: IOfferAnalyticsData, label?: string | null) {
  ca('event', {
    name: 'oldevent',
    category: EHideOfferCategories.hideObjects,
    action: EHideOfferCaActions.hide,
    label,
    products: [offerData],
  });
}

export function trackSuccessRestoring(offerData: IOfferAnalyticsData, label?: string | null) {
  ca('event', {
    name: 'oldevent',
    category: EHideOfferCategories.restoreObject,
    action: EHideOfferCaActions.restoreIcon,
    label,
    products: [offerData],
  });
}

export function trackAuthPopupShow() {
  ca('event', {
    name: 'oldevent',
    category: EHideOfferCategories.authShow,
    action: EHideOfferCaActions.success,
  });
}

export function trackOnboardingShow() {
  ca('event', {
    name: 'oldevent',
    category: EHideOfferCategories.onboardingShow,
    action: EHideOfferCaActions.show,
  });
}

export function trackSuccessAuth(category: EHideOfferCategories, label: TAuthLabel) {
  ca('event', {
    name: 'oldevent',
    category,
    action: EHideOfferCaActions.success,
    label,
  });
}
