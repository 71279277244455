import { without } from 'lodash';
import * as React from 'react';

import { CheckboxList, ICheckboxListItem } from '../../../components/filters_checkbox_list/filters_ckeckbox_list';
import { DealType, flatObjectTypes, isSuburbanObjectType } from '../../../redux/filters/model';
import { FlatCondition } from '../../../redux/filters/model';
import { IFieldProps, IsVisibleCheck } from '../field';

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const isSuburban = isSuburbanObjectType(this.props.filter.selectedObjectTypes[0]);

    const conditions: ICheckboxListItem[] = [
      { id: FlatCondition.Fridge, label: 'Холодильник' },
      { id: FlatCondition.Washer, label: 'Стиральная машина' },
      { id: FlatCondition.KitchenFurniture, label: 'Кухонная мебель' },
      { id: FlatCondition.Internet, label: 'Интернет' },
      { id: FlatCondition.Tv, label: 'Телевизор' },
      { id: FlatCondition.Conditioner, label: 'Кондиционер' },
      { id: FlatCondition.DishWasher, label: 'Посудомоечная машина' },
      { id: FlatCondition.Phone, label: 'Телефон' },
      { id: FlatCondition.Bath, label: 'Ванна' },
      { id: FlatCondition.Shower, label: 'Душевая кабина' },

      isSuburban && { id: FlatCondition.Bathhouse, label: 'Баня' },
      isSuburban && { id: FlatCondition.Garage, label: 'Гараж' },
      isSuburban && { id: FlatCondition.Pool, label: 'Бассейн' },
    ].filter(Boolean) as ICheckboxListItem[];

    const selectedConditions = this.props.filter.flatConditions;

    return (
      <CheckboxList
        list={conditions}
        selected={selectedConditions}
        onItemChecked={(checked: boolean, id: number) => this.props.actions.toggleFlatCondition(id)}
        dataMark="FieldConditionsList"
      />
    );
  }
}

const allowedObjectTypes = [
  ...flatObjectTypes,
];

export const isVisible: IsVisibleCheck = filter =>
  filter.dealType === DealType.Rent
  && filter.selectedObjectTypes.length > 0
  && without(filter.selectedObjectTypes, ...allowedObjectTypes).length === 0;
