/* eslint-disable max-lines */

import { EOfficeType } from '@cian/api-models/common/json_query';
import { ILogger } from '@cian/logger';

import { QueryClient } from '@tanstack/react-query';
import { forIn, isEmpty, toPairs, values } from 'lodash';
import { browserHistory } from 'react-router';
import { push } from 'react-router-redux';
import { ActionType, createAction } from 'typesafe-actions';

import { IGetSearchUrl, ISettingsResponseData, MobileWebsiteApi } from '../../api/api';
import { IJsonQuery, IJsonQueryTerm } from '../../api/models/json_query';
import { TOption, options } from '../../components/sorting';
import {
  trackGeoSuggestAutoOfferChange,
  trackGeoSuggestNotFound,
} from '../../filters/common/geo_search/suggest/trackings';
import { extractSpecialPromo, getOptionalRegionInfo, isPromoSpecial } from '../../filters/field';
import {
  IFiltersVisibilityParams,
  TCoworkingOfferType,
  isSuburbanObjectGroup,
  suburbanObjectType,
} from '../../filters/fields/field';
import { queryStringParams } from '../../map/components/InfrastructureDropdown';
import { pipe } from '../../utils/functional';
import { isServer } from '../../utils/isomorphic';
import { appendQueryParam, getFullUrl, getQueryString, getSubdomain, getUrlForRequest } from '../../utils/location';
import { Regions, getLastCommonRegion, patchGeoTag, tagsCanBeUnitedByCompositeRegion } from '../../utils/regions';
import { routeFor } from '../../utils/routeFor';
import { filterSortOptions } from '../../utils/sorting';
import { toPromise } from '../../utils/streams';
import { isTimeoutError, timeoutPromise } from '../../utils/timeout_promise';
import { showAutoOfferChangeHint } from '../autoOfferChangeHint/actions';
import { IUserRegion } from '../common/model';
import { changeFiltersPageNextLoading, changeFiltersPagePendingState } from '../filters_page/actions';
import { removeInfrastructureFromMapObjects, toggleInfrastructure } from '../map_page/actions';
import { ReduxState, TTypedThunkAction } from '../model';
import { getDefaultCityOrRegion, updateRegionMetaForFilter } from '../regions/actions';

import { boxTypeDict } from './dictionaries';
import { filterToJsonQuery } from './filter_to_json_query';
import { getNextJsonQuery } from './getNextJsonQuery';
import { jsonQueryToFilter } from './json_query_to_filter';
import {
  BathroomPlacement,
  BathroomType,
  BuildingClassType,
  ContractType,
  Currency,
  DealType,
  EElectronicTradingType,
  EJsonQueryPriceType,
  ESuburbanOfferFilter,
  EnterType,
  FilterState,
  FiltersSection,
  FlatCondition,
  ICoworkingAccess,
  IGeoTag,
  IMapInfrastructure,
  IRegionsSettings,
  MovementMethod,
  RangeType,
  RentTime,
  RoomType,
  SaleTypeFlat,
  SteadCondition,
  SteadStatus,
  TObjectType,
  TPriceType,
  defaultRegionsSettings,
} from './model';
import { TQueryStringActions, setQueryString } from './queryStringActions';

export const setBusinessAppointments = createAction('SetBusinessAppointments')<Array<number>>();

export type TFiltersActions =
  | { type: 'AddGeoTag'; payload: { geoTag: IGeoTag } }
  | { type: 'AdvancedFilters'; payload: { showAdvancedFilters: boolean } }
  | { type: 'AdvancedFiltersInPopup'; payload: { showAdvancedFiltersInPopup?: boolean } }
  | { type: 'ClearFilter'; payload: { defaultRegion: number } }
  | { type: 'DeleteError' }
  | { type: 'DeleteGeoTag'; payload: { id?: number; kind?: string } }
  | { type: 'DeleteRailwayTags' }
  | { type: 'GetInitialFiltersPending' }
  | { type: 'SetShouldSkipInitialLoading'; state: boolean }
  | {
      type: 'GetInitialFiltersSuccess';
      payload: {
        filter: FilterState;
        section: FiltersSection;
        lastUrl: string;
      };
    }
  | { type: 'RejectOfferCount' }
  | { type: 'RequestOfferCount' }
  | {
      type: 'ResetFilter';
      payload: {
        region: number;
        section: FiltersSection;
        lastUrl: string;
      };
    }
  | { type: 'SetAgentNoFee'; payload: { agentNoFee: boolean } }
  | { type: 'SetFilterLastUrl'; payload: { lastUrl: string } }
  | { type: 'SetBathroomPlacement'; payload: { bathroomPlacement: BathroomPlacement } }
  | { type: 'SetBathroomsCount'; payload: { bathroomsCount: number } }
  | { type: 'SetBathroomType'; payload: { bathroomType: BathroomType } }
  | { type: 'SetRoomType'; payload: { roomType: RoomType } }
  | { type: 'SetCurrency'; payload: { currency: Currency } }
  | { type: 'SetDealType'; payload: { dealType: DealType } }
  | { type: 'SetDemolishedInMoscowProgramm'; payload: { demolishedInMoscowProgramm: boolean | undefined } }
  | { type: 'SetHasVideo'; payload: { hasVideo: boolean } }
  | { type: 'SetError'; payload: { message: string; onRefresh?(): void } }
  | { type: 'SetFromOwner'; payload: { isByHomeOwner: boolean } }
  | { type: 'SetGarageSubtype'; payload: { selectedGarageSubtypes: string[] } }
  | { type: 'SetGarageType'; payload: { id: number; checked: boolean } }
  | { type: 'SetGeoTagsLoading'; payload: { isGeoTagsLoading: boolean } }
  | { type: 'SetHighways'; payload: { highways: number[] } }
  | { type: 'SetHouseMaterials'; payload: { houseMaterials: string[] } }
  | { type: 'setHouseMaxYear'; payload: { houseMaxYear: number } }
  | { type: 'setHouseMinYear'; payload: { houseMinYear: number } }
  | { type: 'SetInfrastructure'; payload: { infrastructureType: string | number } }
  | { type: 'SetInitialInfrastructure'; payload: { infrastructure: IMapInfrastructure } }
  | { type: 'SetLiftsCount'; payload: { liftsCount: number } }
  | { type: 'SetNoDeposit'; payload: { withDeposit?: boolean } }
  | { type: 'SetOfferCount'; payload: { offerCount: number } }
  | { type: 'SetRange'; payload: { valueType: RangeType; value: number; field: string } }
  | { type: 'SetPublicationPeriod'; payload: { publicationPeriod?: number } }
  | { type: 'SetRegion'; payload: ISetRegionPayload }
  | { type: 'SetRegionsSettings'; payload: { regionSettings: IRegionsSettings } }
  | { type: 'SetRentTime'; payload: { rentTime: RentTime } }
  | { type: 'SetRooms'; payload: { roomType: string; checked: boolean } }
  | { type: 'SetSaleTypeFlat'; payload: { saleTypeFlat: SaleTypeFlat } }
  | { type: 'SetSaleTypeFlat'; payload: SaleTypeFlat }
  | { type: 'SetSaleTypesNewbuilding'; payload: { saleType: string; checked: boolean } }
  | { type: 'SetSelectedHouseTypes'; payload: { selectedHouseTypes: string[] } }
  | { type: 'SetSelectedObjectTypes'; payload: { selectedObjectTypes: TObjectType[] } }
  | { type: 'SetSelectedCoworkingOfferTypes'; payload: { selectedCoworkingOfferType: TCoworkingOfferType } }
  | { type: 'ClearCoworkingWorkplaceFilter' }
  | { type: 'SetCoworkingWorkplaceFilter' }
  | { type: 'SetCoworkingAmenity'; payload: { amenityIndexList: string[] } }
  | { type: 'SetCoworkingAccess'; payload: { coworkingAccess: ICoworkingAccess } }
  | { type: 'SetSelectedRoomsCount'; payload: { roomsCount: number[] } }
  | { type: 'SetSelectedRentRoomsCount'; payload: { rentRoomsCount?: number } }
  | { type: 'SetSeller'; payload: { selectedSellers: string[] } }
  | { type: 'SetSuburbanOfferFilter'; payload: { suburbanOfferFilter: number } }
  | { type: 'SetSteadStatus'; payload?: SteadStatus[] }
  | { type: 'SetSubwayDistanceMovement'; payload?: MovementMethod }
  | { type: 'SetSubwayMaxDistance'; payload?: number }
  | { type: 'SetWithBalcony'; payload: { withBalcony: boolean } }
  | { type: 'SetWithDiscount'; payload: { withDiscount: boolean } }
  | { type: 'SetWithFurniture'; payload: { withFurniture?: boolean } }
  | { type: 'SetWithLoggia'; payload: { withLoggia: boolean } }
  | { type: 'SetWithMortgage'; payload: { withMortgage: boolean } }
  | { type: 'SetWithLayout'; payload: { withLayout: boolean } }
  | { type: 'SetWithNewobject'; payload: { withNewobject: boolean } }
  | { type: 'SetWithPhoto'; payload: { withPhoto: boolean } }
  | { type: 'SetWithPik'; payload: { withPik: boolean } }
  | { type: 'SetWithCustom'; payload: { withCustom: true; builderId: number } }
  | { type: 'SetWithCustom'; payload: { withCustom: false; builderId: undefined } }
  | { type: 'SetWithServiceLift'; payload: { withServiceLift: boolean } }
  | { type: 'SetYear'; payload: { selectedYears: string[] } }
  | { type: 'ToggleBoxType'; payload?: boxTypeDict }
  | { type: 'ToggleSaleTypeFlat'; payload?: SaleTypeFlat }
  | { type: 'ToggleBuildingClass'; payload?: BuildingClassType }
  | { type: 'ToggleFlatCondition'; payload?: FlatCondition }
  | { type: 'ToggleSteadCondition'; payload?: SteadCondition }
  | { type: 'ToggleContractType'; payload?: ContractType }
  | { type: 'SetDecoration'; payload: { decoration: boolean } }
  | { type: 'SetOnlyFlat'; payload: { onlyFlat: boolean | undefined } }
  | { type: 'SetApartment'; payload: { apartment: boolean | undefined } }
  | { type: 'SetWindowsType'; payload: { windowsType: number | undefined } }
  | { type: 'SetCeilingHeight'; payload: { ceilingHeight: number } }
  | { type: 'SetLastFloor'; payload: { notLastFloor?: boolean } }
  | { type: 'SetFirstFloor'; payload: { firstFloor?: boolean } }
  | { type: 'SetBasementFloor'; payload: { basementFloor: boolean } }
  | { type: 'SetSemibasementFloor'; payload: { semibasementFloor: boolean } }
  | { type: 'SetAccessSystem'; payload: { accessSystem?: boolean } }
  | { type: 'SetEnterTypes'; payload: { enterTypes: EnterType[] } }
  | { type: 'SetResaleOnly'; payload: { resaleOnly: boolean } }
  | { type: 'SetHouseType'; payload: IHouseTypeFilter }
  | { type: 'GetAdvancedFiltersCount'; payload: { jsonQuery: IJsonQuery } }
  | { type: 'SetGeoTags'; payload: { geoTags: IGeoTag[] } }
  | { type: 'CloseAdvancedSearch' }
  | { type: 'SetPriceType'; payload: { priceType: TPriceType } }
  | { type: 'SetJsonQueryPriceType'; payload: EJsonQueryPriceType | undefined }
  | { type: 'SetRepresentative'; payload: { fromRepresentative: boolean } }
  | { type: 'SetUserInput'; payload: { userInput: string } }
  | { type: 'SetFromDomrf'; payload: { isFromDomrf: boolean } }
  | { type: 'SetFromCommercialOwner'; payload: { isByCommercialOwner: boolean } }
  | { type: 'SetNewobjectCompilation'; payload: { compilationType: string; checked: boolean } }
  | { type: 'SetIsUpcomingSale'; payload: boolean }
  | { type: 'SetElectronicTrading'; payload: { electronicTrading: EElectronicTradingType | undefined } }
  | ActionType<typeof setBusinessAppointments>;

export interface ISetRegionPayload {
  region: number;
  resetTags?: boolean;
}

export interface IHouseTypeFilter {
  resaleOnly: boolean;
  withNewobject: boolean;
}

export function getExpandAdvancedQueryParam(isAdvanced: boolean): '' | 'advanced=1' {
  return isAdvanced ? 'advanced=1' : '';
}

export function isStatusOk(res: { result: { status?: string } }): boolean {
  return Boolean(res.result.status && res.result.status.toLowerCase() === 'ok');
}

export function getAdvancedFiltersPageInitials(
  queryString: string,
  regionsPromise: Promise<void>,
  section: FiltersSection,
  subdomain: string,
): TTypedThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    const {
      filtersPage: { skipNextLoading },
    } = getState();

    if (!skipNextLoading) {
      dispatch(changeFiltersPagePendingState(true));
      await dispatch(getInitialFilters(queryString, regionsPromise, section, subdomain));
      dispatch(changeFiltersPagePendingState(false));
    }

    if (skipNextLoading) {
      dispatch(changeFiltersPageNextLoading(false));
    } else if (isServer) {
      dispatch(changeFiltersPageNextLoading(true));
    }
  };
}

export function getInitialFilters(
  queryString: string,
  regionsPromise: Promise<void>,
  section: FiltersSection,
  subdomain: string,
  initialOnServer?: boolean,
): TTypedThunkAction<Promise<void>> {
  if (queryString.startsWith('/') || queryString.startsWith('?')) {
    throw new Error('getInitialFilters: you should pass query string not url');
  }

  return (dispatch, getState, { api, logger }) => {
    dispatch<TFiltersActions>({ type: 'GetInitialFiltersPending' });

    const {
      routing: {
        locationBeforeTransitions: { pathname, search, query },
      },
      specialPromo: spState,
      specialPromosInfo: spInfoState,
      filter: filterState,
      regions,
    } = getState();
    const spRegion = regions.regionMeta.location;
    const specialPromo = spState || extractSpecialPromo(spInfoState, filterState.region, !!spState, spRegion);

    const lastUrl = pathname + search;

    const emptyQuery = queryString === '' || queryString === getExpandAdvancedQueryParam(true);

    const isAdvanced = query['advanced'] != null;

    if (emptyQuery) {
      return regionsPromise
        .then(() => {
          const defaultRegion = getDefaultCityOrRegion(getState());

          dispatch<TFiltersActions>({
            type: 'ResetFilter',
            payload: {
              region: defaultRegion,
              section,
              lastUrl,
            },
          });
          dispatch<TFiltersActions>(setAdvancedFilters(isAdvanced));

          return dispatch(updateRegionMetaForFilter(defaultRegion, initialOnServer));
        })
        .catch(error => {
          logger.error(error);
          dispatch<TFiltersActions>({
            type: 'SetError',
            payload: {
              message: 'Не удалось проинициализировать фильтры',
            },
          });
        });
    }

    dispatch<TFiltersActions>(setAdvancedFilters(isAdvanced));

    const jsonQueryP = toPromise(api.getJsonQuery(queryString));
    const geoP = toPromise(api.getGeoTagsByQueryString(queryString, subdomain, logger));

    return Promise.all([jsonQueryP, geoP, regionsPromise])
      .then(([jsonQueryRes, geoRes]) => {
        if (isStatusOk(jsonQueryRes) && isStatusOk(geoRes)) {
          const {
            data: { jsonQuery },
          } = jsonQueryRes.result;
          const { data: geo } = geoRes.result;
          const defaultRegion = getDefaultCityOrRegion(getState());

          const filter = jsonQueryToFilter(jsonQuery, geo, defaultRegion, specialPromo);

          dispatch<TFiltersActions>({
            type: 'GetInitialFiltersSuccess',
            payload: { filter, section, lastUrl },
          });

          return dispatch(updateRegionMetaForFilter(filter.region, initialOnServer));
        }
        throw new Error(`Wrong response: ${status}`);
      })
      .catch(err => {
        if (logger) {
          if (isTimeoutError(err)) {
            logger.error(err, {
              message: 'getInitialFilters timeout',
              domain: 'src/mobile_website/redux/filters/actions.ts',
            });
          } else {
            logger.error(err, {
              message: 'getInitialFilters error',
              domain: 'src/mobile_website/redux/filters/actions.ts',
            });
          }
        }

        dispatch<TFiltersActions>({
          type: 'SetError',
          payload: {
            message: 'Не удалось проинициализировать фильтры',
          },
        });
      });
  };
}

export function getInitialFiltersOnServer(
  queryString: string,
  subdomain: string,
  regionsPromise?: Promise<void>,
): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState) => {
    const {
      filter: { section = FiltersSection.Index },
    } = getState();
    if (isServer) {
      return dispatch(getInitialFilters(queryString, regionsPromise || Promise.resolve(), section, subdomain, true));
    }

    return Promise.resolve();
  };
}

export function setPriceType(priceType: TPriceType): TFiltersActions {
  return {
    type: 'SetPriceType',
    payload: { priceType },
  };
}

export function setJsonQueryPriceType(payload: EJsonQueryPriceType | undefined): TFiltersActions {
  return {
    type: 'SetJsonQueryPriceType',
    payload,
  };
}

export function setDealType(dealType: DealType): TFiltersActions {
  return {
    type: 'SetDealType',
    payload: { dealType },
  };
}

export function setRange(valueType: RangeType, value: number, field: string): TFiltersActions {
  return {
    type: 'SetRange',
    payload: { valueType, value, field },
  };
}

export function setPublicationPeriod(publicationPeriod?: number): TFiltersActions {
  return {
    type: 'SetPublicationPeriod',
    payload: { publicationPeriod },
  };
}

export function setRegion(payload: ISetRegionPayload): TFiltersActions {
  return {
    type: 'SetRegion',
    payload,
  };
}

export function setRentTime(rentTime: string): TFiltersActions {
  return {
    type: 'SetRentTime',
    payload: { rentTime: rentTime as RentTime },
  };
}

export function setWithPhoto(withPhoto: boolean): TFiltersActions {
  return {
    type: 'SetWithPhoto',
    payload: { withPhoto },
  };
}

export function setAdvancedFilters(showAdvancedFilters: boolean): TFiltersActions {
  return {
    type: 'AdvancedFilters',
    payload: { showAdvancedFilters },
  };
}

export function setRooms(roomType: string, checked: boolean): TFiltersActions {
  return {
    type: 'SetRooms',
    payload: { roomType, checked },
  };
}

export function setSaleTypeFlat(saleTypeFlat: SaleTypeFlat): TFiltersActions {
  return {
    type: 'SetSaleTypeFlat',
    payload: saleTypeFlat,
  };
}

export function setSaleTypesNewbuilding(saleType: string, checked: boolean): TFiltersActions {
  return {
    type: 'SetSaleTypesNewbuilding',
    payload: { saleType, checked },
  };
}

export function setWithNewobject(withNewobject: boolean): TFiltersActions {
  return {
    type: 'SetWithNewobject',
    payload: { withNewobject },
  };
}

export const addGeoTagPure = (geoTag: IGeoTag): TTypedThunkAction => {
  return (dispatch, getState) => {
    dispatch({
      type: 'AddGeoTag',
      payload: { geoTag },
    });

    const { filter } = getState();

    if (geoTag.kind === 'Underground' && !filter.subwayDistance.maxDistance) {
      dispatch(setSubwayMaxDistance(45));
    }
  };
};

export function addGeoTag(tagToAdd: IGeoTag): TTypedThunkAction<Promise<void | {}>> {
  return (dispatch, getState) => {
    const { filter: state } = getState();
    const previousRegion = state.region;

    const isTagExists = state.geoTags.some(t => t.name === tagToAdd.name && t.id === tagToAdd.id);
    if (isTagExists) {
      return Promise.resolve();
    }
    const isVillageTag = tagToAdd.kind.toLocaleLowerCase() === 'village';

    if (
      state.selectedObjectTypes &&
      !isSuburbanObjectGroup(state) && // незагородный тип объявок
      isVillageTag && // добавлен тег с kind: "village"
      tagToAdd.id
    ) {
      dispatch(setSelectedObjectTypes(values(suburbanObjectType)));
      dispatch(showAutoOfferChangeHint(true));
      trackGeoSuggestAutoOfferChange(tagToAdd.id, state.selectedObjectTypes);
    }

    const finish = (region?: number): Promise<void> =>
      new Promise<void>(resolve => {
        if (region) {
          dispatch(setRegion({ region }));
          dispatch(getRegionSettings(getSubdomain(location.href), region));
        }
        dispatch(addGeoTagPure(tagToAdd));

        resolve();
      });

    const allTags = [tagToAdd, ...state.geoTags];

    let tagRegion: number | undefined;

    /** Сначала проверяем, нельзя ли объеденить все геотеги составными регионами */
    if (tagsCanBeUnitedByCompositeRegion(allTags, [Regions.Moscow, Regions.MoscowRegion])) {
      return finish(Regions.MoscowAndRegion);
    } else if (tagsCanBeUnitedByCompositeRegion(allTags, [Regions.SaintPetersburg, Regions.LeningradRegion])) {
      return finish(Regions.SaintPetersburgAndRegion);
    } else {
      /** Добавляем в иерархию городов области. */
      const patchedTagsDetails = allTags.map(patchGeoTag).map(t => t.details || []);
      tagRegion = getLastCommonRegion(patchedTagsDetails);
    }

    if (!tagRegion) {
      const fallBackRegion = tagToAdd.regionId ? tagToAdd.regionId : state.region;

      return finish(fallBackRegion);
    }

    /** Если у пользователя выбран регион мск+мо (спб+ло), а новый геотег относится к мск или мо (спб или ло),
     * то изменять регион не нужно. */
    const shouldSavePreviousRegion =
      (state.region === Regions.MoscowAndRegion &&
        (tagToAdd.regionId === Regions.Moscow || tagToAdd.regionId === Regions.MoscowRegion)) ||
      (state.region === Regions.SaintPetersburgAndRegion &&
        (tagToAdd.regionId === Regions.SaintPetersburg || tagToAdd.regionId === Regions.LeningradRegion));

    /** При смене региона нужно удалить все теги, которые не относятся к новому региону */
    if (tagRegion && tagRegion !== previousRegion) {
      state.geoTags.forEach(tag => {
        const tagRegionIDs: number[] = tag.details ? tag.details.map(detail => detail.id) : [];

        if (!tagRegionIDs.includes(tagRegion as number)) {
          dispatch(
            deleteGeoTag({
              id: tag.id,
              kind: tag.kind,
            }),
          );
        }
      });
    }

    /** Если добавляемый тег - это и есть регион, то он не должен добавляться как тег */
    const shouldAddTag = tagToAdd.id !== tagRegion || shouldSavePreviousRegion;

    if (!shouldSavePreviousRegion) {
      dispatch(setRegion({ region: tagRegion }));
      dispatch(getRegionSettings(getSubdomain(location.href), tagRegion));
      dispatch(
        updateNewobjectCheckboxesByPromoState({
          id: tagToAdd.id,
          parentId: tagToAdd.regionId,
        }),
      );
    }

    if (shouldAddTag) {
      dispatch(addGeoTagPure(tagToAdd));
    }

    return Promise.resolve();
  };
}

export function deteleRailwayTags(): TFiltersActions {
  return {
    type: 'DeleteRailwayTags',
  };
}

export function deleteGeoTag(payload: { id?: number; kind?: string; name?: string }): TFiltersActions {
  return {
    type: 'DeleteGeoTag',
    payload,
  };
}

export function setGeoTags(geoTags: IGeoTag[]): TFiltersActions {
  return {
    type: 'SetGeoTags',
    payload: { geoTags },
  };
}

export function setGeoTagsLoading(state: boolean): TFiltersActions {
  return {
    type: 'SetGeoTagsLoading',
    payload: { isGeoTagsLoading: state },
  };
}

export function setCurrency(currency: Currency): TFiltersActions {
  return {
    type: 'SetCurrency',
    payload: { currency },
  };
}

export function setResaleOnly(resaleOnly: boolean): TFiltersActions {
  return {
    type: 'SetResaleOnly',
    payload: { resaleOnly },
  };
}

export function SetHouseType(payload: IHouseTypeFilter): TFiltersActions {
  return {
    type: 'SetHouseType',
    payload,
  };
}

export interface IUrlData {
  queryString: string;
  seoPath?: string;
  subdomain: string;
}

export function getRelativeUrl(data: IUrlData, route: string): string {
  const hasSort = data.queryString.includes('sort=');

  if (data.seoPath && route !== routeFor.MAP && route !== routeFor.ADVANCED_SEARCH && !hasSort) {
    return data.seoPath;
  }

  return `${route}?${data.queryString}`;
}

export function getFilterUrl(path: string, queryString: string, expandAdvancedParam: string): string {
  const query = appendQueryParam(queryString, expandAdvancedParam);
  const queryWithoutPath = query.split('?')[1] || query;

  return `${path}?${queryWithoutPath}`;
}

export function getUrlByJsonQuery(jsonQuery: IJsonQuery, api: MobileWebsiteApi): Promise<IGetSearchUrl> {
  return toPromise(api.getSearchUrl(jsonQuery)).then(res => {
    const { data, status } = res.result;
    if (status && status.toLowerCase() === 'ok') {
      return data;
    }
    throw new Error(`Wrong response: ${status}`);
  });
}

export function getSearchQueryString(
  state: ReduxState,
  preferQueryStringFromLocation: boolean,
  api: MobileWebsiteApi,
  isNewbuildingsMapMode: boolean,
  isCustomSpecialPromo?: boolean,
  sort?: IJsonQueryTerm<string>,
): Promise<IUrlData> {
  const { routing, queryClient } = state;
  const { search } = routing.locationBeforeTransitions;

  const currentQueryString = getQueryString(search);
  const currentSubdomain = getSubdomain(location.href);

  if (preferQueryStringFromLocation && currentQueryString) {
    return Promise.resolve({
      queryString: currentQueryString,
      subdomain: currentSubdomain,
    });
  }

  const jsonQuery = getNextJsonQuery(
    state,
    preferQueryStringFromLocation,
    isNewbuildingsMapMode,
    !!isCustomSpecialPromo,
    queryClient,
  );

  const jsonQuerySort: IJsonQuery = jsonQueryWithSort(state, jsonQuery, sort) || jsonQuery;
  const nextJsonQuery: IJsonQuery = getReplaceCoworkingOfficeType(jsonQuerySort);

  return getUrlByJsonQuery(nextJsonQuery, api).then(searchMetaToUriData);
}

function jsonQueryWithSort(
  { regions, routing: { routeName } }: ReduxState,
  jsonQuery: IJsonQuery,
  sort?: IJsonQueryTerm<string>,
): IJsonQuery {
  const newFiltersValues = pipe(filterSortOptions(jsonQuery, regions), mapOptionsToValues)(options);

  return sort && routeName === 'advancedSearch' && newFiltersValues.includes(sort.value)
    ? Object.assign({}, jsonQuery, { sort })
    : jsonQuery;
}

function getReplaceCoworkingOfficeType(jsonQuery: IJsonQuery): IJsonQuery {
  if (
    jsonQuery &&
    jsonQuery.office_type &&
    jsonQuery.office_type.value.length === 1 &&
    jsonQuery.office_type.value[0] === EOfficeType.Coworking &&
    jsonQuery.coworking_offer_type &&
    jsonQuery.coworking_offer_type.value.length > 0
  ) {
    return {
      ...jsonQuery,
      office_type: {
        ...jsonQuery.office_type,
        value: [EOfficeType.Office],
      },
    };
  }

  return jsonQuery;
}

export function mapOptionsToValues(opts: TOption[]): string[] {
  return opts.map(option => option.value);
}

function searchMetaToUriData(data: IGetSearchUrl): IUrlData {
  return {
    queryString: data.querystring,
    seoPath: data.seoPath,
    subdomain: data.subdomain,
  };
}

export interface IRouteParams {
  openNewWindow?: boolean;
  shouldReload?: boolean;
  isNewbuildingsMapMode?: boolean;
}

export function updateNewobjectCheckboxesByPromoState(region: Partial<IUserRegion>): TTypedThunkAction<void> {
  return (dispatch, getState) => {
    if (region.id === undefined) {
      return;
    }

    const { filter, specialPromosInfo: spInfos } = getState();
    const specialPromosState = {
      withPik: filter.withPik,
      withCustom: filter.withCustom,
    };
    const regionToCheckWith = region.parentId || region.id;

    const spInfo = spInfos.specialPromosInfo.find(item => item.region === regionToCheckWith);
    const { isRegionInMO } = getOptionalRegionInfo(spInfo);

    const isPik =
      specialPromosState.withPik &&
      ([Regions.Moscow, Regions.MoscowAndRegion, Regions.MoscowRegion].includes(regionToCheckWith) || isRegionInMO);

    const spBuilderId = spInfo ? spInfo.builderId : undefined;
    const isCustomSp =
      spInfo &&
      specialPromosState &&
      specialPromosState.withCustom &&
      spBuilderId !== undefined &&
      spBuilderId === filter.builderId;

    dispatch(setWithPik(!!isPik));
    dispatch(setWithCustom(!!isCustomSp, isCustomSp ? spBuilderId : undefined));
  };
}

export function goToRouteWithSearchQuery(
  route: string,
  routeParams: IRouteParams = {},
): TTypedThunkAction<Promise<void>> {
  const isAdvancedSearchRoute = route === routeFor.ADVANCED_SEARCH;
  const { openNewWindow } = routeParams;

  return (dispatch, getState, { api, logger }) => {
    const state = getState();
    const { filter, routing, popups, mapPage, specialPromo, searchList, queryClient } = state;
    const { pathname: path } = routing.locationBeforeTransitions;
    const isNewbuildingsMapMode = mapPage.newbuildingsPinsEnabled || routeParams.isNewbuildingsMapMode || false;
    const sort = searchList.initialized ? searchList.jsonQuery.sort : undefined;

    const expandAdvancedParam =
      !isAdvancedSearchRoute && filter.showAdvancedFilters
        ? getExpandAdvancedQueryParam(filter.showAdvancedFilters)
        : '';

    if (metaRequestIsInvalid(filter)) {
      return Promise.resolve();
    }

    const preferQueryStringFromLocation = isAdvancedSearchRoute || routing.routeName === 'listing';

    const getSearchQueryStringFunc: () => Promise<void> = () => {
      const isCustomSpecialPromo = isPromoSpecial(specialPromo);

      return (
        getSearchQueryString as (
          state: ReduxState,
          preferQueryStringFromLocation: boolean,
          api: MobileWebsiteApi,
          isNewbuildingsMapMode: boolean,
          isCustomSpecialPromo?: boolean,
          sort?: IJsonQueryTerm<string>,
        ) => Promise<IUrlData>
      )(state, preferQueryStringFromLocation, api, isNewbuildingsMapMode, isCustomSpecialPromo, sort)
        .then((data: IUrlData) => {
          let url = getRelativeUrl(data, route);
          if (!isAdvancedSearchRoute && routing.routeName !== 'map' && !route.includes('map')) {
            const newUrl = getFilterUrl(path, data.queryString, expandAdvancedParam);
            history.replaceState(history.state, '', newUrl);
            dispatch<TFiltersActions>({ type: 'SetFilterLastUrl', payload: { lastUrl: newUrl } });
          }

          const { nextMobileSearchAvailableTypes } = state;
          const nextOfferType = getNextJsonQuery(
            state,
            preferQueryStringFromLocation,
            isNewbuildingsMapMode,
            isCustomSpecialPromo,
            queryClient,
          )._type;
          const isNextMobileSearchAvailable = nextMobileSearchAvailableTypes.split(',').includes(nextOfferType);

          const oldSubdomain = getSubdomain(location.href);
          const shouldReload =
            oldSubdomain !== data.subdomain || routeParams.shouldReload || isNextMobileSearchAvailable;

          // если фильтруем по застройщику(спецпроект), показываем выдачу для спецпроекта
          if (filter.withCustom && filter.builderId !== undefined) {
            const urlHasLocation = url.includes('location%5B0%5D');

            if (!url.includes('&is_special_promo=1')) {
              url += '&is_special_promo=1';
            }

            if (!urlHasLocation) {
              url += `&location%5B0%5D=${filter.region}`; // иначе после рефреша не определится регион
            }

            if (route === routeFor.MAP && specialPromo && specialPromo.mapSettingsJk) {
              const { lat, lng, zoom } = specialPromo.mapSettingsJk;

              url += `&zoom=${zoom}`;
              url += `&center=${lat},${lng}`;

              url = url.replace(/&?location%5B(0|1)%5D=\d+/g, '');
            }
          }

          if (filter.withPik && !url.includes('&is_pik_promo=1')) {
            url += '&is_pik_promo=1';
          }

          if (openNewWindow) {
            window.open(getFullUrl(data.subdomain, url), '_blank');
          } else if (shouldReload) {
            window.location.href = getFullUrl(data.subdomain, url);
          } else {
            if (popups.activePopup !== 'none') {
              url += `&${popups.activePopup}=1`;
            }

            browserHistory.push(url);
          }
        })
        .catch(err => {
          const error = 'Не удалось загрузить данные';
          const onRefresh = goToRouteWithSearchQuery(route).bind(null, dispatch, getState, { api });
          dispatch<TFiltersActions>({ type: 'SetError', payload: { message: error, onRefresh } });
          logger.error(err);
        });
    };

    return getSearchQueryStringFunc();
  };
}

export function setAdvancedFilterInPopup(showAdvancedFiltersInPopup?: boolean): TFiltersActions {
  return {
    type: 'AdvancedFiltersInPopup',
    payload: { showAdvancedFiltersInPopup },
  };
}

export function goToList(openNewWindow?: boolean, isNewbuildingsMapMode?: boolean): TTypedThunkAction<Promise<void>> {
  return goToRouteWithSearchQuery(routeFor.OFFERS_LIST, { openNewWindow, isNewbuildingsMapMode });
}

export function goToAdvancedSearch(): TTypedThunkAction<Promise<void>> {
  return goToRouteWithSearchQuery(routeFor.ADVANCED_SEARCH);
}

export function goToMap(isNewbuildingsMapMode?: boolean): TTypedThunkAction<Promise<void>> {
  return goToRouteWithSearchQuery(routeFor.MAP, { isNewbuildingsMapMode, shouldReload: true });
}

export function searchInMap(isNewbuildingsMapMode?: boolean): TTypedThunkAction<Promise<void>> {
  return async dispatch => {
    dispatch(setAdvancedFilters(false));
    await dispatch(goToMap(isNewbuildingsMapMode));
  };
}

export function closeAdvancedSearch(): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    dispatch(setAdvancedFilterInPopup(undefined));
    const {
      routing,
      filter: { section },
      common,
    } = getState();
    const { search } = routing.locationBeforeTransitions;
    const queryString = search.replace('?', '');
    const jsonQueryP = toPromise(api.getJsonQuery(queryString));
    const subdomain = getSubdomain(location.href);

    return jsonQueryP.then(res => {
      const jsonQuery = res.result.data.jsonQuery;

      return getUrlByJsonQuery(jsonQuery, api).then(data => {
        const url = getRelativeUrl(searchMetaToUriData(data), routeFor.OFFERS_LIST);

        if (url === getUrlForRequest(common.lastUrl)) {
          browserHistory.goBack();
        } else {
          browserHistory.push(url);
          dispatch(getInitialFilters(queryString, Promise.resolve(), section || FiltersSection.Index, subdomain));
        }
      });
    });
  };
}

export function setYear(selectedYears: string[]): TFiltersActions {
  return {
    type: 'SetYear',
    payload: { selectedYears },
  };
}

export function setGarageType(id: number, checked: boolean): TFiltersActions {
  return {
    type: 'SetGarageType',
    payload: { id, checked },
  };
}

export function setGarageSubtype(selectedGarageSubtypes: string[]): TFiltersActions {
  return {
    type: 'SetGarageSubtype',
    payload: { selectedGarageSubtypes },
  };
}

export function setLiftsCount(liftsCount: number): TFiltersActions {
  return {
    type: 'SetLiftsCount',
    payload: { liftsCount },
  };
}

export function setDemolishedInMoscowProgramm(demolishedInMoscowProgramm: boolean | undefined): TFiltersActions {
  return {
    type: 'SetDemolishedInMoscowProgramm',
    payload: { demolishedInMoscowProgramm },
  };
}

export function setSeller(selectedSellers: string | string[]): TFiltersActions {
  return {
    type: 'SetSeller',
    payload: {
      selectedSellers: Array.isArray(selectedSellers) ? selectedSellers : [selectedSellers],
    },
  };
}

export function setSuburbanOfferFilter(suburbanOfferFilter: string[]): TFiltersActions {
  return {
    type: 'SetSuburbanOfferFilter',
    payload: {
      suburbanOfferFilter: Number(suburbanOfferFilter[0]) as ESuburbanOfferFilter,
    },
  };
}

export function setWithBalcony(withBalcony: boolean): TFiltersActions {
  return {
    type: 'SetWithBalcony',
    payload: { withBalcony },
  };
}

export function setWithDiscount(withDiscount: boolean): TFiltersActions {
  return {
    type: 'SetWithDiscount',
    payload: { withDiscount },
  };
}

export function setWithLoggia(withLoggia: boolean): TFiltersActions {
  return {
    type: 'SetWithLoggia',
    payload: { withLoggia },
  };
}

export function setWithMortgage(withMortgage: boolean): TFiltersActions {
  return {
    type: 'SetWithMortgage',
    payload: { withMortgage },
  };
}

export function setWithLayout(withLayout: boolean): TFiltersActions {
  return {
    type: 'SetWithLayout',
    payload: { withLayout },
  };
}

export function setWithServiceLift(withServiceLift: boolean): TFiltersActions {
  return {
    type: 'SetWithServiceLift',
    payload: { withServiceLift },
  };
}

// todo вынести в common, используется не только в фильтрах
export function setError(message: string, onRefresh?: () => void): TFiltersActions {
  return {
    type: 'SetError',
    payload: { message, onRefresh },
  };
}

export function deleteError(): TFiltersActions {
  return {
    type: 'DeleteError',
  };
}

export function setSelectedObjectTypes(selectedObjectTypes: TObjectType[]): TFiltersActions {
  return {
    type: 'SetSelectedObjectTypes',
    payload: { selectedObjectTypes },
  };
}

export function setSelectedCoworkingOfferType(selectedCoworkingOfferType: TCoworkingOfferType): TFiltersActions {
  return {
    type: 'SetSelectedCoworkingOfferTypes',
    payload: { selectedCoworkingOfferType },
  };
}

export function clearCoworkingWokrplaceFilter(): TFiltersActions {
  return {
    type: 'ClearCoworkingWorkplaceFilter',
  };
}

export function setCoworkingWokrplaceFilter(): TFiltersActions {
  return {
    type: 'SetCoworkingWorkplaceFilter',
  };
}

export function setOfferCount(offerCount: number): TFiltersActions {
  return {
    type: 'SetOfferCount',
    payload: { offerCount },
  };
}

function metaRequestIsInvalid(filter: FilterState): boolean {
  const objectTypes = filter.selectedObjectTypes && filter.selectedObjectTypes.filter(t => t !== null);

  return isEmpty(objectTypes);
}

export function getNewbuildingMapObjectsCount(
  mapRequestQueryString: string,
  api: MobileWebsiteApi,
): Promise<{ offerCount: number }> {
  return toPromise(api.newbuildingsSearchMap(mapRequestQueryString)).then(resp => {
    let offerCount = 0;

    if (resp) {
      const { data, status } = resp.result;

      if (status && status.toLowerCase() === 'ok') {
        offerCount = data.offersCount || 0;
      }

      return { offerCount };
    }

    throw new Error('Wrong response');
  });
}

export interface IGetOfferCountParams {
  filter?: FilterState;
  isNewbuildingsMapModeEnabled?: boolean;
}

export function getOfferCount({
  filter,
  isNewbuildingsMapModeEnabled,
}: IGetOfferCountParams): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const currentFilter = filter || state.filter;

    const isNewbuildingsMapMode =
      isNewbuildingsMapModeEnabled !== undefined ? isNewbuildingsMapModeEnabled : state.mapPage.newbuildingsPinsEnabled;

    if (metaRequestIsInvalid(currentFilter)) {
      return new Promise<void>(resolve => {
        dispatch<TFiltersActions>(setOfferCount(0));
        resolve();
      });
    }

    const jsonQuery = filterToJsonQuery(
      currentFilter,
      {
        isNewbuildingsMapMode,
        optionalRegionInfo: getOptionalRegionInfo(state.regions.currentRegionInfo),
      },
      state.queryClient,
    );

    dispatch<TFiltersActions>({ type: 'RequestOfferCount' });

    const nextJsonQuery: IJsonQuery = getReplaceCoworkingOfficeType(jsonQuery);

    return getUrlByJsonQuery(nextJsonQuery, api)
      .then(data => {
        const {
          filter: { userInput },
        } = state;

        dispatch<TFiltersActions>(setOfferCount(data.count));
        dispatch<TQueryStringActions>(setQueryString(data.querystring));

        if (data.count === 0 && userInput) {
          trackGeoSuggestNotFound(userInput);
        }
      })
      .catch((err: Error) => {
        dispatch<TFiltersActions>(setOfferCount(0));
        dispatch<TQueryStringActions>(setQueryString(''));
      });
  };
}

export function clearFilters(): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState) => {
    const defaultRegion = parseInt(Object.keys(getState().regions.data.default)[0], 10);
    dispatch<TFiltersActions>({
      type: 'ClearFilter',
      payload: {
        defaultRegion,
      },
    });

    return Promise.resolve();
  };
}

export function setWithPik(withPik: boolean): TFiltersActions {
  return {
    type: 'SetWithPik',
    payload: { withPik },
  };
}

export function setWithCustom(withCustom: false, builderId?: number): TFiltersActions;
export function setWithCustom(withCustom: true, builderId: number): TFiltersActions;
export function setWithCustom(withCustom: boolean, builderId: number | undefined): TFiltersActions;
export function setWithCustom(withCustom: boolean, builderId: number | undefined): TFiltersActions {
  return {
    type: 'SetWithCustom',
    payload: {
      builderId,
      withCustom,
    },
  } as TFiltersActions;
}

export function setRegionsSettings(regionSettings: IRegionsSettings): TFiltersActions {
  return {
    type: 'SetRegionsSettings',
    payload: {
      regionSettings,
    },
  };
}

export function getRegionSettingsByRegionId(
  subdomain: string,
  regionId: number,
  api: MobileWebsiteApi,
  logger?: ILogger,
): Promise<ISettingsResponseData | void> {
  return timeoutPromise(toPromise(api.getRegionSettingsByRegionId(subdomain, regionId)), 2000)
    .then(res => {
      const { data, status } = res.result;
      if (status && status.toLowerCase() === 'ok') {
        return data;
      }
      throw new Error(`Wrong response: ${status}`);
    })
    .catch(err => {
      if (logger) {
        logger.error('getRegionSettingsByRegionId error', { value: JSON.stringify(err) });
      }
    });
}

export function getRegionSettings(
  subdomain: string,
  regionId: number,
  logger?: ILogger,
): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState, { api }) => {
    return getRegionSettingsByRegionId(subdomain, regionId, api, logger)
      .then(data => {
        if (data) {
          dispatch<TFiltersActions>(
            setRegionsSettings({
              isPikPromoEnabled: data.isPikPromoEnabled,
              isOfferNewEnabled: data.isOfferNewEnabled,
              isDealRentEnabled: data.isDealRentEnabled,
              initialized: true,
            }),
          );
        }
      })
      .catch(err => {
        dispatch<TFiltersActions>(setRegionsSettings(defaultRegionsSettings));
      });
  };
}

export function setSteadStatus(statuses?: SteadStatus[]): TFiltersActions {
  return {
    type: 'SetSteadStatus',
    payload: statuses,
  };
}

export function toggleSteadCondition(condition: SteadCondition): TFiltersActions {
  return {
    type: 'ToggleSteadCondition',
    payload: condition,
  };
}

export function toggleBoxType(boxType: boxTypeDict): TFiltersActions {
  return {
    type: 'ToggleBoxType',
    payload: boxType,
  };
}

export function toggleSaleTypeFlat(saleType: SaleTypeFlat): TFiltersActions {
  return {
    type: 'ToggleSaleTypeFlat',
    payload: saleType,
  };
}

export function toggleContractType(contractType: ContractType): TFiltersActions {
  return {
    type: 'ToggleContractType',
    payload: contractType,
  };
}

export function setSubwayDistanceMovement(movementMethod?: MovementMethod): TFiltersActions {
  return {
    type: 'SetSubwayDistanceMovement',
    payload: movementMethod,
  };
}

export function setSubwayMaxDistance(maxDistance?: number): TFiltersActions {
  return {
    type: 'SetSubwayMaxDistance',
    payload: maxDistance,
  };
}

export function toggleBuildingClass(buildingClass: BuildingClassType): TFiltersActions {
  return {
    type: 'ToggleBuildingClass',
    payload: buildingClass,
  };
}

export function setSelectedHouseTypes(selectedHouseTypes: string[]): TFiltersActions {
  return {
    type: 'SetSelectedHouseTypes',
    payload: { selectedHouseTypes },
  };
}

export function setHasVideo(hasVideo: boolean): TFiltersActions {
  return {
    type: 'SetHasVideo',
    payload: { hasVideo },
  };
}

export function setHouseMaterials(houseMaterials: string[]): TFiltersActions {
  return {
    type: 'SetHouseMaterials',
    payload: { houseMaterials },
  };
}

export function setHouseMinYear(houseMinYear: number): TFiltersActions {
  return {
    type: 'setHouseMinYear',
    payload: { houseMinYear },
  };
}

export function setHouseMaxYear(houseMaxYear: number): TFiltersActions {
  return {
    type: 'setHouseMaxYear',
    payload: { houseMaxYear },
  };
}

export function setHighways(highways: number[]): TFiltersActions {
  return {
    type: 'SetHighways',
    payload: { highways },
  };
}

export function setBathroomType(value: BathroomType): TFiltersActions {
  const bathroomType = value || BathroomType.Any;

  return {
    type: 'SetBathroomType',
    payload: { bathroomType },
  };
}

export function setRoomType(value: RoomType): TFiltersActions {
  const roomType = value || RoomType.Any;

  return {
    type: 'SetRoomType',
    payload: { roomType },
  };
}

export function setBathroomPlacement(value: BathroomPlacement): TFiltersActions {
  const bathroomPlacement = value || BathroomPlacement.Any;

  return {
    type: 'SetBathroomPlacement',
    payload: { bathroomPlacement },
  };
}

export function setBathroomsCount(bathroomsCount: number): TFiltersActions {
  return {
    type: 'SetBathroomsCount',
    payload: { bathroomsCount },
  };
}

export function toggleFlatCondition(condition: FlatCondition): TFiltersActions {
  return {
    type: 'ToggleFlatCondition',
    payload: condition,
  };
}

export function setWithFurniture(withFurniture?: boolean): TFiltersActions {
  return {
    type: 'SetWithFurniture',
    payload: { withFurniture },
  };
}

export function setSelectedRoomsCount(roomsCount: number[]): TFiltersActions {
  return {
    type: 'SetSelectedRoomsCount',
    payload: { roomsCount },
  };
}

export function setSelectedRentRoomsCount(rentRoomsCount?: number): TFiltersActions {
  return {
    type: 'SetSelectedRentRoomsCount',
    payload: { rentRoomsCount },
  };
}

export function setAccessSystem(accessSystem?: boolean): TFiltersActions {
  return {
    type: 'SetAccessSystem',
    payload: { accessSystem },
  };
}

export function setEnterTypes(enterTypes: EnterType[]): TFiltersActions {
  return {
    type: 'SetEnterTypes',
    payload: { enterTypes },
  };
}

export function setDecoration(decoration: boolean): TFiltersActions {
  return {
    type: 'SetDecoration',
    payload: { decoration },
  };
}

export function setOnlyFlat(onlyFlat: boolean | undefined): TFiltersActions {
  return {
    type: 'SetOnlyFlat',
    payload: { onlyFlat },
  };
}

export function setApartment(apartment: boolean | undefined): TFiltersActions {
  return {
    type: 'SetApartment',
    payload: { apartment },
  };
}

export function setWindowsType(windowsType: number | undefined): TFiltersActions {
  return {
    type: 'SetWindowsType',
    payload: { windowsType },
  };
}

export function setCeilingHeight(ceilingHeight: number): TFiltersActions {
  return {
    type: 'SetCeilingHeight',
    payload: { ceilingHeight },
  };
}

export function setNotFirstFloor(notFirstFloor: boolean): TFiltersActions {
  return {
    type: 'SetFirstFloor',
    payload: { firstFloor: !notFirstFloor ? undefined : false },
  };
}

export function setFirstFloor(firstFloor: boolean): TFiltersActions {
  return {
    type: 'SetFirstFloor',
    payload: { firstFloor: firstFloor || undefined },
  };
}

export function setOnlyLastFloor(onlyLastFloor: boolean): TFiltersActions {
  return {
    type: 'SetLastFloor',
    payload: { notLastFloor: !onlyLastFloor ? undefined : false },
  };
}

export function setNotLastFloor(notLastFloor: boolean): TFiltersActions {
  return {
    type: 'SetLastFloor',
    payload: { notLastFloor: notLastFloor || undefined },
  };
}
export function setBasementFloor(basementFloor: boolean): TFiltersActions {
  return {
    type: 'SetBasementFloor',
    payload: { basementFloor },
  };
}

export function setSemibasementFloor(semibasementFloor: boolean): TFiltersActions {
  return {
    type: 'SetSemibasementFloor',
    payload: { semibasementFloor },
  };
}

export function filterToAdvancedFiltersCount(
  filter: FilterState,
  filtersVisibilityParams: IFiltersVisibilityParams | undefined,
  queryClient: QueryClient,
): TFiltersActions {
  return {
    type: 'GetAdvancedFiltersCount',
    payload: { jsonQuery: filterToJsonQuery(filter, filtersVisibilityParams, queryClient) },
  };
}

export function jsonQueryToAdvancedFiltersCount(jsonQuery: IJsonQuery): TFiltersActions {
  return {
    type: 'GetAdvancedFiltersCount',
    payload: { jsonQuery },
  };
}

export function setFromOwner(isByHomeOwner: boolean): TFiltersActions {
  return {
    type: 'SetFromOwner',
    payload: { isByHomeOwner },
  };
}

export function setAgentNoFee(agentNoFee: boolean): TFiltersActions {
  return {
    type: 'SetAgentNoFee',
    payload: { agentNoFee },
  };
}

export function setNoDeposit(noDeposit: boolean): TFiltersActions {
  return {
    type: 'SetNoDeposit',
    payload: { withDeposit: noDeposit ? false : undefined },
  };
}

export function setRepresentative(fromRepresentative: boolean): TFiltersActions {
  return {
    type: 'SetRepresentative',
    payload: { fromRepresentative },
  };
}

export function setInfrastructure(infrastructureType: string): TTypedThunkAction<Promise<void>> {
  return (dispatch, getState) => {
    let queryString = location.search.slice(1).split('&');

    dispatch(setInfrastructureParam(infrastructureType));

    if (getState().filter.infrastructure[infrastructureType] === false) {
      dispatch(removeInfrastructureFromMapObjects(infrastructureType));
    }

    queryString = changeInfrastructureInQueryString(getState(), queryString);
    dispatch(push(`${location.pathname}?${queryString.join('&')}`));
    dispatch(toggleInfrastructure(enableInfrastructure(getState())));

    return Promise.resolve();
  };
}

export function changeInfrastructureInQueryString(
  { filter: { infrastructure } }: ReduxState,
  queryString: Array<string>,
): string[] {
  forIn(infrastructure, (val, key) => {
    if (queryString.includes(`${queryStringParams[key]}=true`)) {
      return val === true ? queryString : deleteValueFromQueryString(queryString, key);
    } else {
      return val === true ? putValueInQureyString(queryString, key) : queryString;
    }
  });

  return queryString;
}

function putValueInQureyString(queryString: Array<string>, param: string): number {
  return queryString.push(`${queryStringParams[param]}=true`);
}

function deleteValueFromQueryString(queryString: Array<string>, param: string): string[] {
  return queryString.splice(queryString.indexOf(`${queryStringParams[param]}=true`), 1);
}

function enableInfrastructure({ filter: { infrastructure } }: ReduxState): boolean {
  return toPairs(infrastructure).filter(pair => pair[1]).length > 0;
}

export function setInitialInfrastructure(infrastructure: IMapInfrastructure): TFiltersActions {
  return {
    type: 'SetInitialInfrastructure',
    payload: { infrastructure },
  };
}

export function setInfrastructureParam(infrastructureType: string | number): TFiltersActions {
  return {
    type: 'SetInfrastructure',
    payload: { infrastructureType },
  };
}

export function setUserInput(userInput: string): TFiltersActions {
  return {
    type: 'SetUserInput',
    payload: { userInput },
  };
}

export function setFromDomrf(isFromDomrf: boolean): TFiltersActions {
  return {
    type: 'SetFromDomrf',
    payload: { isFromDomrf },
  };
}

export function setCoworkingAmenity(amenityIndexList: string[]): TFiltersActions {
  return {
    type: 'SetCoworkingAmenity',
    payload: { amenityIndexList },
  };
}

export function setCoworkingAccess(coworkingAccess: ICoworkingAccess): TFiltersActions {
  return {
    type: 'SetCoworkingAccess',
    payload: { coworkingAccess },
  };
}

export function setFromCommercialOwner(isByCommercialOwner: boolean): TFiltersActions {
  return {
    type: 'SetFromCommercialOwner',
    payload: { isByCommercialOwner },
  };
}

export function setNewobjectCompilation(compilationType: string, checked: boolean): TFiltersActions {
  return {
    type: 'SetNewobjectCompilation',
    payload: { compilationType, checked },
  };
}

export function setIsUpcomingSale(checked: boolean): TFiltersActions {
  return {
    type: 'SetIsUpcomingSale',
    payload: checked,
  };
}

export function setElectronicTrading(electronicTrading?: number): TFiltersActions {
  return {
    type: 'SetElectronicTrading',
    payload: {
      electronicTrading: electronicTrading as EElectronicTradingType,
    },
  };
}
