import * as React from 'react';
import { ButtonCheckbox, Label } from '@cian/components';

import ButtonCheckboxContainer from '../../../ui/button_checkbox_container/button_checkbox_container';
import { IFieldProps, IsVisibleCheck } from '../field';

const styles = require('../field.css');
const fieldStyle = require('./coworking_access.css');

export function Component({ filter, actions: { setCoworkingAccess } }: IFieldProps) {
  const { coworkingAccess = {} } = filter;
  const { allDay = false, allWeek = false } = coworkingAccess;

  const options = React.useMemo(() => ([
    {
      key: 'coworking-all-day-access',
      value: allDay,
      onChange: (value: boolean) => setCoworkingAccess({...coworkingAccess, allDay: value}),
      title: 'Круглосуточно',
    },
    {
      key: 'coworking-all-week-access',
      value: allWeek,
      onChange: (value: boolean) => setCoworkingAccess({...coworkingAccess, allWeek: value}),
      title: 'В выходные дни',
    },
  ]), [coworkingAccess, allDay, allWeek, setCoworkingAccess]);

  return (
    <div className={styles.field} data-mark="FieldCoworkingAccess">
      <Label label="Доступ">
        <ButtonCheckboxContainer>
          {
            options.map(item => (
              <ButtonCheckbox
                key={item.key}
                id={item.key}
                name={item.key}
                mobile
                checked={item.value}
                onValueChange={item.onChange}
                buttonCheckboxStyle={[styles['buttonCheckbox'], fieldStyle['coworking-access-type']]}
              >
                {item.title}
              </ButtonCheckbox>
            ))
          }
        </ButtonCheckboxContainer>
      </Label>
    </div>
  );
}

export const isVisible: IsVisibleCheck = () => true;
