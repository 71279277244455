const monthNames = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

/** toLocaleString(options) не работает на мобильных устрйоствах. */
export const getDate = (date: string): string => {
  const jsDate = new Date(date);
  if (isNaN(jsDate.getTime())) {
    return '';
  }
  jsDate.setTime( jsDate.getTime() + jsDate.getTimezoneOffset() * 60 * 1000);
  const year = jsDate.getFullYear();
  const day = jsDate.getDate();
  const month = monthNames[jsDate.getMonth()];

  return `${day} ${month} ${year}`;
};

export function daysToMilliseconds(days: number) {
  return days * 24 * 3600 * 1000;
}

export function yearsToMilliseconds(years: number) {
  return daysToMilliseconds(years * 366);
}
