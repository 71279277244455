import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { IGetBusinessPossibleAppointmentsResponse } from '../../../../../repositories/announcements/v1/get-business-possible-appointments';
import { useApplicationContext } from '../../../../../utils/applicationContext';

import { QueryError, QueryKey, getQueryKey } from './internal/config';
import { fetchGetBusinessPossibleAppointmentsService } from './internal/fetchService';

export function useGetBusinessPossibleAppointmentsQuery<TData = IGetBusinessPossibleAppointmentsResponse>(
  options?: UseQueryOptions<IGetBusinessPossibleAppointmentsResponse, QueryError, TData, QueryKey>,
): UseQueryResult<TData, QueryError> {
  const context = useApplicationContext();

  return useQuery<IGetBusinessPossibleAppointmentsResponse, QueryError, TData, QueryKey>({
    queryKey: getQueryKey(),
    queryFn: () => fetchGetBusinessPossibleAppointmentsService(context),
    ...options,
  });
}
