import {
  IFavoritesOptions,
  IFavoriteStats,
  IGetFavoritesIdsResponseOffers,
  IGetFavouritesResponseOrder,
} from '../../api/api';
import { IOfferInfo } from '../offer/model';
import { IGk } from '../offers_list/gk';
import { FAVORITES_PER_PAGE_COUNT } from './actions';

export interface IFavoritesData {
  stats: IFavoriteStats;
  offers: IOfferInfo[];
  newobjects: IGk[];
  favourites: IGetFavouritesResponseOrder[];
  perPage: number;
  totalCurrent: number;
}

export type FavoritesState = {
  count: number,
  ids: FavouritesIDs,
  initialized: boolean,
  pagesInitialized: boolean;
  loading: boolean,
  favouriteItems: IFavoritesData,
  offersIds?: IGetFavoritesIdsResponseOffers,
  options: IFavoritesOptions;
  pages: IFavouritePage[];
};

export type FavouritesIDs = {
  rent: number[],
  sale: number[],
};

export interface IFavouritePage {
  num: number;
  offers: IOfferInfo[];
  newobjects: IGk[];
  favourites: IGetFavouritesResponseOrder[];
}

export interface IFormattedFavoritePage {
  favouriteItems: IFavouritePage;
  num: number;
}

export const defaultFavoritesState: FavoritesState = {
  count: 0,
  ids: {
    rent: [],
    sale: [],
  },
  initialized: false,
  pagesInitialized: false,
  loading: false,
  offersIds: {} as IGetFavoritesIdsResponseOffers,
  favouriteItems: {
    favourites: [],
    stats: {
      total: 0,
      perDealType: {
        sale: 0,
        rent: 0,
      },
      perOfferType: {
        residential: 0,
        commercial: 0,
        newobject: 0,
      },
    },
    offers: [],
    newobjects: [],
    perPage: FAVORITES_PER_PAGE_COUNT,
    totalCurrent: 0,
  },
  options: {
    dealType: 'all',
    page: 1,
    offset: 0,
    sortType: 'date',
  },
  pages: [],
};
