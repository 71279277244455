import * as React from 'react';

import  { mergeStyles } from '@cian/components';
import { without } from 'lodash';
import { DealType, flatObjectTypes } from '../../../redux/filters/model';
import { Field } from '../../field';
import * as fields from '../../fields/';
import { IFieldProps, IsVisibleCheck } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    return (
      <div {...mergeStyles(styles.field, styles.withFurnitureContainer)} data-mark="FieldWithFurnitureFlat">
        <Field field={fields.withFurniture} />
      </div>
    );
  }
}

const allowedFlatObjectTypes = [
  ...flatObjectTypes,
];

export const isVisible: IsVisibleCheck = filter =>
  filter.dealType === DealType.Rent
  && filter.selectedObjectTypes.length > 0
  && without(filter.selectedObjectTypes, ...allowedFlatObjectTypes).length === 0;
