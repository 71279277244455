import * as React from 'react';

import { ISelectMobileOption, Label } from '@cian/components';
import { without } from 'lodash';
import { SelectMobile } from '../../common/select/select';
import { commercialObjectType, EnterType } from '../field';
import { IFieldProps, IsVisibleCheck } from '../field';

const styles = require('../field.css');

export class Component extends React.Component<IFieldProps, object> {
  public render() {
    const typesList: ISelectMobileOption[] = [{
      id: EnterType.CommonFromStreet.toString(),
      label: 'Общий с улицы',
    }, {
      id: EnterType.CommonFromYard.toString(),
      label: 'Общий со двора',
    }, {
      id: EnterType.SeparateFromStreet.toString(),
      label: 'Отдельный с улицы',
    }, {
      id: EnterType.SeparateFromYard.toString(),
      label: 'Отдельный со двора',
    }];
    const currentValue = this.props.filter.enterTypes.map(String);

    return (
      <div className={styles.field} data-mark="FieldEnterType">
        <Label label="Вход">
          <SelectMobile
            multiple
            onValueChange={this.onValueSelect}
            options={typesList}
            label="Неважно"
            value={currentValue}
          >
          </SelectMobile>
        </Label>
      </div>
    );
  }

  private onValueSelect = (options: string[]) => {
    const selectedValue = options.map(Number);
    this.props.actions.setEnterTypes(selectedValue);
  }
}

const allowedObjectTypes = [
  commercialObjectType.Marketplace,
  commercialObjectType.Psn,
  commercialObjectType.PublicCatering,
  commercialObjectType.CarService,
  commercialObjectType.DomesticService,
  commercialObjectType.Building,
];

export const isVisible: IsVisibleCheck = filter =>
  filter.selectedObjectTypes.length > 0
  && without(filter.selectedObjectTypes, ...allowedObjectTypes).length === 0;
