import { Button, mergeStyles } from '@cian/components';

import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';

import { openAuthenticationPopup } from '../../redux/authenticationPopup/actions';
import { ReduxState, TTypedThunkDispatch } from '../../redux/model';
import { newPageQueueHandler } from '../../utils/analytics';
import { inject } from '../../utils/context_provider';
import { CookiesUtils } from '../../utils/cookies';

import { trackingEnterLK, trackingShowPopup } from './tracking';

const style = require('./style.css');

export interface ILoginMotivationPopupProps {
  cookiesUtils: CookiesUtils;
  isAuthenticated: boolean;
  pathname: string;
  isAuthPageDrawPending: boolean;
  openAuth(): void;
}

export interface ILoginMotivationPopupState {
  open: boolean;
}

const COOKIE_NAME = 'render_mobile_popup_in_registation';

class LoginMotivationPopup extends Component<ILoginMotivationPopupProps, ILoginMotivationPopupState> {
  public constructor(props: ILoginMotivationPopupProps) {
    super(props);

    this.state = {
      open: !props.isAuthenticated && !props.cookiesUtils.get(COOKIE_NAME),
    };
  }

  // eslint-disable-next-line react/no-deprecated
  public componentWillReceiveProps(nextProps: ILoginMotivationPopupProps) {
    if (!nextProps.isAuthPageDrawPending && this.state.open) {
      this.closePopup();
    }
  }

  public componentDidMount() {
    if (this.state.open) {
      document.addEventListener('click', this.closePopup);
      document.addEventListener('scroll', this.closePopup);
      newPageQueueHandler.subscribe(trackingShowPopup);
    }
  }

  public componentDidUpdate(prevProps: ILoginMotivationPopupProps, prevState: ILoginMotivationPopupState) {
    if (!this.state.open) {
      document.removeEventListener('click', this.closePopup);
      document.removeEventListener('scroll', this.closePopup);
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.closePopup);
    document.removeEventListener('scroll', this.closePopup);
  }

  public render() {
    const loginMotivationVisibleClass = this.state.open ? style['login-motivation-popup--visible'] : '';
    const buttonPendingClass = this.props.isAuthPageDrawPending ? style['button-enter--loader'] : '';

    return (
      <div
        onClick={this.handlePopupClick}
        {...mergeStyles([style['login-motivation-popup'], loginMotivationVisibleClass])}
      >
        <div className={style['login-motivation-popup_container']}>
          <p className={style['login-motivation-popup_title']}>Войдите в личный кабинет</p>
          <p className={style['login-motivation-popup_text']}>
            для доступа к избранным объявлениям и сохраненным поискам
          </p>
          <div className={style['login-motivation-popup_button-container']}>
            <Button
              buttonStyle={mergeStyles([style['button-enter'], buttonPendingClass]).className}
              theme="button/solid"
              onClick={this.handleClickEnterButton}
            >
              <span className={style['button-text']}>Войти</span>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  private handleClickEnterButton = () => {
    trackingEnterLK();

    this.props.openAuth();
  };

  private closePopup = () => {
    this.setState({
      open: false,
    });
    this.props.cookiesUtils.set(COOKIE_NAME, '1');
  };

  private handlePopupClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };
}

type TStateProps = Pick<ILoginMotivationPopupProps, 'isAuthenticated' | 'pathname' | 'isAuthPageDrawPending'>;
type TDispatchProps = Pick<ILoginMotivationPopupProps, 'openAuth'>;

export const LoginMotivationPopupContainer = connect<TStateProps, TDispatchProps>(
  (state: ReduxState) => ({
    isAuthenticated: state.common.isAuthenticated,
    pathname: state.routing.locationBeforeTransitions.pathname,
    isAuthPageDrawPending: state.authPage.isAuthPageDrawPending,
  }),
  (dispatch: TTypedThunkDispatch) => ({
    openAuth: () => dispatch(openAuthenticationPopup()),
  }),
)(inject(['cookiesUtils'])<TStateProps & TDispatchProps>(LoginMotivationPopup));
