import { IStyleConfig } from '@cian/components';

import * as React from 'react';

import { default as SectionComponent } from '../../ui/section/section';
import { Field, IFieldContext, injectAll } from '../field';
import * as fields from '../fields/';

import { AdvancedCoworkingFilters } from './components/AdvancedCoworkingFilters';

const styles = require('./advanced.css');

interface IProps {
  inPopup?: boolean;
  isRent?: boolean;
  isCommercialLand?: boolean;
  isSuburban?: boolean;
  isCoworking?: boolean;
  inSuburbanOfferExperiment?: boolean;
}

export function Section(props: { children?: React.ReactNode; containerStyle?: IStyleConfig }) {
  return (
    <SectionComponent containerStyle={props.containerStyle} left={0} right={0}>
      {props.children}
    </SectionComponent>
  );
}

class AdvancedFiltersListComponent extends React.Component<IProps & IFieldContext> {
  public render() {
    const { isSuburban, isCommercialLand, isCoworking, inSuburbanOfferExperiment } = this.props;

    if (isCoworking) {
      return (
        <AdvancedCoworkingFilters>
          <div className={styles['footer']}>
            <Field field={fields.saveSearchButton} />
            <Field field={fields.clearFilters} inPopup={this.props.inPopup} />
          </div>
        </AdvancedCoworkingFilters>
      );
    }

    return (
      <div data-mark="AdvancedFiltersList">
        <Section>
          <Field field={fields.subwayDistance} />
        </Section>
        <Section>
          <Field field={fields.direction} />
        </Section>
        <Section>
          <Field field={fields.aboutObject} />
        </Section>
        {isSuburban && (
          <Section>
            <Field field={fields.layout} />
          </Section>
        )}
        <Section>
          <Field field={fields.conditions} />
        </Section>
        <Section>
          <Field field={fields.aboutHouse} />
        </Section>
        <Section>
          <Field field={fields.aboutStead} />
        </Section>
        <Section>
          <Field field={fields.terms} />
        </Section>
        {(isSuburban || isCommercialLand) && (
          <Section>
            <div className={styles['filter-only-block']}>
              {inSuburbanOfferExperiment && <Field field={fields.suburbanOfferFilterExp} />}
              <Field field={fields.publicationPeriodAB} />
              <div className={styles['photo-filter']}>
                <Field field={fields.withPhoto} />
              </div>
              <Field field={fields.hasVideo} />
            </div>
          </Section>
        )}
        <div className={styles['footer']}>
          <Field field={fields.saveSearchButton} />
          <Field field={fields.clearFilters} inPopup={this.props.inPopup} />
        </div>
      </div>
    );
  }
}

export const AdvancedFiltersList = injectAll(AdvancedFiltersListComponent);
