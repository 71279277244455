/* eslint-disable max-lines */

import { isEqual, omit, omitBy } from 'lodash';
import { getType } from 'typesafe-actions';

import { isCommercialObjectGroup, isFlatObjectGroup } from '../../filters/fields/field';
import { ifNull, toggleItem } from '../../utils/helpers';

import { ISetRegionPayload, TFiltersActions, setBusinessAppointments } from './actions';
import { jsonQueryToMobileAdvancedFiltersCount } from './json_query_to_advanced_filters_count';
import {
  BathroomType,
  BathroomsCountType,
  DealType,
  FilterState,
  FiltersSection,
  IGeoTag,
  IMapInfrastructure,
  IRange,
  MovementMethod,
  SteadStatus,
  TCoworkingOfferType,
  TObjectType,
  commercialObjectType,
  coworkingOfferType,
  defaultCommercialFilter,
  defaultFilter,
  defaultNewBuildingFilter,
  defaultRegionsSettings,
  defaultRentFilter,
  defaultSaleFilter,
  defaultShortRentFilter,
  flatObjectType,
  objectTypeVisibility,
} from './model';

type Reducer = (state: FilterState, action: TFiltersActions) => FilterState;

type Payload = {
  valueType: 'min' | 'max';
  value: number;
  field: string;
};

function setRange(state: FilterState, payload: Payload): FilterState {
  let { valueType, value, field } = payload;

  value = value < 0 ? 0 : value;

  type State = {
    [key: string]: any; // tslint:disable-line: no-any
  };

  const range: IRange = Object.assign({}, (state as State)[field], { [valueType]: value });
  const omitedRange = omitBy(range, prop => prop === null);

  return !isEqual(omitedRange, (state as State)[field]) ? Object.assign({}, state, { [field]: omitedRange }) : state;
}

const deleteGeoTag = (state: FilterState, payload: { id?: number; kind?: string; name?: string }): FilterState => {
  const geoTags = state.geoTags.filter(
    t => t.kind.toLowerCase() !== (payload.kind != null ? payload.kind.toLowerCase() : '') || t.id !== payload.id,
  );

  return { ...state, geoTags, kpId: payload.kind === 'village' ? undefined : state.kpId };
};

const setRegion = (state: FilterState, payload: ISetRegionPayload): FilterState => {
  const { region, resetTags } = payload;

  return {
    ...state,
    region,
    geoTags: resetTags ? [] : state.geoTags,
  };
};

const setSteadStatus = (state: FilterState, steadStatuses?: SteadStatus[]): FilterState => {
  return Object.assign({}, state, { steadStatuses });
};

const setUndergroundDistance = (
  state: FilterState,
  movementMethod?: MovementMethod,
  maxDistance?: number,
): FilterState => {
  const distance = { movementMethod, maxDistance };

  return { ...state, subwayDistance: distance };
};

const setSubwayDistanceMovement = (state: FilterState, movementMethod?: MovementMethod): FilterState => {
  const { maxDistance: oldMaxDistance } = state.subwayDistance;
  const newMaxDistance = movementMethod == null ? undefined : ifNull(oldMaxDistance, 45);

  return setUndergroundDistance(state, movementMethod, newMaxDistance);
};

const setSubwayMaxDistance = (state: FilterState, maxDistance?: number): FilterState => {
  const { movementMethod: oldMovementMethod } = state.subwayDistance;
  const newMovementMethod = maxDistance == null ? undefined : ifNull(oldMovementMethod, MovementMethod.Walk);

  return setUndergroundDistance(state, newMovementMethod, maxDistance);
};

const toggleBuildingClass = toggleItemInStateArray.bind(null, 'selectedBuildingClasses');
const toggleSteadCondition = toggleItemInStateArray.bind(null, 'steadConditions');
const toggleBoxType = toggleItemInStateArray.bind(null, 'boxTypes');
const toggleFlatCondition = toggleItemInStateArray.bind(null, 'flatConditions');
const toggleContractType = toggleItemInStateArray.bind(null, 'contractTypes');
const toggleSaleTypeFlat = toggleItemInStateArray.bind(null, 'saleTypeFlat');

function toggleItemInStateArray<T>(key: string, state: FilterState, item: T): FilterState {
  // tslint:disable-next-line:no-any
  if (!(state as { [key: string]: any })[key]) {
    return state;
  }

  return {
    ...state,
    // tslint:disable-next-line:no-any
    [key]: toggleItem((state as { [key: string]: any })[key], item),
  };
}

function setTimeToSubwayForGeotags(state: FilterState, payload: IGeoTag): FilterState {
  const shouldSetTime =
    state.dealType === DealType.Rent &&
    state.geoTags.filter(t => t.kind.toLowerCase() === 'underground').length === 0 &&
    (isCommercialObjectGroup(state) || isFlatObjectGroup(state)) &&
    payload.kind.toLowerCase() === 'underground';

  return shouldSetTime ? { ...state, ...setSubwayMaxDistance(state, 45) } : state;
}

const setRoomsCounts = (state: FilterState, roomsCount: number[]): FilterState => {
  return { ...state, roomsCount };
};

const setRentRoomsCounts = (state: FilterState, rentRoomsCount?: number): FilterState => {
  return { ...state, rentRoomsCount };
};

const getFilteredObjectTypes = (filter: FilterState): TObjectType[] => {
  const filteredObjectTypes = filter.selectedObjectTypes.filter(
    type => !objectTypeVisibility[type] || !objectTypeVisibility[type].some(rule => rule(filter)),
  );

  return isEqual(filteredObjectTypes, filter.selectedObjectTypes) ? filter.selectedObjectTypes : filteredObjectTypes;
};

const statesDefaultFilters: { [key: number]: FilterState } = {
  [FiltersSection.Index]: defaultFilter,
  [FiltersSection.LongRent]: defaultRentFilter,
  [FiltersSection.Sale]: defaultSaleFilter,
  [FiltersSection.ShortRent]: defaultShortRentFilter,
  [FiltersSection.NewBuildings]: defaultNewBuildingFilter,
  [FiltersSection.Commercial]: defaultCommercialFilter,
};

const getDefaultStateFilter = (section: FiltersSection): FilterState => {
  return statesDefaultFilters[section] || defaultFilter;
};

type TInfrastructurePaylod = { infrastructureType: string | number };

function changeInfrastructure({ infrastructure }: FilterState, payload: TInfrastructurePaylod): IMapInfrastructure {
  switch (payload.infrastructureType) {
    case 'withSchools':
      return { ...infrastructure, withSchools: !infrastructure.withSchools };
    case 'withKindergartens':
      return { ...infrastructure, withKindergartens: !infrastructure.withKindergartens };
    case 'withHospitals':
      return { ...infrastructure, withHospitals: !infrastructure.withHospitals };
    case 'withGroceryStores':
      return { ...infrastructure, withGroceryStores: !infrastructure.withGroceryStores };
    case 'withCafes':
      return { ...infrastructure, withCafes: !infrastructure.withCafes };
    default:
      return infrastructure;
  }
}

export function getSelectedCoworkingOfferTypes(
  state: FilterState,
  selectedCoworkingOfferType: TCoworkingOfferType,
): TCoworkingOfferType[] | undefined {
  const prevCoworkingOfferTypes = state.selectedCoworkingOfferType;

  if (!prevCoworkingOfferTypes || !selectedCoworkingOfferType) {
    return undefined;
  }

  if (prevCoworkingOfferTypes.includes(selectedCoworkingOfferType)) {
    return prevCoworkingOfferTypes.filter(f => f !== selectedCoworkingOfferType);
  }

  return prevCoworkingOfferTypes.concat([selectedCoworkingOfferType]);
}

const filterReducer: Reducer = (state = defaultFilter, action) => {
  switch (action.type) {
    case 'ResetFilter': {
      const { region, lastUrl } = action.payload;

      return {
        ...state,
        ...getDefaultStateFilter(action.payload.section),
        region,
        lastUrl,
      };
    }

    case 'ClearFilter': {
      const keysToOmit: (keyof FilterState)[] = ['showAdvancedFilters', 'showAdvancedFiltersInPopup', 'dealType'];
      const sectionDefaultFilter = getDefaultStateFilter(state.section || FiltersSection.Index);
      const filter = omit<FilterState>(sectionDefaultFilter, keysToOmit);

      return {
        ...state,
        ...filter,
        region: action.payload.defaultRegion,
      };
    }

    case 'SetDealType': {
      const newFiltersState = {
        ...state,
        dealType: action.payload.dealType,
        contractTypes: [], //На разных табах разные типы контрактов
        isUpcomingSale: undefined,
      };

      if (
        state.selectedObjectTypes.length === 1 &&
        state.selectedObjectTypes[0] === commercialObjectType.Coworking &&
        state.selectedCoworkingOfferType
      ) {
        return {
          ...newFiltersState,
          selectedObjectTypes: [flatObjectType.Apartment],
          selectedCoworkingOfferType: undefined,
        };
      }

      return {
        ...newFiltersState,
        selectedObjectTypes: getFilteredObjectTypes(newFiltersState),
      };
    }

    case 'SetRentTime': {
      const newState = {
        ...state,
        rentTime: action.payload.rentTime,
      };

      return {
        ...newState,
        selectedObjectTypes: getFilteredObjectTypes(newState),
      };
    }

    case 'SetRegion': {
      return setRegion(state, action.payload);
    }

    case 'SetBathroomType': {
      const shouldSetOneBathroom =
        state.bathroomsCount === BathroomsCountType.Any && action.payload.bathroomType !== BathroomType.Any;

      const bathroomsCount = shouldSetOneBathroom ? BathroomsCountType.One : state.bathroomsCount;

      return {
        ...state,
        ...action.payload,
        bathroomsCount,
      };
    }

    case 'AdvancedFilters':
    case 'AdvancedFiltersInPopup':
    case 'SetAgentNoFee':
    case 'SetBathroomsCount':
    case 'SetBathroomPlacement':
    case 'SetCurrency':
    case 'SetDecoration':
    case 'SetDemolishedInMoscowProgramm':
    case 'SetFromOwner':
    case 'SetFromCommercialOwner':
    case 'SetGarageSubtype':
    case 'SetGeoTags':
    case 'SetHasVideo':
    case 'SetHighways':
    case 'SetHouseMaterials':
    case 'SetLiftsCount':
    case 'SetNoDeposit':
    case 'SetSelectedHouseTypes':
    case 'SetSeller':
    case 'SetSuburbanOfferFilter':
    case 'SetWithBalcony':
    case 'SetWithDiscount':
    case 'SetWithLoggia':
    case 'SetWithMortgage':
    case 'SetWithLayout':
    case 'SetWithPhoto':
    case 'SetWithPik':
    case 'SetWithCustom':
    case 'SetWithServiceLift':
    case 'SetYear':
    case 'SetLastFloor':
    case 'SetBasementFloor':
    case 'SetSemibasementFloor':
    case 'SetFirstFloor':
    case 'SetGeoTagsLoading':
    case 'SetRepresentative':
    case 'SetRoomType':
    case 'SetApartment':
    case 'SetOnlyFlat':
    case 'SetWindowsType':
      return {
        ...state,
        ...action.payload,
      };

    case getType(setBusinessAppointments):
      return {
        ...state,
        businessAppointments: action.payload,
      };

    case 'SetPublicationPeriod': {
      const nextState = { ...state };

      if (action.payload.publicationPeriod) {
        Object.assign(nextState, action.payload);
      } else {
        nextState.publicationPeriod = undefined;
      }

      return nextState;
    }

    case 'SetCeilingHeight': {
      const nextState = { ...state };

      if (action.payload.ceilingHeight) {
        Object.assign(nextState, action.payload);
      } else {
        nextState.ceilingHeight = undefined;
      }

      return nextState;
    }

    case 'SetSelectedCoworkingOfferTypes': {
      const { selectedCoworkingOfferType } = action.payload;

      return {
        ...state,
        selectedCoworkingOfferType: getSelectedCoworkingOfferTypes(state, selectedCoworkingOfferType),
      };
    }

    case 'ClearCoworkingWorkplaceFilter': {
      const selectedCoworkingOfferTypes = state.selectedCoworkingOfferType;

      if (!selectedCoworkingOfferTypes) {
        return state;
      }

      return {
        ...state,
        selectedCoworkingOfferType: selectedCoworkingOfferTypes.filter(
          offerType =>
            ![coworkingOfferType.FixedWorkplace as string, coworkingOfferType.FlexibleWorkplace as string].includes(
              offerType,
            ),
        ),
      };
    }

    case 'SetCoworkingWorkplaceFilter': {
      if (!state.selectedCoworkingOfferType) {
        return state;
      }

      const types = state.selectedCoworkingOfferType;
      const hasOffice = types.length === 1 && types[0] === coworkingOfferType.Office;

      const selectedCoworkingOfferTypeList = hasOffice
        ? [coworkingOfferType.Office, coworkingOfferType.FixedWorkplace, coworkingOfferType.FlexibleWorkplace]
        : [coworkingOfferType.FixedWorkplace, coworkingOfferType.FlexibleWorkplace];

      return {
        ...state,
        selectedCoworkingOfferType: selectedCoworkingOfferTypeList,
      };
    }

    case 'setHouseMaxYear':
      return {
        ...state,
        houseYear: {
          ...state.houseYear,
          max: action.payload.houseMaxYear,
        },
      };

    case 'setHouseMinYear':
      return {
        ...state,
        houseYear: {
          ...state.houseYear,
          min: action.payload.houseMinYear,
        },
      };

    case 'SetPriceType':
      return {
        ...state,
        priceType: action.payload.priceType,
      };

    case 'SetJsonQueryPriceType':
      return {
        ...state,
        jsonQueryPriceType: action.payload,
      };

    case 'AddGeoTag': {
      const { geoTag } = action.payload;

      return {
        ...setTimeToSubwayForGeotags(state, geoTag),
        geoTags: state.geoTags.concat(geoTag),
        kpId: geoTag.kind.toLocaleLowerCase() === 'village' ? geoTag.id : state.kpId,
      };
    }

    case 'SetRegionsSettings': {
      return {
        ...state,
        regionSettings: {
          ...defaultRegionsSettings,
          ...action.payload.regionSettings,
          initialized: true,
          isTabletNewPagesEnabled: true,
        },
      };
    }

    case 'SetWithNewobject': {
      const { withNewobject } = action.payload;
      let selectedHouseTypes = state.selectedHouseTypes;

      // нужно отфильтровать массив, иначе в нем останутся типы,
      // которых не должно быть в новостройке
      if (withNewobject) {
        selectedHouseTypes = state.selectedHouseTypes.filter(t => !['4', '5', '6'].includes(t));
      }

      return {
        ...state,
        withNewobject,
        selectedHouseTypes,
        withCustom: withNewobject ? state.withCustom : false,
      };
    }
    case 'SetHouseType': {
      return {
        ...state,
        ...action.payload,
        isUpcomingSale: undefined,
      };
    }
    case 'SetResaleOnly': {
      const { resaleOnly } = action.payload;

      return { ...state, resaleOnly };
    }

    case 'SetRooms': {
      const { roomType, checked: checkedState } = action.payload;

      return { ...state, rooms: { ...state.rooms, [roomType]: checkedState } };
    }

    case 'SetSaleTypesNewbuilding': {
      const { saleType, checked: checkedState } = action.payload;

      return {
        ...state,
        saleTypesNewbuilding: {
          ...state.saleTypesNewbuilding,
          [saleType]: checkedState,
        },
      };
    }

    case 'SetRange':
      return setRange(state, action.payload);

    case 'SetGarageType': {
      const { id, checked } = action.payload;
      let selectedGarageTypes = [...state.selectedGarageTypes];
      if (checked) {
        if (!selectedGarageTypes.includes(id)) {
          selectedGarageTypes.push(id);
        }
      } else {
        selectedGarageTypes = selectedGarageTypes.filter(i => i !== id);
      }

      return Object.assign({}, state, { selectedGarageTypes });
    }

    case 'DeleteError': {
      return { ...state, error: undefined };
    }

    case 'DeleteGeoTag': {
      return deleteGeoTag(state, action.payload);
    }

    case 'DeleteRailwayTags': {
      return {
        ...state,
        geoTags: [...state.geoTags.filter(tag => tag.kind !== 'railway')],
      };
    }

    case 'SetError': {
      return { ...state, error: action.payload };
    }

    case 'GetInitialFiltersSuccess': {
      const { filter, section } = action.payload;

      return {
        ...state,
        section,
        ...filter,
        lastUrl: action.payload.lastUrl,
      };
    }

    case 'SetFilterLastUrl': {
      return {
        ...state,
        lastUrl: action.payload.lastUrl,
      };
    }

    case 'SetOfferCount': {
      return {
        ...state,
        offerCount: action.payload.offerCount,
        isOfferCountFetching: false,
      };
    }

    case 'RequestOfferCount': {
      return { ...state, isOfferCountFetching: true };
    }

    case 'RejectOfferCount': {
      return { ...state, isOfferCountFetching: false };
    }

    case 'SetSteadStatus':
      return setSteadStatus(state, action.payload);

    case 'ToggleSteadCondition':
      return toggleSteadCondition(state, action.payload);

    case 'ToggleBoxType':
      return toggleBoxType(state, action.payload);

    case 'SetSubwayDistanceMovement':
      return setSubwayDistanceMovement(state, action.payload);

    case 'SetSubwayMaxDistance':
      return setSubwayMaxDistance(state, action.payload);

    case 'ToggleBuildingClass':
      return toggleBuildingClass(state, action.payload);

    case 'ToggleFlatCondition':
      return toggleFlatCondition(state, action.payload);

    case 'ToggleContractType':
      return toggleContractType(state, action.payload);

    case 'SetSaleTypeFlat':
      return toggleSaleTypeFlat(state, action.payload);

    case 'SetWithFurniture':
      return { ...state, withFurniture: action.payload.withFurniture };

    case 'SetSelectedRoomsCount':
      return setRoomsCounts(state, action.payload.roomsCount);

    case 'SetSelectedRentRoomsCount':
      return setRentRoomsCounts(state, action.payload.rentRoomsCount);

    case 'SetAccessSystem':
      return { ...state, accessSystem: action.payload.accessSystem };

    case 'SetEnterTypes':
      return { ...state, enterTypes: action.payload.enterTypes };

    case 'GetInitialFiltersPending':
      return state;

    case 'GetAdvancedFiltersCount': {
      const { jsonQuery } = action.payload;
      const advancedFiltersCount = jsonQueryToMobileAdvancedFiltersCount(jsonQuery);
      const { showAdvancedFiltersInPopup } = state;
      const shouldShow = showAdvancedFiltersInPopup == null && advancedFiltersCount > 0 ? true : undefined;

      return {
        ...state,
        advancedFiltersCount,
        showAdvancedFiltersInPopup: ifNull(showAdvancedFiltersInPopup, shouldShow),
      };
    }
    case 'CloseAdvancedSearch':
      return {
        ...state,
        showAdvancedFiltersInPopup: undefined,
      };

    case 'SetInfrastructure': {
      const infrastructure = changeInfrastructure(state, action.payload);

      return { ...state, infrastructure };
    }

    case 'SetInitialInfrastructure':
      return { ...state, infrastructure: action.payload.infrastructure };

    case 'SetUserInput':
      return { ...state, userInput: action.payload.userInput };

    case 'SetFromDomrf':
      return { ...state, isFromDomrf: action.payload.isFromDomrf };

    case 'SetSelectedObjectTypes': {
      const nextState = {
        ...state,
        ...action.payload,
        isUpcomingSale: undefined,
      };

      if (!state.selectedObjectTypes.includes('coworking') && nextState.selectedObjectTypes.includes('coworking')) {
        return {
          ...nextState,
          selectedCoworkingOfferType: ['office'],
        };
      } else if (!nextState.selectedObjectTypes.includes('coworking')) {
        return {
          ...nextState,
          selectedCoworkingOfferType: undefined,
        };
      }

      return nextState;
    }

    case 'SetCoworkingAmenity': {
      const { amenityIndexList } = action.payload;

      return {
        ...state,
        amenities: amenityIndexList,
      };
    }

    case 'SetCoworkingAccess': {
      const { coworkingAccess } = action.payload;

      return {
        ...state,
        coworkingAccess,
      };
    }

    case 'SetNewobjectCompilation': {
      const { compilationType, checked: checkedState } = action.payload;

      return { ...state, newobjectCompilation: { ...state.newobjectCompilation, [compilationType]: checkedState } };
    }

    case 'SetIsUpcomingSale': {
      if (isCommercialObjectGroup(state)) {
        return state;
      }

      return {
        ...getDefaultStateFilter(FiltersSection.NewBuildings),
        lastUrl: state.lastUrl,
        selectedObjectTypes: state.selectedObjectTypes,
        region: state.region,
        isUpcomingSale: action.payload,
      };
    }

    case 'SetElectronicTrading': {
      const { electronicTrading } = action.payload;

      return {
        ...state,
        electronicTrading,
      };
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/no-default-export
export default filterReducer;
