import * as React from 'react';
import { IMapInfrastructure } from '../../../../redux/filters/model';
import Icon from '../../../../ui/icon/icon';
import { AnimatedTooltip, TooltipPosition } from '../../../../ui/tooltip/tooltip';
import { ItemContainer } from '../Item';

const styles = require('./body.css');

interface IInfrastructureBodyProps {
  opened: boolean;
}

const items: {name: keyof IMapInfrastructure, icon: React.ReactNode}[] = [
  {name: 'withSchools', icon: <Icon name="infrastructure/school"/> },
  {name: 'withKindergartens', icon: <Icon name="infrastructure/kindergarten"/> },
  {name: 'withHospitals', icon: <Icon name="infrastructure/hospital"/> },
  {name: 'withGroceryStores', icon: <Icon name="infrastructure/grocery-store"/> },
  {name: 'withCafes', icon: <Icon name="infrastructure/cafe"/> },
];

export function Body({opened}: IInfrastructureBodyProps) {
  return(
    <AnimatedTooltip
      position={TooltipPosition.Bottom}
      visible={opened}
      tooltipStyle={styles['body']}
    >
      {items.map(({icon, name}, index) => (
        <ItemContainer
          key={index}
          icon={icon}
          name={name}
        />
      ))}
    </AnimatedTooltip>
  );
}
