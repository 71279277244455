import { IOfferDetail } from '../../../api/models/offer_card';
import {
  IOfferIconField,
  IOfferIconListMap,
} from '../../../redux/offer/model';

export const infrastructureMap: IOfferIconListMap = new Map([
  ['hasElectricity', {
    name: 'Электричество',
    icon: 'card/ic-electricity',
  }],
  ['hasLight', {
    name: 'Свет',
    icon: 'card/ic-electricity',
  }],
  ['hasWater', {
    name: 'Вода',
    icon: 'card/ic-water-system',
  }],
  ['hasHeating', {
    name: 'Отопление',
    icon: 'card/ic-radiator',
  }],
  ['hasExtinguishingSystem', {
    name: 'Система пожаротушения',
    icon: 'card/ic-fire',
  }],
]);

export const responseToGarageInfrastructure = (offer: IOfferDetail): IOfferIconField[] => {
  const newState: IOfferIconField[] = [];

  if (offer.offerType !== 'commercial') {
    return [];
  }

  infrastructureMap.forEach((value: { name: string; icon: string; }, key: keyof IOfferDetail) => {
    if (offer[key]) {
      newState.push(value);
    }
  });

  return newState;
};
