import { TAutoOfferChangeHintActions, SHOW_TYPE } from './actions';

type Reducer = (state: boolean, action: TAutoOfferChangeHintActions) => boolean;

export const autoOfferChangeHintReducer: Reducer = (
  state = false,
  action: TAutoOfferChangeHintActions,
) => {
  switch (action.type) {
    case SHOW_TYPE:
      return action.payload.isVisible;

    default:
      return state;
  }
};
