import { mergeStyles } from '@cian/utils/lib/shared/style';
import * as React from 'react';

import * as styles from './index.css';

export type TCopyrightColor = 'black' | 'gray';

export const CopyrightColor = {
  Black: 'black' as TCopyrightColor,
  Gray: 'gray' as TCopyrightColor,
};

export interface ICopyrightProps {
  color: TCopyrightColor;
  year: number;
}

export function Copyright(props: ICopyrightProps) {
  return (
    <div {...mergeStyles(styles['container'], styles[props.color])}>
      ©&nbsp;&nbsp;{props.year}&nbsp;&nbsp;ЦИАН ГРУПП
    </div>
  );
}
