export enum FDealType {
  Unexpected   = 0, // Do not use
  Sale         = 1 << 0,
  RentLongterm = 1 << 1,
  RentDaily    = 1 << 2,
  Rent         = RentLongterm | RentDaily,
  Any          = Sale | Rent,
}

export enum FOfferType {
  Unexpected            = 0, // Do not use
  FlatOld               = 1 << 1,
  FlatNew               = 1 << 2,
  Flat                  = FlatOld | FlatNew,
  FlatShared            = 1 << 3,
  Room                  = 1 << 4,
  Bed                   = 1 << 5,
  Urban                 = Flat | FlatShared | Room | Bed,
  House                 = 1 << 6,
  HousePart             = 1 << 7,
  Townhouse             = 1 << 8,
  Land                  = 1 << 9,
  Suburban              = House | HousePart | Townhouse | Land,
  Residential           = Urban | Suburban,
  // Офис
  Office                = 1 << 10,
  // Торговая площадь
  TradeArea             = 1 << 11,
  // Склад
  Warehouse             = 1 << 12,
  // ПСН
  FreeAppointmentObject = 1 << 13,
  // Общепит
  PublicCatering        = 1 << 14,
  // Гараж
  Garage                = 1 << 15,
  // Производство
  Manufacture           = 1 << 16,
  // Автосервис
  AutoService           = 1 << 17,
  // Готовый бизнес
  Business              = 1 << 18,
  // Здание
  Building              = 1 << 19,
  // Бытовые услуги
  DomesticServices      = 1 << 20,
  // Коммерческая земля
  CommercialLand        = 1 << 21,
  Coworking = 1 << 22,
  // Коворкинг
  Commercial            = Office | TradeArea | Warehouse |
                          FreeAppointmentObject | PublicCatering | Garage |
                          Manufacture | AutoService | Business |
                          Building | DomesticServices | CommercialLand | Coworking,
  Any                   = Residential | Commercial,
}
