import { ButtonCheckbox, Label } from '@cian/components';

import * as React from 'react';

import { getIsElectronicTradingEnabled } from '../../../../shared/utils/isElectronicTradingEnabled';
import ButtonCheckboxContainer from '../../../ui/button_checkbox_container/button_checkbox_container';
import { EElectronicTradingType, IFieldProps, IsVisibleCheck } from '../field';

const styles = require('../field.css');
const fieldStyle = require('./electronic_trading.css');

export function Component({ filter, actions: { setElectronicTrading } }: IFieldProps) {
  const { electronicTrading = {} } = filter;
  const currentValue =
    typeof electronicTrading === 'undefined' || electronicTrading === null ? -1 : Number(electronicTrading);

  const options = React.useMemo(
    () => [
      {
        key: 'electronic-trading-any',
        value: EElectronicTradingType.Any,
        onChange: (value: boolean) => setElectronicTrading(value ? EElectronicTradingType.Any : undefined),
        title: 'Неважно',
      },
      {
        key: 'electronic-trading-do-not-display',
        value: EElectronicTradingType.DoNotDisplay,
        onChange: (value: boolean) => setElectronicTrading(value ? EElectronicTradingType.DoNotDisplay : undefined),
        title: 'Не показывать',
      },
      {
        key: 'electronic-trading-electronic-trading-only',
        value: EElectronicTradingType.ElectronicTradingOnly,
        onChange: (value: boolean) =>
          setElectronicTrading(value ? EElectronicTradingType.ElectronicTradingOnly : undefined),
        title: 'Только торги и залоговая недвижимость',
      },
    ],
    [setElectronicTrading],
  );

  return (
    <div className={styles.field} data-mark="FieldElectronicTradingType">
      <Label label="Торги и залоговая недвижимость">
        <ButtonCheckboxContainer>
          {options.map(item => (
            <ButtonCheckbox
              mobile
              buttonCheckboxStyle={[styles['buttonCheckbox'], fieldStyle['electronic-trading-type']]}
              checked={item.value === currentValue}
              id={item.key}
              key={item.key}
              name={item.key}
              onValueChange={item.onChange}
            >
              {item.title}
            </ButtonCheckbox>
          ))}
        </ButtonCheckboxContainer>
      </Label>
    </div>
  );
}

export const isVisible: IsVisibleCheck = () => getIsElectronicTradingEnabled();
