import * as React from 'react';

import { OutsideClick } from '@cian/components';
import { mergeStyles } from '@cian/utils';
import { IconClose } from './close_icon';

const style = require('./index.css');

interface ITooltipPopup {
  children?: string;
  containerClass?: string;
  direction?: TDirection;
  open: boolean;
  closeByScroll?: boolean;
  handlePopupClose(): void;
}

type TDirection = 'left' | 'right' | 'center';

export class TooltipPopup extends React.Component<ITooltipPopup, {}> {
  public componentDidMount() {
    if (this.props.closeByScroll) {
      document.addEventListener('scroll', this.handlePopupClose);
    }
  }

  public componentWillUnmount() {
    if (this.props.closeByScroll) {
      document.removeEventListener('scroll', this.handlePopupClose);
    }
  }

  public render() {

    return this.props.open ? (
      <OutsideClick onOutsideClick={this.handlePopupClose}>
        <div
          onClick={this.handleContainerClick}
          {...mergeStyles([
            style['container'],
            style[`container--${this.props.direction}`],
            this.props.containerClass,
          ])}
        >
          <div className={style['popup']}>
            <div className={style['close']} onClick={this.props.handlePopupClose}>
              <IconClose/>
            </div>
            {this.props.children}
          </div>
        </div>
      </OutsideClick>
    ) : null;
  }

  private handleContainerClick(e: React.SyntheticEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  private handlePopupClose = () => {
    this.props.handlePopupClose();
  }
}
