import * as React from 'react';

import { Label } from '@cian/components';
import { LiftsCountType } from '../../../../redux/filters/model';
import { SelectMobile } from '../../../common/select/select';
import { IFieldProps, isFlatObjectGroup, IsVisibleCheck  } from '../../field';

const styles = require('../../field.css');

export class Component extends React.Component<IFieldProps, {}> {
  private options = [{
    label: '',
    options: [{
      id: LiftsCountType.Any,
      label: 'Неважно',
    }, {
      id: LiftsCountType.One,
      label: 'Есть любой',
    }, {
      id: LiftsCountType.Two,
      label: 'Есть грузовой',
    }],
  }];

  public render() {
    return (
      <div data-mark="FieldLifts">
        <Label
          label="Лифт"
          containerStyle={styles.label}
        >
          <SelectMobile
            id="select-object-year"
            name="selectMobile"
            label="Неважно"
            onValueChange={this.changeLifts}
            value={this.getValue()}
            options={this.options[0].options}
          />
        </Label>
      </div>
    );
  }

  private getValue = () => {
    const { filter: { withServiceLift, liftsCount } } = this.props;

    if (withServiceLift) {
      return [LiftsCountType.Two];
    }

    if (liftsCount) {
      return [LiftsCountType.One];
    }

    return [LiftsCountType.Any];
  }

  private changeLifts = (value: string[]) => {
    const newValue = value[0];

    if (newValue === LiftsCountType.One) {
      this.props.actions.setLiftsCount(Number(newValue));
      this.props.actions.setWithServiceLift(false);

      return;
    }

    if (newValue === LiftsCountType.Two) {
      this.props.actions.setWithServiceLift(true);
      this.props.actions.setLiftsCount(Number(LiftsCountType.Any));

      return;
    }

    this.props.actions.setWithServiceLift(false);
    this.props.actions.setLiftsCount(Number(LiftsCountType.Any));
  }
}

export const isVisible: IsVisibleCheck = filter =>
  isFlatObjectGroup(filter) && !filter.withNewobject;
