import { mergeStyles } from '@cian/utils';
import * as React from 'react';

import { isIE } from '../../../utils/device_detector';

import {
  coworkingOfferType,
  FilterState,
  IFieldProps,
  IsVisibleCheck,
} from '../field';

import {
  createSelectOptions,
  IOptionsList,
  IProcessedOptions,
} from '../../common/select_helper';
import { Field } from '../../field';
import * as fields from '../../fields';

import { PopupParamNames } from '../../../redux/popups/actions';
import { CoworkingOfferTypesPopup } from './coworking_offer_type_popup';

const style = require('./select_coworking_offer_type.css');
const fieldStyle = require('../field.css');

interface ISelectObjectTypeState {
  processedOptions: IProcessedOptions;
  // for bug in winphone: disable before mounting
  selectDisabled?: boolean;
  isWorkplacesAvailable: boolean;
}

type TComponentProps = IFieldProps;

export class Component extends React.Component<TComponentProps, ISelectObjectTypeState> {

  public constructor(props: TComponentProps) {
    super(props);

    const { filter } = props;
    const { selectedCoworkingOfferType } = filter;
    const isWorkplacesAvailable = !!selectedCoworkingOfferType
      && selectedCoworkingOfferType.some(offerType => [
        coworkingOfferType.FixedWorkplace as string,
        coworkingOfferType.FlexibleWorkplace as string,
      ].includes(offerType));

    this.state = {
      isWorkplacesAvailable,
      selectDisabled: isIE(props.userAgent),
      processedOptions: {
        options: [],
        value: [],
      },
    };
  }

  public componentDidMount() {
    this.setState({ selectDisabled: false });

    this.updateProcessedOptions(this.props);
  }

  public componentWillReceiveProps(newProps: TComponentProps) {
    this.updateProcessedOptions(newProps);
  }

  public render() {
    const { dealType, selectedCoworkingOfferType } = this.props.filter;
    const { isWorkplacesAvailable } = this.state;
    const buttonText = this.renderButtonText();
    const error = !selectedCoworkingOfferType || !selectedCoworkingOfferType.length;
    const showError = error && !this.state.isWorkplacesAvailable;
    const buttonStyles = mergeStyles([
      style.button,
      showError && style.buttonError,
    ]);

    return (
      <div className={fieldStyle.field} data-mark="FieldSelectCoworkingOfferType">
        <button
          type="button"
          disabled={this.state.selectDisabled}
          {...buttonStyles}
          onClick={this.openPopup}
          key={dealType}
        >
          <span className={style.buttonText}>
            {buttonText}
          </span>
          {showError && (
            <div className={style.errorMessage}>
              Не выбран тип недвижимости
            </div>
          )}
        </button>
        <CoworkingOfferTypesPopup
          opened={this.props.isPopupOpened(PopupParamNames.CoworkingOfferType)}
          actions={this.props.actions}
          options={this.state.processedOptions.options}
          onClose={this.closePopup}
          selectedCoworkingOfferTypes={selectedCoworkingOfferType}
          onWorkplaceSelect={this.setSwitcherVisible}
          isWorkplaceSelected={isWorkplacesAvailable}
        />
        {isWorkplacesAvailable && <Field field={fields.coworkingWorkplaces} />}
      </div>
    );
  }

  private openPopup = () => {
    this.props.popupActions.openPopup(PopupParamNames.CoworkingOfferType);
  }

  private closePopup = () => {
    this.props.popupActions.closePopup(PopupParamNames.CoworkingOfferType);
  }

  private setSwitcherVisible = (isWorkplacesAvailable: boolean) => this.setState({ isWorkplacesAvailable });

  private renderButtonText = () => {
    const { selectedCoworkingOfferType } = this.props.filter;
    const { isWorkplacesAvailable } = this.state;
    let title = [];

    if (selectedCoworkingOfferType && selectedCoworkingOfferType.includes(coworkingOfferType.Office)) {
      title.push(isWorkplacesAvailable ? 'Отдел. офис' : 'Отдельный офис');
    }

    if (isWorkplacesAvailable) {
      title.push('Рабочее место');
    }

    if (!title.length) {
      title.push('Не выбран тип коворкинга');
    }

    return title.join(', ').toLowerCase();
  }

  private updateProcessedOptions = (props: TComponentProps) => {
    this.setState({
      processedOptions: this.getProcessedOptions(props.filter),
    });
  }

  private getProcessedOptions(filter: FilterState): IProcessedOptions {
    const coworkingOfferTypeList = this.createCoworkingOfferTypeList();

    if (filter.selectedCoworkingOfferType) {
      return createSelectOptions(coworkingOfferTypeList, filter.selectedCoworkingOfferType, filter);
    }

    return {
      options: coworkingOfferTypeList,
      value: [],
    };
  }

  private createCoworkingOfferTypeList(): IOptionsList[] {
    const { filter: { selectedCoworkingOfferType = [] } } = this.props;
    const { isWorkplacesAvailable } = this.state;

    const options: IOptionsList[] = [
      {
        label: '',
        options: [
          {
            label: 'Отдельный офис',
            id: coworkingOfferType.Office,
            disabled: selectedCoworkingOfferType
              && selectedCoworkingOfferType.includes(coworkingOfferType.Office)
              && !isWorkplacesAvailable,
          },
          {
            label: 'Рабочее место',
            id: 'workplace',
            disabled: isWorkplacesAvailable
              && selectedCoworkingOfferType
              && !selectedCoworkingOfferType.includes(coworkingOfferType.Office),
          },
        ],
      },
    ];

    return options;
  }
}

export const isVisible: IsVisibleCheck = filter => {
  return filter.selectedObjectTypes.includes('coworking') && filter.selectedCoworkingOfferType !== undefined;
};
