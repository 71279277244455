import { ResourceStatus } from '../../common/model';
import { TSeoMainActions } from './actions';
import { defaultSeoMainState, SeoMainState } from './model';

type Reducer = (state: SeoMainState, action: TSeoMainActions) => SeoMainState;

const seoMainReducer: Reducer = (state = defaultSeoMainState, action) => {
  switch (action.type) {
    case 'GetSeoMainPending':
      return { ...state, status: ResourceStatus.Pending };

    case 'GetSeoMainSuccess':
      return { ...action.payload.seoMain, status: ResourceStatus.Success };

    default:
      return state;
  }
};

export default seoMainReducer;
