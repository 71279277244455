export function setAuthPageDrawPending(isAuthPageDrawPending: boolean) {
  return {
    type: 'SetAuthPageDrawPending' as 'SetAuthPageDrawPending',
    payload: { isAuthPageDrawPending },
  };
}

export type TAuthPageAction = {
  type: 'SetAuthPageDrawPending',
  payload: {
    isAuthPageDrawPending: boolean;
  };
};
