import { isEqual } from 'lodash';

import { ILocation } from '../api/api';

const MOSCOW_IDS = [1, 4593];
const SPB_IDS = [2, 4588];

export function getCurrentRegionId(items: ILocation[]): number {
  if (items.length === 2) {
    const locationIds = items.map((item) => item.id).sort();

    if (isEqual(locationIds, MOSCOW_IDS)) {
      return 1;
    }

    if (isEqual(locationIds, SPB_IDS)) {
      return 2;
    }
  }

  return items[0].id;
}
