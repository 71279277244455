import * as React from 'react';
import { mergeStyles } from '@cian/utils';

import { NOTIFICATION_FREQUENCY, TNotificationFrequencyValue } from '../utils/notificationFrequency';

import * as styles from './styles.css';

export interface IFrequencyChangeProps {
  value: TNotificationFrequencyValue;
  setFrequency(value: TNotificationFrequencyValue): void;
}

export class FrequencyChange extends React.Component<IFrequencyChangeProps, {}> {
  public render() {
    const { value, setFrequency } = this.props;

    return (
      <div className={styles['wrapper']}>
        <div className={styles['container']}>
          {
            NOTIFICATION_FREQUENCY.map((item, index) => {
              return (
                <button
                  key={`NOTIFICATION_FREQUENCY_${item.value}_${index}`}
                  type="button"
                  onClick={() => setFrequency(item.value as TNotificationFrequencyValue)}
                  {...mergeStyles(
                    styles['button'],
                    value === item.value && styles['button-active'],
                  )}
                >
                  {item.label}
                </button>
              );
            })
          }
        </div>
      </div>
    );
  }
}
