import { mergeStyles } from '@cian/utils';
import * as React from 'react';

import { TOs } from '../download_app_button/download_app_button';
import Footer from '../footer/footer';
import { PromoType } from '../specials';
import SpecialFooter from '../specials/special_footer';
import { SaveFilterPopupContainer } from '../save_filter_popup/container';
import { AuthenticationPopup } from '../AuthenticationPopup';

const styles = require('./page.css');

export enum PageTheme {
  GRAY,
  LIGHT,
}

const pageStyles = {
  [PageTheme.LIGHT]: styles.pageLight,
  [PageTheme.GRAY]: styles.pageGray,
};

interface IPageProps {
  children?: React.ReactNode;
  onClick?: () => void;
  os: TOs;
  theme: PageTheme;
  pikPhone?: string;
  withFooter: boolean;
}

const Page: React.SFC<IPageProps> = ({
  children,
  onClick,
  os,
  theme,
  pikPhone,
  withFooter,
}: IPageProps) => {
  const promoInfo: { type: PromoType, phone?: string } | undefined = (() => {
    switch (true) {
      case Boolean(pikPhone):
        return {
          type: 'pik' as PromoType,
          phone: pikPhone as string,
        };
      default: return undefined;
    }
  })();

  const footer = promoInfo ?
    <SpecialFooter {...promoInfo} /> :
    <Footer os={os} />;

  return <div className={styles.page} onClick={onClick}>
    <div {...mergeStyles(styles.pageContent, pageStyles[theme])}>
      {children}
    </div>
    {withFooter && footer}
    <SaveFilterPopupContainer />
    <AuthenticationPopup />
  </div>;
};

export default Page;
