import { IStyleConfig, mergeStyles } from '@cian/components';
import * as React from 'react';

const styles = require('./visibility.css');

export interface IVisibilityProps {
  containerStyle?: IStyleConfig;
  visibleStyle?: IStyleConfig;
  visible: boolean;
  onTransitionEnd?: React.TransitionEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onBeforeShow?: () => void;
  onShow?: () => void;
  onBeforeHide?: () => void;
  onHide?: () => void;
  showBanner?: boolean;
  headerHeight?: number;
  headerOffset?: number;
}

interface IVisibilityState {
  visible: boolean;
  inDom: boolean;
}

export function animateVisibility<P>(
  Component: React.ComponentClass<P>,
  mapProps?: (props: IVisibilityProps & P) => IVisibilityProps & P,
): React.ComponentClass<IVisibilityProps & P> {
  return class AnimateVisibility extends React.Component<IVisibilityProps & P, IVisibilityState> {
    public static displayName = `animateVisibility(${Component.displayName || Component.name})`;

    public constructor(props: IVisibilityProps & P) {
      super(props);
      this.state = {
        visible: props.visible,
        inDom: props.visible,
      };
    }

    public componentWillReceiveProps(nextProps: IVisibilityProps) {
      if (nextProps.visible !== this.props.visible) {
        if (nextProps.visible && this.props.onBeforeShow) {
          this.props.onBeforeShow();
        }
        if (!nextProps.visible && this.props.onBeforeHide) {
          this.props.onBeforeHide();
        }
        this.setState({ visible: this.props.visible, inDom: true }, () => {
          //setTimeout нужен для корректного запуска анимации
          setTimeout(() => {
            this.setState({ visible: nextProps.visible, inDom: true }, () => {
              if (nextProps.visible && this.props.onShow) {
                this.props.onShow();
              }
              if (!nextProps.visible && this.props.onHide) {
                this.props.onHide();
              }
            });
          });
        });
      }
    }

    public render() {
      const { state } = this;
      const props = mapProps
        ? mapProps(this.props as IVisibilityProps & P)
        : this.props;

      if (!state.inDom) {
        return null as any; // tslint:disable-line:no-any
      }
      const style = mergeStyles([
        styles.container,
        state.visible && styles.visible,
        state.visible && props.visibleStyle,
      ], props.containerStyle);

      return (
        <div {...style} onTransitionEnd={this.transitionEnd} onClick={this.onClick}>
          {/* tslint:disable-next-line:no-any */}
          <Component {...props as any} />
        </div>
      );
    }

    private onClick = (e: React.MouseEvent<HTMLElement>) => {
      if (this.props.onClick) {
        this.props.onClick(e);
      }
    }

    private transitionEnd = () => {
      if (!this.state.visible) {
        this.setState({ inDom: false });
      }
    }
  };
}
