import { invert, keys, values } from 'lodash';
import { goBack, push } from 'react-router-redux';
import { Dispatch } from 'redux';

import { isClient } from '../../utils/isomorphic';
import { mergeQueryParams, removeQueryParam } from '../../utils/location';
import { ReduxState, TTypedThunkAction } from '../model';

export type TPopupsActions
  = { type: 'SetSidebarState', payload: { sidebarIsOpened: boolean } }
  | { type: 'SetActivePopup', payload: { activePopup: PopupParamName; } }
  | { type: 'ClearActivePopup' };

// Название обязательно должно содержать _popup
export type PopupParamName
  = 'where_popup'
  | 'where_popup_tablet'
  | 'advanced_region_popup'
  | 'advanced_region_popup_tablet'
  | 'map_in_card_popup'
  | 'object_type_popup'
  | 'coworking_offer_type_popup'
  | 'subscribe_map_popup'
  | 'legend_map_popup'
  | 'complain_popup'
  | 'geo_popup'
  | 'success_feedback_popup'
  | 'gk_mvk_map_popup'
  | 'gk_mvk_section_popup'
  | 'gk_mvk_flat_popup'
  | 'none'
  | 'business_specialties_popup'
;

export type TPopupParamNames = {
  /**попап "Где" в мобилках*/
  Where: PopupParamName;
  /**попап "Где" в таблетах */
  WhereTablet: PopupParamName;
  /** попап выбора гео: "Регион" */
  Geo: PopupParamName;
  /** попап Метро/районы в мобилках */
  AdvancedRegion: PopupParamName;
  /** попап Метро/районы в таблетах*/
  AdvancedRegionTablet: PopupParamName;
  /** попап Метро/районы */
  MapInCard: PopupParamName;
  /** попап выбора типа недвижимости в мобилках */
  ObjectType: PopupParamName;
  /** попап выбора типа коворкинга в мобилках */
  CoworkingOfferType: PopupParamName;
  /** попап подписки на карте */
  SubscribeMap: PopupParamName;
  /** попап легенды на карте */
  LegendMap: PopupParamName;
  /** попап жалобы на карточке */
  Complain: PopupParamName;
  /** попап успешной отправки формы обратной связи. */
  SuccessFeedback: PopupParamName;
  /** попап выбора корпуса ЖК */
  GKMVKMap: PopupParamName;
  /** попап выбора секции ЖК */
  GKMVKSection: PopupParamName;
  /** попап выбора квартиры ЖК */
  GKMVKFlat: PopupParamName;
  /** попап выбора направление готового бизнеса */
  BusinessSpecilties: PopupParamName;
};

export const PopupParamNames: TPopupParamNames = {
  Where: 'where_popup',
  WhereTablet: 'where_popup_tablet',
  Geo: 'geo_popup',
  AdvancedRegion: 'advanced_region_popup',
  AdvancedRegionTablet: 'advanced_region_popup_tablet',
  MapInCard: 'map_in_card_popup',
  ObjectType: 'object_type_popup',
  CoworkingOfferType: 'coworking_offer_type_popup',
  SubscribeMap: 'subscribe_map_popup',
  LegendMap: 'legend_map_popup',
  Complain: 'complain_popup',
  SuccessFeedback: 'success_feedback_popup',
  GKMVKMap: 'gk_mvk_map_popup',
  GKMVKSection: 'gk_mvk_section_popup',
  GKMVKFlat: 'gk_mvk_flat_popup',
  BusinessSpecilties: 'business_specialties_popup',
};

export type OpenPopupDispatchProp = { openPopup: (popupQueryParam: PopupParamName) => void };
export type ClosePopupDispatchProp = { closePopup: (popupQueryParam: PopupParamName) => void };
export type PopupDispatchProp = ClosePopupDispatchProp & OpenPopupDispatchProp;

interface IQueryParams {
  [key: string]: string;
}

let openedOnPage = false;

export function openPopup(popupQueryParam: PopupParamName, paramValue: string = '1'): TTypedThunkAction<Promise<void>> {
  return (dispatch) => {
    openedOnPage = true;

    dispatch<TPopupsActions>({
      type: 'SetActivePopup',
      payload: {
        activePopup: popupQueryParam,
      },
    });

    pushQueryParams({ [popupQueryParam]: paramValue }, true, dispatch);

    return Promise.resolve();
  };
}

export function closePopup(popupQueryParam: PopupParamName, isMapPage?: boolean): TTypedThunkAction<Promise<void>> {
  return (dispatch) => {
    dispatch<TPopupsActions>({
      type: 'ClearActivePopup',
    });

    if (openedOnPage && !isMapPage) {
      dispatch(goBack()); // чтобы сохранить скролл страницы до перехода.
    } else {
      pushQueryParams({ [popupQueryParam]: '0' }, false, dispatch);
    }

    openedOnPage = false;

    return Promise.resolve();
  };
}

export function closePopups(popupQueryParams: PopupParamName[]): TTypedThunkAction<Promise<void>> {
  return (dispatch) => {
    dispatch<TPopupsActions>({
      type: 'ClearActivePopup',
    });

    const params: IQueryParams = invert(popupQueryParams);

    pushQueryParams(params, false, dispatch);

    openedOnPage = false;

    return Promise.resolve();
  };
}

export function isPopupOpened(state: ReduxState, popupQueryParam: string): boolean {
  return !!state.routing.locationBeforeTransitions.query[popupQueryParam];
}

export function isSidebarOpened(state: ReduxState) {
  return state.popups.sidebarIsOpened;
}

export function createIsPopupOpened(state: ReduxState): (s: string) => boolean {
  return (popupQueryParam: string) => isPopupOpened(state, popupQueryParam);
}

export function pushQueryParams(params: IQueryParams, open: boolean, dispatch: Dispatch<TPopupsActions>): void {
  if (!isClient) { return; }

  const { search, pathname } = window.location;

  let queryString = open
    ? mergeQueryParams(search, params)
    : removeQueryParam(search, ...keys(params));

  dispatch(push(pathname + queryString));
}

export function removePopupParams(location: string) {
  return removeQueryParam(location, ...values(PopupParamNames));
}

export function hasPopupParams(location: string) {
  return values(PopupParamNames).some(param => location.includes(`${param}=1`));
}

export function setSidebarState(opened: boolean): TPopupsActions {
  return {
    type: 'SetSidebarState',
    payload: { sidebarIsOpened: opened },
  };
}
