import { isClient } from './isomorphic';

export function isSafari(userAgent: string): boolean {
  return /^((?!chrome|android).)*safari/i.test(userAgent);
}

const BREAKPOINT_MOBILE = 640;

export function isTablet() {
  return screen.width >= BREAKPOINT_MOBILE;
}

export function isPhone() {
  return screen.width < BREAKPOINT_MOBILE;
}

export type Orientation = 'landscape' | 'portrait';

interface IBrowserInfo {
  needUpdate: boolean;
  browserName: string;
  version: number;
  storeName: string;
  url: string;
}

const minVersion = {
  Google_Chrome: 53,
  Safari: 9,
  Yandex_Browser: 16.9,
  Android_Browser: 4.4,
  Samsung_Internet: 3,
} as { [key: string]: number };

const regularBrowsers = {
  SAMSUNG: 'Samsung_Internet',
  YaBrowser: 'Yandex_Browser',
  Chrome: 'Google_Chrome',
  CriOS: 'Google_Chrome',
  Safari: 'Safari',
  Android: 'Android_Browser',
} as { [key: string]: string };

const getUserAgent = (ua?: string): string | undefined => {
  // tslint:disable-next-line:no-any
  if (!(global as any).navigator && !ua) {
    return;
  }
  return ua || navigator.userAgent;
};

const testUserAgent = (test: (ua: string) => boolean, userAgent?: string): boolean => {
  const ua = getUserAgent(userAgent);
  return ua ? test(ua) : false;
};

export const isWinPhone = (userAgent?: string): boolean => {
  return testUserAgent(
    ua => /Windows Phone/i.test(ua),
    userAgent,
  );
};

export const isInternetExplorer = (userAgent?: string): boolean => {
  return testUserAgent(
    ua => /Trident\//i.test(ua),
    userAgent,
  );
}

export const isEdge = (userAgent?: string): boolean => {
  return testUserAgent(
    ua => /Windows Phone 10/i.test(ua),
    userAgent,
  );
};

export const isIE = (userAgent?: string): boolean => {
  return testUserAgent(
    ua => isWinPhone(ua) && !isEdge(ua),
    userAgent,
  );
};

// http://stackoverflow.com/questions/9038625/detect-if-device-is-ios
export const isAppleDevice = (userAgent?: string): boolean => {
  return testUserAgent(
    ua => /iPad|iPhone|iPod/.test(ua) && !/trident/i.test(ua),
    userAgent,
  );
};

export const isAndroidDevice = (userAgent?: string): boolean => {
  return testUserAgent(
    ua => /(android)/i.test(ua) && !isWinPhone(ua),
    userAgent,
  );
};

export const getVersionNumber = (str: string) => {
  const tmpStr = str.trim();
  let result = tmpStr.includes(' ') ? tmpStr.split(' ')[0] : tmpStr;
  result = result.includes(';') ? result.split(';')[0] : result;
  return result.trim();
};

export const getVersion = (str: string, codeName: string, searchOnlyCodeName?: boolean) => {
  if (!searchOnlyCodeName) {
    if (str.includes('Browser')) {
      codeName = 'Browser';
    } else if (str.includes('Version')) {
      if (codeName === 'Android') {
        const version = str.match(/Android (\d\.\d)/);
        if (version && version[1]) {
          return parseFloat(version[1]);
        }
      } else {
        codeName = 'Version';
      }
    }
  }
  const offset = str.indexOf(codeName) + codeName.length + 1;
  return parseFloat(getVersionNumber(str.substring(offset)));
};

const parseBrowserInfo = (
  userAgent: string,
  codeName: string,
  storeName: string,
  url: string,
): IBrowserInfo => {
  const browserName = regularBrowsers[codeName];
  const version = getVersion(userAgent, codeName);
  const needUpdate = version < minVersion[browserName];

  return { needUpdate, browserName, version, storeName, url };
};

export const isSamsungBrowser = (userAgent: string): Boolean => {
  return (isAndroidDevice(userAgent) && !userAgent.toLowerCase().includes('samsung')
    && (userAgent.includes('Version')));
};

export const isOldBrowser = (userAgent?: string): IBrowserInfo => {
  let storeName = '';
  let url = '';
  let browserName = '';
  let version = 99;
  let needUpdate = false;
  userAgent = userAgent || navigator.userAgent;

  if (isAppleDevice(userAgent)) {
    storeName = 'App Store';
    url = 'https://itunes.apple.com';
  } else if (isAndroidDevice(userAgent)) {
    storeName = 'Google Play';
    url = 'https://play.google.com';
    if (isSamsungBrowser(userAgent)) {
      return parseBrowserInfo(userAgent, 'Android', storeName, url);
    }
  } else {
    return { needUpdate, browserName, version, storeName, url };
  }

  for (let key in regularBrowsers) {
    if (userAgent.includes(key)) {
      return parseBrowserInfo(userAgent, key, storeName, url);
    }
  }

  return { needUpdate, browserName, version, storeName, url };
};

export const isPortrait = (): boolean => {
  if (!isClient) {
    return false;
  }

  return document.documentElement.clientHeight > document.documentElement.clientWidth;
};

export const getOrientation = (): Orientation => isPortrait() ? 'portrait' : 'landscape';
